import React, { FunctionComponent } from 'react';
import { Theme } from '../../theme';
import { Text } from '../../components/common/Text';
import styled from 'styled-components';
import { Button } from '../../components/common/Button';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ActionButtonDetailsDocumentProps } from './DocumentContainerHeader';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Subscribe } from '../../svg';
import { CircleLoader } from '../../components/common/CircleLoader';

export const ActionButtonDetailsDocuments: FunctionComponent<{
  actionsButton: ActionButtonDetailsDocumentProps;
}> = ({ actionsButton }) => (
  <FlexContainer alignItems="center" justifyContent="flex-end">
    {actionsButton.onCLickDelete && (
      <StyledButton
        border={`1px solid ${Theme.colors.c2186C6}`}
        padding="3px 15px"
        borderRadius="4px"
        onClick={actionsButton.onCLickDelete}
      >
        <Text
          text="delete"
          fontSize="12px"
          lineHeight="26px"
          color={Theme.colors.c2186C6}
        />
      </StyledButton>
    )}
    {actionsButton.onCLickEdit && (
      <>
        <StyledButton
          border={`1px solid ${Theme.colors.c2186C6}`}
          padding="3px 15px"
          borderRadius="4px"
          onClick={actionsButton.onClickMove}
        >
          <Text
            text="move"
            fontSize="12px"
            lineHeight="26px"
            color={Theme.colors.c2186C6}
          />
        </StyledButton>
        <StyledButton
          border={`1px solid ${Theme.colors.c2186C6}`}
          padding="3px 15px"
          borderRadius="4px"
          onClick={actionsButton.onCLickEdit}
        >
          <Text
            text="edit"
            fontSize="12px"
            lineHeight="26px"
            color={Theme.colors.c2186C6}
          />
        </StyledButton>
      </>
    )}
    {actionsButton.onClickSubscribe && (
      <StyledButton
        border={`1px solid ${Theme.colors.c2186C6}`}
        padding="3px 15px"
        borderRadius="4px"
        onClick={actionsButton.onClickSubscribe.submit}
        backgroundColor={
          actionsButton.onclickPublish ? undefined : Theme.colors.c5B84EF
        }
        hoverBackgroundColor={
          actionsButton.onclickPublish ? undefined : Theme.colors.c5176d6
        }
      >
        {!actionsButton.onClickSubscribe.loading ? (
          <SvgIcon
            svg={<Subscribe />}
            height="14px"
            color={
              actionsButton.onclickPublish
                ? Theme.colors.c2186C6
                : Theme.colors.cFFFFFF
            }
          />
        ) : (
          <CircleLoader thicknessInPx="2px" sizeInPx="14px" />
        )}
        <Text
          style={{
            marginLeft: '5px',
          }}
          text={actionsButton.onClickSubscribe.text}
          fontSize="12px"
          lineHeight="26px"
          color={
            actionsButton.onclickPublish
              ? Theme.colors.c2186C6
              : Theme.colors.cFFFFFF
          }
        />
      </StyledButton>
    )}
    {actionsButton.onClickDownload && (
      <StyledButton
        border={`1px solid ${Theme.colors.c2186C6}`}
        padding="3px 15px"
        borderRadius="4px"
        onClick={actionsButton.onClickDownload}
        backgroundColor={
          actionsButton.onclickPublish ? undefined : Theme.colors.c5B84EF
        }
        hoverBackgroundColor={
          actionsButton.onclickPublish ? undefined : Theme.colors.c5176d6
        }
      >
        <Text
          text="download"
          fontSize="12px"
          lineHeight="26px"
          color={
            actionsButton.onclickPublish
              ? Theme.colors.c2186C6
              : Theme.colors.cFFFFFF
          }
        />
      </StyledButton>
    )}
    {actionsButton.onclickPublish && (
      <StyledButton
        border={`1px solid ${Theme.colors.c2186C6}`}
        padding="3px 15px"
        borderRadius="4px"
        onClick={actionsButton.onclickPublish}
        backgroundColor={Theme.colors.c5B84EF}
        hoverBackgroundColor={Theme.colors.c5176d6}
      >
        <Text
          text="publish"
          fontSize="12px"
          lineHeight="26px"
          color={Theme.colors.cFFFFFF}
        />
      </StyledButton>
    )}
  </FlexContainer>
);

const StyledButton = styled(Button)`
  margin-right: 16px;
  white-space: nowrap;
`;
