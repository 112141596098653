import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useRef,
} from 'react';
import { Text } from '../Text';
import { Theme } from '../../../theme';
import styled from 'styled-components';
import { FlexContainer } from '../../layout/FlexContainer';

interface ItemSelectProps {
  cursor: number;
  index: number;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  svgRender: ((element: any) => JSX.Element) | undefined;
  element: JSX.Element;
  selectItemMap: { id: string; displayValue: string };
  setHovered: (index: string | undefined) => void;
  hovered: string | undefined;
}

export const ItemSelect: FunctionComponent<ItemSelectProps> = ({
  cursor,
  index,
  onMouseDown,
  svgRender,
  element,
  selectItemMap,
  setHovered,
  hovered,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!hovered && cursor === index && ref.current) {
      // @ts-ignore
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [cursor, hovered, index]);

  return (
    <ItemContainer
      ref={ref}
      alignItems="center"
      selected={cursor === index}
      onMouseDown={onMouseDown}
      onMouseEnter={() => setHovered(selectItemMap.id)}
      onMouseLeave={() => setHovered(undefined)}
    >
      {svgRender && element && svgRender(element)}
      <Text
        text={selectItemMap.displayValue}
        fontSize="14px"
        lineHeight="14px"
        fontWeight="300"
        color={Theme.colors.c404040}
        skipTranslation
      />
    </ItemContainer>
  );
};

const ItemContainer = styled(FlexContainer)<{ selected?: boolean }>`
  height: 36px;
  padding: 10px 16px;

  ${({ selected, theme }) => selected && `background: ${theme.colors.cF0F0F0};`}

  &:hover {
    cursor: pointer;
  }

  & > div:first-child {
    margin-right: 8px;
  }
`;
