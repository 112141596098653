import React, { FunctionComponent, useEffect, useState } from 'react';
import { DocumentNode } from 'graphql';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { useNotificationBanner } from '../../hooks/useNotificationBanner';
import { useKeyPress } from '../../input/useKeyPress';
import { ItemSelect } from './ItemSelect';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../layout/FlexContainer';
import { CircleLoader } from '../CircleLoader';
import { Text } from '../Text';

interface DynamicSelectProps {
  query: DocumentNode;
  queryResultKey: string;
  fieldName?: string;
  getVariablesFilterTerm: (filter: string) => any;
  filterTerm: string;
  selectItemMap: (element: any) => { id: string; displayValue: string };
  onSelectItemClick: (element: any) => void;
  svgRender?: (element: any) => JSX.Element;
  showResults: boolean;
  collectionFilter?: (x: any) => boolean;
}

export const DynamicSelect: FunctionComponent<DynamicSelectProps> = ({
  query,
  queryResultKey,
  fieldName,
  selectItemMap,
  getVariablesFilterTerm,
  filterTerm,
  onSelectItemClick,
  svgRender,
  showResults,
  collectionFilter,
}) => {
  const [functionQuery, { data, error, loading }] = useLazyQuery(query, {
    fetchPolicy: 'cache-and-network',
  });

  useNotificationBanner(undefined, error, undefined, {
    id: uuidv4(),
    type: 'failed',
    title: 'Woops!',
    content: 'somethingWentWrongsInField',
    variables: {
      fieldName,
    },
  });

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (data && data[queryResultKey].length && downPress) {
      setCursor((prevState) =>
        prevState < data[queryResultKey].length - 1 ? prevState + 1 : prevState
      );
    }
  }, [data, downPress, queryResultKey]);

  useEffect(() => {
    if (data && data[queryResultKey].length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [collectionFilter, data, queryResultKey, upPress]);

  useEffect(() => {
    if (showResults && data && data[queryResultKey].length && enterPress) {
      onSelectItemClick(data && data[queryResultKey][cursor]);
    }
    // eslint-disable-next-line
  }, [collectionFilter, cursor, data, enterPress, queryResultKey]);

  useEffect(() => {
    if (data && data[queryResultKey].length && hovered) {
      setCursor(
        data &&
          data[queryResultKey]
            .map((a: any) => selectItemMap(a).id)
            .indexOf(hovered)
      );
    }
    // eslint-disable-next-line
  }, [collectionFilter, data, hovered, queryResultKey]);

  useEffect(() => {
    if (showResults) {
      functionQuery({ variables: getVariablesFilterTerm(filterTerm) });
    }
    // eslint-disable-next-line
  }, [showResults, filterTerm]);

  const checkData =
    data && data[queryResultKey] && data[queryResultKey].length > 0;

  return showResults ? (
    <Container>
      {checkData &&
        !loading &&
        data[queryResultKey]
          .filter(collectionFilter || (() => true))
          .map((element: any, index: number) => {
            return (
              <ItemSelect
                key={selectItemMap(element).id}
                cursor={cursor}
                index={index}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  onSelectItemClick(element);
                }}
                svgRender={svgRender}
                element={element}
                selectItemMap={selectItemMap(element)}
                setHovered={setHovered}
                hovered={hovered}
              />
            );
          })}
      {loading && (
        <FlexContainer
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%', height: '80px' }}
        >
          <CircleLoader thicknessInPx="4px" sizeInPx="36px" />
        </FlexContainer>
      )}
      {!checkData && !loading && (
        <FlexContainer
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%', height: '80px' }}
        >
          <Text
            text="noResults"
            fontSize="14px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.c404040}
          />
        </FlexContainer>
      )}
    </Container>
  ) : null;
};

const Container = styled.div`
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  width: calc(100% - 10px);
  margin-left: 5px;
  position: absolute;
  background: ${({ theme }) => theme.colors.cFFFFFF};
  max-height: 180px;
  overflow-y: auto;
  top: calc(100% - 20px);
  z-index: 2;
`;
