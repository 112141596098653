import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { ItemSelect } from './ItemSelect';
import { useNotificationBanner } from '../../../hooks/useNotificationBanner';
import { useKeyPress } from '../../../input/useKeyPress';
import { FIND_USERS } from '../../../../graphql/user/queries';
import { gqlName } from '../../../../graphql/utils';
import { User } from '@pec-manager/graphql';
import { KeycloakService } from '../../../../services/KeycloakService';
import { splitBySeparator } from '../../../../utils/stringUtils';
import { throttle } from 'lodash';
import { FlexContainer } from '../../../layout/FlexContainer';
import { Text } from '../../Text';
import { Theme } from '../../../../theme';
import { AroundLoader } from '../../../loader/AroundLoader';

interface DynamicSelectProps {
  onSelectItemClick: (element: any) => void;
  svgRender?: (element: any) => JSX.Element;
  showResults: boolean;
  setData?: any;
  disablePressEnter?: boolean;
  displayValueMap?: any;
  users: string[];
  onClickRemove: (id: string) => void;
  onClickOutside: () => void;
  openUp?: boolean;
  width?: string;
  left?: boolean;
}

export const DynamicSelectUser: FunctionComponent<DynamicSelectProps> = ({
  onSelectItemClick,
  svgRender,
  showResults,
  setData,
  users,
  onClickRemove,
  onClickOutside,
  openUp,
  left,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputThrottled, setInputThrottled] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setThrottled = throttle((newValue) => {
    setInputThrottled(newValue);
  }, 1000);

  useEffect(() => {
    if (showResults) {
      findUser();
    }
    // eslint-disable-next-line
  }, [showResults]);

  const [findUser, { data, error, loading }] = useLazyQuery(FIND_USERS, {
    variables: { searchFilter: inputThrottled },
    fetchPolicy: 'cache-and-network',
  });

  useNotificationBanner(undefined, error, undefined, {
    id: uuidv4(),
    type: 'failed',
    title: 'Woops!',
    content: 'somethingWentWrongsInField',
  });

  const selectItemMap = (user: User) => ({
    displayValue: `${user.firstName} ${user.lastName}`,
    id: user.id,
  });

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (data && data[gqlName(FIND_USERS)].length && downPress) {
      setCursor((prevState) =>
        prevState < data[gqlName(FIND_USERS)].length - 1
          ? prevState + 1
          : prevState
      );
    }
  }, [data, downPress]);

  useEffect(() => {
    if (data && data[gqlName(FIND_USERS)].length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [data, upPress]);

  useEffect(() => {
    if (showResults && data && data[gqlName(FIND_USERS)].length && enterPress) {
      onSelectItemClick(data && data[gqlName(FIND_USERS)][cursor]);
      setCursor(0);
      setInputValue('');
      setInputThrottled('');
    }
    // eslint-disable-next-line
  }, [cursor, data, enterPress]);

  useEffect(() => {
    if (data && data[gqlName(FIND_USERS)].length && hovered) {
      setCursor(
        data &&
          data[gqlName(FIND_USERS)]
            .map((a: any) => selectItemMap(a).id)
            .indexOf(hovered)
      );
    }
    // eslint-disable-next-line
  }, [data, hovered]);

  useEffect(() => {
    showResults && setCursor(0);
  }, [showResults]);

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  const addInput = () => {
    setData(
      Array.from(new Set(data.concat(splitBySeparator(inputValue, ','))))
    );
  };

  const enterAddInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && inputValue) {
      addInput();
    }
  };

  return showResults ? (
    <Container openUp={openUp} ref={ref} left={left}>
      <InputStyled
        lang={KeycloakService.getLocale()}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          setThrottled(e.target.value);
        }}
        autoFocus
        onKeyDown={enterAddInput}
      />
      <FlexContainer column style={{ overflow: 'auto', maxHeight: '140px' }}>
        {data &&
          data[gqlName(FIND_USERS)].length > 0 &&
          data[gqlName(FIND_USERS)].map((element: any, index: number) => (
            <ItemSelect
              key={selectItemMap(element).id}
              cursor={cursor}
              index={index}
              onMouseDown={(e) => {
                e.stopPropagation();
                if (users?.includes(selectItemMap(element).id)) {
                  onClickRemove(selectItemMap(element).id);
                } else {
                  onSelectItemClick(element);
                }
                setInputValue('');
                setInputThrottled('');
              }}
              svgRender={svgRender}
              element={element}
              selectItemMap={selectItemMap(element)}
              setHovered={setHovered}
              hovered={hovered}
              selected={users.includes(selectItemMap(element).id)}
            />
          ))}
        {data && data[gqlName(FIND_USERS)].length === 0 && (
          <FlexContainer
            style={{ minWidth: '200px' }}
            alignItems="center"
            justifyContent="center"
          >
            <Text
              text="Non ci sono risultati"
              fontSize="14px"
              lineHeight="18px"
              fontWeight="300"
              color={Theme.colors.c404040}
              style={{ padding: '4px 8px' }}
            />
          </FlexContainer>
        )}
        {!data && loading && (
          <FlexContainer
            style={{ minWidth: '200px' }}
            alignItems="center"
            justifyContent="center"
          >
            <AroundLoader />
          </FlexContainer>
        )}
      </FlexContainer>
      {/*      {error && (
        <ItemContainer alignItems="center">
          <Text
            text="advancedSelectError"
            fontSize="14px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.cFF8686}
            skipTranslation
          />
        </ItemContainer>
      )} */}
    </Container>
  ) : null;
};

const Container = styled.div<{
  openUp?: boolean;
  widthExeternal?: string;
  left?: boolean;
}>`
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  ${({ widthExeternal }) => widthExeternal && `width: ${widthExeternal};`}
  margin-left: 5px;
  position: absolute;
  background: ${({ theme }) => theme.colors.cFFFFFF};
  max-height: 200px;
  top: calc(${({ openUp }) => (openUp ? '-190px' : '100%')});
  ${({ left }) => (left ? `left: 0;` : `right: 0;`)}
  z-index: 2;
`;

const InputStyled = styled.input`
  border: 2px solid ${({ theme }) => theme.colors.cDFE1E6};
  border-radius: 6px;
  margin: 8px 11px;
  padding: 4px;
  outline: none;
  flex-grow: 1;
  width: calc(100% - 22px);
  color: ${({ theme }) => theme.colors.c000000};
`;
