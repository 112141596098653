import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import styled from 'styled-components';
import { Theme } from '../../theme';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { isToday } from '../../utils/stringUtils';

interface UserWithDateProps {
  date: any;
  name: string;
  size?: 'small';
  notFullWidth?: boolean;
}

export const UserWithDate: FunctionComponent<UserWithDateProps> = ({
  date,
  name,
  size,
  notFullWidth,
}) => {
  const calculateSize = (size?: 'small' | 'big') => {
    if (size === 'small') {
      return {
        fontSizeAvatar: 12,
        sizeAvatar: 24,
        fontSizeText: '10px',
        lineHeight: '16px',
      };
    }
    return {
      fontSizeAvatar: 14,
      sizeAvatar: undefined,
      fontSizeText: '12px',
      lineHeight: '20px',
    };
  };

  return (
    <FlexContainer
      style={{ width: notFullWidth ? 'fit-content' : '100%' }}
      alignItems="center"
    >
      <RoundedTextAvatar
        text={name}
        fontSize={calculateSize(size).fontSizeAvatar}
        size={calculateSize(size).sizeAvatar}
      />
      <Container column justifyContent="space-around">
        <Text
          text={name}
          fontSize={calculateSize(size).fontSizeText}
          lineHeight={calculateSize(size).lineHeight}
          ellipsis
        />
        <Text
          text={isToday(date) ? 'todayAt' : 'completeDate'}
          translationArgs={{
            date: getDateAsString(date),
            time: getTimeAsString(date),
            fresh: true,
          }}
          fontSize={calculateSize(size).fontSizeText}
          lineHeight={calculateSize(size).lineHeight}
          color={Theme.colors.c838383}
          ellipsis
        />
      </Container>
    </FlexContainer>
  );
};

const Container = styled(FlexContainer)`
  height: 40px;
  margin: 0 16px;
  overflow: auto;
`;
