import { gql } from '@apollo/client/core';
import { gqlCommentPageShape } from './shapes';

export const GET_COMMENTS_BY_RESOURCE = gql`
    query GetCommentsByResource($resource: ResourceInput!, $pagination: Pagination) {
        getCommentsByResource(resource: $resource, pagination: $pagination) {
            ${gqlCommentPageShape}
        }
    }
`;

export const GET_COMMENTS_REPLIES = gql`
  query GetCommentReplies($commentId: ID!, $resource: ResourceInput!, $pagination: Pagination) {
    getCommentReplies(commentId: $commentId, resource: $resource, pagination: $pagination) {
        ${gqlCommentPageShape}
    }
  }
`;
