import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Text } from '../../Text';
import { Theme } from '../../../../theme';
import styled from 'styled-components';
import { FlexContainer } from '../../../layout/FlexContainer';
import { SvgIcon } from '../../SvgIcon';
import { Check, Close } from '../../../../svg';

interface ItemSelectProps {
  cursor: number;
  index: number;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  svgRender: ((element: any) => JSX.Element) | undefined;
  element: JSX.Element;
  selectItemMap: { id: string; displayValue: string };
  setHovered: (index: string | undefined) => void;
  hovered: string | undefined;
  selected?: boolean;
}

export const ItemSelect: FunctionComponent<ItemSelectProps> = ({
  cursor,
  index,
  onMouseDown,
  svgRender,
  element,
  selectItemMap,
  setHovered,
  hovered,
  selected,
}) => {
  const ref = useRef(null);

  const [deleteHovered, setDeleteHovered] = useState(false);
  const [blockOnMouseEvent, setBlockOnMouseEvent] = useState(false);

  useEffect(() => {
    if (!hovered && cursor === index && ref.current) {
      // @ts-ignore
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [cursor, hovered, index]);

  return (
    <ItemContainer
      ref={ref}
      alignItems="center"
      selected={cursor === index}
      onMouseDown={(e) => {
        if (!blockOnMouseEvent) {
          onMouseDown && onMouseDown(e);
        }
      }}
      onMouseEnter={() => {
        setDeleteHovered(true);
        setHovered(selectItemMap.id);
      }}
      onMouseLeave={() => {
        setDeleteHovered(false);
        setHovered(undefined);
      }}
    >
      {svgRender && element && svgRender(element)}
      <Text
        text={selectItemMap.displayValue}
        fontSize="14px"
        lineHeight="18px"
        fontWeight="300"
        color={Theme.colors.c404040}
        skipTranslation
        ellipsis
      />
      {selected && (
        <SelectedContainer
          onMouseEnter={() => setBlockOnMouseEvent(true)}
          onMouseLeave={() => setBlockOnMouseEvent(false)}
        >
          {!deleteHovered ? (
            <SvgIcon svg={<Check />} width="12px" />
          ) : (
            <SvgIcon
              svg={<Close />}
              color={Theme.colors.cFE4242}
              width="12px"
            />
          )}
        </SelectedContainer>
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled(FlexContainer)<{ selected?: boolean }>`
  height: 36px;
  padding: 10px 16px;

  ${({ selected, theme }) => selected && `background: ${theme.colors.cF0F0F0};`}

  &:hover {
    cursor: pointer;
  }

  & > div:first-child {
    margin-right: 8px;
  }
`;

const SelectedContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transform: translate();
`;
