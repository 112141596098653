import { ExecutionContext } from '@pec-manager/graphql/lib/graphql.types';
import {
  FORM_DEFINITION_BY_ID,
  FORM_DEFINITION_DIFFS_ON_DYNAMIC_FIELD_CHANGE,
} from '../../graphql/form/mutations';
import {
  ADVANCED_SEARCH_FORM_DEFINITION,
  ADVANCED_SEARCH_FORM_DEFINITION_DIFFS_ON_DYNAMIC_FIELD_CHANGE,
} from '../../graphql/search/mutations';

export interface PagedFormDefinitionCallerCoordinates {
  formDefinitionByIdMutation: any;
  formDefinitionDiffsMutationDefinition: any;
  formDefinitionMutationVariables: any;
}

export const getPagedFormDefinitionCallerCoordinates = (
  executionContext?: ExecutionContext,
  id?: string,
  appId?: string,
  processName?: string,
  initialContext?: any
  // eslint-disable-next-line consistent-return
): PagedFormDefinitionCallerCoordinates => {
  switch (executionContext) {
    case ExecutionContext.START:
    case ExecutionContext.TASK:
      return {
        formDefinitionByIdMutation: FORM_DEFINITION_BY_ID,
        formDefinitionDiffsMutationDefinition:
          FORM_DEFINITION_DIFFS_ON_DYNAMIC_FIELD_CHANGE,
        formDefinitionMutationVariables: {
          id,
          context: executionContext,
          name: processName || '',
          initialContext: initialContext
            ? {
                context: initialContext,
              }
            : {},
        },
      };
    case ExecutionContext.SEARCH:
    default:
      return {
        formDefinitionByIdMutation: ADVANCED_SEARCH_FORM_DEFINITION,
        formDefinitionDiffsMutationDefinition:
          ADVANCED_SEARCH_FORM_DEFINITION_DIFFS_ON_DYNAMIC_FIELD_CHANGE,
        formDefinitionMutationVariables: {
          appId,
          name: processName || '',
          initialContext: initialContext
            ? {
                context: initialContext,
              }
            : {},
        },
      };
  }
};
