import React, { FunctionComponent, useState } from 'react';
import { User } from '@pec-manager/graphql';
import { FlexContainer } from '../../layout/FlexContainer';
import { RoundedTextAvatar } from '../../avatar/RoundedTextAvatar';
import { DynamicSelectUser } from './DynamicSelectUser';
import { RoundedAvatarGroup } from '../../avatar/RoundedAvatarGroup';
import { AddAssignee } from './DynamicSelectUser/AddAssignee';

interface GenericTextFieldProps {
  users: string[];
  setUsers: (el: any) => void;
  openUp?: boolean;
  displayValue?: {
    name: string;
    type: 'GROUP' | 'USER';
    id?: string;
  }[];
  onClickRemove: (id: string) => void;
  width?: string;
  disabled?: boolean;
  left?: boolean;
  widthAvatar?: number;
  heightPlus?: string;
  widthPlus?: string;
}

export const DynamicGenericUserField: FunctionComponent<
  GenericTextFieldProps
> = ({
  users,
  setUsers,
  displayValue,
  openUp,
  onClickRemove,
  width,
  disabled,
  left,
  widthAvatar,
  heightPlus,
  widthPlus,
}) => {
  const [showDynamicSelectResult, setShowDynamicSelectResult] = useState(false);

  return (
    <FlexContainer style={{ position: 'relative', flex: heightPlus ? 0 : 1 }}>
      {displayValue && <RoundedAvatarGroup team={displayValue} />}
      {!disabled && (
        <AddAssignee
          onClickAdd={() =>
            setShowDynamicSelectResult((prevState) => !prevState)
          }
          firstElement={displayValue && displayValue.length > 0}
          showDynamicSelectResult={showDynamicSelectResult}
          heightPlus={heightPlus}
          widthPlus={widthPlus}
        />
      )}
      <DynamicSelectUser
        width={width}
        openUp={openUp}
        users={users}
        setData={setUsers}
        onSelectItemClick={(element) => {
          setShowDynamicSelectResult(false);
          setUsers(element);
        }}
        onClickRemove={onClickRemove}
        onClickOutside={() => setShowDynamicSelectResult(false)}
        svgRender={(user: User) => (
          <RoundedTextAvatar
            text={`${user.firstName} ${user.lastName}`}
            size={widthAvatar || 24}
            fontSize={9.5}
          />
        )}
        showResults={showDynamicSelectResult}
        left={left}
      />
    </FlexContainer>
  );
};
