import React from 'react';

export const componentDecorator = (
  href: string | undefined,
  text:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined,
  key: React.Key | null | undefined
) => (
  <a
    className="linkify__text"
    href={href}
    key={key}
    target="_blank"
    rel="noreferrer"
  >
    {text}
  </a>
);
