import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  FilledForm,
  FilledFormGroup,
  FormDefinition as FormDefinitionType,
  ExecutionContext,
} from '@pec-manager/graphql/lib/graphql.types';
import styled from 'styled-components';
import { FormGroup } from './FormGroup';
import { FlexContainer } from '../../layout/FlexContainer';
import { extractDefaultValuesAsStrings } from '../../../utils/stringUtils';
import { extractFormDefinitionPredicatesMap } from './utils/extractFormDefinitonPredicatesMap';
import { FilledFormComplexField } from '@pec-manager/graphql';

interface FormDefinitionProps {
  executionContext: ExecutionContext;
  formDefinition: FormDefinitionType;
  fillFormDelegateCallback?: (filledForm: FilledForm) => void;
  formDefinitionDiffsOnDynamicFieldChange: (filledForm: FilledForm) => void;
  isModalView?: boolean;
  onFilledFormInitialization?: () => void;
  notifyFieldCoordinateForError: (
    fieldCoordinate: string,
    hasError: boolean
  ) => void;
  maxHeight100?: boolean;
  setFileLocaleView?: (fileLocaleView?: string) => void;
  checkRequired?: boolean;
  initialContext?: any;
  columnsForm: number;
}

export const FormDefinition: FunctionComponent<FormDefinitionProps> = ({
  executionContext,
  formDefinition,
  fillFormDelegateCallback,
  formDefinitionDiffsOnDynamicFieldChange,
  isModalView,
  onFilledFormInitialization,
  notifyFieldCoordinateForError,
  maxHeight100,
  setFileLocaleView,
  checkRequired,
  initialContext,
  columnsForm,
}) => {
  const [filledForm, setFilledForm] = useState<FilledForm>({
    definitionId: '',
    filledGroups: [],
  });

  const [selectOpened, setSelectOpened] = useState('');

  useEffect(() => {
    if (onFilledFormInitialization && filledForm.definitionId) {
      onFilledFormInitialization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filledForm.definitionId]);

  useEffect(() => {
    if (fillFormDelegateCallback) {
      fillFormDelegateCallback(filledForm);
    }
  }, [filledForm, fillFormDelegateCallback]);

  const formDefinitionPredicatesMap = useMemo(() => {
    return extractFormDefinitionPredicatesMap(formDefinition);
  }, [formDefinition]);

  const fillField = (
    groupId: string,
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean,
    complexVariables?: FilledFormComplexField[]
  ) => {
    setFilledForm(
      setFilledFormDelegate(
        groupId,
        fieldId,
        data,
        refetchDefinition,
        formDefinitionDiffsOnDynamicFieldChange,
        complexVariables
      )
    );
    if (!refetchDefinition) {
      if (complexVariables) {
        const fieldCoordinate = `${groupId}__${fieldId}`;

        notifyFieldCoordinateForError(
          fieldCoordinate,
          formDefinitionPredicatesMap[fieldCoordinate](
            data,
            complexVariables[0].filledFields[0] || []
          )
        );
      } else {
        const fieldCoordinate = `${groupId}__${fieldId}`;

        notifyFieldCoordinateForError(
          fieldCoordinate,
          formDefinitionPredicatesMap[fieldCoordinate](data)
        );
      }
    }
  };

  useEffect(() => {
    if (formDefinition) {
      // onceInit();
      initPrefilledFormFromDefinition(
        formDefinition,
        setFilledForm,
        notifyFieldCoordinateForError,
        formDefinitionPredicatesMap,
        filledForm
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefinition]);

  return isModalView ? (
    <ContentContainerModal
      column
      isModalView={isModalView}
      maxHeight100={maxHeight100}
    >
      {filledForm.definitionId &&
        formDefinition.fieldGroups
          .filter((group) => !group.isHidden)
          .map((group) => (
            <FormGroup
              executionContext={executionContext}
              isModalView
              key={group.id}
              group={group}
              filledForm={filledForm}
              fillField={fillField}
              selectOpened={selectOpened}
              setSelectOpened={setSelectOpened}
              setFileLocaleView={setFileLocaleView}
              checkRequired={checkRequired}
              initialContext={initialContext}
              columnsForm={columnsForm}
            />
          ))}
    </ContentContainerModal>
  ) : filledForm.definitionId &&
    formDefinition.fieldGroups.filter((group) => !group.isHidden).length ===
      0 ? (
    <></>
  ) : (
    <ContentContainer column className="formContainer">
      {filledForm.definitionId &&
        formDefinition.fieldGroups
          .filter((group) => !group.isHidden)
          .map((group) => (
            <FormGroup
              executionContext={executionContext}
              key={group.id}
              group={group}
              filledForm={filledForm}
              fillField={fillField}
              selectOpened={selectOpened}
              setSelectOpened={setSelectOpened}
              setFileLocaleView={setFileLocaleView}
              checkRequired={checkRequired}
              initialContext={initialContext}
              columnsForm={columnsForm}
            />
          ))}
    </ContentContainer>
  );
};

function setFilledFormDelegate(
  groupId: string,
  fieldId: string,
  data: string[],
  refetchDefinition: boolean | undefined,
  formDefinitionDiffsOnDynamicFieldChange: (filledForm: FilledForm) => void,
  complexVariables?: FilledFormComplexField[]
): React.SetStateAction<FilledForm> {
  return (prevState) => {
    const group = prevState.filledGroups.find((g) => g.id === groupId);
    if (!group) {
      return {
        definitionId: prevState.definitionId,
        filledGroups: [
          ...prevState.filledGroups,
          complexVariables
            ? {
                id: groupId,
                filledFields: [
                  {
                    ...complexVariables.map((c) => c.filledFields[0])[0],
                    filledValues: data,
                  },
                ],
              }
            : {
                id: groupId,
                filledFields: [
                  {
                    id: fieldId,
                    filledValues: data,
                    complexValues: complexVariables,
                  },
                ],
              },
        ],
      };
    }

    const mergeFilledFields = group.filledFields.find((f) => f.id === fieldId);

    const updatedGroup: FilledFormGroup = {
      id: group.id,
      filledFields: complexVariables
        ? [
            ...group.filledFields.filter((f) => f.id !== fieldId),
            ...complexVariables[0].filledFields.filter((f) => f.id !== groupId),
          ]
        : [
            ...group.filledFields.filter((f) => f.id !== fieldId),
            {
              id: fieldId,
              filledValues: data || mergeFilledFields?.filledValues,
              complexValues: complexVariables,
            },
          ],
    };

    const newFilledForm = {
      definitionId: prevState.definitionId,
      filledGroups: [
        ...prevState.filledGroups.filter((g) => g.id !== groupId),
        updatedGroup,
      ],
    };

    refetchDefinition && formDefinitionDiffsOnDynamicFieldChange(newFilledForm);
    return newFilledForm;
  };
}

function initPrefilledFormFromDefinition(
  formDefinition: any,
  setFilledForm: React.Dispatch<React.SetStateAction<FilledForm>>,
  notifyFieldCoordinateForError: (
    fieldCoordinate: string,
    hasError: boolean
  ) => void,
  formDefinitionPredicatesMap: any,
  filledForm?: FilledForm
) {
  const prefilledForm: FilledForm = {
    definitionId: formDefinition.id,
    filledGroups: [],
  };

  for (const fieldGroup of formDefinition.fieldGroups) {
    const prefilledGroup: FilledFormGroup = {
      id: fieldGroup.id,
      filledFields: [],
    };

    const filledFormGroup = filledForm?.filledGroups.find(
      (g) => g.id === fieldGroup.id
    );

    for (const field of fieldGroup.fields) {
      if (field.isEditable) {
        const extractedDefaultValues = extractDefaultValuesAsStrings(
          field.defaultValues
        );

        const extractFilledValue = filledFormGroup?.filledFields.find(
          (v) => v.id === field.id
        )?.filledValues;

        const fieldCoordinate = `${fieldGroup.id}__${field.id}`;

        if (field.errors.length > 0) {
          notifyFieldCoordinateForError(fieldCoordinate, true);
        } else {
          notifyFieldCoordinateForError(
            fieldCoordinate,
            formDefinitionPredicatesMap[fieldCoordinate](
              extractedDefaultValues,
              true
            )
          );
        }

        const exDef =
          extractedDefaultValues.length > 0
            ? extractedDefaultValues
            : extractFilledValue;

        prefilledGroup.filledFields.push({
          id: field.id,
          filledValues: exDef,
        });
      }
    }

    prefilledForm.filledGroups.push(prefilledGroup);
    setFilledForm(prefilledForm);
  }
}

const ContentContainerModal = styled(FlexContainer)<{
  isModalView?: boolean;
  maxHeight100?: boolean;
}>`
  min-height: 500px;
  padding: 0 24px;
  width: 100%;
  overflow-y: scroll;

  ${({ isModalView, maxHeight100 }) =>
    isModalView && !maxHeight100 ? 'max-height: 900px' : 'height: 100%'};

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  }

  @media (max-width: 1040px) {
    max-height: 100%;
  }
`;

const ContentContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  margin-bottom: 24px;

  @media (max-width: 990px) {
    padding: 8px;
  }
`;
