import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../layout/FlexContainer';
import { RoundedTextAvatar } from '../avatar/RoundedTextAvatar';
import { Text } from './Text';
import { Theme } from '../../theme';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { isToday } from '../../utils/stringUtils';

interface WithIconNameDateWrapperProps {
  date: Date;
  fullName: string;
  overrideIconText?: string;
  children?: React.ReactNode;
}

export const WithIconNameDateWrapper: FunctionComponent<
  WithIconNameDateWrapperProps
> = ({ fullName, overrideIconText, date, children }) => (
  <FlexContainer column>
    <ContainerTop alignItems="center">
      <RoundedTextAvatar
        text={overrideIconText || fullName}
        size={24}
        fontSize={9}
      />
      <Text
        text={fullName}
        fontSize="14px"
        lineHeight="21px"
        color={Theme.colors.c505050}
      />
      <Text
        text={isToday(date as any) ? 'todayAt' : 'completeDate'}
        translationArgs={{
          date: getDateAsString(date as any),
          time: getTimeAsString(date as any),
        }}
        fontSize="14px"
        lineHeight="21px"
        color={Theme.colors.c505050}
        fontWeight="300"
      />
    </ContainerTop>
    <ContainerMessage column justifyContent="center">
      {children}
    </ContainerMessage>
  </FlexContainer>
);

const ContainerTop = styled(FlexContainer)`
  & > div {
    margin-right: 16px;
  }
  & > span {
    margin-right: 8px;
  }
`;
const ContainerMessage = styled(FlexContainer)`
  margin: 8px 40px 16px 40px;
`;
