import React, { FunctionComponent } from 'react';
import {
  ExecutionProgressStatus,
  ExecutionProgressStep,
} from '@pec-manager/graphql';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Text } from '../common/Text';
import { Clock, Close, Rolling, Success } from '../../svg';
import { SvgIcon } from '../common/SvgIcon';
import styled from 'styled-components';
import { FlexContainer } from '../layout/FlexContainer';
import { Theme } from '../../theme';

interface StepProgressBarProps {
  optionList: ExecutionProgressStep[];
}

const mapExecutionStatus = (status: ExecutionProgressStatus) => {
  switch (status) {
    case ExecutionProgressStatus.COMPLETED:
      return {
        backgroundColor: '#ffffff',
        icon: <SvgIcon svg={<Success />} />,
        state: 'executionStateCompleted',
      };
    case ExecutionProgressStatus.RUNNING:
      return {
        backgroundColor: '#2F80ED',
        icon: <SvgIcon svg={<Rolling />} />,
        state: 'executionStateRunning',
      };
    case ExecutionProgressStatus.FAILED:
      return {
        backgroundColor: '#EB5757',
        icon: (
          <SvgIcon
            svg={<Close />}
            height="30px"
            width="30px"
            color="#fff"
            center
          />
        ),
        state: 'executionStateFailed',
      };
    case ExecutionProgressStatus.PENDING:
    default:
      return {
        backgroundColor: '#ffff',
        icon: <SvgIcon svg={<Clock />} color="#BDBDBD" />,
        state: 'executionStateWaiting',
      };
  }
};

export const StepProgressBar: FunctionComponent<StepProgressBarProps> = ({
  optionList,
}) => (
  <VerticalTimelineStyled
    lineColor="#BDBDBD"
    layout="1-column-left"
    itemLength={optionList.length}
  >
    {optionList.map((option) => (
      <VerticalTimelineElementStyled
        key={option.name + option.status}
        className="vertical-timeline-element"
        iconStyle={{
          height: '40px',
          width: '40px',
          backgroundColor: mapExecutionStatus(option.status).backgroundColor,
          marginTop: '10px',
        }}
        icon={mapExecutionStatus(option.status).icon}
      >
        <FlexContainer column>
          <Text text={option.name} fontSize="16px" lineHeight="16px" />
          <Text
            text={mapExecutionStatus(option.status).state}
            fontSize="12px"
            lineHeight="12px"
            color={Theme.colors.c505050}
            style={{ marginTop: '8px' }}
          />
        </FlexContainer>
      </VerticalTimelineElementStyled>
    ))}
  </VerticalTimelineStyled>
);

const VerticalTimelineStyled = styled(VerticalTimeline)<{ itemLength: number }>`
  ::before {
    top: 55px;
    height: calc(100% - 140px);
  }
`;

const VerticalTimelineElementStyled = styled(VerticalTimelineElement)`
  margin: 0 !important;

  .vertical-timeline-element-icon svg {
    margin-left: -20px !important;
    margin-top: -20px !important;
  }
  .vertical-timeline-element-content {
    box-shadow: none !important;
  }
`;
