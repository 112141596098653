import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { NotificationBanner } from '../../redux/notification/sidebar/reducers';
import { FlexContainer } from '../layout/FlexContainer';
import { SvgIcon } from './SvgIcon';
import { Text } from './Text';
import { Theme } from '../../theme';
import { SuccessAlert, WarningAlert } from '../../svg';

interface NotificationBannersProps {
  notifications: NotificationBanner[];
}

export const NotificationBanners: FunctionComponent<
  NotificationBannersProps
> = ({ notifications }) => (
  <Container>
    <AnimatePresence>
      {notifications.map((notification) => (
        <motion.div
          key={notification.id}
          initial={{
            opacity: 0,
            y: 5,
          }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 5 }}
          transition={{
            type: 'just',
          }}
        >
          <Notification alignItems="center">
            <SvgIcon
              height="22px"
              width="40px"
              svg={
                notification.type === 'success' ? (
                  <SuccessAlert />
                ) : (
                  <WarningAlert />
                )
              }
            />
            <ContainerTitle column>
              <TitleText
                text={notification.title}
                fontSize="14px"
                lineHeight="24px"
              />
              {notification.content && (
                <ContentText
                  text={notification.content}
                  translationArgs={notification.variables}
                  fontSize="12px"
                  lineHeight="22px"
                  color={Theme.colors.c505050}
                  fontWeight="300"
                />
              )}
            </ContainerTitle>
          </Notification>
        </motion.div>
      ))}
    </AnimatePresence>
  </Container>
);

const Container = styled.div`
  position: absolute;
  bottom: 30px;
  left: 74px;
  z-index: 100;
  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Notification = styled(FlexContainer)`
  width: 412px;
  height: 108px;
  background: #ffffff;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 16px 24px;
`;

const ContainerTitle = styled(FlexContainer)`
  margin-left: 20px;
  width: calc(100% - 60px);
`;

const TitleText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContentText = styled(Text)`
  margin-top: 5px;
`;
