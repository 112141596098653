import React, { FunctionComponent, useState } from 'react';
import { RecordContainer } from './RecordContainer';
import { Table } from '@pec-manager/graphql';
import { useLocation } from 'react-router-dom';
import { ListRecords } from './ListRecords';
import { RecordDetails } from './RecordDetails';

interface BusinessKpiProps {
  notMobile?: boolean;
}

export const Records: FunctionComponent<BusinessKpiProps> = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [forceRefetch, setForceRefetch] = useState<boolean>(false);

  const [table, setTable] = useState<Table | undefined>(undefined);
  const [appCode, setAppCode] = useState<string>('');
  const [searchRecords, setSearchRecords] = useState<string>('');
  const [initialContextDetail, setInitialContextDetail] =
    useState<any>(undefined);

  return (
    <RecordContainer
      setTable={setTable}
      setAppCode={setAppCode}
      table={table}
      initialContextDetail={initialContextDetail}
      setInitialContextDetail={setInitialContextDetail}
      detailsView={!!searchParams.get('recordId')}
      setForceRefetch={setForceRefetch}
      setSearchRecords={setSearchRecords}
    >
      {searchParams.get('recordId') ? (
        <RecordDetails
          appCode={appCode}
          tableName={table?.name}
          setInitialContextDetail={setInitialContextDetail}
          forceRefetch={forceRefetch}
          setForceRefetch={setForceRefetch}
        />
      ) : (
        <ListRecords
          searchRecords={searchRecords}
          table={table}
          appCode={appCode}
          setForceRefetch={setForceRefetch}
          forceRefetch={forceRefetch}
        />
      )}
    </RecordContainer>
  );
};
