import { useQuery, useSubscription } from '@apollo/client';
import { TASK_PROGRESS_EVENT_RECEIVED } from '../../graphql/form/subscriptions';
import { KeycloakService } from '../../services/KeycloakService';
import { useEffect } from 'react';
import { TaskProgressEvent } from '@pec-manager/graphql';
import {
  addInitialTasksProgressFlowAction,
  addTaskProgressFlowAction,
} from '../../redux/notification/sidebar/actions';
import { GET_TASK_PROGRESS_FLOWS } from '../../graphql/form/queries';
import { useDispatch } from 'react-redux';

export const useJoiningTaskReceived = () => {
  const dispatch = useDispatch();

  const { data: processFlows } = useQuery(GET_TASK_PROGRESS_FLOWS);
  const { data: progressEvent } = useSubscription(
    TASK_PROGRESS_EVENT_RECEIVED,
    {
      variables: {
        correlationId: KeycloakService.getToken(),
      },
      shouldResubscribe: false,
    }
  );

  useEffect(() => {
    if (processFlows) {
      dispatch(
        addInitialTasksProgressFlowAction(processFlows.getTaskProgressFlows)
      );
    }
  }, [dispatch, processFlows]);

  useEffect(() => {
    if (progressEvent) {
      const event =
        progressEvent.taskProgressEventReceived as TaskProgressEvent;
      dispatch(
        addTaskProgressFlowAction({
          taskId: event.taskId,
          taskDisplayName: event.taskDisplayName,
          progressSteps: [event.progressStep],
          taskCategory: event.taskCategory,
        })
      );
    }
  }, [dispatch, progressEvent]);
};
