import React, { useEffect, useRef } from 'react';
import { Text } from '../Text';
import styled from 'styled-components';
import {
  extractBeforeBetweenAndAfterBoldTags,
  removeTagsFromHtml,
} from './utils';

interface SuggestionItemProps {
  text: string;
  setHovered: (index: string | undefined) => void;
  hovered: string | undefined;
  onMouseDown?: (
    eventDown: React.MouseEvent<HTMLDivElement>,
    text: string
  ) => void;
  cursor?: number;
  index?: number;
}

export const SuggestionItem: React.FC<SuggestionItemProps> = ({
  text,
  hovered,
  setHovered,
  cursor,
  onMouseDown,
  index,
}) => {
  const ref = useRef(null);
  const { between, before, after } = extractBeforeBetweenAndAfterBoldTags(text);

  useEffect(() => {
    if (!hovered && cursor === index && ref.current) {
      // @ts-ignore
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [cursor, hovered, index, text]);
  return (
    <SuggestionContainer
      ref={ref}
      selected={cursor === index}
      onMouseDown={(e) =>
        onMouseDown ? onMouseDown(e, removeTagsFromHtml(text)) : {}
      }
      onMouseEnter={() => setHovered(text)}
      onMouseLeave={() => setHovered(undefined)}
    >
      <Text text={before} fontSize="14px" lineHeight="14px" />
      <Text
        text={between}
        fontSize="14px"
        lineHeight="14px"
        fontWeight="bold"
      />
      <Text text={after} fontSize="14px" lineHeight="14px" />
    </SuggestionContainer>
  );
};

const SuggestionContainer = styled.div<{ selected?: boolean }>`
  height: 30px;
  width: 100%;
  padding-left: 8px;
  ${({ selected, theme }) =>
    selected && `background: ${theme.colors.transparentGrey};`}
  &:hover {
    background: ${({ theme }) => theme.colors.transparentGrey};
    cursor: pointer;
  }
`;
