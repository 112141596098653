import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BlobRef } from '@pec-manager/graphql';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Text } from '../../../common/Text';
import { FlexContainer } from '../../../layout/FlexContainer';
import { AttachmentPreview } from '../../../common/AttachmentPreview';
import { GET_RECENT_BLOB_REFS } from '../../../../graphql/document/queries';
import { HorizontalLoader } from '../../../loader/HorizontalLoader';
import { Theme } from '../../../../theme';

interface RecentAttachmentsSectionProps {
  setBlobRefChoices: (f: BlobRef[]) => void;
  blobRefChoices: BlobRef[];
}

export const RecentAttachmentsSection: FunctionComponent<
  RecentAttachmentsSectionProps
> = ({ setBlobRefChoices, blobRefChoices }) => {
  const { appId } = useParams();

  const { data: blobRefList, loading } = useQuery(GET_RECENT_BLOB_REFS, {
    variables: {
      appId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <div style={{ height: '13px', display: 'flex' }}>
        <Text text="file" fontSize="12px" lineHeight="18px" />
      </div>
      <ContainerAttachments wrapContent>
        {loading && (
          <HorizontalLoader size="l" top="33px" color={Theme.colors.c4C9AFF} />
        )}
        {!loading && blobRefList.getRecentBlobRefs?.pageElements.length > 0 ? (
          blobRefList.getRecentBlobRefs?.pageElements.map(
            (blobRef: BlobRef) => {
              if (blobRef)
                return (
                  <div
                    key={blobRef?.id}
                    style={{ marginRight: '37px' }}
                    onClick={() => {
                      if (blobRefChoices.find((c) => c.id === blobRef!.id)) {
                        setBlobRefChoices(
                          blobRefChoices.filter((c) => c.id !== blobRef!.id)
                        );
                      } else {
                        setBlobRefChoices([...blobRefChoices, blobRef!]);
                      }
                    }}
                  >
                    <AttachmentPreview
                      shrinkYPx={0}
                      attachment={blobRef!}
                      selected={
                        !!blobRefChoices.find((c) => c.id === blobRef!.id)
                      }
                    />
                  </div>
                );
              else return null;
            }
          )
        ) : (
          <EmptyAttachments
            text="notAttachment"
            fontSize="13px"
            lineHeight="13px"
            color={Theme.colors.c838383}
          />
        )}
      </ContainerAttachments>
    </Container>
  );
};

const Container = styled.div`
  padding-left: 16px;
  margin: 24px 0 12px 0;
`;

const ContainerAttachments = styled(FlexContainer)`
  padding: 12px 0;
`;

const EmptyAttachments = styled(Text)`
  text-align: center;
`;
