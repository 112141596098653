import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { KeycloakService } from '../../services/KeycloakService';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ProgressFlows } from '../../inbox/mail/ProgressFlows';
import { Text } from '../../components/common/Text';
import { Select } from '../../components/select';
import { Application, ApplicationOverview, Period } from '@pec-manager/graphql';
import { Theme } from '../../theme';
import {
  AnalyticsContainerProps,
  TabAnalyticsEnum,
} from './AnalyticsContainer';
import { useLazyQuery, useQuery } from '@apollo/client';
import { PROCESS_LIST } from '../../graphql/kpi/queries';
import { useParams } from 'react-router-dom';
import { GET_APPLICATION_OVERVIEWS } from '../../graphql/application/queries';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';

export const AnalyticsContainerHeader: FunctionComponent<
  AnalyticsContainerProps
> = ({
  tabAnalytics,
  setPeriodSelected,
  periodSelected,
  setTabAnalytics,
  processNameSelected,
  setProcessNameSelected,
}) => {
  const { appId } = useParams();
  const [processSelected, setProcessSelected] = useState<
    Application | undefined
  >(undefined);

  const { data: dataApplication } = useQuery(GET_APPLICATION_OVERVIEWS);

  useEffect(() => {
    if (dataApplication) {
      setProcessSelected(
        dataApplication.getApplicationOverviews.find(
          (app: ApplicationOverview) => app?.application?.id === appId
        )?.application
      );
    }
  }, [appId, dataApplication]);

  const [processListQuery, { data }] = useLazyQuery(PROCESS_LIST, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (processSelected?.name) {
      processListQuery({
        variables: {
          appName: processSelected.name,
        },
      }).then((result) => {
        if (result.data?.processList) {
          setProcessNameSelected(
            result.data.processList[0] || 'noDataAvailable'
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [processSelected]);

  const menuItems = useMemo(() => {
    if (data?.processList)
      return data.processList?.map((process: any) => ({
        displayValue: process,
        value: process,
      }));
    return [];
  }, [data]);

  return (
    <Container column>
      <TopContainer alignItems="center" justifyContent="space-between">
        <TitleWithChooseApp />
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </TopContainer>
      <BottomContainer
        alignItems="center"
        justifyContent="space-between"
        column
      >
        <TitleContainer>
          <LeftContainer alignItems="center">
            <Text
              text="analytics"
              fontSize="18px"
              lineHeight="27px"
              fontWeight="500"
              style={{ marginRight: '16px' }}
            />
            <Select
              width="250px"
              defaultChoice={{
                value: processNameSelected,
                label: processNameSelected,
              }}
              borderContainer={`2px solid ${Theme.colors.cDFE1E6}`}
              borderRadius="6px"
              choices={menuItems}
              onChoiceSelected={(choice) => {
                setProcessNameSelected(choice.value);
              }}
              disabled={!processSelected}
              zIndex={2}
              lineHeightText="16px"
              fontSizeText="14px"
            />
          </LeftContainer>
          <RightContainer justifyContent="flex-end">
            <ProgressFlows />
          </RightContainer>
        </TitleContainer>
        <TabContainer>
          <LeftTabContainer>
            <TabItem
              selected={tabAnalytics === TabAnalyticsEnum.PERFORMANCE}
              onClick={() => setTabAnalytics(TabAnalyticsEnum.PERFORMANCE)}
            >
              <Text
                text="processPerformance"
                fontSize="12px"
                lineHeight="22px"
                color={
                  tabAnalytics === TabAnalyticsEnum.PERFORMANCE
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </TabItem>
            <TabItem
              selected={tabAnalytics === TabAnalyticsEnum.WORKLOAD}
              onClick={() => setTabAnalytics(TabAnalyticsEnum.WORKLOAD)}
            >
              <Text
                text="processWorkload"
                fontSize="12px"
                lineHeight="22px"
                color={
                  tabAnalytics === TabAnalyticsEnum.WORKLOAD
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </TabItem>
            {/*<TabItem
              selected={tabAnalytics === TabAnalyticsEnum.BUSINESS}
              onClick={() => setTabAnalytics(TabAnalyticsEnum.BUSINESS)}
            >
              <Text
                text="processBusiness"
                fontSize="12px"
                lineHeight="22px"
                color={
                  tabAnalytics === TabAnalyticsEnum.BUSINESS
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </TabItem>*/}
          </LeftTabContainer>
          <RightTabContainer alignItems="center">
            <ClickableText onClick={() => setPeriodSelected(Period.DAY)}>
              <Text
                text="Day"
                fontSize="12px"
                lineHeight="22px"
                color={
                  periodSelected === Period.DAY
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </ClickableText>
            <ClickableText onClick={() => setPeriodSelected(Period.WEEK)}>
              <Text
                text="Week"
                fontSize="12px"
                lineHeight="22px"
                color={
                  periodSelected === Period.WEEK
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </ClickableText>
            <ClickableText onClick={() => setPeriodSelected(Period.MONTH)}>
              <Text
                text="Month"
                fontSize="12px"
                lineHeight="22px"
                color={
                  periodSelected === Period.MONTH
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </ClickableText>
            <ClickableText onClick={() => setPeriodSelected(Period.YEAR)}>
              <Text
                text="Year"
                fontSize="12px"
                lineHeight="22px"
                color={
                  periodSelected === Period.YEAR
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </ClickableText>
          </RightTabContainer>
        </TabContainer>
      </BottomContainer>
      {/* <ContainerBottom alignItems="center" justifyContent="flex-end">
            <SvgClickable onClick={() => setOpenMenu((prevState) => !prevState)}>
              <SvgIcon
                svg={<More />}
                color={Theme.colors.c2186C6}
                height="20px"
                width="20px"
              />
              {openMenu && (
                <MenuChoices
                  containerMenuProps={{ right: '6px', top: '104px' }}
                  choices={[
                    {
                      text: 'NonSoCosaCiVa',
                      onClick: () => {},
                    },
                  ]}
                />
              )}
            </SvgClickable>
          </ContainerBottom> */}
    </Container>
  );
};

const TabItem = styled(FlexContainer)<{ selected: boolean }>`
  cursor: pointer;
  padding: 0 16px;
  height: 56px;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  ${({ selected }) =>
    selected &&
    `
    border-bottom: 4px solid ${Theme.colors.c5B84EF};
    color: ${Theme.colors.c5B84EF} !important;
  `}
`;

const Container = styled(FlexContainer)`
  width: 100%;
  height: 156px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  height: 64px;
  padding: 0 16px;
`;

const LeftContainer = styled(FlexContainer)`
  max-width: 465px;
  width: 100%;
  height: 56px;
  padding-left: 16px;
`;

const RightContainer = styled(FlexContainer)`
  width: 100%;
  padding-right: 20px;
  margin-right: -6px;
`;

const TopContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

const TitleContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const TabContainer = styled(FlexContainer)`
  width: 100%;
  height: 56px;
`;

const LeftTabContainer = styled(FlexContainer)`
  width: 50%;
  height: 56px;
`;

const RightTabContainer = styled(FlexContainer)`
  height: 56px;
  margin-left: auto;
`;

const ClickableText = styled.div`
  cursor: pointer;
  height: 30px;
  margin-left: 24px;
`;

/* const SvgClickable = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  margin-right: 4px;
  cursor: pointer;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`; */
