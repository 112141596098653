import {
  ResourceContext,
  ResourceContextSection,
  ResourceContextType,
} from '@pec-manager/graphql';

export const getNotificationUrl = (context: ResourceContext, appId: string) => {
  switch (context?.type) {
    case ResourceContextType.DOCUMENT:
      return `/${appId}/sintropi/document?${
        context.document?.documentId
          ? `documentId=${context.document.documentId}`
          : `folderId=${context.document!.folderId}`
      }`;
    case ResourceContextType.INBOX:
      if (context.inbox) {
        switch (context.inbox.contextSection) {
          case ResourceContextSection.BY_ASSIGNMENT:
            return `/${appId}/inbox?sectionId=MAIN&elementId=INBOX&dropDownId=${context.inbox.assignmentType}&mailId=${context.resourceId}`;
          case ResourceContextSection.BY_STATE:
            return `/${appId}/inbox?sectionId=MAIN&elementId=${
              context.inbox.mailState ?? 'INBOX'
            }&mailId=${context.resourceId}`;
          case ResourceContextSection.ARCHIVED:
            return `/${appId}/inbox?sectionId=MAIN&elementId=ARCHIVE&dropDownId=${context.inbox.archiveId}&mailId=${context.resourceId}`;
          case ResourceContextSection.DEFAULT:
          default:
            return `/${appId}/`;
        }
      }
      break;
    case ResourceContextType.SINTROPI:
      if (context.sintropi) {
        switch (context.sintropi.contextSection) {
          case ResourceContextSection.BY_ASSIGNMENT:
            return `/${appId}/sintropi?sectionId=MAIN&elementId=SINTROPI${
              context.sintropi.assignmentType
                ? `&dropDownId=${context.sintropi.assignmentType}`
                : ''
            }&taskId=${context.resourceId}`;
          case ResourceContextSection.BY_STATE:
            return `/${appId}/sintropi?sectionId=MAIN&elementId=${
              context.sintropi.taskState ?? 'SINTROPI'
            }&taskId=${context.resourceId}`;
          case ResourceContextSection.ARCHIVED:
            return `/${appId}/sintropi/document`;
          case ResourceContextSection.DEFAULT:
          default:
            return `/${appId}/`;
        }
      }
      break;
    default:
      return `/${appId}/`;
  }
  return `/${appId}/`;
};
