import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BoxAnalytics } from './BoxAnalytics';
import { useLazyQuery } from '@apollo/client';
import { PROCESS_PERFORMANCE_KPI } from '../../graphql/kpi/queries';
import { Period, ProcessPerformanceKpiResponse } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { AroundLoader } from '../../components/loader/AroundLoader';
import { GaugeGeneric } from '../../components/common/GaugeGeneric';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { GaugeGenericPercentage } from '../../components/common/GaugeGenericPercentage';
import { CandlestickChart } from './CandlestickChart';
import { LineChartPerformance } from './LineChartPerformance';
import { BoardItemtype } from './index';
import { transformSecondInDayHoursMinutes } from '../../utils/dateUtils';

interface TabPerformanceProps {
  periodSelected: Period;
  processNameSelected?: string;
  setBoardItems: (boardItems: BoardItemtype[]) => void;
  notMobile?: boolean;
}

export const TabPerformance: FunctionComponent<TabPerformanceProps> = ({
  periodSelected,
  processNameSelected,
  notMobile,
  setBoardItems,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [processPerformance, setProcessPerformance] = React.useState<
    ProcessPerformanceKpiResponse | undefined
  >(undefined);
  const [processPerformanceKpiQuery, { loading }] = useLazyQuery(
    PROCESS_PERFORMANCE_KPI,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    }
  );

  useEffect(() => {
    if (processNameSelected) {
      processPerformanceKpiQuery({
        variables: {
          period: periodSelected,
          processName: processNameSelected,
        },
      }).then((result) => {
        if (result.data?.processPerformanceKpi) {
          setProcessPerformance(result.data.processPerformanceKpi);
          setBoardItems([
            {
              title:
                result.data.processPerformanceKpi.totalCompletedExecutionsLabel,
              value:
                result.data.processPerformanceKpi.totalCompletedExecutionsValue,
            },
            {
              title: result.data.processPerformanceKpi.processLeadTimeLabel,
              value: transformSecondInDayHoursMinutes(
                result.data.processPerformanceKpi.processLeadTimeValue
              ),
            },
            {
              title:
                result.data.processPerformanceKpi.processDueDateComplianceLabel,
              value:
                result.data.processPerformanceKpi
                  .processDueDateComplianceValue + '%',
            },
          ]);
        }

        if (ref.current) {
          setWidth(((ref.current as any)?.offsetWidth / 10) * 6);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodSelected, processNameSelected, processPerformanceKpiQuery, ref]);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setWidth((ref.current?.offsetWidth / 10) * 6);
    }
  }, [ref]);

  return (
    <Container ref={ref}>
      {processPerformance && !loading ? (
        <>
          <BoxAnalytics
            firstElement
            title={processPerformance.processLeadKpiDescription.title}
          >
            <Text
              text={processPerformance.processLeadKpiDescription.description}
              fontSize="12px"
              lineHeight="16px"
              color={Theme.colors.c505050}
              style={{ marginBottom: '16px' }}
            />
            <FlexContainer wrapContent alignItems="center">
              <GaugeGeneric
                title={processPerformance.processLeadTimeKpi.name}
                maxValue={processPerformance.processLeadTimeKpi.value.max}
                defaultValue={processPerformance.processLeadTimeKpi.value.avg}
                minValue={processPerformance.processLeadTimeKpi.value.min}
                averageMedium={processPerformance.processLeadTimeKpi.value.avg}
                scale={
                  processPerformance.processLeadTimeKpi.value.avg +
                  processPerformance.processLeadTimeKpi.value.stdDev
                }
                percentageWidth={33.3}
              />
              {processPerformance && (
                <CandlestickChart
                  processPerformance={processPerformance}
                  width={notMobile ? width : '100%'}
                />
              )}
            </FlexContainer>
            {processPerformance.taskDelayTimeKpi &&
              processPerformance.taskDelayTimeKpi.length > 0 && (
                <BoxAnalytics
                  title={processPerformance.taskDelayKpiDescription.title}
                  collapsed
                >
                  <Text
                    text={
                      processPerformance.taskDelayKpiDescription.description
                    }
                    fontSize="12px"
                    lineHeight="16px"
                    color={Theme.colors.c505050}
                    style={{ marginBottom: '16px' }}
                  />
                  <FlexContainer wrapContent>
                    {processPerformance.taskDelayTimeKpi.map((process: any) => (
                      <GaugeGeneric
                        key={process.name}
                        title={process.name}
                        maxValue={process.value.max}
                        defaultValue={process.value.avg}
                        minValue={process.value.min}
                        averageMedium={
                          processPerformance.processLeadTimeKpi.value.avg
                        }
                        percentageWidth={33.3}
                        scale={
                          processPerformance.processLeadTimeKpi.value.avg +
                          processPerformance.processLeadTimeKpi.value.stdDev
                        }
                      />
                    ))}
                  </FlexContainer>
                </BoxAnalytics>
              )}
            {processPerformance.taskLeadTimeKpi &&
              processPerformance.taskLeadTimeKpi.length > 0 && (
                <BoxAnalytics
                  title={processPerformance.taskLeadTimeKpiDescription.title}
                  collapsed
                >
                  <Text
                    text={
                      processPerformance.taskLeadTimeKpiDescription.description
                    }
                    fontSize="12px"
                    lineHeight="16px"
                    color={Theme.colors.c505050}
                    style={{ marginBottom: '16px' }}
                  />
                  <FlexContainer wrapContent>
                    {processPerformance.taskLeadTimeKpi.map((process: any) => (
                      <GaugeGeneric
                        key={process.name}
                        title={process.name}
                        maxValue={process.value.max}
                        defaultValue={process.value.avg}
                        minValue={process.value.min}
                        percentageWidth={33.3}
                        averageMedium={
                          processPerformance.processLeadTimeKpi.value.avg
                        }
                        scale={
                          processPerformance.processLeadTimeKpi.value.avg +
                          processPerformance.processLeadTimeKpi.value.stdDev
                        }
                      />
                    ))}
                  </FlexContainer>
                </BoxAnalytics>
              )}
            {processPerformance.milestoneLeadTimeKpi &&
              processPerformance.milestoneLeadTimeKpi.length > 0 && (
                <BoxAnalytics
                  title={
                    processPerformance.milestoneLeadTimeKpiDescription.title
                  }
                  collapsed
                >
                  <Text
                    text={
                      processPerformance.milestoneLeadTimeKpiDescription
                        .description
                    }
                    fontSize="12px"
                    lineHeight="16px"
                    color={Theme.colors.c505050}
                    style={{ marginBottom: '16px' }}
                  />
                  <FlexContainer wrapContent>
                    {processPerformance.milestoneLeadTimeKpi.map(
                      (process: any) => (
                        <GaugeGeneric
                          key={process.name}
                          title={process.name}
                          maxValue={process.value.max}
                          defaultValue={process.value.avg}
                          minValue={process.value.min}
                          percentageWidth={33.3}
                          averageMedium={
                            processPerformance.processLeadTimeKpi.value.avg
                          }
                          scale={
                            processPerformance.processLeadTimeKpi.value.avg +
                            processPerformance.processLeadTimeKpi.value.stdDev
                          }
                        />
                      )
                    )}
                  </FlexContainer>
                </BoxAnalytics>
              )}
          </BoxAnalytics>
          <BoxAnalytics
            title={processPerformance.processSlaComplianceKpiDescription.title}
            firstElement
          >
            <Text
              text={
                processPerformance.processSlaComplianceKpiDescription
                  .description
              }
              fontSize="12px"
              lineHeight="16px"
              color={Theme.colors.c505050}
              style={{ marginBottom: '16px' }}
            />
            <FlexContainer wrapContent>
              <GaugeGenericPercentage
                title={processPerformance.processSlaComplianceKpi.name}
                defaultValue={
                  processPerformance.processSlaComplianceKpi.value.percent
                }
                percentageWidth={33.3}
                description={
                  processPerformance.processSlaComplianceKpi.description
                }
              />
              <LineChartPerformance
                processPerformance={processPerformance}
                width={width}
                labelText="SLA compliance"
                notMobile={notMobile}
              />
            </FlexContainer>
            {processPerformance.taskSlaComplianceKpi &&
              processPerformance.taskSlaComplianceKpi.length > 0 && (
                <BoxAnalytics
                  title={
                    processPerformance.taskSlaComplianceKpiDescription.title
                  }
                  collapsed
                >
                  <Text
                    text={
                      processPerformance.taskSlaComplianceKpiDescription
                        .description
                    }
                    fontSize="12px"
                    lineHeight="16px"
                    color={Theme.colors.c505050}
                    style={{ marginBottom: '16px' }}
                  />
                  <FlexContainer wrapContent>
                    {processPerformance.taskSlaComplianceKpi.map(
                      (process: any) => (
                        <GaugeGenericPercentage
                          key={process.name}
                          title={process.name}
                          defaultValue={process.value.percent}
                          percentageWidth={33.3}
                          description={process.description}
                        />
                      )
                    )}
                  </FlexContainer>
                </BoxAnalytics>
              )}
          </BoxAnalytics>
        </>
      ) : (
        <LoaderContainer justifyContent="center" alignItems="center">
          <AroundLoader />
        </LoaderContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 16px 16px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const LoaderContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
`;
