import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import {
  ExecutionContext,
  FilledForm,
  FilledFormComplexField,
  FormFieldType,
  FormGroupDefinition,
} from '@pec-manager/graphql';
import { FlexContainer } from '../../layout/FlexContainer';
import { Text } from '../../common/Text';
import { mapFormFieldToComponent } from './mapFormFieldToComponent';

interface FormGroupProps {
  executionContext: ExecutionContext;
  group: FormGroupDefinition;
  fillField: (
    groupId: string,
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean,
    complexVariables?: FilledFormComplexField[]
  ) => void;
  filledForm: FilledForm;
  isModalView?: boolean;
  selectOpened: string;
  setSelectOpened: (id: string) => void;
  setFileLocaleView?: (fileLocaleView?: string) => void;
  checkRequired?: boolean;
  initialContext?: any;
  columnsForm: number;
}

export const FormGroup: FunctionComponent<FormGroupProps> = ({
  executionContext,
  group,
  fillField,
  filledForm,
  isModalView,
  selectOpened,
  setSelectOpened,
  setFileLocaleView,
  checkRequired,
  initialContext,
  columnsForm,
}) => {
  const fillFieldDelegate = (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean,
    complexVariables?: FilledFormComplexField[]
  ) => {
    fillField(group.id, fieldId, data, refetchDefinition, complexVariables);
  };

  const [fileIdViewActive, setFileIdViewActive] = useState<
    string | undefined
  >();

  return isModalView ? (
    <ContainerWithModal column className="ContainerFormWithModal">
      {group.name && (
        <StyledTitle
          text={group.name}
          fontSize="18px"
          lineHeight="22px"
          style={{ marginBottom: '10px' }}
          skipTranslation
        />
      )}
      <ContainerField
        wrapContent
        justifyContent="space-between"
        className="itemContainer"
      >
        {group.fields
          .filter((field) => field.type !== FormFieldType.HIDDEN)
          .map((field, index) =>
            mapFormFieldToComponent(
              executionContext,
              field,
              fillFieldDelegate,
              filledForm,
              field.id,
              group.id,
              selectOpened,
              undefined,
              setSelectOpened,
              setFileLocaleView,
              setFileIdViewActive,
              fileIdViewActive,
              checkRequired,
              false,
              undefined,
              undefined,
              columnsForm,
              initialContext
            )
          )}
      </ContainerField>
    </ContainerWithModal>
  ) : (
    <Container column className="ContainerForm">
      {group.name && (
        <StyledTitle
          text={group.name}
          fontSize="20px"
          lineHeight="21px"
          fontWeight="500"
          style={{ marginBottom: '10px' }}
          skipTranslation
        />
      )}
      <ContainerField wrapContent alignItems="flex-start">
        {group.fields.map((field, index) =>
          mapFormFieldToComponent(
            executionContext,
            field,
            fillFieldDelegate,
            filledForm,
            field.id,
            group.id,
            selectOpened,
            undefined,
            setSelectOpened,
            setFileLocaleView,
            setFileIdViewActive,
            fileIdViewActive,
            checkRequired,
            false,
            undefined,
            undefined,
            columnsForm,
            initialContext
          )
        )}
      </ContainerField>
    </Container>
  );
};

const ContainerWithModal = styled(FlexContainer)`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 32px;
`;
const Container = styled(FlexContainer)`
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  box-sizing: border-box;
  padding: 5px 20px 5px 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTitle = styled(Text)`
  width: 200px;
  margin-bottom: 5px;
`;

const ContainerField = styled(FlexContainer)`
  width: 100%;
  row-gap: 10px;
  gap: 10px 20px;
`;
