import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { ExecutionContext, IdentifiableValue } from '@pec-manager/graphql';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/common/Button';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { InboxSidebarSection } from './InboxSidebarSection';
import { getInboxSidebarMainSectionElements } from '../inboxSidebarDefaultSectionElements';
import { GET_ARCHIVES } from '../../../graphql/mail/queries';
import { InboxSidebarDefaultSectionId } from '../inboxSidebarDefaultSectionId';
import { Arrow } from '../../../svg';
import { GET_START_PROCESSES } from '../../../graphql/process/queries';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { DynamicForm } from '../../../components/DynamicForm';

export const InboxSidebar: FunctionComponent = () => {
  const { appId } = useParams();
  const { data } = useQuery(GET_ARCHIVES, {
    variables: {
      appId,
    },
  });
  /* const { data: savedSearchesData } = useQuery(GET_SAVED_SEARCHES, {
    variables: {
      appId,
    },
  });*/
  const { data: processesData } = useQuery(GET_START_PROCESSES, {
    variables: {
      appId,
    },
    fetchPolicy: 'no-cache',
  });
  const [isOpenButton, setIsOpenButton] = useState(false);
  const [modalProcessOpened, setModalProcessOpened] = useState(false);

  const [currentProcess, setCurrentProcess] =
    useState<IdentifiableValue | null>(null);
  const [isChoiceSelected, setIsChoiceSelected] = useState(false);

  useEffect(() => {
    if (processesData && processesData.getStartProcesses.length > 0) {
      setCurrentProcess(processesData.getStartProcesses[0]);
    }
  }, [processesData]);

  useEffect(() => {
    if (isChoiceSelected && currentProcess) {
      setModalProcessOpened(true);
    }
    // eslint-disable-next-line
  }, [currentProcess]);

  /*  useEffect(() => {
    dispatch(setSavedSearchList(savedSearchesData?.getSavedSearches || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchesData]);*/

  return (
    <InboxSidebarContainer>
      {currentProcess && (
        <StyledButton
          backgroundColor={Theme.colors.c5B84EF}
          hoverBackgroundColor={Theme.colors.c5176d6}
          justifyContentBetween={
            processesData?.getStartProcesses.length > 1 || false
          }
          height="36px"
          width="100%"
          borderRadius="56px"
          onClick={() => setModalProcessOpened(true)}
        >
          <Text
            text={currentProcess.value}
            fontSize="13px"
            lineHeight="15.6px"
            color={Theme.colors.cFFFFFF}
            tooltipText={currentProcess.value}
            breakWord
          />
          {processesData?.getStartProcesses.length > 1 && (
            <>
              <ButtonArrow
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenButton((prevState) => !prevState);
                }}
                alignItems="center"
                justifyContent="center"
              >
                <ArrowIcon
                  svg={<Arrow />}
                  width="10.8px"
                  color={Theme.colors.cFFFFFF}
                  isRotated={isOpenButton}
                />
              </ButtonArrow>
              {isOpenButton && (
                <ChoicesContainer>
                  {(processesData.getStartProcesses as IdentifiableValue[])
                    .filter((p) => p.id !== currentProcess?.id)
                    .map((process) => (
                      <div
                        key={process.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentProcess(process);
                          setIsChoiceSelected(true);
                          setIsOpenButton((prevState) => !prevState);
                        }}
                      >
                        <Text
                          text={process.value}
                          fontSize="13px"
                          lineHeight="15.6px"
                          color={Theme.colors.c505050}
                          tooltipText={process.value}
                          breakWord
                        />
                      </div>
                    ))}
                </ChoicesContainer>
              )}
            </>
          )}
        </StyledButton>
      )}
      {currentProcess && modalProcessOpened && (
        <DynamicForm
          executionContext={ExecutionContext.START}
          columnsForm={1}
          processId={currentProcess.id}
          onCancel={() => {
            setModalProcessOpened(false);
          }}
          onSuccess={() => {
            setModalProcessOpened(false);
          }}
        />
      )}
      <InboxSidebarSection
        sectionId={InboxSidebarDefaultSectionId.MAIN}
        sectionElements={getInboxSidebarMainSectionElements(
          (data?.getArchives || []) as IdentifiableValue[]
        )}
      />
      {/*{savedSearchesData && savedSearchesData.getSavedSearches.length > 0 && (
        <InboxSidebarSection
          sectionId={InboxSidebarDefaultSectionId.SAVED_SEARCHES}
          sectionName="savedSearches"
          sectionElements={(savedSearchesData.getSavedSearches as SavedSearch[]).map(
            ({ id, name }) => ({
              id,
              name,
              svgIcon: { icon: <Disk />, color: Theme.colors.c8E96D6 },
            })
          )}
        />
      )}*/}
    </InboxSidebarContainer>
  );
};

const InboxSidebarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.c212832};
  padding: 25.6px 16px 25.6px 16px;
  overflow-y: auto;
  flex-basis: 200px;
  min-width: 200px;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledButton = styled(Button)<{ justifyContentBetween: boolean }>`
  margin-top: 21px;
  margin-left: -3px;
  padding: 6px 16px;
  display: flex;
  justify-content: ${({ justifyContentBetween }) =>
    justifyContentBetween ? 'space-between' : 'center'};
  position: relative;
  height: fit-content;
  min-height: 36px;
`;

const ArrowIcon = styled(SvgIcon)<{ isRotated: boolean }>`
  transition: 200ms transform ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`;

const ChoicesContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  display: flex;
  flex-direction: column;
  top: calc(100% + 3px);
  border-radius: 12px;
  width: 100%;
  margin-left: -16px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6px;
  }

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  }
`;

const ButtonArrow = styled(FlexContainer)`
  min-height: 28px;
  min-width: 22px;
`;
