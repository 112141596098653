import React, { FunctionComponent, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { CountKpi } from '@pec-manager/graphql';

interface CandlestickChartProps {
  workloadSamples: CountKpi[];
  width: number | string;
  labelText: string;
}

export const LineChartWorkload: FunctionComponent<CandlestickChartProps> = ({
  workloadSamples,
  width,
  labelText,
}) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    xaxis: {
      categories: workloadSamples.map((sample) => sample.name),
    },
  };
  const series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined =
    useMemo(() => {
      if (!workloadSamples) {
        return undefined;
      }
      return [
        {
          name: labelText,
          data: workloadSamples.map((sample) => sample.value),
        },
      ];
    }, [labelText, workloadSamples]);

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      width={width}
      height={400}
    />
  );
};
