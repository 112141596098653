import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import styled from 'styled-components';
import { Theme } from '../../theme';
import { Tag } from '../../components/tag';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Attachment, Comment } from '../../svg';

interface CategoryProps {
  title: string;
  subtitle: string;
  category?: string;
  numberOfAttachments: number;
  numberOfComments: number;
}

export const Description: FunctionComponent<CategoryProps> = ({
  subtitle,
  title,
  category,
  numberOfAttachments,
  numberOfComments,
}) => (
  <Container column>
    <ContainerTop justifyContent="space-between">
      <FlexContainer
        style={{ maxWidth: '76%' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Title text={title} fontSize="14px" lineHeight="20px" ellipsis />
        {category && <Tag text={category} />}
      </FlexContainer>
      <FlexContainer>
        <TipsContainer alignItems="center">
          <SvgIcon
            svg={<Attachment />}
            height="10px"
            width="10px"
            rotateDeg={20}
            color={Theme.colors.c505050}
          />
          <Text
            text={`${numberOfAttachments}`}
            fontSize="10px"
            lineHeight="15px"
            color={Theme.colors.c838383}
          />
        </TipsContainer>
        <TipsContainer alignItems="center">
          <SvgIcon
            svg={<Comment />}
            height="10px"
            width="10px"
            color={Theme.colors.cFF8686}
          />
          <Text
            text={`${numberOfComments}`}
            translationArgs={{ numberOfComments }}
            fontSize="10px"
            lineHeight="15px"
            color={Theme.colors.c838383}
          />
        </TipsContainer>
      </FlexContainer>
    </ContainerTop>
    <Text
      text={subtitle}
      fontSize="12px"
      lineHeight="20px"
      color={Theme.colors.c505050}
      ellipsis
    />
  </Container>
);

const Container = styled(FlexContainer)`
  height: 40px;
  width: 100%;
  padding-right: 16px;

  span {
    white-space: nowrap;
  }
`;

const ContainerTop = styled(FlexContainer)`
  width: 100%;
`;

const Title = styled(Text)`
  margin-right: 8px;
`;

const TipsContainer = styled(FlexContainer)`
  & > span {
    margin-left: 5px;
  }

  &:nth-child(2) {
    margin-left: 16px;
  }
`;
