import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ApplicationOverview } from '@pec-manager/graphql';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/common/Text';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Theme } from '../../theme';
import { ExecutionsDetail } from './ExecutionsDetail';
import { ActivitiesDetail } from './ActivitiesDetail';

interface AppDetailsProps {
  app: ApplicationOverview;
}

export const AppDetails: FunctionComponent<AppDetailsProps> = ({ app }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header justifyContent="space-between" alignItems="center">
        <Text
          text={app.application.name || ''}
          fontSize="16px"
          lineHeight="22px"
          fontWeight="600"
          ellipsis
        />
        <NavigationClickable
          alignItems="center"
          justifyContent="center"
          onClick={() =>
            navigate(
              `/${app.application.id}/${app.application.context.toLowerCase()}`
            )
          }
        >
          <Text
            text="goToApp"
            fontSize="12px"
            lineHeight="12px"
            color={Theme.colors.c5B84EF}
          />
          <Text
            text=">"
            fontSize="18px"
            lineHeight="18px"
            color={Theme.colors.c5B84EF}
          />
        </NavigationClickable>
      </Header>
      <ExecutionsDetail
        total={app.executionsCompleted + app.executionsInProgress}
        partialCompleted={app.executionsCompleted}
        partialInProgress={app.executionsInProgress}
      />
      <ActivitiesDetail
        partialCompleted={app.activitiesCompleted}
        total={
          app.activitiesCompleted +
          app.activitiesExpired +
          app.activitiesInDraft
        }
        partialInDraft={app.activitiesInDraft}
        partialInExpired={app.activitiesExpired}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-left: 21px;
  margin-top: 21px;
  height: 422px;
  width: 420px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  @media (max-width: 1039px) {
    margin-left: 0;
    &:nth-of-type(2n) {
      margin-left: 21px;
    }
  }
  @media (max-width: 910px) {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
`;

const Header = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 12px 16px 16px 16px;
`;

const NavigationClickable = styled(FlexContainer)`
  cursor: pointer;
  & > span:first-child {
    margin-right: 11px;
  }
`;
