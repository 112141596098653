import styled from 'styled-components';
import { Color } from '../../theme';

interface CircleLoaderProps {
  sizeInPx: string;
  thicknessInPx: string;
  colorSpinner?: Color | string;
}

export const CircleLoader = styled.div<CircleLoaderProps>`
  border: ${({ theme, thicknessInPx }) =>
    `${thicknessInPx} solid ${theme.colors.cE2F3FF}`};
  border-top: ${({ colorSpinner, theme, thicknessInPx }) =>
    `${thicknessInPx} solid ${colorSpinner || theme.colors.c2186C6}`};
  border-radius: 50%;
  width: ${({ sizeInPx }) => sizeInPx};
  height: ${({ sizeInPx }) => sizeInPx};
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
