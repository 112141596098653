import { useQuery, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { NotificationEvent, NotificationEventType } from '@pec-manager/graphql';
import { NOTIFICATION_RECEIVED } from '../../graphql/notification/subscriptions';
import { KeycloakService } from '../../services/KeycloakService';
import {
  initNotificationsAction,
  pushNotificationAction,
  readNotificationAction,
  updateNotificationAction,
} from '../../redux/notification/sidebar/actions';
import { GET_NOTIFICATIONS } from '../../graphql/notification/queries';

export const useNotificationQuery = () => {
  const { data } = useSubscription(NOTIFICATION_RECEIVED, {
    variables: { correlationId: KeycloakService.getToken() },
    fetchPolicy: 'network-only',
  });

  const { data: queryData } = useQuery(GET_NOTIFICATIONS);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const notificationEvent: NotificationEvent = data.notificationReceived;

      switch (notificationEvent.type) {
        case NotificationEventType.CREATED:
          dispatch(
            pushNotificationAction(notificationEvent.created!.notification)
          );
          break;
        case NotificationEventType.UPDATED:
          dispatch(updateNotificationAction(notificationEvent.updated!));
          break;
        case NotificationEventType.OPENED:
          dispatch(
            readNotificationAction(notificationEvent.opened!.notificationId)
          );
          break;
        case NotificationEventType.READ:
          dispatch(
            readNotificationAction(notificationEvent.read!.notificationId)
          );
          break;
        case NotificationEventType.DELETED:
          dispatch(
            readNotificationAction(notificationEvent.deleted!.notificationId)
          );
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (queryData) {
      dispatch(initNotificationsAction(queryData.getNotifications));
    }
    // eslint-disable-next-line
  }, [queryData]);
};
