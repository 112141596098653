import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import { ChatResourceType, ResourceAction } from '@pec-manager/graphql';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ButtonWithIcon } from '../../components/common/ButtonWithIcon';
import { ActionMapping } from './ActionSectionMapping';
import { Theme } from '../../theme';
import { ModalTypeInbox } from './index';
import { openChatModalId } from '../../redux/Sintropi/execution/actions';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import { CREATE_CHAT } from '../../graphql/chat/mutations';

interface ActionSectionProps {
  actions: ResourceAction[];
  setModalType: Dispatch<SetStateAction<ModalTypeInbox | undefined>>;
  rootExecutionId: string;
}

export const ActionSection: FunctionComponent<ActionSectionProps> = ({
  actions,
  setModalType,
  rootExecutionId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { appId } = useParams();

  const [createChat] = useMutationHook({
    mutationGql: CREATE_CHAT,
    errorMessage: 'genericErrorMessage',
  });

  return (
    <Container alignItems="center" justifyContent="flex-end">
      {actions
        .slice()
        .sort((a, b) => b.priority - a.priority)
        .map((action, index) => {
          const { icon, onClick, text } = ActionMapping(
            action.type,
            setModalType,
            () => navigate(`/${appId}/sintropi/takeincharge${location.search}`),
            () =>
              createChat({
                variables: {
                  invitedUsers: [],
                  resource: {
                    resourceId: rootExecutionId,
                    resourceType: ChatResourceType.EXECUTION,
                  },
                },
              }).then((result: any) => {
                if (result.data?.createChatRoom) {
                  dispatch(
                    openChatModalId({
                      id: result.data.createChatRoom,
                      resourceId: rootExecutionId,
                    })
                  );
                }
              })
          )!;

          return (
            <ButtonWithIcon
              key={action.type}
              text={text}
              svg={icon}
              onClickHandler={onClick}
              color={
                actions.length - 1 === index ? Theme.colors.cFFFFFF : undefined
              }
              backgroundColor={
                actions.length - 1 === index ? Theme.colors.c5B84EF : undefined
              }
            />
          );
        })}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  margin-top: 24px;
  padding-top: 20px;
  width: 100%;
  & > div:not(:first-child) {
    margin-left: 12px;
  }
`;
