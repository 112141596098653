import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';

interface MessageSystemProps {
  sender?: string;
  invited?: string;
  message: string;
}

export const MessageSystem: FunctionComponent<MessageSystemProps> = ({
  message,
  sender = '',
  invited = '',
}) => (
  <Container alignItems="center" justifyContent="center">
    <ContainerText>
      <Text
        text={message}
        translationArgs={{
          sender,
          invited,
        }}
        fontSize="14px"
        lineHeight="21px"
        color={Theme.colors.c505050}
      />
    </ContainerText>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 24px;
`;

const ContainerText = styled.div`
  width: 50%;
  text-align: center;
`;
