import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { TaskSummary } from '@pec-manager/graphql';
import { motion } from 'framer-motion';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { TopSection } from './TopSection';
import { TitleSection } from './TitleSection';
import { AttachmentsCommentsSection } from './AttachmentsCommentsSection';
import { BottomSection } from './BottomSection';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { DueDateSection } from './DueDateSection';
import { colorBorderLeft } from '../../utils/funcUtils';

export const TaskItemCard: FunctionComponent<{
  task: TaskSummary;
  index: number;
}> = ({ task, index }) => {
  const [hideCorrelatedSection, setHideCorrelatedSection] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSelected = task.base.id === searchParams.get('taskId');
  const dropDownId = searchParams.get('dropDownId');
  const { appId } = useParams();

  const dropDownIdQueryParam = dropDownId
    ? `&dropDownId=${searchParams.get('dropDownId')}`
    : '';

  const navigatePushString =
    location.pathname === `${appId}/sintropi/search`
      ? `/${location.pathname}?taskId=${task.base.id}`
      : `/${appId}/sintropi?sectionId=${searchParams.get(
          'sectionId'
        )}&elementId=${searchParams.get(
          'elementId'
        )}${dropDownIdQueryParam}&taskId=${task.base.id}`;

  return (
    <Motion
    /*      initial={{
        y: index <= MAILS_PAGE_SIZE ? -10 * (index + 1) : 0,
        opacity: 0,
      }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: index <= 7 ? 0.05 * (index + 1) : 0.1,
        type: 'tween',
      }}*/
    >
      <ContainerTask
        column
        selected={isSelected}
        colorBorderLeft={colorBorderLeft(task.base)}
      >
        <PaddingContainer
          column
          onClick={() => {
            navigate(navigatePushString);
          }}
        >
          <TopSection
            textCategory={task.base.category}
            colorBackground={task.base.rootExecutionId}
          />
          <TitleSection
            title={task.base.name}
            receivedAt={task.base.createdAt as any as number}
            setHideCorrelatedSection={setHideCorrelatedSection}
            hideCorrelatedSection={hideCorrelatedSection}
          />
          <DescriptionSection
            text={task.base.description}
            skipTranslation
            lineHeight="18px"
            fontSize="12px"
            color={Theme.colors.c505050}
          />
          <AttachmentsCommentsSection
            numberOfAttachments={task.attachmentCount}
            numberOfComments={task.commentCount}
          />
          <DueDateSection taskBase={task.base} />
        </PaddingContainer>
        <BottomSection
          selected={isSelected}
          taskBase={task.base}
          hideCorrelateSection={hideCorrelatedSection}
        />
      </ContainerTask>
    </Motion>
  );
};

const ContainerTask = styled(FlexContainer)<{
  selected?: boolean;
  colorBorderLeft: string;
}>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFFFFFF};
  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  border-left: 6px solid ${({ colorBorderLeft }) => colorBorderLeft};

  @media (max-width: 1040px) {
    max-width: 100%;
  }
`;

const PaddingContainer = styled(FlexContainer)`
  padding: 12px 16px 0 16px;
`;

const DescriptionSection = styled(Text)`
  max-width: calc(100% - 23px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

const Motion = styled(motion.div)`
  width: calc(33% - 20px);
  margin: 10px;

  @media (max-width: 1700px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 1200px) {
    width: calc(100% - 20px);
  }
`;
