import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ContainerModal } from '../../components/modals/ContainerModal';
import { InnerChatDetails } from './InnerChatDetails';
import { useChat } from '../hooks/useChat';

interface ChatDetailsProps {
  viewInModal?: boolean;
}

export const ChatDetails: FunctionComponent<ChatDetailsProps> = ({
  viewInModal,
}) => {
  const { data: chat } = useChat();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (chat && viewInModal) {
      setOpenModal(true);
    }
  }, [viewInModal, chat]);

  if (!chat) {
    // loading or empty
    return <></>;
  }

  return !viewInModal ? (
    <Container column>
      <InnerChatDetails chat={chat} />
    </Container>
  ) : (
    <>
      {openModal && chat && (
        <ContainerModal position="fixed">
          <Container onClick={(e) => e.stopPropagation()}>
            <InnerChatDetails chat={chat} modal />
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (max-width: 1040px) {
    padding: 0;
    margin: 20px;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 100px);
    border-radius: 8px;
    overflow-y: auto;
  }
  @media (max-width: 990px) {
    max-height: calc((var(--vh, 1vh) * 100) - 150px);
    height: calc((var(--vh, 1vh) * 100) - 80px);
    margin: 0;
  }
`;
