import { gqlUserResolutionShape } from '../user/shapes';

export const gqlNotificationResolutionShape = `
    id
    createdAt
    updatedAt
    activityUsers {
      ${gqlUserResolutionShape}
    }
    activitySource
    activityType
    activityCount
    resourceId
    resourceType
    resourceName
    notificationStatus
`;

export const gqlNotificationEventResolutionShape = `
  type
  created {
    notification {
      ${gqlNotificationResolutionShape}
    }
  }
  updated {
    notificationId
    activityUsers {
      ${gqlUserResolutionShape}
    }
    activityCount
  }
  opened {
    notificationId
  }
  read {
    notificationId
  }
  deleted {
    notificationId
  }
`;
