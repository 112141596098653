import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { getMappedColor } from '../../../utils/colorUtils';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { setDisplayName } from '../../../redux/Inbox/sidebar/actions';

interface SidebarSectionItemDropDownItemProps {
  sectionElementDropDownElementId: string | null;
  id: string;
  name: string;
  isParentSelected: boolean;
  onClickHandler: (id: string) => void;
}

export const InboxSidebarSectionItemDropDownItem: FunctionComponent<
  SidebarSectionItemDropDownItemProps
> = ({
  sectionElementDropDownElementId,
  id,
  name,
  isParentSelected,
  onClickHandler,
}) => {
  const dispatch = useDispatch();

  const isSelected = sectionElementDropDownElementId === id && isParentSelected;

  useEffect(() => {
    if (isSelected) {
      dispatch(setDisplayName(name));
    }
  }, [dispatch, isSelected, name]);

  return (
    <motion.section
      key={id}
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ type: 'just' }}
    >
      <StyledDropDownItemFlexContainer
        alignItems="center"
        onClick={() => onClickHandler(id)}
      >
        <Border color={getMappedColor(name)} />
        <Text
          text={name}
          fontSize="12px"
          lineHeight="14.4px"
          color={isSelected ? Theme.colors.c4C9AFF : Theme.colors.cFFFFFF}
        />
      </StyledDropDownItemFlexContainer>
    </motion.section>
  );
};

const Border = styled(FlexContainer)<{ color: string }>`
  background-color: ${({ color }) => color};
  height: 16px;
  width: 3px;
  border-radius: 1px;
  margin-right: 8px;
`;

const StyledDropDownItemFlexContainer = styled(FlexContainer)`
  cursor: pointer;
`;
