import { createReducer } from '@reduxjs/toolkit';
import { setDisplayName } from './actions';

export interface InboxSidebarState {
  displayName?: string;
}

export const inboxSidebarReducer = createReducer<InboxSidebarState>(
  {},
  {
    [setDisplayName.type]: (
      state,
      action: { payload: string }
    ): InboxSidebarState => ({ displayName: action.payload }),
  }
);
