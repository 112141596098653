import { gql } from '@apollo/client/core';
import { gqlIdentifiableValueShape } from '../common/shapes';
import { gqlTaskProgressFlowShape } from '../task/shapes';

export const GET_ADVANCED_SELECT_CHOICES = gql`
  query GetAdvancedSelectChoices(
    $context: ExecutionContext!
    $searchFilter: String!
    $formField: AbsoluteFormFieldCoordinates!
    $filledForm: FilledForm!
  ) {
    getAdvancedSelectChoices(
      context: $context
      searchFilter: $searchFilter
      formField: $formField
      filledForm: $filledForm
    ) {
      ${gqlIdentifiableValueShape}
    }
  }
`;

export const GET_TASK_PROGRESS_FLOWS = gql`
    query GetTaskProgressFlows {
        getTaskProgressFlows {
            ${gqlTaskProgressFlowShape}
        }
    }
`;

export const GET_START_PROCESS_BY_NAME = gql`
  query GetStartProcessByName($appId: ID!, $processName: String!) {
    getStartProcessByName(appId: $appId, processName: $processName) {
      id
      value
    }
  }
`;
