import React, { FunctionComponent, useEffect, useState } from 'react';
import { KeycloakService } from '../../services/KeycloakService';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ProgressFlows } from '../../inbox/mail/ProgressFlows';
import { Text } from '../../components/common/Text';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';
import { RecordContainerProps } from './RecordContainer';
import { SelectTableRecord } from '../../components/common/SelectTableRecord';
import { ButtonPrimary } from '../../components/common/ButtonPrimary';
import { useNavigate, useParams } from 'react-router-dom';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Arrow } from '../../svg';
import { GET_START_PROCESS_BY_NAME } from '../../graphql/form/queries';
import { useLazyQuery } from '@apollo/client';
import { SearchBar } from '../../components/common/Search';
import { DynamicForm } from '../../components/DynamicForm';
import { ExecutionContext } from '@pec-manager/graphql';
import styled from 'styled-components';

export const RecordContainerHeader: FunctionComponent<RecordContainerProps> = ({
  setTable,
  setAppCode,
  detailsView,
  initialContextDetail,
  setInitialContextDetail,
  setSearchRecords,
  setForceRefetch,
  table,
}) => {
  const navigate = useNavigate();
  const { appId } = useParams<{ appId: string }>();
  const [searchValue, setSearchValue] = React.useState('');
  const [modalProcessOpened, setModalProcessOpened] = useState(false);
  const [nameToPassToForm, setNameToPassToFormClicked] = useState<string>('');
  const [button, setButton] = useState<
    | {
        label: string;
        processName: string;
      }[]
    | undefined
  >(undefined);

  const [buttonDetail, setButtonDetail] = useState<
    | {
        label: string;
        processName: string;
      }[]
    | undefined
  >(undefined);

  const [getStartProcessByName] = useLazyQuery(GET_START_PROCESS_BY_NAME);

  useEffect(() => {
    const actionsWithNameToPass = table?.actions?.map((action) => {
      return {
        label: action.label,
        processName: action.processName,
      };
    });
    setButton(actionsWithNameToPass);
  }, [table]);

  useEffect(() => {
    const actionsWithNameToPass = table?.itemActions?.map((action) => {
      return {
        label: action.label,
        processName: action.processName,
      };
    });
    setButtonDetail(actionsWithNameToPass);
  }, [table]);

  useEffect(() => {
    if (searchValue === '') {
      setSearchRecords && setSearchRecords('');
    }
  }, [searchValue, setSearchRecords]);

  useEffect(() => {
    if (table) {
      navigate(`/${appId}/sintropi/records`);
      setSearchRecords && setSearchRecords('');
      setSearchValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, table]);

  console.log(initialContextDetail);

  return (
    <Container column>
      <TopContainer alignItems="center" justifyContent="space-between">
        <FlexContainer>
          <TitleWithChooseApp />
          <SelectTableRecord setAppCode={setAppCode} setTable={setTable} />
        </FlexContainer>
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </TopContainer>
      <BottomContainer
        alignItems="center"
        justifyContent="space-between"
        column
      >
        <TitleContainer justifyContent="space-between">
          <LeftContainer alignItems="center">
            {!detailsView &&
              button &&
              button?.map((v) => (
                <ButtonPrimary
                  key={v.processName}
                  onClick={async () => {
                    setInitialContextDetail &&
                      setInitialContextDetail(undefined);
                    await getStartProcessByName({
                      variables: {
                        processName: v.processName,
                        appId,
                      },
                    }).then((res) => {
                      setNameToPassToFormClicked(
                        res?.data?.getStartProcessByName?.id || undefined
                      );
                    });
                    setModalProcessOpened(true);
                  }}
                  style={{ marginRight: '10px' }}
                  text={v.label}
                />
              ))}
            {detailsView && (
              <>
                <BackButton onClick={() => navigate(-1)}>
                  <SvgIcon svg={<Arrow />} rotateDeg={90} height="10px" />
                </BackButton>
                <Title>
                  <Text text="recordDetail" fontSize="18px" lineHeight="18px" />
                </Title>
              </>
            )}
          </LeftContainer>
          <RightContainer justifyContent="flex-end" alignItems="center">
            {setSearchRecords && !detailsView && (
              <SearchBar
                placeholder="searchRecord"
                actionSearchBar={setSearchRecords}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                typeEntityAutocomplete={[]}
              />
            )}
            {detailsView && buttonDetail && initialContextDetail
              ? buttonDetail.map((b) => (
                  <ButtonPrimary
                    key={b.processName}
                    onClick={async () => {
                      await getStartProcessByName({
                        variables: {
                          processName: b.processName,
                          appId,
                        },
                      }).then((res) => {
                        setNameToPassToFormClicked(
                          res?.data?.getStartProcessByName?.id || undefined
                        );
                      });
                      setModalProcessOpened(true);
                    }}
                    text={b.label}
                    style={{
                      marginLeft: '10px',
                    }}
                  />
                ))
              : null}
            <ProgressFlows />
          </RightContainer>
        </TitleContainer>
      </BottomContainer>
      {modalProcessOpened && !!nameToPassToForm && (
        <DynamicForm
          executionContext={ExecutionContext.START}
          context={initialContextDetail || [{}]}
          columnsForm={1}
          processId={nameToPassToForm}
          onCancel={() => {
            setModalProcessOpened(false);
            setNameToPassToFormClicked('');
          }}
          onSuccess={() => {
            setForceRefetch && setForceRefetch(true);
            setModalProcessOpened(false);
            setNameToPassToFormClicked('');
          }}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  padding: 0 16px;
`;

const LeftContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 56px;
`;

const RightContainer = styled(FlexContainer)`
  width: fit-content;
  padding-right: 20px;
  margin-right: -6px;
`;

const TopContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

const TitleContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const BackButton = styled.div`
  cursor: pointer;
  margin-left: 45px;
  margin-right: 24px;
  width: 46.2px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
`;

const Title = styled.div`
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  height: 30px;
  display: flex;
  align-items: center;
`;
