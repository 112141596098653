import React, { FC, useState } from 'react';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { EditPencil } from '../../../svg';
import { Text } from '../../../components/common/Text';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { KeycloakService } from '../../../services/KeycloakService';

interface CustomSectionProps {
  canManageAcl: boolean;
  canCreate: boolean;
  canDelete: boolean;
  canRead: boolean;
  canUpdate: boolean;
  handleAuthChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    userId: string
  ) => void;
  userId: string;
}

export const ModifyAuthButton: FC<CustomSectionProps> = ({
  handleAuthChange,
  canRead,
  canUpdate,
  canCreate,
  canDelete,
  userId,
  canManageAcl,
}) => {
  const [showAuth, setShowAuth] = useState(false);

  return (
    <Container alignItems="center" onClick={() => setShowAuth((prev) => !prev)}>
      <div>
        <SvgIcon svg={<EditPencil />} />
      </div>
      {showAuth && (
        <AuthCheckContainer
          style={{ width: 'fit-content' }}
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
        >
          <AuthContainer>
            <input
              type="checkbox"
              name="canCreate"
              value="canCreate"
              defaultChecked={canCreate}
              onChange={(e) => handleAuthChange(e, userId)}
            />
            <Text text="canCreate" fontSize="10px" lineHeight="12px" />
          </AuthContainer>
          <AuthContainer>
            <input
              type="checkbox"
              name="canRead"
              value="canRead"
              defaultChecked={canRead}
              onChange={(e) => handleAuthChange(e, userId)}
            />
            <Text text="canRead" fontSize="10px" lineHeight="12px" />
          </AuthContainer>
          <AuthContainer>
            <input
              type="checkbox"
              name="canUpdate"
              value="canUpdate"
              defaultChecked={canUpdate}
              onChange={(e) => handleAuthChange(e, userId)}
            />
            <Text text="canUpdate" fontSize="10px" lineHeight="12px" />
          </AuthContainer>
          <AuthContainer>
            <input
              type="checkbox"
              name="canDelete"
              value="canDelete"
              defaultChecked={canDelete}
              onChange={(e) => handleAuthChange(e, userId)}
            />
            <Text text="canDelete" fontSize="10px" lineHeight="12px" />
          </AuthContainer>
          {userId !== KeycloakService.getId() && (
            <AuthContainer>
              <input
                type="checkbox"
                name="canManageAcl"
                value="canManageAcl"
                defaultChecked={canManageAcl}
                onChange={(e) => handleAuthChange(e, userId)}
              />
              <Text text="canManageAcl" fontSize="10px" lineHeight="12px" />
            </AuthContainer>
          )}
        </AuthCheckContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 30px;
  height: 30px;
  padding: 6px;
  margin-top: 5px;
  border-radius: 10%;
  margin-left: auto;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.c2186C6};
  position: relative;
`;

const AuthCheckContainer = styled(FlexContainer)`
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  position: absolute;
  right: 30px;
  background: ${({ theme }) => theme.colors.cFFFFFF};
  width: fit-content;
  padding: 8px;
  border-radius: 8px;
  height: 60px;
`;

const AuthContainer = styled(FlexContainer)`
  width: max-content;
  height: fit-content;
  padding: 8px 12px;

  & > span {
    margin-left: 4px;
    margin-right: 8px;
  }
`;
