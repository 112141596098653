import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import styled from 'styled-components';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { ResourceAssignee } from '@pec-manager/graphql';
import { RoundedAvatarGroup } from '../../components/avatar/RoundedAvatarGroup';

interface UserWithDateProps {
  name: string;
  group?: ResourceAssignee[];
  fontSize?: string;
  lineHeight?: string;
  fontSizeAvatar?: number;
  sizeAvatar?: number;
  colorText?: string;
}

export const UserWithoutDate: FunctionComponent<UserWithDateProps> = ({
  name,
  group,
  lineHeight = '20px',
  fontSize = '16px',
  fontSizeAvatar = 14,
  sizeAvatar,
  colorText,
}) => {
  const groupName: {
    name: string;
    type: 'GROUP' | 'USER';
  }[] =
    group?.map(({ group, user }) =>
      group?.name
        ? { name: group?.name, type: 'GROUP' }
        : { name: `${user?.firstName} ${user?.lastName}`, type: 'USER' }
    ) || [];

  return name !== '--' ? (
    <>
      <RoundedTextAvatar
        text={name}
        fontSize={fontSizeAvatar}
        size={sizeAvatar}
        visibleTooltip
      />
      <Container column justifyContent="center">
        <Text
          text={name}
          fontSize={fontSize}
          lineHeight={lineHeight}
          ellipsis
          color={colorText}
        />
      </Container>
    </>
  ) : (
    <>
      <RoundedAvatarGroup
        team={groupName}
        fontSize={fontSizeAvatar}
        size={sizeAvatar}
      />
      {groupName.length === 1 && (
        <Container column justifyContent="center">
          <Text
            text={groupName[0].name}
            fontSize={fontSize}
            lineHeight={lineHeight}
            ellipsis
            color={colorText}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled(FlexContainer)`
  height: 40px;
  margin: 0 16px;
  overflow: auto;
`;
