import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Period, ProcessWorkloadKpiResponse } from '@pec-manager/graphql';
import { useLazyQuery } from '@apollo/client';
import { PROCESS_WORKLOAD_KPI } from '../../graphql/kpi/queries';
import styled from 'styled-components';
import { BoxAnalytics } from './BoxAnalytics';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { AroundLoader } from '../../components/loader/AroundLoader';
import { LineChartWorkload } from './LineChartWorkload';
import { PieChart } from '../../components/common/PieChart';
import { BoardItemtype } from './index';
import { transformSecondInDayHoursMinutes } from '../../utils/dateUtils';
import { extractWidthForPieChart } from './utils';

interface TabWorkloadProps {
  periodSelected: Period;
  processNameSelected?: string;
  setBoardItems: (boardItems: BoardItemtype[]) => void;
  notMobile?: boolean;
}

export const TabWorkload: FunctionComponent<TabWorkloadProps> = ({
  periodSelected,
  processNameSelected,
  setBoardItems,
  notMobile,
}) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  const [widthLineChart, setWidthLineChart] = useState(0);
  const [workloadKPI, setWorkloadKPI] = React.useState<
    ProcessWorkloadKpiResponse | undefined
  >(undefined);
  const [processWorkloadKpiQuery, { loading }] = useLazyQuery(
    PROCESS_WORKLOAD_KPI,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    }
  );

  useEffect(() => {
    if (processNameSelected) {
      processWorkloadKpiQuery({
        variables: {
          period: periodSelected,
          processName: processNameSelected,
        },
      }).then((result) => {
        if (result.data?.processWorkloadKpi) {
          setWorkloadKPI(result.data.processWorkloadKpi);
          setBoardItems([
            {
              title:
                result.data.processWorkloadKpi.totalCompletedExecutionsLabel,
              value:
                result.data.processWorkloadKpi.totalCompletedExecutionsValue,
            },
            {
              title: result.data.processWorkloadKpi.processLeadTimeLabel,
              value: transformSecondInDayHoursMinutes(
                result.data.processWorkloadKpi.processLeadTimeValue
              ),
            },
            {
              title:
                result.data.processWorkloadKpi.processDueDateComplianceLabel,
              value:
                result.data.processWorkloadKpi.processDueDateComplianceValue +
                '%',
            },
          ]);
        }
        if (ref.current) {
          setWidth(extractWidthForPieChart((ref.current as any)?.offsetWidth));
          setWidthLineChart(((ref.current as any)?.offsetWidth / 10) * 6);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodSelected, processNameSelected, processWorkloadKpiQuery, ref]);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setWidth(extractWidthForPieChart((ref.current as any)?.offsetWidth));
      setWidthLineChart(((ref.current as any)?.offsetWidth / 10) * 6);
    }
  }, [ref]);

  return (
    <Container ref={ref}>
      {workloadKPI && !loading ? (
        <>
          {/*<BoxAnalytics title={workloadKPI.processWorkloadKpiDescription.title} firstElement>
            <Text
              text={workloadKPI.processWorkloadKpiDescription.description}
              fontSize="12px"
              lineHeight="16px"
              color={Theme.colors.c505050}
              style={{ marginBottom: '16px' }}
            />
            <FlexContainer wrapContent alignItems="center" ref={ref}>
              <FlexContainer
                column
                style={{ width: '33.3%' }}
                alignItems="center"
              >
                <Text
                  text={workloadKPI.processWorkloadKpi.name}
                  fontSize="20px"
                  lineHeight="30px"
                  color={Theme.colors.c505050}
                  fontWeight="300"
                  style={{ marginBottom: '30px' }}
                />
                <Text
                  text={workloadKPI.processWorkloadKpi.value.toString()}
                  fontSize="28px"
                  lineHeight="34px"
                  fontWeight="600"
                />
              </FlexContainer>
              {workloadKPI.processWorkloadKpi.samples &&
                workloadKPI.processWorkloadKpi.samples.length > 0 && (
                  <LineChartWorkload
                    labelText="Running processes"
                    workloadSamples={workloadKPI.processWorkloadKpi.samples}
                    width={widthLineChart}
                  />
                )}
            </FlexContainer>
            <BoxAnalytics title={workloadKPI.taskWorkloadKpiDescription.title}>
              <Text
                text={workloadKPI.taskWorkloadKpiDescription.description}
                fontSize="12px"
                lineHeight="16px"
                color={Theme.colors.c505050}
                style={{ marginBottom: '16px' }}
              />
              <FlexContainer wrapContent>
                {workloadKPI.taskWorkloadKpi.map((task) =>
                  task.samples ? (
                    <PieChart
                      samples={task.samples}
                      width={width}
                      title={task.name}
                      count={task.value}
                    />
                  ) : null
                )}
              </FlexContainer>
            </BoxAnalytics>
          </BoxAnalytics>*/}
          <BoxAnalytics
            title={workloadKPI.processWorkforceKpiDescription.title}
            firstElement
          >
            <Text
              text={workloadKPI.processWorkforceKpiDescription.description}
              fontSize="12px"
              lineHeight="16px"
              color={Theme.colors.c505050}
              style={{ marginBottom: '16px' }}
            />
            <FlexContainer wrapContent alignItems="center">
              <InnerContainer
                column
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  text={workloadKPI.processWorkforceKpi.name}
                  fontSize="20px"
                  lineHeight="30px"
                  color={Theme.colors.c505050}
                  fontWeight="300"
                  style={{ marginBottom: '30px' }}
                />
                <Text
                  text={`Total completed: ${workloadKPI.processWorkforceKpi.value}`}
                  fontSize="28px"
                  lineHeight="34px"
                  fontWeight="600"
                />
                {workloadKPI.processWorkforceKpi.dailyRate && (
                  <Text
                    text={`Daily frequency: ${workloadKPI.processWorkforceKpi.dailyRate}`}
                    fontSize="18px"
                    lineHeight="30px"
                    color={Theme.colors.c505050}
                    fontWeight="300"
                    style={{ marginTop: '10px' }}
                  />
                )}
              </InnerContainer>
              {workloadKPI.processWorkforceKpi.samples &&
                workloadKPI.processWorkforceKpi.samples.length > 0 && (
                  <LineChartWorkload
                    labelText="Completed processes"
                    workloadSamples={workloadKPI.processWorkforceKpi.samples}
                    width={notMobile ? widthLineChart : '100%'}
                  />
                )}
            </FlexContainer>
            <BoxAnalytics title={workloadKPI.taskWorkforceKpiDescription.title}>
              <Text
                text={workloadKPI.taskWorkforceKpiDescription.description}
                fontSize="12px"
                lineHeight="16px"
                color={Theme.colors.c505050}
                style={{ marginBottom: '16px' }}
              />
              <FlexContainer wrapContent>
                {workloadKPI.taskWorkforceKpi.map((task) =>
                  task.samples ? (
                    <PieChart
                      key={task.name}
                      samples={task.samples}
                      width={notMobile ? width : '100%'}
                      title={task.name}
                      count={task.value}
                      dailyRate={task.dailyRate!}
                    />
                  ) : null
                )}
              </FlexContainer>
            </BoxAnalytics>
          </BoxAnalytics>
        </>
      ) : (
        <LoaderContainer justifyContent="center" alignItems="center">
          <AroundLoader />
        </LoaderContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 16px 16px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const InnerContainer = styled(FlexContainer)`
  width: 33.3%;

  @media (max-width: 990px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const LoaderContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
`;
