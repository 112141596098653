import React, { FunctionComponent } from 'react';
import { ExecutionStatus } from '@pec-manager/graphql';
import { Text } from '../../components/common/Text';
import { Color, Theme } from '../../theme';
import styled from 'styled-components';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Close, Rolling, Success, Pause, Task } from '../../svg';

interface StateExecutionProps {
  state: ExecutionStatus;
  fontSize?: number;
  sizeCircle?: string;
  colorText?: string;
  task?: boolean;
}

const mapState = (state: ExecutionStatus, size = 0) => {
  switch (state) {
    case ExecutionStatus.COMPLETED:
      return {
        state: 'executionStateCompleted',
        color: Theme.colors.cFFFFFF,
        icon: (
          <SvgIcon
            svg={<Success />}
            width={`${size + 6}px`}
            color={Theme.colors.c52C41A}
          />
        ),
      };
    case ExecutionStatus.RUNNING:
      return {
        state: 'executionStateRunning',
        color: Theme.colors.c2F80ED,
        icon: (
          <SvgIcon
            svg={<Rolling />}
            width={`${size}px`}
            color={Theme.colors.cFFFFFF}
          />
        ),
      };
    case ExecutionStatus.CANCELED:
      return {
        state: 'executionStateCancelled',
        color: Theme.colors.cB7880E,
        icon: (
          <SvgIcon
            svg={<Pause />}
            width={`${size - 2}px`}
            color={Theme.colors.cFFFFFF}
          />
        ),
      };
    case ExecutionStatus.FAILED:
    default:
      return {
        state: 'executionStateFailed',
        color: Theme.colors.cEB5757,
        icon: (
          <SvgIcon
            svg={<Close />}
            width={`${size - 2}px`}
            color={Theme.colors.cFFFFFF}
          />
        ),
      };
  }
};

export const StateExecution: FunctionComponent<StateExecutionProps> = ({
  state,
  fontSize = 16,
  sizeCircle = '20px',
  colorText,
  task,
}) => {
  return (
    <Flex>
      {task ? (
        <SvgIcon
          svg={<Task />}
          width={`${sizeCircle + 6}px`}
          color={Theme.colors.c52C41A}
          style={{ marginRight: '8px' }}
        />
      ) : (
        <Circle color={mapState(state).color} sizeCircle={sizeCircle}>
          {mapState(state, fontSize).icon}
        </Circle>
      )}
      <HideOnSmallScreen>
        <Text
          text={task ? 'task' : mapState(state).state}
          fontSize={`${fontSize}px`}
          lineHeight={`${fontSize + 4}px`}
          color={colorText}
          ellipsis
        />
      </HideOnSmallScreen>
    </Flex>
  );
};

const Circle = styled.div<{ color: string | Color; sizeCircle?: string }>`
  background-color: ${({ color }) => color};
  height: ${({ sizeCircle }) => sizeCircle};
  width: ${({ sizeCircle }) => sizeCircle};
  min-height: ${({ sizeCircle }) => sizeCircle};
  min-width: ${({ sizeCircle }) => sizeCircle};
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const HideOnSmallScreen = styled.div`
  @media (max-width: 1300px) {
    display: none;
  }
`;
