import { MailSummary } from '@pec-manager/graphql';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { CorrelatedMailsItem } from './CorrelatedMailsItem';

interface CorrelatedMailsSectionProps {
  correlatedMailList: MailSummary[];
}

export const CorrelatedMailsSection: FunctionComponent<
  CorrelatedMailsSectionProps
> = ({ correlatedMailList }) => {
  const searchParams = new URLSearchParams(useLocation().search);

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 60 * correlatedMailList.length }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ type: 'just' }}
    >
      <Container>
        <motion.div exit={{ opacity: 0 }}>
          <BoxShadowMask />
          {correlatedMailList.map(
            ({ base: { id, subject, body, receivedAt } }, i) => (
              <CorrelatedMailsItem
                selected={id === searchParams.get('mailId')}
                id={id}
                key={id}
                title={subject}
                description={body}
                date={receivedAt as any as number}
              />
            )
          )}
        </motion.div>
      </Container>
    </motion.div>
  );
};

const BoxShadowMask = styled.div`
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.08);
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Container = styled.div`
  position: relative;
  & > div {
    & > div:not(:last-child) {
      & > div {
        border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
      }
    }
  }
`;
