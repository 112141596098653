const gqlLeadTimeKpiValueShape = `
  count
  avg
  min
  max
  stdDev
`;

const gqlLeadTimeKpiSampleShape = `
  label
  value {
      ${gqlLeadTimeKpiValueShape}
    }
`;

export const gqlLeadTimeKpiShape = `
    name
    value {
      ${gqlLeadTimeKpiValueShape}
    }
    samples {
      ${gqlLeadTimeKpiSampleShape}
    }
 `;

export const gqlSlaComplianceKpiValueShape = ` 
  count
  percent
  meet
`;

export const gqlSlaComplianceKpiSampleShape = ` 
  label
  value {
    ${gqlSlaComplianceKpiValueShape}
  }
`;

export const gqlSlaComplianceKpiShape = ` 
  name
  value {
    ${gqlSlaComplianceKpiValueShape}
  }
  samples {
    ${gqlSlaComplianceKpiSampleShape}
  }
  description
`;

export const gqlKpiDescriptionShape = `
  title
  description
`;

export const gqlProcessPerformanceKpiShape = `
  processLeadTimeKpi {
    ${gqlLeadTimeKpiShape}
  }
  taskDelayTimeKpi {
   ${gqlLeadTimeKpiShape}
  }
  taskLeadTimeKpi {
    ${gqlLeadTimeKpiShape}
  }
  milestoneLeadTimeKpi {
    ${gqlLeadTimeKpiShape}
  }
  
  processSlaComplianceKpi {
    ${gqlSlaComplianceKpiShape}
  }
  taskSlaComplianceKpi {
    ${gqlSlaComplianceKpiShape}
  }
  processLeadKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  taskDelayKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  taskLeadTimeKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  milestoneLeadTimeKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  processSlaComplianceKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  taskSlaComplianceKpiDescription {
    ${gqlKpiDescriptionShape}
  }  
  
  totalCompletedExecutionsLabel
  totalCompletedExecutionsValue
  processLeadTimeLabel
  processLeadTimeValue
  processDueDateComplianceLabel
  processDueDateComplianceValue
`;

export const gqlCountKpiShape = `
  name
  value
`;

export const gqlCountWithSamplesKpiShape = `
  name
  value
  samples {
    ${gqlCountKpiShape}
  }
  dailyRate
`;

export const gqlProcessWorkloadKpiResponseShape = `
  processWorkloadKpi {
    ${gqlCountWithSamplesKpiShape}
  }
  taskWorkloadKpi {
    ${gqlCountWithSamplesKpiShape}
  }

  processWorkforceKpi {
    ${gqlCountWithSamplesKpiShape}
  }
  taskWorkforceKpi {
    ${gqlCountWithSamplesKpiShape}
  }

  processWorkloadKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  taskWorkloadKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  processWorkforceKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  taskWorkforceKpiDescription {
    ${gqlKpiDescriptionShape}
  }
  
  totalCompletedExecutionsLabel
  totalCompletedExecutionsValue
  processLeadTimeLabel
  processLeadTimeValue
  processDueDateComplianceLabel
  processDueDateComplianceValue
`;

export const gqlKpiFiltersShape = `
  select_filters {
    selectId
    label
    options {
      label
      value
    }
    required
  }
`;

export const gqlWidgetDataShape = `
counter {
  label
  value
  icon
}
donutChart {
    label
  value
  labels
  colors
  series
  legend
}
pieChart {
    labels
  colors
  series
  legend
}
columnLineChart {
  xAxisCategories
  yAxisColumnLabel
  columnValues {
    name
    values
    color
  }
  columnStacked
  yAxisLineLabel
  lineValues
  lineColor
}
barChart {
  labels
  colors
  series
  }
 lineChart {
  labels
  color
  series
  }
`;

export const gqlWidgetShape = `
  widgetId
  label
  type
  data {
    ${gqlWidgetDataShape}
  }
  percent
  vertical
  `;

export const gqlKpiRowShape = `
  kpiId
  label
  description
  collapsed
  widgets {
    ${gqlWidgetShape}
  }
`;
