import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { IdentifiableValue, ResourceState } from '@pec-manager/graphql';
import { Text } from '../../../components/common/Text';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Color, Theme } from '../../../theme';
import { SintropiSidebarSectionItem } from './SintropiSidebarSectionItem';
import { KeycloakService } from '../../../services/KeycloakService';

export interface ISvgIcon {
  icon: JSX.Element;
  color?: Color | string;
}

export interface SintropiSidebarSectionElement {
  id: string;
  name: string;
  svgIcon: ISvgIcon;
  dropDownItems?: IdentifiableValue[];
}

interface SintropiSidebarSectionProps {
  sectionId: string;
  sectionName?: string;
  sectionElements: SintropiSidebarSectionElement[];
}

export const SintropiSidebarSection: FunctionComponent<
  SintropiSidebarSectionProps
> = ({ sectionId, sectionName, sectionElements }) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const hides: string[] = KeycloakService.getHideList();
  const location = useLocation();

  const isDocumentPath =
    location.pathname.search('document') > 0 ? 'DOCUMENT' : undefined;
  const isExecutionsPath =
    location.pathname.search('executions') > 0
      ? ResourceState.IN_PROGRESS
      : undefined;
  const isAnalyticsPath =
    location.pathname.search('analytics') > 0 ? 'ANALYTICS' : undefined;
  const isBusinessKpiPath =
    location.pathname.search('business-kpi') > 0 ? 'BUSINESS_KPI' : undefined;
  const isRecordPath =
    location.pathname.search('record') > 0 ? 'RECORD' : undefined;

  return (
    <StyledFlexContainer column alignSelf="stretch">
      {sectionName && (
        <StyledText
          text={sectionName}
          fontSize="12px"
          lineHeight="18px"
          color={Theme.colors.c838383}
        />
      )}
      {sectionElements.map(({ dropDownItems, svgIcon, name, id }) =>
        !hides.includes(name) &&
        (!dropDownItems || dropDownItems.length > 0) ? (
          <SintropiSidebarSectionItem
            key={id}
            id={id}
            sectionId={sectionId}
            isSelected={
              (sectionId === searchParams.get('sectionId') &&
                id === searchParams.get('elementId')) ||
              id === isDocumentPath ||
              id === isExecutionsPath ||
              id === isAnalyticsPath ||
              id === isBusinessKpiPath ||
              id === isRecordPath
            }
            text={name}
            svgIcon={svgIcon}
            dropDownItems={dropDownItems}
          />
        ) : null
      )}
    </StyledFlexContainer>
  );
};

const StyledFlexContainer = styled(FlexContainer)`
  margin-top: 32px;

  & > div {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  & > span + div {
    margin-top: 0;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 24px;
`;
