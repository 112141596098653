import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BlobRef, BlobRefOrFolder } from '@pec-manager/graphql';
import { Text } from '../../../common/Text';
import { FlexContainer } from '../../../layout/FlexContainer';
import { AttachmentPreview } from '../../../common/AttachmentPreview';
import { HorizontalLoader } from '../../../loader/HorizontalLoader';
import { Theme } from '../../../../theme';
import { extractTypeToMimetype } from '../../../common/AttachmentPreview/extractThumbnail';

interface FolderSectionProps {
  setBlobRefChoices: (f: BlobRef[]) => void;
  blobRefChoices: BlobRef[];
  allowedFiles: string[];
  blobRefList: BlobRefOrFolder[];
  loading: boolean;
}

export const AttachmentsSection: FunctionComponent<FolderSectionProps> = ({
  setBlobRefChoices,
  blobRefList,
  allowedFiles,
  loading,
  blobRefChoices,
}) => (
  <Container>
    <div style={{ height: '13px', display: 'flex' }}>
      <Text text="file" fontSize="12px" lineHeight="18px" />
    </div>
    <ContainerAttachments wrapContent>
      {loading && (
        <HorizontalLoader size="l" top="33px" color={Theme.colors.c4C9AFF} />
      )}
      {!loading && blobRefList.length > 0 ? (
        blobRefList.map(({ blobRef }: BlobRefOrFolder) => {
          if (blobRef)
            return (
              <div
                key={blobRef?.id}
                style={{ marginRight: '33px', marginBottom: '10px' }}
                onClick={() => {
                  if (blobRefChoices.find((c) => c.id === blobRef!.id)) {
                    setBlobRefChoices(
                      blobRefChoices.filter((c) => c.id !== blobRef!.id)
                    );
                  } else if (
                    allowedFiles.length === 0 ||
                    allowedFiles.includes(blobRef!.mimeType)
                  )
                    setBlobRefChoices([...blobRefChoices, blobRef!]);
                }}
              >
                <AttachmentPreview
                  cursorOnAttachment
                  disabled={
                    allowedFiles.length > 0 &&
                    allowedFiles.includes(
                      extractTypeToMimetype(blobRef!.mimeType)
                    )
                  }
                  shrinkYPx={0}
                  attachment={blobRef!}
                  selected={!!blobRefChoices.find((c) => c.id === blobRef!.id)}
                />
              </div>
            );
          else return null;
        })
      ) : (
        <EmptyAttachments
          text="notAttachment"
          fontSize="13px"
          lineHeight="13px"
          color={Theme.colors.c838383}
        />
      )}
    </ContainerAttachments>
  </Container>
);

const Container = styled.div`
  padding-left: 16px;
  margin: 24px 0 12px 0;
`;

const ContainerAttachments = styled(FlexContainer)`
  padding: 12px 0;
`;

const EmptyAttachments = styled(Text)`
  text-align: center;
`;
