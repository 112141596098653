import { gqlUserResolutionShape } from '../user/shapes';
import { gqlBlobRefShape } from '../document/shapes';

export const gqlTaskBaseResolutionShape = `
    id
    createdAt
    expiresAt
    name
    description
    priority
    category
    referencedProcessName
    referencedProcessCompletionPercentage
    executionStartUser {
      ${gqlUserResolutionShape}
    }
    inCharge {
      ${gqlUserResolutionShape}
    }
    isManageable
    isReassignable
    rootExecutionId
    executionId
    status
    assignees {
      group {
        id
        name
        members {
         ${gqlUserResolutionShape}
        }
      }
      user {
        ${gqlUserResolutionShape}
      }
    }
`;

export const gqlTaskSummaryResolutionShape = `
      base {
        ${gqlTaskBaseResolutionShape}
      }
      attachmentCount
      commentCount
`;
export const gqlTaskSummaryPagingWrapperShape = `
      totalElements
      pageElements {
        ${gqlTaskSummaryResolutionShape}
      }
`;

export const gqlFullTaskResolutionShape = `
      base {
        ${gqlTaskBaseResolutionShape}
      }
      actions {
        type
        priority
      }
      attachments {
        ${gqlBlobRefShape}
      }
      outputSections {
        name
        fields {
          name
          values {
            value
            user {
              ${gqlUserResolutionShape}
            }
          }
          type
        }
      }
`;
export const gqlTaskProgressFlowShape = `
      taskId
      taskDisplayName
      taskCategory
      progressSteps {
        occurredAt
        type
        formDefinitionInvocationArgs {
          id
          context
        }
      }
`;
export const gqlTaskProgressEventShape = `
      taskId
      taskDisplayName
      taskCategory
      progressStep {
        occurredAt
        type
        formDefinitionInvocationArgs {
          id
          context
        }
      }
`;
