import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { BlobRef } from '@pec-manager/graphql';
import { Text } from '../Text';
import { SvgIcon } from '../SvgIcon';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../layout/FlexContainer';
import { AttachmentPreviewModal } from '../../modals/AttachmentPreviewModal';
import { Eye, Download, More } from '../../../svg';
import { downloadAndSave } from '../../../utils/file';
import { extractThumbnailFromName } from './extractThumbnail';
import { MenuChoices } from '../../PagedFormDefinition/MenuChoices';

interface AttachmentPreviewProps {
  attachment: BlobRef;
  shrinkYPx?: number;
  selected?: boolean;
  category?: string;
  disabled?: boolean;
  cursorOnAttachment?: boolean;
}

export const AttachmentPreview: React.FunctionComponent<
  AttachmentPreviewProps
> = ({
  attachment,
  shrinkYPx = 0,
  selected,
  category,
  disabled,
  cursorOnAttachment,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  return (
    <Container
      shrinkYPx={shrinkYPx}
      selected={selected}
      disabled={disabled}
      cursorPointer={cursorOnAttachment}
    >
      {extractThumbnailFromName(attachment?.mimeType, attachment.name)
        ?.extension && (
        <ExtensionLabel alignItems="center" justifyContent="center">
          <Text
            text={
              extractThumbnailFromName(attachment.mimeType, attachment.name)
                .extension
            }
            fontSize="10px"
            lineHeight="12px"
            color="white"
            skipTranslation
            ellipsis
          />
        </ExtensionLabel>
      )}
      {category && (
        <ExtensionLabel
          alignItems="center"
          justifyContent="center"
          backgroundColor={Theme.colors.c5B84EF}
        >
          <Text
            text={category}
            fontSize="10px"
            lineHeight="12px"
            color="white"
            skipTranslation
            ellipsis
          />
        </ExtensionLabel>
      )}
      <AttachmentPreviewImage
        thumbnail={attachment.thumbnail}
        shrinkYPx={shrinkYPx}
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {!attachment.thumbnail && (
          <SvgIcon
            svg={
              extractThumbnailFromName(attachment.mimeType, attachment.name)
                .thumbnail
            }
            width="60px"
            height="60px"
            color={Theme.colors.cF0F0F0}
          />
        )}
      </AttachmentPreviewImage>
      <BottomSection alignItems="center" width="100%" height="30px">
        <TextContainer>
          <Text
            text={attachment.name}
            fontSize="10px"
            lineHeight="28px"
            skipTranslation
            tooltipText={attachment.name}
          />
        </TextContainer>
        <SvgContainer>
          {attachment.mimeType === 'application/pdf' && (
            <PreviewContainer onClick={() => setShowPreview(true)}>
              <SvgIcon
                svg={<Eye />}
                width="25px"
                height="15px"
                color={Theme.colors.c0E53B7}
              />
            </PreviewContainer>
          )}
          {category ? (
            <div
              style={{ position: 'relative' }}
              onClick={() => setOpenMenu((prevState) => !prevState)}
            >
              <SvgIcon
                svg={<More />}
                height="15px"
                width="25px"
                color={Theme.colors.c0E53B7}
              />
              {openMenu && (
                <MenuChoices
                  containerMenuProps={{ right: '-8px', top: '28px' }}
                  choices={[
                    {
                      text: 'Dettaglio',
                      onClick: () => {},
                    },
                    {
                      text: 'download',
                      onClick: async () => {
                        await downloadAndSave(attachment.downloadUrl);
                      },
                    },
                  ]}
                  onClickOutside={() => setOpenMenu(false)}
                />
              )}
            </div>
          ) : (
            <div
              onClick={async () => {
                await downloadAndSave(attachment.downloadUrl);
              }}
            >
              <SvgIcon
                svg={<Download />}
                height="15px"
                width="25px"
                color={Theme.colors.c0E53B7}
              />
            </div>
          )}
        </SvgContainer>
      </BottomSection>
      {showPreview && (
        <AttachmentPreviewModal
          closeOnClick={() => setShowPreview(false)}
          downloadUrl={attachment.downloadUrl}
          nameFile={attachment.name}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{
  shrinkYPx: number;
  selected?: boolean;
  disabled?: boolean;
  cursorPointer?: boolean;
}>`
  position: relative;
  height: ${({ shrinkYPx }) => 210 - shrinkYPx}px;
  width: 147px;
  border-radius: 4px;
  ${({ cursorPointer, disabled }) =>
    cursorPointer && !disabled && 'cursor: pointer;'};
  ${({ disabled, theme }) =>
    disabled && `background-color: ${theme.colors.transparentGrey}`};

  box-shadow: 0 0 9px
    ${({ selected }) =>
      selected ? 'rgba(68, 89, 193, 0.5)' : 'rgba(0, 0, 0, 0.15)'};
`;

const ExtensionLabel = styled(FlexContainer)<{
  backgroundColor?: string;
  colorText?: string;
}>`
  position: absolute;
  top: ${({ backgroundColor }) => (backgroundColor ? '35px' : '8px')};
  right: -4px;
  color: ${({ colorText, theme }) => colorText || theme.colors.c000000};
  width: fit-content;
  padding: 0 8px;
  height: 20px;
  border-radius: 2px;
  backdrop-filter: blur(40px);
  background: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.cFF8686};
`;

const AttachmentPreviewImage = styled(FlexContainer)<{
  thumbnail?: string;
  shrinkYPx: number;
}>`
  height: ${({ shrinkYPx }) => 179 - shrinkYPx}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  border-radius: 4px 4px 0 0;
  ${({ thumbnail }) =>
    thumbnail &&
    css`
      background-image: url('data:image/png;base64,${thumbnail}');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `}
`;

const BottomSection = styled(FlexContainer)`
  padding: 8px;
`;

const SvgContainer = styled(FlexContainer)`
  margin-left: auto;
  & > div,
  a {
    padding: 4px 4px;
    border-radius: 100%;
    transition: 150ms background ease-in-out;
    &:hover {
      background: ${({ theme }) => theme.colors.transparentGrey};
      cursor: pointer;
    }
  }
`;

const PreviewContainer = styled.div`
  & > div {
    display: flex;
    padding-top: 1px;
  }
`;
const TextContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
`;
