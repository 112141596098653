import React, { FC, useEffect, useState } from 'react';
import { Theme } from '../../theme';
import { KeycloakService } from '../../services/KeycloakService';
import { useParams } from 'react-router-dom';
import { ContainerModal } from '../modals/ContainerModal';
import './dynamic-form.css';
import styled from 'styled-components';
import { ExecutionContext } from '@pec-manager/graphql/lib/graphql.types';
import { trasformInitialContext } from '../../utils/funcUtils';

interface DynamicFormProps {
  processName?: string;
  context?: any;
  columnsForm?: number;
  executionContext: ExecutionContext;
  externalPoint?: string;
  onSuccess: () => void;
  onCancel: () => void;
  fileViewer?: string;
  documentId?: string;
  folderId?: string;
  taskId?: string;
  processId?: string;
}

function randomString(length: number): string {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const DynamicForm: FC<DynamicFormProps> = ({
  processName,
  taskId,
  processId,
  columnsForm,
  externalPoint,
  documentId,
  folderId,
  context,
  executionContext,
  onSuccess,
  fileViewer,
  onCancel,
}) => {
  const [closed, setClosed] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  const [reference] = useState<string>(randomString(5));
  const { appId } = useParams<{ appId: string }>();

  useEffect(() => {
    const handleSuccess = (e: any) => {
      console.log('handleSuccess', e);
      setStatus('success');
    };
    const handleError = (e: any) => {
      console.log('handleError', e);
      setStatus('error');
    };

    const handleClose = (e: any) => {
      console.log('handleClose', e);
      setClosed(true);
    };

    console.log('fileViewer', fileViewer);

    console.log('add event listener with reference', reference);
    window.addEventListener(`${reference}-success`, handleSuccess);
    window.addEventListener(`${reference}-error`, handleError);
    window.addEventListener(`${reference}-close`, handleClose);
    return () => {
      console.log('remove event listener with reference', reference);
      window.removeEventListener(`${reference}-success`, handleSuccess);
      window.removeEventListener(`${reference}-error`, handleError);
      window.removeEventListener(`${reference}-close`, handleClose);
    };
  }, [reference]);

  useEffect(() => {
    if (closed) {
      setTimeout(() => {
        if (status === 'success') {
          onSuccess();
        } else {
          onCancel();
        }
      }, 500);
    }
  }, [status, onCancel, onSuccess, closed]);

  const newContext = (context || [{}]).flat();

  return (
    <ContainerModal position="fixed">
      <Container onClick={(e) => e.stopPropagation()}>
        <dynamic-form
          reference={reference}
          app_id={appId || ''}
          name={processName}
          id={taskId}
          process_id={processId}
          ext_point={externalPoint || ''}
          columns_form={columnsForm}
          title_enabled={true}
          file_viewer={fileViewer}
          document_id={documentId}
          folder_id={folderId}
          execution_context={executionContext}
          initial_context={JSON.stringify(trasformInitialContext(newContext))}
          token={KeycloakService.getToken() || ''}
          locale={KeycloakService.getLocale() || 'en'}
          theme={JSON.stringify(Theme)}
          style={{ display: 'flex', maxHeight: '100%' }}
        />
      </Container>
    </ContainerModal>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  min-width: 940px;

  @media (max-width: 990px) {
    height: calc((var(--vh, 1vh) * 100));
    width: 100%;
  }
`;
