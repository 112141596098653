import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Attachment, Comment } from '../../../svg';

interface AttachmentsCommentsSectionProps {
  numberOfAttachments: number;
  numberOfComments: number;
}

export const AttachmentsCommentsSection: FunctionComponent<
  AttachmentsCommentsSectionProps
> = ({ numberOfComments, numberOfAttachments }) => (
  <Container>
    <TipsContainer alignItems="center">
      <SvgIcon
        svg={<Attachment />}
        height="10px"
        width="10px"
        rotateDeg={20}
        color={Theme.colors.c505050}
      />
      <Text
        text="numberOfAttachments"
        translationArgs={{ numberOfAttachments }}
        fontSize="10px"
        lineHeight="15px"
        color={Theme.colors.c838383}
      />
    </TipsContainer>
    <TipsContainer alignItems="center">
      <SvgIcon
        svg={<Comment />}
        height="10px"
        width="10px"
        color={Theme.colors.cFF8686}
      />
      <Text
        text="numberOfComments"
        translationArgs={{ numberOfComments }}
        fontSize="10px"
        lineHeight="15px"
        color={Theme.colors.c838383}
      />
    </TipsContainer>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 12px;
`;

const TipsContainer = styled(FlexContainer)`
  & > span {
    margin-left: 5px;
  }

  &:nth-child(2) {
    margin-left: 16px;
  }
`;
