import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from './Text';
import { FlexContainer } from '../layout/FlexContainer';
import { Theme } from '../../theme';
import { useGauge } from 'use-gauge';
import cc from 'classcat';

interface GaugeGenericProps {
  defaultValue: number;
  percentageWidth?: number;
  title?: string;
  description?: string;
}

const START_ANGLE = 70;
const END_ANGLE = 290;

export const GaugeGenericPercentage: FunctionComponent<GaugeGenericProps> = ({
  defaultValue,
  percentageWidth = 50,
  title,
  description,
}) => {
  const gauge = useGauge({
    domain: [0, 100],
    startAngle: START_ANGLE,
    endAngle: END_ANGLE,
    numTicks: 51,
    diameter: 250,
  });

  const needle = gauge.getNeedleProps({
    value: defaultValue,
    baseRadius: 12,
    tipRadius: 1,
  });

  return (
    <Container percentageWidth={percentageWidth}>
      <TopContainer column alignItems="center">
        {title && (
          <Text
            text={title}
            fontSize="20px"
            lineHeight="30px"
            color={Theme.colors.c505050}
            fontWeight="300"
            style={{ marginBottom: '30px' }}
          />
        )}
        <div className="p-4">
          <svg className="w-full overflow-visible p-2" {...gauge.getSVGProps()}>
            <g id="arcs">
              <path
                {...gauge.getArcProps({
                  offset: -32,
                  startAngle: START_ANGLE,
                  endAngle: END_ANGLE,
                })}
                fill="none"
                className="stroke-gray-200"
                strokeWidth={36}
              />
              <path
                {...gauge.getArcProps({
                  offset: -32,
                  startAngle: START_ANGLE,
                  endAngle: gauge.valueToAngle(defaultValue),
                })}
                fill="none"
                className={cc([
                  {
                    'stroke-red-300':
                      gauge.valueToAngle(defaultValue) - START_ANGLE <=
                      (220 * 70) / 100,
                    'stroke-orange-300':
                      gauge.valueToAngle(defaultValue) - START_ANGLE >
                        (220 * 70) / 100 &&
                      gauge.valueToAngle(defaultValue) - START_ANGLE <
                        (220 * 80) / 100,
                    'stroke-green-400':
                      gauge.valueToAngle(defaultValue) - START_ANGLE >=
                      (220 * 80) / 100,
                  },
                ])}
                strokeWidth={36}
              />
            </g>
            <g id="ticks">
              {gauge.ticks.map((angle, index) => {
                const asValue = gauge.angleToValue(angle);
                const showTextInPercentage = index % 10 === 0;

                const lengthTick = showTextInPercentage ? 14 : 10;

                return (
                  <React.Fragment key={`tick-group-${angle}`}>
                    <line
                      className={cc([
                        {
                          'stroke-red-400': asValue <= (100 * 70) / 100,
                          'stroke-orange-300':
                            asValue > (100 * 70) / 100 &&
                            asValue < (100 * 80) / 100,
                          'stroke-green-300': asValue >= (100 * 80) / 100,
                        },
                      ])}
                      strokeWidth={2}
                      {...gauge.getTickProps({
                        angle,
                        length: lengthTick,
                      })}
                    />
                    {showTextInPercentage && (
                      <text
                        className="text-sm fill-gray-400 font-medium"
                        {...gauge.getLabelProps({ angle, offset: -32 })}
                      >
                        {asValue}
                      </text>
                    )}
                  </React.Fragment>
                );
              })}
            </g>
            <g id="needle">
              <circle className="fill-white" {...needle.base} r={20} />

              <line
                className="stroke-gray-600"
                strokeLinecap="round"
                strokeWidth={4}
                x1={needle.base.cx}
                x2={needle.tip.cx}
                y1={needle.base.cy}
                y2={needle.tip.cy}
              />
            </g>
          </svg>
        </div>
        <Text
          text={`${defaultValue}%`}
          fontSize="28px"
          lineHeight="34px"
          fontWeight="600"
        />
        {description && (
          <Text
            text={description}
            fontSize="18px"
            lineHeight="30px"
            color={Theme.colors.c505050}
            fontWeight="300"
            style={{ marginTop: '20px' }}
          />
        )}
      </TopContainer>
    </Container>
  );
};

const Container = styled.div<{ percentageWidth: number }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  width: ${({ percentageWidth }) => percentageWidth}%;

  @media (max-width: 990px) {
    width: 100%;
  }
`;

const TopContainer = styled(FlexContainer)`
  margin-bottom: 24px;
`;
