import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Theme } from '../../../theme';
import { Text } from '../../../components/common/Text';
import { TaskSummary } from '@pec-manager/graphql';
import styled from 'styled-components';
import { ActivityProcessItem } from './ActivityProcessItem';
import { KeycloakService } from '../../../services/KeycloakService';

interface ActivityProcessProps {
  activities: TaskSummary[];
}

export const ActivityProcess: FunctionComponent<ActivityProcessProps> = ({
  activities,
}) => (
  <FlexContainer
    column
    style={{
      width: '100%',
    }}
  >
    <ListField>
      <ItemBig>
        <Text
          text="description"
          fontSize="14px"
          lineHeight="14px"
          color={Theme.colors.c505050}
          fontWeight="500"
          style={{ textTransform: 'uppercase' }}
        />
      </ItemBig>
      <ItemSmall>
        <Text
          text="users"
          fontSize="14px"
          lineHeight="14px"
          color={Theme.colors.c505050}
          fontWeight="500"
          style={{ textTransform: 'uppercase' }}
        />
      </ItemSmall>
      {!KeycloakService.removeFieldInExecution().includes('dueDate') && (
        <ItemSmall>
          <Text
            text="dueDate"
            fontSize="14px"
            lineHeight="14px"
            color={Theme.colors.c505050}
            fontWeight="500"
            style={{ textTransform: 'uppercase' }}
          />
        </ItemSmall>
      )}
      {!KeycloakService.removeFieldInExecution().includes('priority') && (
        <ItemSmall>
          <Text
            text="priority"
            fontSize="14px"
            lineHeight="14px"
            color={Theme.colors.c505050}
            fontWeight="500"
            style={{ textTransform: 'uppercase' }}
          />
        </ItemSmall>
      )}
    </ListField>
    <ListContainer column>
      {activities && activities.length > 0 ? (
        activities.map((activity) => (
          <ActivityProcessItem key={activity.base.id} activity={activity} />
        ))
      ) : (
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', height: '120px' }}
        >
          <Text text="emptyList" fontSize="12px" lineHeight="16px" />
        </FlexContainer>
      )}
    </ListContainer>
  </FlexContainer>
);

const ListField = styled(FlexContainer)`
  height: 40px;
  width: calc(100% - 40px);
  padding-left: 20px;
  background-color: ${({ theme }) => theme.colors.cF0F0F0};

  & > div:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 990px) {
    display: none;
  }
`;

const ItemBig = styled.div`
  height: 40px;
  flex: 2;
  display: flex;
  align-items: center;
`;

const ItemSmall = styled.div`
  height: 40px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const ListContainer = styled(FlexContainer)`
  margin-bottom: 16px;
`;
