import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Text } from '../../components/common/Text';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Theme } from '../../theme';
import {
  ExecutionContext,
  Record,
  SortField,
  Table,
} from '@pec-manager/graphql';
import { ItemRecordList } from './ItemRecordList';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { useRecords } from '../../components/hooks/useRecords';
import { useLazyQuery } from '@apollo/client';
import { GET_START_PROCESS_BY_NAME } from '../../graphql/form/queries';
import { useParams } from 'react-router-dom';
import { calcInitialContextForRecord } from './ItemRecordList/utils';
import { CircleLoader } from '../../components/common/CircleLoader';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Arrow } from '../../svg';
import { DynamicForm } from '../../components/DynamicForm';
import { trasformStringInUppercaseForRecord } from '../../utils/stringUtils';

interface ListRecordsProps {
  appCode?: string;
  searchRecords?: string;
  table?: Table;
  forceRefetch?: boolean;
  setForceRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ListRecords: FC<ListRecordsProps> = ({
  appCode,
  table,
  searchRecords,
  setForceRefetch,
  forceRefetch,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const [openMenu, setOpenMenu] = useState<string | undefined>(undefined);
  const [sortField, setSortField] = useState<SortField[]>([]);

  const { records, hasMore, loading, fetchMore, refetch } = useRecords(
    appCode || '',
    table?.name || '',
    searchRecords || '',
    sortField
  );

  useEffect(() => {
    if (forceRefetch) {
      refetch();
      setForceRefetch && setForceRefetch(false);
    }
    // eslint-disable-next-line
  }, [forceRefetch]);

  const [getStartProcessByName] = useLazyQuery(GET_START_PROCESS_BY_NAME);

  const [modalProcessOpened, setModalProcessOpened] = useState(false);
  const [nameToPassToForm, setNameToPassToFormClicked] = useState<string>('');
  const [initialContext, setInitialContext] = useState<any>(undefined);

  const tableItemActions = useMemo(
    () => (record: Record) => {
      return (
        table?.itemActions?.map((action) => {
          return {
            text: action.label,
            onClick: async () => {
              await getStartProcessByName({
                variables: {
                  processName: action.processName,
                  appId,
                },
              }).then((res) => {
                setNameToPassToFormClicked(
                  res?.data?.getStartProcessByName?.id || undefined
                );
                const calcInitialContext = record.columns?.nested?.map((c) => ({
                  key: c.name,
                  values:
                    c.value?.list && c.value.list.length > 0
                      ? [...calcInitialContextForRecord(c.value)]
                      : [calcInitialContextForRecord(c.value)],
                }));
                setInitialContext(calcInitialContext || undefined);
              });
              setModalProcessOpened(true);
            },
          };
        }) || []
      );
    },
    [appId, getStartProcessByName, table?.itemActions]
  );

  const onClickSort = useCallback(
    (sortableField: string) => {
      const field = sortField.find((f) => f.field === sortableField);
      if (!!field) {
        if (field.asc) {
          return setSortField([
            ...sortField.filter((f) => f.field !== sortableField),
            {
              field: sortableField,
              asc: false,
            },
          ]);
        } else {
          return setSortField(
            sortField.filter((f) => f.field !== sortableField)
          );
        }
      } else {
        setSortField([
          ...sortField,
          {
            field: sortableField,
            asc: true,
          },
        ]);
      }
    },
    [sortField]
  );

  const calcSortIcon = useCallback(
    (sortableField: string) => {
      const field = sortField.find((f) => f.field === sortableField);
      if (!!field) {
        if (field.asc) {
          return (
            <SvgIcon
              svg={<Arrow />}
              width="16px"
              rotateDeg={180}
              color={Theme.colors.c2F80ED}
              style={{ marginLeft: '8px' }}
            />
          );
        } else {
          return (
            <SvgIcon
              svg={<Arrow />}
              width="16px"
              color={Theme.colors.c2F80ED}
              style={{ marginLeft: '8px' }}
            />
          );
        }
      }
      return null;
    },
    [sortField]
  );

  const resetForm = () => {
    setNameToPassToFormClicked('');
    setModalProcessOpened(false);
    setInitialContext(undefined);
  };

  return (
    <Container>
      {table?.listDisplay && table?.listDisplay.length > 0 && (
        <ListField
          alignItems="center"
          minusWidthList={records.length > 12 ? 48 : 40}
        >
          {table.listDisplay.map((label, index) => (
            <Item
              elementWidth={table.listDisplay!.length}
              onClick={() => onClickSort(label)}
              style={{ cursor: 'pointer' }}
            >
              <Text
                text={trasformStringInUppercaseForRecord(label)}
                fontSize="14px"
                lineHeight="14px"
                style={{ textTransform: 'uppercase' }}
                ellipsis
                tooltipText={label}
              />
              {calcSortIcon(label)}
            </Item>
          ))}
          <ItemMenuChoices />
        </ListField>
      )}
      <ContainerScroll id="containerScroll">
        <InfiniteScroll
          style={{
            paddingBottom: '60px',
            overflow: 'hidden',
          }}
          dataLength={records.length}
          next={fetchMore}
          hasMore={hasMore}
          scrollableTarget="containerScroll"
          loader={
            <p className="text-center">
              <Text
                text="loading"
                className="font-bold"
                fontSize="12px"
                lineHeight="14px"
                color={Theme.colors.c505050}
              />
            </p>
          }
          endMessage={
            <p className="text-center">
              <Text
                text="endResults"
                className="font-bold"
                fontSize="12px"
                lineHeight="14px"
                color={Theme.colors.cDCDCDC}
              />
            </p>
          }
        >
          {records.map((record: Record) => (
            <ItemRecordList
              key={record.id}
              record={record}
              listLabel={table?.listDisplay || []}
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              choicesItem={(record) => tableItemActions(record)}
              resetForm={resetForm}
            />
          ))}
          {loading && records && (
            <FlexContainer
              alignItems="center"
              justifyContent="center"
              style={{ width: '100%', height: '80px' }}
            >
              <CircleLoader thicknessInPx="4px" sizeInPx="36px" />
            </FlexContainer>
          )}
          {table && records.length === 0 && !loading && (
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%', height: '120px' }}
            >
              <Text text="emptyList" fontSize="12px" lineHeight="16px" />
            </FlexContainer>
          )}
        </InfiniteScroll>
      </ContainerScroll>
      {modalProcessOpened && !!nameToPassToForm && (
        <DynamicForm
          executionContext={ExecutionContext.START}
          context={initialContext || [{}]}
          columnsForm={1}
          processId={nameToPassToForm}
          onCancel={resetForm}
          onSuccess={() => {
            setForceRefetch && setForceRefetch(true);
            resetForm();
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  z-index: 0;
  background-color: rgb(245, 245, 245);
`;

const ContainerScroll = styled.div`
  padding: 0 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ListField = styled(FlexContainer)<{ minusWidthList: number }>`
  height: 40px;
  width: calc(100% - ${({ minusWidthList }) => minusWidthList}px);
  margin: 16px 0 0 20px;
  background-color: ${({ theme }) => theme.colors.cF0F0F0};

  & > div:not(:first-child, :last-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    height: 30px;
  }
  & > div {
    padding-left: 16px;
  }
`;

const Item = styled.div<{ elementWidth: number }>`
  width: ${({ elementWidth }) => 100 / elementWidth}%;
  display: flex;
  align-items: center;
`;

export const ItemMenuChoices = styled.div`
  height: 30px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 !important;
`;
