import React, { FunctionComponent, useEffect, useState } from 'react';
import { Comment, ResourceType, User } from '@pec-manager/graphql';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Mention, MentionsInput } from 'react-mentions';
import { useLocation } from 'react-router-dom';
import { CommentSection } from '../../inbox/MailDetails/ActivitySection/CommentSection';
import { RoundedTextAvatar } from '../avatar/RoundedTextAvatar';
import { KeycloakService } from '../../services/KeycloakService';
import { useMutationHook } from '../hooks/useMutationHook';
import { ADD_COMMENT } from '../../graphql/comment/mutations';
import { FlexContainer } from '../layout/FlexContainer';
import { FIND_USERS } from '../../graphql/user/queries';
import { GET_COMMENTS_BY_RESOURCE } from '../../graphql/comment/queries';
import { COMMENTS_PAGE_SIZE } from '../../constant';
import defaultStyle from './defaultStyle';

interface CommentsRightProps {
  resourceId: string;
}

export const CommentsRight: FunctionComponent<CommentsRightProps> = ({
  resourceId,
}) => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(useLocation().search);
  const taskId = searchParams.get('taskId');

  const { data: comments, refetch } = useQuery(GET_COMMENTS_BY_RESOURCE, {
    variables: {
      resource: {
        id: resourceId,
        type: ResourceType.MAIL,
      },
      pagination: {
        offset: 0,
        limit: COMMENTS_PAGE_SIZE,
      },
    },
  });

  const [inputValue, setInputValue] = useState('');
  const [addComment] = useMutationHook({
    mutationGql: ADD_COMMENT,
    errorMessage: 'genericErrorMessage',
  });
  const [mentions, setMentions] = useState<string[]>([]);
  const { data: searchUser } = useQuery(FIND_USERS);

  const [users, setUsers] = useState<{ id: string; display: string }[]>([]);

  useEffect(() => {
    if (searchUser) {
      setUsers(
        searchUser.findUsers.map((u: User) => ({
          id: u.id,
          display: `${u.firstName} ${u.lastName}`,
        }))
      );
    }
  }, [searchUser]);

  return (
    <ContainerSectionComments column>
      <>
        {resourceId &&
          comments?.getCommentsByResource.pageElements.map(
            (comment: Comment) => (
              <CommentSection
                refetchComments={refetch}
                users={users}
                key={comment.id}
                resourceId={resourceId}
                comment={comment}
              />
            )
          )}
      </>
      <InputContainer>
        <RoundedTextAvatar
          size={24}
          text={KeycloakService.getUsername()}
          fontSize={10}
        />
        <MentionsInput
          value={inputValue}
          onChange={(event, newValue, newSet, mentionsItem) => {
            setMentions(mentionsItem.map((m) => m.id));
            setInputValue(newValue);
          }}
          style={defaultStyle}
          placeholder={t('addComment') as string}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputValue) {
              addComment({
                variables: {
                  resource: {
                    id: resourceId,
                    type: taskId ? ResourceType.TASK : ResourceType.MAIL,
                  },
                  resourceName: KeycloakService.getFullName(),
                  body: inputValue,
                  mentionIds: mentions,
                },
              }).then(() => {
                setInputValue('');
                refetch();
              });
            }
          }}
        >
          <Mention
            trigger="@"
            data={users}
            displayTransform={(id, display) => display}
            style={{
              backgroundColor: 'rgba(205, 205, 205, 0.5)',
              borderRadius: '4px',
            }}
          />
        </MentionsInput>
      </InputContainer>
    </ContainerSectionComments>
  );
};

const ContainerSectionComments = styled(FlexContainer)`
  margin-top: 32px;
  padding-right: 16px;
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 0;
  position: fixed;
  bottom: 0;
  width: calc(400px - 16px);
  & > div:first-child {
    margin-right: 8px;
  }
`;
