import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { ProcessIcon, Task } from '../../../svg';

interface AttachmentsCommentsSectionProps {
  numberOfTask: number;
  numberOfSubExecution: number;
}

export const TaskSubExecutionSection: FunctionComponent<
  AttachmentsCommentsSectionProps
> = ({ numberOfTask, numberOfSubExecution }) => (
  <Container>
    <TipsContainer alignItems="center">
      <SvgIcon
        svg={<Task />}
        height="10px"
        width="10px"
        color={Theme.colors.c52C41A}
      />
      <Text
        text="numberOfTasks"
        translationArgs={{ numberOfTask }}
        fontSize="10px"
        lineHeight="15px"
        color={Theme.colors.c838383}
      />
    </TipsContainer>
    <TipsContainer alignItems="center">
      <SvgIcon
        svg={<ProcessIcon />}
        height="14px"
        width="14px"
        color={Theme.colors.c2F80ED}
      />
      <Text
        text="numberOffSubProcess"
        translationArgs={{ numberOfSubExecution }}
        fontSize="10px"
        lineHeight="15px"
        color={Theme.colors.c505050}
      />
    </TipsContainer>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  margin: 12px 0;
`;

const TipsContainer = styled(FlexContainer)`
  & > span {
    margin-left: 5px;
  }

  &:nth-child(2) {
    margin-left: 16px;
  }
`;
