import { useLazyQuery } from '@apollo/client';
import {
  GET_TASKS_BY_ASSIGNMENT_TYPE_WITHOUT_DUE_DATE,
  GET_TASKS_BY_STATE_WITHOUT_DUE_DATE,
  GET_TASKS_IN_INBOX_WITHOUT_DUE_DATE,
} from '../../graphql/task/queries';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { SintropiSidebarDefaultSectionId } from '../../sintropi/sidebar/sintropiSidebarDefaultSectionId';
import { taskStateKeys } from '../../constant';
import { SintropiSidebarMainSectionElementId } from '../../sintropi/sidebar/sintropiSidebarDefaultSectionElements';
import { gqlName } from '../../graphql/utils';
import {
  appendTasksWithoutDueDate,
  clearTaskWithoutDueDateList,
} from '../../redux/Sintropi/task/actions';
import { useDispatch, useSelector } from 'react-redux';
import { taskStateSelector } from '../../redux/Sintropi/task/selectors';

export const useGetTasksInCalendarWithoutDate = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { appId } = useParams();
  const sectionId = searchParams.get('sectionId');
  const sectionElementId = searchParams.get('elementId');
  const sectionElementDropDownElementId = searchParams.get('dropDownId');
  const { tasksWithoutDueDate } = useSelector(taskStateSelector);
  const dispatch = useDispatch();

  const { gqlQuery, gqlQueryVariables } = useMemo(() => {
    let query: any | null = null;
    let queryVariables: any = {};

    if (appId && sectionElementId) {
      switch (sectionId) {
        case SintropiSidebarDefaultSectionId.MAIN:
          switch (true) {
            case taskStateKeys.some((s) => s === sectionElementId):
              query = GET_TASKS_BY_STATE_WITHOUT_DUE_DATE;
              queryVariables = {
                ...queryVariables,
                appId,
                taskState: sectionElementId,
                pagination: {
                  offset: 0,
                  limit: 100,
                },
                sorts: [],
              };
              break;
            case sectionElementId ===
              SintropiSidebarMainSectionElementId.SINTROPI:
              if (!!sectionElementDropDownElementId) {
                query = GET_TASKS_BY_ASSIGNMENT_TYPE_WITHOUT_DUE_DATE;
                queryVariables = {
                  ...queryVariables,
                  appId,
                  assignmentType: sectionElementDropDownElementId,
                  pagination: {
                    offset: 0,
                    limit: 100,
                  },
                  sorts: [],
                };
              } else {
                query = GET_TASKS_IN_INBOX_WITHOUT_DUE_DATE;
                queryVariables = {
                  ...queryVariables,
                  appId,
                  pagination: {
                    offset: 0,
                    limit: 100,
                  },
                  sorts: [],
                };
              }
              break;
            default:
              break;
          }
          break;
      }
    }
    return { gqlQuery: query, gqlQueryVariables: queryVariables };
  }, [appId, sectionElementId, sectionId, sectionElementDropDownElementId]);

  const [loadQuery, { loading, error, data, refetch }] = useLazyQuery(
    gqlQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!!gqlQuery) {
      loadQuery({
        variables: gqlQueryVariables,
      });
    }
  }, [gqlQuery, gqlQueryVariables, loadQuery, location]);

  useEffect(() => {
    if (data) {
      const { totalElements, pageElements } = data[gqlName(gqlQuery!)];
      dispatch(
        appendTasksWithoutDueDate({
          taskCount: totalElements,
          tasks: pageElements,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(clearTaskWithoutDueDateList());
    return () => {
      dispatch(clearTaskWithoutDueDateList());
    };
  }, [dispatch]);

  return {
    tasks: tasksWithoutDueDate ? tasksWithoutDueDate : [],
    loading,
    error,
    refetch,
  };
};
