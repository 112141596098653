import {
  ActivityType,
  Notification,
  NotificationResourceType,
  NotificationStatus,
} from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoundedTextAvatar } from '../components/avatar/RoundedTextAvatar';
import {
  extractFullName,
  transformEnumValueToCamelCase,
} from '../utils/stringUtils';
import { Theme } from '../theme';
import { getTimeAsString } from '../utils/dateUtils';
import { Text } from '../components/common/Text';
import { extractMultipleForNotificationTranslate } from './index';
import { getNotificationUrl } from './getNotificationUrl';
import { toggleNotificationModalAction } from '../redux/notification/sidebar/actions';
import { READ_NOTIFICATIONS } from '../graphql/notification/mutations';
import { nothing } from '../utils/funcUtils';
import { SvgIcon } from '../components/common/SvgIcon';
import { JoinTask } from '../svg';
import { GET_RESOURCE_CONTEXT } from '../graphql/resource/queries';
import { ActivitySource, ChatResourceType } from '@pec-manager/graphql';
import { useMutationHook } from '../components/hooks/useMutationHook';
import { CREATE_CHAT } from '../graphql/chat/mutations';
import { openChatModalId } from '../redux/Sintropi/execution/actions';

interface NotificationItemProps {
  notification: Notification;
  setFormDefinitionId?: (id: string) => void;
  haveContext: (id: boolean) => void;
}

export const NotificationItem: FunctionComponent<NotificationItemProps> = ({
  notification,
  setFormDefinitionId,
  haveContext,
}) => {
  const [getContextQuery, { data }] = useLazyQuery(GET_RESOURCE_CONTEXT, {
    variables: {
      resourceId: notification.resourceId,
      resourceType: notification.resourceType,
    },
  });

  useEffect(() => {
    if (notification.resourceType !== NotificationResourceType.CHAT) {
      getContextQuery({
        variables: {
          resourceId: notification.resourceId,
          resourceType: notification.resourceType,
        },
      }).then(nothing);
    }
  }, [getContextQuery, notification.resourceId, notification.resourceType]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [readNotification] = useMutation(READ_NOTIFICATIONS, {
    variables: {
      notificationIds: [notification.id],
    },
  });

  const [createChat] = useMutationHook({
    mutationGql: CREATE_CHAT,
    errorMessage: 'genericErrorMessage',
  });

  useEffect(() => {
    if (
      data?.getResourceContext.context ||
      notification.resourceType === NotificationResourceType.CHAT
    ) {
      haveContext(true);
    }
    if (data?.getResourceContext.context === null) {
      readNotification().then(nothing);
    }
    // eslint-disable-next-line
  }, [data]);

  return data?.getResourceContext.context ||
    notification.resourceType === NotificationResourceType.CHAT ? (
    <Item
      unread={notification.notificationStatus === NotificationStatus.UNREAD}
      pointer={
        !!data?.getResourceContext.context ||
        notification.resourceType === NotificationResourceType.CHAT
      }
      onClick={() => {
        readNotification().then(nothing);
        if (notification.activitySource === ActivitySource.CHAT_SERVICE) {
          if (notification.resourceType === NotificationResourceType.CHAT) {
            dispatch(
              openChatModalId({
                id: notification.resourceId,
              })
            );
          } else {
            createChat({
              variables: {
                invitedUsers: [],
                resource: {
                  resourceId: notification.resourceId,
                  resourceType: ChatResourceType.EXECUTION,
                },
              },
            }).then((result: any) => {
              if (result.data?.createChatRoom) {
                dispatch(
                  openChatModalId({
                    id: result.data.createChatRoom,
                    resourceId: notification.resourceId,
                  })
                );
              }
            });
          }
          dispatch(toggleNotificationModalAction());
        } else {
          if (
            setFormDefinitionId &&
            notification.activityType === ActivityType.JOINED_TASK_READY
          ) {
            setFormDefinitionId(notification.resourceId);
            dispatch(toggleNotificationModalAction());
            return;
          }

          switch (notification.resourceType) {
            case NotificationResourceType.CHAT:
              navigate(
                `/chat?chatId=${
                  data?.getResourceContext?.context.resourceId ||
                  notification.resourceId
                }`
              );
              break;
            case NotificationResourceType.TASK:
            case NotificationResourceType.DOCUMENT:
            case NotificationResourceType.COMMENT:
            case NotificationResourceType.EXECUTION:
            default:
              navigate(
                getNotificationUrl(
                  data?.getResourceContext.context,
                  data?.getResourceContext.context.appId
                )
              );
          }
          dispatch(toggleNotificationModalAction());
        }
      }}
    >
      <AvatarContainer>
        {notification.activityType === ActivityType.JOINED_TASK_READY ? (
          <SvgIcon svg={<JoinTask />} height="40px" width="40px" />
        ) : (
          <RoundedTextAvatar
            text={extractFullName(notification.activityUsers[0])}
          />
        )}
      </AvatarContainer>
      <MessageText
        text={`notificationActivityType${transformEnumValueToCamelCase(
          notification.activityType
        )}Actor${extractMultipleForNotificationTranslate(
          notification.activityUsers.length > 1,
          notification.activityCount
        )}`}
        translationArgs={{
          actors: notification.activityUsers.map((u) => u.firstName).join(', '),
          actor: extractFullName(notification.activityUsers[0]),
          resourceName:
            notification.resourceName ||
            data?.getResourceContext.context?.resourceName ||
            '',
          activityCount: notification.activityCount,
        }}
        fontSize="12px"
        lineHeight="18px"
        fontWeight={
          notification.notificationStatus === NotificationStatus.READ
            ? undefined
            : '500'
        }
        color={
          notification.notificationStatus === NotificationStatus.READ
            ? Theme.colors.c505050
            : undefined
        }
      />
      <DateText
        text={getTimeAsString(notification.createdAt as unknown as number)}
        fontSize="10px"
        lineHeight="15px"
        skipTranslation
      />
    </Item>
  ) : null;
};

const Item = styled.div<{ pointer: boolean; unread: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, unread }) => theme.colors.transparentGrey};
  }
  ${({ theme, unread }) =>
    unread && `background-color: ${theme.colors.unreadNotification};`};
`;

const AvatarContainer = styled.div`
  width: 40px;
  margin-right: 16px;
`;

const MessageText = styled(Text)`
  width: 100%;
  margin-right: 16px;
`;

const DateText = styled(Text)`
  margin-bottom: auto;
`;
