import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { ContainerModal } from '../../../components/modals/ContainerModal';
import { Details } from './Details';
import { useExecutionDetails } from '../../../components/hooks/useExecutionDetails';

interface ExecutionDetailsProps {
  id: string;
}

export const ExecutionDetails: FunctionComponent<ExecutionDetailsProps> = ({
  id,
}) => {
  useExecutionDetails(id);

  return (
    <ContainerModal position="fixed">
      <Container onClick={(e) => e.stopPropagation()}>
        <Details id={id} />
      </Container>
    </ContainerModal>
  );
};

const Container = styled(FlexContainer)<{ chatIsOpen?: boolean }>`
  width: 100%;
  max-height: calc(100vh - 100px);
  max-width: 1440px;
  margin: 20px;
  ${({ chatIsOpen }) => chatIsOpen && 'padding-bottom: 80px;'}

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: 1440px) {
    max-height: calc((var(--vh, 1vh) * 100));
    max-width: 100%;
    margin: 0;
  }
`;
