import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../layout/FlexContainer';
import { KeycloakService } from '../../services/KeycloakService';
import { useTranslation } from 'react-i18next';

interface GenericTextInputCardinality01Props {
  data: string[];
  setData?: any;
  placeholder?: string;
  errorMessage?: string;
  type: 'string' | 'number';
  isEditable: boolean;
  onInputChange?: (val: string) => void;
  displayValueMap?: any;
  onFocusInput?: (val: boolean) => void;
  addInputError?: boolean;
  setFirstOnClick: (focus: boolean) => void;
}

export const GenericTextInputCardinality01: FunctionComponent<
  GenericTextInputCardinality01Props
> = ({
  onInputChange,
  addInputError,
  setFirstOnClick,
  setData,
  data,
  placeholder,
  onFocusInput,
  type,
  errorMessage,
  isEditable,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (data) {
      setInputValue(data[0]);
    }
  }, [data]);

  const addInput = () => {
    if (!addInputError) {
      setData(inputValue?.length > 0 ? [inputValue] : []);
    }
  };

  return (
    <Container
      alignItems="center"
      wrapContent
      error={!!errorMessage}
      isEditable={isEditable}
    >
      <InputStyled
        onBlur={() => addInput()}
        onFocus={() => {
          setFirstOnClick(true);
          onFocusInput && onFocusInput(true);
        }}
        lang={KeycloakService.getLocale()}
        disabled={!isEditable}
        placeholder={t('insertText') as string}
        value={inputValue || ''}
        onChange={(e) => {
          if (type === 'number' && !isNaN(Number(e.target.value))) {
            setInputValue(e.target.value);
            onInputChange && onInputChange(e.target.value);
          } else if (type === 'string') {
            setInputValue(e.target.value);
            onInputChange && onInputChange(e.target.value);
          }
        }}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)<{
  error?: boolean;
  isEditable: boolean;
}>`
  padding: 10px 10px 10px 10px;
  border: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors.cFE4242 : theme.colors.cDFE1E6};
  min-width: 260px;
  width: 100%;
  border-radius: 6px;
  background-color: ${({ theme, isEditable }) =>
    isEditable ? theme.colors.cFFFFFF : theme.colors.transparentGrey};
  min-height: 51px;

  &:focus {
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.cFE4242 : theme.colors.cD8FF86};
  }
  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }
`;

const InputStyled = styled.input`
  border: 0;
  outline: none;
  height: 31px;
  width: 100%;
`;
