import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../layout/FlexContainer';
import { Theme } from '../../../theme';
import { Button } from '../../common/Button';
import { Text } from '../../common/Text';

interface ButtonSectionProps {
  disabledButton?: boolean;
  closeOnClick: () => void;
  insertOnClick: () => void;
}

export const ButtonSection: FunctionComponent<ButtonSectionProps> = ({
  disabledButton,
  closeOnClick,
  insertOnClick,
}) => (
  <ContainerButton alignItems="center" justifyContent="flex-end">
    <CancelButton
      backgroundColor={Theme.colors.cFFFFFF}
      padding="10px 12px"
      borderRadius="8px"
      border={`1px solid ${Theme.colors.cDCDCDC}`}
      height="40px"
      onClick={closeOnClick}
    >
      <Text text="cancel" fontSize="13px" lineHeight="13px" />
    </CancelButton>
    <InsertButton
      border={`1px solid ${Theme.colors.cDCDCDC}`}
      padding="3px 15px"
      borderRadius="8px"
      height="40px"
      backgroundColor={Theme.colors.c5B84EF}
      hoverBackgroundColor={Theme.colors.c5176d6}
      disabled={disabledButton}
      onClick={insertOnClick}
    >
      <Text
        text="insert"
        color={Theme.colors.cFFFFFF}
        fontSize="13px"
        lineHeight="13px"
      />
    </InsertButton>
  </ContainerButton>
);

const ContainerButton = styled(FlexContainer)`
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 80px;
  padding: 0 16px;
  margin-top: auto;
`;

const InsertButton = styled(Button)`
  margin-left: 16px;
  min-width: fit-content;
`;

const CancelButton = styled(Button)`
  margin-left: 16px;
  min-width: fit-content;
`;
