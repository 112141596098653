import { TaskSummary } from '@pec-manager/graphql';

export const grouptasksByExpiresAtByMonth = (tasks: TaskSummary[]) => {
  const tasksByMonth: { [key: string]: TaskSummary[] } = {};

  tasks.forEach((task) => {
    // @ts-ignore
    const month = new Date(task.base.expiresAt).getMonth();
    // @ts-ignore
    const year = new Date(task.base.expiresAt).getFullYear();
    const key = `${month}-${year}`;

    if (tasksByMonth[key]) {
      tasksByMonth[key].push(task);
    } else {
      tasksByMonth[key] = [task];
    }
  });

  const arrayTasksByMonth = Object.keys(tasksByMonth).map((key) => ({
    month: key,
    tasks: tasksByMonth[key],
  }));

  const sortedArrayTasksByMonth = arrayTasksByMonth.sort((a, b) => {
    const [monthA, yearA] = a.month.split('-');
    const [monthB, yearB] = b.month.split('-');

    if (yearA === yearB) {
      return parseInt(monthA) - parseInt(monthB);
    }

    return parseInt(yearB) - parseInt(yearA);
  });

  const lastElementArray =
    sortedArrayTasksByMonth[sortedArrayTasksByMonth.length - 1];

  if (lastElementArray?.month === '0-1970') {
    const moveLastElementArrayToFirstPosition = sortedArrayTasksByMonth.filter(
      (item) => item.month !== lastElementArray.month
    );
    return [lastElementArray, ...moveLastElementArrayToFirstPosition];
  }

  return sortedArrayTasksByMonth;
};
