import { gql } from '@apollo/client/core';
import {
  gqlKpiFiltersShape,
  gqlKpiRowShape,
  gqlProcessPerformanceKpiShape,
  gqlProcessWorkloadKpiResponseShape,
} from './shapes';

export const PROCESS_PERFORMANCE_KPI = gql`
    query ProcessPerformanceKpi($processName: String!, $period: Period!) {
        processPerformanceKpi(processName: $processName, period: $period) {
            ${gqlProcessPerformanceKpiShape}
        }
    }
`;

export const PROCESS_LIST = gql`
  query ProcessList($appName: String!) {
    processList(appName: $appName)
  }
`;

export const PROCESS_WORKLOAD_KPI = gql`
    query ProcessWorkloadKpi($processName: String!, $period: Period!) {
        processWorkloadKpi(processName: $processName, period: $period) {
            ${gqlProcessWorkloadKpiResponseShape}
        }
    }
`;

export const HAS_BUSINESS_KPI = gql`
  query HasBusinessKpi($appId: String!) {
    hasBusinessKpi(appId: $appId) {
      hasBusinessKpi
    }
  }
`;

export const GET_KPI_FILTERS = gql`
  query GetKpiFilters($appId: String!) {
    getKpiFilters(appId: $appId) {
        ${gqlKpiFiltersShape}
    }
  }
`;

export const GET_KPI_TABS = gql`
  query GetKpiTabs($appId: String!) {
    getKpiTabs(appId: $appId) {
      tabId
      label
    }
  }
`;

export const GET_KPI_TAB_ROWS = gql`
  query GetKpiTabRows($appId: String!, $tabId: String!, $filter: [KpiFilterInput]) {
    getKpiTabRows(appId: $appId, tabId: $tabId, filter: $filter) {
        ${gqlKpiRowShape}
    }
  }
`;
