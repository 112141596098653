import {
  FilledFormField,
  FormDefinition,
  FormFieldDefinition,
  FormFieldType,
} from '@pec-manager/graphql';
import {
  boundaryError,
  generatePredicatesFromDefinitions,
  validationError,
} from './errorMessage';

export function extractFormDefinitionPredicatesMap(
  formDefinition: FormDefinition,
  init?: boolean
): { [key: string]: any } {
  const formDefinitionPredicatesMap: any = {};

  const isCalled: any = {};

  for (const fieldGroup of formDefinition.fieldGroups) {
    for (const field of fieldGroup.fields) {
      formDefinitionPredicatesMap[`${fieldGroup.id}__${field.id}`] = (
        data: string[],
        complexData?: FilledFormField
      ): boolean => {
        if (field.type === FormFieldType.HIDDEN || fieldGroup.isHidden) {
          return false;
        }
        const hasCardinalityBoundsError =
          boundaryError(
            data.filter((d) => d !== '').length,
            field.cardinalityBounds?.left,
            field.cardinalityBounds?.right
          ) !== undefined;

        const hasComplexCardinalityBoundsError =
          boundaryError(
            complexData?.complexValues?.length || 0,
            field.cardinalityBounds?.left,
            field.cardinalityBounds?.right
          ) !== undefined;

        const predicatesForFields = generatePredicatesFromDefinitions(
          field.validationPredicates
        );

        const hasValuePredicateError = data.some(
          (d) => validationError(d, predicatesForFields) !== undefined
        );

        const hasPasswordFieldError =
          field.type === FormFieldType.GENERIC_PASSWORD &&
          field.kind?.genericPassword?.withConfirmation &&
          data[0] !== data[1];

        let initError = false;

        if (init) {
          initError = field.errors.length > 0;
        }

        if (!isCalled[`${fieldGroup.id}__${field.id}`]) {
          isCalled[`${fieldGroup.id}__${field.id}`] = true;
        }

        let oneFieldHasCardinalityBoundsError: { [id: string]: boolean[] }[] =
          [];
        const predicatesForEmptyFields = generatePredicatesFromDefinitions(
          field.validationPredicates
        );

        let oneFieldHasValuePredicateError: { [id: string]: boolean[] }[] = [];

        if (
          field.type === FormFieldType.COMPOSITE &&
          complexData?.complexValues
        ) {
          field.kind?.composite?.emptyFormFieldDefinitions.map(
            // eslint-disable-next-line array-callback-return
            (fieldEmpty: FormFieldDefinition) => {
              complexData?.complexValues?.map((v, index) => {
                // eslint-disable-next-line array-callback-return
                return v.filledFields.map((c) => {
                  if (c.id === fieldEmpty.id) {
                    oneFieldHasCardinalityBoundsError[
                      // @ts-ignore
                      `${index}__${fieldEmpty.id}`
                    ] =
                      boundaryError(
                        (c.filledValues || []).filter((d) => d !== '').length,
                        fieldEmpty.cardinalityBounds?.left,
                        fieldEmpty.cardinalityBounds?.right
                      ) !== undefined;

                    oneFieldHasValuePredicateError[
                      // @ts-ignore
                      `${index}__${fieldEmpty.id}`
                    ] = (c.filledValues || []).some(
                      (d) =>
                        validationError(d, predicatesForEmptyFields) !==
                        undefined
                    );
                  } else if (
                    !Object.keys(oneFieldHasCardinalityBoundsError).includes(
                      // @ts-ignore
                      `${index}__${fieldEmpty.id}`
                    )
                  ) {
                    oneFieldHasCardinalityBoundsError[
                      // @ts-ignore
                      `${index}__${fieldEmpty.id}`
                    ] =
                      boundaryError(
                        [].filter((d) => d !== '').length,
                        fieldEmpty.cardinalityBounds?.left,
                        fieldEmpty.cardinalityBounds?.right
                      ) !== undefined;

                    oneFieldHasValuePredicateError[
                      // @ts-ignore
                      `${index}__${fieldEmpty.id}`
                    ] = [].some(
                      (d) =>
                        validationError(d, predicatesForEmptyFields) !==
                        undefined
                    );
                  }
                });
              });
              if (field.type === FormFieldType.HIDDEN || fieldGroup.isHidden) {
                return false;
              }
              if (!isCalled[`${fieldGroup.id}__${field.id}`]) {
                isCalled[`${fieldGroup.id}__${field.id}`] = true;
              }
            }
          );
        }

        return (
          hasPasswordFieldError ||
          (field.type === FormFieldType.COMPOSITE && complexData?.complexValues
            ? hasComplexCardinalityBoundsError
            : hasCardinalityBoundsError) ||
          (field.type === FormFieldType.COMPOSITE && complexData?.complexValues
            ? Object.keys(oneFieldHasCardinalityBoundsError).some(
                (c) =>
                  // @ts-ignore
                  oneFieldHasCardinalityBoundsError[c]
              )
            : false) ||
          (field.type === FormFieldType.COMPOSITE && complexData?.complexValues
            ? oneFieldHasValuePredicateError.some(
                (c) =>
                  // @ts-ignore
                  oneFieldHasValuePredicateError[c]
              )
            : false) ||
          hasValuePredicateError ||
          initError ||
          (!isCalled[`${fieldGroup.id}__${field.id}`] &&
            field.errors.length > 0)
        );
      };
    }
  }
  return formDefinitionPredicatesMap;
}
