import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { SavedSearch, FilledForm } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { KeycloakService } from '../../services/KeycloakService';
import { Text } from '../../components/common/Text';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Theme } from '../../theme';
import { mailStateSelector } from '../../redux/Inbox/mail/selectors';
import { clearMailList } from '../../redux/Inbox/mail/actions';
import { inboxSidebarStateSelector } from '../../redux/Inbox/sidebar/selectors';
import { Arrow, Filter, Reload } from '../../svg';
import { GenericSimpleInputModal } from '../../components/modals/SaveSearch';
import {
  DELETE_SAVED_SEARCH,
  DUPLICATE_SAVED_SEARCH,
  EDIT_SAVED_SEARCH,
  RENAME_SAVED_SEARCH,
  SAVE_SEARCH,
} from '../../graphql/search/mutations';
import { MenuChoices } from '../../components/PagedFormDefinition/MenuChoices';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import { ProgressFlows } from './ProgressFlows';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';

export const InboxContainerHeader: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { appId } = useParams();

  const searchParams = new URLSearchParams(location.search);

  const [rotateFactor, setRotateFactor] = useState(0);
  const { mailCount, advancedSearchFilledForm, advancedSearchPageId } =
    useSelector(mailStateSelector);
  const { displayName } = useSelector(inboxSidebarStateSelector);
  const displayNameValue = () => {
    if (location.pathname === `${appId}/inbox/search`) {
      return 'searchResult';
    }
    if (displayName === 'inbox') {
      return 'allInboxes';
    }
    return displayName || 'loading';
  };

  const sectionElementId = searchParams.get('elementId');

  const [showSavedSearch, setShowSavedSearch] = useState(false);

  const [showRenameSavedSearch, setShowRenameSavedSearch] = useState(false);

  const [showRenameNewSavedSearch, setShowRenameNewSavedSearch] =
    useState(false);

  const [selectChoicesIsOpen, setSelectChoicesIsOpen] = useState(false);

  const [savedSearch] = useMutationHook({
    mutationGql: SAVE_SEARCH,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [renameSavedSearch] = useMutationHook({
    mutationGql: RENAME_SAVED_SEARCH,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [duplicateSavedSearch] = useMutationHook({
    mutationGql: DUPLICATE_SAVED_SEARCH,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [deleteSavedSearch] = useMutationHook({
    mutationGql: DELETE_SAVED_SEARCH,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [editSavedSearch] = useMutationHook({
    mutationGql: EDIT_SAVED_SEARCH,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [saveDirtySearch, setSaveDirtySearch] = useState<
    FilledForm | undefined
  >(undefined);

  useEffect(
    () => () => {
      setSelectChoicesIsOpen(false);
      setSaveDirtySearch(undefined);
    },
    [location]
  );

  return (
    <Container column>
      <ContainerTop alignItems="center" justifyContent="space-between">
        <TitleWithChooseApp />
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </ContainerTop>
      <ContainerBottom alignItems="center">
        <LeftContainer alignItems="center" justifyContent="space-between">
          <FlexContainer column>
            <FlexContainer alignItems="center">
              <Text
                text={displayNameValue()}
                fontSize="18px"
                lineHeight="27px"
                fontWeight="500"
              />
              <SvgContainer
                rotateFactor={rotateFactor}
                onClick={() => {
                  setRotateFactor((prevState) =>
                    prevState === 20 ? 0 : prevState + 1
                  );
                  dispatch(clearMailList());
                }}
              >
                <SvgIcon
                  svg={<Reload />}
                  width="11px"
                  height="11px"
                  color={Theme.colors.c2186C6}
                />
              </SvgContainer>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <AvailableTaskText
                text={`${
                  saveDirtySearch ? 'availableTasksWithEdit' : 'availableTasks'
                }`}
                translationArgs={{ taskCount: mailCount }}
                fontSize="14px"
                lineHeight="21px"
                fontWeight="300"
              />
              {advancedSearchPageId &&
                location.pathname === `/${appId}/inbox/search` && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowSavedSearch(true);
                    }}
                  >
                    <Text
                      text="saveWithName"
                      fontSize="12px"
                      lineHeight="12px"
                      color={Theme.colors.c5B84EF}
                    />
                  </div>
                )}
              {location.search.includes(
                '?sectionId=SAVED_SEARCHES&elementId='
              ) &&
                saveDirtySearch && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      editSavedSearch({
                        variables: {
                          appId,
                          filledForm: saveDirtySearch,
                          searchId: sectionElementId,
                        },
                      }).then(() => setSaveDirtySearch(undefined));
                    }}
                  >
                    <Text
                      text="save"
                      fontSize="12px"
                      lineHeight="12px"
                      color={Theme.colors.c5B84EF}
                    />
                  </div>
                )}
              {!saveDirtySearch &&
                location.search.includes(
                  '?sectionId=SAVED_SEARCHES&elementId='
                ) && (
                  <ClickableArrow
                    onClick={() =>
                      setSelectChoicesIsOpen((prevState) => !prevState)
                    }
                  >
                    <ArrowIcon
                      svg={<Arrow />}
                      width="10.8px"
                      color={Theme.colors.c5B84EF}
                      isRotated={selectChoicesIsOpen}
                    />
                    {selectChoicesIsOpen && (
                      <MenuChoices
                        containerMenuProps={{
                          top: '64px',
                          maxWidth: '150px',
                        }}
                        choices={[
                          {
                            text: 'duplicateSearch',
                            onClick: () => {
                              setShowRenameSavedSearch(true);
                            },
                          },
                          {
                            text: 'rename',
                            onClick: () => {
                              setShowRenameSavedSearch(true);
                            },
                          },
                          {
                            text: 'delete',
                            onClick: () => {
                              deleteSavedSearch({
                                variables: {
                                  appId,
                                  searchId: sectionElementId,
                                },
                              }).then(() =>
                                navigate(
                                  `${appId}/inbox?sectionId=MAIN&elementId=INBOX`
                                )
                              );
                            },
                            colorText: Theme.colors.cD73E18,
                          },
                        ]}
                        onClickOutside={() => setSelectChoicesIsOpen(false)}
                      />
                    )}
                  </ClickableArrow>
                )}
            </FlexContainer>
          </FlexContainer>
          {!saveDirtySearch &&
            location.search.includes(
              '?sectionId=SAVED_SEARCHES&elementId='
            ) && (
              <EditSearchContainer onClick={() => {}}>
                <SvgIcon
                  svg={<Filter />}
                  color={Theme.colors.c2186C6}
                  height="14px"
                  width="14px"
                />
              </EditSearchContainer>
            )}
        </LeftContainer>
        <RightContainer justifyContent="flex-end">
          <ProgressFlows />
        </RightContainer>
        {showSavedSearch && (
          <GenericSimpleInputModal
            placeHolder="insertNameSavedSearch"
            title="savedSearch"
            onClickClose={() => setShowSavedSearch(false)}
            onClickSaved={(data) => {
              savedSearch({
                variables: {
                  appId,
                  filledForm: advancedSearchFilledForm,
                  searchName: data,
                },
              }).then((r: SavedSearch) => {
                navigate(`sectionId=SAVED_SEARCHES&elementId=${r.id}`);
                setSaveDirtySearch(undefined);
              });
            }}
          />
        )}
        {showRenameSavedSearch && (
          <GenericSimpleInputModal
            placeHolder="insertRenameSavedSearch"
            title="renameSearch"
            onClickClose={() => setShowRenameSavedSearch(false)}
            onClickSaved={(data) => {
              renameSavedSearch({
                variables: {
                  appId,
                  searchId: sectionElementId,
                  newName: data,
                },
              });
            }}
          />
        )}
        {showRenameNewSavedSearch && (
          <GenericSimpleInputModal
            placeHolder="insertRenameNewSavedSearch"
            title="renameSearchDuplicate"
            onClickClose={() => setShowRenameNewSavedSearch(false)}
            onClickSaved={(data) => {
              duplicateSavedSearch({
                variables: {
                  appId,
                  searchId: sectionElementId,
                  newName: data,
                },
              }).then((r: SavedSearch) => {
                navigate(`sectionId=SAVED_SEARCHES&elementId=${r.id}`);
              });
            }}
          />
        )}
      </ContainerBottom>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 106px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ContainerBottom = styled(FlexContainer)`
  width: 100%;
  height: 64px;
`;

const ContainerTop = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

const LeftContainer = styled(FlexContainer)`
  max-width: 465px;
  width: 100%;
  height: 56px;
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
`;

const RightContainer = styled(FlexContainer)`
  width: 100%;
  padding-right: 20px;
  margin-right: -6px;
`;

const SvgContainer = styled.div<{ rotateFactor: number }>`
  margin-left: 8px;
  cursor: pointer;
  transition: ${({ rotateFactor }) => (rotateFactor === 0 ? '0ms' : '500ms')}
    transform ease-in-out;
  transform: rotate(${({ rotateFactor }) => `${rotateFactor * 360}deg`});
`;

const AvailableTaskText = styled(Text)`
  margin-right: 8px;
`;

const ArrowIcon = styled(SvgIcon)<{ isRotated?: boolean }>`
  margin-right: 9px;
  transition: 200ms transform ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`;

const ClickableArrow = styled.div`
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const EditSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  height: 26px;
  width: 26px;
  background-color: ${({ theme }) => theme.colors.cF0F0F0};
  border-radius: 50%;
  cursor: pointer;
`;
