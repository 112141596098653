import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContainerModal } from '../ContainerModal';
import { SvgIcon } from '../../common/SvgIcon';
import { Text } from '../../common/Text';
import { ButtonWithIcon } from '../../common/ButtonWithIcon';
import { KO, Download } from '../../../svg';
import { downloadFile } from '../../../utils/file';
import { PdfViewerWithToolbar } from '../../common/PdfViewerWithToolbar';
import { saveAs } from 'file-saver';

interface AttachmentPreviewModalProps {
  closeOnClick: () => void;
  downloadUrl: string;
  nameFile: string;
}

export const AttachmentPreviewModal: FunctionComponent<
  AttachmentPreviewModalProps
> = ({ closeOnClick, downloadUrl, nameFile }) => {
  const [file, setFile] = useState<
    { file: File; fileName: string } | undefined
  >();
  const [filePreview, setFileFilePreview] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const fileLocale = await downloadFile(downloadUrl);
      setFile(fileLocale);

      const createObjectURL = URL.createObjectURL(fileLocale.file);
      setFileFilePreview(createObjectURL);
    })();
  }, [downloadUrl]);

  return (
    <ContainerModal position="fixed">
      <Container onClick={(e) => e.stopPropagation()}>
        <ContainerHeader>
          <ContainerText>
            <ClickableIcon onClick={closeOnClick}>
              <SvgIcon svg={<KO />} width="15px" />
            </ClickableIcon>
            <Text
              text={nameFile}
              fontSize="18px"
              lineHeight="27px"
              fontWeight="500"
              ellipsis
              tooltipText={nameFile}
            />
          </ContainerText>
          <StyledA>
            <ButtonWithIcon
              text="Download"
              svg={<Download />}
              onClickHandler={() => {
                if (file) {
                  saveAs(file.file, file.fileName);
                }
              }}
            />
          </StyledA>
        </ContainerHeader>
        {file && filePreview && <PdfViewerWithToolbar file={filePreview} />}
      </Container>
    </ContainerModal>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  width: 800px;
  height: 1042px;
  max-height: 100vh;
  overflow: auto;

  @media (max-width: 990px) {
    height: calc((var(--vh, 1vh) * 100));
    width: 100%;
  }
`;

const ContainerHeader = styled.div`
  height: 70px;
  border-radius: 12px 12px 0 0;
  background: rgba(244, 244, 245, 0.96);
  opacity: 0.98;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const ContainerText = styled.a`
  display: flex;
  max-width: 70%;
`;

const ClickableIcon = styled.div`
  margin-right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 3px;
`;

const StyledA = styled.a`
  text-decoration: none;
`;
