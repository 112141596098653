import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { MailDetails } from './MailDetails';
import { SpamModal } from '../../components/modals/SpamModal';
import { ReassignModal } from '../../components/modals/ReassignModal';
import { ContainerModal } from '../../components/modals/ContainerModal';
import { useMail } from '../../components/hooks/useMail';
import { executionsStateSelector } from '../../redux/Sintropi/execution/selectors';

export enum ModalTypeInbox {
  SPAM,
  REASSIGN,
}

interface MailDetailsWithCorrelatedProps {
  viewInModal?: boolean;
}

export const MailDetailsHandler: FunctionComponent<
  MailDetailsWithCorrelatedProps
> = ({ viewInModal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appId } = useParams();
  const { data: mail } = useMail();
  const seeOther = mail && mail.correlatedMails.length !== 0;
  const [modalType, setModalType] = useState<ModalTypeInbox | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const { chatInModal: chatModalIdIsOpen } = useSelector(
    executionsStateSelector
  );

  const elementId = new URLSearchParams(location.search).get('elementId');
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const dropDownId = new URLSearchParams(location.search).get('dropDownId');
  const mailId = new URLSearchParams(location.search).get('mailId');

  const clickOnClose = () => {
    navigate(
      `/${appId}/inbox?sectionId=${sectionId}&elementId=${elementId}${
        dropDownId ? `&dropDownId=${dropDownId}` : ''
      }`
    );
  };

  const modal = () => {
    switch (modalType) {
      case ModalTypeInbox.SPAM:
        return (
          <SpamModal
            mailId={mail.base.id}
            closeOnClick={() => setModalType(undefined)}
          />
        );
      case ModalTypeInbox.REASSIGN:
        return (
          <ReassignModal
            id={mail.base.id}
            closeOnClick={() => setModalType(undefined)}
            type="MAIL"
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (mailId && viewInModal) {
      setOpenModal(true);
    }
  }, [viewInModal, mailId]);

  return !viewInModal ? (
    <Container column chatIsOpen={!!chatModalIdIsOpen}>
      {!viewInModal && mail && (
        <MailDetails
          mail={mail}
          seeOther={seeOther}
          setModalType={setModalType}
        />
      )}
      {!viewInModal &&
        mail &&
        mail.correlatedMails.map((correlatedMail) => (
          <MailDetails
            key={correlatedMail.base.id}
            mail={correlatedMail}
            seeOther={seeOther}
            setModalType={setModalType}
          />
        ))}
      {mail && modal()}
    </Container>
  ) : (
    <>
      {openModal && mail && (
        <ContainerModal position="fixed">
          <Container onClick={(e) => e.stopPropagation()}>
            <MailDetails
              mail={mail}
              seeOther={false}
              setModalType={setModalType}
              clickOnClose={clickOnClose}
            />
          </Container>
          {mail && modal()}
        </ContainerModal>
      )}
    </>
  );
};

const Container = styled(FlexContainer)<{ chatIsOpen?: boolean }>`
  width: 100%;
  padding: 16px 8px;
  overflow-y: auto;
  ${({ chatIsOpen }) => chatIsOpen && 'padding-bottom: 80px;'}

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (max-width: 1040px) {
    padding: 0;
    margin: 20px;
    max-height: calc(100vh - 100px);
  }
`;
