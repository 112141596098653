import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { AnalyticsContainer, TabAnalyticsEnum } from './AnalyticsContainer';
import { TabPerformance } from './TabPerformance';
import { TabWorkload } from './TabWorkload';
import { Period } from '@pec-manager/graphql';
import { BoardItem } from './BoardItem';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';

interface AnalyticsProps {
  notMobile?: boolean;
}

export interface BoardItemtype {
  title: string;
  value: string;
}

export const Analytics: FunctionComponent<AnalyticsProps> = ({ notMobile }) => {
  const [boardItem, setBoardItem] = React.useState<BoardItemtype[]>([]);

  const [tabAnalytics, setTabAnalytics] = useState(
    TabAnalyticsEnum.PERFORMANCE
  );

  const [periodSelected, setPeriodSelected] = useState(Period.WEEK);

  const [processNameSelected, setProcessNameSelected] = useState<
    string | undefined
  >(undefined);

  return (
    <AnalyticsContainer
      tabAnalytics={tabAnalytics}
      setTabAnalytics={setTabAnalytics}
      setPeriodSelected={setPeriodSelected}
      periodSelected={periodSelected}
      setProcessNameSelected={setProcessNameSelected}
      processNameSelected={processNameSelected}
      notMobile={notMobile}
    >
      <Container>
        {processNameSelected !== 'noDataAvailable' ? (
          <>
            {boardItem.length > 0 && (
              <FieldContainer justifyContent="space-between">
                {boardItem.map((b) => (
                  <BoardItem key={b.title} title={b.title} bigText={b.value} />
                ))}
              </FieldContainer>
            )}
            {tabAnalytics === TabAnalyticsEnum.PERFORMANCE && (
              <TabPerformance
                notMobile={notMobile}
                periodSelected={periodSelected}
                processNameSelected={processNameSelected}
                setBoardItems={setBoardItem}
              />
            )}
            {tabAnalytics === TabAnalyticsEnum.WORKLOAD && (
              <TabWorkload
                notMobile={notMobile}
                periodSelected={periodSelected}
                processNameSelected={processNameSelected}
                setBoardItems={setBoardItem}
              />
            )}
          </>
        ) : (
          <NoDataContainer justifyContent="center" alignItems="center">
            <Text text="noDataAvailable" fontSize="14px" lineHeight="20px" />
          </NoDataContainer>
        )}
        {/*{tabAnalytics === TabAnalyticsEnum.BUSINESS && <TabBusiness />}*/}
      </Container>
    </AnalyticsContainer>
  );
};

const Container = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const FieldContainer = styled(FlexContainer)`
  padding: 16px;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

const NoDataContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
`;
