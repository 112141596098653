import React, { FunctionComponent, useEffect, useState } from 'react';
import { Comment, ResourceType } from '@pec-manager/graphql';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useLazyQuery } from '@apollo/client';
import { extractFullName } from '../../../utils/stringUtils';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { WithIconNameDateWrapper } from '../../../components/common/WithIconNameDateWrapper';
import { Button } from '../../../components/common/Button';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { RoundedTextAvatar } from '../../../components/avatar/RoundedTextAvatar';
import { KeycloakService } from '../../../services/KeycloakService';
import {
  REMOVE_COMMENT,
  REPLY_TO_COMMENT,
} from '../../../graphql/comment/mutations';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import defaultStyle from './defaultStyle';
import { decomposeRawWithMentions } from '../../../utils/mention';
import { GET_COMMENTS_REPLIES } from '../../../graphql/comment/queries';
import { REPLIES_PAGE_SIZE } from '../../../constant';

interface CommentSectionProps {
  comment: Comment;
  resourceId: string;
  users: { id: string; display: string }[];
  refetchComments: any;
}

export const CommentSection: FunctionComponent<CommentSectionProps> = ({
  comment,
  resourceId,
  users,
  refetchComments,
}) => {
  const { t } = useTranslation();
  const [reply, setReply] = useState(false);

  const [getCommentReplies, { data: replies, refetch }] = useLazyQuery(
    GET_COMMENTS_REPLIES,
    {
      variables: {
        commentId: comment.id,
        resource: {
          id: resourceId,
          type: ResourceType.MAIL,
        },
        pagination: {
          offset: 0,
          limit: REPLIES_PAGE_SIZE,
        },
      },
    }
  );

  const [inputValue, setInputValue] = useState('');

  const [replyToComment] = useMutationHook({
    mutationGql: REPLY_TO_COMMENT,
    errorMessage: 'genericErrorMessage',
  });

  const [removeComment] = useMutationHook({
    mutationGql: REMOVE_COMMENT,
    errorMessage: 'genericErrorMessage',
  });

  const [mentions, setMentions] = useState<string[]>([]);

  useEffect(() => {
    getCommentReplies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (removeComment || replyToComment) {
      refetch && refetch();
    }
    // eslint-disable-next-line
  }, [removeComment, replyToComment]);

  return (
    <>
      <WithIconNameDateWrapper
        date={comment.createdAt}
        fullName={extractFullName(comment.author)}
      >
        <BodyContainer>
          {decomposeRawWithMentions(comment.body).map((m, index) => {
            /* eslint-disable react/no-array-index-key */
            if (m) {
              return (
                <Text
                  style={{
                    whiteSpace: 'pre-line',
                  }}
                  key={index}
                  text={
                    (m as any).id ? (m as any).display : (m as any).textElement
                  }
                  skipTranslation
                  color={(m as any).id ? Theme.colors.c5B84EF : undefined}
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="300"
                />
              );
            }
            return null;
          })}
        </BodyContainer>
        <ButtonContainer alignItems="center">
          {/* <Button onClick={() => setReply((prevState) => !prevState)}>
            <Text
              text="reply"
              fontSize="14px"
              lineHeight="21px"
              color={Theme.colors.c505050}
            />
          </Button> */}
          {!(replies?.getCommentReplies.totalElements.length > 0) &&
            comment.author.username === KeycloakService.getUsername() && (
              <>
                {/* <FlexContainer alignItems="center"> */}
                {/* <Separator>.</Separator> */}
                <Button
                  onClick={() => {
                    removeComment({
                      variables: {
                        resourceId,
                        commentId: comment.id,
                      },
                    }).then(() => refetchComments());
                  }}
                >
                  <Text
                    text="delete"
                    fontSize="14px"
                    lineHeight="21px"
                    color={Theme.colors.c505050}
                  />
                </Button>
                {/* </FlexContainer> */}
              </>
            )}
        </ButtonContainer>
      </WithIconNameDateWrapper>
      {reply && (
        <>
          <InputContainer>
            <RoundedTextAvatar
              size={24}
              text={KeycloakService.getUsername()}
              fontSize={10}
            />
            <MentionsInput
              value={inputValue}
              onChange={(event, newValue, newSet, mentionsItem) => {
                setMentions(mentionsItem.map((m) => m.id));
                setInputValue(newValue);
              }}
              onKeyUp={(event) => {
                if (
                  event.key === 'Enter' &&
                  event.altKey &&
                  inputValue.length > 0
                )
                  replyToComment({
                    variables: {
                      resourceId,
                      commentId: comment.id,
                      body: inputValue,
                      mentionIds: Array.from(new Set(mentions)),
                    },
                  }).then(() => {
                    setReply(false);
                    refetchComments();
                  });
              }}
              style={defaultStyle}
              placeholder={t('addComment') as string}
            >
              <Mention
                trigger="@"
                data={users}
                displayTransform={(id, display) => display}
                style={{
                  backgroundColor: 'rgba(205, 205, 205, 0.5)',
                  borderRadius: '4px',
                }}
              />
            </MentionsInput>
          </InputContainer>
          <SuggestionText
            text="suggestSendComment"
            fontSize="12px"
            lineHeight="18px"
            color={Theme.colors.c505050}
          />
        </>
      )}
      {replies &&
        replies.getCommentReplies.pageElements.map((replyComment: Comment) => (
          <div
            key={replyComment.id}
            style={{ marginLeft: '40px', marginTop: '8px' }}
          >
            <CommentSection
              refetchComments={refetchComments}
              resourceId={resourceId}
              comment={replyComment}
              users={users}
            />
          </div>
        ))}
    </>
  );
};

/* const Separator = styled.div`
  margin: 0 8px 0 8px;
  line-height: 13px;
  height: 21px;
`; */

const BodyContainer = styled.div`
  display: inline;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled(FlexContainer)`
  margin-top: 5px;
`;

const SuggestionText = styled(Text)`
  margin-top: 5px;
  margin-left: 40px;
`;
