import { ExecutionContext } from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTask } from '../../components/hooks/useTask';
import { DynamicForm } from '../../components/DynamicForm';

export const TakeInCharge: FunctionComponent = () => {
  const { data: task } = useTask();
  const navigate = useNavigate();

  const clickOnClose = () => {
    navigate(-2);
  };

  if (task) {
    return (
      <DynamicForm
        executionContext={ExecutionContext.TASK}
        columnsForm={1}
        taskId={task.base.id}
        onCancel={clickOnClose}
        onSuccess={clickOnClose}
      />
    );
  }
  // TODO Loader
  return <></>;
};
