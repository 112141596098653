import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SintropiSidebarSection } from './SintropiSidebarSection';
import { SintropiSidebarDefaultSectionId } from '../sintropiSidebarDefaultSectionId';
import { getSintropiSidebarMainSectionElements } from '../sintropiSidebarDefaultSectionElements';
import { KeycloakService } from '../../../services/KeycloakService';
import { Theme } from '../../../theme';
import { Text } from '../../../components/common/Text';
import { Arrow } from '../../../svg';
import { ExecutionContext, IdentifiableValue } from '@pec-manager/graphql';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SAVED_SEARCHES } from '../../../graphql/search/queries';
import { GET_START_PROCESSES } from '../../../graphql/process/queries';
import { setSavedSearchList } from '../../../redux/Inbox/mail/actions';
import { Button } from '../../../components/common/Button';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { HAS_BUSINESS_KPI } from '../../../graphql/kpi/queries';
import { DynamicForm } from '../../../components/DynamicForm';

interface SintropiSidebarContainerProps {
  menuMobileIsOpen?: boolean;
}

export const SintropiSidebarMobile: FunctionComponent<
  SintropiSidebarContainerProps
> = ({ menuMobileIsOpen }) => {
  const dispatch = useDispatch();
  const { appId } = useParams();

  const { data: savedSearchesData } = useQuery(GET_SAVED_SEARCHES, {
    variables: {
      appId,
    },
  });
  const { data: processesData } = useQuery(GET_START_PROCESSES, {
    variables: {
      appId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: hasBusinessKpi } = useQuery(HAS_BUSINESS_KPI, {
    variables: {
      appId,
    },
    fetchPolicy: 'no-cache',
  });

  const [isOpenButton, setIsOpenButton] = useState(false);
  const [modalProcessOpened, setModalProcessOpened] = useState(false);

  const [currentProcess, setCurrentProcess] =
    useState<IdentifiableValue | null>(null);
  const [isChoiceSelected, setIsChoiceSelected] = useState(false);

  useEffect(() => {
    if (processesData && processesData.getStartProcesses.length > 0) {
      setCurrentProcess(processesData.getStartProcesses[0]);
    }
  }, [processesData]);

  useEffect(() => {
    if (isChoiceSelected && currentProcess) {
      setModalProcessOpened(true);
    }
    // eslint-disable-next-line
  }, [currentProcess]);

  useEffect(() => {
    dispatch(setSavedSearchList(savedSearchesData?.getSavedSearches || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchesData]);

  useEffect(() => {
    if (appId) {
      setIsOpenButton(false);
      setModalProcessOpened(false);
    }
  }, [appId]);

  return (
    <>
      {menuMobileIsOpen && (
        <SintropiSidebarContainer>
          {currentProcess && (
            <StyledButton
              backgroundColor={Theme.colors.c5B84EF}
              hoverBackgroundColor={Theme.colors.c5176d6}
              justifyContentBetween={
                processesData?.getStartProcesses.length > 1 || false
              }
              height="36px"
              width="100%"
              borderRadius="56px"
              onClick={() => setModalProcessOpened(true)}
            >
              <Text
                text={currentProcess.value}
                fontSize="13px"
                lineHeight="15.6px"
                color={Theme.colors.cFFFFFF}
                tooltipText={currentProcess.value}
                breakWord
              />
              {processesData?.getStartProcesses.length > 1 && (
                <>
                  <ButtonArrow
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setIsOpenButton((prevState) => !prevState);
                    }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ArrowIcon
                      svg={<Arrow />}
                      width="10.8px"
                      color={Theme.colors.cFFFFFF}
                      isRotated={isOpenButton}
                    />
                  </ButtonArrow>
                  {isOpenButton && (
                    <ChoicesContainer>
                      {(processesData.getStartProcesses as IdentifiableValue[])
                        .filter((p) => p.id !== currentProcess?.id)
                        .map((process) => (
                          <div
                            key={process.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentProcess(process);
                              setIsChoiceSelected(true);
                              setIsOpenButton((prevState) => !prevState);
                            }}
                          >
                            <Text
                              text={process.value}
                              fontSize="13px"
                              lineHeight="15.6px"
                              color={Theme.colors.c505050}
                              tooltipText={process.value}
                              breakWord
                            />
                          </div>
                        ))}
                    </ChoicesContainer>
                  )}
                </>
              )}
            </StyledButton>
          )}
          <SintropiSidebarSection
            sectionId={SintropiSidebarDefaultSectionId.MAIN}
            sectionElements={getSintropiSidebarMainSectionElements(
              KeycloakService.sintropiHasRoles(['business-analytics']),
              KeycloakService.sintropiHasRoles(['business-kpi']) &&
                hasBusinessKpi?.hasBusinessKpi.hasBusinessKpi
            )}
          />
        </SintropiSidebarContainer>
      )}
      {currentProcess && modalProcessOpened && (
        <DynamicForm
          executionContext={ExecutionContext.START}
          columnsForm={1}
          processId={currentProcess.id}
          onCancel={() => {
            setModalProcessOpened(false);
          }}
          onSuccess={() => {
            setModalProcessOpened(false);
          }}
        />
      )}
    </>
  );
};

const SintropiSidebarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.c212832};
  padding: 25.6px 16px 25.6px 16px;
  overflow-y: auto;
  position: absolute;
  flex-basis: 200px;
  min-width: 200px;
  height: 100vh;
  z-index: 3;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledButton = styled(Button)<{ justifyContentBetween: boolean }>`
  margin-top: 21px;
  margin-left: -3px;
  padding: 6px 16px;
  display: flex;
  justify-content: ${({ justifyContentBetween }) =>
    justifyContentBetween ? 'space-between' : 'center'};
  position: relative;
  height: fit-content;
  min-height: 36px;
`;

const ArrowIcon = styled(SvgIcon)<{ isRotated: boolean }>`
  transition: 200ms transform ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`;

const ChoicesContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  display: flex;
  flex-direction: column;
  top: calc(100% + 3px);
  border-radius: 12px;
  width: 100%;
  margin-left: -16px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6px;
  }

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  }
`;

const ButtonArrow = styled(FlexContainer)`
  min-height: 28px;
  min-width: 22px;
`;
