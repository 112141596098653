import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import {
  EntityAction,
  EntityType,
  ExecutionContext,
  IdentifiableValue,
  OutputSection,
} from '@pec-manager/graphql';
import { CustomSectionDocument } from '../CustomSectionDocument';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_DOCUMENT_BY_ID } from '../../../graphql/document/queries';
import { downloadFile } from '../../../utils/file';
import { AttachmentsSection } from './AttachmentsSection';
import { DocumentLinkedSection } from './DocumentLinkedSection';
import { DocumentVersionSection } from './DocumentVersionSection';
import { ActivitySection } from './ActivitySection';
import { handlePathForDocumentDetails } from '../../../utils/funcUtils';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import {
  DELETE_DOCUMENTS,
  MOVE_DOCUMENTS,
  PUBLISH_DOCUMENT,
} from '../../../graphql/document/mutations';
import { useParams } from 'react-router-dom';
import { ActionButtonDetailsDocumentProps } from '../DocumentContainerHeader';
import { MoveDocumentModal } from '../../../components/modals/MoveDocumentModal';
import { getPenultimateIdInDocumentPath } from '../utils';
import { IS_SUBSCRIBED } from '../../../graphql/notification/queries';
import {
  SUBSCRIBE,
  UNSUBSCRIBE,
} from '../../../graphql/notification/mutations';
import { AttachmentPreview } from '../../../components/common/AttachmentPreview';
import { AuthSectionDocument } from '../AuthSectionDocument';
import { DynamicForm } from '../../../components/DynamicForm';

interface DocumentaryDetailsProps {
  id: string;
  setCurrentPath: (path: IdentifiableValue[]) => void;
  setCurrentDocument: (doc: IdentifiableValue) => void;
  setActionsButton: Dispatch<SetStateAction<ActionButtonDetailsDocumentProps>>;
  deleteBackAction: () => void;
}

export const DocumentDetails: FunctionComponent<DocumentaryDetailsProps> = ({
  id,
  setCurrentPath,
  setCurrentDocument,
  setActionsButton,
  deleteBackAction,
}) => {
  const { appId } = useParams();
  const { data, refetch } = useQuery(GET_DOCUMENT_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  const [openEditAction, setOpenEditAction] = useState<string | undefined>(
    undefined
  );

  const [modalMoveOpen, setModalMoveOpen] = useState(false);

  const [isSubscribe, setIsSubscribe] = useState<boolean | undefined>(
    undefined
  );

  const [moveDocumentsSubmit] = useMutationHook({
    mutationGql: MOVE_DOCUMENTS,
    errorMessage: 'errorMessageEditDocument',
  });

  const [publishDocumentMutation] = useMutationHook({
    mutationGql: PUBLISH_DOCUMENT,
    errorMessage: 'errorMessagePublishDocument',
  });

  const [deleteDocumentMutation] = useMutationHook({
    mutationGql: DELETE_DOCUMENTS,
    errorMessage: 'errorMessageDeleteDocument',
  });

  const [subscribe, { loading: loadingSubscribe }] = useMutationHook({
    mutationGql: SUBSCRIBE,
    errorMessage: 'errorMessageSubscribeDocument',
  });

  const [unsubscribe, { loading: loadingUnsubscribe }] = useMutationHook({
    mutationGql: UNSUBSCRIBE,
    errorMessage: 'errorMessageUnsubscribeDocument',
  });

  const { data: isSubscribedData, loading } = useQuery(IS_SUBSCRIBED, {
    variables: {
      entityId: id,
      entityType: EntityType.DOCUMENT,
    },
  });

  useEffect(() => {
    if (isSubscribedData !== undefined && !loading) {
      setIsSubscribe(isSubscribedData.isSubscribed);
    }
  }, [isSubscribedData, loading, id]);

  useEffect(() => {
    if (data?.getDocumentById) {
      setCurrentPath(
        handlePathForDocumentDetails(data.getDocumentById.base.documentPath)
      );
      setCurrentDocument({
        id: data.getDocumentById.base.id,
        value: `${data.getDocumentById.base.name}${
          data.getDocumentById.base.isPublished ? '' : ' - DRAFT'
        }`,
      });
    }
  }, [data, setCurrentDocument, setCurrentPath]);

  const [file, setFile] = useState<string | undefined>();

  useEffect(() => {
    if (
      data &&
      data.getDocumentById.base.blobRef?.mimeType === 'application/pdf'
    ) {
      (async () => {
        const fileLocale = await downloadFile(
          data.getDocumentById.base.blobRef.downloadUrl
        );

        const createObjectURL = URL.createObjectURL(fileLocale.file);
        setFile(createObjectURL);
      })();
    }
  }, [data]);

  const deleteAction = useMemo(() => {
    if (data?.getDocumentById.actions.includes(EntityAction.DELETE)) {
      return () => {
        deleteDocumentMutation({
          variables: {
            appId,
            documentIds: [id],
          },
        }).then(deleteBackAction);
      };
    }
    return undefined;
    // eslint-disable-next-line
  }, [data]);

  const updateAction = useMemo(() => {
    if (data?.getDocumentById.actions.includes(EntityAction.UPDATE)) {
      return () => {
        setOpenEditAction(id);
      };
    }
    return undefined;
    // eslint-disable-next-line
  }, [data]);

  const subscribeAction = useMemo(() => {
    if (isSubscribe !== undefined) {
      if (!isSubscribe) {
        return {
          submit: () =>
            subscribe({
              variables: {
                entityId: id,
                entityType: EntityType.DOCUMENT,
              },
            }).then(() => {
              setIsSubscribe(true);
            }),
          text: 'subscribe',
          loading: loadingSubscribe,
        };
      } else {
        return {
          submit: () =>
            unsubscribe({
              variables: {
                entityId: id,
                entityType: EntityType.DOCUMENT,
              },
            }).then(() => {
              setIsSubscribe(false);
            }),
          text: 'unsubscribe',
          loading: loadingUnsubscribe,
        };
      }
    }
    return undefined;
    // eslint-disable-next-line
  }, [data, isSubscribe, loadingUnsubscribe, loadingSubscribe]);

  const publishAction = useMemo(() => {
    if (data?.getDocumentById.actions.includes(EntityAction.PUBLISH)) {
      return () => {
        publishDocumentMutation({
          variables: {
            appId,
            documentId: id,
          },
        });
      };
    }
    return undefined;
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data?.getDocumentById) {
      setActionsButton({
        onCLickDelete: deleteAction,
        onCLickEdit: updateAction,
        onclickPublish: publishAction,
        onClickMove: () => setModalMoveOpen(true),
        onClickSubscribe: subscribeAction,
      });
    }
  }, [
    data,
    deleteAction,
    publishAction,
    setActionsButton,
    subscribeAction,
    updateAction,
  ]);

  return (
    <Container column>
      <FlexContainer
        style={{ height: '100%', padding: '20px 0', overflow: 'auto' }}
      >
        <ContainerLeft column>
          {data && (
            <>
              <FlexContainer style={{ width: '100%' }} justifyContent="center">
                {data?.getDocumentById?.base?.blobRef && (
                  <AttachmentPreview
                    attachment={data.getDocumentById.base.blobRef}
                    shrinkYPx={0}
                  />
                )}
              </FlexContainer>
              <FlexContainer column style={{ marginTop: '16px' }}>
                {data &&
                  data.getDocumentById.outputSections.map(
                    (outputSections: OutputSection) => (
                      <CustomSectionDocument
                        key={outputSections.name}
                        output={outputSections}
                      />
                    )
                  )}
              </FlexContainer>
              {data && data.getDocumentById.authorizations.length > 0 && (
                <AuthSectionDocument
                  authorizations={data.getDocumentById.authorizations}
                  entityId={id}
                  entityType={EntityType.DOCUMENT}
                  refetchDocument={refetch}
                />
              )}
              {/*file && <ViewerPdf srcFile={file.file as any} />*/}
              {data.getDocumentById.attachments && (
                <FieldContainer>
                  <AttachmentsSection
                    attachments={data.getDocumentById.attachments}
                  />
                </FieldContainer>
              )}
              {data.getDocumentById.linkedDocuments && (
                <FieldContainer>
                  <DocumentLinkedSection
                    linkedDocuments={data.getDocumentById.linkedDocuments}
                  />
                </FieldContainer>
              )}
              {data.getDocumentById.documentVersions && (
                <FieldContainer>
                  <DocumentVersionSection
                    documentVersions={data.getDocumentById.documentVersions}
                  />
                </FieldContainer>
              )}
              <ActivitySection
                id={id}
                taskSubject={data.getDocumentById.base.name}
              />
            </>
          )}
        </ContainerLeft>
      </FlexContainer>
      {openEditAction && (
        <DynamicForm
          onCancel={() => setOpenEditAction(undefined)}
          onSuccess={() => {
            setOpenEditAction(undefined);
            refetch();
          }}
          documentId={openEditAction}
          executionContext={ExecutionContext.DOCUMENT}
          fileViewer={file}
        />
      )}
      {modalMoveOpen && (
        <MoveDocumentModal
          closeOnClick={() => setModalMoveOpen(false)}
          submitOnClick={(d, path) =>
            moveDocumentsSubmit({
              variables: {
                appId,
                documentIds: [id],
                targetFolderId: d,
              },
            }).then(() => {
              setCurrentPath(path);
              setModalMoveOpen(false);
            })
          }
          folderId={getPenultimateIdInDocumentPath(
            data?.getDocumentById.base.documentPath || []
          )}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
`;

const ContainerLeft = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const FieldContainer = styled(FlexContainer)`
  width: 100%;
  margin: 8px 0;
`;
