import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { KeycloakService } from '../../services/KeycloakService';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Title } from '../../components/modals/UploadFileModal/InsertFromSintropiDrive/Title';
import { IdentifiableValue, SintropiEntityType } from '@pec-manager/graphql';
import { ActionButtonDetailsDocuments } from './ActionButtonDetailsDocuments';
import useEventListener from '../../components/hooks/useEventListener';
import { Theme } from '../../theme';
import { More } from '../../svg';
import { MenuChoices } from '../../components/PagedFormDefinition/MenuChoices';
import { SvgIcon } from '../../components/common/SvgIcon';
import { useLocation } from 'react-router-dom';
import { SearchBar } from '../../components/common/Search';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';

export interface ActionButtonDetailsDocumentProps {
  onClickDownload?: () => void;
  onCLickDelete?: () => void;
  onCLickEdit?: () => void;
  onclickPublish?: () => void;
  onClickMove?: () => void;
  onClickSubscribe?: { submit?: () => void; text: string; loading?: boolean };
}

export interface DocumentContainerHeaderProps {
  path: IdentifiableValue[];
  folder: IdentifiableValue;
  backAction: () => void;
  backActionSearch: () => void;
  searchError?: boolean;
  actionsButton: ActionButtonDetailsDocumentProps;
  actionSearchBar: (val: string) => void;
  children?: React.ReactNode;
}

export const DocumentContainerHeader: FunctionComponent<
  DocumentContainerHeaderProps
> = ({
  folder,
  path,
  backAction,
  backActionSearch,
  searchError,
  actionsButton,
  actionSearchBar,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState('');

  const refContainer = useRef(null);
  const [width, setWidth] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const documentId = searchParams.get('documentId');
  const folderId = searchParams.get('folderId');

  useEffect(() => {
    if (refContainer.current) {
      setWidth((refContainer.current as any)?.offsetWidth + 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refContainer.current]);

  useEventListener('resize', () =>
    setWidth((refContainer.current as any)?.offsetWidth + 250)
  );

  useEffect(() => {
    if (searchError) {
      setSearchValue('');
    }
  }, [searchError]);

  const handleMenuChoices = useMemo(() => {
    const valueChoices = [];
    if (actionsButton.onClickMove) {
      valueChoices.push({
        text: 'move',
        onClick: actionsButton.onClickMove,
      });
    }
    if (actionsButton.onCLickEdit) {
      valueChoices.push({
        text: 'edit',
        onClick: actionsButton.onCLickEdit,
      });
    }
    if (actionsButton.onclickPublish) {
      valueChoices.push({
        text: 'publish',
        onClick: actionsButton.onclickPublish,
      });
    }
    if (actionsButton.onCLickDelete) {
      valueChoices.push({
        text: 'delete',
        onClick: actionsButton.onCLickDelete,
      });
    }
    if (actionsButton.onClickSubscribe?.submit) {
      valueChoices.push({
        text: actionsButton.onClickSubscribe.text,
        onClick: actionsButton.onClickSubscribe.submit,
      });
    }
    if (actionsButton.onClickDownload) {
      valueChoices.push({
        text: 'download',
        onClick: actionsButton.onClickDownload,
      });
    }
    return valueChoices;
  }, [actionsButton]);

  return (
    <Container column ref={refContainer}>
      <ContainerTop alignItems="center" justifyContent="space-between">
        <TitleWithChooseApp />
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </ContainerTop>
      <ContainerBottom alignItems="center" justifyContent="space-between">
        {searchValue && !(documentId || folderId) ? (
          <Title
            folder={{ id: '1', value: 'searchWithWords' }}
            translationArgs={{ wordsToSearch: searchValue }}
            backAction={() => {
              setSearchValue('');
              backActionSearch();
            }}
          />
        ) : (
          <Title
            folder={folder}
            backAction={() => {
              setSearchValue('');
              path.length > 0 && backAction();
            }}
            folderPath={path}
          />
        )}
        {Object.keys(actionsButton).length > 0 && (documentId || folderId) ? (
          <>
            {width < 1040 ? (
              <SvgClickable
                onClick={() => setOpenMenu((prevState) => !prevState)}
              >
                <SvgIcon
                  svg={<More />}
                  color={Theme.colors.c2186C6}
                  height="20px"
                  width="20px"
                />
                {openMenu && (
                  <MenuChoices
                    containerMenuProps={{ right: '6px', top: '104px' }}
                    choices={handleMenuChoices}
                    onClickOutside={() => setOpenMenu(false)}
                  />
                )}
              </SvgClickable>
            ) : (
              <ActionButtonDetailsDocuments actionsButton={actionsButton} />
            )}
          </>
        ) : (
          <SearchBar
            placeholder="searchFileOrFolder"
            actionSearchBar={actionSearchBar}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            typeEntityAutocomplete={[
              SintropiEntityType.DOCUMENT,
              SintropiEntityType.FOLDER,
            ]}
          />
        )}
      </ContainerBottom>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 106px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ContainerBottom = styled(FlexContainer)`
  width: 100%;
  height: 64px;
  padding: 0 16px;
`;

const ContainerTop = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

const SvgClickable = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  margin-right: 4px;
  cursor: pointer;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
