import { gql } from '@apollo/client/core';
import { gqlComplexShape, gqlTablesShape } from './shapes';

export const GET_TABLES = gql`
  query GetTables($appName: String!) {
    getTables(appName: $appName) {
      ${gqlTablesShape}
    }
  }
`;

export const LIST_RECORDS = gql`
  query ListRecords(
    $appName: String!
    $table: String!
    $pagination: Pagination
  ) {
    listRecords(appName: $appName, table: $table, pagination: $pagination) {
      totalResults
      records {
        id
        table
        columns {
          ${gqlComplexShape}
        }
      }
    }
  }
`;

export const GET_RECORDS_BY_IDS = gql`
  query GetRecordsByIds($appName: String!, $recordsIds: [String!]!) {
    getRecordsByIds(appName: $appName, recordsIds: $recordsIds) {
      results {
          record {
            id
            table
            columns {
              ${gqlComplexShape}
            }
          }
          hidden
      }
    }
  }
`;

export const GET_RECORD_DETAIL = gql`
  query GetRecordDetail($appName: String!, $table: String!, $queryFilter: QueryFilter) {
    getRecordDetail(appName: $appName, table: $table, queryFilter: $queryFilter) {
      id
      table
      fieldSets {
          name
          columns {
            ${gqlComplexShape}
          }
      }
    }
  }
`;

export const SEARCH_RECORDS = gql`
    query SearchRecords(
        $appName: String!
        $table: String!
        $pagination: Pagination
        $queryFilter: QueryFilter
    ) {
        searchRecords(appName: $appName, table: $table, pagination: $pagination, queryFilter: $queryFilter) {
            totalResults
            records {
                id
                table
                columns {
                    ${gqlComplexShape}
                }
            }
        }
    }
`;
