import { LineChart } from '@pec-manager/graphql';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { KeycloakService } from '../../services/KeycloakService';

export const LineChartWidget: FunctionComponent<LineChart> = ({
  labels,
  color,
  series,
}) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((ref.current as any)?.offsetWidth);

    const getWidth = () => {
      setWidth((ref.current as any)?.offsetWidth);
    };
    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, [ref]);

  const options: ApexCharts.ApexOptions = {
    series: [
      {
        data: series || [0, 0],
      },
    ],
    dataLabels: {
      dropShadow: {
        enabled: true,
        color: color,
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      offsetX: 0,
      textAnchor: 'start',
      background: {
        opacity: 1,
      },
      style: {
        colors: ['#000'],
      },
      formatter(val: number, opts?: any): string {
        return Intl.NumberFormat(KeycloakService.getLocale()).format(val);
      },
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: [color],
    xaxis: {
      categories: labels,
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 1,
    },
    tooltip: {
      y: {
        title: {
          formatter: function () {
            return '';
          },
        },
        formatter(val: number, opts?: any): string {
          return Intl.NumberFormat(KeycloakService.getLocale()).format(val);
        },
      },
    },
    responsive: [
      {
        breakpoint: 1350,
        options: {
          chart: {
            width: 700,
          },
        },
      },
      {
        breakpoint: 1150,
        options: {
          chart: {
            width: 700,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };

  return (
    <Container
      column
      alignItems="center"
      style={{ marginBottom: '24px' }}
      ref={ref}
    >
      <Chart
        options={options}
        series={[{ data: series || [0, 0] }]}
        type="line"
        width={width}
        height={400}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  .apexcharts-legend {
    max-height: 150px;
    overflow: auto;
    .apexcharts-legend-series {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
