import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_RECORDS } from '../../graphql/record/queries';
import { useLocation, useParams } from 'react-router-dom';
import { Operator, QueryFilter, Record, SortField } from '@pec-manager/graphql';

const emptyRecord = {
  records: [],
  totalResults: 0,
};

export const useRecords: (
  appCode: string,
  tableName: string,
  searchRecords: string,
  sortFields: SortField[]
) => {
  fetchMore: () => void;
  records: Record[];
  hasMore: boolean;
  loading: boolean;
  refetch: () => void;
} = (appName, tableName, searchRecords, sortFields) => {
  const [
    searchRecordsQuery,
    { loading: loadingSearchRecords, refetch: refetchSearchRecords },
  ] = useLazyQuery(SEARCH_RECORDS, {
    fetchPolicy: 'network-only',
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { appId } = useParams();
  const sectionId = searchParams.get('sectionId');
  const sectionElementId = searchParams.get('elementId');
  const sectionElementDropDownElementId = searchParams.get('dropDownId');

  const [recordList, setRecordList] = useState<{
    records: Record[];
    totalResults: number;
  }>(emptyRecord);

  useEffect(() => {
    setRecordList(emptyRecord);
  }, [tableName, sectionId, sectionElementId, sectionElementDropDownElementId]);

  const queryFilter: QueryFilter = useMemo(
    () => ({
      sorts: sortFields.length > 0 ? sortFields : undefined,
      filter: searchRecords
        ? {
            operator: {
              value: {
                string: searchRecords,
              },
              // @ts-ignore
              op: /[\&\|\!]/.test(searchRecords)
                ? Operator.search
                : Operator.icontains,
              key: 'search',
            },
          }
        : undefined,
      annotations: undefined,
    }),
    [sortFields, searchRecords]
  );

  useEffect(() => {
    if ((appName || appId) && tableName) {
      searchRecordsQuery({
        variables: {
          appName: appName || appId,
          table: tableName,
          pagination: {
            offset: 0,
            limit: 50,
          },
          queryFilter: queryFilter,
        },
      }).then((r: any) => {
        setRecordList({
          records: r.data.searchRecords.records,
          totalResults: r.data.searchRecords.totalResults,
        });
      });
    }
  }, [
    appId,
    appName,
    queryFilter,
    sortFields,
    searchRecords,
    searchRecordsQuery,
    tableName,
  ]);

  return {
    fetchMore: () => {
      refetchSearchRecords({
        appName: appName || appId,
        table: tableName,
        queryFilter: queryFilter,
        pagination: {
          offset: recordList.records.length,
          limit: 50,
        },
      }).then((r: any) => {
        setRecordList({
          records: [
            ...recordList.records,
            ...(r.data.searchRecords.records as Record[]),
          ],
          totalResults: r.data.searchRecords.totalResults,
        });
      });
    },
    records: recordList.records,
    hasMore: recordList.records.length < recordList.totalResults,
    loading: loadingSearchRecords,
    refetch: () =>
      searchRecordsQuery({
        variables: {
          appName: appName || appId,
          table: tableName,
          pagination: {
            offset: 0,
            limit: 50,
          },
          queryFilter: queryFilter,
        },
      }).then((r: any) => {
        setRecordList({
          records: r.data.searchRecords.records,
          totalResults: r.data.searchRecords.totalResults,
        });
      }),
  };
};
