import {
  ChatRoomEvent,
  ChatRoomEventType,
  User,
  ResourceContextType,
} from '@pec-manager/graphql/lib/graphql.types';
import { extractFullName } from '../../utils/stringUtils';
import { InboxV2, Task } from '../../svg';

export function updateMessageStatuses(initialEvents: ChatRoomEvent[]) {
  const messageStatuses: any = {};

  initialEvents
    .filter(
      (e) =>
        e.eventType === ChatRoomEventType.PARTICIPANT_MESSAGE_READ ||
        e.eventType === ChatRoomEventType.PARTICIPANT_MESSAGE_RECEIVED
    )
    .forEach((e) => {
      if (e.eventType === ChatRoomEventType.PARTICIPANT_MESSAGE_READ) {
        messageStatuses[e.participantMessageReadEvent!.messageNumber!] = {
          isRead: true,
          isReceived: true,
        };
      } else {
        messageStatuses[e.participantMessageReceivedEvent!.messageNumber!] = {
          isRead: false,
          isReceived: true,
        };
      }
    });

  return messageStatuses;
}

export const showAvatar = (
  reversedChatEvents: ChatRoomEvent[],
  index: number,
  actor: User
) => {
  if (index === reversedChatEvents.length - 1) {
    return true;
  }
  const previousEvent = reversedChatEvents[index + 1];

  if (
    previousEvent.participantJoinedEvent ||
    previousEvent.participantLeftEvent ||
    previousEvent.participantInvitedEvent
  ) {
    return true;
  }

  if (
    previousEvent.resourceLinkedEvent &&
    previousEvent.actor.username === actor.username
  ) {
    return false;
  }

  if (
    previousEvent.messagePostedEvent &&
    previousEvent.actor.username === actor.username
  ) {
    return false;
  }

  return true;
};

export const findLastMessageReceived = (eventsReverse: ChatRoomEvent[]) => {
  let index = 0;

  while (index < eventsReverse.length) {
    if (eventsReverse[index].eventType === ChatRoomEventType.MESSAGE_POSTED) {
      return eventsReverse[index].messagePostedEvent?.messageNumber;
    }
    index += 1;
  }
  return null;
};

export const extractTitleChat = (users?: User[], onlyOneName?: boolean) => {
  if (!users) {
    return '';
  }
  if (users.length === 1) {
    return extractFullName(users[0]);
  }
  if (onlyOneName) {
    return `${extractFullName(users[0])} +${users.length - 1}`;
  }
  if (users.length === 2) {
    return `${extractFullName(users[0])}, ${extractFullName(users[1])}`;
  }
  return `${extractFullName(users[0])}, ${extractFullName(users[1])} +${
    users.length - 2
  }`;
};

export const IconChat = (type: ResourceContextType) => {
  switch (type) {
    case ResourceContextType.INBOX:
      return InboxV2;
    case ResourceContextType.SINTROPI:
    default:
      return Task;
  }
};
