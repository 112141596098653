import React, { FC } from 'react';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import styled from 'styled-components';
import pdfJs from 'pdfjs-dist/package.json';

interface PdfViewerWithToolbarProps {
  file: string;
  inModal?: boolean;
  halfScreen?: boolean;
}

export const PdfViewerWithToolbar: FC<PdfViewerWithToolbarProps> = ({
  file,
  inModal,
  halfScreen,
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      fullScreenPlugin: {
        // Zoom to fit the screen after entering and exiting the full screen mode
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
      },
    },
  });

  return (
    <Container inModal={inModal} halfWidth={halfScreen}>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfJs.version}/build/pdf.worker.min.js`}
      >
        <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </Container>
  );
};

const Container = styled.div<{ inModal?: boolean; halfWidth?: boolean }>`
  ${({ inModal, halfWidth }) =>
    inModal
      ? `
    height: 100%;
    width: ${halfWidth ? '50%' : '70%'};
    .rpv-default-layout__container {
      border-radius: 0;
      overflow: auto;
    }
  `
      : `
    height: 972px;
    `}
  .rpv-default-layout__container {
    border: none;
  }
`;
