import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { KeycloakService } from '../services/KeycloakService';
import { env } from '../index';
import eventBus from './EventBus';

export const apolloClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors || networkError) {
      // eslint-disable-next-line no-console
      console.error(graphQLErrors || networkError);
    }
  });

  const httpLink = createHttpLink({
    uri: env('REACT_APP_GQL_HTTP_URL') || `http://localhost:8080/graphql`,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${KeycloakService.getToken()}`,
    },
  }));

  const wsLink = new WebSocketLink({
    uri: env('REACT_APP_GQL_WS_URL') || 'ws://localhost:8080/graphql',
    options: {
      reconnect: true,
      minTimeout: 99999,
      timeout: 99999,
      inactivityTimeout: 0,
      lazy: false,
      connectionParams: () => {
        console.log('reconnecting to the websocket');
        // if token is expired, refresh it
        return {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        };
      },
      connectionCallback: (error) => {
        if (error) {
          console.log('error', error);
        } else {
          // dispatch("reload");
          eventBus.dispatch('websocket-connected', true);
        }
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  return new ApolloClient({
    link: from([errorLink, splitLink]),
    connectToDevTools: env('REACT_APP_DEVTOOLS_APOLLO') || false,
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'network-only',
      },
    },
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Mail: {
          fields: {
            attachments: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        MailBase: {
          fields: {
            archiveWalkPath: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        GetExecutionById: {
          fields: {
            execution: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        ChatRoom: {
          fields: {
            participants: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
  });
};
