import {
  ExecutionContext,
  FilledForm,
  FormAction,
  FormActionType,
  PagedFormDefinition,
} from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormButton } from '../../common/FormButton';
import { Theme } from '../../../theme';
import { setDisplayName } from '../../../redux/Inbox/sidebar/actions';
import { setAdvancedSearchFilledForm } from '../../../redux/Inbox/mail/actions';
import { Text } from '../../common/Text';
import { HorizontalLoader } from '../../loader/HorizontalLoader';
import { useMutationHook } from '../../hooks/useMutationHook';
import {
  DRAFT_FORM,
  FORM_DEFINITION_BY_ACTION,
  SUBMIT_EXTENSION_FORM,
  SUBMIT_FORM,
} from '../../../graphql/form/mutations';
import { getTypeAppInPath } from '../../../utils/stringUtils';
import { UseSubmitForm } from '../../hooks/useSubmitForm';
import { ExtensionPoint } from '@pec-manager/graphql';

interface ActionsButtonsProps {
  currentPagedFormDefinition: PagedFormDefinition;
  setCurrentPagedFormDefinition: any;
  formDefinitionHasErrors: boolean;
  setChildrenPagedFormDefinition: any;
  closeOnClick?: () => void;
  closeAllModals?: () => void;
  callbackFilledForm?: (filledForm?: FilledForm) => void;
  formAction: FormAction;
  filledForm?: FilledForm;
  executionContext: ExecutionContext;
  extensionPoint?: ExtensionPoint;
  id?: string;
  childrenActionButtons?: any;
  setChildrenActionButtons?: any;
  showRequiredFields?: () => void;
  initialContext?: any;
}

export const ActionsButtons: FunctionComponent<ActionsButtonsProps> = ({
  currentPagedFormDefinition,
  setCurrentPagedFormDefinition,
  formDefinitionHasErrors,
  setChildrenPagedFormDefinition,
  closeOnClick,
  formAction,
  filledForm,
  executionContext,
  callbackFilledForm,
  closeAllModals,
  extensionPoint,
  id,
  setChildrenActionButtons,
  childrenActionButtons,
  showRequiredFields,
  initialContext,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const { appId } = useParams();

  const [submitForm, { data: formDefinitionSubmitResponse, loading }] =
    UseSubmitForm({
      mutationGql: SUBMIT_FORM,
      successMessage: 'genericSuccessMessage',
      errorMessage: 'genericErrorMessage',
    });

  const [
    draftForm,
    { data: formDefinitionSubmitDraftResponse, loading: draftLoading },
  ] = UseSubmitForm({
    mutationGql: DRAFT_FORM,
    successMessage: 'genericSuccessMessage',
    errorMessage: 'genericErrorMessage',
  });

  const [
    submitExtensionForm,
    { data: formDefinitionSubmitExtensionResponse, loading: loadingExtension },
  ] = UseSubmitForm({
    mutationGql: SUBMIT_EXTENSION_FORM,
    successMessage: 'genericSuccessMessage',
    errorMessage: 'genericErrorMessage',
  });

  const [
    openActionFilledFormDefinitionPage,
    { data: openActionFormDefinition },
  ] = useMutationHook({
    mutationGql: FORM_DEFINITION_BY_ACTION,
    errorMessage: 'genericErrorMessage',
  });

  const colorTextButton = (buttonIsDisabled?: boolean) => {
    if (buttonIsDisabled) {
      return Theme.colors.cFFFFFF;
    }
    return formAction.isPrimary ? Theme.colors.cFFFFFF : undefined;
  };

  useEffect(() => {
    if (openActionFormDefinition) {
      setChildrenPagedFormDefinition(
        openActionFormDefinition.formDefinitionByAction
      );
    }
    // eslint-disable-next-line
  }, [openActionFormDefinition]);

  useEffect(() => {
    if (formDefinitionSubmitResponse || formDefinitionSubmitDraftResponse) {
      const response: PagedFormDefinition =
        formDefinitionSubmitResponse?.submitForm?.pagedFormDefinition ||
        formDefinitionSubmitDraftResponse.draftForm?.pagedFormDefinition;

      if (!response || response.formDefinition.fieldGroups.length === 0) {
        closeAllModals && closeAllModals();
      } else {
        setCurrentPagedFormDefinition(response);
      }
    }
    // eslint-disable-next-line
  }, [formDefinitionSubmitResponse, formDefinitionSubmitDraftResponse]);

  useEffect(() => {
    if (formDefinitionSubmitExtensionResponse) {
      const response: PagedFormDefinition =
        formDefinitionSubmitExtensionResponse?.submitExtensionForm
          ?.pagedFormDefinition;

      if (!response || response.formDefinition.fieldGroups.length === 0) {
        closeAllModals && closeAllModals();
      } else {
        setCurrentPagedFormDefinition(response);
      }
    }
    // eslint-disable-next-line
  }, [formDefinitionSubmitExtensionResponse]);

  return (
    <FormButton
      isPrimary={formAction.isPrimary}
      key={formAction.name}
      padding="10px 12px"
      borderRadius="6px"
      border={
        formAction.isPrimary ? 'none' : `1px solid ${Theme.colors.cDCDCDC}`
      }
      height="32px"
      onClick={() => {
        if (loading || loadingExtension || draftLoading) return;
        switch (formAction.type) {
          case FormActionType.CANCEL:
            callbackFilledForm && callbackFilledForm(undefined);
            closeOnClick && closeOnClick();
            break;
          case FormActionType.SUBMIT:
            if (formDefinitionHasErrors) {
              showRequiredFields && showRequiredFields();
            } else {
              extensionPoint
                ? submitExtensionForm({
                    variables: {
                      execId: id,
                      extPoint: extensionPoint,
                      filledForm,
                    },
                  })
                : submitForm({
                    variables: {
                      context: executionContext,
                      currentPageId: currentPagedFormDefinition!.pageId,
                      filledForm,
                      dynamicAction: childrenActionButtons,
                      initialContext: initialContext
                        ? { context: initialContext }
                        : undefined,
                    },
                  });
            }
            break;
          case FormActionType.DRAFT:
            draftForm({
              variables: {
                context: executionContext,
                currentPageId: currentPagedFormDefinition!.pageId,
                filledForm,
                dynamicAction: childrenActionButtons,
              },
            });
            break;
          case FormActionType.DYNAMIC:
            if (formAction.dynActionCoords?.spawnsChildForm) {
              openActionFilledFormDefinitionPage({
                variables: {
                  context: executionContext,
                  dynamicAction: {
                    id: formAction.dynActionCoords?.id,
                    value: formAction.dynActionCoords?.value,
                  },
                  filledForm,
                },
              });
              if (formAction.dynActionCoords?.id) {
                setChildrenActionButtons &&
                  setChildrenActionButtons({
                    id: formAction.dynActionCoords?.id,
                    value: formAction.dynActionCoords?.value,
                  });
              }
            } else {
              submitForm({
                variables: {
                  context: executionContext,
                  currentPageId: currentPagedFormDefinition!.pageId,
                  dynamicAction: {
                    id: formAction.dynActionCoords?.id,
                    value: formAction.dynActionCoords?.value,
                  },
                  initialContext: initialContext
                    ? { context: initialContext }
                    : undefined,
                  filledForm,
                },
              });
            }
            break;
          case FormActionType.SEARCH:
            if (filledForm) {
              dispatch(setDisplayName('searchResult'));
              dispatch(
                setAdvancedSearchFilledForm({
                  pageId: currentPagedFormDefinition.pageId,
                  filledForm,
                })
              );
              !callbackFilledForm &&
                navigate(`/${appId}/${getTypeAppInPath(location)}/search`);
              closeOnClick && closeOnClick();
            }
            break;
        }
      }}
    >
      {!(loading || loadingExtension) ? (
        <Text
          text={formAction.name}
          fontSize="12px"
          lineHeight="32px"
          color={colorTextButton(
            !(
              formAction.type === FormActionType.CANCEL ||
              formAction.type === FormActionType.DRAFT ||
              formAction.type === FormActionType.DYNAMIC
            )
          )}
        />
      ) : (
        <div
          style={{
            paddingRight: '30px',
          }}
        >
          <HorizontalLoader size="xs" top="6px" color={Theme.colors.cFFFFFF} />
        </div>
      )}
    </FormButton>
  );
};
