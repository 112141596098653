import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  useLocation,
  useNavigate,
  useParams,
  Routes,
  Route,
} from 'react-router-dom';
import { InboxSidebar } from './sidebar/components/InboxSidebar';
import { MailContainer } from './mail';
import { MailDetailsHandler } from './MailDetails';
import { FlexContainer } from '../components/layout/FlexContainer';
import { InboxSidebarDefaultSectionId } from './sidebar/inboxSidebarDefaultSectionId';
import { InboxSidebarMainSectionElementId } from './sidebar/inboxSidebarDefaultSectionElements';
import { MailList } from './mail/MailList';
import { TakeInCharge } from './takeInCharge';
import useEventListener from '../components/hooks/useEventListener';
import { useResourceUpdateReceived } from '../components/hooks/useResourceUpdateReceived';

const Inbox: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refContainer = useRef(null);
  const [width, setWidth] = useState(0);
  const { appId } = useParams();

  useResourceUpdateReceived();

  useEffect(() => {
    if (
      !searchParams.get('sectionId') &&
      !(location.pathname === `${appId}/inbox/search`) &&
      !(location.pathname === `${appId}/inbox/list`) &&
      !(location.pathname === `${appId}/inbox/takeincharge`)
    ) {
      navigate(
        `?sectionId=${InboxSidebarDefaultSectionId.MAIN}&elementId=${InboxSidebarMainSectionElementId.INBOX}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refContainer.current) {
      setWidth((refContainer.current as any)?.offsetWidth + 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refContainer.current]);

  useEventListener('resize', () =>
    setWidth((refContainer.current as any)?.offsetWidth + 50)
  );

  return (
    <StyledMotionDiv>
      <FlexContainer ref={refContainer}>
        <InboxSidebar />
        <Routes>
          <Route
            path="/takeincharge"
            element={
              <TakeInCharge viewInModal={width <= 1040} context="inbox" />
            }
          />
          <Route
            path="/*"
            element={
              <MailContainer>
                <MailList viewInModal={width <= 1040} />
                {searchParams.get('mailId') && (
                  <MailDetailsHandler viewInModal={width <= 1040} />
                )}
              </MailContainer>
            }
          />
        </Routes>
      </FlexContainer>
    </StyledMotionDiv>
  );
};

const StyledMotionDiv = styled(motion.div)`
  flex: 1;
`;

export default Inbox;
