import { createAction } from '@reduxjs/toolkit';
import { BaseExecution, Execution } from '@pec-manager/graphql';

enum Actions {
  SET_SELECTED_EXECUTION_ID = 'SET_SELECTED_EXECUTION_ID',
  CLEAR_EXECUTION_LIST = 'CLEAR_EXECUTION_LIST',
  CLEAR_EXECUTION_DETAILS = 'CLEAR_EXECUTION_DETAILS',
  APPEND_EXECUTION = 'APPEND_EXECUTION',
  UPDATE_EXECUTIONS = 'UPDATE_EXECUTIONS',
  UPDATE_EXECUTION_DETAILS = 'UPDATE_EXECUTION_DETAILS',
  SET_SIMPLE_SEARCH_FILTER = 'SET_SIMPLE_SEARCH_FILTER',
  NEW_PRIORITY = 'NEW_PRIORITY',
  OPEN_CHAT_MODAL_ID = 'OPEN_CHAT_MODAL_ID',
  CLOSE_CHAT_MODAL_ID = 'CLOSE_CHAT_MODAL_ID',
  DELETE_EXECUTION = 'DELETE_EXECUTION',
}

interface AppendTasksActionType {
  executionsCount: number;
  executions: BaseExecution[];
}

export const setSelectedExecutionId = createAction<string | undefined>(
  Actions.SET_SELECTED_EXECUTION_ID
);

export const clearExecutionList = createAction(Actions.CLEAR_EXECUTION_LIST);

export const appendExecutions = createAction<AppendTasksActionType>(
  Actions.APPEND_EXECUTION
);

export const deleteExecution = createAction<string>(Actions.DELETE_EXECUTION);

export const updateExecutions = createAction<Execution>(
  Actions.UPDATE_EXECUTIONS
);

export const clearExecutionDetails = createAction(
  Actions.CLEAR_EXECUTION_DETAILS
);

export const updateExecutionDetails = createAction<Execution>(
  Actions.UPDATE_EXECUTION_DETAILS
);

export const openChatModalId = createAction<{
  id?: string;
  resourceId?: string;
}>(Actions.OPEN_CHAT_MODAL_ID);

export const closeChatModalId = createAction(Actions.CLOSE_CHAT_MODAL_ID);
