import React, { FunctionComponent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Calendar } from '../../svg';
import { Theme } from '../../theme';
import { KeycloakService } from '../../services/KeycloakService';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { HeaderDataPicker } from '../../components/common/HeaderDataPicker';
import { SvgIcon } from '../../components/common/SvgIcon';
import { DateRangeLinkButton } from '../../components/common/DateRangeLinkButton';
import { FlexContainer } from '../../components/layout/FlexContainer';

interface InputDateProps {
  onChangeValue: (value: string) => void;
  deleteDateRange: () => void;
  removeBorder?: boolean;
}

export const InputDateRange: FunctionComponent<InputDateProps> = ({
  onChangeValue,
  deleteDateRange,
  removeBorder,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  useEffect(() => {
    if (startDate && endDate) {
      onChangeValue(`${startDate.getTime()}-${endDate.getTime()}`);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <Container alignItems="center">
      <ContainerInput removeBorder={removeBorder}>
        <DatePicker
          placeholderText={t('startDateRange') as string}
          locale={
            mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
          }
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderDataPicker
              date={date}
              decreaseMonth={decreaseMonth}
              changeMonth={changeMonth}
              changeYear={changeYear}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={
            mappingLocaleForDatePicker(
              KeycloakService.getLocaleForDate()?.toLowerCase() ||
                KeycloakService.getLocale()
            ).formatDate
          }
          showPopperArrow
          todayButton={
            <ContainerTodayButton
              column
              onClick={(e: any) => e.stopPropagation()}
            >
              <DateRangeLinkButton
                text="lastYear"
                onClick={() => {
                  const today = DateTime.now();
                  setStartDate(today.minus({ years: 1 }).toJSDate());
                  setEndDate(today.toJSDate());
                }}
              />
              <DateRangeLinkButton
                text="delete"
                backgroundColor={Theme.colors.transparentRed}
                colorText={Theme.colors.cD73E18}
                onClick={() => {
                  deleteDateRange();
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
            </ContainerTodayButton>
          }
        />
        <ContainerSvg>
          <SvgIcon
            svg={<Calendar />}
            width="20px"
            color={Theme.colors.c505050}
          />
        </ContainerSvg>
      </ContainerInput>

      <ContainerInput removeBorder={removeBorder}>
        <DatePicker
          placeholderText={t('endDateRange') as string}
          selected={endDate}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderDataPicker
              date={date}
              decreaseMonth={decreaseMonth}
              changeMonth={changeMonth}
              changeYear={changeYear}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          locale={
            mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
          }
          onChange={(date) => {
            setEndDate(date);
          }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat={
            mappingLocaleForDatePicker(
              KeycloakService.getLocaleForDate()?.toLowerCase() ||
                KeycloakService.getLocale()
            ).formatDate
          }
          minDate={startDate}
          todayButton={
            <ContainerTodayButton
              column
              onClick={(e: any) => e.stopPropagation()}
            >
              <DateRangeLinkButton
                text="lastYear"
                onClick={() => {
                  const today = DateTime.now();
                  setStartDate(today.minus({ years: 1 }).toJSDate());
                  setEndDate(today.toJSDate());
                }}
              />
              <DateRangeLinkButton
                text="delete"
                backgroundColor={Theme.colors.transparentRed}
                colorText={Theme.colors.cD73E18}
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                  deleteDateRange();
                }}
              />
            </ContainerTodayButton>
          }
        />
        <ContainerSvg>
          <SvgIcon
            svg={<Calendar />}
            width="20px"
            color={Theme.colors.c505050}
          />
        </ContainerSvg>
      </ContainerInput>
    </Container>
  );
};

const Container = styled(FlexContainer)``;

const ContainerInput = styled.div<{ removeBorder?: boolean }>`
  position: relative;

  & > div {
    margin-right: 15px;
    @media (max-width: 1350px) {
      margin-right: 0;
    }
  }

  input {
    border: 2px solid
      ${({ theme, removeBorder }) => removeBorder || theme.colors.cDFE1E6};
    border-radius: 6px;
    padding: 10px;
    outline: none;
    width: 150px;
    height: 41px;
    font-size: 14px;
    line-height: 16px;
  }

  input:focus {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }

  .react-datepicker-popper {
    z-index: 4;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 7px 10px;
  }

  .react-datepicker__today-button {
    padding: 0;
  }
`;

const ContainerSvg = styled.div`
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 120px;
`;

const ContainerTodayButton = styled(FlexContainer)`
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};

  & > div:not(:first-child) {
    margin-top: 4px;
  }
`;
