import { createReducer, current } from '@reduxjs/toolkit';
import { FilledForm, MailSummary, SavedSearch } from '@pec-manager/graphql';
import {
  appendMails,
  clearMailList,
  deleteMail,
  setAdvancedSearchFilledForm,
  setNewPriorityInMail,
  setSavedSearchList,
  setSelectedMailId,
  setSimpleSearchFilter,
  updateMail,
} from './actions';

export interface MailState {
  selectedMailId?: string;
  mailCount: number;
  mails: MailSummary[] | null;
  simpleSearchFilter: string | null;
  advancedSearchFilledForm: FilledForm | null;
  advancedSearchPageId: string | null;
  savedSearchList: SavedSearch[];
}

const defaultState: MailState = {
  mailCount: 0,
  mails: null,
  simpleSearchFilter: null,
  advancedSearchFilledForm: null,
  advancedSearchPageId: null,
  savedSearchList: [],
};

export const mailReducer = createReducer<MailState>(defaultState, {
  [setSelectedMailId.type]: (state, action): MailState => ({
    ...state,
    selectedMailId: action.payload,
  }),
  [clearMailList.type]: (state): MailState => ({
    ...state,
    mails: null,
    mailCount: 0,
  }),
  [appendMails.type]: (state, action): MailState => {
    const mergingMailIds = (action.payload.mails as MailSummary[]).map(
      (m) => m.base.id
    );

    return {
      ...state,
      mailCount: action.payload.mailCount,
      mails: [
        ...(state.mails?.filter(
          (m) => !mergingMailIds.some((i) => i === m.base.id)
        ) || []),
        ...action.payload.mails,
      ],
    };
  },
  [deleteMail.type]: (state, action): MailState => ({
    ...state,
    mails: state.mails
      ? state.mails.filter((m) => m.base.id !== action.payload)
      : state.mails,
  }),
  [updateMail.type]: (state, action: { payload: MailSummary }): MailState => {
    const mailIndex = state.mails?.findIndex(
      (m) => m.base.id === action.payload.base.id
    );

    return {
      ...state,
      mailCount: mailIndex !== -1 ? state.mailCount : state.mailCount + 1,
      mails: [
        action.payload,
        ...(state.mails?.filter((t) => t.base.id !== action.payload.base.id) ||
          []),
      ],
    };
  },
  [setNewPriorityInMail.type]: (state, action): MailState => ({
    ...state,
    mails: [
      ...(current(state).mails?.map((m) =>
        m.base.id === action.payload.id
          ? {
              ...m,
              base: { ...m.base, priority: action.payload.priority },
            }
          : m
      ) || []),
    ],
  }),
  [setSimpleSearchFilter.type]: (state, action): MailState => ({
    ...state,
    simpleSearchFilter: action.payload,
    advancedSearchFilledForm: null,
    advancedSearchPageId: null,
  }),
  [setAdvancedSearchFilledForm.type]: (state, action): MailState => ({
    ...state,
    simpleSearchFilter: null,
    advancedSearchFilledForm: action.payload.filledForm,
    advancedSearchPageId: action.payload.pageId,
  }),
  [setSavedSearchList.type]: (state, action): MailState => ({
    ...state,
    savedSearchList: action.payload,
  }),
});
