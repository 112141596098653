export const Theme = {
  colors: {
    transparentGreen: 'rgba(216, 255, 134, 0.5)',
    cD8FF86: '#D8FF86',
    c7EB902: '#7EB902',
    c52C41A: '#52C41A',
    transparentOrange: 'rgba(255, 243, 134, 0.5)',
    cFFC53D: '#FFC53D',
    cB7880E: '#B7880E',
    c86E2FF: '#86E2FF',
    cE2F3FF: '#E2F3FF',
    transparentBlue: 'rgba(134, 183, 255, 0.5)',
    c86B7FF: '#86B7FF',
    cC7E7FF: '#C7E7FF',
    c4C9AFF: '#4C9AFF',
    c4259C1: '#4259C1',
    c5B84EF: '#5B84EF',
    c5176d6: '#5176d6',
    c2186C6: '#2186C6',
    c2F80ED: '#2F80ED',
    c0E53B7: '#0E53B7',
    c212832: '#212832',
    c2C313C: '#2C313C',
    c000000: '#000000',
    c404040: '#404040',
    c505050: '#505050',
    c6A6A6A: '#6A6A6A',
    cF5F5F5: '#F5F5F5',
    cEFEFEF: '#EFEFEF',
    cE4E7E7: '#E4E7E7',
    cD3D4D6: '#D3D4D6',
    transparentGrey: 'rgba(235, 234, 234, 0.5)',
    unreadNotification: 'rgba(235, 234, 234, 0.8)',
    c838383: '#838383',
    c9F9F9F: '#9F9F9F',
    c7A869A: '#7A869A',
    cA5ADBA: '#A5ADBA',
    cDCDCDC: '#DCDCDC',
    cDFE1E6: '#DFE1E6',
    cF0F0F0: '#F0F0F0',
    cFAFAFA: '#FAFAFA',
    cFCFCFC: '#FCFCFC',
    cFFFFFF: '#FFFFFF',
    transparentRed: 'rgb(255, 66, 66, 0.5)',
    cFF8686: '#FF8686',
    cFE4242: '#FE4242',
    cD73E18: '#D73E18',
    cEB5757: '#EB5757',
    c00875A: '#00875A',
    cBB86FF: '#BB86FF',
    c8E96D6: '#8E96D6',
    c00CCB3: '#00CCB3',
  },
};

export type Color = keyof typeof Theme.colors;
