import { Mail } from '@pec-manager/graphql/lib/graphql.types';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { GET_MAIL_BY_ID } from '../../graphql/mail/queries';
import { gqlName } from '../../graphql/utils';

export const useMail = (): {
  data: Mail;
  error?: ApolloError;
  refetch: any;
  loading: boolean;
} => {
  const searchParams = new URLSearchParams(useLocation().search);
  const mailId = searchParams.get('mailId');

  const [loadGetMailById, { data, error, loading, refetch }] = useLazyQuery(
    GET_MAIL_BY_ID,
    {
      variables: {
        mailId,
      },
    }
  );
  useEffect(() => {
    if (mailId) {
      loadGetMailById();
    }
  }, [loadGetMailById, mailId]);
  return {
    data: data ? data[gqlName(GET_MAIL_BY_ID)] : undefined,
    error,
    loading,
    refetch,
  };
};
