import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IdentifiableValue, ResourceState } from '@pec-manager/graphql';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { setDisplayName } from '../../../redux/Inbox/sidebar/actions';
import { Arrow } from '../../../svg';
import { ISvgIcon } from './SintropiSidebarSection';
import { SintropiSidebarSectionItemDropDown } from './SintropiSidebarSectionItemDropDown';
import { SintropiSidebarMainSectionElementId } from '../sintropiSidebarDefaultSectionElements';
import { taskSidebarStateSelector } from '../../../redux/Sintropi/sidebar/selectors';
import { setOpenMenuMobile } from '../../../redux/Sintropi/sidebar/actions';

interface SintropiSidebarSectionItemProps {
  id: string;
  sectionId: string;
  isSelected: boolean;
  svgIcon: ISvgIcon;
  text: string;
  dropDownItems?: IdentifiableValue[];
}

export const SintropiSidebarSectionItem: FunctionComponent<
  SintropiSidebarSectionItemProps
> = ({ id, sectionId, svgIcon, isSelected, text, dropDownItems = [] }) => {
  const [isDropDownOpened, setIsDropDownOpened] = useState(
    dropDownItems.length > 0
  );
  const location = useLocation();
  const { appId } = useParams();
  const sectionElementDropDownElementId = new URLSearchParams(
    location.search
  ).get('dropDownId');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { menuMobileIsOpen } = useSelector(taskSidebarStateSelector);

  useEffect(() => {
    if (isSelected && sectionElementDropDownElementId) {
      setIsDropDownOpened(true);
    }
  }, [setIsDropDownOpened, isSelected, sectionElementDropDownElementId]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setDisplayName(text));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  const onClickHandler = () => {
    if (menuMobileIsOpen) {
      dispatch(setOpenMenuMobile(false));
    }
    if (isSelected && !(!dropDownItems || dropDownItems.length === 0)) {
      setIsDropDownOpened((s) => !s);
    }
    if (id === SintropiSidebarMainSectionElementId.DOCUMENT) {
      navigate(`/${appId}/sintropi/document`);
    } else {
      navigate(`/${appId}/sintropi?sectionId=${sectionId}&elementId=${id}`);
    }
    if (id === ResourceState.IN_PROGRESS) {
      navigate(`/${appId}/sintropi/executions`);
    }
    if (id === 'ANALYTICS') {
      navigate(`/${appId}/sintropi/analytics`);
    }
    if (id === 'BUSINESS_KPI') {
      navigate(`/${appId}/sintropi/business-kpi`);
    }
    if (id === 'RECORD') {
      navigate(`/${appId}/sintropi/records`);
    }
  };

  return (
    <>
      <StyledFlexContainer alignItems="center" onClick={onClickHandler}>
        <SvgIcon
          svg={svgIcon.icon}
          width="24px"
          height="24px"
          color={
            isSelected
              ? Theme.colors.c4C9AFF
              : svgIcon.color || Theme.colors.cFFFFFF
          }
        />
        <StyledText
          text={text}
          fontSize="14px"
          lineHeight="21px"
          color={isSelected ? Theme.colors.c4C9AFF : Theme.colors.cFFFFFF}
        />
        {dropDownItems.length > 0 && (
          <ArrowIcon
            svg={<Arrow />}
            width="10.8px"
            color={isSelected ? Theme.colors.c4C9AFF : Theme.colors.cFFFFFF}
            isRotated={isDropDownOpened}
          />
        )}
      </StyledFlexContainer>
      <AnimatePresence>
        {isDropDownOpened && (
          <SintropiSidebarSectionItemDropDown
            dropDownItems={dropDownItems}
            isParentSelected={isSelected}
            sectionId={sectionId}
            sectionElementId={id}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const StyledFlexContainer = styled(FlexContainer)`
  transition: 150ms opacity ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }
`;

const StyledText = styled(Text)`
  flex: 1;
  margin-left: 12px;
`;

const ArrowIcon = styled(SvgIcon)<{ isRotated: boolean }>`
  margin-right: 9px;
  transition: 200ms transform ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`;
