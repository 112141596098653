// eslint-disable-next-line import/no-anonymous-default-export
export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    width: '100%',
    marginLeft: 16,
    padding: 2,
    control: {
      fontFamily: 'monospace',
      width: '100%',
      boxSizing: 'border-box',
    },
    highlighter: {
      padding: 10,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '2px solid #CDCDCDCD',
      borderRadius: 6,
    },
  },

  suggestions: {
    list: {
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      maxHeight: 128,
      overflowY: 'auto',
      backgroundColor: '#fff',
      position: 'absolute',
      bottom: '10px',
      width: '250px',
    },
    item: {
      padding: '10px 25px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: 'rgba(205, 205, 205, 0.4)',
      },
    },
  },
};
