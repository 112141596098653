import { createSelector } from '@reduxjs/toolkit';
import { ReduxState } from '../../index';
import { ExecutionState } from './reducers';

export const executionsStateSelector = createSelector(
  (state: ReduxState): ExecutionState => state.executionReducer,
  (state) => state
);

export const executionBaseSelector = createSelector(
  [executionsStateSelector, (state1, id: string) => id],
  (state, id) => {
    const selected = state.executions?.filter(
      (execution) => execution.id === id
    );

    return selected ? selected[0] : undefined;
  }
);
