import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { Theme } from '../../theme';
import { isToday } from '../../utils/stringUtils';

interface FromSectionProps {
  from: string;
  receivedAt?: number;
}

export const FromSection: FunctionComponent<FromSectionProps> = ({
  from,
  receivedAt: arrivedAt,
}) => {
  return (
    <FlexContainer justifyContent="space-between">
      <Container alignItems="center">
        <Text
          text="from"
          fontSize="11px"
          lineHeight="13.2px"
          fontWeight="300"
        />
        <FromText
          text={from}
          fontSize="11px"
          lineHeight="13.2px"
          skipTranslation
        />
      </Container>
      {arrivedAt && (
        <Text
          text={isToday(arrivedAt ?? 0) ? 'todayAt' : 'completeDate'}
          translationArgs={{
            date: getDateAsString(arrivedAt),
            time: getTimeAsString(arrivedAt),
          }}
          fontSize="11px"
          lineHeight="13px"
          fontWeight="300"
          color={Theme.colors.c505050}
        />
      )}
    </FlexContainer>
  );
};

const Container = styled(FlexContainer)`
  margin-bottom: 8px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FromText = styled(Text)`
  margin-left: 5px;
`;
