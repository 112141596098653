import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ApplicationOverview, Table } from '@pec-manager/graphql';
import { Text } from './Text';
import { MenuChoices } from '../PagedFormDefinition/MenuChoices';
import { SvgIcon } from './SvgIcon';
import { Arrow } from '../../svg';
import { FlexContainer } from '../layout/FlexContainer';
import { GET_APPLICATION_OVERVIEWS } from '../../graphql/application/queries';
import { HorizontalLoader } from '../loader/HorizontalLoader';
import { Theme } from '../../theme';
import { GET_TABLES } from '../../graphql/record/queries';

interface TopSelectProps {
  setTable: (table: Table) => void;
  setAppCode: (name: string) => void;
}

export const SelectTableRecord: FunctionComponent<TopSelectProps> = ({
  setTable,
  setAppCode,
}) => {
  const [openChangeAppMenu, setOpenChangeAppMenu] = useState(false);
  const [tables, setTables] = useState<Table[]>([]);
  const [appCode, _setAppCode] = useState('');
  const { data, loading } = useQuery(GET_APPLICATION_OVERVIEWS);
  const [getTablesQuery, { loading: loadingTables }] = useLazyQuery(GET_TABLES);
  const [selectedTable, setSelectedTable] = useState<Table | undefined>(
    undefined
  );
  const { appId } = useParams();

  //remove space and lowercase

  useEffect(() => {
    if (data) {
      const appCode = data.getApplicationOverviews.find(
        (app: ApplicationOverview) => app?.application?.id === appId
      ).application.code;

      _setAppCode(appCode);
      setAppCode(appCode);
    }
  }, [appId, data, setAppCode]);

  useEffect(() => {
    if (appCode) {
      getTablesQuery({
        variables: {
          appName: appCode.toLowerCase(),
        },
      }).then((res) => {
        setTables(res.data.getTables.tables);
      });
    }
  }, [appCode, getTablesQuery]);

  const choicesMenu = useMemo(() => {
    if (tables.length > 0) {
      setSelectedTable(tables[0]);
      return tables.map((table) => ({
        text: table.name,
        onClick: () => {
          setOpenChangeAppMenu(false);
          setSelectedTable(table);
          setTable(table);
        },
      }));
    } else {
      setSelectedTable({ name: 'No table' });
      return [
        {
          text: 'No table',
          onClick: () => {
            setOpenChangeAppMenu(false);
          },
        },
      ];
    }
  }, [tables, setSelectedTable, setTable]);

  useEffect(() => {
    if (tables.length > 0 && !selectedTable) {
      setSelectedTable(tables[0]);
    }
  }, [tables, selectedTable]);

  useEffect(() => {
    if (selectedTable && selectedTable.name !== 'No table') {
      setTable(selectedTable);
    }
  }, [selectedTable, setTable]);

  return (
    <>
      <Container alignItems="center">
        {loadingTables || loading ? (
          <HorizontalLoader color={Theme.colors.c5B84EF} size="s" top="12px" />
        ) : (
          <Text
            text={selectedTable?.name || 'Dashboard'}
            skipTranslation
            fontSize="14px"
            lineHeight="21px"
          />
        )}
        {openChangeAppMenu && data && (
          <MenuChoices
            containerMenuProps={{
              left: '50px',
              top: '40px',
              maxWidth: 'fit-content',
            }}
            choices={choicesMenu}
            onClickOutside={() => setOpenChangeAppMenu(false)}
          />
        )}
        {!loading && !loadingTables && (
          <ArrowClickable
            rotateFactor={openChangeAppMenu}
            onClick={() => setOpenChangeAppMenu((prevState) => !prevState)}
          >
            <SvgIcon svg={<Arrow />} height="10px" />
          </ArrowClickable>
        )}
      </Container>
      <div />
    </>
  );
};

const ArrowClickable = styled(FlexContainer)<{ rotateFactor: boolean }>`
  margin-left: 8px;
  cursor: pointer;

  transition: 250ms transform ease-in-out;
  transform: rotate(${({ rotateFactor }) => (rotateFactor ? `180deg` : `0`)});
  @media (max-width: 990px) {
    display: none;
  }
`;

const Container = styled(FlexContainer)``;
