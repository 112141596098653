import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { ContainerModal } from '../ContainerModal';
import { SvgIcon } from '../../common/SvgIcon';
import { Text } from '../../common/Text';
import { Button } from '../../common/Button';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../layout/FlexContainer';
import { WarningAlert } from '../../../svg';
import { MARK_MAIL_AS_SPAM } from '../../../graphql/mail/mutations';
import { useNotificationBanner } from '../../hooks/useNotificationBanner';

interface SpamModalProps {
  mailId: string;
  closeOnClick: () => void;
}

export const SpamModal: FunctionComponent<SpamModalProps> = ({
  mailId,
  closeOnClick,
}) => {
  const [markMailAsSpam, { data, error }] = useMutation(MARK_MAIL_AS_SPAM);
  useNotificationBanner(
    // TODO i18n
    data?.markMailAsSpam,
    error,
    {
      id: uuidv4(),
      type: 'success',
      title: 'Marked as spam successfully',
      content: 'That dirty mail is gone for good!',
    },
    {
      id: uuidv4(),
      type: 'failed',
      title: 'Woops!',
      content: 'somethingWentWrongs',
    },
    closeOnClick
  );
  return (
    <ContainerModal position="fixed" zIndex={12}>
      <Container
        onClick={(e) => e.stopPropagation()}
        justifyContent="space-between"
        alignItems="center"
        column
      >
        <SvgIcon svg={<WarningAlert />} height="63px" />
        <Text text="wantMarkPecSpam" fontSize="20px" lineHeight="24px" />
        <ContainerButton alignItems="center" justifyContent="space-around">
          <Button
            backgroundColor={Theme.colors.c5B84EF}
            hoverBackgroundColor={Theme.colors.c5176d6}
            padding="10px 12px"
            borderRadius="6px"
            onClick={() => {
              markMailAsSpam({
                variables: {
                  mailId,
                },
              });
            }}
          >
            <Text
              text="markAsSpam"
              fontSize="12px"
              lineHeight="21px"
              color={Theme.colors.cFFFFFF}
            />
          </Button>
          <Button
            backgroundColor={Theme.colors.cFFFFFF}
            padding="10px 12px"
            borderRadius="6px"
            border={`1px solid ${Theme.colors.cDCDCDC}`}
            onClick={() => {
              closeOnClick();
            }}
          >
            <Text text="doNotMark" fontSize="12px" lineHeight="21px" />
          </Button>
        </ContainerButton>
      </Container>
    </ContainerModal>
  );
};

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  overflow: hidden;
  width: 458px;
  height: 275px;
  padding: 36px;
  text-align: center;
`;

const ContainerButton = styled(FlexContainer)`
  width: 100%;
`;
