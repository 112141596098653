import {
  FormFieldDefinition,
  FormFieldValidationPredicateType,
} from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Calendar } from '../../svg';
import { Theme } from '../../theme';
import { extractDefaultDates } from '../../utils/dateUtils';
import { SvgIcon } from '../common/SvgIcon';
import { FlexContainer } from '../layout/FlexContainer';
import { FormFieldContainer } from '../modals/FormModal/FormFieldContainer';
import { DateRangeLinkButton } from '../common/DateRangeLinkButton';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { HeaderDataPicker } from '../common/HeaderDataPicker';
import { KeycloakService } from '../../services/KeycloakService';

interface InputDateProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  checkRequired?: boolean;
  columnForm?: number;
  placeholder: string;
}

export const InputDateRange: FunctionComponent<InputDateProps> = ({
  formField,
  fillField,
  errorMessage,
  checkRequired = false,
  columnForm,
  placeholder,
}) => {
  const [defaultStartDate, defaultEndDate] = useMemo(
    () => extractDefaultDates(formField.defaultValues),
    [formField]
  );
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage, formField]);

  useEffect(() => {
    setStartDate(defaultStartDate || null);
    setEndDate(defaultEndDate || null);
    setErrorMessageDate(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField]);

  const { minDate, maxDate } = useMemo(() => {
    const predicate = formField.validationPredicates.find(
      (p) => p.type === FormFieldValidationPredicateType.DATE_BETWEEN
    );

    return predicate
      ? {
          minDate: predicate.kind.dateBetweenBounds?.dLeft
            ? new Date(predicate.kind.dateBetweenBounds?.dLeft)
            : undefined,
          maxDate: predicate.kind.dateBetweenBounds?.dRight
            ? new Date(predicate.kind.dateBetweenBounds?.dRight)
            : undefined,
        }
      : {
          minDate: undefined,
          maxDate: undefined,
        };
  }, [formField]);

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={firstOnClick ? errorMessageDate : undefined}
      columnForm={columnForm}
    >
      <Container alignItems="center">
        <ContainerInput
          error={firstOnClick && !!errorMessageDate}
          isEditable={formField.isEditable}
        >
          <DatePicker
            disabled={!formField.isEditable}
            placeholderText={placeholder}
            locale={KeycloakService.getLocale()}
            onChange={(date) => {
              setStartDate(date);
              setErrorMessageDate(undefined);
              if (date) {
                fillField(
                  formField.id,
                  [`${(date as Date).getTime()}`],
                  formField.isDynamic
                );
              }
            }}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <HeaderDataPicker
                date={date}
                decreaseMonth={decreaseMonth}
                changeMonth={changeMonth}
                changeYear={changeYear}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            onFocus={() => setFirstOnClick(true)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat={
              mappingLocaleForDatePicker(
                KeycloakService.getLocaleForDate()?.toLowerCase() ||
                  KeycloakService.getLocale()
              ).formatDate
            }
            showPopperArrow
            minDate={minDate}
          />
          <ContainerSvg>
            <SvgIcon
              svg={<Calendar />}
              width="20px"
              color={Theme.colors.c505050}
            />
          </ContainerSvg>
        </ContainerInput>

        <ContainerInput
          error={firstOnClick && !!errorMessageDate}
          isEditable={formField.isEditable}
        >
          <DatePicker
            disabled={!formField.isEditable}
            placeholderText={placeholder}
            selected={endDate}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <HeaderDataPicker
                date={date}
                decreaseMonth={decreaseMonth}
                changeMonth={changeMonth}
                changeYear={changeYear}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            locale={KeycloakService.getLocale()}
            onChange={(date) => {
              setEndDate(date);
              setErrorMessageDate(undefined);
              if (date) {
                fillField(
                  formField.id,
                  [
                    `${startDate && startDate.getTime()}`,
                    `${(date as Date).getTime()}`,
                  ],
                  formField.isDynamic
                );
              }
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat={
              mappingLocaleForDatePicker(
                KeycloakService.getLocaleForDate()?.toLowerCase() ||
                  KeycloakService.getLocale()
              ).formatDate
            }
            minDate={startDate}
            maxDate={maxDate}
            onFocus={() => {
              setFirstOnClick(true);
            }}
            todayButton={
              <ContainerTodayButton column onClick={(e) => e.stopPropagation()}>
                <DateRangeLinkButton
                  text="yesterday"
                  onClick={() => {
                    setErrorMessageDate(undefined);
                    const today = DateTime.now();
                    setStartDate(today.minus({ days: 1 }).toJSDate());
                    setEndDate(today.toJSDate());
                  }}
                />
                <DateRangeLinkButton
                  text="lastWeek"
                  onClick={() => {
                    setErrorMessageDate(undefined);
                    const today = DateTime.now();
                    setStartDate(today.minus({ weeks: 1 }).toJSDate());
                    setEndDate(today.toJSDate());
                  }}
                />
                <DateRangeLinkButton
                  text="lastMonth"
                  onClick={() => {
                    setErrorMessageDate(undefined);
                    const today = DateTime.now();
                    setStartDate(today.minus({ months: 1 }).toJSDate());
                    setEndDate(today.toJSDate());
                  }}
                />
              </ContainerTodayButton>
            }
          />
          <ContainerSvg>
            <SvgIcon
              svg={<Calendar />}
              width="20px"
              color={Theme.colors.c505050}
            />
          </ContainerSvg>
        </ContainerInput>
      </Container>
    </FormFieldContainer>
  );
};

const Container = styled(FlexContainer)``;

const ContainerInput = styled.div<{
  error?: boolean;
  isEditable: boolean;
}>`
  position: relative;

  & > div {
    margin-right: 20px;
  }

  input {
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.cFE4242 : theme.colors.cDFE1E6};
    border-radius: 6px;
    padding: 10px;
    outline: none;
    width: 200px;
    height: 55px;
  }

  input:focus {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }

  .react-datepicker-popper {
    z-index: 4;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 7px 10px;
  }

  .react-datepicker__today-button {
    padding: 0;
  }
`;

const ContainerSvg = styled.div`
  pointer-events: none;
  position: absolute;
  top: 17px;
  right: 17px;
`;

const ContainerTodayButton = styled(FlexContainer)`
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};

  & > div:not(:first-child) {
    margin-top: 4px;
  }
`;
