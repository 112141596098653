import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { ResourceAction, ChatResourceType } from '@pec-manager/graphql';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ButtonWithIcon } from '../../components/common/ButtonWithIcon';
import { ActionMapping } from './ActionSectionMapping';
import { Theme } from '../../theme';
import { ModalTypeInbox } from './index';
import { GET_CHAT } from '../../graphql/chat/queries';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import { CREATE_CHAT } from '../../graphql/chat/mutations';
import { openChatModalId } from '../../redux/Sintropi/execution/actions';

interface ActionSectionProps {
  actions: ResourceAction[];
  setModalType: Dispatch<SetStateAction<ModalTypeInbox | undefined>>;
  mailId: string;
}

export const ActionSection: FunctionComponent<ActionSectionProps> = ({
  actions,
  setModalType,
  mailId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appId } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const openChatParams = searchParams.get('openChat') || false;

  const dispatch = useDispatch();

  const { data: idChat } = useQuery(GET_CHAT, {
    variables: {
      resource: {
        resourceId: mailId,
        resourceType: ChatResourceType.EXECUTION,
      },
    },
  });

  const [createChat] = useMutationHook({
    mutationGql: CREATE_CHAT,
    errorMessage: 'genericErrorMessage',
  });

  const openChat = useCallback(() => {
    if (idChat?.getChatRoomIdByResource.length > 0) {
      dispatch(
        openChatModalId({
          id: idChat.getChatRoomIdByResource,
          resourceId: mailId,
        })
      );
    } else {
      createChat({
        variables: {
          resource: {
            resourceId: mailId,
            resourceType: ChatResourceType.EXECUTION,
          },
        },
      }).then((r: any) =>
        dispatch(
          openChatModalId({
            id: r.data.createChatRoom,
            resourceId: mailId,
          })
        )
      );
    }
    // eslint-disable-next-line
  }, [idChat]);

  useEffect(() => {
    if (openChatParams) {
      openChat();
    }
  }, [openChatParams, openChat]);

  return (
    <Container alignItems="center" justifyContent="flex-end">
      {actions
        .slice()
        .sort((a, b) => b.priority - a.priority)
        .map((action, index) => {
          const { icon, onClick, text } = ActionMapping(
            action.type,
            setModalType,
            () => navigate(`/${appId}/inbox/takeincharge${location.search}`),
            () => openChat()
          )!;

          return (
            <ButtonWithIcon
              key={action.type}
              text={text}
              svg={icon}
              onClickHandler={onClick}
              color={
                actions.length - 1 === index ? Theme.colors.cFFFFFF : undefined
              }
              backgroundColor={
                actions.length - 1 === index ? Theme.colors.c5B84EF : undefined
              }
            />
          );
        })}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  margin-top: 24px;
  padding-top: 20px;
  width: 100%;
  & > div:not(:first-child) {
    margin-left: 12px;
  }
`;
