import { FormFieldDefinition } from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormFieldContainer } from './FormFieldContainer';
import {
  boundaryError,
  generatePredicatesFromDefinitions,
  validationError,
} from './utils/errorMessage';
import styled from 'styled-components';
import { FlexContainer } from '../../layout/FlexContainer';
// eslint-disable-next-line import/no-extraneous-dependencies
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import { FormViewMode } from '@pec-manager/graphql';
import { KeycloakService } from '../../../services/KeycloakService';

interface CurrencyFieldProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  svgInput?: JSX.Element;
  viewMode?: FormViewMode;
  checkRequired?: boolean;
  columnForm?: number;
}

const removePeriodOrCommasBasedOnLocalization = (
  value: string,
  localization: string
) => {
  switch (localization) {
    case 'it':
    case 'it-IT':
      return value.replace(/\./g, '').replace(/,/g, '.');
    case 'en':
    case 'en-US':
    case 'en-GB':
    case 'en-EN':
      return value.replace(/,/g, '');
    default:
      return value.replace(/\./g, '');
  }
};

export const CurrencyField: FunctionComponent<CurrencyFieldProps> = ({
  formField,
  fillField,
  errorMessage,
  viewMode,
  checkRequired,
  columnForm,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<string[]>([]);
  const [fieldError, setFieldError] = useState<string | undefined>(undefined);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);
  const setAllData = (newData: string[]) => {
    setData(newData.map((d) => d.trim()));
    fillField(
      formField.id,
      newData.map((d) =>
        removePeriodOrCommasBasedOnLocalization(
          d.trim(),
          KeycloakService.getLocale()
        )
      ),
      formField.isDynamic
    );
  };

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage, formField]);

  const calcError = useMemo(
    () =>
      firstOnClick && formField.isEditable
        ? fieldError ||
          boundaryError(
            data.length,
            formField.cardinalityBounds?.left,
            formField.cardinalityBounds?.right
          ) ||
          errorMessageDate
        : undefined,
    [formField, data, firstOnClick, fieldError, errorMessageDate]
  );

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (
      formField?.defaultValues.length > 0 &&
      formField.defaultValues[0].kind?.currency &&
      formField.defaultValues[0].kind?.currency?.values.length > 0
    ) {
      setInputValue(
        formField.defaultValues[0].kind.currency.values[0].toString()
      );
    }
  }, [formField.defaultValues]);

  useEffect(() => {
    if (data.length > 0) {
      setInputValue(data[0]);
    }
  }, [data]);

  const addInput = () => {
    if (!fieldError) {
      setAllData(inputValue?.length > 0 ? [inputValue.toString()] : []);
    }
  };

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={calcError}
      viewMode={viewMode}
      columnForm={columnForm}
    >
      <Container
        wrapContent
        alignItems="center"
        error={!!calcError}
        isEditable={formField.isEditable}
      >
        <InputStyled
          lang={KeycloakService.getLocale()}
          placeholder={t('insertCurrency') as string}
          value={inputValue}
          decimalsLimit={2}
          disabled={!formField.isEditable}
          onBlur={() => addInput()}
          intlConfig={{
            currency: formField.kind?.currency?.currencies[0].symbol,
            locale: KeycloakService.getLocale(),
          }}
          onFocus={() => setFirstOnClick(true)}
          onValueChange={(value: any) => {
            setInputValue(value || '');
            setErrorMessageDate(undefined);
            setFieldError(
              validationError(
                value || '',
                generatePredicatesFromDefinitions(
                  formField.validationPredicates
                )
              )
            );
          }}
        />
      </Container>
    </FormFieldContainer>
  );
};
const Container = styled(FlexContainer)<{
  error?: boolean;
  isEditable: boolean;
}>`
  background-color: ${({ theme, isEditable }) =>
    isEditable ? theme.colors.primaryGentle : theme.colors.transparentGrey};
  padding: 10px 10px 10px 10px;
  border: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors.cFE4242 : theme.colors.transparentGrey};
  border-radius: 6px;
  min-height: 38px;

  &:focus {
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.cFE4242 : theme.colors.cD8FF86};
  }
  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }
`;

const InputStyled = styled(CurrencyInput)`
  border: 0;
  outline: none;
  height: 31px;
  width: calc(100% - 50px);
`;
