import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Theme } from '../../../theme';
import { Text } from '../../../components/common/Text';
import { IdentifiableValue } from '@pec-manager/graphql';
import styled from 'styled-components';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Arrow } from '../../../svg';
import { useQuery } from '@apollo/client';
import { GET_EXECUTION_BY_ID } from '../../../graphql/execution/queries';
import { AroundLoader } from '../../../components/loader/AroundLoader';
import { CustomSectionSubProcess } from './CustomSectionSubProcess';

interface DetailsSubProcessProps {
  subProcess: IdentifiableValue;
  onClickBack: () => void;
}

export const DetailsSubProcess: FunctionComponent<DetailsSubProcessProps> = ({
  subProcess,
  onClickBack,
}) => {
  const { data, loading } = useQuery(GET_EXECUTION_BY_ID, {
    variables: {
      executionId: subProcess.id,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <FlexContainer
      column
      style={{
        width: '100%',
      }}
    >
      <TitleSection alignItems="center">
        <SvgClickable
          onClick={(e) => {
            e.stopPropagation();
            onClickBack();
          }}
        >
          <SvgIcon svg={<Arrow />} height="16px" width="16px" rotateDeg={90} />
        </SvgClickable>
        <Text
          text={subProcess.value}
          fontSize="16px"
          lineHeight="20px"
          color={Theme.colors.c505050}
          fontWeight="500"
        />
      </TitleSection>
      <CustomSectionContainer column>
        {loading && (
          <LoaderContainer>
            <AroundLoader />
          </LoaderContainer>
        )}
        {data && data.getExecutionById.mainVariables.length > 0 && (
          <CustomSectionSubProcess
            mainVariables={data.getExecutionById.mainVariables}
          />
        )}
      </CustomSectionContainer>
    </FlexContainer>
  );
};

const TitleSection = styled(FlexContainer)`
  height: 40px;
  width: 100%;
`;

const CustomSectionContainer = styled(FlexContainer)`
  overflow: auto;
  height: 330px;
  @media (max-width: 1440px) {
    overflow: visible;
  }
`;

const SvgClickable = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-right: 16px;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
