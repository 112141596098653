import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../common/Text';
import { Theme } from '../../theme';
import { getMappedColor } from '../../utils/colorUtils';

interface TagProps {
  text?: string;
  colorBackground?: string;
  height?: string;
}

export const TagCalendar: FunctionComponent<TagProps> = ({
  text = 'withoutCategory',
  colorBackground,
  height = 'fit-content',
}) => {
  const isWithoutCategory = text === 'withoutCategory';

  return (
    <TagContainer
      backgroundColor={getMappedColor(colorBackground || text)}
      height={height}
    >
      <Text
        text={text}
        fontSize="12px"
        lineHeight="15px"
        color={isWithoutCategory ? Theme.colors.c9F9F9F : Theme.colors.cFFFFFF}
        ellipsis
      />
      {/* {isWithoutCategory && (
        <StyledText
          lineHeight="15px"
          fontSize="10px"
          text="assign"
          color={Theme.colors.c86B7FF}
        />
      )} */}
    </TagContainer>
  );
};

const TagContainer = styled.div<{
  backgroundColor: string;
  height: string;
}>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 1px 7px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => height};
  border-radius: 6px;
  max-width: 250px;

  &:hover {
    cursor: default;
  }
`;

/* const StyledText = styled(Text)`
  padding-left: 5px;
`; */
