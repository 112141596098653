import { gql } from '@apollo/client/core';

export const READ_NOTIFICATIONS = gql`
  mutation ReadNotifications($notificationIds: [ID!]!) {
    readNotifications(notificationIds: $notificationIds) {
      _
    }
  }
`;

export const SUBSCRIBE = gql`
  mutation Subscribe($entityId: ID!, $entityType: EntityType!) {
    subscribe(entityId: $entityId, entityType: $entityType) {
      _
    }
  }
`;

export const UNSUBSCRIBE = gql`
  mutation Unsubscribe($entityId: ID!, $entityType: EntityType!) {
    unsubscribe(entityId: $entityId, entityType: $entityType) {
      _
    }
  }
`;
