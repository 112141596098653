import {
  BaseDocument,
  EntityMetadata,
  BaseDocumentFolder,
  IdentifiableValue,
} from '@pec-manager/graphql';
import React, { FunctionComponent, useState } from 'react';
import { SvgIcon } from '../../components/common/SvgIcon';
import { FolderIcon } from '../../svg';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { UserWithDate } from './UserWithDate';
import { extractFullName } from '../../utils/stringUtils';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { AttachmentPreviewModal } from '../../components/modals/AttachmentPreviewModal';
import { useNavigate } from 'react-router-dom';
import { extractThumbnailFromName } from '../../components/common/AttachmentPreview/extractThumbnail';
import { handlePathForDocumentDetails } from '../../utils/funcUtils';
import { Tag } from '../../components/tag';
import { PathShow } from '../../components/common/PathShow';

interface DocumentItemProps {
  folder?: BaseDocumentFolder;
  document?: BaseDocument;
  setCurrentFolder: ({ id, value }: IdentifiableValue) => void;
  setPathFolder: (val: IdentifiableValue[]) => void;
  setOpenMenu: React.Dispatch<React.SetStateAction<string | undefined>>;
  openMenu?: string;
}

export const DocumentItemMobile: FunctionComponent<DocumentItemProps> = ({
  document,
  folder,
  setCurrentFolder,
  setPathFolder,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();
  const metadata: EntityMetadata = document?.metadata || folder!.metadata;

  return (
    <LineItem
      key={document?.id || folder?.id}
      onClick={() => {
        if (folder?.id) {
          setPathFolder(handlePathForDocumentDetails(folder!.folderPath));
          setCurrentFolder({
            id: folder.id,
            value: folder!.name,
          });
        } else navigate(`?documentId=${document!.id}`);
      }}
      column
    >
      <ItemRight>
        <Tag text={metadata.category} />

        {(document?.metadata || folder?.metadata) && (
          <UserWithDate
            name={extractFullName(metadata.createdBy)}
            date={metadata.createdAt}
            size="small"
            notFullWidth
          />
        )}
      </ItemRight>
      <First>
        {folder && <SvgIcon svg={<FolderIcon />} height="30px" width="40px" />}
        {!folder && document?.blobRef && (
          <div style={{ position: 'relative' }}>
            <SvgIcon
              svg={
                extractThumbnailFromName(
                  document.blobRef.mimeType,
                  document.blobRef.name
                ).documents
              }
              height="30px"
              width="40px"
            />
            <TypeContainer
              numberLetters={
                extractThumbnailFromName(
                  document.blobRef.mimeType,
                  document.blobRef.name
                ).extension.length
              }
            >
              <Text
                text={
                  extractThumbnailFromName(
                    document.blobRef.mimeType,
                    document.blobRef.name
                  ).extension
                }
                fontSize="8px"
                lineHeight="30px"
                color={Theme.colors.cFFFFFF}
                fontWeight="500"
              />
            </TypeContainer>
          </div>
        )}
        <Text
          style={{ marginLeft: '8px' }}
          text={
            (document?.name &&
              `${document?.name}${document?.isPublished ? '' : ' - DRAFT'}`) ||
            folder!.name
          }
          fontSize="14px"
          lineHeight="14px"
          color={Theme.colors.c505050}
          fontWeight="500"
        />
      </First>
      {(document?.documentPath || folder?.folderPath) && (
        <PathShow
          position={
            document?.documentPath.slice(0, -1) ||
            folder!.folderPath.slice(0, -1)
          }
        />
      )}
      {showPreview && document && document.blobRef && (
        <AttachmentPreviewModal
          closeOnClick={() => setShowPreview(false)}
          downloadUrl={document.blobRef.downloadUrl}
          nameFile={document.name}
        />
      )}
    </LineItem>
  );
};

const LineItem = styled(FlexContainer)`
  width: calc(100% - 16px);
  padding: 16px;
  margin: 8px;
  background: ${Theme.colors.cFFFFFF};
  box-shadow: 0 3px 5px rgb(9 30 66 / 10%), 0 0 1px rgb(9 30 66 / 31%);
  border-radius: 8px;
`;

const First = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const ItemRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TypeContainer = styled.div<{ numberLetters: number }>`
  position: absolute;
  top: 0;
  left: ${({ numberLetters }) => {
    switch (numberLetters) {
      case 2:
        return '14px';
      case 4:
        return '10px';
      case 3:
      default:
        return '12px';
    }
  }};
`;
