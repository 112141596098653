import { gqlUserResolutionShape } from '../user/shapes';

export const gqlCommentShape = `
  id
  author { 
    ${gqlUserResolutionShape}
  }
  createdAt
  mentions { 
    ${gqlUserResolutionShape}
  }
  body
  parentId
  changedAt
`;

export const gqlCommentPageShape = `
  totalElements
  pageElements {
    ${gqlCommentShape}
  }
`;
