export function extractBeforeBetweenAndAfterBoldTags(html: string) {
  const indexOfBold = html.indexOf('<b>');
  const before = html.substring(0, indexOfBold);
  const between = html.substring(indexOfBold + 3, html.indexOf('</b>'));
  const after = html.substring(html.indexOf('</b>') + 4);
  return { before, between, after };
}
export const removeTagsFromHtml = (html: string) => {
  return html.replace(/<[^>]*>/g, '');
};
