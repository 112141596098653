import React, { FunctionComponent } from 'react';
import { Text } from '../../../components/common/Text';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { AttachmentItem } from './AttachmentItem';
import { extractTypeToMimetype } from '../../../components/common/AttachmentPreview/extractThumbnail';
import { DocumentVersion } from '@pec-manager/graphql';
import { Theme } from '../../../theme';
import { AttachmentItemMobile } from './AttachmentItemMobile';
import { useNavigate, useParams } from 'react-router-dom';

interface DocumentVersionSectionProps {
  documentVersions: DocumentVersion[];
}

export const DocumentVersionSection: FunctionComponent<
  DocumentVersionSectionProps
> = ({ documentVersions }) => {
  const navigate = useNavigate();
  const { appId } = useParams();

  return (
    <Container column>
      <Text text="versionDocument" fontSize="14px" lineHeight="17px" />
      {documentVersions.length > 0 &&
        documentVersions.map(
          ({
            documentId,
            documentName,
            documentModifiedAt,
            documentModifiedBy,
            documentBlobRef,
            number,
          }) =>
            documentBlobRef && (
              <>
                <AttachmentItem
                  key={documentId}
                  file={documentBlobRef}
                  title={`${documentName} - Version ${number}`}
                  label={extractTypeToMimetype(documentBlobRef.mimeType)}
                  numbersOfVersions={
                    documentVersions.length > 0
                      ? documentVersions.length
                      : undefined
                  }
                  user={documentModifiedBy}
                  navigateTo={() =>
                    navigate(
                      `/${appId}/sintropi/document?documentId=${documentId}`
                    )
                  }
                />
                <AttachmentItemMobile
                  key={documentId}
                  file={documentBlobRef}
                  title={`${documentName} - Version ${number}`}
                  label={extractTypeToMimetype(documentBlobRef.mimeType)}
                  numbersOfVersions={
                    documentVersions.length > 0
                      ? documentVersions.length
                      : undefined
                  }
                  user={documentModifiedBy}
                  navigateTo={() =>
                    navigate(
                      `/${appId}/sintropi/document?documentId=${documentId}`
                    )
                  }
                />
              </>
            )
        )}
      {documentVersions.length === 0 && (
        <TextStyled
          text="noDocumentVersions"
          fontSize="10px"
          lineHeight="16px"
          color={Theme.colors.c505050}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;

  & > span {
    margin-bottom: 4px;
  }

  & > div {
    margin-top: 12px;
  }
`;

const TextStyled = styled(Text)`
  margin-top: 12px;
`;
