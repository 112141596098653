import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMailsQuery } from '../../components/hooks/useMailsQuery';
import { MailItem } from './MailItem';
import MailItemLoader from './MailItemLoader';

interface MailListProps {
  viewInModal: boolean;
}

export const MailList: FunctionComponent<MailListProps> = ({ viewInModal }) => {
  const { fetchMore, mails, mailCount, loading } = useMailsQuery();

  const [rendered, setRendered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mailId = searchParams.get('mailId');
  const sectionId = searchParams.get('sectionId');

  useEffect(() => {
    if (rendered) {
      if (!viewInModal && mails.length > 0 && !mailId && sectionId) {
        navigate(`${location.search}&mailId=${mails[0].base.id}`);
      }
    } else {
      setRendered(true);
    }
    // eslint-disable-next-line
  }, [mails]);

  return (
    <ListContainer>
      <InfiniteScroll
        style={{ padding: '6px' }}
        height="calc(100vh - 110px)"
        dataLength={mails.length}
        next={fetchMore}
        hasMore={mails.length !== mailCount}
        loader={<MailItemLoader />}
      >
        {!loading
          ? mails.map((mail, i) => (
              <MailItem mail={mail} key={mail.base.id} index={i} />
            ))
          : [1, 2, 3, 4, 5, 6, 7].map((value) => (
              <MailItemLoader key={value} extended={viewInModal} />
            ))}
      </InfiniteScroll>
    </ListContainer>
  );
};

const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.cF5F5F5};
  max-width: 465px;
  width: 100%;

  @media (max-width: 1040px) {
    max-width: 100%;
  }
`;
