import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { FormDefinitionPageRef, ChatResourceType } from '@pec-manager/graphql';
import { FlexContainer } from '../layout/FlexContainer';
import { RoundedTextAvatar } from '../avatar/RoundedTextAvatar';
import { KeycloakService } from '../../services/KeycloakService';
import { SvgIcon } from '../common/SvgIcon';
import { Arrow, KO, More } from '../../svg';
import { Text } from '../common/Text';
import { Theme } from '../../theme';
import { Bubble } from '../common/Bubble';
import { MenuChoices } from './MenuChoices';
import { CommentsRight } from './CommentsRight';
import { ChatRight } from './chatRight';
import { GET_CHAT } from '../../graphql/chat/queries';
import { useMutationHook } from '../hooks/useMutationHook';
import { CREATE_CHAT } from '../../graphql/chat/mutations';

interface HeaderProps {
  title?: string;
  pageRefs: FormDefinitionPageRef[];
  currentPageId: string;
  resourceId?: string;
  closeOnClick?: () => void;
}

export enum MenuChoicesValue {
  CHRONOLOGY = 'CHRONOLOGY',
  CHAT = 'CHAT',
  COMMENTS = 'COMMENTS',
}

export const Header: FunctionComponent<HeaderProps> = ({
  title,
  pageRefs,
  currentPageId,
  resourceId,
  closeOnClick,
}) => {
  const navigate = useNavigate();
  const fullName = KeycloakService.getUsername();
  const [createChat] = useMutationHook({
    mutationGql: CREATE_CHAT,
    errorMessage: 'genericErrorMessage',
  });

  const [openMenu, setOpenMenu] = useState(false);

  const [chatRoomId, setChatRoomId] = useState<undefined | string>(undefined);

  const [openWindowRight, setOpenWindowRight] = useState<
    undefined | MenuChoicesValue
  >(undefined);

  const textTitleMenuChoice = () => {
    switch (openWindowRight) {
      /*case MenuChoicesValue.CHRONOLOGY:
        return 'chronology';*/
      case MenuChoicesValue.CHAT:
        return 'Chat';
      case MenuChoicesValue.COMMENTS:
        return 'comments';
      default:
        return '';
    }
  };

  const [getChat] = useLazyQuery(GET_CHAT);

  return (
    <FlexContainer style={{ width: '100%' }} column>
      {!closeOnClick && (
        <ContainerTop>
          <RoundedTextAvatar size={24} fontSize={12} text={fullName} />
        </ContainerTop>
      )}
      <ContainerBottom alignItems="center">
        <ContainerLeft alignItems="center">
          <BackButton
            onClick={() => (closeOnClick ? closeOnClick() : navigate(-1))}
          >
            <SvgIcon svg={<Arrow />} rotateDeg={90} height="10px" />
          </BackButton>
          <Title>
            <Text text={title || 'loading'} fontSize="18px" lineHeight="18px" />
          </Title>
        </ContainerLeft>
        <ContainerRight>
          {pageRefs.length > 1 && (
            <Step>
              {pageRefs.map((pageRef, index) => (
                <Bubble
                  key={pageRef.pageId}
                  name={pageRef.pageName}
                  index={index}
                  isSelected={pageRef.pageId === currentPageId}
                />
              ))}
            </Step>
          )}
          <SvgMenu
            alignItems="center"
            onClick={() => setOpenMenu((prevState) => !prevState)}
          >
            <SvgIcon
              svg={<More />}
              height="20px"
              color={Theme.colors.c0E53B7}
            />
            {openMenu && (
              <MenuChoices
                containerMenuProps={{ right: '6px', top: '54px' }}
                choices={[
                  /*{
                    text: 'chronology',
                    onClick: () =>
                      setOpenWindowRight(MenuChoicesValue.CHRONOLOGY),
                  },*/
                  {
                    text: 'comments',
                    onClick: () =>
                      setOpenWindowRight(MenuChoicesValue.COMMENTS),
                  },
                  {
                    text: 'Chat',
                    onClick: () => {
                      getChat({
                        variables: {
                          resource: {
                            resourceId,
                            resourceType: ChatResourceType.EXECUTION,
                          },
                        },
                      }).then((result) => {
                        if (result?.data.getChatRoomIResource?.length > 0) {
                          setChatRoomId(result.data.getChatRoomIdByResource);
                          setOpenWindowRight(MenuChoicesValue.CHAT);
                        } else {
                          createChat({
                            variables: {
                              resource: {
                                resourceId,
                                resourceType: ChatResourceType.EXECUTION,
                              },
                            },
                          }).then((r: any) => {
                            setChatRoomId(r.data.createChatRoom);
                            setOpenWindowRight(MenuChoicesValue.CHAT);
                          });
                        }
                      });
                    },
                  },
                ]}
                onClickOutside={() => setOpenMenu(false)}
              />
            )}
          </SvgMenu>
        </ContainerRight>
      </ContainerBottom>
      {openWindowRight && (
        <ContainerMenuChoice
          column
          chat={openWindowRight === MenuChoicesValue.CHAT}
        >
          <ContainerTitle
            justifyContent="space-between"
            alignItems="center"
            chat={openWindowRight === MenuChoicesValue.CHAT}
          >
            <Text
              text={textTitleMenuChoice()}
              fontSize="18px"
              lineHeight="27px"
            />
            <ClickContainer onClick={() => setOpenWindowRight(undefined)}>
              <SvgIcon
                svg={<KO />}
                height="18px"
                width="18px"
                color={Theme.colors.c838383}
              />
            </ClickContainer>
          </ContainerTitle>
          {/*{resourceId && openWindowRight === MenuChoicesValue.CHRONOLOGY && (
            <ChronologyRight resourceId={resourceId} />
          )}*/}
          {resourceId && openWindowRight === MenuChoicesValue.COMMENTS && (
            <CommentsRight resourceId={resourceId} />
          )}
          {chatRoomId && openWindowRight === MenuChoicesValue.CHAT && (
            <ChatRight chatRoomId={chatRoomId} resourceId={resourceId} />
          )}
        </ContainerMenuChoice>
      )}
    </FlexContainer>
  );
};

const ContainerTop = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  & > div {
    margin-left: auto;
    margin-right: 12px;
  }
`;

const ContainerBottom = styled(FlexContainer)`
  width: 100%;
  height: 66px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};

  @media (max-width: 1040px) {
    border-top: 0;
  }
`;

const ContainerLeft = styled(FlexContainer)``;

const ContainerRight = styled(FlexContainer)`
  margin-left: auto;
`;

const Step = styled(FlexContainer)`
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 40px;

  @media (max-width: 1040px) {
    border-right: 0;
  }
`;

const SvgMenu = styled(FlexContainer)`
  margin-right: 24px;
  margin-left: 16px;
  position: relative;
`;

const ContainerMenuChoice = styled(FlexContainer)<{ chat?: boolean }>`
  z-index: 5;
  position: absolute;
  width: 400px;
  top: 66px;
  bottom: 0;
  right: 0;
  height: calc(100% - 66px);
  background: #ffffff;
  box-shadow: -8px 0 22px rgba(0, 0, 0, 0.04);
  padding-left: ${({ chat }) => (chat ? '0' : '16px')};
`;

const ContainerTitle = styled(FlexContainer)<{ chat?: boolean }>`
  padding: ${({ chat }) => (chat ? '16px' : '0')};
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  width: 100%;
`;

const ClickContainer = styled.div`
  margin-right: 16px;
  cursor: pointer;
`;

const BackButton = styled.div`
  cursor: pointer;
  margin-left: 45px;
  margin-right: 24px;
  width: 46.2px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
`;

const Title = styled.div`
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  height: 30px;
  display: flex;
  align-items: center;
`;
