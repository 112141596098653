import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from './Text';
import { Theme } from '../../theme';

interface ButtonPrimaryProps {
  text: string;
  onClick: () => void;
  fontSize?: string;
  lineHeight?: string;
  style?: React.CSSProperties;
}

export const ButtonPrimary: FC<ButtonPrimaryProps> = ({
  onClick,
  text,
  fontSize,
  lineHeight,
  style,
}) => {
  return (
    <ButtonP onClick={onClick} style={style}>
      <Text
        text={text}
        fontSize={fontSize || '14px'}
        lineHeight={lineHeight || '18px'}
        color={Theme.colors.cFFFFFF}
        fontWeight="300"
      />
    </ButtonP>
  );
};

const ButtonP = styled.button`
  background-color: ${({ theme }) => theme.colors.c5B84EF};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: fit-content;
  padding: 6px 16px;
  border-radius: 6px;
  gap: 10px;
  height: 40px;
  background-color: ${({ theme, disabled }) => theme.colors.c5B84EF};
  transition: 200ms all ease-in-out;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: 0.9;
`;
