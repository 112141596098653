import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ResourcePriority } from '@pec-manager/graphql';
import { Text } from '../../components/common/Text';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Choice, Select } from '../../components/select';
import { Theme } from '../../theme';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Priority } from '../../svg';
import { CHANGE_MAIL_PRIORITY } from '../../graphql/mail/mutations';
import { useMutationHook } from '../../components/hooks/useMutationHook';

interface TitleSectionProps {
  mailId: string;
  title: string;
  priority: ResourcePriority;
}

const mapColorIcon = {
  [ResourcePriority.HIGH]: Theme.colors.cFE4242,
  [ResourcePriority.MEDIUM]: Theme.colors.cFFC53D,
  [ResourcePriority.LOW]: Theme.colors.c7EB902,
  [ResourcePriority.NONE]: Theme.colors.c505050,
};

const PRIORITIES = [
  ResourcePriority.HIGH,
  ResourcePriority.MEDIUM,
  ResourcePriority.LOW,
];

const PrioritySelect: Choice[] = PRIORITIES.map((priority) => ({
  svg: (
    <SvgIcon
      svg={<Priority />}
      color={mapColorIcon[priority as ResourcePriority]}
      width="13px"
    />
  ),
  displayValue: `${priority.toLowerCase()}Priority`,
  value: priority,
}));

export const TitleSection: FunctionComponent<TitleSectionProps> = ({
  mailId,
  title,
  priority,
}) => {
  const [changeMailPriority] = useMutationHook({
    mutationGql: CHANGE_MAIL_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });
  return (
    <Container wrapContent alignItems="center" justifyContent="space-between">
      <TextTitle
        text={title}
        fontSize="24px"
        lineHeight="29px"
        fontWeight="500"
        skipTranslation
        style={{
          maxWidth: 'calc(100% - 165px)',
        }}
      />
      <Select
        colorText={Theme.colors.c838383}
        fontSizeText="12px"
        lineHeightText="18px"
        choices={PrioritySelect.filter(
          (choice) => choice.value !== ResourcePriority.NONE
        )}
        width="150px"
        defaultChoice={PrioritySelect.filter((s) => s.value === priority)[0]}
        onChoiceSelected={(choice: Choice) => {
          changeMailPriority({
            variables: {
              mailId,
              newPriority: choice.value as ResourcePriority,
            },
          });
        }}
        height="32px"
        backgroundColor={Theme.colors.cFAFAFA}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  margin-bottom: 16px;
`;

const TextTitle = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
