import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ExecutionContainerHeader } from './ExecutionContainerHeader';

interface Props {
  setFullSearchText: (value: string) => void;
  children?: React.ReactNode;
}

export const ExecutionContainer: FunctionComponent<Props> = ({
  setFullSearchText,
  children,
}) => (
  <div style={{ position: 'relative', width: '100%' }}>
    <BackgroundBorderTop />
    <Container column>
      <ExecutionContainerHeader setFullSearchText={setFullSearchText} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  </div>
);

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 6px 0 0 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @media (max-width: 990px) {
    border-radius: 0;
  }
`;

const ChildrenContainer = styled(FlexContainer)`
  height: calc(100vh - 110px);
  width: calc(100vw - 250px);

  @media (max-width: 1039px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 990px) {
    width: 100%;
    height: calc((var(--vh, 1vh) * 100) - 160px);
  }
`;

const BackgroundBorderTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.c212832};
`;
