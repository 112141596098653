import {
  FormFieldValidationPredicate,
  FormFieldValidationPredicateType,
} from '@pec-manager/graphql';

export const boundaryError: (
  dataLength: number,
  left?: number,
  right?: number
) => string | undefined = (dataLength, left, right) => {
  if (right != null && dataLength > right) return 'tooManyElements';

  if (left != null && dataLength < left) {
    if (dataLength === 0) {
      return 'requiredField';
    }
    return 'fewElements';
  }
  return undefined;
};

interface ValidationPredicate {
  (value: string | number): string | undefined;
}

export const validationError: (
  inputValue: string | number,
  validationPredicates: ValidationPredicate[]
) => string | undefined = (inputValue, validationPredicates) => {
  for (const p of validationPredicates) {
    const predicateError = p(inputValue);
    if (predicateError) {
      return predicateError;
    }
  }
  return undefined;
};

export const generatePredicatesFromDefinitions = (
  predicateDefinitions: FormFieldValidationPredicate[]
): ValidationPredicate[] =>
  // eslint-disable-next-line consistent-return
  predicateDefinitions.map((p): ValidationPredicate => {
    switch (p.type) {
      case FormFieldValidationPredicateType.VALUE_BETWEEN: {
        const { vLeft, vRight } = p.kind.valueBetweenBounds!;

        const textLeft = vLeft || '0';

        return (v: number | string) => {
          if (vLeft && (v as number) < vLeft) {
            if (vRight === 1.7976931348623157e308) {
              return `Il valore deve essere maggiore di ${textLeft}`;
            }
            return `Il valore non è compreso tra ${textLeft} - ${vRight}`;
          }
          if (vRight && (v as number) > vRight) {
            if (vLeft === 5e-324) {
              return `Il valore deve essere minore di ${vRight}`;
            }
            return `Il valore non è compreso tra ${textLeft} - ${vRight}`;
          }
          return undefined;
        };
      }
      case FormFieldValidationPredicateType.LENGTH_BETWEEN: {
        const { lLeft, lRight } = p.kind.lengthBetweenBounds!;

        const textLeft = lLeft || '0';

        return (v: number | string) => {
          if (lLeft && (v as string)?.length < lLeft) {
            return `Il valore non è compreso tra ${textLeft} - ${lRight}`;
          }
          if (lRight && (v as string)?.length > lRight) {
            return `Il valore non è compreso tra ${textLeft} - ${lRight}`;
          }
          return undefined;
        };
      }
      case FormFieldValidationPredicateType.REGEX: {
        const { regex } = p.kind.regex!;
        return (v: number | string) =>
          new RegExp(regex).test(`${v}`) ? undefined : 'errorInRegex';
      }
      default:
        return (v: string | number) => undefined;
    }
  });
