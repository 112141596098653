import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { getDateAsString, getTimeAsString } from '../../../utils/dateUtils';

export interface CorrelatedMailsItemProps {
  id: string;
  title: string;
  description: string;
  date: number;
  selected: boolean;
}

export const CorrelatedMailsItem: FunctionComponent<
  CorrelatedMailsItemProps
> = ({ id, title, description, date, selected }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dropDownId = searchParams.get('dropDownId');
  const { appId } = useParams();

  const dropDownIdQueryParam = dropDownId
    ? `&dropDownId=${searchParams.get('dropDownId')}`
    : '';

  const historyPushString =
    location.pathname === `${appId}/inbox/search`
      ? `/${location.pathname}?mailId=${id}`
      : `/${appId}/inbox?sectionId=${searchParams.get(
          'sectionId'
        )}&elementId=${searchParams.get(
          'elementId'
        )}${dropDownIdQueryParam}&mailId=${id}`;

  return (
    <Container selected={selected} onClick={() => navigate(historyPushString)}>
      <ContentContainer column>
        <StyledFlexContainer justifyContent="space-between" alignItems="center">
          <Text
            text={title}
            fontSize="12px"
            lineHeight="18px"
            skipTranslation
          />
          <Text
            text={`${getDateAsString(date)}, ${getTimeAsString(date)}`}
            fontSize="10px"
            lineHeight="15px"
            skipTranslation
            color={Theme.colors.c505050}
          />
        </StyledFlexContainer>
        <DescriptionText
          text={description}
          fontSize="10px"
          lineHeight="15px"
          fontWeight="300"
          skipTranslation
          color={Theme.colors.c505050}
        />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean }>`
  width: 100%;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFAFAFA};
  height: 60px;
  padding: 0 18px;
`;

const ContentContainer = styled(FlexContainer)`
  width: 100%;
  height: 60px;
  padding: 12px 0;
`;

const StyledFlexContainer = styled(FlexContainer)`
  margin-bottom: 3px;
`;

const DescriptionText = styled(Text)`
  width: calc(100% - 37px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
