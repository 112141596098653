import React, { FC } from 'react';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Counter } from '@pec-manager/graphql';

export const CounterWidget: FC<Counter> = ({ label, value, icon }) => {
  return (
    <InnerContainer column alignItems="center" justifyContent="center">
      <Text
        text={label}
        fontSize="20px"
        lineHeight="30px"
        color={Theme.colors.c505050}
        fontWeight="300"
        style={{ marginBottom: '30px' }}
      />
      <Text text={value} fontSize="28px" lineHeight="34px" fontWeight="600" />
    </InnerContainer>
  );
};

const InnerContainer = styled(FlexContainer)`
  @media (max-width: 990px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;
