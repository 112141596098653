import React from 'react';
import { Text } from '../../components/common/Text';
import { Button } from '../../components/common/Button';
import { useNavigate } from 'react-router-dom';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Theme } from '../../theme';

export const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <FlexContainer
      alignItems="center"
      justifyContent="center"
      column
      style={{ width: '100%' }}
    >
      <Text
        text="404 Error"
        fontSize="30px"
        lineHeight="50px"
        color={Theme.colors.cFE4242}
      />
      <Button
        onClick={() => {
          navigate('/dashboard');
          window.location.reload();
        }}
      >
        <Text text="torna alla home" fontSize="14px" lineHeight="20px" />
      </Button>
    </FlexContainer>
  );
};
