import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Theme } from '../../../theme';
import { Text } from '../../../components/common/Text';
import { StateExecution } from '../StateExecution';
import {
  ExecutionStatus,
  ExecutionVariable,
  ExecutionVariableType,
  User,
} from '@pec-manager/graphql';
import { getDateAsString, getTimeAsString } from '../../../utils/dateUtils';
import { extractFullName } from '../../../utils/stringUtils';
import { UserWithoutDate } from '../../document/UserWithoutDate';
import { complexValueHandler, mapFieldsMainVariable } from './utils';

interface DetailsSectionExecutionProps {
  startedBy: User;
  startDate: number;
  endDate?: number;
  state: ExecutionStatus;
  mainVariables: ExecutionVariable[];
}

export const DetailsSectionExecution: FunctionComponent<
  DetailsSectionExecutionProps
> = ({ startedBy, endDate, startDate, state, mainVariables }) => (
  <>
    <SectionTitle text="processDetails" fontSize="16px" lineHeight="20px" />

    <Container column>
      <FlexContainer
        justifyContent="space-between"
        style={{ width: '100%', height: '100%' }}
      >
        <ContainerMainDetails column>
          <Item justifyContent="space-between" alignItems="center">
            <ItemTitle
              text="startedBy"
              fontSize="12px"
              fontWeight="300"
              lineHeight="12px"
              color={Theme.colors.c505050}
            />
            <FlexContainer alignItems="center" style={{ marginRight: '-16px' }}>
              <UserWithoutDate
                name={extractFullName(startedBy)}
                lineHeight="12px"
                fontSize="12px"
                fontSizeAvatar={12}
                sizeAvatar={28}
              />
            </FlexContainer>
          </Item>
          <Item justifyContent="space-between" alignItems="center">
            <ItemTitle
              text="startDate"
              fontSize="12px"
              lineHeight="12px"
              color={Theme.colors.c505050}
            />
            <Text
              text={
                getDateAsString(Date.now()) === getDateAsString(startDate)
                  ? 'todayAt'
                  : 'completeDate'
              }
              fontSize="12px"
              color={Theme.colors.c000000}
              translationArgs={{
                date: getDateAsString(startDate),
                time: getTimeAsString(startDate),
                fresh: true,
              }}
              lineHeight="12px"
            />
          </Item>
          {endDate && (
            <Item justifyContent="space-between" alignItems="center">
              <ItemTitle
                text="endDate"
                fontSize="12px"
                lineHeight="12px"
                color={Theme.colors.c505050}
              />
              <Text
                text={
                  getDateAsString(Date.now()) === getDateAsString(endDate)
                    ? 'todayAt'
                    : 'completeDate'
                }
                fontSize="12px"
                color={Theme.colors.c000000}
                translationArgs={{
                  date: getDateAsString(endDate),
                  time: getTimeAsString(endDate),
                  fresh: true,
                }}
                lineHeight="12px"
              />
            </Item>
          )}
          <Item justifyContent="space-between" alignItems="center">
            <ItemTitle
              text="state"
              fontSize="12px"
              fontWeight="300"
              lineHeight="12px"
              color={Theme.colors.c505050}
            />
            <FlexContainer alignItems="center">
              <StateExecution state={state} fontSize={12} sizeCircle="16px" />
            </FlexContainer>
          </Item>
        </ContainerMainDetails>
        <ContainerOtherDetails column>
          {mainVariables.length > 0 &&
            mainVariables.map(({ name, values, type, complex }) => {
              if (type !== ExecutionVariableType.COMPLEX) {
                const { values: mappedValues, translationArgs } =
                  mapFieldsMainVariable(type, values);
                return (
                  <Item
                    justifyContent="space-between"
                    alignItems="center"
                    key={name}
                  >
                    <ItemTitle
                      text={name}
                      fontSize="12px"
                      lineHeight="12px"
                      fontWeight="300"
                      color={Theme.colors.c505050}
                      skipTranslation
                    />
                    <Text
                      text={mappedValues}
                      fontSize="12px"
                      translationArgs={translationArgs}
                      lineHeight="20px"
                      possibleLink
                      skipTranslation={
                        type !== ExecutionVariableType.DATE &&
                        type !== ExecutionVariableType.USER
                      }
                      style={{ paddingLeft: '16px', textAlign: 'right' }}
                    />
                  </Item>
                );
              } else {
                return null;
              }
            })}
        </ContainerOtherDetails>
      </FlexContainer>
      <FlexContainer justifyContent="space-between" style={{ width: '100%' }}>
        {mainVariables.length > 0 &&
          mainVariables.map(({ name, values, type, complex }) => {
            if (type === ExecutionVariableType.COMPLEX) {
              return (
                <Item alignItems="center" key={name} column>
                  <ItemTitle
                    text={name}
                    fontSize="12px"
                    lineHeight="20px"
                    fontWeight="300"
                    color={Theme.colors.c505050}
                    skipTranslation
                  />
                  <FlexContainer justifyContent="center">
                    {complex?.map((value) => (
                      <FlexContainer
                        alignItems="center"
                        column
                        style={{
                          border: `1px solid ${Theme.colors.transparentGrey}`,
                        }}
                      >
                        <Text
                          text={value.name}
                          fontSize="12px"
                          lineHeight="20px"
                          possibleLink
                          skipTranslation={true}
                          style={{
                            padding: '8px',
                            textAlign: 'center',
                            borderBottom: `1px outset ${Theme.colors.transparentGrey}`,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        />
                        {value.value &&
                          value.value.map((v) => {
                            return (
                              <Text
                                text={complexValueHandler(v.value || '')}
                                fontSize="12px"
                                lineHeight="20px"
                                possibleLink
                                skipTranslation={true}
                                style={{
                                  width: '100%',
                                  padding: '8px',
                                  display: 'flex',
                                  textAlign: 'center',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '60px',
                                  borderBottom: `1px outset ${Theme.colors.transparentGrey}`,
                                  overflow: 'auto',
                                }}
                              />
                            );
                          })}
                      </FlexContainer>
                    ))}
                  </FlexContainer>
                </Item>
              );
            } else {
              return null;
            }
          })}
      </FlexContainer>
    </Container>
  </>
);

const Container = styled(FlexContainer)`
  max-height: 400px;
  min-height: 300px;
  overflow: auto;
  padding: 16px;

  border: 1px solid ${Theme.colors.cDCDCDC};
  border-radius: 4px;

  @media (max-width: 990px) {
    width: 100%;
    height: 270px;
    flex-direction: column;
    overflow: auto;
  }
`;

const ContainerMainDetails = styled(FlexContainer)`
  width: 40%;

  @media (max-width: 990px) {
    width: 100%;
  }
`;

const ContainerOtherDetails = styled(FlexContainer)`
  overflow: auto;
  height: 100%;
  width: 45%;
  padding-right: 8px;

  @media (max-width: 990px) {
    width: 100%;
    padding: 0;
    overflow: visible;
  }
`;

const Item = styled(FlexContainer)`
  width: 100%;
  padding: 8px 0;
`;

const ItemTitle = styled(Text)`
  margin: 8px;
`;
const SectionTitle = styled(Text)`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 4px;
`;
