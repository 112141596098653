import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from './Text';
import { Theme } from '../../theme';

interface DateRangeLinkButtonProps {
  text: string;
  onClick: () => void;
  backgroundColor?: string;
  colorText?: string;
}

export const DateRangeLinkButton: FunctionComponent<
  DateRangeLinkButtonProps
> = ({ onClick, text, backgroundColor, colorText }) => (
  <Container onClick={onClick} backgroundColor={backgroundColor}>
    <Text
      text={text}
      fontSize="10px"
      lineHeight="10px"
      fontWeight="300"
      color={colorText || Theme.colors.c5B84EF}
    />
  </Container>
);

const Container = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'rgba(91, 132, 239, 0.11)'};
  padding: 4px 2px;
  border-radius: 4px;
`;
