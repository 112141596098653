import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from './Text';
import { SvgIcon } from './SvgIcon';
import { Theme } from '../../theme';
import { FlexContainer } from '../layout/FlexContainer';
import { Close } from '../../svg';

interface SelectedMailProps {
  text: string;
  onClickDelete?: (text: string) => void;
}

export const SelectedInput: FunctionComponent<SelectedMailProps> = ({
  text,
  onClickDelete,
}) => (
  <Container alignItems="center">
    <Text
      text={text}
      fontSize="14px"
      lineHeight="21px"
      skipTranslation
      color={Theme.colors.c5B84EF}
    />
    {onClickDelete && (
      <ClickContainer onClick={() => onClickDelete(text)}>
        <SvgIcon
          svg={<Close />}
          height="10px"
          width="10px"
          color={Theme.colors.c5B84EF}
        />
      </ClickContainer>
    )}
  </Container>
);

const Container = styled(FlexContainer)`
  background: rgba(91, 132, 239, 0.11);
  border-radius: 40px;
  width: fit-content;
  padding: 5px 10px;
  margin-right: 10px;
`;

const ClickContainer = styled.div`
  margin-left: 9px;
  cursor: pointer;
`;
