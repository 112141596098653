import { createAction } from '@reduxjs/toolkit';
import { ResourcePriority, TaskSummary } from '@pec-manager/graphql';

enum Actions {
  SET_SELECTED_TASK_ID = 'SET_SELECTED_TASK_ID',
  CLEAR_TASK_LIST = 'CLEAR_TASK_LIST',
  CLEAR_TASK_WITH_DUE_DATE_LIST = 'CLEAR_TASK_WITH_DUE_DATE_LIST',
  CLEAR_TASK_WITHOUT_DUE_DATE_LIST = 'CLEAR_TASK_WITHOUT_DUE_DATE_LIST',
  APPEND_TASKS = 'APPEND_TASKS',
  APPEND_TASKS_WITH_DUE_DATE = 'APPEND_TASKS_WITH_DUE_DATE',
  APPEND_TASKS_WITHOUT_DUE_DATE = 'APPEND_TASKS_WITHOUT_DUE_DATE',
  UPDATE_TASK = 'UPDATE_TASK',
  SET_SIMPLE_SEARCH_FILTER = 'SET_SIMPLE_SEARCH_FILTER',
  NEW_PRIORITY = 'NEW_PRIORITY',
  DELETE_TASK = 'DELETE_TASK',
}

interface AppendTasksActionType {
  taskCount: number;
  tasks: TaskSummary[];
}

export const setSelectedTaskId = createAction<string>(
  Actions.SET_SELECTED_TASK_ID
);

export const clearTaskList = createAction(Actions.CLEAR_TASK_LIST);

export const clearTaskWithDueDateList = createAction(
  Actions.CLEAR_TASK_WITH_DUE_DATE_LIST
);

export const clearTaskWithoutDueDateList = createAction(
  Actions.CLEAR_TASK_WITHOUT_DUE_DATE_LIST
);

export const appendTasks = createAction<AppendTasksActionType>(
  Actions.APPEND_TASKS
);

export const appendTasksWithDueDate = createAction<AppendTasksActionType>(
  Actions.APPEND_TASKS_WITH_DUE_DATE
);

export const appendTasksWithoutDueDate = createAction<AppendTasksActionType>(
  Actions.APPEND_TASKS_WITHOUT_DUE_DATE
);

export const deleteTask = createAction<string>(Actions.DELETE_TASK);

export const updateTask = createAction<TaskSummary>(Actions.UPDATE_TASK);

export const setSimpleSearchFilter = createAction<string>(
  Actions.SET_SIMPLE_SEARCH_FILTER
);

export const setNewPriorityInTask = createAction<{
  id: string;
  priority: ResourcePriority;
}>(Actions.NEW_PRIORITY);
