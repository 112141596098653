import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { SubTaskTitle } from './SubTaskTitle';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import styled from 'styled-components';
import { BaseTask, ResourcePriority } from '@pec-manager/graphql';
import { motion } from 'framer-motion';
import { handleRoundedAvatar } from '../../../utils/stringUtils';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { ItemMenuChoices } from '../../document';
import { mappingLocaleForDatePicker } from '../../../utils/locale';
import { KeycloakService } from '../../../services/KeycloakService';
import { ContainerClear, PrioritySelect } from '../../TaskCalendar/EventItem';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { AssignedIcon, Calendar, More, TakeInChargeIcon } from '../../../svg';
import { Choice, Select } from '../../../components/select';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import {
  CHANGE_TASK_DUE_DATE,
  CHANGE_TASK_PRIORITY,
  REASSIGN_TASK,
} from '../../../graphql/task/mutations';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { DynamicGenericUserField } from '../../../components/common/GenericUserSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuChoices } from '../../../components/PagedFormDefinition/MenuChoices';
import { HeaderDataPicker } from '../../../components/common/HeaderDataPicker';

/* eslint-disable react/no-children-prop */

interface SubTaskContainerProps {
  task: BaseTask;
}

export const SubTaskItem: FunctionComponent<SubTaskContainerProps> = ({
  task,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [changeTaskDueDate] = useMutationHook({
    mutationGql: CHANGE_TASK_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeTaskPriority] = useMutationHook({
    mutationGql: CHANGE_TASK_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [reassignTask] = useMutationHook({
    mutationGql: REASSIGN_TASK,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const executionId = searchParams.get('executionId');

  const [users, setUsers] = useState<string[]>([]);

  const [displayValueUsers, setDisplayValueUsers] = useState<
    {
      name: string;
      type: 'GROUP' | 'USER';
      id?: string;
    }[]
  >([]);

  const [date, setDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    setUsers(handleRoundedAvatar(task).map((user) => user.id!));
    setDisplayValueUsers(handleRoundedAvatar(task));
    setDate(task.expiresAt);

    // eslint-disable-next-line
  }, [task]);

  const navigate = useNavigate();

  const taskLink = executionId ? `&taskId=${task.id}` : `?taskId=${task.id}`;

  const [openMenu, setOpenMenu] = React.useState<string | undefined>(undefined);
  const choicesMenu = [
    {
      text: 'openTask',
      onClick: () => {
        navigate(taskLink);
      },
    },
  ];

  return (
    <SubTaskContainer
      key={task.id}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: '100%' }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ type: 'just' }}
    >
      <SubTaskFirstItem
        onClick={(event) => {
          event.stopPropagation();
          navigate(taskLink);
        }}
      >
        <SubTaskTitle title={task.name} processName={task.category || ''} />
      </SubTaskFirstItem>
      <ItemMedium justifyContent="space-around" column>
        <Text
          text={task.inCharge?.id ? 'takeInChargeTo' : 'assignedToBottomList'}
          fontSize="12px"
          lineHeight="20px"
          color={Theme.colors.c505050}
          style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
        />
        <DynamicGenericUserField
          openUp={
            // @ts-ignore
            window.innerHeight - ref.current?.getBoundingClientRect().top < 214
          }
          displayValue={displayValueUsers}
          users={users}
          disabled={!task.isReassignable}
          setUsers={(el) => {
            const us = displayValueUsers
              .filter((user) => user.type !== 'GROUP')
              .map((user) => user.id!);

            const usersWithoutGroup = Array.from(new Set(us.concat(el.id!)));

            const groups = displayValueUsers.filter((u) => u.type === 'GROUP');

            reassignTask({
              variables: {
                taskId: task.id,
                userIds: usersWithoutGroup,
                groupIds: groups.length ? groups.map((g) => g.id!) : [],
              },
            }).then(() => {
              if (groups.length > 0) {
                setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
              } else {
                setUsers(usersWithoutGroup);
              }
              setDisplayValueUsers((prevState) => {
                if (
                  prevState.find(
                    (element) => el.name === `${el.firstName} ${el.lastName}`
                  )
                ) {
                  return prevState;
                } else {
                  return [
                    ...prevState,
                    {
                      name: `${el.firstName} ${el.lastName}`,
                      type: 'USER',
                      id: el.id,
                    },
                  ];
                }
              });
            });
          }}
          onClickRemove={(id) => {
            const us = displayValueUsers
              .filter((user) => user.type !== 'GROUP')
              .map((user) => user.id!);

            const usersWithoutGroup = us.filter((user) => user !== id);

            const groups = displayValueUsers.filter((u) => u.type === 'GROUP');

            reassignTask({
              variables: {
                taskId: task.id,
                userIds: usersWithoutGroup,
                groupIds: groups.length ? groups.map((g) => g.id!) : [],
              },
            }).then(() => {
              if (groups.length > 0) {
                setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
              } else {
                setUsers(usersWithoutGroup);
              }
              setDisplayValueUsers((prevState) =>
                prevState.filter((element) => element.id !== id)
              );
            });
          }}
        />
      </ItemMedium>
      {!KeycloakService.removeFieldInExecution().includes('dueDate') && (
        <ItemSmall>
          <DatePickerStyled
            placeholderText={t('insertDate') as string}
            disabled={!task.isManageable}
            locale={
              mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
            }
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <HeaderDataPicker
                date={date}
                decreaseMonth={decreaseMonth}
                changeMonth={changeMonth}
                changeYear={changeYear}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            dateFormat={
              mappingLocaleForDatePicker(
                KeycloakService.getLocaleForDate()?.toLowerCase() ||
                  KeycloakService.getLocale()
              ).formatDate
            }
            onChange={(date) => {
              changeTaskDueDate({
                variables: {
                  taskId: task.id,
                  newDueDate: new Date(date as any).getTime(),
                },
              }).then(() => {
                setDate(date as any);
              });
            }}
            children={
              <ContainerClear
                onClick={() => {
                  changeTaskDueDate({
                    variables: {
                      taskId: task.id,
                      newDueDate: undefined,
                    },
                  }).then(() => {
                    setDate(undefined);
                  });
                }}
              >
                <Text
                  text={t('clearDueDate')}
                  fontSize="12px"
                  lineHeight="32px"
                  fontWeight="300"
                  color={Theme.colors.cD73E18}
                />
              </ContainerClear>
            }
            value={date as string | undefined}
            selected={date}
            showTimeSelect={false}
            timeFormat="HH:mm"
          />
          <SvgIcon
            svg={<Calendar />}
            color={Theme.colors.c505050}
            width="18px"
            style={{ marginLeft: '-26px', cursor: 'default' }}
          />
        </ItemSmall>
      )}
      {!KeycloakService.removeFieldInExecution().includes('priority') && (
        <SelectItem>
          <Select
            colorText={Theme.colors.c838383}
            fontSizeText="12px"
            lineHeightText="18px"
            choices={PrioritySelect.filter(
              (choice) => choice.value !== ResourcePriority.NONE
            )}
            defaultChoice={
              PrioritySelect.filter((s) => s.value === task.priority)[0]
            }
            disabled={!task.isManageable}
            onChoiceSelected={(choice: Choice) => {
              changeTaskPriority({
                variables: {
                  taskId: task.id,
                  newPriority: choice.value as ResourcePriority,
                },
              });
            }}
            height="32px"
            backgroundColor={Theme.colors.cFAFAFA}
            noDisplayValue
          />
        </SelectItem>
      )}
      <ItemState alignItems="center">
        <SvgIcon
          svg={task.inCharge?.id ? <TakeInChargeIcon /> : <AssignedIcon />}
          height="20px"
          width="20px"
        />
        <TextState
          text={task.inCharge?.id ? 'takenInCharge' : 'assigned'}
          fontSize="14px"
          lineHeight="20px"
          color={Theme.colors.c505050}
        />
      </ItemState>
      <ItemMenuChoices onClick={(e) => e.stopPropagation()}>
        <SvgClickable
          onClick={(e) => {
            e.stopPropagation();
            setOpenMenu(task.id === openMenu ? undefined : task.id);
          }}
        >
          <SvgIcon
            svg={<More />}
            color={Theme.colors.c2186C6}
            height="20px"
            width="20px"
          />
          {openMenu === task.id && (
            <MenuChoices
              containerMenuProps={{ right: '-10px', top: '36px' }}
              choices={choicesMenu}
              onClickOutside={() => setOpenMenu(undefined)}
            />
          )}
        </SvgClickable>
      </ItemMenuChoices>
    </SubTaskContainer>
  );
};

const SubTaskContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  margin-top: 8px;
  padding: 2px 0;
`;

const SubTaskFirstItem = styled.div`
  flex: 3 1 0%;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
`;

const ItemSmall = styled(FlexContainer)`
  min-height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  @media (max-width: 1300px) {
    width: 16%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ItemMedium = styled(FlexContainer)`
  min-height: 100%;
  flex: 1;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 19%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ItemState = styled(FlexContainer)`
  min-height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 7%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const TextState = styled(Text)`
  margin-left: 8px;

  @media (max-width: 1300px) {
    display: none;
  }
`;

const SelectItem = styled(FlexContainer)`
  display: flex;
  align-items: center;
  min-height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 16%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 100%;
  overflow: auto;
  width: calc(100% - 4px);
  text-overflow: ellipsis;
  padding-right: 30px;
`;

const SvgClickable = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
