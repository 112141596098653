import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { KeycloakService } from '../../services/KeycloakService';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { Select } from '../../components/select';
import { KpiSelectFilterOption, KpiTab } from '@pec-manager/graphql';
import { Theme } from '../../theme';
import { useParams } from 'react-router-dom';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';
import { useBusinessKpi } from '../../components/hooks/useBusinessKpi';
import { KpiContainerProps } from './KpiContainer';
import { InputDateRange } from './InputDateRange';
import { useTranslation } from 'react-i18next';

export const KpiContainerHeaderMobile: FunctionComponent<KpiContainerProps> = ({
  setKpiRow,
  setHeaderHeight,
}) => {
  const { appId } = useParams();
  const { t } = useTranslation();
  const ref = React.useRef<HTMLDivElement>(null);

  const [tabSelected, setTabSelected] = useState('');

  const [filterValue, setFilterValue] = useState<
    { name: string; value: string }[]
  >([]);

  const { kpiTabs, kpiFilters, kpiRow } = useBusinessKpi(
    appId || '',
    tabSelected,
    filterValue
  );

  useEffect(() => {
    setHeaderHeight && setHeaderHeight(ref.current?.offsetHeight || 0);
    // eslint-disable-next-line
  }, [ref, kpiFilters]);

  useEffect(() => {
    if (kpiTabs && kpiTabs.length > 0 && !tabSelected) {
      setTabSelected(kpiTabs[0].tabId);
    }
    // eslint-disable-next-line
  }, [appId, kpiTabs]);

  useEffect(() => {
    setKpiRow(kpiRow);
  }, [kpiRow, setKpiRow]);

  useEffect(() => {
    if (kpiFilters && kpiFilters.length > 0) {
      setFilterValue(
        kpiFilters.map(({ required, options, selectId }) => ({
          name: selectId,
          value:
            required && options && options.length > 0 ? options[0].value : '',
        }))
      );
    }
    // eslint-disable-next-line
  }, [kpiFilters]);

  const choices = useMemo(
    () => (option: KpiSelectFilterOption[], required: boolean) => {
      if (required) {
        return option.map(({ label, value }) => ({
          label,
          value,
        }));
      }
      return option
        .map(({ label, value }) => ({
          label,
          value,
        }))
        .concat({
          label: '',
          value: t('remove'),
        });
    },
    // eslint-disable-next-line
    []
  );

  const tabChoices = useMemo(
    () => (option: KpiTab[]) => {
      return option.map(({ tabId, label }) => ({
        displayValue: label,
        value: tabId,
      }));
    },
    // eslint-disable-next-line
    [kpiTabs]
  );

  return (
    <Container column ref={ref}>
      <TopContainer alignItems="center" justifyContent="space-between">
        <TitleWithChooseApp />
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </TopContainer>
      <BottomContainer
        alignItems="center"
        justifyContent="space-between"
        column
      >
        <TitleContainer>
          <LeftContainer alignItems="center">
            <Text
              text="businessKpi"
              fontSize="18px"
              lineHeight="27px"
              fontWeight="500"
              style={{ marginRight: '16px' }}
            />
          </LeftContainer>
        </TitleContainer>
        <TabContainer>
          <LeftTabContainer wrapContent>
            <FilterContainer key="selectTab">
              <Text
                text="selectTab"
                fontSize="12px"
                lineHeight="16px"
                style={{ marginRight: '16px' }}
              />
              <Select
                width="150px"
                borderContainer={`2px solid ${Theme.colors.cDFE1E6}`}
                borderRadius="6px"
                defaultChoice={{
                  label:
                    kpiTabs?.find((tab) => tab.tabId === tabSelected)?.label ||
                    '',
                  value:
                    kpiTabs?.find((tab) => tab.tabId === tabSelected)?.tabId ||
                    '',
                }}
                choices={tabChoices(kpiTabs || [])}
                onChoiceSelected={(choice) => {
                  setTabSelected(choice.value);
                }}
                zIndex={3}
                lineHeightText="16px"
                fontSizeText="14px"
              />
            </FilterContainer>
            {kpiFilters &&
              kpiFilters.map(({ label, options, required, selectId }) => {
                if (selectId !== 'date_range') {
                  return (
                    <FilterContainer key={label}>
                      <Text
                        text={label}
                        fontSize="12px"
                        lineHeight="16px"
                        style={{ marginRight: '16px' }}
                      />
                      {options && options.length > 0 && (
                        <Select
                          width="150px"
                          borderContainer={`2px solid ${Theme.colors.cDFE1E6}`}
                          borderRadius="6px"
                          defaultChoice={filterValue.find(
                            (filter) => filter.name === selectId
                          )}
                          choices={choices(options, required)}
                          onChoiceSelected={(choice) => {
                            setFilterValue((prevState) => {
                              if (choice.value === t('remove')) {
                                return prevState.filter(
                                  (filter) => filter.name !== selectId
                                );
                              }
                              const choiceSelected = {
                                value: choice.value,
                                name: selectId,
                              };
                              return prevState
                                .filter((filter) => filter.name !== selectId)
                                .concat(choiceSelected);
                            });
                          }}
                          zIndex={2}
                          lineHeightText="16px"
                          fontSizeText="14px"
                        />
                      )}
                    </FilterContainer>
                  );
                } else {
                  return (
                    <FilterContainer key={label}>
                      <Text
                        text={label}
                        fontSize="12px"
                        lineHeight="16px"
                        style={{ marginRight: '16px' }}
                      />
                      <InputDateRange
                        onChangeValue={(value) => {
                          setFilterValue((prevState) => {
                            const choiceSelected = {
                              value,
                              name: selectId,
                            };

                            return prevState
                              .filter((filter) => filter.name !== selectId)
                              .concat(choiceSelected);
                          });
                        }}
                        deleteDateRange={() =>
                          setFilterValue((prevState) =>
                            prevState.filter(
                              (filter) => filter.name !== selectId
                            )
                          )
                        }
                      />
                    </FilterContainer>
                  );
                }
              })}
            {/*<TabItem
              selected={tabAnalytics === TabAnalyticsEnum.BUSINESS}
              onClick={() => setTabAnalytics(TabAnalyticsEnum.BUSINESS)}
            >
              <Text
                text="processBusiness"
                fontSize="12px"
                lineHeight="22px"
                color={
                  tabAnalytics === TabAnalyticsEnum.BUSINESS
                    ? Theme.colors.c5B84EF
                    : undefined
                }
              />
            </TabItem>*/}
          </LeftTabContainer>
        </TabContainer>
      </BottomContainer>
      {/* <ContainerBottom alignItems="center" justifyContent="flex-end">
            <SvgClickable onClick={() => setOpenMenu((prevState) => !prevState)}>
              <SvgIcon
                svg={<More />}
                color={Theme.colors.c2186C6}
                height="20px"
                width="20px"
              />
              {openMenu && (
                <MenuChoices
                  containerMenuProps={{ right: '6px', top: '104px' }}
                  choices={[
                    {
                      text: 'NonSoCosaCiVa',
                      onClick: () => {},
                    },
                  ]}
                />
              )}
            </SvgClickable>
          </ContainerBottom> */}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  padding: 0 16px;
`;

const LeftContainer = styled(FlexContainer)`
  max-width: 465px;
  width: 100%;
  padding-left: 16px;
`;

const TopContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

const TitleContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const TabContainer = styled(FlexContainer)`
  width: 100%;
  padding-bottom: 10px;
`;

const LeftTabContainer = styled(FlexContainer)``;

const FilterContainer = styled(FlexContainer)`
  padding: 0 16px;

  @media (max-width: 1440px) {
    flex-direction: column;
    padding: 0;
  }
`;
