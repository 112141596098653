import { gql } from '@apollo/client/core';

export const REASSIGN_MAIL = gql`
  mutation ReassignMail($mailId: ID!, $userId: ID!, $comment: String) {
    reassignMail(mailId: $mailId, userId: $userId, comment: $comment) {
      _
    }
  }
`;

export const CHANGE_MAIL_PRIORITY = gql`
  mutation ChangeMailPriority($mailId: ID!, $newPriority: MailPriority!) {
    changeMailPriority(mailId: $mailId, newPriority: $newPriority) {
      _
    }
  }
`;

export const MARK_MAIL_AS_SPAM = gql`
  mutation MarkMailAsSpam($mailId: ID!) {
    markMailAsSpam(mailId: $mailId) {
      _
    }
  }
`;

export const UPDATE_ENTITY_AUTHORIZATION_POLICY_FOR_ACTOR = gql`
  mutation UpdateEntityAuthorizationPolicyForActor(
    $appId: ID!
    $input: UpdateAuthorizationPolicyInput!
  ) {
    updateEntityAuthorizationPolicyForActor(appId: $appId, input: $input) {
      _
    }
  }
`;
