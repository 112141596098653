import React, { FC, useMemo, useRef, useState } from 'react';
import { Calendar, Messages, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/it';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { KeycloakService } from '../../services/KeycloakService';
import { EventItem } from './EventItem';
import { useGetTasksInCalendarWithDate } from '../../components/hooks/useGetTasksInCalendarWithDate';
import { TaskSummary } from '@pec-manager/graphql';
import { calcFirstAndLastDayOfMonthInMilliseconds } from '../../utils/dateUtils';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { EventItemWithoutDueDate } from './EventItemWithoutDueDate';
import { useGetTasksInCalendarWithoutDate } from '../../components/hooks/useGetTasksInCalendarWithoutDate';
import styled from 'styled-components';

moment.locale(KeycloakService.getLocale());

const lang: { [key: string]: Messages | undefined } = {
  en: undefined,
  'en-GB': undefined,
  it: {
    week: 'Settimana',
    work_week: 'Settimana lavorativa',
    day: 'Giorno',
    month: 'Mese',
    previous: 'Precedente',
    next: 'Prossimo',
    today: 'Oggi',
    agenda: 'Agenda',

    showMore: (total: any) => `+${total} ${total === 1 ? 'altro' : 'altri'}`,
  },
};

export const TaskCalendar: FC = () => {
  const [dateRange, setDateRange] = useState({
    from: calcFirstAndLastDayOfMonthInMilliseconds(new Date())[0],
    to: calcFirstAndLastDayOfMonthInMilliseconds(new Date())[1],
  });

  const { tasks, refetch: refetchTaskWithDateRange } =
    useGetTasksInCalendarWithDate(dateRange.from, dateRange.to);

  const { tasks: eventsWithoutDate, refetch: refetchTaskWithoutDateRange } =
    useGetTasksInCalendarWithoutDate();

  const localizer = momentLocalizer(moment);
  const ref = useRef(null);

  const events = useMemo(() => {
    return tasks.length > 0
      ? tasks.map((task: TaskSummary) => ({
          id: task.base.id,
          title: task.base.name,
          taskBase: task.base,
          dueDate: task.base.expiresAt,
          end: task.base.expiresAt,
          start: task.base.expiresAt,
          description: task.base.description,
          priority: task.base.priority,
        }))
      : [];
  }, [tasks]);

  const eventsWithoutItemMap = useMemo(() => {
    return eventsWithoutDate.length > 0
      ? eventsWithoutDate.map((task: TaskSummary) => ({
          id: task.base.id,
          title: task.base.name,
          taskBase: task.base,
          dueDate: task.base.expiresAt,
          end: task.base.expiresAt,
          start: task.base.expiresAt,
          description: task.base.description,
          priority: task.base.priority,
        }))
      : [];
  }, [eventsWithoutDate]);

  return (
    <CalendarContainer style={{ height: '100%' }}>
      <div
        style={{
          padding: '16px 16px 20px 16px',
          height: '100%',
          width: '100%',
        }}
        ref={ref}
      >
        <Calendar
          components={{
            event: (props, context) => (
              <EventItem
                event={props.event}
                refetch={() => {
                  refetchTaskWithoutDateRange();
                  refetchTaskWithDateRange();
                }}
              />
            ),
          }}
          localizer={localizer}
          popup
          backgroundEvents={events}
          events={events}
          onNavigate={(date) => {
            setDateRange({
              from: calcFirstAndLastDayOfMonthInMilliseconds(date)[0],
              to: calcFirstAndLastDayOfMonthInMilliseconds(date)[1],
            });
            return date;
          }}
          messages={lang[KeycloakService.getLocale()]}
          views={{
            month: true,
          }}
        />
      </div>
      <div
        style={{
          minWidth: '150px',
          display: 'flex',
          flexDirection: 'column',
          margin: '8px',
          overflowY: 'auto',
        }}
      >
        <Text text="noDueDate" fontSize="16px" lineHeight="21px" />
        <Text text="tipDueDate" fontSize="12px" lineHeight="21px" />
        {eventsWithoutItemMap?.length > 0 &&
          eventsWithoutItemMap.map((event: any) => (
            <EventItemWithoutDueDate
              event={event}
              key={event.id!}
              refetch={() => {
                refetchTaskWithoutDateRange();
                refetchTaskWithDateRange();
              }}
            />
          ))}
      </div>
    </CalendarContainer>
  );
};

const CalendarContainer = styled(FlexContainer)`
  height: 100%;
  .rbc-event {
    display: flex;
    align-items: center;
    margin: 2px 4px;
    width: calc(100% - 8px);
    background-color: #ffffff;
    padding: 0;
    border-radius: 9px;
  }
  .rbc-row-content {
    z-index: unset;
  }

  .rbc-event-content {
    width: 100%;
  }
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }
`;
