import React, { FunctionComponent, useState } from 'react';
import {
  BlobRef,
  IdentifiableValue,
} from '@pec-manager/graphql/lib/graphql.types';
import styled from 'styled-components';
import { Title } from './Title';
import { FolderSection } from './FolderSection';
import { AttachmentsSection } from './AttachmentsSection';
import { useQuery } from '@apollo/client';
import { LIST_DRIVE_FOLDER } from '../../../../graphql/document/queries';
import { useParams } from 'react-router-dom';
import { BlobRefOrFolder } from '@pec-manager/graphql';

interface InsertFromSintropiDriveProps {
  blobRefChoices: BlobRef[];
  setBlobRefChoices: (f: BlobRef[]) => void;
  allowedFiles: string[];
}

export const InsertFromSintropiDrive: FunctionComponent<
  InsertFromSintropiDriveProps
> = ({ setBlobRefChoices, blobRefChoices, allowedFiles }) => {
  const { appId } = useParams();
  const [currentFolderSelected, setCurrentFolderSelected] =
    useState<IdentifiableValue>({ id: '', value: 'Drive' });

  const [pathFolder, setPathFolder] = useState<IdentifiableValue[]>([]);

  const backAction = () => {
    setCurrentFolderSelected(pathFolder[pathFolder.length - 1]);
    const newPath = [...pathFolder];
    setPathFolder(() => newPath.slice(0, -1));
  };

  const { data: folderBlobList, loading } = useQuery(LIST_DRIVE_FOLDER, {
    variables: {
      appId,
      folderId: currentFolderSelected?.id,
      pagination: null,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <Title
        folder={currentFolderSelected}
        backAction={backAction}
        folderPath={pathFolder}
        border
      />
      <FolderSection
        setCurrentFolder={(f) => {
          setPathFolder((prevState) => [...prevState, currentFolderSelected]);
          setCurrentFolderSelected(f);
        }}
        folderList={
          folderBlobList?.listDriveFolder.pageElements.filter(
            ({ folder }: BlobRefOrFolder) => folder != null
          ) || []
        }
        loading={loading}
      />
      <AttachmentsSection
        allowedFiles={allowedFiles}
        blobRefChoices={blobRefChoices}
        setBlobRefChoices={setBlobRefChoices}
        loading={loading}
        blobRefList={
          folderBlobList?.listDriveFolder.pageElements.filter(
            ({ blobRef }: BlobRefOrFolder) => blobRef != null
          ) || []
        }
      />
    </Container>
  );
};

const Container = styled.div`
  max-height: 461px;
  overflow-y: scroll;
`;
