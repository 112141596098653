import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { IdentifiableValue } from '@pec-manager/graphql/lib/graphql.types';
import { FlexContainer } from '../layout/FlexContainer';
import { SvgIcon } from './SvgIcon';
import { Arrow } from '../../svg';
import { Text } from './Text';
import { Color } from '../../theme';
import { getMappedColor } from '../../utils/colorUtils';

interface BreadcrumbProps {
  position: IdentifiableValue[];
}

export const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  position,
}) => {
  const positionMobile = useMemo(() => {
    if (position.length > 2)
      return [
        { id: position.length, value: `+${position.length - 1}` },
        position.slice(-1)[0],
      ];
    return position;
  }, [position]);

  return (
    <>
      <Container alignItems="center">
        {position.map((v, index) => (
          <FlexContainer key={v.id} alignItems="center">
            <RoundedSquare backgroundColor={getMappedColor(v.value)} />
            <Text
              text={v.value}
              fontSize="8px"
              lineHeight="8px"
              skipTranslation
            />
            {index !== position.length - 1 && (
              <StyledSvgIcon
                height="8px"
                width="8px"
                svg={<Arrow />}
                rotateDeg={270}
              />
            )}
          </FlexContainer>
        ))}
      </Container>
      <ContainerMobile alignItems="center">
        {positionMobile.map((v, index) => (
          <FlexContainer key={v.id} alignItems="center">
            <RoundedSquare backgroundColor={getMappedColor(v.value)} />
            <Text
              text={v.value}
              fontSize="8px"
              lineHeight="8px"
              skipTranslation
            />
            {index < 1 && (
              <StyledSvgIcon
                height="8px"
                width="8px"
                svg={<Arrow />}
                rotateDeg={270}
              />
            )}
          </FlexContainer>
        ))}
      </ContainerMobile>
    </>
  );
};

const Container = styled(FlexContainer)`
  height: 23px;

  @media (max-width: 990px) {
    display: none !important;
  }
`;

const ContainerMobile = styled(FlexContainer)`
  height: 23px;

  @media (min-width: 990px) {
    display: none;
  }
`;

const RoundedSquare = styled.div<{ backgroundColor: Color | string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 8px;
  width: 8px;
  border-radius: 3px;
  margin-right: 5px;
  margin-top: -2px;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: -2px 5px 0 5px;
`;
