import { createAction } from '@reduxjs/toolkit';
import {
  Notification,
  NotificationUpdated,
  TaskProgressFlow,
} from '@pec-manager/graphql';
import { NotificationBanner } from './reducers';

enum Actions {
  TOGGLE_NOTIFICATION_MODAL_ACTION = 'TOGGLE_NOTIFICATION_MODAL_ACTION',
  CLOSE_NOTIFICATION_MODAL_ACTION = 'CLOSE_NOTIFICATION_MODAL_ACTION',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  INIT_NOTIFICATIONS = 'INIT_NOTIFICATIONS',
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATIONS',
  READ_NOTIFICATION = 'READ_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
  PUSH_NOTIFICATION_BANNER = 'PUSH_NOTIFICATION_BANNER',
  REMOVE_NOTIFICATION_BANNER = 'REMOVE_NOTIFICATION_BANNER',
  ADD_TASK_PROGRESS_FLOW = 'ADD_TASK_PROGRESS_FLOW',
  REMOVE_TASK_PROGRESS_FLOW = 'REMOVE_TASK_PROGRESS_FLOW',
  ADD_INITIAL_TASKS_PROGRESS_FLOW = 'ADD_INITIAL_TASKS_PROGRESS_FLOW',
}

export const toggleNotificationModalAction = createAction(
  Actions.TOGGLE_NOTIFICATION_MODAL_ACTION
);
export const closeNotificationModalAction = createAction(
  Actions.CLOSE_NOTIFICATION_MODAL_ACTION
);

export const pushNotificationAction = createAction<Notification>(
  Actions.PUSH_NOTIFICATION
);

export const initNotificationsAction = createAction<Notification[]>(
  Actions.INIT_NOTIFICATIONS
);

export const updateNotificationAction = createAction<NotificationUpdated>(
  Actions.UPDATE_NOTIFICATION
);

export const readNotificationAction = createAction<string>(
  Actions.READ_NOTIFICATION
);

export const deleteNotificationAction = createAction<string>(
  Actions.DELETE_NOTIFICATION
);

export const pushNotificationBannerAction = createAction<NotificationBanner>(
  Actions.PUSH_NOTIFICATION_BANNER
);

export const removeNotificationBannerAction = createAction<NotificationBanner>(
  Actions.REMOVE_NOTIFICATION_BANNER
);

export const addTaskProgressFlowAction = createAction<TaskProgressFlow>(
  Actions.ADD_TASK_PROGRESS_FLOW
);

export const addInitialTasksProgressFlowAction = createAction<
  TaskProgressFlow[]
>(Actions.ADD_INITIAL_TASKS_PROGRESS_FLOW);

export const removeTaskProgressFlowAction = createAction<string>(
  Actions.REMOVE_TASK_PROGRESS_FLOW
);
