import { useLazyQuery, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { GET_EXECUTION_BY_ID } from '../../graphql/execution/queries';
import { useDispatch } from 'react-redux';
import { updateExecutionDetails } from '../../redux/Sintropi/execution/actions';
import { EXECUTION_UPDATED } from '../../graphql/execution/subscriptions';
import { KeycloakService } from '../../services/KeycloakService';

export const useExecutionDetails = (id: string) => {
  const dispatch = useDispatch();

  const [getExecutionById, { data: executionById, startPolling, stopPolling }] =
    useLazyQuery(GET_EXECUTION_BY_ID, {
      variables: {
        executionId: id,
      },
      fetchPolicy: 'network-only',
    });

  const { data: updateExecution } = useSubscription(EXECUTION_UPDATED, {
    variables: {
      correlationId: KeycloakService.getToken(),
      executionId: id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(30000);
    return () => {
      stopPolling();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (executionById) {
      dispatch(updateExecutionDetails(executionById.getExecutionById));
    }
    // eslint-disable-next-line
  }, [executionById]);

  useEffect(() => {
    getExecutionById({
      variables: {
        executionId: id,
      },
    }).then((result) => {
      dispatch(updateExecutionDetails(result.data.getExecutionById));
    });
  }, [dispatch, getExecutionById, id, updateExecution]);
};
