import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface HorizontalLoaderProps {
  size: 'xs' | 's' | 'm' | 'l';
  top: string;
  color: any;
}

export const HorizontalLoader: FunctionComponent<HorizontalLoaderProps> = ({
  size,
  top,
  color,
}) => (
  <Container size={size} top={top} color={color}>
    <div />
    <div />
    <div />
    <div />
  </Container>
);

const Container = styled.div<HorizontalLoaderProps>`
  transform: scale(0.5);
  display: inline-block;
  position: relative;
  width: ${({ size }) => {
    switch (size) {
      case 'xs':
        return '10px';
      case 's':
        return '25px';
      case 'm':
        return '45px';
      case 'l':
      default:
        return '65px';
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'xs':
        return '20px';
      case 's':
        return '40px';
      case 'm':
        return '60px';
      case 'l':
      default:
        return '80px';
    }
  }};
  & > div {
    position: absolute;
    top: ${({ top }) => top};
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ color }) => color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & > div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  & > div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & > div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & > div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
