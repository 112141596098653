import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { BlobRef } from '@pec-manager/graphql';
import { ContainerModal } from '../ContainerModal';
import { SvgIcon } from '../../common/SvgIcon';
import { KO } from '../../../svg';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { Choices } from './Choices';
import { InsertFromSintropiDrive } from './InsertFromSintropiDrive';
import { ButtonSection } from './ButtonSection';
import { InsertFromLocale } from './InsertFromLocale';
import { RecentAttachmentsSection } from './InsertFromSintropiDrive/RecentAttachmentsSection';

export enum ChoicesType {
  recent = 'recent',
  uploadFile = 'uploadFile',
  fromSintropiDrive = 'fromSintropiDrive',
}

interface UploadFileModalProps {
  onDropCallback: (acceptFiles: any, rejectedFiles: any) => void;
  insertBlobRefsFromSintropi: (b: BlobRef[]) => void;
  closeOnClick: () => void;
  allowedFiles: string[];
}

export const UploadFileModal: FunctionComponent<UploadFileModalProps> = ({
  onDropCallback,
  insertBlobRefsFromSintropi,
  closeOnClick,
  allowedFiles,
}) => {
  const [choiceSelected, setChoiceSelected] = useState<ChoicesType>(
    ChoicesType.uploadFile
  );

  const [blobRefChoices, setBlobRefChoices] = useState<BlobRef[]>([]);

  const insertOnClick = () => {
    insertBlobRefsFromSintropi(blobRefChoices);
    closeOnClick();
  };

  return (
    <ContainerModal position="fixed">
      <Container onClick={(e) => e.stopPropagation()}>
        <ContainerHeader>
          <Text
            text="insertAnAttachment"
            fontSize="18px"
            lineHeight="27px"
            fontWeight="500"
          />
          <ClickableIcon onClick={closeOnClick}>
            <SvgIcon svg={<KO />} width="18px" color={Theme.colors.c7A869A} />
          </ClickableIcon>
        </ContainerHeader>
        <Choices
          choiceSelected={choiceSelected}
          setChoiceSelected={setChoiceSelected}
        />
        <div
          style={{
            minHeight: `${
              choiceSelected === ChoicesType.uploadFile ? '541px' : '461px'
            }`,
          }}
        >
          {choiceSelected === ChoicesType.uploadFile && (
            <InsertFromLocale
              onDropCallback={onDropCallback}
              allowedFiles={allowedFiles}
            />
          )}
          {choiceSelected === ChoicesType.fromSintropiDrive && (
            <InsertFromSintropiDrive
              allowedFiles={allowedFiles}
              blobRefChoices={blobRefChoices}
              setBlobRefChoices={setBlobRefChoices}
            />
          )}
          {choiceSelected === ChoicesType.recent && (
            <ContainerRecent>
              <RecentAttachmentsSection
                blobRefChoices={blobRefChoices}
                setBlobRefChoices={setBlobRefChoices}
              />
            </ContainerRecent>
          )}
        </div>
        {choiceSelected !== ChoicesType.uploadFile && (
          <ButtonSection
            closeOnClick={closeOnClick}
            insertOnClick={insertOnClick}
            disabledButton={!(blobRefChoices.length > 0)}
          />
        )}
      </Container>
    </ContainerModal>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  overflow: hidden;
  width: 940px;
  min-height: 520px;
`;

const ContainerHeader = styled.div`
  height: 70px;
  border-radius: 12px 12px 0 0;
  opacity: 0.98;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const ContainerRecent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ClickableIcon = styled.div`
  margin-right: 12px;
  height: 18px;
  cursor: pointer;
`;
