import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationStatus } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { SvgIcon } from '../../components/common/SvgIcon';
import { sidebarElements } from './sidebarElements';
import { Theme } from '../../theme';
import { notificationStateSelector } from '../../redux/notification/sidebar/selectors';
import { taskSidebarStateSelector } from '../../redux/Sintropi/sidebar/selectors';
import { setOpenMenuMobile } from '../../redux/Sintropi/sidebar/actions';

function getSvgIconColor(sidebarElementIndex: number, currentIndex: number) {
  return sidebarElementIndex === currentIndex
    ? Theme.colors.cFFFFFF
    : Theme.colors.cD3D4D6;
}

export const SidebarMobile = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuMobileIsOpen } = useSelector(taskSidebarStateSelector);

  const [currentIndex, setCurrentIndex] = useState(0);
  const { notifications } = useSelector(notificationStateSelector);

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      dispatch(setOpenMenuMobile(false));
    }
  }, [dispatch, location]);

  const conditionAvailableSearch = (pathlessId?: string) => {
    return pathlessId === 'hamburgerMenu' && location.pathname === '/dashboard';
  };

  return (
    <SidebarContainer justifyContent="space-around" alignItems="center">
      {sidebarElements.map(
        ({ svg, path, onClickHandler, pathlessId }, index) => {
          if (path) {
            return (
              <Link
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                to={path}
                onClick={() => setCurrentIndex(index)}
              >
                <SvgIcon
                  svg={svg}
                  width="24px"
                  height="24px"
                  color={
                    conditionAvailableSearch(pathlessId)
                      ? Theme.colors.c505050
                      : getSvgIconColor(index, currentIndex)
                  }
                />
              </Link>
            );
          }
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentIndex(index);
                onClickHandler &&
                  onClickHandler(
                    dispatch,
                    conditionAvailableSearch(pathlessId)
                      ? true
                      : menuMobileIsOpen
                  );
              }}
              style={{ position: 'relative' }}
            >
              <SvgIcon
                svg={svg}
                width="24px"
                height="24px"
                pointer={!conditionAvailableSearch(pathlessId)}
                color={
                  conditionAvailableSearch(pathlessId)
                    ? Theme.colors.c505050
                    : getSvgIconColor(index, currentIndex)
                }
              />
              {pathlessId === 'bell' &&
                notifications.some(
                  (n) => n.notificationStatus === NotificationStatus.UNREAD
                ) && <CircleRed />}
            </div>
          );
        }
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.c2C313C};
  box-shadow: 9px 0 12px rgba(25, 33, 48, 0.3);
  z-index: 4;
  min-width: 50px;
  position: relative;
  height: 50px;
`;

const CircleRed = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 0.1px solid ${({ theme }) => theme.colors.cFFFFFF};
  background-color: ${({ theme }) => theme.colors.cFE4242};
  position: absolute;
  top: 2px;
  right: 2px;
`;
