import React, { FunctionComponent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { throttle } from 'lodash';
import { Input } from './Input';
import { DynamicSelect } from '../common/DynamicSelect';

interface SearchInputProps {
  valueInputSearch: string;
  setValueInputSearch: (element: any) => void;
  query: DocumentNode;
  queryResultKey: string;
  selectItemMap: (element: any) => { id: string; displayValue: string };
  svgRender: (element: any) => JSX.Element;
  onSelectItemClick: (element: any) => void;
  getVariablesFilterTerm: (filter: string) => any;
}

export const SearchInput: FunctionComponent<SearchInputProps> = ({
  valueInputSearch,
  setValueInputSearch,
  query,
  queryResultKey,
  selectItemMap,
  svgRender,
  onSelectItemClick,
  getVariablesFilterTerm,
}) => {
  const { t } = useTranslation();
  const [searchFilter, setSearchFilter] = useState('');
  const [showDynamicSelectResult, setShowDynamicSelectResult] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchFilter = useCallback(
    throttle((f) => setSearchFilter(f), 1000),
    [setSearchFilter]
  );

  return (
    <Container>
      <Input
        value={valueInputSearch}
        onChange={(e) => {
          setValueInputSearch(e.target.value);
          debounceSearchFilter(e.target.value);
          setShowDynamicSelectResult(true);
        }}
        placeholder={t('placeholderChooseTeam') as string}
      />
      <DynamicSelect
        showResults={showDynamicSelectResult}
        query={query}
        getVariablesFilterTerm={getVariablesFilterTerm}
        queryResultKey={queryResultKey}
        filterTerm={searchFilter}
        selectItemMap={selectItemMap}
        onSelectItemClick={(element) => {
          onSelectItemClick(element);
          setShowDynamicSelectResult(false);
        }}
        svgRender={svgRender}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;
