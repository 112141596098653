import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormFieldDefinition, FormViewMode } from '@pec-manager/graphql';
import { DocumentNode } from 'graphql';
import { throttle } from 'lodash';
import { FormFieldContainer } from './FormFieldContainer';
import { MultipleValuesInput } from '../../input/MultipleValuesInput';
import {
  extractDefaultValuesAsStrings,
  extractDisplayNamesFromDefaultValues,
} from '../../../utils/stringUtils';
import { boundaryError } from './utils/errorMessage';
import { DynamicSelect } from '../../common/DynamicSelect';

interface GenericTextFieldProps {
  dataMapper: (el: any) => string;
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  type: 'string' | 'number';
  errorMessage?: string;
  query: DocumentNode;
  queryResultKey: string;
  selectItemMap: (element: any) => { id: string; displayValue: string };
  svgRender?: (element: any) => JSX.Element;
  getVariablesFilterTerm: (filter: string) => any;
  svgInput?: JSX.Element;
  disablePressEnter?: boolean;
  isAdvancedSelect?: boolean;
  selectOpened: string;
  setSelectOpened?: (id: string) => void;
  viewMode?: FormViewMode;
  checkRequired?: boolean;
  temporaryId?: string;
  columnForm?: number;
}

export const DynamicGenericTextField: FunctionComponent<
  GenericTextFieldProps
> = ({
  dataMapper,
  formField,
  fillField,
  type,
  errorMessage,
  query,
  queryResultKey,
  selectItemMap,
  svgRender,
  svgInput,
  getVariablesFilterTerm,
  disablePressEnter,
  selectOpened,
  setSelectOpened,
  viewMode,
  checkRequired = false,
  temporaryId,
  columnForm = 1,
}) => {
  const [data, setData] = useState<string[]>([]);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  const [dataToDisplayValueMap, setDataToDisplayValueMap] = useState(
    extractDisplayNamesFromDefaultValues(formField.defaultValues)
  );

  const [inputValue, setInputValue] = useState('');
  const [showDynamicSelectResult, setShowDynamicSelectResult] = useState(false);

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (formField.defaultValues.length > 0) {
      setData(extractDefaultValuesAsStrings(formField.defaultValues));
      setDataToDisplayValueMap(
        extractDisplayNamesFromDefaultValues(formField.defaultValues)
      );
    }
  }, [formField]);

  const setAllData = (newData: string[]) => {
    if (newData[0]?.length > 0) {
      setData(newData);
    } else {
      setData([]);
    }
    setShowDynamicSelectResult(false);
    fillField(formField.id, newData, formField.isDynamic);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setInputValueThrottled = useCallback(
    throttle((newValue) => {
      setInputValue(newValue);
    }, 1000),
    []
  );

  useEffect(() => {
    if (selectOpened === formField.id + temporaryId) {
      setShowDynamicSelectResult(true);
    } else {
      setShowDynamicSelectResult(false);
    }
  }, [formField.id, selectOpened, temporaryId]);

  const calcError = useMemo(
    () =>
      firstOnClick && formField.isEditable
        ? boundaryError(
            data.length,
            formField.cardinalityBounds?.left,
            formField.cardinalityBounds?.right
          ) || errorMessageDate
        : undefined,
    [
      data,
      errorMessageDate,
      firstOnClick,
      formField.cardinalityBounds?.left,
      formField.cardinalityBounds?.right,
      formField.isEditable,
    ]
  );

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={calcError}
      viewMode={viewMode}
      columnForm={columnForm}
    >
      <MultipleValuesInput
        disablePressEnter={disablePressEnter}
        data={data}
        displayValueMap={dataToDisplayValueMap}
        errorMessage={calcError}
        svgInput={svgInput}
        setData={formField.isEditable ? setAllData : undefined}
        type={type}
        isEditable={formField.isEditable}
        boundaryRight={formField.cardinalityBounds?.right === 1}
        onInputChange={(val) => {
          setErrorMessageDate(undefined);
          setInputValueThrottled(val);
          setShowDynamicSelectResult(true);
        }}
        onFocusInput={setShowDynamicSelectResult}
        selectChoicesIsOpen={showDynamicSelectResult}
        setFirstOnClick={() => {
          setSelectOpened && setSelectOpened(formField.id + temporaryId);
          setFirstOnClick(true);
        }}
        arrowIcon={formField.isEditable}
      />
      <DynamicSelect
        fieldName={formField.name}
        query={query}
        queryResultKey={queryResultKey}
        getVariablesFilterTerm={getVariablesFilterTerm}
        filterTerm={inputValue}
        selectItemMap={selectItemMap}
        onSelectItemClick={(element) => {
          setFirstOnClick(true);
          setShowDynamicSelectResult(false);
          setErrorMessageDate(undefined);
          if (formField.cardinalityBounds?.right === 1) {
            setAllData([dataMapper(element)]);
            setDataToDisplayValueMap((prevState: any) => ({
              ...prevState,
              [dataMapper(element)]: selectItemMap(element).displayValue,
            }));
          } else {
            setAllData(Array.from(new Set(data.concat(dataMapper(element)))));
            setDataToDisplayValueMap((prevState: any) => ({
              ...prevState,
              [dataMapper(element)]: selectItemMap(element).displayValue,
            }));
          }
        }}
        svgRender={svgRender}
        showResults={showDynamicSelectResult}
      />
    </FormFieldContainer>
  );
};
