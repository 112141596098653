import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Color } from '../../theme';

interface ProgressBarProps {
  total: number;
  partial: number;
  fillerColor: Color | string;
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  partial,
  total,
  fillerColor,
}) => {
  const percentage = total > 0 ? (partial * 100) / total : 0;

  return (
    <ContainerStyles>
      <FillerStyles percentage={percentage} fillerColor={fillerColor} />
    </ContainerStyles>
  );
};

const ContainerStyles = styled.div`
  height: 8px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 4px;
`;

const FillerStyles = styled.div<{
  percentage: number;
  fillerColor: Color | string;
}>`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background-color: ${({ fillerColor }) => fillerColor};
  border-radius: inherit;
  text-align: right;
`;
