import React, { FC } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { KpiRow as KpiRowType } from '@pec-manager/graphql';
import { KpiRow } from './KpiRow';

interface GenericTabProps {
  kpiRow: KpiRowType[];
  notMobile?: boolean;
}

export const GenericTab: FC<GenericTabProps> = ({ notMobile, kpiRow }) => {
  return (
    <ContainerRow column>
      {kpiRow.map((kpi) => (
        <KpiRow kpiRow={kpi} key={kpi.kpiId} />
      ))}
    </ContainerRow>
  );
};

const ContainerRow = styled(FlexContainer)`
  width: 100%;
  padding: 0 20px;
`;
