import React, { FunctionComponent, useState } from 'react';
import { User } from '@pec-manager/graphql/lib/graphql.types';
import { useTranslation } from 'react-i18next';
import { MultipleValuesInput } from '../../input/MultipleValuesInput';
import { RoundedTextAvatar } from '../../avatar/RoundedTextAvatar';
import { FIND_USERS } from '../../../graphql/user/queries';
import { gqlName } from '../../../graphql/utils';
import { KeycloakService } from '../../../services/KeycloakService';
import { DynamicSelect } from '../../common/DynamicSelect';

interface GenericTextFieldProps {
  data: string[];
  setData: (newData: string[]) => void;
}

export const DynamicGenericTextField: FunctionComponent<
  GenericTextFieldProps
> = ({ data, setData }) => {
  const [showDynamicSelectResult, setShowDynamicSelectResult] = useState(false);
  const [dataToDisplayValueMap, setDataToDisplayValueMap] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const dataMapper = (choice: any) => choice.id;

  const selectItemMap = (user: User) => ({
    displayValue: `${user.firstName} ${user.lastName}`,
    id: user.id,
  });

  return (
    <div
      style={{ position: 'relative' }}
      onBlur={() => setShowDynamicSelectResult(false)}
    >
      <MultipleValuesInput
        setFirstOnClick={() => {}}
        disablePressEnter={true}
        data={data}
        displayValueMap={dataToDisplayValueMap}
        setData={(newData: string[]) => setData(newData)}
        type="string"
        isEditable={true}
        onInputChange={(val) => {
          setInputValue(val);
          setShowDynamicSelectResult(true);
        }}
        placeholder={t('insertName') as string}
      />
      <DynamicSelect
        query={FIND_USERS}
        queryResultKey={gqlName(FIND_USERS)}
        getVariablesFilterTerm={(searchFilter) => ({
          searchFilter,
        })}
        filterTerm={inputValue}
        selectItemMap={selectItemMap}
        onSelectItemClick={(element) => {
          setShowDynamicSelectResult(false);
          setData(Array.from(new Set(data.concat(dataMapper(element)))));
          setDataToDisplayValueMap((prevState: any) => ({
            ...prevState,
            [dataMapper(element)]: selectItemMap(element).displayValue,
          }));
        }}
        collectionFilter={(user: User) =>
          user.username !== KeycloakService.getUsername()
        }
        svgRender={(user: User) => (
          <RoundedTextAvatar
            text={`${user.firstName} ${user.lastName}`}
            size={24}
            fontSize={9.5}
          />
        )}
        showResults={showDynamicSelectResult}
      />
    </div>
  );
};
