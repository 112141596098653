import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import {
  DocumentContainerHeader,
  DocumentContainerHeaderProps,
} from './DocumentContainerHeader';

export const DocumentContainer: FunctionComponent<
  DocumentContainerHeaderProps
> = ({
  folder,
  path,
  backAction,
  backActionSearch,
  searchError,
  actionsButton,
  children,
  actionSearchBar,
}) => (
  <div style={{ position: 'relative', width: '100%' }}>
    <BackgroundBorderTop />
    <Container column>
      <DocumentContainerHeader
        folder={folder}
        backAction={backAction}
        backActionSearch={backActionSearch}
        searchError={searchError}
        path={path}
        actionsButton={actionsButton}
        actionSearchBar={actionSearchBar}
      />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  </div>
);

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 6px 0 0 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @media (max-width: 990px) {
    border-radius: 0;
  }
`;

const ChildrenContainer = styled(FlexContainer)`
  height: calc(100vh - 110px);
  width: calc(100vw - 250px);

  @media (max-width: 1039px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 990px) {
    width: 100%;
    height: calc((var(--vh, 1vh) * 100));
  }
`;

const BackgroundBorderTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.c212832};
`;
