import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { RoundedTextAvatar } from '../components/avatar/RoundedTextAvatar';
import { KeycloakService } from '../services/KeycloakService';
import { FlexContainer } from '../components/layout/FlexContainer';

export const DashboardContainerHeader: FunctionComponent = () => (
  /*
  const [openMenu, setOpenMenu] = useState(false);
*/

  <Container column>
    <ContainerTop alignItems="center" justifyContent="space-between">
      <div />
      <div
        onClick={() =>
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          process.env.REACT_APP_LINK_PROFILE
            ? window.open(process.env.REACT_APP_LINK_PROFILE)
            : {}
        }
        style={{
          cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
        }}
      >
        <RoundedTextAvatar
          text={KeycloakService.getFullName()}
          fontSize={11}
          size={24}
        />
      </div>
    </ContainerTop>
    {/* <ContainerBottom alignItems="center" justifyContent="flex-end">
        <SvgClickable onClick={() => setOpenMenu((prevState) => !prevState)}>
          <SvgIcon
            svg={<More />}
            color={Theme.colors.c2186C6}
            height="20px"
            width="20px"
          />
          {openMenu && (
            <MenuChoices
              containerMenuProps={{ right: '6px', top: '104px' }}
              choices={[
                {
                  text: 'NonSoCosaCiVa',
                  onClick: () => {},
                },
              ]}
            />
          )}
        </SvgClickable>
      </ContainerBottom> */}
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  height: 106px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

/* const ContainerBottom = styled(FlexContainer)`
  width: 100%;
  height: 64px;
  padding: 0 16px;
`; */

const ContainerTop = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

/* const SvgClickable = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  margin-right: 4px;
  cursor: pointer;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`; */
