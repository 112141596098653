import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components';
import {
  FormFieldDefinition,
  FormFieldType,
  FormViewMode,
} from '@pec-manager/graphql/lib/graphql.types';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../layout/FlexContainer';
import { SvgIcon } from '../../common/SvgIcon';
import { Error } from '../../../svg';
import { DisplayInfo } from '../../DispayInfo';

interface FormFieldContainerProps {
  formField?: FormFieldDefinition;
  errorMessage?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  viewMode?: FormViewMode;
  row?: boolean;
  columnForm?: number;
  complexWithoutBorder?: boolean;
}

export const FormFieldContainer: FunctionComponent<FormFieldContainerProps> = ({
  formField,
  children,
  errorMessage,
  style,
  viewMode,
  row,
  columnForm,
  complexWithoutBorder,
}) => {
  const ref = useRef(null);

  return (
    <ContainerFieldWithTitle
      className="ContainerFieldWithTitle"
      column={!row}
      style={style}
      justifyContent="center"
      type={formField?.type}
      viewMode={viewMode}
      wrapContent
      ref={ref}
      columnForm={columnForm}
      alignSelf={'start'}
    >
      {formField?.type !== FormFieldType.DOCUMENT_OBJECT &&
        formField?.type !== FormFieldType.COMPOSITE &&
        formField?.name &&
        (complexWithoutBorder ? (
          <Text
            text={formField.name}
            fontSize="20px"
            lineHeight="24px"
            fontWeight="500"
            style={{ marginBottom: '8px' }}
            skipTranslation
          />
        ) : (
          <FlexContainer>
            <StyledText
              text="fieldTitle"
              translationArgs={{
                title: formField.name,
                required:
                  formField?.cardinalityBounds?.left &&
                  formField.cardinalityBounds.left > 0
                    ? '*'
                    : '',
              }}
              fontSize="14px"
              lineHeight="18px"
              color={Theme.colors.c505050}
              fontWeight="500"
            />
            {formField.placeholder && (
              <DisplayInfo
                style={{ marginTop: '2px', marginLeft: '8px' }}
                description={formField.placeholder!}
                refContainer={ref}
              />
            )}
          </FlexContainer>
        ))}
      {children}
      {errorMessage ? (
        <ErrorMassage>
          <SvgIcon svg={<Error />} height="16px" width="16px" />
          <ErrorText
            text={errorMessage}
            fontSize="14px"
            lineHeight="18px"
            fontWeight="300"
            color={Theme.colors.cFE4242}
          />
        </ErrorMassage>
      ) : (
        <EmptySpace />
      )}
    </ContainerFieldWithTitle>
  );
};

const ContainerFieldWithTitle = styled(FlexContainer)<{
  type?: FormFieldType;
  viewMode?: FormViewMode;
  columnForm?: number;
}>`
  ${({ viewMode, columnForm }) => {
    switch (viewMode) {
      case FormViewMode.TABULAR:
        return 'flex:1;';
      case FormViewMode.STACKED:
      default:
        return `width: calc((100% / ${columnForm || 1}) ${
          (columnForm || 1) > 1 ? '- 20px' : ''
        });`;
    }
  }};
  position: relative;

  @media (max-width: 990px) {
    width: 100% !important;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 8px;
`;

const ErrorMassage = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const ErrorText = styled(Text)`
  margin-left: 8px;
`;
const EmptySpace = styled.div`
  height: 26px;
`;
