import { createReducer } from '@reduxjs/toolkit';
import { BaseExecution, Execution } from '@pec-manager/graphql';
import {
  appendExecutions,
  clearExecutionList,
  updateExecutions,
  openChatModalId,
  closeChatModalId,
  deleteExecution,
  updateExecutionDetails,
  clearExecutionDetails,
} from './actions';

export interface ExecutionState {
  executionsCount: number;
  executions: BaseExecution[] | null;
  chatInModal?: {
    id: string;
    resourceId?: string;
  };
  executionDetails?: Execution;
}

const defaultState: ExecutionState = {
  executionsCount: 0,
  executions: null,
};

export const executionReducer = createReducer<ExecutionState>(defaultState, {
  [clearExecutionList.type]: (state): ExecutionState => ({
    ...state,
    executions: null,
    executionsCount: 0,
  }),
  [appendExecutions.type]: (state, action): ExecutionState => {
    const mergingExecutionIds = (
      action.payload.executions as BaseExecution[]
    ).map((m) => m.id);

    return {
      ...state,
      executionsCount: action.payload.executionsCount,
      executions: [
        ...(state.executions?.filter(
          (m) => !mergingExecutionIds.some((i) => i === m.id)
        ) || []),
        ...action.payload.executions,
      ],
    };
  },
  [deleteExecution.type]: (state, action): ExecutionState => {
    return {
      ...state,
      executions: state.executions
        ? state.executions.filter((m) => m.id !== action.payload)
        : state.executions,
    };
  },
  [updateExecutions.type]: (
    state,
    action: { payload: Execution }
  ): ExecutionState => {
    const ExecutionIndex = state.executions?.findIndex(
      (m) => m.id === action.payload.base.id
    );

    return {
      ...state,
      executionsCount:
        ExecutionIndex !== -1
          ? state.executionsCount
          : state.executionsCount + 1,
      executions:
        ExecutionIndex !== -1
          ? [
              ...(state.executions?.map((t) =>
                t.id === action.payload.base.id ? action.payload.base : t
              ) || []),
            ]
          : [action.payload.base, ...(state.executions || [])],
      executionDetails:
        state.executionDetails?.base.id === action.payload.base.id
          ? action.payload
          : state.executionDetails,
    };
  },
  [clearExecutionDetails.type]: (state): ExecutionState => ({
    ...state,
    executionDetails: undefined,
  }),
  [updateExecutionDetails.type]: (state, action): ExecutionState => ({
    ...state,
    executionDetails: action.payload,
  }),
  [openChatModalId.type]: (state, action): ExecutionState => ({
    ...state,
    chatInModal: action.payload,
  }),
  [closeChatModalId.type]: (state): ExecutionState => ({
    ...state,
    chatInModal: undefined,
  }),
});
