import { IdentifiableValue } from '@pec-manager/graphql';

export const getPenultimateIdInDocumentPath = (
  arr: IdentifiableValue[]
): string => {
  if (arr.length === 0) {
    return '';
  }
  if (arr.length === 1) {
    return arr[0].id;
  }
  return arr[arr.length - 2].id;
};
