import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SelectChoice } from '@pec-manager/graphql';
import { Text } from './Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../layout/FlexContainer';

interface DynamicSelectProps {
  choices: SelectChoice[];
  filterTerm: string;
  onSelectItemClick: (element: any) => void;
  svgRender?: (element: any) => JSX.Element;
  showResults: boolean;
  hasError?: boolean;
}

export const MultipleSelect: FunctionComponent<DynamicSelectProps> = ({
  choices,
  filterTerm,
  onSelectItemClick,
  svgRender,
  showResults,
  hasError,
}) =>
  showResults ? (
    <Container hasError={hasError}>
      {choices
        .filter((v) => v.displayValue.toLowerCase().includes(filterTerm))
        .map((element) => (
          <ItemContainer
            alignItems="center"
            key={element.id}
            onClick={() => {
              onSelectItemClick(element);
            }}
          >
            {svgRender && svgRender(element)}
            <Text
              text={element.displayValue}
              fontSize="14px"
              lineHeight="14px"
              fontWeight="300"
              color={Theme.colors.c404040}
              skipTranslation
            />
          </ItemContainer>
        ))}
    </Container>
  ) : null;

const Container = styled.div<{ hasError?: boolean }>`
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  width: calc(100% - 10px);
  margin-left: 5px;
  position: absolute;
  z-index: 3;
  background: ${({ theme }) => theme.colors.cFFFFFF};
  max-height: 180px;
  overflow-y: auto;
  top: calc(100% - 20px);
`;

const ItemContainer = styled(FlexContainer)`
  height: 36px;
  padding: 10px 16px;
  &:hover {
    background: ${({ theme }) => theme.colors.cF0F0F0};
  }
  & > div:first-child {
    margin-right: 8px;
  }
`;
