import styled from 'styled-components';
import { Color } from '../../theme';

interface ButtonProps {
  width?: string;
  height?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  backgroundColor?: Color | string;
  hoverBackgroundColor?: Color | string;
  disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: ${({ width }) => width ?? 'fit-content'};
  height: ${({ height }) => height ?? 'fit-content'};
  padding: ${({ padding }) => padding ?? '0'};
  border: ${({ border }) => border ?? '0 none transparent'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '0'};
  box-shadow: ${({ boxShadow }) => boxShadow ?? 'none'};
  background-color: ${({ theme, backgroundColor, disabled }) =>
    disabled ? theme.colors.cDCDCDC : backgroundColor ?? 'transparent'};
  transition: 200ms all ease-in-out;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background-color: ${({ theme, hoverBackgroundColor, disabled }) =>
      disabled ? theme.colors.cDCDCDC : hoverBackgroundColor ?? 'transparent'};
  }
`;
