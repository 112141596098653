import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  DocumentOrFolder,
  IdentifiableValue,
  OrderingType,
  SintropiEntityType,
} from '@pec-manager/graphql';
import { DocumentContainer } from './DocumentContainer';
import { Text } from '../../components/common/Text';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { useLazyQuery } from '@apollo/client';
import { LIST_DOCUMENT_FOLDER } from '../../graphql/document/queries';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DocumentItem } from './DocumentItem';
import { FolderDetails } from './FolderDetails';
import { DocumentDetails } from './Document';
import { ActionButtonDetailsDocumentProps } from './DocumentContainerHeader';
import { SEARCH_SINTROPI_ENTITIES_BY_FULL_TEXT } from '../../graphql/search/queries';
import { useLazyQueryHook } from '../../components/hooks/useLazyQuerHook';
import { handlePathForDocumentDetails } from '../../utils/funcUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Theme } from '../../theme';

export const Document: FunctionComponent = () => {
  const [documentList, setDocumentList] = useState<DocumentOrFolder[]>([]);
  const [totalDocument, setTotalDocument] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const documentId = searchParams.get('documentId');
  const folderId = searchParams.get('folderId');
  const selected = searchParams.get('selected');

  const { appId } = useParams();

  const [currentFolderSelected, setCurrentFolderSelected] =
    useState<IdentifiableValue>({ id: '', value: 'Drive' });

  const [pathFolder, setPathFolder] = useState<IdentifiableValue[]>([]);

  const [actionsButton, setActionsButton] =
    useState<ActionButtonDetailsDocumentProps>({});

  const [openMenuItem, setOpenMenuItem] = useState<string | undefined>(
    undefined
  );

  const backAction = () => {
    setCurrentFolderSelected(pathFolder[pathFolder.length - 1]);
    const newPath = [...pathFolder];
    setPathFolder(() => newPath.slice(0, -1));
    navigate(
      `${location.pathname}${
        pathFolder[pathFolder.length - 1].id.length > 0
          ? `?selected=${pathFolder[pathFolder.length - 1].id}`
          : ''
      }`
    );
  };

  useEffect(() => {
    if (!documentId || !folderId || !selected) {
      setCurrentFolderSelected({ id: '', value: 'Drive' });
      setPathFolder([]);
    }
  }, [documentId, folderId, selected]);

  const [listDocuments, { loading, refetch }] = useLazyQuery(
    LIST_DOCUMENT_FOLDER,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!documentId)
      listDocuments({
        variables: {
          appId,
          folderId: selected,
          pagination: {
            offset: 0,
            limit: 50,
          },
        },
        fetchPolicy: 'network-only',
      })
        .then((r) => {
          setDocumentList(
            (r as any).data.listDocumentFolder?.pageElements || []
          );
          setTotalDocument(
            (r as any).data.listDocumentFolder?.totalElements || 0
          );
          if (r.data.listDocumentFolder?.queriedFolderPath.length > 2) {
            setPathFolder(
              handlePathForDocumentDetails(
                r.data.listDocumentFolder.queriedFolderPath
              )
            );
            setCurrentFolderSelected(
              r.data.listDocumentFolder.queriedFolderPath[
                r.data.listDocumentFolder.queriedFolderPath.length - 1
              ]
            );
          }
        })
        .catch(() => {
          refetch().then((r) => {
            setDocumentList(
              (r as any).data.listDocumentFolder?.pageElements || []
            );
            setTotalDocument(
              (r as any).data.listDocumentFolder?.totalElements || 0
            );
            if (r.data.listDocumentFolder?.queriedFolderPath.length > 2) {
              setPathFolder(
                handlePathForDocumentDetails(
                  r.data.listDocumentFolder.queriedFolderPath
                )
              );
              setCurrentFolderSelected(
                r.data.listDocumentFolder.queriedFolderPath[
                  r.data.listDocumentFolder.queriedFolderPath.length - 1
                ]
              );
            }
          });
        });
    // eslint-disable-next-line
  }, [selected, location]);

  const [searchSintropiEntities, { error: searchError }] = useLazyQueryHook({
    queryGql: SEARCH_SINTROPI_ENTITIES_BY_FULL_TEXT,
  });

  const hasMore = useMemo(
    () => documentList.length !== totalDocument,
    [documentList, totalDocument]
  );

  return (
    <DocumentContainer
      path={pathFolder}
      backAction={backAction}
      backActionSearch={() =>
        listDocuments({
          variables: {
            appId,
            folderId: selected,
            pagination: {
              offset: 0,
              limit: 50,
            },
          },
        }).then((r: any) => {
          setDocumentList(r.data.listDocumentFolder.pageElements);
          setTotalDocument(
            (r as any).data.listDocumentFolder?.totalElements || 0
          );
        })
      }
      searchError={searchError}
      folder={currentFolderSelected}
      actionsButton={actionsButton}
      actionSearchBar={(val) => {
        if (val.length > 0) {
          searchSintropiEntities({
            variables: {
              input: {
                appId,
                searchString: val,
                types: [SintropiEntityType.FOLDER, SintropiEntityType.DOCUMENT],
                pagination: {
                  offset: 0,
                  limit: 50,
                },
                orderingType: OrderingType.DESCENDING,
              },
            },
          }).then((r: any) => {
            setDocumentList(
              r.data.searchSintropiEntitiesByFullText.pageElements
            );
            setTotalDocument(
              (r as any).data.searchSintropiEntitiesByFullText?.totalElements ||
                0
            );
          });
        } else {
          listDocuments({
            variables: {
              appId,
              folderId: selected,
              pagination: {
                offset: 0,
                limit: 50,
              },
            },
          }).then((r) => {
            setDocumentList((r as any).data.listDocumentFolder.pageElements);
            setTotalDocument(
              (r as any).data.listDocumentFolder?.totalElements || 0
            );
          });
        }
      }}
    >
      <Container
        loadingCursor={loading}
        overflowAuto={!!documentId ? true : undefined}
      >
        {!folderId && !documentId && (
          <>
            <ListField alignItems="center">
              <ItemBig>
                <Text
                  text="name"
                  fontSize="14px"
                  lineHeight="14px"
                  style={{ textTransform: 'uppercase' }}
                />
              </ItemBig>
              <ItemBig>
                <Text
                  text="category"
                  fontSize="14px"
                  lineHeight="14px"
                  style={{ textTransform: 'uppercase' }}
                />
              </ItemBig>
              <ItemSmall>
                <Text
                  text="createdBy"
                  fontSize="14px"
                  lineHeight="14px"
                  style={{ textTransform: 'uppercase' }}
                />
              </ItemSmall>
              <ItemSmall>
                <Text
                  text="modifiedBy"
                  fontSize="14px"
                  lineHeight="14px"
                  style={{ textTransform: 'uppercase' }}
                />
              </ItemSmall>
              <ItemMenuChoices />
            </ListField>
            <ContainerScroll id="containerScroll">
              {documentList.length > 0 && (
                <InfiniteScroll
                  style={{
                    paddingBottom: '60px',
                    overflow: 'hidden',
                  }}
                  dataLength={documentList.length}
                  next={() => {
                    listDocuments({
                      variables: {
                        appId,
                        folderId: selected,
                        pagination: {
                          offset: documentList.length,
                          limit: 50,
                        },
                      },
                    }).then((r: any) => {
                      setDocumentList((prevState) => [
                        ...prevState,
                        ...r.data.listDocumentFolder.pageElements,
                      ]);
                      setTotalDocument(
                        (r as any).data.listDocumentFolder?.totalElements || 0
                      );
                    });
                  }}
                  hasMore={hasMore}
                  scrollableTarget="containerScroll"
                  loader={
                    <p className="text-center">
                      <Text
                        text="loading"
                        className="font-bold"
                        fontSize="12px"
                        lineHeight="14px"
                        color={Theme.colors.c505050}
                      />
                    </p>
                  }
                  endMessage={
                    <p className="text-center">
                      <Text
                        text="endResults"
                        className="font-bold"
                        fontSize="12px"
                        lineHeight="14px"
                        color={Theme.colors.cDCDCDC}
                      />
                    </p>
                  }
                >
                  {documentList.map(
                    ({ document, folder }: DocumentOrFolder) => {
                      if (document?.id || folder?.id)
                        return (
                          <DocumentItem
                            setOpenMenu={setOpenMenuItem}
                            openMenu={openMenuItem}
                            key={document?.id || folder?.id}
                            document={document}
                            folder={folder}
                            setPathFolder={setPathFolder}
                            setCurrentFolder={(f) => {
                              navigate(`${location.pathname}?selected=${f.id}`);
                              setCurrentFolderSelected(f);
                            }}
                          />
                        );
                      return null;
                    }
                  )}
                </InfiniteScroll>
              )}
              {documentList.length === 0 && (
                <FlexContainer
                  justifyContent="center"
                  alignItems="center"
                  style={{ width: '100%', marginTop: '200px' }}
                >
                  <Text text="emptyList" fontSize="25px" lineHeight="80px" />
                </FlexContainer>
              )}
            </ContainerScroll>
          </>
        )}
        {folderId && (
          <FolderDetails
            id={folderId}
            setCurrentPath={setPathFolder}
            setCurrentDocument={setCurrentFolderSelected}
            setActionsButton={setActionsButton}
            deleteBackAction={() => navigate(-1)}
          />
        )}
        {documentId && (
          <DocumentDetails
            id={documentId}
            setCurrentPath={setPathFolder}
            setCurrentDocument={setCurrentFolderSelected}
            setActionsButton={setActionsButton}
            deleteBackAction={() => navigate(-1)}
          />
        )}
      </Container>
    </DocumentContainer>
  );
};

const Container = styled.div<{
  loadingCursor?: boolean;
  overflowAuto?: boolean;
}>`
  height: 100%;
  width: 100%;
  cursor: ${({ loadingCursor }) => (loadingCursor ? 'wait' : 'default')};
  ${({ overflowAuto }) => overflowAuto && 'overflow: auto;'};
  background-color: rgb(245, 245, 245);
`;

const ListField = styled(FlexContainer)`
  height: 40px;
  width: calc(100% - 40px);
  padding-left: 20px;
  margin: 16px 0 0 20px;
  background-color: ${({ theme }) => theme.colors.cF0F0F0};

  & > div:not(:first-child, :last-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    padding-left: 16px;
  }
`;

export const ItemBig = styled.div`
  height: 40px;
  width: 33%;
  display: flex;
  align-items: center;
  flex-basis: auto;
`;

export const ItemSmall = styled.div<{ listItem?: boolean }>`
  height: 40px;
  width: 17%;
  display: flex;
  align-items: center;

  @media (max-width: 1500px) {
    width: ${({ listItem }) => (listItem ? 'calc(17% - 9px)' : '17%')};
    min-width: ${({ listItem }) => (listItem ? 'calc(17% - 9px)' : '17%')};
  }
`;

export const ItemMenuChoices = styled.div`
  height: 40px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 !important;
`;

const ContainerScroll = styled.div`
  padding: 0 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
