import { gql } from '@apollo/client/core';

export const CHANGE_TASK_PRIORITY = gql`
  mutation ChangeTaskPriority($taskId: ID!, $newPriority: ResourcePriority!) {
    changeTaskPriority(taskId: $taskId, newPriority: $newPriority) {
      _
    }
  }
`;

export const REASSIGN_TASK = gql`
  mutation ReassignTask($taskId: ID!, $userIds: [ID!]!, $groupIds: [ID!]!) {
    reassignTask(taskId: $taskId, userIds: $userIds, groupIds: $groupIds) {
      _
    }
  }
`;

export const CHANGE_TASK_DUE_DATE = gql`
  mutation ChangeTaskDueDate($taskId: ID!, $newDueDate: Date) {
    changeTaskDueDate(taskId: $taskId, newDueDate: $newDueDate) {
      _
    }
  }
`;
