// @ts-ignore
import { FilledForm } from '@pec-manager/graphql/lib/graphql.types';
import { BaseTask, IdentifiableValue } from '@pec-manager/graphql';
import { KeycloakService } from '../services/KeycloakService';
import { Theme } from '../theme';

export const identity = (x: any) => x;

export const nothing = () => {};

export function isFilledFormEqual(f1: FilledForm, f2: FilledForm): boolean {
  if (f1.definitionId !== f2.definitionId) return false;
  if (f1.filledGroups.length !== f2.filledGroups.length) return false;
  for (const f1FilledGroup of f1.filledGroups) {
    const f2FilledGroup = f2.filledGroups.find(
      (f2FilledGroupElement) => f2FilledGroupElement.id === f1FilledGroup.id
    );
    if (!f2FilledGroup) return false;
    if (f1FilledGroup.filledFields.length !== f2FilledGroup.filledFields.length)
      return false;
    for (const f1FilledField of f1FilledGroup.filledFields) {
      const f2FilledField = f2FilledGroup.filledFields.find(
        (f2FilledFieldElement) => f2FilledFieldElement.id === f1FilledField.id
      );
      if (!f2FilledField) return false;
      if (
        f1FilledField.filledValues?.length !==
        f2FilledField.filledValues?.length
      )
        return false;
      if (f1FilledField.filledValues) {
        return false;
      }
      for (const f1FilledFieldValue of f1FilledField.filledValues!) {
        if (
          !f2FilledField.filledValues?.some(
            (f2FilledFieldValue) => f1FilledFieldValue === f2FilledFieldValue
          )
        )
          if (
            !f2FilledField.complexValues?.some((f2FilledFieldValue) =>
              f2FilledFieldValue.filledFields.some(
                (f2FilledFieldValueField) =>
                  f2FilledFieldValueField.id === f1FilledFieldValue
              )
            )
          )
            return false;
      }
    }
  }
  return true;
}

export function handlePathForDocumentDetails(
  array: IdentifiableValue[]
): { id: string; value: string }[] {
  if (array.length > 2) {
    return [{ id: '', value: 'Drive' }, ...array.slice(2, array.length - 1)];
  }
  return [{ id: '', value: 'Drive' }];
}

export const colorBorderLeft = (base: BaseTask) => {
  if (base.inCharge?.username === KeycloakService.getUsername()) {
    return Theme.colors.cFE4242;
  }
  if (
    base.assignees?.find(
      (assignee) => assignee.user?.username === KeycloakService.getUsername()
    )
  ) {
    return Theme.colors.c0E53B7;
  }
  if (
    base.assignees?.find((assignee) =>
      KeycloakService.hasGroup(assignee.group?.name || '')
    )
  ) {
    return Theme.colors.c0E53B7;
  }
  return Theme.colors.cFFC53D;
};

export const trasformInitialContext = (
  array: { key: string; values: any }[]
) => {
  // @ts-ignore
  if (!array[0]?.key) return {};
  const result = {};

  array.forEach((item) => {
    // @ts-ignore
    result[item.key] = item.values;
  });

  return result;
};
