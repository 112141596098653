import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_TASKS_BY_ASSIGNMENT_TYPE_WITH_DUE_DATE_IN_RANGE,
  GET_TASKS_BY_STATE_WITH_DUE_DATE_IN_RANGE,
  GET_TASKS_IN_INBOX_WITH_DUE_DATE_IN_RANGE,
} from '../../graphql/task/queries';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { gqlName } from '../../graphql/utils';
import { SintropiSidebarDefaultSectionId } from '../../sintropi/sidebar/sintropiSidebarDefaultSectionId';
import { taskStateKeys } from '../../constant';
import { SintropiSidebarMainSectionElementId } from '../../sintropi/sidebar/sintropiSidebarDefaultSectionElements';
import {
  appendTasksWithDueDate,
  clearTaskWithDueDateList,
} from '../../redux/Sintropi/task/actions';
import { nothing } from '../../utils/funcUtils';
import { taskStateSelector } from '../../redux/Sintropi/task/selectors';

export const useGetTasksInCalendarWithDate = (from: Date, to: Date) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { appId } = useParams();
  const sectionId = searchParams.get('sectionId');
  const sectionElementId = searchParams.get('elementId');
  const sectionElementDropDownElementId = searchParams.get('dropDownId');
  const { tasksWithDueDate } = useSelector(taskStateSelector);
  const dispatch = useDispatch();

  const { gqlQuery, gqlQueryVariables } = useMemo(() => {
    let query: any | null = null;
    let queryVariables: any = {};

    if (appId && sectionElementId) {
      switch (sectionId) {
        case SintropiSidebarDefaultSectionId.MAIN:
          switch (true) {
            case taskStateKeys.some((s) => s === sectionElementId):
              query = GET_TASKS_BY_STATE_WITH_DUE_DATE_IN_RANGE;
              queryVariables = {
                ...queryVariables,
                appId,
                taskState: sectionElementId,
                from,
                to,
              };
              break;
            case sectionElementId ===
              SintropiSidebarMainSectionElementId.SINTROPI:
              if (!!sectionElementDropDownElementId) {
                query = GET_TASKS_BY_ASSIGNMENT_TYPE_WITH_DUE_DATE_IN_RANGE;
                queryVariables = {
                  ...queryVariables,
                  appId,
                  assignmentType: sectionElementDropDownElementId,
                  from,
                  to,
                };
              } else {
                query = GET_TASKS_IN_INBOX_WITH_DUE_DATE_IN_RANGE;
                queryVariables = {
                  ...queryVariables,
                  appId,
                  from,
                  to,
                };
              }
              break;
            default:
              break;
          }
          break;
      }
    }
    return { gqlQuery: query, gqlQueryVariables: queryVariables };
  }, [
    appId,
    sectionElementId,
    sectionId,
    from,
    to,
    sectionElementDropDownElementId,
  ]);

  const [loadQuery, { loading, error, data, refetch: refetchQuery }] =
    useLazyQuery(gqlQuery, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (from && to) {
      loadQuery({
        variables: gqlQueryVariables,
      });
    }
  }, [from, gqlQueryVariables, loadQuery, to, location]);

  useEffect(() => {
    if (data) {
      const { totalElements, pageElements } = data[gqlName(gqlQuery!)];
      dispatch(
        appendTasksWithDueDate({
          taskCount: totalElements,
          tasks: pageElements,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    dispatch(clearTaskWithDueDateList());
    return () => {
      dispatch(clearTaskWithDueDateList());
    };
  }, [dispatch]);

  return {
    tasks: tasksWithDueDate ? tasksWithDueDate : [],
    loading,
    error,
    refetch: () => {
      refetchQuery().then(nothing);
    },
  };
};
