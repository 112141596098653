import React, { FunctionComponent, useState } from 'react';
import { AttachmentPreview } from '../../../components/common/AttachmentPreview';
import { BlobRef } from '@pec-manager/graphql';
import { Select } from '../../../components/select';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Theme } from '../../../theme';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';

interface AttachmentSectionProps {
  attachments: BlobRef[];
}

const SELECT_ALL_CHOICE = 'selectAll';

export const AttachmentsSection: FunctionComponent<AttachmentSectionProps> = ({
  attachments,
}) => {
  const [selectedExtension, setSelectedExtension] = useState(
    SELECT_ALL_CHOICE.toLowerCase()
  );

  const extensions = Array.from(
    new Set(attachments.map((a) => a.mimeType))
  ).concat([SELECT_ALL_CHOICE]);

  return (
    <Container lastSection>
      <SelectContainer alignItems="center">
        <Text text="attachments" fontSize="14px" lineHeight="17px" />
        {extensions.length > 2 && (
          <Select
            borderBottomChoice
            colorText={Theme.colors.c5B84EF}
            fontSizeText="12px"
            lineHeightText="12px"
            width="120px"
            height="30px"
            choices={extensions.map((extension) => ({
              displayValue: extension,
              value: extension,
            }))}
            placeholder={SELECT_ALL_CHOICE}
            onChoiceSelected={(choice) =>
              setSelectedExtension(choice.value.toLowerCase())
            }
          />
        )}
      </SelectContainer>
      <AttachmentsContainer wrapContent>
        {attachments
          .filter(
            (blobRef) =>
              selectedExtension === SELECT_ALL_CHOICE.toLowerCase() ||
              blobRef.mimeType.toLowerCase() === selectedExtension
          )
          .map((attachment) => {
            if (attachment)
              return (
                <AttachmentPreview
                  key={attachment.id}
                  shrinkYPx={0}
                  attachment={attachment}
                />
              );
            else return null;
          })}
        {attachments.length === 0 && (
          <TextStyled
            text="noAttachment"
            fontSize="10px"
            lineHeight="16px"
            color={Theme.colors.c505050}
          />
        )}
      </AttachmentsContainer>
    </Container>
  );
};

const Container = styled.div<{ lastSection?: boolean }>`
  margin-top: 24px;
  width: 100%;
  padding-bottom: 24px;
`;

const SelectContainer = styled(FlexContainer)`
  margin-bottom: 16px;
`;

const AttachmentsContainer = styled(FlexContainer)`
  margin-left: -10px;
  & > div {
    margin: 10px;
  }
`;

const TextStyled = styled(Text)`
  margin-left: 10px;
`;
