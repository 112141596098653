import { createSelector } from '@reduxjs/toolkit';
import { ReduxState } from '../../index';
import { NotificationState } from './reducers';

export const notificationStateSelector = createSelector(
  (state: ReduxState): NotificationState => state.notificationReducer,
  (state) => state
);

export const joiningTaskSelector = createSelector(
  (state: ReduxState): NotificationState => state.notificationReducer,
  (state) => state.joiningTask
);
