import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Close, ReduceToIcon } from '../../svg';

interface ChatModalCompressedProps {
  closeChat: () => void;
  closeCompressed: () => void;
  title: string;
}

export const ChatModalCompressed: FunctionComponent<
  ChatModalCompressedProps
> = ({ closeCompressed, closeChat, title }) => (
  <ContainerModal alignItems="center" justifyContent="space-between">
    <Text
      text={title || 'loading'}
      fontSize="18px"
      lineHeight="22px"
      color={Theme.colors.cFFFFFF}
      ellipsis
    />
    <FlexContainer>
      <IconContainer onClick={closeCompressed}>
        <SvgIcon
          svg={<ReduceToIcon />}
          width="22px"
          color={Theme.colors.cFFFFFF}
          rotateDeg={180}
          height="22px"
        />
      </IconContainer>
      <IconContainer onClick={closeChat}>
        <SvgIcon
          svg={<Close />}
          width="22px"
          color={Theme.colors.cFFFFFF}
          rotateDeg={180}
          height="22px"
        />
      </IconContainer>
    </FlexContainer>
  </ContainerModal>
);

const ContainerModal = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 70px;
  width: 400px;
  background: ${({ theme }) => theme.colors.c212832};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px 12px 0 0;
  z-index: 10;
  padding: 0 24px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  margin-left: 16px;
  margin-top: -10px;
  min-height: 22px;
  min-width: 22px;
`;
