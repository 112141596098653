import React from 'react';
import {
  ChatRoomEvent,
  ChatRoomEventType,
} from '@pec-manager/graphql/lib/graphql.types';
import { MessagePosted } from './MessagePosted';
import { MessageSystem } from './MessageSystem';
import { MessageStatuses } from './index';
import { extractFullName } from '../../../utils/stringUtils';

export const chatEventMapper = (
  event: ChatRoomEvent,
  messageStatuses: MessageStatuses,
  showAvatar?: boolean
  // eslint-disable-next-line consistent-return
) => {
  switch (event.eventType) {
    case ChatRoomEventType.MESSAGE_POSTED:
      return (
        <MessagePosted
          key={event.id} // TODO fix all key
          messagePostedEvent={event.messagePostedEvent!}
          isRead={
            messageStatuses[event.messagePostedEvent!.messageNumber]?.isRead
          }
          isReceived={
            messageStatuses[event.messagePostedEvent!.messageNumber]?.isReceived
          }
          showAvatar={showAvatar}
          actor={event.actor}
          timestamp={event.timestamp as any}
        />
      );
    case ChatRoomEventType.PARTICIPANT_INVITED:
      return (
        <MessageSystem
          key={event.id}
          message="participantInvited"
          sender={extractFullName(event.actor!)}
          invited={extractFullName(event.participantInvitedEvent?.user)}
        />
      );
    case ChatRoomEventType.PARTICIPANT_JOINED:
      return (
        <MessageSystem
          key={event.id}
          message="participantJoined"
          invited={extractFullName(event.actor!)}
        />
      );
    case ChatRoomEventType.PARTICIPANT_LEFT:
      return (
        <MessageSystem
          key={event.id}
          message="participantLeft"
          invited={extractFullName(event.actor!)}
        />
      );
    case ChatRoomEventType.PARTICIPANT_MESSAGE_RECEIVED:
    case ChatRoomEventType.PARTICIPANT_MESSAGE_READ:
    case ChatRoomEventType.RESOURCE_LINKED:
    case ChatRoomEventType.CREATED:
    case ChatRoomEventType.CLOSED:
    case ChatRoomEventType.REOPENED:
      break;
  }
};
