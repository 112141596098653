import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { KeycloakService } from '../../services/KeycloakService';
import { Text } from '../../components/common/Text';

export const ChatContainerHeader: FunctionComponent = () => (
  <FlexContainer column>
    <ContainerTop alignItems="center" justifyContent="space-between">
      <Text
        text="Sintropi"
        fontSize="14px"
        lineHeight="14px"
        fontWeight="600"
      />
      <RoundedTextAvatar
        text={KeycloakService.getFullName()}
        fontSize={10}
        size={24}
      />
    </ContainerTop>
  </FlexContainer>
);

const ContainerTop = styled(FlexContainer)`
  width: 100%;
  height: 42px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
`;
