import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Theme } from '../../theme';
import { Tag } from '../../components/tag';
import { Text } from '../../components/common/Text';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Close } from '../../svg';
import { isToday } from '../../utils/stringUtils';

interface TopSectionProps {
  textTag?: string;
  receivedAt: number;
  clickOnClose?: () => void;
}

export const TopSection: FunctionComponent<TopSectionProps> = ({
  textTag,
  receivedAt: arrivedAt,
  clickOnClose,
}) => (
  <Container justifyContent="space-between" wrapContent>
    <Tag text={textTag} />
    {!clickOnClose ? (
      <Text
        text={isToday(arrivedAt as any) ? 'todayAt' : 'completeDate'}
        translationArgs={{
          date: getDateAsString(arrivedAt),
          time: getTimeAsString(arrivedAt),
        }}
        fontSize="12px"
        lineHeight="18px"
        fontWeight="300"
        color={Theme.colors.c505050}
      />
    ) : (
      <ClickableIcon onClick={clickOnClose}>
        <SvgIcon
          svg={<Close />}
          width="10px"
          height="10px"
          color={Theme.colors.cFFFFFF}
        />
      </ClickableIcon>
    )}
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 1040px) {
    margin-bottom: 16px;
  }
`;

const ClickableIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.c838383};
`;
