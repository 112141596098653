import { gql } from '@apollo/client/core';
import { gqlIdentifiableValueShape } from '../common/shapes';

export const GET_START_PROCESSES = gql`
  query GetStartProcesses($appId: ID!) {
    getStartProcesses(appId: $appId) {
      ${gqlIdentifiableValueShape}
    }
  }
`;

export const GET_DOCUMENT_CONTEXT_PROCESS = gql`
  query GetDocumentContextProcesses($appId: ID!) {
      getDocumentContextProcesses(appId: $appId) {
      ${gqlIdentifiableValueShape}
    }
  }
`;
