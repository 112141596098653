import styled from 'styled-components';

interface Props {
  column?: boolean;
  wrapContent?: boolean;
  justifyContent?:
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  alignSelf?: 'stretch' | 'start' | 'end' | 'center';
  height?: string;
  width?: string;
  flexBasis?: string;
}

export const FlexContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'raw')};
  flex-wrap: ${({ wrapContent }) => (wrapContent ? 'wrap' : 'nowrap')};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'stretch'};
  align-self: ${({ alignSelf }) => alignSelf ?? 'auto'};
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
  flex-basis: ${({ flexBasis }) => flexBasis ?? 'auto'};
`;
