import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResourceType, User, Comment } from '@pec-manager/graphql';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FlexContainer } from '../../../../components/layout/FlexContainer';
import { Text } from '../../../../components/common/Text';
import { Theme } from '../../../../theme';
import { Button } from '../../../../components/common/Button';
import { CommentSection } from './CommentSection';
import defaultStyle from './defaultStyle';
import { FIND_USERS } from '../../../../graphql/user/queries';
import { ADD_COMMENT } from '../../../../graphql/comment/mutations';
import { GET_COMMENTS_BY_RESOURCE } from '../../../../graphql/comment/queries';
import { COMMENTS_PAGE_SIZE } from '../../../../constant';
import { nothing } from '../../../../utils/funcUtils';
import { useMutationHook } from '../../../../components/hooks/useMutationHook';
import { Sent } from '../../../../svg';
import { SvgIcon } from '../../../../components/common/SvgIcon';

interface ActivitySectionProps {
  id: string;
  taskSubject: string;
}

export const ActivitySection: FunctionComponent<ActivitySectionProps> = ({
  id,
  taskSubject,
}) => {
  const { data: comments, refetch: refetchComments } = useQuery(
    GET_COMMENTS_BY_RESOURCE,
    {
      variables: {
        resource: {
          id,
          type: ResourceType.DOCUMENT,
        },
        pagination: {
          offset: 0,
          limit: COMMENTS_PAGE_SIZE,
        },
      },
    }
  );

  /*  const { data: activityLog, refetch: refetchHistory } = useQuery(
    GET_HISTORY_LOG_BY_RESOURCE,
    {
      variables: {
        resource: {
          id,
          type: ResourceType.DOCUMENT,
        },
        pagination: {
          offset: 0,
          limit: ACTIVITIES_PAGE_SIZE,
        },
      },
    }
  );*/

  const { t } = useTranslation();
  const [selected, setSelected] = useState<'comments' | 'chronology'>(
    'comments'
  );

  const [addComment] = useMutationHook({
    mutationGql: ADD_COMMENT,
    errorMessage: 'genericErrorMessage',
  });

  const [inputValue, setInputValue] = useState('');

  const [mentions, setMentions] = useState<string[]>([]);

  const [findUser, { data: searchUser }] = useLazyQuery(FIND_USERS);

  const [users, setUsers] = useState<{ id: string; display: string }[]>([]);

  useEffect(() => {
    if (searchUser) {
      setUsers(
        searchUser.findUsers.map((u: User) => ({
          id: u.id,
          display: `${u.firstName} ${u.lastName}`,
        }))
      );
    }
  }, [searchUser]);
  /*
  useEffect(() => {
    if (selected === 'chronology') {
      refetchHistory().then(nothing);
    }
    // eslint-disable-next-line
  }, [selected]);*/

  return (
    <Container column>
      <StyledTitle text="activity" lineHeight="21px" fontSize="14px" />
      <ContainerSwitch alignItems="center">
        <Text
          text="show"
          lineHeight="21px"
          fontSize="14px"
          fontWeight="300"
          color={Theme.colors.c505050}
        />
        <Button
          backgroundColor={
            selected === 'comments'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          height="26px"
          border={`1px solid ${Theme.colors.cDCDCDC}`}
          borderRadius="6px"
          padding="6px 8px"
          hoverBackgroundColor={
            selected === 'comments'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          onClick={() => setSelected('comments')}
        >
          <Text
            text="comments"
            fontSize="10px"
            lineHeight="12px"
            color={
              selected !== 'comments'
                ? Theme.colors.c6A6A6A
                : Theme.colors.cFFFFFF
            }
          />
        </Button>
        {/*        <Button
          backgroundColor={
            selected === 'chronology'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          height="26px"
          border={`1px solid ${Theme.colors.cDCDCDC}`}
          borderRadius="6px"
          padding="6px 8px"
          hoverBackgroundColor={
            selected === 'chronology'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          onClick={() => setSelected('chronology')}
        >
          <Text
            text="chronology"
            fontSize="10px"
            lineHeight="12px"
            color={
              selected !== 'chronology'
                ? Theme.colors.c6A6A6A
                : Theme.colors.cFFFFFF
            }
          />
        </Button>*/}
      </ContainerSwitch>
      <ContainerBottom column>
        {/*   {selected === 'chronology' &&
          activityLog?.getActivityLogByResource.pageElements.map(
            (entry: ActivityLogEntry) => (
              <ChronologySection key={entry.id} entry={entry} />
            )
          )}*/}
        {selected === 'comments' && (
          <FlexContainer column>
            <FlexContainer
              column
              style={{ maxHeight: '380px', overflow: 'auto' }}
            >
              {comments?.getCommentsByResource.pageElements.map(
                (comment: Comment) => (
                  <CommentSection
                    refetchComments={refetchComments}
                    users={users}
                    key={comment.id}
                    mailId={id}
                    comment={comment}
                  />
                )
              )}
            </FlexContainer>
            <FlexContainer>
              <FlexContainer style={{ width: '100%' }} column>
                <InputContainer>
                  <MentionsInput
                    value={inputValue}
                    onFocus={() => findUser()}
                    onChange={(event, newValue, newSet, mentionsItem) => {
                      setMentions(mentionsItem.map((m) => m.id));
                      setInputValue(newValue);
                    }}
                    onKeyUp={(event) => {
                      if (
                        event.key === 'Enter' &&
                        event.altKey &&
                        inputValue.length > 0
                      )
                        addComment({
                          variables: {
                            resource: {
                              id,
                              type: ResourceType.TASK,
                            },
                            resourceName: taskSubject,
                            body: inputValue,
                            mentionIds: Array.from(new Set(mentions)),
                          },
                        }).then(() => {
                          setInputValue('');
                          refetchComments().then(nothing);
                        });
                    }}
                    style={defaultStyle}
                    placeholder={t('addComment') as string}
                  >
                    <Mention
                      trigger="@"
                      data={users}
                      displayTransform={(_, display) => display}
                      style={{
                        backgroundColor: '#5B84EF2B',
                        borderRadius: '4px',
                        width: '110%',
                      }}
                    />
                  </MentionsInput>
                </InputContainer>
                <SuggestionText
                  text="suggestSendComment"
                  fontSize="12px"
                  lineHeight="18px"
                  color={Theme.colors.c505050}
                />
              </FlexContainer>
              <SendMessageButton
                border={`1px solid ${Theme.colors.cDCDCDC}`}
                padding="3px 15px"
                borderRadius="8px"
                height="48px"
                backgroundColor={Theme.colors.c5B84EF}
                hoverBackgroundColor={Theme.colors.c5176d6}
                disabled={!(inputValue.length > 0)}
                onClick={() => {
                  if (inputValue.length > 0)
                    addComment({
                      variables: {
                        resource: {
                          id,
                          type: ResourceType.TASK,
                        },
                        resourceName: taskSubject,
                        body: inputValue,
                        mentionIds: Array.from(new Set(mentions)),
                      },
                    }).then(() => {
                      setInputValue('');
                      refetchComments().then(nothing);
                    });
                }}
              >
                <SendText
                  text="sendMessage"
                  color={Theme.colors.cFFFFFF}
                  fontSize="13px"
                  lineHeight="13px"
                />
                <SendIcon svg={<Sent />} width="20px" height="20px" />
              </SendMessageButton>
            </FlexContainer>
          </FlexContainer>
        )}
      </ContainerBottom>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  padding-top: 24px;
  margin-top: auto;
`;

const StyledTitle = styled(Text)`
  margin-bottom: 6px;
`;

const ContainerSwitch = styled(FlexContainer)`
  & > span:first-child {
    margin-right: 16px;
  }

  button {
    margin-right: 8px;
  }
`;

const ContainerBottom = styled(FlexContainer)`
  margin-top: 34px;
  height: 100%;
`;

const InputContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const SuggestionText = styled(Text)`
  margin-top: 5px;
  margin-left: 40px;

  @media (max-width: 990px) {
    display: none;
  }
`;

const SendMessageButton = styled(Button)`
  margin-left: 16px;
  margin-top: 8px;
  min-width: fit-content;

  @media (max-width: 990px) {
    border-radius: 50%;
    height: 47px;
    width: 47px;
  }
`;

const SendText = styled(Text)`
  @media (max-width: 990px) {
    display: none;
  }
`;

const SendIcon = styled(SvgIcon)`
  @media (min-width: 990px) {
    display: none;
  }
`;
