import { gqlIdentifiableValueShape } from '../common/shapes';
import { gqlUserResolutionShape } from '../user/shapes';
import { gqlBlobRefShape } from '../document/shapes';

export const gqlMailBaseResolutionShape = `
  base {
    id
    sender
    receivedAt
    expiresAt
    subject
    body
    priority
    canChangePriority
    category
    archiveWalkPath {
      ${gqlIdentifiableValueShape}
    }
    assignees {
        group {
          id
          name
          members {
           ${gqlUserResolutionShape}
          }
        }
        user {
          ${gqlUserResolutionShape}
        }
      }
      inCharge {
        ${gqlUserResolutionShape}
      }
  }
`;
export const gqlMailSummaryResolutionShape = `
      ${gqlMailBaseResolutionShape}
      attachmentCount
      commentCount
      correlatedMails {
        base {
          id
          receivedAt
          subject
          body
        }
      }
`;
export const mailSummaryPagingWrapperShape = `
      totalElements
      pageElements {
        ${gqlMailSummaryResolutionShape}
      }
`;

export const gqlFullMailResolutionShape = `
      ${gqlMailBaseResolutionShape}
      actions {
        type
        priority
      }
      attachments {
        ${gqlBlobRefShape}
      }
      outputSections {
        name
        fields {
          name
          values {
            value
            user {
              ${gqlUserResolutionShape}  
            }
          }
          type
        }
      }
`;
export const gqlResourceContextResolutionShape = `
  appId
  type
  resourceName
  inbox {
    contextSection
    mailId
    assignmentType
    mailState
    archiveId
  }
  sintropi {
    taskId
  }
`;
