import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { ColumnLineChart } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { KeycloakService } from '../../services/KeycloakService';

export const ColumnLineWidget: FunctionComponent<ColumnLineChart> = ({
  columnStacked,
  lineColor,
  lineValues = [],
  xAxisCategories,
  yAxisColumnLabel,
  yAxisLineLabel,
  columnValues,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  const colorsColumn = useMemo(
    () => columnValues?.map((columnValue) => columnValue.color) || [],
    [columnValues]
  );

  useEffect(() => {
    setWidth((ref.current as any)?.offsetWidth);

    const getWidth = () => {
      setWidth((ref.current as any)?.offsetWidth);
    };
    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, [ref]);

  const series = useMemo(() => {
    if (lineValues.length <= 0) {
      return [
        ...(columnValues?.map((columnValue, index) => ({
          name: columnValue.name,
          type: 'column',
          data: columnValue.values,
        })) || []),
      ];
    } else {
      return [
        {
          name: yAxisLineLabel,
          type: 'line',
          data: lineValues,
        },
        ...(columnValues?.map((columnValue, index) => ({
          name: columnValue.name,
          type: 'column',
          data: columnValue.values,
        })) || []),
      ];
    }
  }, [columnValues, lineValues, yAxisLineLabel]);

  // @ts-ignore
  const options: ApexCharts.ApexOptions = useMemo(() => {
    if (lineValues.length <= 0) {
      return {
        labels: xAxisCategories,
        xaxis: {
          type: 'category',
        },
        yaxis: [
          {
            labels: {
              formatter: (value) => {
                return Intl.NumberFormat(KeycloakService.getLocale()).format(
                  value
                );
              },
            },
            title: {
              text: yAxisColumnLabel,
            },
          },
        ],
        dataLabels: {
          enabled: true,
          // @ts-ignore
          formatter(val, opts) {
            //const name = opts.w.globals.labels[opts.seriesIndex].split(':')[1];
            // @ts-ignore
            return [Intl.NumberFormat(KeycloakService.getLocale()).format(val)];
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
          },
        },
        chart: {
          stacked: columnStacked,
        },
        colors: lineColor ? [lineColor, ...colorsColumn] : [...colorsColumn],
        legend: {
          position: 'bottom',
          show: true,
          horizontalAlign: 'center',
          inverseOrder: true,
        },
      };
    } else {
      return {
        labels: xAxisCategories,
        xaxis: {
          type: 'category',
        },
        yaxis: [
          {
            labels: {
              formatter: (value) => {
                return Intl.NumberFormat(KeycloakService.getLocale()).format(
                  value
                );
              },
            },
            show: lineValues && lineValues.length > 0,
            opposite: true,
            title: {
              text: yAxisLineLabel,
            },
          },
          {
            title: {
              text: yAxisColumnLabel,
            },
          },
        ],
        dataLabels: {
          enabled: true,
          // @ts-ignore
          formatter(val, opts) {
            //const name = opts.w.globals.labels[opts.seriesIndex].split(':')[1];
            // @ts-ignore
            return [Intl.NumberFormat(KeycloakService.getLocale()).format(val)];
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
          },
        },
        chart: {
          stacked: columnStacked,
        },
        colors: lineColor ? [lineColor, ...colorsColumn] : [...colorsColumn],
        legend: {
          position: 'bottom',
          show: true,
          horizontalAlign: 'center',
          inverseOrder: true,
          fontSize: '16px',
        },
        responsive: [
          {
            breakpoint: 1150,
            options: {
              chart: {
                width: 700,
              },
            },
          },
        ],
      };
    }
  }, [
    colorsColumn,
    columnStacked,
    lineColor,
    lineValues,
    xAxisCategories,
    yAxisColumnLabel,
    yAxisLineLabel,
  ]);

  return (
    <Container
      column
      alignItems="center"
      style={{ marginBottom: '24px' }}
      ref={ref}
    >
      <Chart
        options={options}
        series={series as any}
        type="line"
        height={400}
        width={width}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  .apexcharts-legend {
    max-height: 150px;
    overflow: auto;
    .apexcharts-legend-series {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
