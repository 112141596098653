import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Close, CloseToIcon, NavigateTo } from '../../../svg';
import { Theme } from '../../../theme';
import { ResourceContext, ResourceContextType } from '@pec-manager/graphql';
import { useNavigate } from 'react-router-dom';

export interface TopBarProps {
  title: string;
  closeChat: () => void;
  closeCompressed: () => void;
  context?: ResourceContext;
}

export const TopBar: FunctionComponent<TopBarProps> = ({
  title,
  closeChat,
  closeCompressed,
  context,
}) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    if (context?.type === ResourceContextType.INBOX) {
      navigate(
        `/${context.appId}/inbox?sectionId=MAIN&elementId=${
          context.inbox?.mailState || 'INBOX'
        }${
          context.inbox?.assignmentType
            ? `&dropDownId=${context.inbox?.assignmentType}`
            : ''
        }&mailId=${context.resourceId}`
      );
    }
    if (context?.type === ResourceContextType.SINTROPI) {
      if (context.resourceId.includes('task')) {
        navigate(
          `/${context.appId}/sintropi?sectionId=MAIN&elementId=${
            context.sintropi?.taskState || 'SINTROPI'
          }${
            context.sintropi?.assignmentType
              ? `&dropDownId=${context.sintropi.assignmentType}`
              : ''
          }&taskId=${context.resourceId}`
        );
      } else {
        navigate(
          `/${context.appId}/sintropi/executions?executionId=${context.resourceId}`
        );
      }
    }
  };

  return (
    <ContainerTop alignItems="center" justifyContent="space-between">
      <FlexContainer alignItems="center">
        <Text
          text={title ?? 'loading'}
          fontSize="18px"
          lineHeight="27px"
          ellipsis
        />
        {context && (
          <OnclickContainer onClick={navigateTo}>
            <SvgIcon svg={<NavigateTo />} width="13px" height="20px" />
          </OnclickContainer>
        )}
      </FlexContainer>
      <FlexContainer>
        <IconContainer onClick={closeCompressed}>
          <SvgIcon
            svg={<CloseToIcon />}
            width="14px"
            color={Theme.colors.c000000}
            rotateDeg={0}
            height="34px"
          />
        </IconContainer>
        <IconContainer onClick={closeChat}>
          <SvgIcon
            svg={<Close />}
            width="14px"
            color={Theme.colors.c000000}
            rotateDeg={180}
            height="14x"
          />
        </IconContainer>
      </FlexContainer>
    </ContainerTop>
  );
};

const ContainerTop = styled(FlexContainer)`
  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 24px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  margin-left: 16px;
  display: flex;
  align-items: center;
  min-height: 14px;
  min-width: 14px;
`;
const OnclickContainer = styled.div`
  cursor: pointer;
  margin-left: 12px;
`;
