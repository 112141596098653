import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { RoundedAvatarGroup } from '../../../components/avatar/RoundedAvatarGroup';
import { getDateAsString } from '../../../utils/dateUtils';
import { Clock } from '../../../svg';

interface BottomSectionProps {
  expiresAt?: number;
  assignee?: string;
  groupName: { name: string; type: 'GROUP' | 'USER' }[];
  selected?: boolean;
  hideCorrelateSection?: boolean;
  // historyPushString?: string;
}

export const BottomSection: FunctionComponent<BottomSectionProps> = ({
  expiresAt,
  assignee,
  selected,
  groupName,
  hideCorrelateSection,
}) => {
  const handleRoundedAvatar: () => {
    name: string;
    type: 'GROUP' | 'USER';
  }[] = () => {
    if (assignee) {
      return [{ name: assignee, type: 'USER' }];
    }
    return groupName;
  };

  return (
    <Container
      justifyContent="space-between"
      wrapContent
      alignItems="center"
      selected={selected}
      hideCorrelateSection={hideCorrelateSection}
    >
      <ItemContainer alignItems="center" marginRight="8px">
        {assignee ? (
          <Text
            text="takeInChargeTo"
            fontSize="10px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
        ) : (
          <Text
            text="assignedToBottomList"
            fontSize="10px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
          /* <>
            <Text
              text="notAssigned"
              fontSize="10px"
              lineHeight="14px"
              fontWeight="300"
              color={Theme.colors.c838383}
            />
            <ContainerTakeInCharge
              onClick={() => {
                history(`/inbox/takeincharge${historyPushString}`);
              }}
            >
              <Text
                text="takeCharge"
                fontSize="10px"
                lineHeight="14px"
                fontWeight="600"
                color={Theme.colors.c2186C6}
              />
            </ContainerTakeInCharge>
          </> */
        )}
        <RoundedAvatarGroup team={handleRoundedAvatar()} />
      </ItemContainer>
      <ItemContainer alignItems="center" marginLeft="4px">
        <SvgIcon
          svg={<Clock />}
          height="12px"
          width="11px"
          color={Theme.colors.c9F9F9F}
        />
        {expiresAt && (
          <Text
            text={expiresAt < Date.now() ? 'expiredAt' : 'expiringAt'}
            translationArgs={{ time: getDateAsString(expiresAt!) }}
            fontSize="12px"
            lineHeight="18px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
        )}
      </ItemContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)<{
  selected?: boolean;
  hideCorrelateSection?: boolean;
}>`
  width: 100%;
  height: 48px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cC7E7FF : theme.colors.cFCFCFC};
  padding: 0 16px;
  border-radius: 0 0 8px 8px;
  transition: 300ms filter ease-in-out;
  filter: drop-shadow(
    ${({ hideCorrelateSection }) =>
      !hideCorrelateSection ? '0px -1px 6px rgba(0, 0, 0, 0.08)' : 'unset'}
  );
`;

const ItemContainer = styled(FlexContainer)<{
  marginRight?: string;
  marginLeft?: string;
}>`
  & > span {
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  }
`;

/*
const ContainerTakeInCharge = styled.div`
  margin-left: 4px;
  cursor: pointer;
  display: flex;
`;
*/
