import React from 'react';
import {
  FormFieldDefinition,
  FormFieldType,
  FilledForm,
  User,
  ExecutionContext,
  FilledFormComplexField,
} from '@pec-manager/graphql';
import { GenericTextField } from './GenericTextField';
import { TextAreaField } from './TextAreaField';
import { DynamicGenericTextField } from './DynamicGenericTextField';
import { FIND_MAILS, FIND_USERS } from '../../../graphql/user/queries';
import { GET_ADVANCED_SELECT_CHOICES } from '../../../graphql/form/queries';
import { Numeric } from '../../../svg';
import { SvgIcon } from '../../common/SvgIcon';
import { Theme } from '../../../theme';
import { RoundedTextAvatar } from '../../avatar/RoundedTextAvatar';
import { InputPassword } from '../../input/InputPassword';
import { InputDate } from '../../input/InputDate';
import { InputDateRange } from '../../input/InputDateRange';
import { GenericFormSelect } from '../../select/GenericFormSelect';
import { AttachmentFormField } from './AttachmentFormField';
import { gqlName } from '../../../graphql/utils';
import { ApprovalField } from '../../common/ApprovalField';
import { ComplexVariablesField } from './ComplexVariablesField';
import { CurrencyField } from './CurrencyField';
import { FormViewMode } from '@pec-manager/graphql/lib/graphql.types';
import { t } from 'i18next';

const getPlaceholder = (type: FormFieldType) => {
  // TODO: verify if make sense to use the same placeholder for all types
  return '';

  switch (type) {
    case FormFieldType.GENERIC_DATE:
    case FormFieldType.GENERIC_DATETIME:
    case FormFieldType.DATE_RANGE:
      return t('insertDate') as string;
    case FormFieldType.AUTH_IDENTITY:
      return t('insertUser') as string;
    case FormFieldType.GENERIC_SELECT:
    case FormFieldType.ADVANCED_SELECT:
      return t('insertValue') as string;
    case FormFieldType.EMAIL_ADDRESS:
      return t('insertEmail') as string;
    case FormFieldType.TELEPHONE_NUMBER:
      return t('insertPhoneNumber') as string;
    case FormFieldType.GENERIC_PASSWORD:
      return t('insertPassword') as string;
    case FormFieldType.CURRENCY:
      return t('insertCurrency') as string;
    case FormFieldType.GENERIC_TEXT:
    case FormFieldType.GENERIC_LONG_TEXT:
      return t('insertText') as string;
    case FormFieldType.GENERIC_NUMBER:
      return t('insertNumber') as string;
    default:
      return '';
  }
};

export const mapFormFieldToComponent = (
  executionContext: ExecutionContext,
  formField: FormFieldDefinition,
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean,
    complexVariables?: FilledFormComplexField[]
  ) => void,
  filledForm: FilledForm,
  formId: string,
  formGroupId: string,
  selectOpened: string,
  viewMode?: FormViewMode,
  setSelectOpened?: (id: string) => void,
  setFileLocaleView?: (fileLocaleView?: string) => void,
  setFileIdViewActive?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >,
  fileIdViewActive?: string,
  checkRequired?: boolean,
  isComplex?: boolean,
  temporaryId?: string,
  indexComplex?: number,
  columnForm?: number,
  initialContext?: any
) => {
  const firstError: string | undefined =
    formField.errors && formField.errors[0] && formField.errors[0].message;

  const placeholder = getPlaceholder(formField.type);

  switch (formField.type) {
    case FormFieldType.GENERIC_NUMBER:
      return (
        <GenericTextField
          viewMode={viewMode}
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          type="number"
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.GENERIC_TEXT:
      return (
        <GenericTextField
          viewMode={viewMode}
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          type="string"
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.GENERIC_LONG_TEXT:
      return (
        <TextAreaField
          viewMode={viewMode}
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
        />
      );
    case FormFieldType.GENERIC_DATE:
      return (
        <InputDate
          viewMode={viewMode}
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.GENERIC_DATETIME:
      return (
        <InputDate
          viewMode={viewMode}
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          dateTime={true}
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.DATE_RANGE:
      return (
        <InputDateRange
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.EMAIL_ADDRESS:
      return (
        <DynamicGenericTextField
          viewMode={viewMode}
          key={formField.id + formField.name}
          getVariablesFilterTerm={(searchFilter) => ({
            searchFilter,
          })}
          fillField={fillField}
          formField={formField}
          type="string"
          query={FIND_MAILS}
          queryResultKey={gqlName(FIND_MAILS)}
          selectItemMap={(mail) => ({
            displayValue: mail,
            id: mail,
          })}
          dataMapper={(mail) => mail}
          errorMessage={firstError}
          selectOpened={selectOpened}
          setSelectOpened={setSelectOpened}
          checkRequired={checkRequired}
          temporaryId={temporaryId}
          columnForm={columnForm}
        />
      );
    case FormFieldType.TELEPHONE_NUMBER:
      return (
        <GenericTextField
          viewMode={viewMode}
          key={formField.id + formField.name}
          svgInput={
            <SvgIcon
              svg={<Numeric />}
              width="25px"
              height="20px"
              color={Theme.colors.c505050}
            />
          }
          fillField={fillField}
          formField={formField}
          type="string"
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.GENERIC_SELECT:
      return (
        <GenericFormSelect
          viewMode={viewMode}
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
        />
      );
    case FormFieldType.GENERIC_PASSWORD:
      return (
        <InputPassword
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.DOCUMENT_OBJECT:
      return (
        <AttachmentFormField
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          errorMessage={firstError}
          setFileLocaleView={setFileLocaleView}
          setFileIdViewActive={setFileIdViewActive!}
          fileIdViewActive={fileIdViewActive}
          checkRequired={checkRequired}
          columnForm={columnForm}
        />
      );
    case FormFieldType.AUTH_IDENTITY:
      return (
        <DynamicGenericTextField
          viewMode={viewMode}
          key={formField.id + formField.name}
          getVariablesFilterTerm={(searchFilter) => ({
            searchFilter,
          })}
          fillField={fillField}
          formField={formField}
          type="string"
          query={FIND_USERS}
          queryResultKey={gqlName(FIND_USERS)}
          selectItemMap={(user: User) => ({
            displayValue: `${user.firstName} ${user.lastName}`,
            id: user.id,
          })}
          dataMapper={(choice) => choice.id}
          errorMessage={firstError}
          svgRender={(user: User) => (
            <RoundedTextAvatar
              text={`${user.firstName} ${user.lastName}`}
              size={24}
              fontSize={9.5}
            />
          )}
          setSelectOpened={setSelectOpened}
          selectOpened={selectOpened}
          checkRequired={checkRequired}
          temporaryId={temporaryId}
          columnForm={columnForm}
        />
      );
    case FormFieldType.ADVANCED_SELECT:
      return (
        <DynamicGenericTextField
          viewMode={viewMode}
          key={formField.id + formField.name}
          getVariablesFilterTerm={(searchFilter) => ({
            context: executionContext,
            searchFilter,
            formField: {
              formId: formId,
              formGroupId,
              formGroupFieldId: isComplex
                ? `${formId}.${indexComplex ?? ''}.${formField.id}`
                : formField.id,
            },
            initialContext,
            filledForm,
          })}
          fillField={fillField}
          formField={formField}
          disablePressEnter
          type="string"
          query={GET_ADVANCED_SELECT_CHOICES}
          queryResultKey={gqlName(GET_ADVANCED_SELECT_CHOICES)}
          selectItemMap={(choice) => ({
            displayValue: choice.value,
            id: choice.id,
          })}
          dataMapper={(choice) => choice.id}
          errorMessage={firstError}
          selectOpened={selectOpened}
          setSelectOpened={setSelectOpened}
          checkRequired={checkRequired}
          temporaryId={temporaryId}
          columnForm={columnForm}
        />
      );
    case FormFieldType.FORMULA:
      return (
        <GenericTextField
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          type="string"
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
          placeholder={placeholder}
        />
      );
    case FormFieldType.APPROVAL:
      return (
        <ApprovalField
          key={formField.id + formField.name}
          formField={formField}
          columnForm={columnForm}
        />
      );
    case FormFieldType.COMPOSITE:
      return (
        <ComplexVariablesField
          key={formField.id + formField.name}
          formField={formField}
          formId={formId}
          fillField={fillField}
          filledForm={filledForm}
          formGroupId={formGroupId}
          executionContext={executionContext}
          selectOpened={selectOpened}
          setSelectOpened={setSelectOpened}
          checkRequired={checkRequired}
          initialContext={initialContext}
          columnsForm={columnForm!}
        />
      );
    case FormFieldType.CURRENCY:
      return (
        <CurrencyField
          viewMode={viewMode}
          key={formField.id + formField.name}
          fillField={fillField}
          formField={formField}
          errorMessage={firstError}
          checkRequired={checkRequired}
          columnForm={columnForm}
        />
      );
    case FormFieldType.HIDDEN:
    case FormFieldType.DMS_NODE:
    default:
      return null;
  }
};
