import {
  BaseExecution,
  ExecutionStatus,
  IdentifiableValue,
} from '@pec-manager/graphql';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getDateAsString, getTimeAsString } from '../../../utils/dateUtils';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { StateExecution } from '../StateExecution';
import { Arrow, ProcessIcon } from '../../../svg';
import { SvgIcon } from '../../../components/common/SvgIcon';
import {
  calculateDifferenceBetweenDatesSplitDaysMinSec,
  isToday,
} from '../../../utils/stringUtils';
import { ItemMenuChoices } from '../ExecutionItem';

interface ExecutionItemProps {
  execution: BaseExecution;
  onClickDetails: (val: IdentifiableValue) => void;
}

export const SubExecutionItem: FunctionComponent<ExecutionItemProps> = ({
  execution,
  onClickDetails,
}) => (
  <ItemContainer column>
    <FlexContainer
      alignItems="center"
      style={{
        paddingBottom: '8px',
      }}
    >
      <FirstItem>
        <TitleContainer column justifyContent="space-around">
          <FlexContainer>
            <SvgIcon
              svg={<ProcessIcon />}
              width="16px"
              height="16px"
              color={Theme.colors.c2F80ED}
            />
            <Text
              text={execution.name}
              fontSize="14px"
              lineHeight="16px"
              fontWeight="500"
              ellipsis
              style={{ paddingRight: '8px', marginLeft: '8px' }}
            />
          </FlexContainer>
          <Text
            text={execution.description}
            fontSize="12px"
            lineHeight="14px"
            color={Theme.colors.c505050}
            ellipsis
            style={{ paddingRight: '8px' }}
          />
        </TitleContainer>
      </FirstItem>
      <ItemSmall justifyContent="space-evenly" column>
        {execution.status !== ExecutionStatus.CANCELED && (
          <>
            <Text
              text={
                isToday(execution.createdAt as any) ? 'todayAt' : 'completeDate'
              }
              translationArgs={{
                date: getDateAsString(execution.createdAt as any),
                time: getTimeAsString(execution.createdAt as any),
                fresh: true,
              }}
              fontSize="14px"
              lineHeight="18px"
              ellipsis
              color={Theme.colors.c505050}
            />
            {execution.startedAt && (
              <Text
                text="ddhhmm"
                translationArgs={{
                  days: calculateDifferenceBetweenDatesSplitDaysMinSec(
                    execution.startedAt as any,
                    Date.now()
                  ).days,
                  hours: calculateDifferenceBetweenDatesSplitDaysMinSec(
                    execution.startedAt as any,
                    Date.now()
                  ).hours,
                  minutes: calculateDifferenceBetweenDatesSplitDaysMinSec(
                    execution.startedAt as any,
                    Date.now()
                  ).minutes,
                }}
                fontSize="12px"
                lineHeight="14px"
                ellipsis
                color={Theme.colors.c505050}
              />
            )}
          </>
        )}
        {execution.status === ExecutionStatus.CANCELED && (
          <>
            <Text
              text={execution ? 'todayAt' : 'completeDate'}
              translationArgs={{
                date: getDateAsString(execution.cancelledAt as any),
                time: getTimeAsString(execution.cancelledAt as any),
                fresh: true,
              }}
              fontSize="12px"
              lineHeight="18px"
              fontWeight="300"
              color={Theme.colors.c505050}
            />
            <Text
              text="executionCancelledBy"
              fontSize="12px"
              translationArgs={{
                name: `${execution.cancelledBy?.firstName} ${execution.cancelledBy?.lastName}`,
              }}
              lineHeight="20px"
              color={Theme.colors.c505050}
              style={{ marginRight: '8px' }}
            />
          </>
        )}
      </ItemSmall>
      <ItemSmall alignItems="center">
        <StateExecution
          state={execution.status}
          colorText={Theme.colors.c505050}
          fontSize={14}
          sizeCircle="18px"
        />
      </ItemSmall>
      <ItemMenuChoices onClick={(e) => e.stopPropagation()}>
        <SvgClickable
          onClick={(e) => {
            e.stopPropagation();
            onClickDetails({
              id: execution.id,
              value: execution.name,
            });
          }}
        >
          <SvgIcon svg={<Arrow />} height="20px" width="20px" rotateDeg={270} />
        </SvgClickable>
      </ItemMenuChoices>
    </FlexContainer>
  </ItemContainer>
);

const ItemContainer = styled(FlexContainer)<{
  selected?: boolean;
}>`
  width: calc(100% - 40px);
  padding: 10px 0 0 20px;
  min-height: 60px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const FirstItem = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  flex-basis: auto;
  overflow: auto;
  height: 100%;
`;

const ItemSmall = styled(FlexContainer)`
  height: 100%;
  width: 20%;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
`;

const TitleContainer = styled(FlexContainer)`
  height: 100%;
  overflow: auto;
`;

const SvgClickable = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
