import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { GET_DOCUMENT_FOLDER_BY_ID } from '../../../graphql/document/queries';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import {
  EntityAction,
  EntityType,
  ExecutionContext,
  IdentifiableValue,
  OutputSection,
  PagedFormDefinition,
} from '@pec-manager/graphql';
import { CustomSectionDocument } from '../CustomSectionDocument';
import { handlePathForDocumentDetails } from '../../../utils/funcUtils';
import { ActionButtonDetailsDocumentProps } from '../DocumentContainerHeader';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import {
  DELETE_DOCUMENT_FOLDERS,
  MOVE_DOCUMENT_FOLDERS,
} from '../../../graphql/document/mutations';
import { useParams } from 'react-router-dom';
import {
  SUBSCRIBE,
  UNSUBSCRIBE,
} from '../../../graphql/notification/mutations';
import { IS_SUBSCRIBED } from '../../../graphql/notification/queries';
import { MoveDocumentModal } from '../../../components/modals/MoveDocumentModal';
import { getPenultimateIdInDocumentPath } from '../utils';
import { AuthSectionDocument } from '../../documentMobile/AuthSectionDocument';
import { DynamicForm } from '../../../components/DynamicForm';

interface FolderDetailsProps {
  id: string;
  setCurrentPath: (path: IdentifiableValue[]) => void;
  setCurrentDocument: (doc: IdentifiableValue) => void;
  setActionsButton: Dispatch<SetStateAction<ActionButtonDetailsDocumentProps>>;
  deleteBackAction: () => void;
}

export const FolderDetails: FunctionComponent<FolderDetailsProps> = ({
  id,
  setCurrentDocument,
  setCurrentPath,
  setActionsButton,
  deleteBackAction,
}) => {
  const [isSubscribe, setIsSubscribe] = useState<boolean | undefined>(
    undefined
  );
  const [modalMoveOpen, setModalMoveOpen] = useState(false);
  const [editFolderUpdateAction, setEditFolderUpdateAction] =
    useState<boolean>(false);

  const { appId } = useParams();
  const { data, refetch } = useQuery(GET_DOCUMENT_FOLDER_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const [deleteDocumentMutation] = useMutationHook({
    mutationGql: DELETE_DOCUMENT_FOLDERS,
    errorMessage: 'errorMessageDeleteDocument',
  });

  const [moveDocumentFoldersSubmit] = useMutationHook({
    mutationGql: MOVE_DOCUMENT_FOLDERS,
    errorMessage: 'errorMessageEditDocument',
  });

  const [subscribe, { loading: loadingSubscribe }] = useMutationHook({
    mutationGql: SUBSCRIBE,
    errorMessage: 'errorMessageSubscribeDocument',
  });

  const [unsubscribe, { loading: loadingUnsubscribe }] = useMutationHook({
    mutationGql: UNSUBSCRIBE,
    errorMessage: 'errorMessageUnsubscribeDocument',
  });

  const { data: isSubscribedData, loading } = useQuery(IS_SUBSCRIBED, {
    variables: {
      entityId: id,
      entityType: EntityType.DOCUMENT,
    },
  });

  useEffect(() => {
    if (isSubscribedData !== undefined && !loading) {
      setIsSubscribe(isSubscribedData.isSubscribed);
    }
  }, [isSubscribedData, loading]);

  useEffect(() => {
    if (data?.getDocumentById) {
      setCurrentPath(
        handlePathForDocumentDetails(data.getDocumentById.base.folderPath || [])
      );
      setCurrentDocument({
        id: data.getDocumentById.base.id,
        value: `${data.getDocumentById.base.name}${
          data.getDocumentById.base.isPublished ? '' : ' - DRAFT'
        }`,
      });
    }
  }, [data, setCurrentDocument, setCurrentPath]);

  const subscribeAction = useMemo(() => {
    if (isSubscribe !== undefined) {
      if (isSubscribe) {
        return {
          submit: () =>
            subscribe({
              variables: {
                entityId: id,
                entityType: EntityType.FOLDER,
              },
            }).then(() => {
              setIsSubscribe(false);
            }),
          text: 'subscribe',
          loading: loadingSubscribe,
        };
      } else {
        return {
          submit: () =>
            unsubscribe({
              variables: {
                entityId: id,
                entityType: EntityType.FOLDER,
              },
            }).then(() => {
              setIsSubscribe(true);
            }),
          text: 'unsubscribe',
          loading: loadingUnsubscribe,
        };
      }
    }
    return undefined;
    // eslint-disable-next-line
  }, [data, isSubscribe, loadingUnsubscribe, loadingSubscribe]);

  const deleteAction = useMemo(() => {
    if (data?.getDocumentFolderById.actions?.includes(EntityAction.DELETE)) {
      return () => {
        deleteDocumentMutation({
          variables: {
            appId,
            folderIds: [id],
          },
        }).then(deleteBackAction);
      };
    }
    return undefined;
    // eslint-disable-next-line
  }, [data]);

  const updateAction = useMemo(() => {
    if (data?.getDocumentFolderById.actions?.includes(EntityAction.UPDATE)) {
      return () => {
        setEditFolderUpdateAction(true);
      };
    }
    return undefined;
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data) {
      setCurrentPath(
        handlePathForDocumentDetails(data.getDocumentFolderById.base.folderPath)
      );
      setCurrentDocument({
        id: data.getDocumentFolderById.base.id,
        value: data.getDocumentFolderById.base.name,
      });
    }
  }, [data, setCurrentDocument, setCurrentPath]);

  useEffect(() => {
    if (data?.getDocumentFolderById) {
      setActionsButton({
        onCLickDelete: deleteAction,
        onCLickEdit: updateAction,
        onClickMove: () => setModalMoveOpen(true),
        onClickSubscribe: subscribeAction,
      });
    }
    // eslint-disable-next-line
  }, [data, deleteAction, setActionsButton, updateAction, subscribeAction]);

  return (
    <Container column>
      <FlexContainer style={{ padding: '20px' }} wrapContent>
        {data?.getDocumentFolderById.outputSections &&
          data.getDocumentFolderById.outputSections.length > 0 &&
          data.getDocumentFolderById.outputSections.map(
            (outputSections: OutputSection) => (
              <CustomSectionDocument
                key={outputSections.name}
                output={outputSections}
              />
            )
          )}
        {data && data.getDocumentFolderById.authorizations.length > 0 && (
          <AuthSectionDocument
            authorizations={data.getDocumentFolderById.authorizations}
            entityType={EntityType.FOLDER}
            entityId={id}
            refetchFolder={refetch}
          />
        )}
      </FlexContainer>
      {editFolderUpdateAction && (
        <DynamicForm
          onCancel={() => setEditFolderUpdateAction(false)}
          onSuccess={() => {
            setEditFolderUpdateAction(false);
            refetch();
          }}
          folderId={id}
          executionContext={ExecutionContext.DOCUMENT}
        />
      )}
      {modalMoveOpen && (
        <MoveDocumentModal
          closeOnClick={() => setModalMoveOpen(false)}
          submitOnClick={(d, path) =>
            moveDocumentFoldersSubmit({
              variables: {
                appId,
                documentIds: [id],
                targetFolderId: d,
              },
            }).then(() => {
              setCurrentPath(path);
              setModalMoveOpen(false);
            })
          }
          folderId={getPenultimateIdInDocumentPath(
            data?.getDocumentById?.base?.folderPath || []
          )}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;
