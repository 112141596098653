import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { FlexContainer } from '../../layout/FlexContainer';
import { SvgIcon } from '../../common/SvgIcon';
import { UploadEmpty } from '../../../svg';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { Button } from '../../common/Button';

interface InsertFromLocaleProps {
  onDropCallback: (acceptFiles: any, rejectedFiles: any) => void;
  allowedFiles: string[];
}

export const InsertFromLocale: FunctionComponent<InsertFromLocaleProps> = ({
  onDropCallback,
  allowedFiles,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCallback,
    maxSize: 50 * 1024 * 1024,
  });

  return (
    <Container
      column
      alignItems="center"
      justifyContent="center"
      {...getRootProps()}
      onClick={(e: any) => e.stopPropagation()}
    >
      <SvgIcon
        svg={<UploadEmpty />}
        width="45px"
        height="70px"
        color={Theme.colors.c7A869A}
      />
      <Text
        text="Trascina un file oppure caricalo"
        fontSize="16px"
        lineHeight="24px"
        color={Theme.colors.c7A869A}
      />
      <InsertButton
        border={`1px solid ${Theme.colors.cDCDCDC}`}
        padding="3px 15px"
        borderRadius="8px"
        height="40px"
        backgroundColor={Theme.colors.c5B84EF}
        hoverBackgroundColor={Theme.colors.c5176d6}
        {...getRootProps()}
      >
        <Text
          text="Sfoglia"
          color={Theme.colors.cFFFFFF}
          fontSize="13px"
          lineHeight="13px"
        />
        <input {...getInputProps()} />
      </InsertButton>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 541px;
`;

const InsertButton = styled(Button)`
  margin-top: 22px;
  min-width: fit-content;
`;
