import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';

interface BoardItemProps {
  title: string;
  bigText: string;
  date?: string;
}

export const BoardItem: FunctionComponent<BoardItemProps> = ({
  title,
  date,
  bigText,
}) => {
  return (
    <Container>
      <Text text={title} fontSize="14px" lineHeight="18px" fontWeight="500" />
      <Text
        text={bigText}
        fontSize="20px"
        lineHeight="24px"
        fontWeight="600"
        style={{ margin: '8px 0' }}
      />
      {date && (
        <Text
          text="dateWithColon"
          fontSize="16px"
          lineHeight="24px"
          translationArgs={{ date }}
          color={Theme.colors.c505050}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 30%;
  height: 4.75em;
  padding: 12px 18px 28px;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  @media (max-width: 990px) {
    padding: 12px;
    height: 30px;
    margin-top: 8px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;
