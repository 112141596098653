import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ChatResourceType,
  ChatRoomEventsResponse,
  ChatRoomEventType,
  NotificationResourceType,
  ResourceContextType,
  User,
} from '@pec-manager/graphql';
import { Mention, MentionsInput } from 'react-mentions';
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Chat, More, NavigateTo, Sent } from '../../svg';
import { Theme } from '../../theme';
import { Text } from '../../components/common/Text';
import { Button } from '../../components/common/Button';
import { ChatContent } from './ChatContent';
import { FIND_USERS } from '../../graphql/user/queries';
import defaultStyle from './ChatContent/defaultStyle';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import { LEAVE_CHAT, POST_MESSAGE_CHAT } from '../../graphql/chat/mutations';
import { extractTitleChat, IconChat } from './utilis';
import { MenuChoices } from '../../components/PagedFormDefinition/MenuChoices';
import { CHAT_ROOM_EVENT_RECEIVED } from '../../graphql/chat/subscriptions';
import { RoundedCircle } from '../../components/avatar/RoundedTextAvatar';
import { getMappedColor } from '../../utils/colorUtils';
import { GET_RESOURCE_CONTEXT } from '../../graphql/resource/queries';

interface InnerChatDetailsProps {
  chat: ChatRoomEventsResponse;
  modal?: boolean;
}

export const InnerChatDetails: FunctionComponent<InnerChatDetailsProps> = ({
  chat,
  modal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [mentions, setMentions] = useState<string[]>([]);
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');

  const [participants, setParticipants] = useState(chat.chatRoom.participants);

  const [postMessage] = useMutationHook({
    mutationGql: POST_MESSAGE_CHAT,
    errorMessage: 'genericErrorMessage',
  });

  const { data: searchUser } = useQuery(FIND_USERS);

  const [users, setUsers] = useState<{ id: string; display: string }[]>([]);

  useEffect(() => {
    if (searchUser) {
      setUsers(
        searchUser.findUsers.map((u: User) => ({
          id: u.id,
          display: `${u.firstName} ${u.lastName}`,
        }))
      );
    }
  }, [searchUser]);

  const { data: newChatEvent } = useSubscription(CHAT_ROOM_EVENT_RECEIVED, {
    variables: { correlationId: chat.chatRoom.id },
  });

  const [getResourceContext, { data: context }] = useLazyQuery(
    GET_RESOURCE_CONTEXT,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [leaveChat] = useMutation(LEAVE_CHAT);

  useEffect(() => {
    if (
      chat.chatRoom.resourceType !== ChatResourceType.NONE &&
      chat.chatRoom.resourceId &&
      taskId
    ) {
      getResourceContext({
        variables: {
          resourceId: chat.chatRoom.resourceId,
          resourceType: taskId
            ? NotificationResourceType.TASK
            : NotificationResourceType.EXECUTION,
        },
      });
    }
    // eslint-disable-next-line
  }, [chat.chatRoom.resourceId, location]);

  useEffect(() => {
    if (newChatEvent) {
      switch (newChatEvent.chatRoomEventReceived.eventType) {
        case ChatRoomEventType.PARTICIPANT_JOINED:
          if (
            newChatEvent.chatRoomEventReceived.participantJoinedEvent
              .chatRoomId === chat.chatRoom.id
          ) {
            setParticipants((prevState) => [
              ...prevState,
              newChatEvent.chatRoomEventReceived.actor,
            ]);
          }
          break;
        case ChatRoomEventType.PARTICIPANT_LEFT:
          if (
            newChatEvent.chatRoomEventReceived.participantLeftEvent
              .chatRoomId === chat.chatRoom.id
          ) {
            setParticipants((prevState) =>
              prevState.filter(
                (u) => u.id !== newChatEvent.chatRoomEventReceived.actor.id
              )
            );
          }
          break;
        case ChatRoomEventType.PARTICIPANT_INVITED:
          if (
            newChatEvent.chatRoomEventReceived.participantInvitedEvent
              .chatRoomId === chat.chatRoom.id
          ) {
            setParticipants((prevState) => [
              ...prevState,
              newChatEvent.chatRoomEventReceived.participantInvitedEvent.user,
            ]);
          }
          break;
        case ChatRoomEventType.PARTICIPANT_MESSAGE_RECEIVED:
        case ChatRoomEventType.PARTICIPANT_MESSAGE_READ:
        case ChatRoomEventType.RESOURCE_LINKED:
        case ChatRoomEventType.MESSAGE_POSTED:
        case ChatRoomEventType.CREATED:
        case ChatRoomEventType.CLOSED:
        case ChatRoomEventType.REOPENED:
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [newChatEvent]);

  const Icon = useMemo(() => {
    if (!chat.chatRoom.resourceId) {
      return <Chat />;
    }
    if (context?.getResourceContext.context) {
      const ExtractIcon = IconChat(context.getResourceContext.context.type);
      return <ExtractIcon />;
    }
    if (chat.chatRoom.resourceType === ChatResourceType.NONE) {
      return <Chat />;
    }
    return undefined;
    // eslint-disable-next-line
  }, [context]);
  const navigateTo = () => {
    if (
      context?.getResourceContext.context?.type === ResourceContextType.INBOX
    ) {
      navigate(
        `/${
          context.getResourceContext.context.appId
        }/inbox?sectionId=MAIN&elementId=${
          context.getResourceContext.context.inbox.mailState || 'INBOX'
        }${
          context.getResourceContext.context.inbox.assignmentType
            ? `&dropDownId=${context.getResourceContext.context.inbox.assignmentType}`
            : ''
        }&mailId=${context.getResourceContext.context.resourceId}`
      );
    }
    if (
      context?.getResourceContext.context?.type === ResourceContextType.SINTROPI
    ) {
      if (context.getResourceContext.context.resourceId.includes('task')) {
        navigate(
          `/${
            context.getResourceContext.context.appId
          }/sintropi?sectionId=MAIN&elementId=${
            context.getResourceContext.context.sintropi.taskState || 'SINTROPI'
          }${
            context.getResourceContext.context.sintropi.assignmentType
              ? `&dropDownId=${context.getResourceContext.context.sintropi.assignmentType}`
              : ''
          }&taskId=${context.getResourceContext.context.resourceId}`
        );
      } else {
        navigate(
          `/${context.getResourceContext.context.appId}/sintropi/executions?executionId=${context.getResourceContext.context.resourceId}`
        );
      }
    }
  };

  const choicesMenuValue = () => {
    const choiceMenu: any = [];
    if (modal) {
      choiceMenu.push({
        text: 'closedChat',
        onClick: () => navigate(`/chat`),
      });
    }
    return [
      {
        text: 'leaveChat',
        onClick: () =>
          leaveChat({
            variables: {
              chatRoomId: chat.chatRoom.id,
            },
          }).then(() => {
            navigate(`/chat`);
          }),
        colorText: Theme.colors.cFE4242,
      },
      ...choiceMenu,
    ];
  };

  return (
    <ChatContainer column>
      <NewChatContainer alignItems="center" justifyContent="space-between">
        <FlexContainer alignItems="center">
          {Icon && (
            <RoundedCircle
              backgroundColor={getMappedColor(
                context?.getResourceContext?.context?.resourceName ||
                  extractTitleChat(participants)
              )}
              size={40}
              alignItems="center"
              justifyContent="center"
            >
              <SvgIcon
                svg={Icon}
                height="25px"
                width="25px"
                color={Theme.colors.cFFFFFF}
              />
            </RoundedCircle>
          )}
          <NameText
            text={
              context?.getResourceContext?.context?.resourceName ||
              extractTitleChat(participants)
            }
            fontSize="16px"
            lineHeight="16px"
            fontWeight="500"
          />
          {chat.chatRoom.resourceType !== ChatResourceType.NONE && context && (
            <OnclickContainer onClick={navigateTo}>
              <SvgIcon svg={<NavigateTo />} width="13px" height="20px" />
            </OnclickContainer>
          )}
        </FlexContainer>
        <SvgClickable onClick={() => setOpenMenu((prevState) => !prevState)}>
          <SvgIcon
            svg={<More />}
            color={Theme.colors.c2186C6}
            height="20px"
            width="20px"
          />
          {openMenu && (
            <MenuChoices
              containerMenuProps={{ right: '6px', top: '104px' }}
              choices={choicesMenuValue()}
              onClickOutside={() => setOpenMenu(false)}
            />
          )}
        </SvgClickable>
      </NewChatContainer>
      <ChatContent
        initialEvents={chat.events}
        chatRoomId={chat.chatRoom.id}
        newChatEvent={newChatEvent?.chatRoomEventReceived}
      />
      <SendMessageContainer>
        <InputContainer column>
          <MentionsInput
            value={inputValue}
            onChange={(event, newValue, newSet, mentionsItem) => {
              setMentions(mentionsItem.map((m) => m.id));
              setInputValue(newValue);
            }}
            style={defaultStyle}
            placeholder={t('enterTextToSend') as string}
            onKeyUp={(event) => {
              if (
                event.key === 'Enter' &&
                event.altKey &&
                inputValue.length > 0
              )
                postMessage({
                  variables: {
                    chatRoomId: chat.chatRoom.id,
                    message: {
                      message: inputValue,
                      mentions: Array.from(new Set(mentions)),
                    },
                  },
                }).then(() => {
                  setInputValue('');
                });
            }}
          >
            <Mention
              trigger="@"
              data={users}
              displayTransform={(id, display) => display}
              style={{
                backgroundColor: 'rgba(205, 205, 205, 0.5)',
                borderRadius: '4px',
              }}
            />
          </MentionsInput>
          <SuggestionText
            text="suggestSendMessage"
            fontSize="12px"
            lineHeight="18px"
            color={Theme.colors.c505050}
          />
        </InputContainer>
        <SendMessageButton
          border={`1px solid ${Theme.colors.cDCDCDC}`}
          padding="3px 15px"
          borderRadius="8px"
          height="40px"
          backgroundColor={Theme.colors.c5B84EF}
          hoverBackgroundColor={Theme.colors.c5176d6}
          disabled={!(inputValue.length > 0)}
          onClick={() => {
            postMessage({
              variables: {
                chatRoomId: chat.chatRoom.id,
                message: {
                  message: inputValue,
                  mentions: Array.from(new Set(mentions)),
                },
              },
            }).then(() => {
              setInputValue('');
            });
          }}
        >
          <SendText
            text="sendMessage"
            color={Theme.colors.cFFFFFF}
            fontSize="13px"
            lineHeight="13px"
          />
          <SendIcon svg={<Sent />} width="20px" height="20px" />
        </SendMessageButton>
      </SendMessageContainer>
    </ChatContainer>
  );
};

const ChatContainer = styled(FlexContainer)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  height: 100%;
  max-height: calc(100vh - 42px);

  @media (max-width: 1040px) {
    overflow: auto;
  }

  @media (max-width: 990px) {
    max-height: calc((var(--vh, 1vh) * 100) - 42px);
  }
`;

const NameText = styled(Text)`
  margin-left: 16px;
`;

const NewChatContainer = styled(FlexContainer)`
  width: 100%;
  flex: 0 1 80px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  padding: 16px;
  min-height: 80px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const SvgClickable = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  margin-right: 4px;
  cursor: pointer;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SendMessageButton = styled(Button)`
  margin-left: 16px;
  min-width: fit-content;

  @media (max-width: 990px) {
    border-radius: 50%;
    height: 47px;
    width: 47px;
  }
`;

const SendMessageContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  padding: 20px;
  -webkit-box-shadow: 0px -2px 16px -2px #50505012;
  box-shadow: 0px -2px 16px 4px #50505012;
  z-index: 1;
`;

const InputContainer = styled(FlexContainer)`
  width: 100%;
  max-width: calc(100vw - 712px);

  @media (max-width: 1040px) {
    max-width: calc(100vw - 235px);
  }

  @media (max-width: 990px) {
    max-width: calc(100vw);
  }
`;

const SuggestionText = styled(Text)`
  margin-top: 5px;

  @media (max-width: 990px) {
    display: none;
  }
`;

const SendText = styled(Text)`
  @media (max-width: 990px) {
    display: none;
  }
`;

const SendIcon = styled(SvgIcon)`
  @media (min-width: 990px) {
    display: none;
  }
`;

const OnclickContainer = styled.div`
  cursor: pointer;
  margin-left: 12px;
`;
