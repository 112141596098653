import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  ResourcePriority,
  TaskStatus,
  TaskSummary,
} from '@pec-manager/graphql';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Calendar, More, Task } from '../../svg';
import { Theme } from '../../theme';
import { MenuChoices } from '../../components/PagedFormDefinition/MenuChoices';
import { ItemMenuChoices } from '../document';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../components/common/Text';
import { Description } from './Description';
import { handleRoundedAvatar } from '../../utils/stringUtils';
import { Tag } from '../../components/tag';
import { Choice, Select } from '../../components/select';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import {
  CHANGE_TASK_DUE_DATE,
  CHANGE_TASK_PRIORITY,
  REASSIGN_TASK,
} from '../../graphql/task/mutations';
import { ContainerClear, PrioritySelect } from '../TaskCalendar/EventItem';
import DatePicker from 'react-datepicker';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { KeycloakService } from '../../services/KeycloakService';
import { useTranslation } from 'react-i18next';
import './dataPicker.css';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { DynamicGenericUserField } from '../../components/common/GenericUserSelect';
import { HeaderDataPicker } from '../../components/common/HeaderDataPicker';
import { colorBorderLeft } from '../../utils/funcUtils';

/* eslint-disable react/no-children-prop */

export const TaskItemList: FunctionComponent<{
  task: TaskSummary;
  index: number;
}> = ({ task, index }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { appId } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const isSelected = task.base.id === searchParams.get('taskId');
  const dropDownId = searchParams.get('dropDownId');

  const [date, setDate] = useState<Date | undefined>(undefined);

  const dateNow =
    getDateAsString(Date.now()) === getDateAsString(task.base.createdAt as any);

  const [changeTaskPriority] = useMutationHook({
    mutationGql: CHANGE_TASK_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeTaskDueDate] = useMutationHook({
    mutationGql: CHANGE_TASK_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [reassignTask] = useMutationHook({
    mutationGql: REASSIGN_TASK,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [users, setUsers] = useState<string[]>([]);

  const [displayValueUsers, setDisplayValueUsers] = useState<
    {
      name: string;
      type: 'GROUP' | 'USER';
      id?: string;
    }[]
  >([]);

  useEffect(() => {
    setUsers(handleRoundedAvatar(task.base).map((user) => user.id!));
    setDisplayValueUsers(handleRoundedAvatar(task.base));
    setDate(task.base.expiresAt);

    // eslint-disable-next-line
  }, [task.base]);

  const [openMenu, setOpenMenu] = useState(false);

  const dropDownIdQueryParam = dropDownId
    ? `&dropDownId=${searchParams.get('dropDownId')}`
    : '';

  const navigatePushString =
    location.pathname === `${appId}/sintropi/search`
      ? `/${location.pathname}?taskId=${task.base.id}`
      : `/${appId}/sintropi?sectionId=${searchParams.get(
          'sectionId'
        )}&elementId=${searchParams.get(
          'elementId'
        )}${dropDownIdQueryParam}&taskId=${task.base.id}`;

  const menuChoices = [
    {
      text: 'Dettaglio',
      onClick: () => {
        navigate(navigatePushString);
      },
    },
  ];

  const text = `
    ${task.base.executionId === task.base.rootExecutionId ? '' : '+ '}
    ${task.base?.referencedProcessName || ''}`;
  return (
    <Motion
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      transition={{
        duration: index <= 7 ? 0.05 * (index + 1) : 0.1,
        type: 'spring',
      }}
    >
      <ContainerItem
        onClick={() => {
          //navigate(navigatePushString);
        }}
        colorBorderLeft={colorBorderLeft(task.base)}
        selected={isSelected}
        ref={ref}
      >
        <FirstItem
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(navigatePushString);
          }}
        >
          <FlexContainer alignItems="center">
            <SvgIcon
              svg={<Task />}
              width="14px"
              height="14px"
              color={Theme.colors.c52C41A}
            />
            <Text
              text={text}
              skipTranslation={true}
              fontSize="14px"
              lineHeight="18px"
              fontWeight="500"
              ellipsis
              style={{ marginLeft: '8px' }}
            />
          </FlexContainer>
          <Tag
            text={task.base.category || ''}
            colorBackground={task.base.referencedProcessName}
          />
          <SmallScreen minWidth={1300}>
            <Description
              subtitle={task.base.description}
              title={task.base.name}
              numberOfComments={task.commentCount}
              numberOfAttachments={task.attachmentCount}
            />
          </SmallScreen>
        </FirstItem>
        <ItemBig
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(navigatePushString);
          }}
        >
          <Description
            subtitle={task.base.description}
            title={task.base.name}
            numberOfComments={task.commentCount}
            numberOfAttachments={task.attachmentCount}
          />
        </ItemBig>
        <StartInfo
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(navigatePushString);
          }}
        >
          <Text
            text={dateNow ? 'todayAt' : 'completeDate'}
            translationArgs={{
              date: getDateAsString(task.base.createdAt as any),
              time: getTimeAsString(task.base.createdAt as any),
              fresh: true,
            }}
            fontSize="12px"
            lineHeight="18px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
          <Text
            text={`by ${task.base.executionStartUser.firstName} ${task.base.executionStartUser.lastName}`}
            fontSize="12px"
            lineHeight="20px"
            color={Theme.colors.c505050}
            style={{ marginRight: '8px' }}
          />
        </StartInfo>
        <UsersItem>
          <SmallScreen minWidth={1100}>
            <Text
              text={dateNow ? 'todayAt' : 'completeDate'}
              translationArgs={{
                date: getDateAsString(task.base.createdAt as any),
                time: getTimeAsString(task.base.createdAt as any),
                fresh: true,
              }}
              fontSize="12px"
              lineHeight="18px"
              fontWeight="300"
              color={Theme.colors.c505050}
            />
          </SmallScreen>
          <Text
            text={
              task.base.inCharge?.id ? 'takeInChargeTo' : 'assignedToBottomList'
            }
            fontSize="12px"
            lineHeight="20px"
            color={Theme.colors.c505050}
            style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
          />
          <DynamicGenericUserField
            openUp={
              // @ts-ignore
              window.innerHeight - ref.current?.getBoundingClientRect().top <
              214
            }
            displayValue={displayValueUsers}
            users={users}
            disabled={!task.base.isReassignable}
            setUsers={(el) => {
              const us = displayValueUsers
                .filter((user) => user.type !== 'GROUP')
                .map((user) => user.id!);

              const usersWithoutGroup = Array.from(new Set(us.concat(el.id!)));

              const groups = displayValueUsers.filter(
                (u) => u.type === 'GROUP'
              );

              reassignTask({
                variables: {
                  taskId: task.base.id,
                  userIds: usersWithoutGroup,
                  groupIds: groups.length ? groups.map((g) => g.id!) : [],
                },
              }).then(() => {
                if (groups.length > 0) {
                  setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
                } else {
                  setUsers(usersWithoutGroup);
                }
                setDisplayValueUsers((prevState) => {
                  if (
                    prevState.find(
                      (element) => el.name === `${el.firstName} ${el.lastName}`
                    )
                  ) {
                    return prevState;
                  } else {
                    return [
                      ...prevState,
                      {
                        name: `${el.firstName} ${el.lastName}`,
                        type: 'USER',
                        id: el.id,
                      },
                    ];
                  }
                });
              });
            }}
            onClickRemove={(id) => {
              const us = displayValueUsers
                .filter((user) => user.type !== 'GROUP')
                .map((user) => user.id!);

              const usersWithoutGroup = us.filter((user) => user !== id);

              const groups = displayValueUsers.filter(
                (u) => u.type === 'GROUP'
              );

              reassignTask({
                variables: {
                  taskId: task.base.id,
                  userIds: usersWithoutGroup,
                  groupIds: groups.length ? groups.map((g) => g.id!) : [],
                },
              }).then(() => {
                if (groups.length > 0) {
                  setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
                } else {
                  setUsers(usersWithoutGroup);
                }
                setDisplayValueUsers((prevState) =>
                  prevState.filter((element) => element.id !== id)
                );
              });
            }}
          />
        </UsersItem>
        {!KeycloakService.removeFieldInTask().includes('dueDate') && (
          <ItemSmall>
            <DatePickerStyled
              placeholderText={t('insertDate') as string}
              disabled={
                task.base.status === TaskStatus.COMPLETED ||
                !task.base.isManageable
              }
              locale={
                mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
              }
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <HeaderDataPicker
                  date={date}
                  decreaseMonth={decreaseMonth}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
              dateFormat={
                mappingLocaleForDatePicker(
                  KeycloakService.getLocaleForDate()?.toLowerCase() ||
                    KeycloakService.getLocale()
                ).formatDate
              }
              onChange={(date) => {
                changeTaskDueDate({
                  variables: {
                    taskId: task.base.id,
                    newDueDate: new Date(date as any).getTime(),
                  },
                }).then(() => {
                  setDate(date as any);
                });
              }}
              children={
                <ContainerClear
                  onClick={() => {
                    changeTaskDueDate({
                      variables: {
                        taskId: task.base.id,
                        newDueDate: undefined,
                      },
                    }).then(() => {
                      setDate(undefined);
                    });
                  }}
                >
                  <Text
                    text={t('clearDueDate')}
                    fontSize="12px"
                    lineHeight="32px"
                    fontWeight="300"
                    color={Theme.colors.cD73E18}
                  />
                </ContainerClear>
              }
              value={date as string | undefined}
              selected={date}
              showTimeSelect={false}
              timeFormat="HH:mm"
            />
            <SvgIcon
              svg={<Calendar />}
              color={Theme.colors.c505050}
              width="18px"
              style={{ marginLeft: '-26px', cursor: 'default' }}
            />
          </ItemSmall>
        )}
        {!KeycloakService.removeFieldInTask().includes('priority') && (
          <LastItem>
            <Select
              colorText={Theme.colors.c838383}
              fontSizeText="12px"
              lineHeightText="18px"
              choices={PrioritySelect.filter(
                (choice) => choice.value !== ResourcePriority.NONE
              )}
              defaultChoice={
                PrioritySelect.filter((s) => s.value === task.base.priority)[0]
              }
              disabled={!task.base.isManageable}
              onChoiceSelected={(choice: Choice) => {
                changeTaskPriority({
                  variables: {
                    taskId: task.base.id,
                    newPriority: choice.value as ResourcePriority,
                  },
                });
              }}
              height="32px"
              backgroundColor={Theme.colors.cFAFAFA}
              noDisplayValue
            />
          </LastItem>
        )}
        <ItemMenuChoices onClick={(e) => e.stopPropagation()}>
          <SvgClickable
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenu((prevState) => !prevState);
            }}
          >
            <SvgIcon
              svg={<More />}
              color={Theme.colors.c2186C6}
              height="20px"
              width="20px"
            />
            {openMenu && (
              <MenuChoices
                containerMenuProps={{ right: '-10px', top: '36px' }}
                choices={menuChoices}
                onClickOutside={() => setOpenMenu(false)}
              />
            )}
          </SvgClickable>
        </ItemMenuChoices>
      </ContainerItem>
    </Motion>
  );
};

const Motion = styled(motion.div)`
  width: calc(100% - 20px);
`;

const ContainerItem = styled(FlexContainer)<{
  selected?: boolean;
  colorBorderLeft?: string;
}>`
  position: relative;
  width: 100%;
  padding: 10px 0 10px 20px;
  margin-top: 8px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFFFFFF};
  border-left: 6px solid ${({ colorBorderLeft }) => colorBorderLeft};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  &:hover {
    transform: translateZ(1.5);
  }

  & > div:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    padding-left: 16px;
  }
`;

export const ItemSmall = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 16px;
  overflow: hidden;
  @media (max-width: 1300px) {
    width: 18%;
  }
  @media (max-width: 1100px) {
    width: 21%;
  }
`;

export const UsersItem = styled.div`
  flex: 1;
  overflow: hidden;
  display: inline-block;
  align-items: center;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 20%;
  }
  @media (max-width: 1100px) {
    width: 25%;
  }
`;

export const LastItem = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 12%;
  }
  @media (max-width: 1100px) {
    width: 14%;
  }
`;
export const FirstItem = styled.div`
  display: flex;
  flex: 2;
  overflow: hidden;
  justify-content: space-around;
  padding-right: 16px;
  flex-direction: column;
  @media (max-width: 1300px) {
    width: 30%;
  }
  @media (max-width: 1100px) {
    width: 40%;
  }
`;

export const ItemBig = styled.div`
  flex: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    display: none;
  }
`;

export const SmallScreen = styled.div<{ minWidth: number }>`
  @media (min-width: ${({ minWidth }) => minWidth}px) {
    display: none;
  }
`;

const SvgClickable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 30px;
  overflow: auto;
  width: 100%;
  text-overflow: ellipsis;
  padding-right: 30px;
`;

export const StartInfo = styled(ItemSmall)`
  width: 12%;
  display: table-cell;
  vertical-align: middle;
  span {
    width: 100%;
    display: inline-block;
  }
  @media (max-width: 1300px) {
    width: 20%;
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;
