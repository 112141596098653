import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ExecutionContext,
  FilledForm,
  FilledFormComplexField,
  FilledFormField,
  FormFieldDefinition,
  FormFieldDefinitionDefaultValueType,
} from '@pec-manager/graphql';
import { FormFieldContainer } from './FormFieldContainer';
import { mapFormFieldToComponent } from './mapFormFieldToComponent';
import { Button } from '../../common/Button';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { FlexContainer } from '../../layout/FlexContainer';
import { boundaryError } from './utils/errorMessage';
import { SvgIcon } from '../../common/SvgIcon';
import { Add, Close, Error } from '../../../svg';
import {
  extractFormFieldDefinitionDefaultValueType,
  insertValueInFilledForm,
  extractDefaultValueKind,
} from './utils/extractAllFieldId';

interface ComplexVariablesFieldProps {
  executionContext: ExecutionContext;
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean,
    complexVariables?: FilledFormComplexField[]
  ) => void;
  filledForm: FilledForm;
  formId: string;
  formGroupId: string;
  selectOpened: string;
  setSelectOpened?: (id: string) => void;
  checkRequired?: boolean;
  initialContext?: any;
  columnsForm: number;
}

export const ComplexVariablesField: FC<ComplexVariablesFieldProps> = ({
  formField,
  setSelectOpened,
  selectOpened,
  filledForm,
  fillField,
  executionContext,
  checkRequired,
  formId,
  formGroupId,
  initialContext,
  columnsForm,
}) => {
  const [data, setData] = useState<
    {
      temporaryId: string;
      complexValues: FilledFormField[];
    }[]
  >([]);

  const [firstOnClick, setFirstOnClick] = useState(checkRequired);
  const [clickAdd, setClickAdd] = useState('');

  useEffect(() => {
    for (let i = data.length; i < formField.cardinalityBounds?.left!; i++) {
      setData((prevState) => [
        ...prevState,
        { temporaryId: uuidv4(), complexValues: [] },
      ]);
    }
  }, [data]);

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    if (formField.defaultValues.length > 0) {
      if (data.length === 1) {
        setData((prevState) => {
          return formField.defaultValues.map((d) => {
            const complex = d.kind.composite?.values.map((v) => {
              const prevstateFilledValues = prevState[0].complexValues.find(
                (q) => q.id === v.id
              )?.filledValues;

              const defDefaultValue = v.defaultValues.map((vv) => {
                // @ts-ignore
                let value = vv.kind[extractDefaultValueKind(vv.type)];

                if (vv.type === FormFieldDefinitionDefaultValueType.CURRENCY) {
                  return (value as any).values.toString();
                }
                if (
                  vv.type ===
                  FormFieldDefinitionDefaultValueType.DOCUMENT_OBJECT
                ) {
                  return vv!.kind!.documentObject!.blobRef;
                }
                // @ts-ignore
                return value[extractDefaultValueKind(vv.type)].toString();
              });
              return {
                id: v.id,
                filledValues:
                  prevstateFilledValues && prevstateFilledValues.length > 0
                    ? prevstateFilledValues
                    : defDefaultValue,
              };
            });

            return {
              temporaryId: uuidv4(),
              complexValues: complex as any,
            };
          });
        });
      } else {
        setData((prevState) => {
          return formField.defaultValues.map((d) => {
            const complex = d.kind.composite?.values.map((v) => {
              return {
                id: v.id,
                filledValues: v.defaultValues.map((vv) => {
                  // @ts-ignore
                  let value = vv.kind[extractDefaultValueKind(vv.type)];

                  if (
                    vv.type === FormFieldDefinitionDefaultValueType.CURRENCY
                  ) {
                    return (value as any).values.toString();
                  }
                  if (
                    vv.type ===
                    FormFieldDefinitionDefaultValueType.DOCUMENT_OBJECT
                  ) {
                    return vv!.kind!.documentObject!.blobRef;
                  }
                  // @ts-ignore
                  return value[extractDefaultValueKind(vv.type)].toString();
                }),
              };
            });

            return {
              temporaryId: uuidv4(),
              complexValues: complex as any,
            };
          });
        });
      }
    }
    setClickAdd('');
  }, [formField]);

  const fillFieldComplexVariables = useCallback(
    (groupId: string, refetchDefinition?: boolean) => {
      fillField(groupId, [], refetchDefinition || formField.isDynamic, [
        {
          filledFields: [
            {
              id: groupId,
              complexValues: data
                .filter((c) => c.complexValues.length > 0)
                .map((d) => ({
                  filledFields: d.complexValues,
                })),
            },
          ],
        },
      ]);
    },
    // eslint-disable-next-line
    [data]
  );

  const internalFillFieldComplexVariables = useCallback(
    (values: any, groupId: string, refetchDefinition?: boolean) => {
      fillField(groupId, [], refetchDefinition || formField.isDynamic, [
        {
          filledFields: [
            {
              id: groupId,
              complexValues: values
                .filter((c: any) => c.complexValues.length > 0)
                .map((d: any) => ({
                  filledFields: d.complexValues,
                })),
            },
          ],
        },
      ]);
    },
    // eslint-disable-next-line
    [data]
  );

  useEffect(() => {
    if (data.some((d) => d.complexValues.length > 0))
      fillFieldComplexVariables(formField.id);
  }, [data, fillFieldComplexVariables, formField.id]);

  const fillFieldDelegateVariables = useCallback(
    (
      temporaryId: string,
      fieldId: string,
      values: string[],
      refetchDefinition?: boolean
    ) => {
      setData((prevState) => {
        return prevState.map((p) => {
          if (p.temporaryId === temporaryId) {
            return {
              ...p,
              complexValues: [
                ...p.complexValues.filter((c) => c.id !== fieldId),
                {
                  id: fieldId,
                  filledValues: values,
                },
              ],
            };
          }
          return p;
        });
      });
      internalFillFieldComplexVariables(
        data.map((p) => {
          if (p.temporaryId === temporaryId) {
            return {
              ...p,
              complexValues: [
                ...p.complexValues.filter((c) => c.id !== fieldId),
                {
                  id: fieldId,
                  filledValues: values,
                },
              ],
            };
          }
          return p;
        }),
        formField.id,
        refetchDefinition
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fillFieldComplexVariables, formField.id]
  );

  const calcError = useMemo(
    () =>
      firstOnClick && formField.isEditable
        ? boundaryError(
            data.length,
            formField.cardinalityBounds?.left,
            formField.cardinalityBounds?.right
          )
        : undefined,
    [
      firstOnClick,
      formField.isEditable,
      formField.cardinalityBounds?.left,
      formField.cardinalityBounds?.right,
      data.length,
    ]
  );

  return (
    <FormFieldContainer
      formField={formField}
      complexWithoutBorder={
        formField.cardinalityBounds?.left === formField.cardinalityBounds?.right
      }
    >
      {data.map(({ complexValues, temporaryId }, indexData) => {
        return (
          <div key={temporaryId}>
            {formField.cardinalityBounds?.left !==
            formField.cardinalityBounds?.right ? (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Text
                  text={formField.name + ' ' + (indexData + 1)}
                  fontSize="14px"
                  lineHeight="18px"
                  fontWeight="500"
                  style={{ marginBottom: '8px' }}
                  skipTranslation
                />
                {formField.isEditable &&
                  data.length > (formField?.cardinalityBounds?.left || 0) && (
                    <Button
                      onClick={() => {
                        setData((prevState) => {
                          fillField(formField.id, [], formField.isDynamic, [
                            {
                              filledFields: [
                                {
                                  id: formField.id,
                                  complexValues: data
                                    .filter((c) => c.complexValues.length > 0)
                                    .filter(
                                      (c) => c.temporaryId !== temporaryId
                                    )
                                    .map((d) => ({
                                      filledFields: d.complexValues,
                                    })),
                                },
                              ],
                            },
                          ]);
                          return prevState.filter(
                            (p) => p.temporaryId !== temporaryId
                          );
                        });
                      }}
                      backgroundColor={Theme.colors.cFFFFFF}
                    >
                      <SvgIcon
                        svg={<Close />}
                        height="18px"
                        width="18px"
                        color={'red'}
                        style={{
                          marginLeft: '1em',
                        }}
                      />
                    </Button>
                  )}
              </div>
            ) : (
              <></>
            )}
            <Container wrapContent removeBorder={true}>
              {formField.kind?.composite?.emptyFormFieldDefinitions.map(
                (field) => {
                  const defaultValue = complexValues.filter(
                    (c) => c.id === field.id
                  );

                  const def =
                    defaultValue.filter((d) => d.id === field.id)[0]
                      ?.filledValues || [];

                  const defComplex = field.defaultValues.filter(
                    (d) => !!insertValueInFilledForm(d.type, '', field)
                  );

                  const formFieldDefaultValues =
                    formField.defaultValues.length > indexData
                      ? formField.defaultValues[
                          indexData
                        ].kind?.composite?.values.filter(
                          (d) => d.id === field.id
                        ) || []
                      : [];

                  const defaultValueCalc = insertValueInFilledForm(
                    extractFormFieldDefinitionDefaultValueType(field.type),
                    def[0] || '',
                    field
                  );

                  const defDefaultValue = {
                    ...(formFieldDefaultValues[0] || field),
                    defaultValues:
                      def.length > 0 || defComplex.length > 0
                        ? [
                            {
                              type: extractFormFieldDefinitionDefaultValueType(
                                field.type
                              ),
                              kind: {
                                // @ts-ignore
                                [extractDefaultValueKind(
                                  extractFormFieldDefinitionDefaultValueType(
                                    field.type
                                  )
                                )]: defaultValueCalc,
                              },
                            },
                          ]
                        : [],
                  };

                  const formFieldDefaultValuesComplex =
                    field.defaultValues.length > 0 &&
                    formFieldDefaultValues.length > 0
                      ? formFieldDefaultValues[0]
                      : defDefaultValue;

                  return (
                    mapFormFieldToComponent(
                      executionContext,
                      clickAdd === temporaryId
                        ? field
                        : formFieldDefaultValuesComplex,
                      (fieldId, data, refetchDefinition) => {
                        fillFieldDelegateVariables(
                          temporaryId,
                          fieldId,
                          data,
                          refetchDefinition
                        );
                      },
                      filledForm,
                      formId,
                      formGroupId,
                      selectOpened,
                      formField.kind?.composite?.viewMode,
                      setSelectOpened,
                      () => {},
                      () => {},
                      undefined,
                      checkRequired,
                      true,
                      temporaryId,
                      indexData,
                      columnsForm,
                      initialContext
                    ) || <></>
                  );
                }
              )}
            </Container>
            {indexData !== data.length - 1 ? <Divider /> : <></>}
          </div>
        );
      })}
      {calcError ? (
        <ErrorMassage>
          <SvgIcon svg={<Error />} height="8px" width="8px" />
          <ErrorText
            text={calcError}
            fontSize="12px"
            lineHeight="12px"
            fontWeight="300"
            color={Theme.colors.cFE4242}
          />
        </ErrorMassage>
      ) : null}
      {formField.isEditable &&
        data.length < (formField?.cardinalityBounds?.right || data.length) && (
          <Button
            onClick={() => {
              const uuid = uuidv4();
              setClickAdd(uuid);
              setData((prevState) => [
                ...prevState,
                { temporaryId: uuid, complexValues: [] },
              ]);
            }}
            backgroundColor={Theme.colors.cFFFFFF}
            border={`none`}
            disabled={
              formField.cardinalityBounds?.right! > 0 &&
              formField.cardinalityBounds?.right! <= data.length
            }
          >
            <SvgIcon svg={<Add />} height="18px" width="18px" color={'green'} />
            &nbsp;
            <Text text={'add'} fontSize="18px" lineHeight="15px" />
            &nbsp;
            <Text text={formField.name} fontSize="18px" lineHeight="15px" />
          </Button>
        )}
    </FormFieldContainer>
  );
};

const Container = styled(FlexContainer)<{ removeBorder: boolean }>`
  border: ${({ removeBorder }) =>
    removeBorder ? 'none' : `1px solid ${Theme.colors.cDCDCDC}`};
  border-radius: 8px;
  gap: 10px 20px;
  padding: ${({ removeBorder }) => (removeBorder ? 'inherited' : `8px`)};

  @media (max-width: 960px) {
    flex-direction: column !important;
  }
`;

const ErrorMassage = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0;
  width: fit-content;
`;

const ErrorText = styled(Text)`
  margin-left: 8px;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #eaeaea 0%, transparent 50%);
  margin: 10px auto;
  width: 100%;
`;
