import { createReducer, current } from '@reduxjs/toolkit';
import { FilledForm, TaskSummary, SavedSearch } from '@pec-manager/graphql';
import {
  appendTasks,
  appendTasksWithDueDate,
  appendTasksWithoutDueDate,
  clearTaskList,
  clearTaskWithDueDateList,
  clearTaskWithoutDueDateList,
  deleteTask,
  setNewPriorityInTask,
  setSelectedTaskId,
  updateTask,
} from './actions';
import {
  setAdvancedSearchFilledForm,
  setSavedSearchList,
  setSimpleSearchFilter,
} from '../../Inbox/mail/actions';

export interface TaskState {
  selectedTaskId?: string;
  taskCount: number;
  tasks: TaskSummary[] | null;
  tasksWithDueDate: TaskSummary[] | null;
  tasksWithoutDueDate: TaskSummary[] | null;
  simpleSearchFilter: string | null;
  advancedSearchFilledForm: FilledForm | null;
  advancedSearchPageId: string | null;
  savedSearchList: SavedSearch[];
  chatModalId?: {
    id: string;
    resourceId?: string;
  };
}

const defaultState: TaskState = {
  taskCount: 0,
  tasks: null,
  tasksWithDueDate: null,
  tasksWithoutDueDate: null,
  simpleSearchFilter: null,
  advancedSearchFilledForm: null,
  advancedSearchPageId: null,
  savedSearchList: [],
};

export const taskReducer = createReducer<TaskState>(defaultState, {
  [setSelectedTaskId.type]: (state, action): TaskState => ({
    ...state,
    selectedTaskId: action.payload,
  }),
  [clearTaskList.type]: (state): TaskState => ({
    ...state,
    tasks: null,
    taskCount: 0,
  }),
  [clearTaskWithDueDateList.type]: (state): TaskState => ({
    ...state,
    tasksWithDueDate: null,
    taskCount: 0,
  }),
  [clearTaskWithoutDueDateList.type]: (state): TaskState => ({
    ...state,
    tasksWithoutDueDate: null,
    taskCount: 0,
  }),
  [appendTasks.type]: (state, action): TaskState => {
    const mergingTaskIds = (action.payload.tasks as TaskSummary[]).map(
      (m) => m.base.id
    );

    return {
      ...state,
      taskCount: action.payload.taskCount,
      tasks: [
        ...(state.tasks?.filter(
          (m) => !mergingTaskIds.some((i) => i === m.base.id)
        ) || []),
        ...action.payload.tasks,
      ],
    };
  },
  [appendTasksWithDueDate.type]: (state, action): TaskState => ({
    ...state,
    taskCount: action.payload.taskCount,
    tasksWithDueDate: action.payload.tasks,
  }),
  [appendTasksWithoutDueDate.type]: (state, action): TaskState => ({
    ...state,
    taskCount: action.payload.taskCount,
    tasksWithoutDueDate: action.payload.tasks,
  }),
  [deleteTask.type]: (state, action): TaskState => {
    return {
      ...state,
      tasks: state.tasks
        ? state.tasks.filter((m) => m.base.id !== action.payload)
        : state.tasks,
    };
  },
  [updateTask.type]: (state, action: { payload: TaskSummary }): TaskState => {
    const taskIndex = state.tasks?.findIndex(
      (m) => m.base.id === action.payload.base.id
    );

    const taskUpdatedWithDueDate =
      action.payload.base.expiresAt && state.tasksWithDueDate
        ? [
            action.payload,
            ...(state.tasksWithDueDate.filter(
              (t) => t.base.id !== action.payload.base.id
            ) || []),
          ]
        : state.tasksWithDueDate?.filter(
            (t) => t.base.id !== action.payload.base.id
          ) || [];

    const taskUpdatedWithoutDueDate =
      !action.payload.base.expiresAt && state.tasksWithoutDueDate
        ? [
            action.payload,
            ...(state.tasksWithoutDueDate.filter(
              (t) => t.base.id !== action.payload.base.id
            ) || []),
          ]
        : state.tasksWithoutDueDate?.filter(
            (t) => t.base.id !== action.payload.base.id
          ) || [];

    return {
      ...state,
      taskCount: taskIndex !== -1 ? state.taskCount : state.taskCount + 1,
      tasks: [
        action.payload,
        ...(state.tasks?.filter((t) => t.base.id !== action.payload.base.id) ||
          []),
      ],
      tasksWithDueDate: taskUpdatedWithDueDate,
      tasksWithoutDueDate: taskUpdatedWithoutDueDate,
    };
  },
  [setNewPriorityInTask.type]: (state, action): TaskState => ({
    ...state,
    tasks: [
      ...(current(state).tasks?.map((t) =>
        t.base.id === action.payload.id
          ? {
              ...t,
              base: { ...t.base, priority: action.payload.priority },
            }
          : t
      ) || []),
    ],
  }),
  [setSimpleSearchFilter.type]: (state, action): TaskState => ({
    ...state,
    simpleSearchFilter: action.payload,
    advancedSearchFilledForm: null,
    advancedSearchPageId: null,
  }),
  [setAdvancedSearchFilledForm.type]: (state, action): TaskState => ({
    ...state,
    simpleSearchFilter: null,
    advancedSearchFilledForm: action.payload.filledForm,
    advancedSearchPageId: action.payload.pageId,
  }),
  [setSavedSearchList.type]: (state, action): TaskState => ({
    ...state,
    savedSearchList: action.payload,
  }),
});
