import { inboxSidebarReducer } from './Inbox/sidebar/reducers';
import { mailReducer } from './Inbox/mail/reducers';
import { notificationReducer } from './notification/sidebar/reducers';
import { taskReducer } from './Sintropi/task/reducers';
import { executionReducer } from './Sintropi/execution/reducers';
import { taskSidebarReducer } from './Sintropi/sidebar/reducers';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    inboxSidebarReducer,
    taskSidebarReducer,
    executionReducer,
    mailReducer,
    notificationReducer,
    taskReducer,
  },
  devTools: true,
});

export type ReduxState = ReturnType<typeof store.getState>;
