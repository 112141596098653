import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { Choice, Select } from '../../components/select';
import { ResourcePriority, TaskStatus } from '@pec-manager/graphql';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import {
  CHANGE_TASK_DUE_DATE,
  CHANGE_TASK_PRIORITY,
  REASSIGN_TASK,
} from '../../graphql/task/mutations';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Calendar, Expand, Priority } from '../../svg';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { TagCalendar } from '../../components/tagCalendar';
import { DynamicGenericUserField } from '../../components/common/GenericUserSelect';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { KeycloakService } from '../../services/KeycloakService';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { handleRoundedAvatar } from '../../utils/stringUtils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HeaderDataPicker } from '../../components/common/HeaderDataPicker';
import { colorBorderLeft } from '../../utils/funcUtils';

/* eslint-disable react/no-children-prop */

const mapColorIcon = {
  [ResourcePriority.HIGH]: Theme.colors.cFE4242,
  [ResourcePriority.MEDIUM]: Theme.colors.cFFC53D,
  [ResourcePriority.LOW]: Theme.colors.c7EB902,
  [ResourcePriority.NONE]: Theme.colors.c505050,
};

const PRIORITIES = [
  ResourcePriority.HIGH,
  ResourcePriority.MEDIUM,
  ResourcePriority.LOW,
];

const PrioritySelect: Choice[] = PRIORITIES.map((priority) => ({
  svg: (
    <SvgIcon
      svg={<Priority />}
      color={mapColorIcon[priority as ResourcePriority]}
      width="13px"
    />
  ),
  displayValue: `${priority.toLowerCase()}Priority`,
  value: priority,
}));

export const EventItemWithoutDueDate: FC<{
  event: any;
  refetch: () => void;
}> = ({ event, refetch }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(event.dueDate || undefined);
  const ref = useRef(null);
  const { t } = useTranslation();
  const [users, setUsers] = useState<string[]>(
    handleRoundedAvatar(event.taskBase).map((user) => user.id!)
  );

  const [displayValueUsers, setDisplayValueUsers] = useState<
    {
      name: string;
      type: 'GROUP' | 'USER';
      id?: string;
    }[]
  >(handleRoundedAvatar(event.taskBase));

  const [changeTaskPriority] = useMutationHook({
    mutationGql: CHANGE_TASK_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [reassignTask] = useMutationHook({
    mutationGql: REASSIGN_TASK,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeTaskDueDate] = useMutationHook({
    mutationGql: CHANGE_TASK_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const top = () => {
    // @ts-ignore
    if (window.innerHeight - ref.current?.getBoundingClientRect().top < 116) {
      return window.innerHeight - 90;
    } else {
      // @ts-ignore
      return ref.current?.getBoundingClientRect().top + 50;
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dropDownId = searchParams.get('dropDownId');
  const { appId } = useParams();

  const dropDownIdQueryParam = dropDownId
    ? `&dropDownId=${searchParams.get('dropDownId')}`
    : '';

  const navigatePushString =
    location.pathname === `${appId}/sintropi/search`
      ? `/${location.pathname}?taskId=${event.taskBase.id}`
      : `/${appId}/sintropi?sectionId=${searchParams.get(
          'sectionId'
        )}&elementId=${searchParams.get(
          'elementId'
        )}${dropDownIdQueryParam}&taskId=${event.taskBase.id}`;

  return (
    <>
      {open && (
        <Modal
          onMouseLeave={() => setOpen(false)}
          // @ts-ignore
          right={ref.current?.getBoundingClientRect().right - 100 || 0} // @ts-ignore
          top={top()}
          colorBorderLeft={colorBorderLeft(event.taskBase)}
        >
          <TopSection justifyContent="space-between" alignItems="center">
            <TagCalendar
              text={event.taskBase.category}
              colorBackground={event.taskBase.category}
              height="32px"
            />
            <Select
              colorText={Theme.colors.c838383}
              fontSizeText="12px"
              lineHeightText="18px"
              choices={PrioritySelect.filter(
                (choice) => choice.value !== ResourcePriority.NONE
              )}
              width="150px"
              defaultChoice={
                PrioritySelect.filter((s) => s.value === event.priority)[0]
              }
              disabled={!event.taskBase.isManageable}
              onChoiceSelected={(choice: Choice) => {
                changeTaskPriority({
                  variables: {
                    taskId: event.id,
                    newPriority: choice.value as ResourcePriority,
                  },
                });
              }}
              height="32px"
              backgroundColor={Theme.colors.cFAFAFA}
              zIndex={1}
            />
          </TopSection>
          <TextSection column>
            <Text
              text={event.title}
              fontSize="16px"
              lineHeight="18px"
              color={Theme.colors.c000000}
              ellipsis
            />
            <Text
              text={event.description}
              fontSize="12px"
              lineHeight="14px"
              color={Theme.colors.c505050}
              ellipsis
              style={{ marginTop: '8px' }}
            />
          </TextSection>
          <AssigneesSection>
            <Text
              text={
                event.taskBase.inCharge?.id
                  ? 'takeInChargeTo'
                  : 'assignedToBottomList'
              }
              fontSize="12px"
              lineHeight="32px"
              color={Theme.colors.c505050}
              style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
            />
            <DynamicGenericUserField
              openUp={
                // @ts-ignore
                window.innerHeight - ref.current?.getBoundingClientRect().top <
                214
              }
              width="calc(100% - 22px)"
              displayValue={displayValueUsers}
              disabled={!event.taskBase.isReassignable}
              users={users}
              setUsers={(el) => {
                const us = displayValueUsers
                  .filter((user) => user.type !== 'GROUP')
                  .map((user) => user.id!);

                const usersWithoutGroup = Array.from(
                  new Set(us.concat(el.id!))
                );

                const groups = displayValueUsers.filter(
                  (u) => u.type === 'GROUP'
                );

                reassignTask({
                  variables: {
                    taskId: event.id,
                    userIds: usersWithoutGroup,
                    groupIds: groups.length ? groups.map((g) => g.id!) : [],
                  },
                }).then(() => {
                  if (groups.length > 0) {
                    setUsers(
                      usersWithoutGroup.concat(groups.map((g) => g.id!))
                    );
                  } else {
                    setUsers(usersWithoutGroup);
                  }
                  setDisplayValueUsers((prevState) => {
                    if (
                      prevState.find(
                        (element) =>
                          el.name === `${el.firstName} ${el.lastName}`
                      )
                    ) {
                      return prevState;
                    } else {
                      return [
                        ...prevState,
                        {
                          name: `${el.firstName} ${el.lastName}`,
                          type: 'USER',
                          id: el.id,
                        },
                      ];
                    }
                  });
                });
              }}
              onClickRemove={(id) => {
                const us = displayValueUsers
                  .filter((user) => user.type !== 'GROUP')
                  .map((user) => user.id!);

                const usersWithoutGroup = us.filter((user) => user !== id);

                const groups = displayValueUsers.filter(
                  (u) => u.type === 'GROUP'
                );

                reassignTask({
                  variables: {
                    taskId: event.id,
                    userIds: usersWithoutGroup,
                    groupIds: groups.length ? groups.map((g) => g.id!) : [],
                  },
                }).then(() => {
                  if (groups.length > 0) {
                    setUsers(
                      usersWithoutGroup.concat(groups.map((g) => g.id!))
                    );
                  } else {
                    setUsers(usersWithoutGroup);
                  }

                  setDisplayValueUsers((prevState) =>
                    prevState.filter((element) => element.id !== id)
                  );
                });
              }}
            />
          </AssigneesSection>
          <DueDateSection>
            <Text
              text="dueDateReassign"
              fontSize="12px"
              lineHeight="36px"
              color={Theme.colors.c505050}
              style={{ marginRight: '8px' }}
            />
            <DatePickerStyled
              placeholderText={t('insertDate') as string}
              disabled={
                event.taskBase.status === TaskStatus.COMPLETED ||
                !event.taskBase.isManageable
              }
              locale={
                mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
              }
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <HeaderDataPicker
                  date={date}
                  decreaseMonth={decreaseMonth}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
              dateFormat={
                mappingLocaleForDatePicker(
                  KeycloakService.getLocaleForDate()?.toLowerCase() ||
                    KeycloakService.getLocale()
                ).formatDate
              }
              onChange={(date) => {
                changeTaskDueDate({
                  variables: {
                    taskId: event.id,
                    newDueDate: new Date(date as any).getTime(),
                  },
                }).then(() => {
                  setDate(date);
                  refetch();
                });
              }}
              children={
                <ContainerClear
                  onClick={() => {
                    changeTaskDueDate({
                      variables: {
                        taskId: event.id,
                        newDueDate: undefined,
                      },
                    }).then(() => {
                      setDate(undefined);
                      refetch();
                    });
                  }}
                >
                  <Text
                    text={t('clearDueDate')}
                    fontSize="12px"
                    lineHeight="32px"
                    fontWeight="300"
                    color={Theme.colors.cD73E18}
                  />
                </ContainerClear>
              }
              value={date}
              selected={date}
              showTimeSelect={false}
              timeFormat="HH:mm"
            />
            <SvgIcon
              svg={<Calendar />}
              color={Theme.colors.c505050}
              width="18px"
              style={{ marginLeft: '-26px', cursor: 'default' }}
            />
          </DueDateSection>
          <ExpandSection onClick={() => navigate(navigatePushString)}>
            <SvgIcon
              svg={<Expand />}
              height="16px"
              width="16px"
              color={Theme.colors.c86B7FF}
            />
            <Text
              text="detail"
              fontSize="12px"
              lineHeight="16px"
              ellipsis
              style={{ marginLeft: 'iem' }}
              color={Theme.colors.c86B7FF}
            />
          </ExpandSection>
        </Modal>
      )}
      <Container
        onClick={() => setOpen(true)}
        ref={ref}
        colorBorderLeft={colorBorderLeft(event.taskBase)}
      >
        <Text
          text={event.title}
          fontSize="12px"
          lineHeight="16px"
          ellipsis
          fontWeight="500"
          tooltipText={event.title}
        />
      </Container>
    </>
  );
};

const Container = styled.div<{ colorBorderLeft: string }>`
  height: 48px;
  padding: 8px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: 4px 0;
  cursor: pointer;

  border-left: 6px solid ${({ colorBorderLeft }) => colorBorderLeft};
`;

const Modal = styled.div<{
  right: number;
  top: number;
  colorBorderLeft: string;
}>`
  position: fixed;
  top: ${({ top }) => top - 200}px;
  right: calc(100vw - ${({ right }) => right + 30}px);
  width: 460px;
  height: 250px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  z-index: 100;
  cursor: auto;
  padding: 18px 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  border-left: 6px solid ${({ colorBorderLeft }) => colorBorderLeft};
`;

const TopSection = styled(FlexContainer)`
  height: 30px;
`;

const AssigneesSection = styled.div`
  display: flex;
  align-items: center;
`;

const DueDateSection = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 30px;
  overflow: auto;
  width: 100%;
  text-overflow: ellipsis;
  padding-right: 30px;
`;

const TextSection = styled(FlexContainer)`
  padding: 16px 0;
`;

const ContainerClear = styled.div`
  border-radius: 4px;
  text-align: right;
  padding: 2px 8px;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.cDFE1E6};
`;

const ExpandSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
`;
