import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { RoundedTextAvatar } from './RoundedTextAvatar';
import { FlexContainer } from '../layout/FlexContainer';
import { GroupIcon } from '../../svg';

interface RoundedAvatarGroupProps {
  team: { name: string; type: 'GROUP' | 'USER'; id?: string }[];
  size?: number;
  fontSize?: number;
}

export const RoundedAvatarGroup: FunctionComponent<RoundedAvatarGroupProps> = ({
  team,
  size = 24,
  fontSize = 9,
}) => (
  <Container size={size}>
    {team.map(({ type, name, id }) =>
      type === 'GROUP' ? (
        <RoundedTextAvatar
          key={id || name}
          text={name}
          size={size}
          fontSize={fontSize}
          visibleTooltip
          icon={<GroupIcon />}
          hidden={name === 'Nobody'}
        />
      ) : (
        <RoundedTextAvatar
          key={id || name}
          text={name}
          size={size}
          fontSize={fontSize}
          visibleTooltip
        />
      )
    )}
  </Container>
);

const Container = styled(FlexContainer)<{ size?: number }>`
  ${({ size }) => size && `height: ${size}px;`}
  & > div {
    border: 1px solid ${({ theme }) => theme.colors.cFFFFFF};
  }
  & > div:not(:first-child) {
    margin-left: -6px;
  }
`;
