import { Task } from '@pec-manager/graphql';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { gqlName } from '../../graphql/utils';
import { GET_TASK_BY_ID } from '../../graphql/task/queries';

export const useTask = (): {
  data: Task;
  error?: ApolloError;
  refetch: any;
  loading: boolean;
} => {
  const searchParams = new URLSearchParams(useLocation().search);
  const taskId = searchParams.get('taskId');

  const [loadGetTaskById, { data, error, loading, refetch }] = useLazyQuery(
    GET_TASK_BY_ID,
    {
      variables: {
        taskId,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (taskId) {
      loadGetTaskById();
    }
  }, [loadGetTaskById, taskId]);

  return {
    data: data ? data[gqlName(GET_TASK_BY_ID)] : undefined,
    error,
    loading,
    refetch,
  };
};
