import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BaseTask, ResourcePriority, TaskStatus } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import {
  CHANGE_TASK_DUE_DATE,
  CHANGE_TASK_PRIORITY,
} from '../../graphql/task/mutations';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { KeycloakService } from '../../services/KeycloakService';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Calendar, Clock } from '../../svg';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Choice, Select } from '../../components/select';
import { PrioritySelect } from '../TaskCalendar/EventItem';
import { HeaderDataPicker } from '../../components/common/HeaderDataPicker';
import { getDateAsString } from '../../utils/dateUtils';

/* eslint-disable react/no-children-prop */

interface DueDateSectionProps {
  taskBase: BaseTask;
}

export const DueDateSection: FunctionComponent<DueDateSectionProps> = ({
  taskBase,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(taskBase.expiresAt || undefined);

  useEffect(() => {
    if (taskBase && taskBase.expiresAt && taskBase.expiresAt !== date) {
      setDate(taskBase.expiresAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskBase]);

  const [changeTaskPriority] = useMutationHook({
    mutationGql: CHANGE_TASK_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeTaskDueDate] = useMutationHook({
    mutationGql: CHANGE_TASK_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  return (
    <Container
      wrapContent
      justifyContent="space-between"
      alignItems="flex-start"
    >
      {!KeycloakService.removeFieldInTask().includes('dueDate') && (
        <FlexContainer onClick={(e) => e.stopPropagation()} column>
          <FlexContainer alignItems="center">
            <Text
              text="dueDateReassign"
              fontSize="12px"
              lineHeight="36px"
              color={Theme.colors.c505050}
              style={{ marginRight: '8px' }}
            />
            <DatePickerStyled
              placeholderText={t('insertDate') as string}
              disabled={
                taskBase.status === TaskStatus.COMPLETED ||
                !taskBase.isManageable
              }
              locale={
                mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
              }
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <HeaderDataPicker
                  date={date}
                  decreaseMonth={decreaseMonth}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
              dateFormat={
                mappingLocaleForDatePicker(
                  KeycloakService.getLocaleForDate()?.toLowerCase() ||
                    KeycloakService.getLocale()
                ).formatDate
              }
              onChange={(date) => {
                changeTaskDueDate({
                  variables: {
                    taskId: taskBase.id,
                    newDueDate: new Date(date as any).getTime(),
                  },
                }).then(() => {
                  // @ts-ignore
                  setDate(date);
                });
              }}
              children={
                <ContainerClear
                  onClick={() => {
                    changeTaskDueDate({
                      variables: {
                        taskId: taskBase.id,
                        newDueDate: undefined,
                      },
                    }).then(() => {
                      setDate(undefined);
                    });
                  }}
                >
                  <Text
                    text={t('clearDueDate')}
                    fontSize="12px"
                    lineHeight="32px"
                    fontWeight="300"
                    color={Theme.colors.cD73E18}
                  />
                </ContainerClear>
              }
              value={date as unknown as string}
              selected={date}
              showTimeSelect={false}
              timeFormat="HH:mm"
            />
            <SvgIcon
              svg={<Calendar />}
              color={Theme.colors.c505050}
              width="18px"
              style={{ marginLeft: '-26px', cursor: 'default' }}
            />
          </FlexContainer>
          {taskBase.expiresAt &&
          (taskBase.expiresAt as unknown as number) < Date.now() ? (
            <FlexContainer alignItems="center">
              <SvgIcon
                svg={<Clock />}
                height="16px"
                width="16px"
                color={Theme.colors.cD73E18}
              />
              <Text
                style={{ marginLeft: '4px' }}
                text="expiredAt"
                translationArgs={{
                  time: getDateAsString(
                    taskBase.expiresAt as unknown as number
                  ),
                }}
                fontSize="12px"
                lineHeight="18px"
                fontWeight="300"
                color={Theme.colors.cD73E18}
              />
            </FlexContainer>
          ) : null}
        </FlexContainer>
      )}

      {!KeycloakService.removeFieldInTask().includes('priority') && (
        <RightContainer
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
        >
          <Select
            colorText={Theme.colors.c838383}
            fontSizeText="12px"
            lineHeightText="18px"
            choices={PrioritySelect.filter(
              (choice) => choice.value !== ResourcePriority.NONE
            )}
            width="150px"
            defaultChoice={
              PrioritySelect.filter((s) => s.value === taskBase.priority)[0]
            }
            disabled={!taskBase.isManageable}
            onChoiceSelected={(choice: Choice) => {
              changeTaskPriority({
                variables: {
                  taskId: taskBase.id,
                  newPriority: choice.value as ResourcePriority,
                },
              });
            }}
            height="32px"
            backgroundColor={Theme.colors.cFAFAFA}
            zIndex={1}
          />
        </RightContainer>
      )}
    </Container>
  );
};

const RightContainer = styled(FlexContainer)`
  margin-left: auto;

  @media (max-width: 990px) {
    margin-top: 8px;
    margin-left: unset;
  }
`;

const Container = styled(FlexContainer)`
  margin-bottom: 8px;
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .react-datepicker-wrapper {
    width: 150px !important;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 30px;
  overflow: auto;
  width: 100%;
  text-overflow: ellipsis;
  padding-right: 30px;
`;

const ContainerClear = styled.div`
  border-radius: 4px;
  text-align: right;
  padding: 2px 8px;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.cDFE1E6};
`;
