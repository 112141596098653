import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ContainerModal } from '../ContainerModal';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../layout/FlexContainer';
import { SvgIcon } from '../../common/SvgIcon';
import { Close } from '../../../svg';
import { Button } from '../../common/Button';
import { Input } from '../../input/Input';

interface GenericSimpleInputModalProps {
  onClickClose: () => void;
  onClickSaved: (data: string) => void;
  title: string;
  placeHolder: string;
}

export const GenericSimpleInputModal: FunctionComponent<
  GenericSimpleInputModalProps
> = ({ onClickClose, onClickSaved, placeHolder, title }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<string>('');

  return (
    <ContainerModal position="absolute">
      <Container onClick={(e) => e.stopPropagation()}>
        <ContainerHeader alignItems="center" justifyContent="space-between">
          <Text text={t(title)} fontSize="16px" lineHeight="24px" />
          <ClickableIcon onClick={() => onClickClose()}>
            <SvgIcon
              svg={<Close />}
              width="10px"
              color={Theme.colors.cFFFFFF}
            />
          </ClickableIcon>
        </ContainerHeader>
        <InnerContainer column>
          <Input
            placeholder={placeHolder ? (t(placeHolder) as string) : undefined}
            value={data}
            onChange={(v) => setData(v.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && data.trim().length > 0) {
                onClickSaved(data);
                onClickClose();
              }
            }}
          />
          <ContainerButtons alignItems="center">
            <ButtonSearch
              disabled={data.trim().length <= 0}
              onClick={() => {
                onClickSaved(data);
                onClickClose();
              }}
              backgroundColor={Theme.colors.c5B84EF}
              hoverBackgroundColor={Theme.colors.c4C9AFF}
              padding="6px 14px"
              borderRadius="8px"
            >
              <Text
                text="save"
                fontSize="14px"
                lineHeight="24px"
                fontWeight="600"
                color={Theme.colors.cFFFFFF}
              />
            </ButtonSearch>
          </ContainerButtons>
        </InnerContainer>
      </Container>
    </ContainerModal>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  width: 60%;
  max-width: 940px;
`;

const ContainerHeader = styled(FlexContainer)`
  height: 70px;
  border-radius: 12px 12px 0 0;
  opacity: 0.98;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 24px;
`;

const InnerContainer = styled(FlexContainer)`
  padding: 24px 32px 24px 24px;
  height: calc(100% - 70px);
`;

const ContainerButtons = styled(FlexContainer)`
  margin-top: auto;
  margin-left: auto;
`;

const ClickableIcon = styled.div`
  min-height: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.c838383};
`;

const ButtonSearch = styled(Button)`
  margin-left: 10px;
  margin-top: 12px;
`;
