import React, { FunctionComponent } from 'react';
import { Text } from '../../../components/common/Text';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { AttachmentItem } from './AttachmentItem';
import { AttachmentItemMobile } from './AttachmentItemMobile';
import { BaseDocument } from '@pec-manager/graphql';
import { extractTypeToMimetype } from '../../../components/common/AttachmentPreview/extractThumbnail';
import { useNavigate, useParams } from 'react-router-dom';
import { Theme } from '../../../theme';

interface DocumentLinkedSectionProps {
  linkedDocuments: BaseDocument[];
}

export const DocumentLinkedSection: FunctionComponent<
  DocumentLinkedSectionProps
> = ({ linkedDocuments }) => {
  const navigate = useNavigate();
  const { appId } = useParams();

  return (
    <Container column>
      <Text text="linkedDocument" fontSize="14px" lineHeight="17px" />
      {linkedDocuments.length > 0 &&
        linkedDocuments.map(
          ({ metadata, blobRef, id, versionNumber, name }) => {
            return blobRef ? (
              <>
                <AttachmentItem
                  key={id}
                  file={blobRef}
                  title={`${name}${
                    versionNumber ? `- Version ${versionNumber}` : ''
                  }`}
                  label={extractTypeToMimetype(blobRef.mimeType)}
                  user={metadata.modifiedBy || metadata.createdBy}
                  navigateTo={() =>
                    navigate(`/${appId}/sintropi/document?documentId=${id}`)
                  }
                />
                <AttachmentItemMobile
                  key={id}
                  file={blobRef}
                  title={`${name}${
                    versionNumber ? `- Version ${versionNumber}` : ''
                  }`}
                  label={extractTypeToMimetype(blobRef.mimeType)}
                  user={metadata.modifiedBy || metadata.createdBy}
                  navigateTo={() =>
                    navigate(`/${appId}/sintropi/document?documentId=${id}`)
                  }
                />
              </>
            ) : null;
          }
        )}
      {linkedDocuments.length === 0 && (
        <TextStyled
          text="noDocumentsLinked"
          fontSize="10px"
          lineHeight="16px"
          color={Theme.colors.c505050}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;

  & > span {
    margin-bottom: 4px;
  }

  & > div {
    margin-top: 12px;
    width: 100%;
  }
`;

const TextStyled = styled(Text)`
  margin-top: 12px;
`;
