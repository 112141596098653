import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface AroundLoaderProps {
  style?: React.CSSProperties;
}

export const AroundLoader: FunctionComponent<AroundLoaderProps> = ({
  style,
}) => (
  <Spinner style={style}>
    <div />
    <div />
    <div />
    <div />
  </Spinner>
);

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  & > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 28px;
    height: 28px;
    border: 4px solid ${({ theme }) => theme.colors.c5B84EF};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.colors.c5B84EF} transparent transparent
      transparent;
  }
  & > div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & > div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & > div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
