import React, { FunctionComponent, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { transformSecondInDayHoursMinutes } from '../../utils/dateUtils';
import { ProcessPerformanceKpiResponse } from '@pec-manager/graphql';

interface CandlestickChartProps {
  processPerformance: ProcessPerformanceKpiResponse;
  width: number | string;
}

export const CandlestickChart: FunctionComponent<CandlestickChartProps> = ({
  processPerformance,
  width,
}) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'candlestick',
      id: 'basic-bar',
    },
    xaxis: {
      type: 'category',
    },
    yaxis: {
      labels: {
        padding: 10,
        // @ts-ignore
        formatter: (value: number) => {
          // @ts-ignore
          return transformSecondInDayHoursMinutes(value);
        },
      },
    },
    tooltip: {
      // @ts-ignore
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex];
        const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex];
        const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex];
        const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex];
        return (
          '<div class="apexcharts-tooltip-candlestick paddingChartCandlestick" >' +
          '<div>Average: <span class="value">' +
          transformSecondInDayHoursMinutes((o + c) / 2) +
          '</span></div>' +
          '<div>Min: <span class="value">' +
          transformSecondInDayHoursMinutes(l) +
          '</span></div>' +
          '<div>Max: <span class="value">' +
          transformSecondInDayHoursMinutes(h) +
          '</span></div>' +
          '</div>'
        );
      },
    },
  };
  const series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined =
    useMemo(() => {
      if (!processPerformance?.processLeadTimeKpi.samples) {
        return undefined;
      }
      return [
        {
          type: 'candlestick',
          data: processPerformance.processLeadTimeKpi.samples.map((sample) => {
            return {
              x: sample.label,
              y: [
                sample.value.avg - sample.value.stdDev,
                sample.value.max,
                sample.value.min,
                sample.value.avg + sample.value.stdDev,
              ],
            };
          }),
        },
      ];
    }, [processPerformance.processLeadTimeKpi]);

  return (
    <Chart
      options={options}
      series={series}
      type="candlestick"
      width={width}
      height={400}
    />
  );
};
