import React, { FC } from 'react';
import styled from 'styled-components';

interface AddAssigneeProps {
  onClickAdd: () => void;
  firstElement?: boolean;
  showDynamicSelectResult: boolean;
  widthPlus?: string;
  heightPlus?: string;
}

export const AddAssignee: FC<AddAssigneeProps> = ({
  onClickAdd,
  firstElement,
  showDynamicSelectResult,
  widthPlus,
  heightPlus,
}) => {
  return (
    <Container
      onClick={onClickAdd}
      firstElement={firstElement}
      transformText={showDynamicSelectResult}
      widthPlus={widthPlus}
      heightPlus={heightPlus}
    >
      +
    </Container>
  );
};

const Container = styled.div<{
  firstElement?: boolean;
  transformText?: boolean;
  widthPlus?: string;
  heightPlus?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.cA5ADBA};
  border: 1px solid ${({ theme }) => theme.colors.cFFFFFF};
  color: ${({ theme }) => theme.colors.cFFFFFF};
  font-size: 16px;
  width: ${({ widthPlus }) => widthPlus || '24px'};
  height: ${({ heightPlus }) => heightPlus || '24px'};
  border-radius: 50%;
  ${({ firstElement }) => firstElement && 'margin-left: -6px;'}
  z-index: 1;
  transform: rotate(0deg);
  transition: all 0.5s ease-out;
  ${({ transformText }) => transformText && 'transform: rotate(45deg);'};
  cursor: pointer;
`;
