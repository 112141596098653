import React, { FC } from 'react';
import { calcValueRecord } from '../ItemRecordList/utils';
import { trasformStringInUppercaseForRecord } from '../../../utils/stringUtils';
import { Theme } from '../../../theme';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Close, Success } from '../../../svg';
import { Text } from '../../../components/common/Text';
import { Circle } from './CustomSectionRecord';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';

interface ItemProps {
  name: string;
  value: any;
  columns: any;
}

export const ItemCustomSection: FC<ItemProps> = ({ value, name, columns }) => {
  const _value = calcValueRecord(value);
  const iconStatusTrue = _value.toLowerCase() === 'true';
  const iconStatusFalse = _value.toLowerCase() === 'false';
  const notIcon = !(iconStatusTrue || iconStatusFalse);

  return (
    <Item
      key={name}
      column
      fullWidth={columns.nested?.length === 1 || columns === 'true'}
    >
      <ItemTitle
        text={trasformStringInUppercaseForRecord(name)}
        fontSize="12px"
        fontWeight="300"
        lineHeight="12px"
        skipTranslation
        color={Theme.colors.c505050}
      />
      {iconStatusTrue && (
        <Circle color={Theme.colors.cFFFFFF} sizeCircle="20px" marginLeft="8px">
          <SvgIcon
            svg={<Success />}
            width="22px"
            color={Theme.colors.c52C41A}
          />
        </Circle>
      )}
      {iconStatusFalse && (
        <Circle color={Theme.colors.cEB5757} sizeCircle="20px" marginLeft="8px">
          <SvgIcon svg={<Close />} width="12px" color={Theme.colors.cFFFFFF} />
        </Circle>
      )}
      {notIcon && (
        <Text
          text={_value}
          fontSize="12px"
          color={Theme.colors.c000000}
          lineHeight="12px"
          possibleLink
          skipTranslation
          breakWord
          style={{
            paddingLeft: '8px',
            whiteSpace: 'pre-wrap',
          }}
        />
      )}
    </Item>
  );
};

const Item = styled(FlexContainer)<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '300px')};
  padding-bottom: 24px;
  margin-right: 24px;
`;

const ItemTitle = styled(Text)`
  margin: 8px;
`;
