import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ChatContainerHeader } from './ChatContainerHeader';

interface Props {
  children?: React.ReactNode;
}

export const ChatContainer: FunctionComponent<Props> = ({ children }) => (
  <div style={{ position: 'relative', width: '100%' }}>
    <BackgroundBorderTop />
    <Container column>
      <ChatContainerHeader />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  </div>
);

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 6px 0 0 0;
  overflow: hidden;
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  @media (max-width: 990px) {
    border-radius: 0;
  }
`;

const ChildrenContainer = styled(FlexContainer)`
  height: calc(100% - 80px);
  @media (max-width: 990px) {
    width: 100%;
    height: calc(100% - 130px);
  }
`;

const BackgroundBorderTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.c212832};
`;
