import { gql } from '@apollo/client/core';
import { gqlResourceContextShape } from './shapes';

export const GET_RESOURCE_CONTEXT = gql`
    query GetResourceContext($resourceId: ID!, $resourceType: NotificationResourceType!) {
        getResourceContext(resourceId: $resourceId, resourceType: $resourceType) {
            context {
                ${gqlResourceContextShape}
            }
        }
    }
`;
