import React, { FunctionComponent } from 'react';
import { FormFieldDefinition } from '@pec-manager/graphql';
import { FormFieldContainer } from '../modals/FormModal/FormFieldContainer';
import styled from 'styled-components';
import { FlexContainer } from '../layout/FlexContainer';
import { RoundedTextAvatar } from '../avatar/RoundedTextAvatar';
import { Text } from './Text';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { Color, Theme } from '../../theme';
import { extractFullName, isToday } from '../../utils/stringUtils';
import { Close, Success } from '../../svg';
import { SvgIcon } from './SvgIcon';
import { useTranslation } from 'react-i18next';

interface ApprovalFieldProps {
  formField: FormFieldDefinition;
  columnForm?: number;
}

export const ApprovalField: FunctionComponent<ApprovalFieldProps> = ({
  formField,
  columnForm,
}) => {
  const { t } = useTranslation();
  return (
    <FormFieldContainer
      formField={formField}
      style={{ maxHeight: '330px', overflow: 'auto' }}
      columnForm={columnForm}
    >
      {formField.defaultValues.length > 0 &&
        formField.defaultValues.map(({ kind }) => (
          <ContainerApprovalValues justifyContent="center" column>
            <TopContainer alignItems="center">
              {kind.approval?.isApproval ? (
                <Circle color={Theme.colors.cFFFFFF} sizeCircle="20px">
                  <SvgIcon
                    svg={<Success />}
                    width="20px"
                    color={Theme.colors.c52C41A}
                  />
                </Circle>
              ) : (
                <Circle color={Theme.colors.cEB5757} sizeCircle="20px">
                  <SvgIcon
                    svg={<Close />}
                    width="12px"
                    color={Theme.colors.cFFFFFF}
                  />
                </Circle>
              )}
              <Text
                text={kind.approval!.actionName}
                fontSize="12px"
                lineHeight="20px"
                fontWeight="500"
                style={{ marginLeft: '4px', marginRight: '8px' }}
              />
              <Text
                text={
                  isToday(kind.approval?.at as any)
                    ? t('todayAt')
                    : t('completeDate')
                }
                translationArgs={{
                  date: getDateAsString(kind.approval?.at as any),
                  time: getTimeAsString(kind.approval?.at as any),
                  fresh: true,
                }}
                fontSize="12px"
                lineHeight="20px"
                ellipsis
                color={Theme.colors.c838383}
                style={{ marginLeft: '8px' }}
              />
            </TopContainer>
            <FlexContainer column style={{ marginLeft: '30px' }}>
              <UserContainer>
                <RoundedTextAvatar
                  text={extractFullName(kind.approval!.approver)}
                  fontSize={8}
                  visibleTooltip
                  size={18}
                />
                <Text
                  text={extractFullName(kind.approval!.approver)}
                  fontSize="12px"
                  lineHeight="20px"
                  ellipsis
                  style={{ marginRight: '12px', marginLeft: '4px' }}
                />
              </UserContainer>
              <BottomContainer>
                {kind.approval?.comment ? (
                  <>
                    <Text
                      text={'commentWithColon'}
                      fontSize="12px"
                      lineHeight="20px"
                      fontWeight="500"
                      style={{ marginTop: '4px' }}
                      color={Theme.colors.c838383}
                    />
                    <Text
                      text={kind.approval!.comment!}
                      fontSize="12px"
                      lineHeight="20px"
                      color={Theme.colors.c838383}
                      style={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        marginTop: '4px',
                      }}
                    />
                  </>
                ) : (
                  <Text
                    text={'noComment'}
                    fontSize="12px"
                    lineHeight="20px"
                    style={{
                      marginRight: '8px',
                      marginTop: '4px',
                    }}
                    color={Theme.colors.c838383}
                  />
                )}
              </BottomContainer>
            </FlexContainer>
          </ContainerApprovalValues>
        ))}
    </FormFieldContainer>
  );
};

const ContainerApprovalValues = styled(FlexContainer)`
  border-bottom: 1px solid ${Theme.colors.transparentGrey};
`;

const UserContainer = styled(FlexContainer)``;

const TopContainer = styled(FlexContainer)`
  height: 40px;
`;

const BottomContainer = styled(FlexContainer)`
  height: 40px;
`;

const Circle = styled.div<{ color: string | Color; sizeCircle?: string }>`
  background-color: ${({ color }) => color};
  height: ${({ sizeCircle }) => sizeCircle};
  width: ${({ sizeCircle }) => sizeCircle};
  min-height: ${({ sizeCircle }) => sizeCircle};
  min-width: ${({ sizeCircle }) => sizeCircle};
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
