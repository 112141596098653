import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../layout/FlexContainer';

interface ContainerModalProps {
  position?: string;
  zIndex?: number;
  children?: React.ReactNode;
}

export const ContainerModal: FunctionComponent<ContainerModalProps> = ({
  children,
  position,
  zIndex,
}) => (
  <Container
    alignItems="center"
    justifyContent="center"
    position={position}
    zIndex={zIndex}
  >
    {children}
  </Container>
);

const Container = styled(FlexContainer)<{ position?: string; zIndex?: number }>`
  height: 100vh;
  width: 100vw;
  z-index: ${({ zIndex }) => zIndex ?? 10};
  background: rgba(104, 104, 104, 0.3);
  backdrop-filter: blur(28px);
  position: ${({ position }) => position ?? 'absolute'};
  top: 0;
  left: 0;

  @media (max-width: 990px) {
    height: calc(var(--vh, 1vh) * 100);
  }
`;
