import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { DocumentNode } from 'graphql';
import {
  pushNotificationBannerAction,
  removeNotificationBannerAction,
} from '../../redux/notification/sidebar/actions';
import { NotificationBanner } from '../../redux/notification/sidebar/reducers';
import { gqlName } from '../../graphql/utils';

export const UseSubmitForm = ({
  errorMessage,
  successMessage,
  mutationGql,
}: {
  mutationGql: DocumentNode;
  successMessage?: string;
  errorMessage?: string;
}): any => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [mutationFunction, { loading, error, data }] = useMutation(mutationGql);

  const dispatchNotificationBanner = (notification: NotificationBanner) => {
    dispatch(pushNotificationBannerAction(notification));
    setTimeout(
      () => dispatch(removeNotificationBannerAction(notification)),
      3000
    );
  };

  useEffect(() => {
    if (errorMessage && error) {
      dispatchNotificationBanner({
        id: uuidv4(),
        title: t(errorMessage),
        type: 'failed',
      });
    }
    if (successMessage && data) {
      if (
        data[gqlName(mutationGql)].pagedFormDefinition?.formDefinition?.errors
          .length > 0 ||
        data[
          gqlName(mutationGql)
        ].pagedFormDefinition?.formDefinition?.fieldGroups.some(
          (fieldGroup: any) =>
            fieldGroup.fields.some((field: any) => field.errors.length > 0)
        )
      ) {
        dispatchNotificationBanner({
          id: uuidv4(),
          title: t('somethingWentWrongs'),
          type: 'failed',
        });
      } else {
        dispatchNotificationBanner({
          id: uuidv4(),
          title: t(successMessage),
          type: 'success',
        });
      }
    }
    // eslint-disable-next-line
  }, [error, data]);

  return [mutationFunction, { data, loading }];
};
