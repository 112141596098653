import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from 'react';
import styled from 'styled-components';
import { Mail } from '@pec-manager/graphql';
import { TopSection } from './TopSection';
import { FromSection } from './FromSection';
import { TitleSection } from './TitleSection';
import { Theme } from '../../theme';
import { AttachmentsSection } from './AttachmentsSection';
import { CustomSection } from './CustomSection';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { ActionSection } from './ActionSection';
import { ModalTypeInbox } from './index';
import { ActivitySection } from './ActivitySection';
import { AssigneesSection } from '../mail/MailItem/AssigneesSection';

interface MailDetailsProps {
  mail: Mail;
  seeOther: boolean;
  setModalType: Dispatch<SetStateAction<ModalTypeInbox | undefined>>;
  clickOnClose?: () => void;
}

export const MailDetails: FunctionComponent<MailDetailsProps> = ({
  mail,
  seeOther,
  setModalType,
  clickOnClose,
}) => {
  const [sectionSeeOther, setSectionSeeOther] = useState(false);

  return (
    <MailContainer column>
      <TopSection
        textTag={mail.base.category}
        receivedAt={mail.base.receivedAt as any as number}
        clickOnClose={clickOnClose}
      />
      <FromSection
        from={mail.base.sender}
        receivedAt={
          clickOnClose ? (mail.base.receivedAt as any as number) : undefined
        }
      />
      <TitleSection
        mailId={mail.base.id}
        title={mail.base.subject}
        priority={mail.base.priority}
      />
      {mail.base.assignees.length > 0 && (
        <AssigneesSection mailBase={mail.base} />
      )}
      <DescriptionSection
        text={mail.base.body}
        skipTranslation
        lineHeight="24px"
        fontSize="16px"
        color={Theme.colors.c505050}
      />
      {mail.attachments.length > 0 && (
        <AttachmentsSection
          attachments={mail.attachments}
          lastSection={mail.outputSections.length === 0}
        />
      )}
      {(!seeOther || sectionSeeOther) && (
        <>
          {mail.outputSections.length > 0 &&
            mail.outputSections.map((output) => (
              <CustomSection key={output.name} output={output} />
            ))}
          <ActivitySection
            mailId={mail.base.id}
            mailSubject={mail.base.subject}
          />
          <ActionSection
            actions={mail.actions}
            setModalType={setModalType}
            mailId={mail.base.id}
          />
        </>
      )}
      {seeOther && (
        <ContainerBottom sectionSeeOther={sectionSeeOther}>
          <SectionSeeAll
            onClick={() => setSectionSeeOther((prevState) => !prevState)}
          >
            <Text
              text={sectionSeeOther ? 'hide' : 'seeOther'}
              fontSize="16px"
              lineHeight="24px"
              color={Theme.colors.c0E53B7}
            />
          </SectionSeeAll>
        </ContainerBottom>
      )}
    </MailContainer>
  );
};

const MailContainer = styled(FlexContainer)`
  width: 100%;
  border-radius: 12px;
  box-shadow: 3px 1px 20px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 16px 24px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};

  @media (max-width: 1040px) {
    overflow: auto;
  }
`;

const DescriptionSection = styled(Text)`
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const SectionSeeAll = styled.div`
  margin-left: auto;
  margin-top: 16px;
  cursor: pointer;
`;

const ContainerBottom = styled.div<{ sectionSeeOther?: boolean }>`
  ${({ sectionSeeOther, theme }) =>
    sectionSeeOther &&
    `
     margin-top: 20px;
     border-top: 1px solid ${theme.colors.cDCDCDC};
    `};
  display: flex;
  width: 100%;
`;
