import React, { FunctionComponent } from 'react';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { RoundedAvatarGroup } from '../../../components/avatar/RoundedAvatarGroup';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import styled from 'styled-components';
import { BaseMail } from '@pec-manager/graphql';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Clock } from '../../../svg';
import { getDateAsString } from '../../../utils/dateUtils';

interface AssigneesSectionProps {
  mailBase: BaseMail;
}

export const AssigneesSection: FunctionComponent<AssigneesSectionProps> = ({
  mailBase,
}) => {
  const assignee =
    mailBase.inCharge &&
    `${mailBase.inCharge.firstName} ${mailBase.inCharge.lastName}`;

  const groupName: {
    name: string;
    type: 'GROUP' | 'USER';
  }[] = mailBase.assignees.map(({ group, user }) =>
    group?.name
      ? { name: group?.name, type: 'GROUP' }
      : { name: `${user?.firstName} ${user?.lastName}`, type: 'USER' }
  );

  const handleRoundedAvatar: () => {
    name: string;
    type: 'GROUP' | 'USER';
  }[] = () => {
    if (assignee) {
      return [{ name: assignee, type: 'USER' }];
    }
    return groupName;
  };

  return (
    <Container justifyContent="space-between" alignItems="center">
      <ItemContainer alignItems="center" marginRight="8px">
        {assignee ? (
          <Text
            text="takeInChargeTo"
            fontSize="10px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
        ) : (
          <Text
            text="assignedToBottomList"
            fontSize="10px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
          /* <>
            <Text
              text="notAssigned"
              fontSize="10px"
              lineHeight="14px"
              fontWeight="300"
              color={Theme.colors.c838383}
            />
            <ContainerTakeInCharge
              onClick={() => {
                history(`/inbox/takeincharge${historyPushString}`);
              }}
            >
              <Text
                text="takeCharge"
                fontSize="10px"
                lineHeight="14px"
                fontWeight="600"
                color={Theme.colors.c2186C6}
              />
            </ContainerTakeInCharge>
          </> */
        )}
        <RoundedAvatarGroup team={handleRoundedAvatar()} />
      </ItemContainer>
      <ItemContainer alignItems="center" marginLeft="4px">
        <SvgIcon
          svg={<Clock />}
          height="12px"
          width="11px"
          color={
            (mailBase.expiresAt as any as number) < Date.now()
              ? Theme.colors.cD73E18
              : Theme.colors.c9F9F9F
          }
        />
        {mailBase.expiresAt && (
          <Text
            text={
              (mailBase.expiresAt as any as number) < Date.now()
                ? 'expiredAt'
                : 'expiringAt'
            }
            translationArgs={{
              time: getDateAsString(mailBase.expiresAt as any as number),
            }}
            fontSize="12px"
            lineHeight="18px"
            fontWeight="300"
            color={
              (mailBase.expiresAt as any as number) < Date.now()
                ? Theme.colors.cD73E18
                : Theme.colors.c505050
            }
          />
        )}
      </ItemContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 12px;
`;

const ItemContainer = styled(FlexContainer)<{
  marginRight?: string;
  marginLeft?: string;
}>`
  & > span {
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  }
`;
