import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { MailSummary } from '@pec-manager/graphql';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { TopSection } from './TopSection';
import { TitleSection } from './TitleSection';
import { AttachmentsCommentsSection } from './AttachmentsCommentsSection';
import { FromSection } from './FromSection';
import { Theme } from '../../../theme';
import { BottomSection } from './BottomSection';
import { CorrelatedMailsSection } from './CorrelatedMailsSection';
import { MAILS_PAGE_SIZE } from '../../../constant';

export const MailItem: FunctionComponent<{
  mail: MailSummary;
  index: number;
}> = ({ mail, index }) => {
  const [hideCorrelatedSection, setHideCorrelatedSection] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSelected = mail.base.id === searchParams.get('mailId');
  const dropDownId = searchParams.get('dropDownId');
  const { appId } = useParams();

  const dropDownIdQueryParam = dropDownId
    ? `&dropDownId=${searchParams.get('dropDownId')}`
    : '';

  const historyPushString =
    location.pathname === `/${appId}/inbox/search`
      ? `/${appId}/inbox/search?mailId=${mail.base.id}`
      : `/${appId}/inbox?sectionId=${searchParams.get(
          'sectionId'
        )}&elementId=${searchParams.get(
          'elementId'
        )}${dropDownIdQueryParam}&mailId=${mail.base.id}`;

  return (
    <motion.div
      initial={{
        y: index <= MAILS_PAGE_SIZE ? -10 * (index + 1) : 0,
        opacity: 0,
      }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: index <= 7 ? 0.05 * (index + 1) : 0.1,
        type: 'tween',
      }}
    >
      <ContainerMail column selected={isSelected}>
        <PaddingContainer
          column
          onClick={() => {
            navigate(historyPushString);
          }}
        >
          <TopSection
            textCategory={mail.base.category}
            receivedAt={mail.base.receivedAt as any as number}
            archiveWalkPath={mail.base.archiveWalkPath}
          />
          <FromSection from={mail.base.sender} />
          <TitleSection
            title={mail.base.subject}
            priority={mail.base.priority}
            setHideCorrelatedSection={setHideCorrelatedSection}
            hideCorrelatedSection={hideCorrelatedSection}
            showSvgArrow={mail.base.canChangePriority}
          />
          <DescriptionSection
            text={mail.base.body}
            skipTranslation
            lineHeight="18px"
            fontSize="12px"
            color={Theme.colors.c505050}
          />
          <AttachmentsCommentsSection
            numberOfAttachments={mail.attachmentCount}
            numberOfComments={mail.commentCount}
          />
        </PaddingContainer>
        <AnimatePresence>
          {!hideCorrelatedSection && (
            <CorrelatedMailsSection correlatedMailList={mail.correlatedMails} />
          )}
        </AnimatePresence>
        {mail.base.assignees.length > 0 && (
          <BottomSection
            expiresAt={mail.base.expiresAt as any as number}
            selected={isSelected}
            assignee={
              mail.base.inCharge &&
              `${mail.base.inCharge.firstName} ${mail.base.inCharge.lastName}`
            }
            groupName={mail.base.assignees.map(({ group, user }) =>
              group?.name
                ? { name: group?.name, type: 'GROUP' }
                : { name: `${user?.firstName} ${user?.lastName}`, type: 'USER' }
            )}
            hideCorrelateSection={hideCorrelatedSection}
            /*     historyPushString={`?sectionId=${searchParams.get(
            'sectionId'
          )}&elementId=${searchParams.get(
            'elementId'
          )}${dropDownIdQueryParam}&mailId=${mail.base.id}`} */
          />
        )}
      </ContainerMail>
    </motion.div>
  );
};

const ContainerMail = styled(FlexContainer)<{ selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFFFFFF};
  max-width: 455px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  @media (max-width: 1040px) {
    max-width: 100%;
  }
`;

const PaddingContainer = styled(FlexContainer)`
  padding: 12px 16px 0 16px;
`;

const DescriptionSection = styled(Text)`
  max-width: calc(100% - 23px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;
