import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  BaseExecution,
  ExecutionStatus,
  ResourcePriority,
} from '@pec-manager/graphql';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { mappingLocaleForDatePicker } from '../../../utils/locale';
import { KeycloakService } from '../../../services/KeycloakService';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Calendar, Clock } from '../../../svg';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Choice, Select } from '../../../components/select';
import { PrioritySelect } from '../../TaskCalendar/EventItem';
import { HeaderDataPicker } from '../../../components/common/HeaderDataPicker';
import {
  CHANGE_EXECUTION_DUE_DATE,
  CHANGE_EXECUTION_PRIORITY,
} from '../../../graphql/execution/mutations';
import { getDateAsString } from '../../../utils/dateUtils';

/* eslint-disable react/no-children-prop */

interface DueDateSectionProps {
  executionBase: BaseExecution;
}

export const DueDateSectionExecution: FunctionComponent<
  DueDateSectionProps
> = ({ executionBase }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(executionBase.dueDate || undefined);
  const ref = useRef(null);

  useEffect(() => {
    if (
      executionBase &&
      executionBase.dueDate &&
      executionBase.dueDate !== date
    ) {
      setDate(executionBase.dueDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executionBase]);

  const [changeExecutionPriority] = useMutationHook({
    mutationGql: CHANGE_EXECUTION_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeExecutionDueDate] = useMutationHook({
    mutationGql: CHANGE_EXECUTION_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  return (
    <Container wrapContent alignItems="center" justifyContent="space-between">
      <FlexContainer onClick={(e) => e.stopPropagation()} alignItems="center">
        <Text
          text="dueDateReassign"
          fontSize="12px"
          lineHeight="36px"
          color={Theme.colors.c505050}
          style={{ marginRight: '8px' }}
        />
        <DatePickerStyled
          placeholderText={t('insertDate') as string}
          disabled={
            executionBase.status === ExecutionStatus.COMPLETED ||
            !executionBase.isManageable
          }
          locale={
            mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
          }
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderDataPicker
              date={date}
              decreaseMonth={decreaseMonth}
              changeMonth={changeMonth}
              changeYear={changeYear}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          dateFormat={
            mappingLocaleForDatePicker(
              KeycloakService.getLocaleForDate()?.toLowerCase() ||
                KeycloakService.getLocale()
            ).formatDate
          }
          onChange={(date) => {
            changeExecutionDueDate({
              variables: {
                executionId: executionBase.id,
                newDueDate: new Date(date as any).getTime(),
              },
            }).then(() => {
              // @ts-ignore
              setDate(date);
            });
          }}
          children={
            <ContainerClear
              onClick={() => {
                changeExecutionDueDate({
                  variables: {
                    executionId: executionBase.id,
                    newDueDate: undefined,
                  },
                }).then(() => {
                  setDate(undefined);
                });
              }}
            >
              <Text
                text={t('clearDueDate')}
                fontSize="12px"
                lineHeight="32px"
                fontWeight="300"
                color={Theme.colors.cD73E18}
              />
            </ContainerClear>
          }
          value={date as unknown as string}
          selected={date}
          showTimeSelect={false}
          timeFormat="HH:mm"
        />
        <SvgIcon
          svg={<Calendar />}
          color={Theme.colors.c505050}
          width="18px"
          style={{ marginLeft: '-26px', cursor: 'default' }}
        />
        {executionBase.dueDate &&
        (executionBase.dueDate as unknown as number) < Date.now() ? (
          <FlexContainer alignItems="center" style={{ marginLeft: '16px' }}>
            <SvgIcon
              svg={<Clock />}
              height="16px"
              width="16px"
              color={Theme.colors.cD73E18}
            />
            <Text
              style={{ marginLeft: '4px' }}
              text="expiredAt"
              translationArgs={{
                time: getDateAsString(
                  executionBase.dueDate as unknown as number
                ),
              }}
              fontSize="12px"
              lineHeight="18px"
              fontWeight="300"
              color={Theme.colors.cD73E18}
            />
          </FlexContainer>
        ) : null}
      </FlexContainer>
      <RightContainer
        alignItems="center"
        onClick={(e) => e.stopPropagation()}
        ref={ref}
      >
        <Select
          colorText={Theme.colors.c838383}
          fontSizeText="12px"
          lineHeightText="18px"
          choices={PrioritySelect.filter(
            (choice) => choice.value !== ResourcePriority.NONE
          )}
          width="150px"
          defaultChoice={
            PrioritySelect.filter((s) => s.value === executionBase.priority)[0]
          }
          disabled={!executionBase.isManageable}
          onChoiceSelected={(choice: Choice) => {
            changeExecutionPriority({
              variables: {
                executionId: executionBase.id,
                newPriority: choice.value as ResourcePriority,
              },
            });
          }}
          height="32px"
          backgroundColor={Theme.colors.cFAFAFA}
          zIndex={1}
        />
      </RightContainer>
    </Container>
  );
};

const RightContainer = styled(FlexContainer)`
  margin-left: auto;

  @media (max-width: 990px) {
    margin-top: 8px;
    margin-left: unset;
  }
`;

const Container = styled(FlexContainer)`
  margin-bottom: 8px;
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 30px;
  overflow: auto;
  width: 100%;
  text-overflow: ellipsis;
  padding-right: 30px;
`;

const ContainerClear = styled.div`
  border-radius: 4px;
  text-align: right;
  padding: 2px 8px;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.cDFE1E6};
`;
