import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  FormFieldDefinition,
  FormViewMode,
  GenericSelectViewType,
} from '@pec-manager/graphql';
import { DynamicMultipleSelectTextField } from '../modals/FormModal/DynamicMultipleSelectTextField';
import { RadioButtonSelect } from '../modals/FormModal/RadioButtonSelect';

interface GenericFormSelectProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  viewMode?: FormViewMode;
  checkRequired?: boolean;
  columnForm?: number;
}

export const GenericFormSelect: FunctionComponent<GenericFormSelectProps> = ({
  formField,
  fillField,
  errorMessage,
  viewMode,
  checkRequired = false,
  columnForm,
}) => {
  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage, formField]);

  return (
    <>
      {formField.kind?.genericSelect?.viewType ===
      GenericSelectViewType.SELECT ? (
        <DynamicMultipleSelectTextField
          formField={formField}
          errorMessage={errorMessageDate}
          fillField={fillField}
          arrowIcon={formField.isEditable}
          onChangeValue={() => {
            setErrorMessageDate(undefined);
          }}
          checkRequired={checkRequired}
          columnForm={columnForm}
        />
      ) : (
        <RadioButtonSelect
          checkbox={
            formField.kind?.genericSelect?.viewType ===
            GenericSelectViewType.CHECKBOX
          }
          onChangeValue={() => {
            setErrorMessageDate(undefined);
          }}
          formField={formField}
          fillField={fillField}
          errorMessage={errorMessageDate}
          checkRequired={checkRequired}
          columnForm={columnForm}
        />
      )}
    </>
  );
};
