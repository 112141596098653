import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Text } from '../common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../layout/FlexContainer';

export type ChoicesForMenu = {
  text: string;
  onClick: () => void;
  colorText?: string;
  translationArgs?: any;
}[];

interface MenuChoicesProps {
  choices: ChoicesForMenu;
  containerMenuProps?: ContainerMenuProps;
  onClickOutside: () => void;
}

export const MenuChoices: FunctionComponent<MenuChoicesProps> = ({
  choices,
  containerMenuProps,
  onClickOutside,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <ContainerMenu
      ref={ref}
      top={containerMenuProps?.top}
      bottom={containerMenuProps?.bottom}
      right={containerMenuProps?.right}
      left={containerMenuProps?.left}
      maxWidth={containerMenuProps?.maxWidth}
    >
      {choices.map((c) => (
        <SingleOption
          key={`${c.text}${c.translationArgs?.name || ''}`}
          onClick={c.onClick}
        >
          <ContainerEllipsis>
            <Text
              color={c.colorText ?? Theme.colors.c838383}
              text={c.text}
              translationArgs={c.translationArgs}
              fontSize="14px"
              lineHeight="21px"
            />
          </ContainerEllipsis>
        </SingleOption>
      ))}
    </ContainerMenu>
  );
};

interface ContainerMenuProps {
  maxWidth?: string;
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  position?: string;
}

const ContainerMenu = styled(FlexContainer)<ContainerMenuProps>`
  z-index: 3;
  min-width: 120px;
  position: absolute;
  ${({ top }) => top && `top: ${top};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ left }) => left && `left: ${left};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.1), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  overflow: hidden;
`;

const SingleOption = styled.div`
  padding: 0 13px;
  display: flex;
  width: 100%;
  align-items: center;
  height: 35px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.cF0F0F0};
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  &:last-child {
    border-bottom: 0;
  }
`;

const ContainerEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 35px;
`;
