import React from 'react';
import styled from 'styled-components';
import { RoundedTextAvatar } from '../avatar/RoundedTextAvatar';
import { Theme } from '../../theme';
import { Text } from './Text';
import { FlexContainer } from '../layout/FlexContainer';

interface BubbleProps {
  name: string;
  index: number;
  isSelected: boolean;
}

export const Bubble: React.FunctionComponent<BubbleProps> = ({
  name,
  index,
  isSelected,
}) => (
  <ContainerItem alignItems="center">
    <RoundedTextAvatar
      backgroundColor={isSelected ? Theme.colors.c52C41A : Theme.colors.c7A869A}
      text={`${index + 1}`}
      size={24}
      fontSize={14}
    />
    <Text
      text={name}
      fontSize="14px"
      lineHeight="14px"
      color={Theme.colors.c505050}
    />
  </ContainerItem>
);

const ContainerItem = styled(FlexContainer)`
  margin-right: 24px;

  & > div:first-child {
    margin-right: 8px;
  }
`;
