import React from 'react';
import {
  DocPagesTxt,
  DocxDocTxtPagesDocument,
  HtmlPhpCssXml,
  HttpCssXmlPhpDocument,
  JsApkJson,
  JsApkJsonDocument,
  Mp4AviMov,
  Mp4AviMovWmaWavDocument,
  NoAttachmentPreview,
  PdfEpub,
  PdfEpubDocument,
  PngJpg,
  PngJpgJpegDocument,
  PptKeynote,
  PptKeynoteDocument,
  SvgEps,
  VmaWav,
  XlsNumbers,
  XlsNumbersDocument,
  ZipRar,
  ZipRarDocument,
} from '../../../svg';

export const extractTypeToMimetype = (mimetype: string) => {
  switch (mimetype) {
    case 'video/quicktime':
      return 'mov';
    case 'video/mp4':
      return 'mp4';
    case 'video/x-msvideo':
      return 'avi';
    case 'text/html':
      return 'html';
    case 'application/x-httpd-php':
      return 'php';
    case 'text/css':
      return 'css';
    case 'application/xml':
    case 'text/xml':
      return 'xml';
    case 'text/javascript':
      return 'js';
    case 'application/vnd.android.package-archive':
      return 'apk';
    case 'application/json':
      return 'json';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.apple.pages':
      return 'page';
    case 'application/msword':
      return 'doc';
    case 'text/plain':
      return 'txt';
    case 'application/pdf':
      return 'pdf';
    case 'application/epub+zip':
      return 'epub';
    case 'application/octet-stream':
    case 'image/png':
      return 'png';
    case 'image/jpeg':
      return 'jpeg';
    case 'application/vnd.ms-powerpoint':
      return 'ppt';
    case 'application/vnd.apple.keynote':
      return 'keyn';
    case 'image/svg+xml':
      return 'svg';
    case 'application/postscript':
      return 'eps';
    case 'audio/x-wav':
    case 'audio/wav':
      return 'wav';
    case 'audio/x-ms-wma':
      return 'wma';
    case 'application/excel':
    case 'application/vnd.ms-excel':
    case 'application/x-excel':
    case 'application/x-msexcel':
      return 'xls';
    case 'application/vnd.apple.numbers':
      return 'numb';
    case 'application/vnd.rar':
      return 'rar';
    case 'application/zip':
      return 'zip';
    default:
      return '';
  }
};

export const extractThumbnailFromName = (
  mimetype: string,
  nameFile: string
) => {
  const extension =
    extractTypeToMimetype(mimetype) || nameFile.split('.').pop() || '';

  if (extension) {
    switch (extension.toLowerCase()) {
      case 'mp4':
      case 'avi':
      case 'mov':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <Mp4AviMov />,
          documents: <Mp4AviMovWmaWavDocument />,
        };
      case 'html':
      case 'php':
      case 'css':
      case 'xml':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <HtmlPhpCssXml />,
          documents: <HttpCssXmlPhpDocument />,
        };
      case 'js':
      case 'apk':
      case 'json':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <JsApkJson />,
          documents: <JsApkJsonDocument />,
        };
      case 'docx':
      case 'page':
      case 'doc':
      case 'txt':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <DocPagesTxt />,
          documents: <DocxDocTxtPagesDocument />,
        };
      case 'pdf':
      case 'epub':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <PdfEpub />,
          documents: <PdfEpubDocument />,
        };
      case 'png':
      case 'jpg':
      case 'jpeg':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <PngJpg />,
          documents: <PngJpgJpegDocument />,
        };
      case 'ppt':
      case 'keynote':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <PptKeynote />,
          documents: <PptKeynoteDocument />,
        };
      case 'eps':
      case 'svg':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <SvgEps />,
          documents: <PngJpgJpegDocument />,
        };
      case 'wma':
      case 'wav':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <VmaWav />,
          documents: <Mp4AviMovWmaWavDocument />,
        };
      case 'xls':
      case 'numb':
      case 'xlsx':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <XlsNumbers />,
          documents: <XlsNumbersDocument />,
        };
      case 'zip':
      case 'rar':
        return {
          extension: extension.toUpperCase(),
          thumbnail: <ZipRar />,
          documents: <ZipRarDocument />,
        };
      default:
        return {
          extension: '',
          thumbnail: <NoAttachmentPreview />,
          documents: <NoAttachmentPreview />,
        };
    }
  }

  return {
    extension: '',
    thumbnail: <NoAttachmentPreview />,
    documents: <NoAttachmentPreview />,
  };
};
