import { gqlUserResolutionShape } from '../user/shapes';

export const gqlChatRoomShape = `
    id
    creationTime
    joiningTime
    resourceId
    resourceType
    lastMessagePreview {
        message
        receivedAt
    }
    unreadMessages
    participants {
      ${gqlUserResolutionShape}
    }
`;

export const gqlChatRoomEventShape = `
    id
    timestamp
    actor {
        ${gqlUserResolutionShape}
      }
    eventType
    createdEvent {
      chatRoomId
    }
    closedEvent {
      chatRoomId
    }
    reopenedEvent {
      chatRoomId
    }
    messagePostedEvent {
      chatRoomId
      messageNumber
      message
      mentions {
        ${gqlUserResolutionShape}
      }
    }
    participantInvitedEvent {
      chatRoomId
      user {
        ${gqlUserResolutionShape}      
      }
    }
    participantJoinedEvent {
      chatRoomId
      resourceId
      resourceType
    }
    participantLeftEvent {
      chatRoomId
    }
    participantMessageReceivedEvent {
      chatRoomId
      messageNumber
    }
    participantMessageReadEvent {
      chatRoomId
      messageNumber
    }
    resourceLinkedEvent {
      chatRoomId
      resourceId
      resourceType
    }
`;

export const gqlChatRoomEventsResponseShape = `
    chatRoom {
      ${gqlChatRoomShape}
    }
    events {
      ${gqlChatRoomEventShape}
    }
`;
