import Keycloak from 'keycloak-js';

// @ts-ignore
const kc = new Keycloak('/keycloak.json');

const initKeycloak = (onAuthenticatedCallback: any) => {
  kc.init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
  }).then((authenticated: boolean) => {
    if (authenticated) {
      if (
        kc.tokenParsed?.realm_access?.roles.some(
          (role: string) => role === 'sintropi-access'
        )
      ) {
        onAuthenticatedCallback();
        watchRefresh();
        watchSessionLifecycle();
      } else {
        // force a logout if the user does not have the sintropi-access role
        doLogout();
      }
    } else {
      doLogin();
    }
  });
};

const doLogin = kc.login;

const doLogout = kc.logout;

const getToken = () => kc.token;

const isLoggedIn = () =>
  !!kc.token &&
  kc.tokenParsed?.realm_access?.roles.some(
    (role: string) => role === 'sintropi-access'
  );

const removeFieldInTask = () => kc.tokenParsed?.remove_field_in_task || '';
const removeFieldInExecution = () =>
  kc.tokenParsed?.remove_field_in_execution || '';

const updateToken = (successCallback: any) =>
  kc.updateToken(60).then(successCallback).catch(doLogin);

const getUsername = () => kc.tokenParsed?.preferred_username;

const getFullName = () =>
  `${kc.tokenParsed?.given_name} ${kc.tokenParsed?.family_name}`;

const getTokenExpiration = () => kc.tokenParsed?.exp;

const getLocale = () => kc.tokenParsed?.locale || 'en-EN';

const getLocaleForDate = () => kc.tokenParsed?.date_format;

const getTranslationFor = (name: string) => kc.tokenParsed?.i18n?.[name];

const getHideList = () =>
  kc.tokenParsed?.hide?.split(',').map((s: string) => s.trim()) || [];

const hasRole = (roles: string[]) =>
  roles.some((role) => kc.hasRealmRole(role));
const hasGroup = (group: string) =>
  kc.tokenParsed?.groups?.find((g: string) => g === group);

const sintropiHasRoles = (roles: string[]) =>
  kc.resourceAccess
    ? kc.resourceAccess['sintropi-web']?.roles.some(
        (role: string) => roles.indexOf(role) >= 0
      )
    : false;

const isLoggedInWithRoles = (roles?: string[]) =>
  isLoggedIn() && (!roles || roles.length === 0 || hasRole(roles!));

const watchRefresh = () => {
  setTimeout(() => {
    updateToken(watchRefresh);
  }, ((getTokenExpiration() || 0) - Date.now() / 1000) * 1000 - 10000); // 10s error window
};

const watchSessionLifecycle = () => {
  // doLogin after 8 hours
  setTimeout(() => {
    doLogout();
  }, 8 * 60 * 60 * 1000);
};

const getId = () => kc.subject;

export const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenExpiration,
  updateToken,
  getFullName,
  getUsername,
  getLocale,
  getLocaleForDate,
  hasRole,
  isLoggedInWithRoles,
  getTranslationFor,
  getHideList,
  sintropiHasRoles,
  hasGroup,
  getId,
  removeFieldInTask,
  removeFieldInExecution,
};
