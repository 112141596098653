import React from 'react';
import {
  IdentifiableValue,
  ResourceAssignmentType,
  ResourceState,
} from '@pec-manager/graphql';
import { InboxSidebarSectionElement } from './components/InboxSidebarSection';
import { Disk, InboxV2, KO, ProcessIcon, Sent, Spam, Success } from '../../svg';

export enum InboxSidebarMainSectionElementId {
  INBOX = 'INBOX',
  ARCHIVE = 'ARCHIVE',
}

export function getInboxSidebarMainSectionElements(
  archives: IdentifiableValue[]
): InboxSidebarSectionElement[] {
  return [
    {
      dropDownItems: [
        {
          id: ResourceAssignmentType.DEFAULT,
          value: 'assignedMails',
        },
        {
          id: ResourceAssignmentType.GROUP,
          value: 'groupMails',
        },
        {
          id: ResourceAssignmentType.TO_OTHERS,
          value: 'assignedToOthers',
        },
      ],
      svgIcon: { icon: <InboxV2 /> },
      id: InboxSidebarMainSectionElementId.INBOX,
      name: 'inbox',
    },
    {
      svgIcon: { icon: <KO /> },
      id: ResourceState.EXPIRED,
      name: 'expired',
    },
    {
      svgIcon: { icon: <Success /> },
      id: ResourceState.WORKED,
      name: 'worked',
    },
    {
      svgIcon: { icon: <Sent /> },
      id: ResourceState.SENT,
      name: 'sent',
    },
    {
      svgIcon: { icon: <Spam /> },
      id: ResourceState.SPAM,
      name: 'spam',
    },
    {
      svgIcon: { icon: <ProcessIcon /> },
      id: ResourceState.IN_PROGRESS,
      name: 'activity',
    },
    {
      dropDownItems: archives,
      svgIcon: { icon: <Disk /> },
      id: InboxSidebarMainSectionElementId.ARCHIVE,
      name: 'archive',
    },
  ];
}
