import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ResourcePriority } from '@pec-manager/graphql';
import { Text } from '../../../components/common/Text';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Tips } from '../../../components/common/Tips';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { CircleArrow } from '../../../svg';

interface TitleSectionProps {
  title: string;
  priority: ResourcePriority;
  setHideCorrelatedSection: Dispatch<SetStateAction<boolean>>;
  hideCorrelatedSection: boolean;
  showSvgArrow: boolean;
}

export const TitleSection: FunctionComponent<TitleSectionProps> = ({
  title,
  priority,
  setHideCorrelatedSection,
  hideCorrelatedSection,
  showSvgArrow,
}) => (
  <Container wrapContent alignItems="center" justifyContent="space-between">
    <TextTitle
      text={title}
      fontSize="14px"
      lineHeight="21px"
      fontWeight="500"
      skipTranslation
    />
    {priority !== ResourcePriority.NONE && (
      <RightContainer alignItems="center">
        <Tips value={priority} size="10px" lineHeight="15px" />
        {showSvgArrow && (
          <motion.div
            whileHover={{ scale: 1.2 }}
            transition={{ duration: 0.2 }}
          >
            <ClickableSvg
              onClick={(e) => {
                e.stopPropagation();
                setHideCorrelatedSection((prevState) => !prevState);
              }}
              rotateFactor={!hideCorrelatedSection}
            >
              <SvgIcon svg={<CircleArrow />} width="18px" height="18px" />
            </ClickableSvg>
          </motion.div>
        )}
      </RightContainer>
    )}
  </Container>
);

const RightContainer = styled(FlexContainer)`
  margin-left: auto;
  & > div:first-child {
    margin-right: 30px;
  }
`;

const Container = styled(FlexContainer)`
  margin-bottom: 8px;
`;

const TextTitle = styled(Text)`
  margin-right: 16px;
  max-width: 285px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClickableSvg = styled.div<{ rotateFactor: boolean }>`
  height: 18px;
  transition: 250ms transform ease-in-out;
  transform: rotate(${({ rotateFactor }) => (rotateFactor ? `180deg` : `0`)});
`;
