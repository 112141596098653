import { KpiRow, KpiSelectFilter, KpiTab } from '@pec-manager/graphql';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_KPI_FILTERS,
  GET_KPI_TAB_ROWS,
  GET_KPI_TABS,
} from '../../graphql/kpi/queries';
import { useEffect } from 'react';
import { nothing } from '../../utils/funcUtils';

export const useBusinessKpi = (
  appId: string,
  tabId?: string,
  filter?: { name: string; value: string }[]
): {
  kpiFilters: KpiSelectFilter[];
  kpiTabs: KpiTab[];
  kpiRow: KpiRow[];
  kpiRowLoading: boolean;
} => {
  const { data: kpiFiltersSelect } = useQuery(GET_KPI_FILTERS, {
    variables: {
      appId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: kpiTabs } = useQuery(GET_KPI_TABS, {
    variables: {
      appId,
    },
    fetchPolicy: 'network-only',
  });

  const [lazyKpiTabRows, { data: kpiRow, loading: kpiRowLoading }] =
    useLazyQuery(GET_KPI_TAB_ROWS, {
      variables: {
        appId,
        tabId,
        filter,
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    lazyKpiTabRows().then(nothing);
  }, [filter, lazyKpiTabRows, tabId]);

  return {
    kpiFilters: kpiFiltersSelect?.getKpiFilters.select_filters,
    kpiRow: kpiRow?.getKpiTabRows,
    kpiTabs: kpiTabs?.getKpiTabs,
    kpiRowLoading,
  };
};
