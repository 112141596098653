import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ChatRoomEvent,
  ChatRoomEventsResponse,
  User,
} from '@pec-manager/graphql/lib/graphql.types';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useQuery } from '@apollo/client';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { TopBar } from './TopBar';
import { ChatContent } from '../../ChatDetails/ChatContent';
import defaultStyle from '../../ChatDetails/ChatContent/defaultStyle';
import { Text } from '../../../components/common/Text';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import { POST_MESSAGE_CHAT } from '../../../graphql/chat/mutations';
import { Button } from '../../../components/common/Button';
import { FIND_USERS } from '../../../graphql/user/queries';
import { Theme } from '../../../theme';
import { ResourceContext } from '@pec-manager/graphql';
import { Sent } from '../../../svg';
import { SvgIcon } from '../../../components/common/SvgIcon';

interface ChatModalExtendedProps {
  closeChat: () => void;
  closeCompressed: () => void;
  chat: ChatRoomEventsResponse;
  newChatEvent?: ChatRoomEvent;
  title?: string;
  context?: ResourceContext;
}

export const ChatModalExtended: FunctionComponent<ChatModalExtendedProps> = ({
  closeChat,
  closeCompressed,
  chat,
  newChatEvent,
  title,
  context,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [postMessage] = useMutationHook({
    mutationGql: POST_MESSAGE_CHAT,
    errorMessage: 'genericErrorMessage',
  });
  const [mentions, setMentions] = useState<string[]>([]);
  const [users, setUsers] = useState<{ id: string; display: string }[]>([]);
  const { data: searchUser } = useQuery(FIND_USERS);

  useEffect(() => {
    if (searchUser) {
      setUsers(
        searchUser.findUsers.map((u: User) => ({
          id: u.id,
          display: `${u.firstName} ${u.lastName}`,
        }))
      );
    }
  }, [searchUser]);

  return (
    <ContainerModal column>
      <TopBar
        closeChat={closeChat}
        closeCompressed={closeCompressed}
        title={title || 'loading'}
        context={context}
      />
      <ChatContent
        initialEvents={chat.events}
        chatRoomId={chat.chatRoom.id}
        newChatEvent={newChatEvent}
      />
      <SendMessageContainer>
        <InputContainer column>
          <MentionsInput
            value={inputValue}
            onChange={(event, newValue, newSet, mentionsItem) => {
              setMentions(mentionsItem.map((m) => m.id));
              setInputValue(newValue);
            }}
            style={defaultStyle}
            placeholder={t('enterTextToSend') as string}
            onKeyUp={(event) => {
              if (
                event.key === 'Enter' &&
                event.altKey &&
                inputValue.length > 0
              )
                postMessage({
                  variables: {
                    chatRoomId: chat.chatRoom.id,
                    message: {
                      message: inputValue,
                      mentions: Array.from(new Set(mentions)),
                    },
                  },
                }).then(() => {
                  setInputValue('');
                });
            }}
          >
            <Mention
              trigger="@"
              data={users}
              displayTransform={(id, display) => display}
              style={{
                backgroundColor: 'rgba(205, 205, 205, 0.5)',
                borderRadius: '4px',
              }}
            />
          </MentionsInput>
          <SuggestionText
            text="suggestSendMessage"
            fontSize="12px"
            lineHeight="18px"
            color={Theme.colors.c505050}
          />
        </InputContainer>
        <SendMessageButton
          border={`1px solid ${Theme.colors.cDCDCDC}`}
          padding="3px 15px"
          borderRadius="8px"
          height="40px"
          backgroundColor={Theme.colors.c5B84EF}
          hoverBackgroundColor={Theme.colors.c5176d6}
          disabled={!(inputValue.length > 0)}
          onClick={() => {
            postMessage({
              variables: {
                chatRoomId: chat.chatRoom.id,
                message: {
                  message: inputValue,
                  mentions: Array.from(new Set(mentions)),
                },
              },
            }).then(() => {
              setInputValue('');
            });
          }}
        >
          <SendText
            text="sendMessage"
            color={Theme.colors.cFFFFFF}
            fontSize="13px"
            lineHeight="13px"
          />
          <SendIcon svg={<Sent />} width="20px" height="20px" />
        </SendMessageButton>
      </SendMessageContainer>
    </ContainerModal>
  );
};

const ContainerModal = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  height: 768px;
  width: 742px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  z-index: 10;
  @media (max-height: 990px) {
    max-height: calc((var(--vh, 1vh) * 100));
    height: calc((var(--vh, 1vh) * 100));
  }

  @media (max-width: 1040px) {
    overflow: auto;
  }

  @media (max-width: 990px) {
    max-height: calc(((var(--vh, 1vh) * 100) - 150px));
    height: 100%;
    width: 100%;
  }
`;

const SendMessageButton = styled(Button)`
  margin-left: 16px;
  min-width: fit-content;

  @media (max-width: 990px) {
    border-radius: 50%;
    height: 47px;
    width: 47px;
  }
`;

const SendMessageContainer = styled.div`
  display: flex;
  padding: 20px;
  -webkit-box-shadow: 0px -2px 16px -2px #50505012;
  box-shadow: 0px -2px 16px 4px #50505012;
  z-index: 1;
`;

const InputContainer = styled(FlexContainer)`
  width: 100%;
  max-width: calc(100% - 155px);

  @media (max-width: 990px) {
    max-width: calc(100vw);
  }
`;

const SuggestionText = styled(Text)`
  margin-top: 5px;

  @media (max-width: 990px) {
    display: none;
  }
`;

const SendText = styled(Text)`
  @media (max-width: 990px) {
    display: none;
  }
`;

const SendIcon = styled(SvgIcon)`
  @media (min-width: 990px) {
    display: none;
  }
`;
