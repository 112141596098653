import { gql } from '@apollo/client/core';

export const CHANGE_EXECUTION_PRIORITY = gql`
  mutation ChangeExecutionPriority(
    $executionId: ID!
    $newPriority: ResourcePriority!
  ) {
    changeExecutionPriority(
      executionId: $executionId
      newPriority: $newPriority
    ) {
      _
    }
  }
`;

export const CHANGE_EXECUTION_DUE_DATE = gql`
  mutation ChangeExecutionDueDate($executionId: ID!, $newDueDate: Date) {
    changeExecutionDueDate(executionId: $executionId, newDueDate: $newDueDate) {
      _
    }
  }
`;
