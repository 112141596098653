const mentionRegex = new RegExp(
  /(@\[[ 0-9A-Za-z_-]{1,}\]\([ 0-9A-Za-z_-]{1,}\))/
);
const extractorRegex = new RegExp(
  /@\[([ 0-9A-Za-z_-]{1,})\]\(([ 0-9A-Za-z_-]{1,})\)/
);
export function decomposeRawWithMentions(
  s: string
): ({ id: string; display: string } | { textElement: string })[] {
  return s.split(mentionRegex).map((x) => {
    if (mentionRegex.test(x)) {
      const unwrapped = extractorRegex.exec(x);
      if (!unwrapped) {
        throw new Error('Could not unwrap mention pattern');
      }
      return { id: unwrapped[2], display: unwrapped[1] };
    }
    return { textElement: x };
  });
}
