import { useMutation } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { ChatResourceType } from '@pec-manager/graphql/lib/graphql.types';
import {
  LOAD_CHAT,
  OPEN_CHAT,
  READ_MESSAGE_CHAT,
} from '../../graphql/chat/mutations';
import { findLastMessageReceived } from '../ChatDetails/utilis';
import { gqlName } from '../../graphql/utils';
import { nothing } from '../../utils/funcUtils';
import { useLocation } from 'react-router-dom';

export const useChat = (chatId?: string, resourceId?: string) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const chatRoomId = chatId ?? searchParams.get('chatId');

  const [loadChatById, { data, error, loading }] = useMutation(LOAD_CHAT);
  const [
    openChatById,
    { data: openChatValue, error: errorOpenChat, loading: loadingOpenChat },
  ] = useMutation(OPEN_CHAT);
  const [readMessageById] = useMutation(READ_MESSAGE_CHAT);

  useEffect(() => {
    if (
      data &&
      data.loadChatRoom.events.length > 0 &&
      findLastMessageReceived(data.loadChatRoom.events.slice().reverse())
    ) {
      readMessageById({
        variables: {
          chatRoomId,
          messageOffset: findLastMessageReceived(
            data.loadChatRoom.events.slice().reverse()
          ),
        },
      }).then(nothing);
    }
    // eslint-disable-next-line
  }, [data, readMessageById]);

  useEffect(() => {
    if (
      openChatValue &&
      openChatValue.openChatRoom.events.length > 0 &&
      findLastMessageReceived(
        openChatValue.openChatRoom.events.slice().reverse()
      )
    ) {
      readMessageById({
        variables: {
          chatRoomId,
          messageOffset: findLastMessageReceived(
            openChatValue.openChatRoom.events.slice().reverse()
          ),
        },
      }).then(nothing);
    }
    // eslint-disable-next-line
  }, [openChatValue, readMessageById]);

  useEffect(() => {
    if (resourceId && chatRoomId) {
      openChatById({
        variables: {
          chatRoomId,
          resource: {
            resourceType: ChatResourceType.EXECUTION,
            resourceId,
          },
        },
      }).then(nothing);
    } else {
      loadChatById({
        variables: {
          chatRoomId,
        },
      }).then(nothing);
    }
    // eslint-disable-next-line
  }, [openChatById, chatRoomId]);

  const dataChat = useMemo(() => {
    if (openChatValue) {
      return openChatValue.openChatRoom;
    }
    return data ? data[gqlName(LOAD_CHAT)] : undefined;
    // eslint-disable-next-line
  }, [data, openChatValue]);

  return {
    data: dataChat,
    error: error || errorOpenChat,
    loading: loading || loadingOpenChat,
    openChat: () => {
      if (resourceId && chatRoomId) {
        openChatById({
          variables: {
            chatRoomId,
            resource: {
              resourceType: ChatResourceType.EXECUTION,
              resourceId,
            },
          },
        }).then(nothing);
      } else {
        loadChatById({
          variables: {
            chatRoomId,
          },
        }).then(nothing);
      }
    },
  };
};
