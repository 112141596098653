import {
  FormFieldDefinition,
  FormViewMode,
} from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { MultipleValuesInput } from '../../input/MultipleValuesInput';
import { FormFieldContainer } from './FormFieldContainer';
import {
  boundaryError,
  generatePredicatesFromDefinitions,
  validationError,
} from './utils/errorMessage';
import { GenericTextInputCardinality01 } from '../../input/GenericTextInputCardinality01';
import { useTranslation } from 'react-i18next';

interface GenericTextFieldProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  type: 'string' | 'number';
  errorMessage?: string;
  svgInput?: JSX.Element;
  viewMode?: FormViewMode;
  checkRequired?: boolean;
  columnForm?: number;
  placeholder: string;
}

export const GenericTextField: FunctionComponent<GenericTextFieldProps> = ({
  formField,
  fillField,
  type,
  errorMessage,
  svgInput,
  viewMode,
  checkRequired = false,
  columnForm = 1,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<string[]>([]);
  const [fieldError, setFieldError] = useState<string | undefined>(undefined);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  const setAllData = (newData: string[]) => {
    setData(newData.map((d) => d.trim()));
    fillField(
      formField.id,
      newData.map((d) => d.trim()),
      formField.isDynamic
    );
  };

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  const validatorData = useMemo(() => {
    return data.length <= 0
      ? undefined
      : data.map((v) =>
          validationError(
            v.trim(),
            generatePredicatesFromDefinitions(formField.validationPredicates)
          )
        )[0];
  }, [data, formField.validationPredicates]);

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage]);

  const calcError = useMemo(
    () =>
      firstOnClick && formField.isEditable
        ? fieldError ||
          boundaryError(
            data.length,
            formField.cardinalityBounds?.left,
            formField.cardinalityBounds?.right
          ) ||
          validatorData ||
          errorMessageDate
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formField, data, firstOnClick, fieldError, errorMessageDate]
  );

  useEffect(() => {
    if (!data || formField.defaultValues.length > 0) {
      setData(
        formField.defaultValues
          .map((v) =>
            type === 'string'
              ? `${v.kind.string?.string}`
              : `${v.kind.number?.number}`
          )
          .filter((v) => v.trim() !== '')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField]);

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={calcError}
      viewMode={viewMode}
      columnForm={columnForm}
    >
      {formField.cardinalityBounds?.right === 1 ? (
        <GenericTextInputCardinality01
          addInputError={!!fieldError}
          onInputChange={(val) => {
            setErrorMessageDate(undefined);
            setFieldError(
              validationError(
                val.trim(),
                generatePredicatesFromDefinitions(
                  formField.validationPredicates
                )
              )
            );
          }}
          data={data}
          setData={formField.isEditable ? setAllData : undefined}
          errorMessage={calcError}
          type={type}
          isEditable={formField.isEditable}
          setFirstOnClick={setFirstOnClick}
          placeholder={placeholder}
        />
      ) : (
        <MultipleValuesInput
          addInputError={!!fieldError}
          onInputChange={(val) => {
            setErrorMessageDate(undefined);
            setFieldError(
              validationError(
                val.trim(),
                generatePredicatesFromDefinitions(
                  formField.validationPredicates
                )
              )
            );
          }}
          placeholder={t('insertText') as string}
          svgInput={svgInput}
          data={data}
          setData={formField.isEditable ? setAllData : undefined}
          errorMessage={calcError}
          type={type}
          isEditable={formField.isEditable}
          setFirstOnClick={setFirstOnClick}
        />
      )}
    </FormFieldContainer>
  );
};
