import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  OutputSection,
  OutputSectionFieldType,
  OutputSectionFieldValue,
} from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { getDateAsString } from '../../utils/dateUtils';
import { extractFullName } from '../../utils/stringUtils';

interface CustomSectionProps {
  output: OutputSection;
}

// It will go into a hook
const mapFields = (
  type: OutputSectionFieldType,
  values: OutputSectionFieldValue[]
) => {
  switch (type) {
    case OutputSectionFieldType.DATE: {
      const epochMillis = parseInt(values[0]?.value || '', 10);

      if (isNaN(epochMillis)) {
        return {
          values: '',
        };
      }

      return {
        values: 'onlyDate',
        translationArgs: {
          date: getDateAsString(epochMillis),
        },
      };
    }
    case OutputSectionFieldType.USER:
      return {
        values: values.map(({ user }) => extractFullName(user)).join(', '),
      };
    case OutputSectionFieldType.MAIL:
    case OutputSectionFieldType.DEFAULT:
    default:
      return {
        values: values.map(({ value }) => value).join(', '),
        translationArgs: {},
      };
  }
};

export const CustomSection: FunctionComponent<CustomSectionProps> = ({
  output,
}) => (
  <Container wrapContent justifyContent="space-between">
    <SectionTitle text={output.name} fontSize="14px" lineHeight="17px" />
    {output.fields.map(({ type, values, name }) => {
      const { values: mappedValues, translationArgs } = mapFields(type, values);

      return (
        <Item column justifyContent="space-around" key={name}>
          <ItemTitle
            text={name}
            fontSize="12px"
            lineHeight="12px"
            color={Theme.colors.c7A869A}
            skipTranslation
          />
          <Text
            text={mappedValues}
            fontSize="14px"
            translationArgs={translationArgs}
            lineHeight="21px"
            possibleLink
            skipTranslation={
              type !== OutputSectionFieldType.DATE &&
              type !== OutputSectionFieldType.USER
            }
          />
        </Item>
      );
    })}
  </Container>
);

const Container = styled(FlexContainer)`
  padding-top: 24px;
  width: 100%;
`;

const SectionTitle = styled(Text)`
  width: 100%;
`;

const Item = styled(FlexContainer)`
  width: calc((100% / 2) - 8px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.cF0F0F0};
  padding: 8px 0;
`;

const ItemTitle = styled(Text)`
  margin: 8px 0;
`;
