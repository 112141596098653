import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../layout/FlexContainer';
import { SelectedInput } from '../common/SelectedInput';
import { splitBySeparator } from '../../utils/stringUtils';
import { Arrow } from '../../svg';
import { Theme } from '../../theme';
import { SvgIcon } from '../common/SvgIcon';
import { KeycloakService } from '../../services/KeycloakService';

interface MultipleValuesInputProps {
  data: string[];
  setData?: any;
  placeholder?: string;
  errorMessage?: string;
  type: 'string' | 'number';
  isEditable: boolean;
  onInputChange?: (val: string) => void;
  disablePressEnter?: boolean;
  svgInput?: JSX.Element;
  displayValueMap?: any;
  onFocusInput?: (val: boolean) => void;
  addInputError?: boolean;
  selectChoicesIsOpen?: boolean;
  arrowIcon?: boolean;
  boundaryRight?: boolean;
  setFirstOnClick: (focus: boolean) => void;
}

export const MultipleValuesInput: FunctionComponent<
  MultipleValuesInputProps
> = ({
  data,
  setData,
  placeholder,
  errorMessage,
  type,
  isEditable,
  onInputChange,
  disablePressEnter,
  svgInput,
  displayValueMap,
  onFocusInput,
  addInputError,
  selectChoicesIsOpen,
  arrowIcon,
  boundaryRight,
  setFirstOnClick,
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue('');
  }, [data]);

  const addInput = () => {
    if (!addInputError) {
      setInputValue('');
      if (boundaryRight) {
        setData(inputValue.length > 0 ? [inputValue] : []);
      } else {
        setData(
          Array.from(new Set(data.concat(splitBySeparator(inputValue, ','))))
        );
      }
    }
  };

  const enterAddInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && inputValue && !disablePressEnter) {
      addInput();
    }
  };

  const deleteInput = (input: string) => {
    setFirstOnClick(true);
    const newInputs = data.filter(
      (el) => (displayValueMap ? displayValueMap[el] || el : el) !== input
    );
    setData(newInputs);
  };

  return (
    <Container
      alignItems="center"
      wrapContent
      error={!!errorMessage}
      isEditable={isEditable}
      justifyContent="space-between"
    >
      <ContainerInput alignItems="center" wrapContent>
        {!!svgInput && <div style={{ marginRight: '10px' }}>{svgInput}</div>}
        {data.map((s) => (
          <SelectedInput
            text={displayValueMap ? displayValueMap[s] || s : s}
            key={s}
            onClickDelete={setData && deleteInput}
          />
        ))}
        {setData && (
          <InputStyled
            onBlur={() =>
              disablePressEnter ? {} : inputValue?.length > 0 && addInput()
            }
            step={type === 'number' ? '0.01' : undefined}
            onFocus={() => {
              setFirstOnClick(true);
              onFocusInput && onFocusInput(true);
            }}
            lang={KeycloakService.getLocale()}
            value={inputValue}
            onChange={(e) => {
              if (type === 'number' && !isNaN(Number(e.target.value))) {
                setInputValue(e.target.value);
              } else if (type === 'string') {
                setInputValue(e.target.value);
                onInputChange && onInputChange(e.target.value);
              }
            }}
            onKeyDown={enterAddInput}
          />
        )}
      </ContainerInput>
      {arrowIcon && (
        <ClickableArrow
          onClick={() => {
            setFirstOnClick(true);
            onFocusInput && onFocusInput(!selectChoicesIsOpen);
          }}
        >
          <ArrowIcon
            svg={<Arrow />}
            width="10.8px"
            color={Theme.colors.c000000}
            isRotated={selectChoicesIsOpen}
          />
        </ClickableArrow>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)<{
  error?: boolean;
  isEditable: boolean;
}>`
  padding: 0 10px 10px 10px;
  border: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors.cFE4242 : theme.colors.cDFE1E6};
  min-width: calc(100% - 20px);
  width: 100%;
  border-radius: 6px;
  background-color: ${({ theme, isEditable }) =>
    isEditable ? theme.colors.cFFFFFF : theme.colors.transparentGrey};
  min-height: 51px;

  &:focus {
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.cFE4242 : theme.colors.cD8FF86};
  }
  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }
`;

const ContainerInput = styled(FlexContainer)`
  width: calc(100% - 32px);
  min-width: 200px;
  & > div,
  input {
    margin-top: 10px;
  }
`;

const InputStyled = styled.input`
  border: 0;
  outline: none;
  height: 31px;
  flex-grow: 1;
`;

const ArrowIcon = styled(SvgIcon)<{ isRotated?: boolean }>`
  margin-right: 9px;
  transition: 200ms transform ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`;

const ClickableArrow = styled.div`
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
