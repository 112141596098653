export const gqlFormFieldDefinitionWithoutCompositeShape = `
          id
          isEditable
          isDynamic
          name
          placeholder
          cardinalityBounds {
            left
            right
          }
          type
          kind {
            genericSelect {
              viewType
              choices {
                id
                displayValue
              }
            }
            formula {
              formula
            }
            genericPassword {
              withConfirmation
            }
            documentObject {
              allowedExtensions
            }
            currency {
              digitsRegex
              currencies {
                displayName
                symbol
              }
            }
          }
          validationPredicates {
            type
            kind {
              valueBetweenBounds {
                vLeft
                vRight
              }
              lengthBetweenBounds {
                lLeft
                lRight
              }
              dateBetweenBounds {
                dLeft
                dRight
              }
              regex {
                regex
              }
            }
          }
          defaultValues {
            type
            kind {
              string {
                string
              }
              number {
                number
              }
              date {
                date
              }
              user {
                user {
                  id
                  firstName
                  lastName
                  mail
                  username
                }
              }   
              approval {
                approver {
                  id
                  firstName
                  lastName
                  mail
                  username
                }
                isApproval
                at
                actionName
                comment
              }
              currency {
                currency {
                  displayName
                  symbol
                }
                values
              }
              documentObject {
                 blobRef {
                   id
                   name
                   container
                   size
                   mimeType
                   downloadUrl
                   thumbnail
                }
              }
            }
          }
          errors {
            code
            message
          }
        
`;
export const gqlFormDefinitionResolutionShape = `
      id
      name
      fieldGroups {
        id
        name
        isHidden
        fields {
          id
          isEditable
          isDynamic
          name
          placeholder
          cardinalityBounds {
            left
            right
          }
          type
          kind {
            genericSelect {
              viewType
              choices {
                id
                displayValue
              }
            }
            formula {
              formula
            }
            genericPassword {
              withConfirmation
            }
            documentObject {
              allowedExtensions
            }
            currency {
              digitsRegex
              currencies {
                displayName
                symbol
              }
            }
            composite {
              viewMode
              emptyFormFieldDefinitions {
                ${gqlFormFieldDefinitionWithoutCompositeShape}
              }
            }
          }
          validationPredicates {
            type
            kind {
              valueBetweenBounds {
                vLeft
                vRight
              }
              lengthBetweenBounds {
                lLeft
                lRight
              }
              dateBetweenBounds {
                dLeft
                dRight
              }
              regex {
                regex
              }
            }
          }
          defaultValues {
            type
            kind {
              string {
                string
              }
              number {
                number
              }
              date {
                date
              }
              user {
                user {
                  id
                  firstName
                  lastName
                  mail
                  username
                }
              }   
              approval {
                approver {
                  id
                  firstName
                  lastName
                  mail
                  username
                }
                isApproval
                at
                actionName
                comment
              }
              composite {
                values {
                  ${gqlFormFieldDefinitionWithoutCompositeShape}
                }
              }
              currency {
                currency {
                  displayName
                  symbol
                }
                values
              }
              documentObject {
                 blobRef {
                   id
                   name
                   container
                   size
                   mimeType
                   downloadUrl
                   thumbnail
                }
              }
            }
          }
          errors {
            code
            message
          }
        }
        errors {
          code
          message
        }
      }
      actions {
        name
        isPrimary
        type
        dynActionCoords {
          id
          value
          spawnsChildForm
        }
      }
      errors {
        code
        message
      }`;
export const gqlPagedFormDefinitionResolutionShape = `
      pageRefs {
        pageId
        pageName
      }
      prevPageId
      nextPageId
      pageId
      formDefinition {
        ${gqlFormDefinitionResolutionShape}
      }`;
