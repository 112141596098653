/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  ExecutionContext,
  FilledForm,
  PagedFormDefinition,
  PagedFormDefinitionCursorDirection,
} from '@pec-manager/graphql/lib/graphql.types';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ADVANCE_FORM_DEFINITION_PAGE_BY_CURSOR_DIRECTION } from '../../graphql/form/mutations';
import { FormButton } from '../common/FormButton';
import { Theme } from '../../theme';
import { nothing } from '../../utils/funcUtils';
import { Text } from '../common/Text';
import { gqlName } from '../../graphql/utils';
import { getPagedFormDefinitionCallerCoordinates } from './useFilledForm';

export function usePagedFormDefinition(
  executionContext: ExecutionContext,
  id?: string,
  initialPagedFormDefinition?: PagedFormDefinition,
  processName?: string,
  initialContext?: any
) {
  const { appId } = useParams();

  const [filledForm, setFilledFormDelegate] = useState<FilledForm | undefined>(
    undefined
  );

  const [currentPagedFormDefinition, setCurrentPagedFormDefinition] =
    useState<PagedFormDefinition | null>(initialPagedFormDefinition || null);

  const {
    formDefinitionByIdMutation,
    formDefinitionMutationVariables,
    formDefinitionDiffsMutationDefinition,
  } = useMemo(
    () =>
      getPagedFormDefinitionCallerCoordinates(
        executionContext,
        id,
        appId,
        processName,
        initialContext
      ),
    [executionContext, id]
  );

  const [getPagedFormDefinition, { data: pagedFormDefinition }] = useMutation(
    formDefinitionByIdMutation
  );

  useEffect(() => {
    if (id && !initialPagedFormDefinition) {
      getPagedFormDefinition({
        variables: formDefinitionMutationVariables,
      }).then(nothing);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (pagedFormDefinition) {
      setCurrentPagedFormDefinition(
        pagedFormDefinition[gqlName(formDefinitionByIdMutation)]
      );
    }
  }, [pagedFormDefinition]);

  const [advanceFormDefinitionPageByCursorDirection, { data: advanceData }] =
    useMutation(ADVANCE_FORM_DEFINITION_PAGE_BY_CURSOR_DIRECTION);

  useEffect(() => {
    if (advanceData) {
      setCurrentPagedFormDefinition(
        advanceData.advanceFormDefinitionPageByCursorDirection
      );
    }
  }, [advanceData]);

  const [
    formDefinitionDiffsMutation,
    { data: formDefinitionDiffsMutationData },
  ] = useMutation(formDefinitionDiffsMutationDefinition);

  useEffect(() => {
    if (formDefinitionDiffsMutationData) {
      setCurrentPagedFormDefinition(
        formDefinitionDiffsMutationData[
          gqlName(formDefinitionDiffsMutationDefinition)
        ]
      );
    }
  }, [formDefinitionDiffsMutationData]);

  return {
    filledForm,
    setFilledFormDelegate,
    currentPagedFormDefinition,
    setCurrentPagedFormDefinition,
    formDefinitionDiffsOnDynamicFieldChange: (ff: FilledForm) => {
      if (currentPagedFormDefinition) {
        setTimeout(() => {
          formDefinitionDiffsMutation({
            variables: {
              context: executionContext,
              currentPageId: currentPagedFormDefinition.pageId,
              filledForm: ff,
            },
          }).then(nothing);
        });
      }
    },
    navigationButtons: (buttonDisabled: boolean) => (
      <>
        {currentPagedFormDefinition &&
          currentPagedFormDefinition.pageRefs.length > 0 &&
          currentPagedFormDefinition.pageId !==
            currentPagedFormDefinition.pageRefs[0].pageId && (
            <FormButton
              border={`1px solid ${Theme.colors.cDCDCDC}`}
              borderRadius="6px"
              padding="9px"
              disabled={buttonDisabled}
              onClick={() => {
                advanceFormDefinitionPageByCursorDirection({
                  variables: {
                    context: executionContext,
                    currentPageId: currentPagedFormDefinition!.pageId,
                    direction: PagedFormDefinitionCursorDirection.BACKWARD,
                    filledForm,
                  },
                }).then(nothing);
              }}
            >
              <Text
                text="buttonBackWithText"
                translationArgs={{
                  textValue:
                    currentPagedFormDefinition!.pageRefs[
                      currentPagedFormDefinition!.pageRefs.findIndex(
                        (p) => p.pageId === currentPagedFormDefinition!.pageId
                      ) - 1
                    ].pageName,
                }}
                fontSize="12px"
                lineHeight="12px"
                color={buttonDisabled ? Theme.colors.cFFFFFF : undefined}
              />
            </FormButton>
          )}
        {currentPagedFormDefinition &&
          currentPagedFormDefinition.pageRefs.length > 0 &&
          currentPagedFormDefinition?.pageId !==
            currentPagedFormDefinition?.pageRefs[
              currentPagedFormDefinition?.pageRefs.length - 1
            ].pageId && (
            <FormButton
              border={`1px solid ${Theme.colors.cDCDCDC}`}
              borderRadius="6px"
              padding="9px"
              disabled={buttonDisabled}
              isPrimary
              onClick={() => {
                advanceFormDefinitionPageByCursorDirection({
                  variables: {
                    context: executionContext,
                    currentPageId: currentPagedFormDefinition!.pageId,
                    direction: PagedFormDefinitionCursorDirection.FORWARD,
                    filledForm,
                  },
                }).then(nothing);
              }}
            >
              <Text
                text="buttonWithText"
                translationArgs={{
                  textValue:
                    currentPagedFormDefinition!.pageRefs[
                      currentPagedFormDefinition!.pageRefs.findIndex(
                        (p) => p.pageId === currentPagedFormDefinition!.pageId
                      ) + 1
                    ].pageName,
                }}
                fontSize="12px"
                lineHeight="12px"
                color={Theme.colors.cFFFFFF}
              />
            </FormButton>
          )}
      </>
    ),
  };
}
