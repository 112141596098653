import {
  FormFieldDefinition,
  FormFieldValidationPredicateType,
} from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './inputDate.css';
import styled from 'styled-components';
import { Calendar } from '../../svg';
import { Theme } from '../../theme';
import { extractDefaultDates } from '../../utils/dateUtils';
import { SvgIcon } from '../common/SvgIcon';
import { FormFieldContainer } from '../modals/FormModal/FormFieldContainer';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { boundaryError } from '../modals/FormModal/utils/errorMessage';
import { HeaderDataPicker } from '../common/HeaderDataPicker';
import { FormViewMode } from '@pec-manager/graphql';
import { KeycloakService } from '../../services/KeycloakService';

interface InputDateProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  dateTime?: boolean;
  viewMode?: FormViewMode;
  checkRequired?: boolean;
  columnForm?: number;
  placeholder: string;
}

export const InputDate: FunctionComponent<InputDateProps> = ({
  formField,
  fillField,
  errorMessage,
  dateTime,
  viewMode,
  checkRequired = false,
  columnForm,
  placeholder,
}) => {
  const [defaultDate] = useMemo(() => {
    if (!formField.defaultValues.some((c) => c.kind.date?.date)) return [null];
    return extractDefaultDates(formField.defaultValues);
  }, [formField]);
  const [date, setDate] = useState<Date | null>(null);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage, formField]);

  useEffect(() => {
    setDate(defaultDate);
    setErrorMessageDate(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField]);

  const { minDate, maxDate } = useMemo(() => {
    const predicate = formField.validationPredicates.find(
      (p) => p.type === FormFieldValidationPredicateType.DATE_BETWEEN
    );

    return predicate
      ? {
          minDate: predicate.kind.dateBetweenBounds?.dLeft
            ? new Date(predicate.kind.dateBetweenBounds?.dLeft)
            : undefined,
          maxDate: predicate.kind.dateBetweenBounds?.dRight
            ? new Date(predicate.kind.dateBetweenBounds.dRight)
            : undefined,
        }
      : {
          minDate: undefined,
          maxDate: undefined,
        };
  }, [formField]);

  const calcError = useMemo(
    () =>
      firstOnClick && formField.isEditable
        ? boundaryError(
            date ? 1 : 0,
            formField.cardinalityBounds?.left,
            formField.cardinalityBounds?.right
          ) || errorMessageDate
        : undefined,
    [
      firstOnClick,
      formField.isEditable,
      formField.cardinalityBounds?.left,
      formField.cardinalityBounds?.right,
      date,
      errorMessageDate,
    ]
  );

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={calcError}
      viewMode={viewMode}
      columnForm={columnForm}
    >
      <ContainerInput error={!!calcError} isEditable={formField.isEditable}>
        <DatePicker
          className={`${calcError ? 'error' : ''} `}
          disabled={!formField.isEditable}
          placeholderText={placeholder}
          locale={
            mappingLocaleForDatePicker(KeycloakService.getLocale(), dateTime)
              .locale
          }
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderDataPicker
              date={date}
              decreaseMonth={decreaseMonth}
              changeMonth={changeMonth}
              changeYear={changeYear}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          dateFormat={
            mappingLocaleForDatePicker(
              KeycloakService.getLocaleForDate()?.toLowerCase() ||
                KeycloakService.getLocale(),
              dateTime
            ).formatDate
          }
          onChange={(date) => {
            setDate(date as Date);
            setErrorMessageDate(undefined);
            if (date) {
              fillField(
                formField.id,
                [`${(date as Date).getTime()}`],
                formField.isDynamic
              );
            }
          }}
          onFocus={() => {
            setFirstOnClick(true);
          }}
          selected={date}
          showTimeSelect={dateTime}
          minDate={minDate}
          maxDate={maxDate}
          timeFormat="HH:mm"
          timeIntervals={30}
        />
        <ContainerSvg>
          <SvgIcon
            svg={<Calendar />}
            width="20px"
            color={Theme.colors.c505050}
          />
        </ContainerSvg>
      </ContainerInput>
    </FormFieldContainer>
  );
};

const ContainerInput = styled.div<{
  error?: boolean;
  isEditable: boolean;
}>`
  position: relative;
  width: calc(50% - 10px);

  input {
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.cFE4242 : theme.colors.cDFE1E6};
    border-radius: 6px;
    outline: none;
    width: 210px;
    height: 55px;
    padding-left: 12px;
  }

  input:focus {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }

  .react-datepicker {
    display: flex;
  }

  .react-datepicker-popper {
    z-index: 4;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 7px 10px;
  }
`;

const ContainerSvg = styled.div`
  pointer-events: none;
  position: absolute;
  top: 17px;
  left: 175px;
  @media (max-width: 1200px) {
    display: none;
  }
`;
