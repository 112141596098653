import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Authorization,
  AuthorizedActorType,
  EntityType,
  UpdateAuthorizationPolicyInput,
} from '@pec-manager/graphql';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { RoundedAvatarGroup } from '../../../components/avatar/RoundedAvatarGroup';
import { extractFullName } from '../../../utils/stringUtils';
import { useTranslation } from 'react-i18next';
import { ModifyAuthButton } from './ModifyAuthButton';
import { DynamicGenericUserField } from '../../../components/common/GenericUserSelect';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import { UPDATE_ENTITY_AUTHORIZATION_POLICY_FOR_ACTOR } from '../../../graphql/mail/mutations';
import { useParams } from 'react-router-dom';
import { KeycloakService } from '../../../services/KeycloakService';

interface CustomSectionProps {
  authorizations: Authorization[];
  entityType: EntityType;
  entityId: string;
  refetchDocument: () => void;
}

const mapAuthorizationsText = ({
  t,
  canManageAcl,
  canDelete,
  canRead,
  canUpdate,
  canCreate,
}: {
  t: any;
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canManageAcl: boolean;
}) => {
  const text = [];

  if (canCreate) {
    text.push(t('canCreate'));
  }
  if (canRead) {
    text.push(t('canRead'));
  }
  if (canUpdate) {
    text.push(t('canUpdate'));
  }
  if (canDelete) {
    text.push(t('canDelete'));
  }
  if (canManageAcl) {
    text.push(t('canManageAcl'));
  }
  return text.join(', ');
};

export const AuthSectionDocument: FunctionComponent<CustomSectionProps> = ({
  authorizations,
  entityId,
  entityType,
  refetchDocument,
}) => {
  const { appId } = useParams();
  const ref = useRef(null);

  const havePermissionManageAcl = authorizations.find(
    (v) =>
      v.actorType === AuthorizedActorType.USER &&
      v.user?.id === KeycloakService.getId() &&
      v.canManageAcl
  );

  const { t } = useTranslation();

  const [userAuths, setUserAuths] = useState<Authorization[]>(authorizations);
  useEffect(() => {
    if (authorizations) {
      setUserAuths(authorizations);
    }
  }, [authorizations]);

  const [addNewUserAuth] = useMutationHook({
    mutationGql: UPDATE_ENTITY_AUTHORIZATION_POLICY_FOR_ACTOR,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const handleAuthChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    userId: string
  ) => {
    const { name, checked } = e.target;
    const newAuths = userAuths.map((v: Authorization) => {
      if (v.user?.id === userId) {
        return {
          ...v,
          [name]: checked,
        };
      }
      return v;
    });
    const input: UpdateAuthorizationPolicyInput = {
      entityId,
      entityType,
      actorType:
        newAuths.find((v) => v.user?.id === userId)?.actorType ||
        AuthorizedActorType.USER,
      actorId: userId,
      policy: {
        canCreate:
          newAuths.find((v) => v.user?.id === userId)?.canCreate || false,
        canDelete:
          newAuths.find((v) => v.user?.id === userId)?.canDelete || false,
        canManageAcl:
          newAuths.find((v) => v.user?.id === userId)?.canManageAcl || false,
        canRead: newAuths.find((v) => v.user?.id === userId)?.canRead || false,
        canUpdate:
          newAuths.find((v) => v.user?.id === userId)?.canUpdate || false,
      },
    };
    addNewUserAuth({
      variables: {
        input,
        appId,
      },
    }).then(() => {
      setUserAuths(newAuths);
    });
  };
  return (
    <Container column ref={ref}>
      <SectionTitle
        text="permissionAndAuths"
        fontSize="14px"
        lineHeight="17px"
        fontWeight="600"
      />
      <ItemContainer column>
        <TopLabel>
          <UserContainer>
            <Text
              text="name"
              fontSize="10px"
              lineHeight="15px"
              fontWeight="600"
              color={Theme.colors.c505050}
              style={{ textTransform: 'uppercase' }}
              ellipsis
            />
          </UserContainer>
          <PermissionContainer>
            <Text
              text="auths"
              fontSize="10px"
              lineHeight="15px"
              fontWeight="600"
              color={Theme.colors.c505050}
              style={{ textTransform: 'uppercase' }}
              ellipsis
            />
          </PermissionContainer>
        </TopLabel>
        {userAuths.map(
          ({
            user,
            canCreate,
            canDelete,
            canManageAcl,
            canUpdate,
            canRead,
            group,
            actorType,
          }) => {
            const calcName: { name: string; type: 'GROUP' | 'USER' }[] = user
              ? [
                  {
                    name: extractFullName(user),
                    type: 'USER',
                  },
                ]
              : [
                  {
                    name: group!.name,
                    type: 'GROUP',
                  },
                ];

            return (
              <Item key={user?.id || group?.id}>
                <UserContainer alignItems="center">
                  <RoundedAvatarGroup team={calcName} fontSize={12} size={30} />
                  <Text
                    text={calcName[0].name}
                    fontSize="12px"
                    lineHeight="40px"
                    style={{ marginLeft: '8px' }}
                    ellipsis
                  />
                </UserContainer>
                <PermissionContainer alignItems="center">
                  <Text
                    text={mapAuthorizationsText({
                      t,
                      canCreate,
                      canDelete,
                      canManageAcl,
                      canRead,
                      canUpdate,
                    })}
                    fontSize="12px"
                    lineHeight="15px"
                  />
                </PermissionContainer>
                {havePermissionManageAcl?.canManageAcl && (
                  <ModifyAuthButton
                    userId={user?.id || ''}
                    canManageAcl={canManageAcl}
                    canCreate={canCreate}
                    canDelete={canDelete}
                    canRead={canRead}
                    canUpdate={canUpdate}
                    handleAuthChange={handleAuthChange}
                  />
                )}
              </Item>
            );
          }
        )}
        {havePermissionManageAcl?.canManageAcl && (
          <Item key="emptyField">
            <UserContainer alignItems="center">
              <DynamicGenericUserField
                openUp={
                  // @ts-ignore
                  window.innerHeight -
                    (ref as any).current?.getBoundingClientRect().top <
                  214
                }
                left
                onClickRemove={() => {}}
                width="calc(100% - 22px)"
                users={[]}
                setUsers={(el) => {
                  const inputAuth: UpdateAuthorizationPolicyInput = {
                    actorId: el.id,
                    actorType: AuthorizedActorType.USER,
                    policy: {
                      canCreate: false,
                      canDelete: false,
                      canManageAcl: false,
                      canRead: true,
                      canUpdate: false,
                    },
                    entityId,
                    entityType,
                  };
                  addNewUserAuth({
                    variables: {
                      input: inputAuth,
                      appId,
                    },
                  }).then((res: any) => {
                    refetchDocument();
                  });
                }}
                widthPlus="30px"
                heightPlus="30px"
              />
              <Text
                text="addUserOrGroup"
                fontSize="12px"
                lineHeight="40px"
                style={{ marginLeft: '8px' }}
                ellipsis
              />
            </UserContainer>
            <PermissionContainer alignItems="center" />
          </Item>
        )}
      </ItemContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: fit-content;

  &:not(:last-child) {
    margin-bottom: 24px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.cDCDCDC};
  }
`;

const ItemContainer = styled(FlexContainer)``;

const SectionTitle = styled(Text)`
  width: 100%;
  padding-bottom: 16px;
`;

const Item = styled(FlexContainer)`
  width: 100%;
  padding: 8px 12px;
`;

const TopLabel = styled(Item)`
  background-color: ${({ theme }) => theme.colors.cEFEFEF};
`;
const UserContainer = styled(FlexContainer)`
  width: 40%;
`;
const PermissionContainer = styled(FlexContainer)`
  width: calc(60% - 30px);
  border-left: 1px solid ${({ theme }) => theme.colors.cE4E7E7};
  padding-left: 14px;
`;
