import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useLocation, Routes, Route } from 'react-router-dom';
import { FlexContainer } from '../components/layout/FlexContainer';
import { ChatList } from './ChatList';
import { ChatContainer } from './ChatContainer';
import { ChatDetails } from './ChatDetails';
import useEventListener from '../components/hooks/useEventListener';

const Chat: React.FunctionComponent = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chatId = searchParams.get('chatId');

  const refContainer = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (refContainer.current) {
      setWidth((refContainer.current as any)?.offsetWidth + 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refContainer.current]);

  useEventListener('resize', () =>
    setWidth((refContainer.current as any)?.offsetWidth + 50)
  );

  return (
    <StyledMotionDiv>
      <FlexContainer ref={refContainer}>
        <Routes>
          <Route
            path="/"
            element={
              <ChatContainer>
                <ChatList />
                {chatId && <ChatDetails viewInModal={width <= 1040} />}
              </ChatContainer>
            }
          />
        </Routes>
      </FlexContainer>
    </StyledMotionDiv>
  );
};

const StyledMotionDiv = styled(motion.div)`
  flex: 1;
`;

export default Chat;
