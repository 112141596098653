import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { RecordDetail } from '@pec-manager/graphql';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Color } from '../../../theme';
import { Text } from '../../../components/common/Text';
import { ItemCustomSection } from './ItemCustomSection';
import {
  orderArrayByName,
  trasformStringInUppercaseForRecord,
} from '../../../utils/stringUtils';

interface CustomSectionProps {
  record: RecordDetail;
}

export const CustomSectionRecord: FunctionComponent<CustomSectionProps> = ({
  record,
}) => {
  return (
    <>
      {record.fieldSets ? (
        record.fieldSets.map(({ name, columns }) => {
          const haveComplex = columns?.nested?.some(
            (column: any) => column.value?.complex
          );

          const haveComplexInList = columns?.nested?.some((column: any) =>
            column.value?.list?.some((value: any) => value?.complex)
          );

          return (
            <Container column>
              <SectionTitle text={name} fontSize="14px" lineHeight="21px" />
              <ContainerFieldDetails
                wrapContent
                style={{ height: 'fit-content' }}
                removeBackground={haveComplex || haveComplexInList}
              >
                {columns?.nested
                  ? columns.nested
                      .sort(orderArrayByName)
                      .map(({ value, name: _name }) => {
                        if (value?.list && value.list.length > 0) {
                          return value.list.map((valueInList, index) => {
                            if (valueInList?.complex) {
                              return (
                                <FlexContainer column>
                                  <SectionTitle
                                    text={
                                      trasformStringInUppercaseForRecord(
                                        _name
                                      ) +
                                      '#' +
                                      (index + 1)
                                    }
                                    fontSize="14px"
                                    lineHeight="21px"
                                    key={index}
                                    style={{ marginTop: '12px' }}
                                  />
                                  <ContainerFieldDetails
                                    wrapContent
                                    alignItems="stretch"
                                  >
                                    {valueInList.complex.nested
                                      ?.sort(orderArrayByName)
                                      .map(
                                        ({
                                          name: nestedName,
                                          value: valueInComplex,
                                        }) => (
                                          <ItemCustomSection
                                            key={nestedName}
                                            name={nestedName}
                                            value={valueInComplex}
                                            columns={true}
                                          />
                                        )
                                      )}
                                  </ContainerFieldDetails>
                                </FlexContainer>
                              );
                            } else {
                              return (
                                <ItemCustomSection
                                  value={value}
                                  name={_name}
                                  columns={columns}
                                />
                              );
                            }
                          });
                        }
                        if (value?.complex) {
                          return (
                            <div>
                              <SectionTitle
                                text={name}
                                fontSize="14px"
                                lineHeight="21px"
                              />
                              <ContainerFieldDetails
                                wrapContent
                                style={{ height: 'fit-content' }}
                              >
                                {value.complex.nested
                                  ?.sort(orderArrayByName)
                                  .map(({ name: _name, value }) => {
                                    return (
                                      <ItemCustomSection
                                        key={_name}
                                        name={_name}
                                        value={value}
                                        columns={columns}
                                      />
                                    );
                                  })}
                              </ContainerFieldDetails>
                            </div>
                          );
                        } else {
                          return (
                            <ItemCustomSection
                              value={value}
                              name={_name}
                              columns={columns}
                            />
                          );
                        }
                      })
                  : null}
              </ContainerFieldDetails>
            </Container>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export const Circle = styled.div<{
  color: string | Color;
  sizeCircle?: string;
  marginLeft?: string;
}>`
  background-color: ${({ color }) => color};
  height: ${({ sizeCircle }) => sizeCircle};
  width: ${({ sizeCircle }) => sizeCircle};
  min-height: ${({ sizeCircle }) => sizeCircle};
  min-width: ${({ sizeCircle }) => sizeCircle};
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ marginLeft }) => marginLeft};
`;

const Container = styled(FlexContainer)`
  width: 100%;
  padding: 12px 0px;
  margin: 16px 0;
`;

const ContainerFieldDetails = styled(FlexContainer)<{
  removeBackground?: boolean;
}>`
  width: 100%;
  background-color: ${({ theme, removeBackground }) =>
    removeBackground ? '' : theme.colors.cFFFFFF};

  border-radius: 8px;
  padding: ${({ removeBackground }) => (removeBackground ? '0' : '12px 8px;')};
  ${({ removeBackground }) => !removeBackground && 'overflow: auto;'};
  box-shadow: ${({ removeBackground }) =>
    removeBackground ? 'none' : '0px 4px 20px 0px rgba(0, 0, 0, 0.15)'};
`;

const SectionTitle = styled(Text)`
  padding-bottom: 16px;
  margin-left: 6px;
`;
