import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { Color } from '../../theme';
import Linkify from 'react-linkify';
import { componentDecorator } from './ComponentDecorator';
import { KeycloakService } from '../../services/KeycloakService';
import { escape } from 'lodash';

interface TextProps {
  text: string;
  fontWeight?: 'bold' | '600' | '500' | '300' | 'normal';
  fontSize: string;
  lineHeight: string;
  color?: Color | string;
  className?: string;
  skipTranslation?: boolean;
  translationArgs?: any;
  tooltipText?: string;
  tooltipTextArgs?: any;
  ellipsis?: boolean;
  style?: any;
  breakWord?: boolean;
  possibleLink?: boolean;
}

export const Text: FunctionComponent<TextProps> = ({
  text,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  className,
  skipTranslation,
  translationArgs,
  tooltipText,
  tooltipTextArgs,
  ellipsis,
  style,
  breakWord,
  possibleLink,
}) => {
  const { t } = useTranslation();

  if (!skipTranslation && text) {
    // get the translation from token using t_ prefix for the user attributes
    const kText = KeycloakService.getTranslationFor(text);
    if (kText) {
      text = kText;
      skipTranslation = true;
    }
  }

  return (
    <TextContainer
      style={style}
      className={className}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      color={color}
      title={
        skipTranslation
          ? tooltipText
          : (t(tooltipText || '', tooltipTextArgs) as unknown as string)
      }
      ellipsis={ellipsis}
      breakWord={breakWord}
    >
      {skipTranslation ? (
        possibleLink ? (
          <Linkify componentDecorator={componentDecorator}>{text}</Linkify>
        ) : (
          text
        )
      ) : (
        <Trans
          i18nKey={escape(text)}
          shouldUnescape={true}
          values={translationArgs}
          components={[
            <b key={0} />,
            <b key={1} className="weight400" />,
            <b key={2} className="blue" />,
          ]}
        />
      )}
    </TextContainer>
  );
};

type TextContainerProps = Omit<TextProps, 'text'>;

const TextContainer = styled.span<TextContainerProps>`
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color, theme }) => color ?? theme.colors.c000000};
  ${({ breakWord }) => breakWord && 'word-break: break-word;'}
  ${({ ellipsis }) =>
    ellipsis &&
    `
     white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `}

  & > b {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.c505050};

    &.weight400 {
      font-weight: 400;
    }

    &.blue {
      color: ${({ theme }) => theme.colors.c5B84EF};
    }
  }
`;
