import { useDispatch, useSelector } from 'react-redux';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { MailSummary } from '@pec-manager/graphql';
import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  GET_MAILS_BY_ASSIGNMENT_TYPE,
  GET_MAILS_BY_FILLED_ADVANCED_SEARCH_FORM,
  GET_MAILS_BY_SAVED_SEARCH_ID,
  GET_MAILS_BY_SIMPLE_SEARCH,
  GET_MAILS_BY_STATE,
  GET_MAILS_IN_ALL_ARCHIVES,
  GET_MAILS_IN_ARCHIVE,
  GET_MAILS_IN_INBOX,
} from '../../graphql/mail/queries';
import { InboxSidebarDefaultSectionId } from '../../inbox/sidebar/inboxSidebarDefaultSectionId';
import { InboxSidebarMainSectionElementId } from '../../inbox/sidebar/inboxSidebarDefaultSectionElements';
import { mailStateSelector } from '../../redux/Inbox/mail/selectors';
import { appendMails, clearMailList } from '../../redux/Inbox/mail/actions';
import { gqlName } from '../../graphql/utils';
import { MAILS_PAGE_SIZE, mailStateKeys } from '../../constant';

function computeGqlVariablesWithOffset(gqlVariables: any, offset: number) {
  return {
    ...gqlVariables,
    pagination: {
      offset,
      limit: MAILS_PAGE_SIZE,
    },
  };
}
export const useMailsQuery: () => {
  fetchMore: () => void;
  mails: MailSummary[];
  mailCount: number;
  error?: ApolloError;
  loading: boolean;
} = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const { appId } = useParams();
  const sectionId = searchParams.get('sectionId');
  const sectionElementId = searchParams.get('elementId');
  const sectionElementDropDownElementId = searchParams.get('dropDownId');

  const {
    mails,
    mailCount,
    simpleSearchFilter,
    advancedSearchFilledForm,
    advancedSearchPageId,
  } = useSelector(mailStateSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMailList());
  }, [
    sectionId,
    sectionElementId,
    sectionElementDropDownElementId,
    dispatch,
    simpleSearchFilter,
  ]);

  const { gqlQuery, gqlQueryVariables } = useMemo(() => {
    let query: any | null = null;
    let queryVariables: any = {};

    if (location.pathname === `/${appId}/inbox/search`) {
      switch (true) {
        // eslint-disable-next-line eqeqeq
        case !!simpleSearchFilter:
          query = GET_MAILS_BY_SIMPLE_SEARCH;
          queryVariables = {
            ...queryVariables,
            appId,
            searchFilter: simpleSearchFilter || '',
          };
          break;
        case !!advancedSearchFilledForm && !!advancedSearchPageId:
          query = GET_MAILS_BY_FILLED_ADVANCED_SEARCH_FORM;
          queryVariables = {
            ...queryVariables,
            appId,
            filledForm: advancedSearchFilledForm,
          };
          break;
        default:
          // Consistency issue
          return {
            gqlQuery: null,
            gqlQueryVariables: null,
          };
      }
    } else {
      switch (sectionId) {
        case InboxSidebarDefaultSectionId.MAIN:
          switch (true) {
            case mailStateKeys.some((s) => s === sectionElementId):
              query = GET_MAILS_BY_STATE;
              queryVariables = {
                ...queryVariables,
                appId,
                mailState: sectionElementId,
              };
              break;
            case sectionElementId === InboxSidebarMainSectionElementId.INBOX:
              if (sectionElementDropDownElementId) {
                query = GET_MAILS_BY_ASSIGNMENT_TYPE;
                queryVariables = {
                  ...queryVariables,
                  appId,
                  assignmentType: sectionElementDropDownElementId,
                };
              } else {
                query = GET_MAILS_IN_INBOX;
                queryVariables = {
                  ...queryVariables,
                  appId,
                };
              }
              break;
            case sectionElementId === InboxSidebarMainSectionElementId.ARCHIVE:
              if (sectionElementDropDownElementId) {
                query = GET_MAILS_IN_ARCHIVE;
                queryVariables = {
                  ...queryVariables,
                  appId,
                  archiveId: sectionElementDropDownElementId,
                };
              } else {
                query = GET_MAILS_IN_ALL_ARCHIVES;
                queryVariables = {
                  ...queryVariables,
                  appId,
                };
              }
          }
          break;
        case InboxSidebarDefaultSectionId.SAVED_SEARCHES:
          query = GET_MAILS_BY_SAVED_SEARCH_ID;
          queryVariables = {
            ...queryVariables,
            appId,
            savedSearchId: sectionElementId,
          };
          break;
      }
    }
    return { gqlQuery: query, gqlQueryVariables: queryVariables };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectionId,
    sectionElementId,
    sectionElementDropDownElementId,
    simpleSearchFilter,
  ]);

  if (!(gqlQuery && gqlQueryVariables)) {
    navigate(`/${appId}/inbox?sectionId=MAIN&elementId=INBOX`);
  }

  const [loadQuery, { loading, error, data }] = useLazyQuery(
    gqlQuery || GET_MAILS_IN_INBOX,
    {
      fetchPolicy: 'network-only',
      variables: computeGqlVariablesWithOffset(gqlQueryVariables, 0),
    }
  );

  useEffect(() => {
    if (data) {
      const { totalElements, pageElements } = data[gqlName(gqlQuery!)];
      dispatch(
        appendMails({
          mailCount: totalElements,
          mails: pageElements,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!mails) {
      loadQuery({
        variables: computeGqlVariablesWithOffset(gqlQueryVariables, 0),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mails, sectionId, sectionElementId, sectionElementDropDownElementId]);

  return {
    fetchMore: () => {
      loadQuery({
        variables: computeGqlVariablesWithOffset(
          gqlQueryVariables,
          mails?.length || 0
        ),
      });
    },
    mails: mails || [],
    mailCount,
    error,
    loading: loading || gqlQuery == null,
  };
};
