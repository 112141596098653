import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { KeycloakService } from '../../services/KeycloakService';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ProgressFlows } from '../../inbox/mail/ProgressFlows';
import { Text } from '../../components/common/Text';
import { useLocation } from 'react-router-dom';
import { ResourceAssignmentType, ResourceState } from '@pec-manager/graphql';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';
import { SearchBar } from '../../components/common/Search';

interface TaskContainerHeaderProps {
  setFullSearchText: (value: string) => void;
}

export const TaskContainerHeader: FunctionComponent<
  TaskContainerHeaderProps
> = ({ setFullSearchText }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sectionId = searchParams.get('sectionId');
  const sectionElementId = searchParams.get('elementId');
  const sectionElementDropDownElementId = searchParams.get('dropDownId');
  const [searchValue, setSearchValue] = React.useState('');

  const titlePage = useMemo(() => {
    if (sectionId === 'MAIN') {
      if (sectionElementId === ResourceState.EXPIRED) {
        return 'Tasks expired';
      }
      if (sectionElementId === ResourceState.WORKED) {
        return 'Tasks completed';
      }
      if (sectionElementDropDownElementId === ResourceAssignmentType.DEFAULT) {
        return 'Tasks in charge';
      }
      if (sectionElementDropDownElementId === ResourceAssignmentType.GROUP) {
        return 'Tasks assigned';
      }
      if (
        sectionElementDropDownElementId === ResourceAssignmentType.TO_OTHERS
      ) {
        return 'Tasks assigned to others';
      }

      return 'Tasks';
    }
    return '';
  }, [sectionElementDropDownElementId, sectionElementId, sectionId]);

  return (
    <Container column>
      <TopContainer alignItems="center" justifyContent="space-between">
        <TitleWithChooseApp />
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </TopContainer>
      <BottomContainer alignItems="center" justifyContent="space-between">
        <LeftContainer alignItems="center">
          {searchValue.length > 0 ? (
            <Text
              text="searchWithWords"
              translationArgs={{ wordsToSearch: searchValue }}
              fontSize="18px"
              lineHeight="27px"
              fontWeight="500"
            />
          ) : (
            <Text
              text={titlePage}
              fontSize="18px"
              lineHeight="27px"
              fontWeight="500"
            />
          )}
        </LeftContainer>
        <RightContainer justifyContent="flex-end">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            actionSearchBar={setFullSearchText}
            typeEntityAutocomplete={[]}
          />
          <ProgressFlows />
        </RightContainer>
      </BottomContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 106px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  height: 64px;
  padding: 0 16px;
`;

const LeftContainer = styled(FlexContainer)`
  max-width: 465px;
  width: 100%;
  height: 56px;
  padding-left: 16px;
`;

const RightContainer = styled(FlexContainer)`
  width: 100%;
  padding-right: 20px;
  margin-right: -6px;
`;

const TopContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

/* const SvgClickable = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  margin-right: 4px;
  cursor: pointer;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`; */
