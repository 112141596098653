import { gql } from '@apollo/client/core';
import { gqlUserResolutionShape } from './shapes';

export const FIND_USERS = gql`
  query FindUsers($searchFilter: String) {
      findUsers(searchFilter: $searchFilter) {
        ${gqlUserResolutionShape}
    }
  }
`;

export const FIND_MAILS = gql`
  query FindMails($searchFilter: String) {
    findMails(searchFilter: $searchFilter)
  }
`;
