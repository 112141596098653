import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ContainerModal } from '../ContainerModal';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../layout/FlexContainer';
import { SvgIcon } from '../../common/SvgIcon';
import { Close } from '../../../svg';
import { DynamicGenericTextField } from './DynamicGenericTextField';
import { Button } from '../../common/Button';
import { useMutationHook } from '../../hooks/useMutationHook';
import { CREATE_CHAT } from '../../../graphql/chat/mutations';

interface SimpleSearchModalProps {
  onClickClose: () => void;
}

export const NewChatModal: FunctionComponent<SimpleSearchModalProps> = ({
  onClickClose,
}) => {
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
  const [submitCreateChat] = useMutationHook({
    mutationGql: CREATE_CHAT,
    errorMessage: 'genericErrorMessage',
  });
  const navigate = useNavigate();

  return (
    <ContainerModal position="absolute">
      <Container onClick={(e) => e.stopPropagation()}>
        <ContainerHeader alignItems="center" justifyContent="space-between">
          <Text text="addPeopleChat" fontSize="16px" lineHeight="24px" />
          <ClickableIcon onClick={() => onClickClose()}>
            <SvgIcon
              svg={<Close />}
              width="10px"
              height="10px"
              color={Theme.colors.cFFFFFF}
            />
          </ClickableIcon>
        </ContainerHeader>
        <InnerContainer column>
          <DynamicGenericTextField
            data={invitedUsers}
            setData={setInvitedUsers}
          />
          <ContainerButtons alignItems="center">
            <ButtonSearch
              disabled={invitedUsers.length <= 0}
              onClick={() => {
                submitCreateChat({
                  variables: {
                    invitedUsers,
                  },
                }).then((r: any) =>
                  navigate(`/chat?chatId=${r.data.createChatRoom}`)
                );
                onClickClose();
              }}
              backgroundColor={Theme.colors.c5B84EF}
              hoverBackgroundColor={Theme.colors.c4C9AFF}
              padding="6px 14px"
              borderRadius="8px"
            >
              <Text
                text="submit"
                fontSize="14px"
                lineHeight="24px"
                fontWeight="600"
                color={Theme.colors.cFFFFFF}
              />
            </ButtonSearch>
          </ContainerButtons>
        </InnerContainer>
      </Container>
    </ContainerModal>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  width: 60%;
  max-width: 940px;
`;

const ContainerHeader = styled(FlexContainer)`
  height: 70px;
  border-radius: 12px 12px 0 0;
  opacity: 0.98;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 24px;
`;

const InnerContainer = styled(FlexContainer)`
  padding: 24px 32px 24px 24px;
  height: calc(100% - 70px);
`;

const ContainerButtons = styled(FlexContainer)`
  margin-top: auto;
  margin-left: auto;
`;

const ClickableIcon = styled.div`
  margin-right: 10px;
  height: 20px;
  min-height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.c838383};
`;

const ButtonSearch = styled(Button)`
  margin-left: 10px;
  margin-top: 12px;
`;
