import React from 'react';
import { Dispatch } from 'redux';
import { KeycloakService } from '../../services/KeycloakService';
import { toggleNotificationModalAction } from '../../redux/notification/sidebar/actions';
import { Bell, Chat, Dashboard, HamburgerMenu, Logout } from '../../svg';
import { setOpenMenuMobile } from '../../redux/Sintropi/sidebar/actions';

interface SidebarElement {
  path?: string;
  svg: JSX.Element;
  onClickHandler?: (dispatch?: Dispatch, menuMobileIsOpen?: boolean) => void;
  pathlessId?: 'hamburgerMenu' | 'bell';
}

export const sidebarElements: SidebarElement[] = [
  {
    svg: <HamburgerMenu />,
    pathlessId: 'hamburgerMenu',
    onClickHandler: (dispatch, menuMobileIsOpen) => {
      dispatch && dispatch(setOpenMenuMobile(!menuMobileIsOpen));
    },
  },
  {
    svg: <Bell />,
    pathlessId: 'bell',
    onClickHandler: (dispatch) => {
      dispatch && dispatch(toggleNotificationModalAction());
    },
  },
  {
    path: '/',
    svg: <Dashboard />,
  },
  {
    path: '/chat',
    svg: <Chat />,
  },
  {
    svg: <Logout />,
    onClickHandler: () => KeycloakService.doLogout(),
  },
];
