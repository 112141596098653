// noinspection JSIgnoredPromiseFromCall

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  ChatResourceType,
  ChatRoom,
  ChatRoomEventType,
  NotificationResourceType,
} from '@pec-manager/graphql';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FlexContainer } from '../components/layout/FlexContainer';
import { Text } from '../components/common/Text';
import { Theme } from '../theme';
import { getDateAsString, getTimeAsString } from '../utils/dateUtils';
import { RECEIVE_MESSAGE_CHAT } from '../graphql/chat/mutations';
import { decomposeRawWithMentions } from '../utils/mention';
import { extractTitleChat, IconChat } from './ChatDetails/utilis';
import { SvgIcon } from '../components/common/SvgIcon';
import { Chat } from '../svg';
import { RoundedCircle } from '../components/avatar/RoundedTextAvatar';
import { getMappedColor } from '../utils/colorUtils';
import { GET_RESOURCE_CONTEXT } from '../graphql/resource/queries';

export const ChatItem: FunctionComponent<{
  chatRoom: ChatRoom;
  chatEvent: any;
}> = ({ chatRoom, chatEvent }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSelected = chatRoom.id === searchParams.get('chatId');

  const [chatRoomDiffs, setChatRoomDiffs] = useState({
    unreadMessages: chatRoom.unreadMessages,
    lastMessage: chatRoom.lastMessagePreview,
    participants: chatRoom.participants,
  });

  useEffect(() => {
    if (isSelected) {
      setChatRoomDiffs((prevState) => ({ ...prevState, unreadMessages: 0 }));
    }
  }, [isSelected]);

  const [receivedMessageById] = useMutation(RECEIVE_MESSAGE_CHAT);

  const navigate = useNavigate();

  useEffect(() => {
    switch (chatEvent?.chatRoomListEventReceived.eventType) {
      case ChatRoomEventType.PARTICIPANT_JOINED:
        if (
          chatRoom.id &&
          chatEvent.chatRoomListEventReceived.participantJoinedEvent
            .chatRoomId === chatRoom.id
        ) {
          setChatRoomDiffs((prevState) => ({
            ...prevState,
            participants: [
              ...prevState.participants,
              chatEvent.chatRoomListEventReceived.actor,
            ],
          }));
        }
        break;
      case ChatRoomEventType.PARTICIPANT_LEFT:
        if (
          chatEvent?.chatRoomListEventReceived.participantLeftEvent
            .chatRoomId === chatRoom.id
        ) {
          setChatRoomDiffs((prevState) => ({
            ...prevState,
            participants: prevState.participants.filter(
              (u) => u.id !== chatEvent.chatRoomListEventReceived.actor.id
            ),
          }));
        }
        break;
      case ChatRoomEventType.MESSAGE_POSTED:
        if (
          chatEvent?.chatRoomListEventReceived.messagePostedEvent.chatRoomId ===
          chatRoom.id
        ) {
          receivedMessageById({
            variables: {
              chatRoomId: chatRoom.id,
              messageOffset:
                chatEvent?.chatRoomListEventReceived.messagePostedEvent
                  .messageNumber,
            },
          });
          setChatRoomDiffs((prevState) => ({
            ...prevState,
            unreadMessages: isSelected ? 0 : prevState.unreadMessages + 1,
            lastMessage: {
              message:
                chatEvent?.chatRoomListEventReceived.messagePostedEvent.message,
              receivedAt: new Date(),
            },
          }));
        }
        break;
      case ChatRoomEventType.PARTICIPANT_INVITED:
        if (
          chatRoom.id &&
          chatEvent.chatRoomListEventReceived.participantInvitedEvent
            .chatRoomId === chatRoom.id
        ) {
          setChatRoomDiffs((prevState) => ({
            ...prevState,
            participants: [
              chatEvent.chatRoomListEventReceived.participantInvitedEvent.user,
              ...prevState.participants.filter(
                (m) =>
                  m.id !==
                  chatEvent.chatRoomListEventReceived.participantInvitedEvent
                    .user.id
              ),
            ],
          }));
        }
        break;
      case ChatRoomEventType.PARTICIPANT_MESSAGE_RECEIVED:
      case ChatRoomEventType.PARTICIPANT_MESSAGE_READ:
      case ChatRoomEventType.RESOURCE_LINKED:
      case ChatRoomEventType.CREATED:
      case ChatRoomEventType.CLOSED:
      case ChatRoomEventType.REOPENED:
      default:
        break;
    }
    // eslint-disable-next-line
  }, [chatEvent, receivedMessageById]);

  const [getResourceContext, { data: context }] =
    useLazyQuery(GET_RESOURCE_CONTEXT);

  useEffect(() => {
    if (chatRoom.resourceId) {
      getResourceContext({
        variables: {
          resourceId: chatRoom.resourceId,
          resourceType: NotificationResourceType.EXECUTION,
        },
      });
    }
    // eslint-disable-next-line
  }, [chatRoom, location]);

  const Icon = useMemo(() => {
    if (chatRoom.resourceId?.length === 0) {
      return <Chat />;
    }
    if (context?.getResourceContext.context) {
      const ExtractIcon = IconChat(context.getResourceContext.context.type);
      return <ExtractIcon />;
    }
    if (chatRoom.resourceType === ChatResourceType.NONE) {
      return <Chat />;
    }
    return undefined;
    // eslint-disable-next-line
  }, [context]);

  const dateNow = chatRoomDiffs.lastMessage?.receivedAt
    ? getDateAsString(Date.now()) ===
      (getDateAsString(chatRoomDiffs.lastMessage?.receivedAt as any) as string)
    : undefined;

  return (
    <ContainerMail
      selected={isSelected}
      onClick={() => navigate(`/chat?chatId=${chatRoom.id}`)}
    >
      <ContainerAvatar alignItems="center" justifyContent="center">
        {Icon && (
          <RoundedCircle
            backgroundColor={getMappedColor(
              context?.getResourceContext?.context?.resourceName ||
                extractTitleChat(chatRoomDiffs.participants as any)
            )}
            size={30}
            justifyContent="center"
            alignItems="center"
          >
            <SvgIcon
              svg={Icon}
              height="20px"
              width="20px"
              color={Theme.colors.cFFFFFF}
            />
          </RoundedCircle>
        )}
      </ContainerAvatar>
      <ContentContainer>
        <FlexContainer
          column
          style={{ width: '100%' }}
          justifyContent="space-between"
        >
          <TopContainer alignItems="center" justifyContent="space-between">
            <Text
              text={
                context?.getResourceContext?.context?.resourceName ||
                extractTitleChat(chatRoomDiffs.participants as any)
              }
              fontSize="14px"
              lineHeight="16px"
              ellipsis
            />
            {chatRoomDiffs.lastMessage?.receivedAt && (
              <TimeText
                text={
                  dateNow
                    ? getTimeAsString(
                        chatRoomDiffs.lastMessage.receivedAt as any as number
                      )
                    : 'completeDate'
                }
                translationArgs={{
                  date: getDateAsString(
                    chatRoomDiffs.lastMessage.receivedAt as any as number
                  ),
                  time: getTimeAsString(
                    chatRoomDiffs.lastMessage.receivedAt as any as number
                  ),
                  fresh: true,
                }}
                fontSize="12px"
                lineHeight="12px"
                fontWeight="300"
                color={Theme.colors.c505050}
              />
            )}
          </TopContainer>
          {chatRoomDiffs.lastMessage?.receivedAt && (
            <BottomContainer alignItems="center" justifyContent="space-between">
              <ContainerMessage>
                {decomposeRawWithMentions(
                  chatRoomDiffs.lastMessage?.message
                ).map((m, index) => {
                  /* eslint-disable react/no-array-index-key */
                  if (m) {
                    return (
                      <Text
                        key={index}
                        text={
                          (m as any).id
                            ? (m as any).display
                            : (m as any).textElement
                        }
                        fontSize="12px"
                        lineHeight="12px"
                        color={Theme.colors.c505050}
                      />
                    );
                  }
                  return null;
                })}
              </ContainerMessage>
              {!isSelected && chatRoomDiffs.unreadMessages !== 0 && (
                <TipsUnreadMessage
                  alignItems="center"
                  justifyContent="center"
                  bean={chatRoomDiffs.unreadMessages > 9}
                >
                  <Text
                    text={chatRoomDiffs.unreadMessages.toString()}
                    fontSize="10px"
                    lineHeight="10px"
                    fontWeight="600"
                    color={Theme.colors.c0E53B7}
                  />
                </TipsUnreadMessage>
              )}
            </BottomContainer>
          )}
        </FlexContainer>
      </ContentContainer>
    </ContainerMail>
  );
};

const ContainerMail = styled(FlexContainer)<{ selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFAFAFA};
  max-width: 465px;
  width: 100%;
  cursor: pointer;
  padding: 12px 16px;
  @media (max-width: 1040px) {
    max-width: 100%;
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ContentContainer = styled(FlexContainer)`
  margin-left: 12px;
  width: calc(100% - 52px);
`;

const TopContainer = styled(FlexContainer)`
  width: 100%;

  & > span:first-child {
    max-width: 80%;
  }
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  height: 20px;
`;

const ContainerAvatar = styled(FlexContainer)`
  width: 40px;
`;

const TimeText = styled(Text)`
  min-width: fit-content;
`;

const ContainerMessage = styled.div`
  max-width: 100%;
  width: 100%;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TipsUnreadMessage = styled(FlexContainer)<{ bean: boolean }>`
  margin-left: 16px;
  border-radius: 50%;
  height: 20px;
  padding: 5px 5px;
  min-width: ${({ bean }) => (bean ? 'fit-content' : '20px')};
  border: 1px solid ${({ theme }) => theme.colors.c0E53B7};

  & > span {
    margin-top: 1px;
  }
`;
