import { gql } from '@apollo/client/core';
import { gqlPagedFormDefinitionResolutionShape } from '../form/shapes';
import {
  gqlBaseDocumentFolderShape,
  gqlBaseDocumentShape,
} from '../document/shapes';

export const GET_LATEST_SIMPLE_SEARCHES = gql`
  query GetLatestSimpleSearches($appId: ID!) {
    getLatestSimpleSearches(appId: $appId)
  }
`;

export const GET_SAVED_SEARCHES = gql`
    query GetSavedSearches($appId: ID!) {
        getSavedSearches(appId: $appId) {
            id
            name
            definition {
                ${gqlPagedFormDefinitionResolutionShape}
            }
        }
    }
`;

export const SEARCH_SINTROPI_ENTITIES_BY_FULL_TEXT = gql`
  query SearchSintropiEntitiesByFullText($input: SearchSintropiEntitiesByFullTextInput!) {
      searchSintropiEntitiesByFullText(input: $input) {
      totalElements
      pageElements {
        document {
            ${gqlBaseDocumentShape}
        }
        folder {
            ${gqlBaseDocumentFolderShape}
        }
      }
    }
  }
`;

export const AUTOCOMPLETE = gql`
  query Autocomplete($input: AutocompleteInput!) {
    autocomplete(input: $input) {
      text
      queryPlusText
    }
  }
`;
