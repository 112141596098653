import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { Color, Theme } from '../../theme';
import { ProgressBar } from '../../components/common/ProgressBar';

interface ProgressBarDetailProps {
  total: number;
  partial: number;
  title: string;
  fillerColor: Color | string;
}

export const ProgressBarDetail: FunctionComponent<ProgressBarDetailProps> = ({
  partial,
  total,
  title,
  fillerColor,
}) => (
  <Container column justifyContent="center">
    <TitleText
      text={title}
      fontSize="10px"
      lineHeight="15px"
      fontWeight="300"
      color={Theme.colors.c505050}
    />
    <FieldContainer alignItems="center">
      <Text
        text={`${partial}`}
        fontSize="12px"
        lineHeight="12px"
        color={Theme.colors.c000000}
      />
      <ProgressBar partial={partial} total={total} fillerColor={fillerColor} />
    </FieldContainer>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  padding-left: 36px;
`;

const TitleText = styled(Text)`
  margin-bottom: 10px;
`;

const FieldContainer = styled(FlexContainer)`
  width: 100%;
  & > span:first-child {
    width: 10px;
    margin-right: 5px;
  }
`;
