import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { User } from '@pec-manager/graphql';
import { ContainerModal } from '../ContainerModal';
import { Text } from '../../common/Text';
import { FlexContainer } from '../../layout/FlexContainer';
import { Theme } from '../../../theme';
import { SearchInput } from '../../input/SearchInput';
import { Button } from '../../common/Button';
import { FIND_USERS } from '../../../graphql/user/queries';
import { REASSIGN_MAIL } from '../../../graphql/mail/mutations';
import { useNotificationBanner } from '../../hooks/useNotificationBanner';
import { NotificationBanner } from '../../../redux/notification/sidebar/reducers';
import { RoundedTextAvatar } from '../../avatar/RoundedTextAvatar';
import { REASSIGN_TASK } from '../../../graphql/task/mutations';

interface AttachmentPreviewModalProps {
  closeOnClick: () => void;
  id: string;
  type: 'TASK' | 'MAIL';
}

export const ReassignModal: FunctionComponent<AttachmentPreviewModalProps> = ({
  closeOnClick,
  id,
  type,
}) => {
  const { t } = useTranslation();
  //const [comment, _] = useState('');
  const [valueInputSearch, setValueInputSearch] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');

  const [reassignMail, { data: reassignMailData, error }] =
    useMutation(REASSIGN_MAIL);

  const [reassignTask, { data: reassignTaskData, error: errorTask }] =
    useMutation(REASSIGN_TASK);

  const notificationBannerSuccess: NotificationBanner = {
    title: t('genericSuccessMessage'),
    id: uuidv4(),
    type: 'success',
  };

  const notificationBannerError: NotificationBanner = {
    title: t('genericErrorMessage'),
    id: uuidv4(),
    type: 'failed',
  };

  useNotificationBanner(
    reassignMailData?.reassignMail || reassignTaskData?.reassignTask,
    error || errorTask,
    notificationBannerSuccess,
    notificationBannerError,
    closeOnClick
  );

  return (
    <ContainerModal position="fixed" zIndex={12}>
      <Container onClick={(e) => e.stopPropagation()}>
        <ContainerHeader alignItems="center">
          <Text
            text="reassignTheTask"
            fontSize="18px"
            lineHeight="18px"
            fontWeight="600"
          />
        </ContainerHeader>
        <InnerContainer column>
          <ChoosingText
            text="ChoosingTeamMembers"
            fontSize="14px"
            lineHeight="21px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
          <SearchInput
            setValueInputSearch={(val) => {
              setValueInputSearch(val);
              setCurrentUserId('');
            }}
            valueInputSearch={valueInputSearch}
            query={FIND_USERS}
            selectItemMap={(user: User) => ({
              displayValue: `${user.firstName} ${user.lastName}`,
              id: user.id,
            })}
            onSelectItemClick={(user: User) => {
              setValueInputSearch(`${user.firstName} ${user.lastName}`);
              setCurrentUserId(user.id);
            }}
            getVariablesFilterTerm={(searchFilter) => ({
              searchFilter,
            })}
            queryResultKey="findUsers"
            svgRender={(user: User) => (
              <RoundedTextAvatar
                text={`${user.firstName} ${user.lastName}`}
                size={24}
                fontSize={9.5}
              />
            )}
          />
          {/*      <ContainerComment column>
            <Input
              placeholder={t('addComment')}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <StyledText
              text="suggestionC"
              fontSize="12px"
              lineHeight="18px"
              color={Theme.colors.c505050}
            />
          </ContainerComment>*/}
          <ContainerButtons>
            <Button
              disabled={!currentUserId}
              backgroundColor={Theme.colors.c5B84EF}
              hoverBackgroundColor={Theme.colors.c5176d6}
              padding="10px 12px"
              borderRadius="6px"
              height="32px"
              onClick={() => {
                if (type === 'MAIL') {
                  reassignMail({
                    variables: {
                      mailId: id,
                      userId: [currentUserId],
                    },
                  });
                } else {
                  reassignTask({
                    variables: {
                      taskId: id,
                      userId: [currentUserId],
                    },
                  });
                }
              }}
            >
              <Text
                text="reassignTheTask"
                fontSize="12px"
                lineHeight="21px"
                color={Theme.colors.cFFFFFF}
              />
            </Button>
            <StyledButton
              backgroundColor={Theme.colors.cFFFFFF}
              padding="10px 12px"
              borderRadius="6px"
              border={`1px solid ${Theme.colors.cDCDCDC}`}
              height="32px"
              onClick={() => closeOnClick()}
            >
              <Text text="cancel" fontSize="12px" lineHeight="21px" />
            </StyledButton>
          </ContainerButtons>
        </InnerContainer>
      </Container>
    </ContainerModal>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  overflow: hidden;
  width: 537px;
  height: 382px;
`;

const ChoosingText = styled(Text)`
  margin-bottom: 8px;
`;

const ContainerHeader = styled(FlexContainer)`
  height: 70px;
  border-radius: 12px 12px 0 0;
  opacity: 0.98;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 16px;
`;

const InnerContainer = styled(FlexContainer)`
  padding: 24px 32px 24px 24px;
  height: calc(100% - 70px);
`;

/*const ContainerComment = styled(FlexContainer)`
  margin-top: 24px;
`;

const StyledText = styled(Text)`
  margin-top: 4px;
`;*/

const ContainerButtons = styled(FlexContainer)`
  margin-top: auto;
  margin-left: auto;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
`;
