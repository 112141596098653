import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BlobRefOrFolder } from '@pec-manager/graphql';
import { Text } from '../../../common/Text';
import { FlexContainer } from '../../../layout/FlexContainer';
import { AttachmentPreview } from '../../../common/AttachmentPreview';
import { HorizontalLoader } from '../../../loader/HorizontalLoader';
import { Theme } from '../../../../theme';

interface FolderSectionProps {
  blobRefList: BlobRefOrFolder[];
  loading: boolean;
}

export const AttachmentsSection: FunctionComponent<FolderSectionProps> = ({
  blobRefList,
  loading,
}) => (
  <Container>
    <div style={{ height: '13px', display: 'flex' }}>
      <Text text="file" fontSize="12px" lineHeight="18px" />
    </div>
    <ContainerAttachments wrapContent>
      {loading && (
        <HorizontalLoader size="l" top="33px" color={Theme.colors.c4C9AFF} />
      )}
      {!loading && blobRefList.length > 0 ? (
        blobRefList.map(({ blobRef }: BlobRefOrFolder) => {
          if (blobRef)
            return (
              <div key={blobRef?.id} style={{ marginRight: '37px' }}>
                <AttachmentPreview
                  cursorOnAttachment
                  disabled={true}
                  shrinkYPx={0}
                  attachment={blobRef}
                  selected={false}
                />
              </div>
            );
          else return null;
        })
      ) : (
        <EmptyAttachments
          text="notAttachment"
          fontSize="13px"
          lineHeight="13px"
          color={Theme.colors.c838383}
        />
      )}
    </ContainerAttachments>
  </Container>
);

const Container = styled.div`
  padding-left: 16px;
  margin: 24px 0 12px 0;
`;

const ContainerAttachments = styled(FlexContainer)`
  padding: 12px 0;
`;

const EmptyAttachments = styled(Text)`
  text-align: center;
`;
