import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import styled from 'styled-components';
import { Theme } from '../../theme';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { isToday } from '../../utils/stringUtils';

interface UserWithDateProps {
  date: any;
  name: string;
}

export const UserWithDate: FunctionComponent<UserWithDateProps> = ({
  date,
  name,
}) => (
  <FlexContainer style={{ width: '100%' }}>
    <RoundedTextAvatar text={name} fontSize={14} visibleTooltip />
    <Container column justifyContent="space-around">
      <Text text={name} fontSize="12px" lineHeight="20px" ellipsis />
      <Text
        text={isToday(date) ? 'todayAt' : 'completeDate'}
        translationArgs={{
          date: getDateAsString(date),
          time: getTimeAsString(date),
          fresh: true,
        }}
        fontSize="12px"
        lineHeight="20px"
        color={Theme.colors.c838383}
        ellipsis
      />
    </Container>
  </FlexContainer>
);

const Container = styled(FlexContainer)`
  height: 40px;
  margin: 0 16px;
  overflow: auto;
`;
