import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { FlexContainer } from '../components/layout/FlexContainer';
import { TakeInCharge } from './takeInCharge';
import { SintropiSidebar } from './sidebar/components/SintropiSidebar';
import { SintropiSidebarDefaultSectionId } from './sidebar/sintropiSidebarDefaultSectionId';
import { SintropiSidebarMainSectionElementId } from './sidebar/sintropiSidebarDefaultSectionElements';
import { Document } from './document';
import { useResourceUpdateReceived } from '../components/hooks/useResourceUpdateReceived';
import { Execution } from './Execution';
import { Analytics } from './Analytics';
import { KeycloakService } from '../services/KeycloakService';
import { SintropiSidebarMobile } from './sidebar/components/SintropiSidebarMobile';
import { useSelector } from 'react-redux';
import { taskSidebarStateSelector } from '../redux/Sintropi/sidebar/selectors';
import { DocumentMobile } from './documentMobile';
import { BusinessKpi } from './Kpi';
import { useQuery } from '@apollo/client';
import { HAS_BUSINESS_KPI } from '../graphql/kpi/queries';
import { Tasks } from './taskList';
import { Records } from './record';

interface SintropiProps {
  notMobile: boolean;
}

export const Sintropi: React.FunctionComponent<SintropiProps> = ({
  notMobile,
}) => {
  const refContainer = useRef(null);
  const navigate = useNavigate();
  const { appId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  useResourceUpdateReceived();

  const { menuMobileIsOpen } = useSelector(taskSidebarStateSelector);

  const { data: hasBusinessKpi } = useQuery(HAS_BUSINESS_KPI, {
    variables: {
      appId,
    },
  });

  useEffect(() => {
    if (
      !searchParams.get('sectionId') &&
      !(location.pathname === `/${appId}/sintropi/search`) &&
      !(location.pathname === `/${appId}/sintropi/list`) &&
      !(location.pathname === `/${appId}/sintropi/takeincharge`) &&
      !(location.pathname === `/${appId}/sintropi/document`) &&
      !(location.pathname === `/${appId}/sintropi/executions`) &&
      !(location.pathname === `/${appId}/sintropi/analytics`) &&
      !(location.pathname === `/${appId}/sintropi/records`) &&
      !(location.pathname === `/${appId}/sintropi/business-kpi`)
    ) {
      navigate(
        `?sectionId=${SintropiSidebarDefaultSectionId.MAIN}&elementId=${SintropiSidebarMainSectionElementId.SINTROPI}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledMotionDiv>
      <FlexContainer ref={refContainer}>
        {notMobile ? (
          <SintropiSidebar />
        ) : (
          <SintropiSidebarMobile menuMobileIsOpen={menuMobileIsOpen} />
        )}
        <Routes>
          <Route path="/takeincharge" element={<TakeInCharge />} />
          <Route path="/*" element={<Tasks notMobile={notMobile} />} />
          <Route path="/executions" element={<Execution />} />
          {KeycloakService.sintropiHasRoles(['business-analytics']) && (
            <Route
              path="/analytics"
              element={<Analytics notMobile={notMobile} />}
            />
          )}
          {KeycloakService.sintropiHasRoles(['business-kpi']) &&
            hasBusinessKpi?.hasBusinessKpi.hasBusinessKpi && (
              <Route
                path="/business-kpi"
                element={<BusinessKpi notMobile={notMobile} />}
              />
            )}
          {KeycloakService.sintropiHasRoles(['records']) && (
            <Route path="/records" element={<Records />} />
          )}
          <Route
            path="/document"
            element={notMobile ? <Document /> : <DocumentMobile />}
          />
        </Routes>
      </FlexContainer>
    </StyledMotionDiv>
  );
};

const StyledMotionDiv = styled(motion.div)`
  flex: 1;
`;
