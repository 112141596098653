const getHashCode = (str: string = '') => {
  let hash = 0;
  let i = 0;
  const len = str.length;
  while (i < len) {
    // eslint-disable-next-line no-bitwise,no-plusplus
    hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
  }
  return hash;
};

const colors = [
  '#1fb85f',
  '#329902',
  '#40ca00',
  '#4b7b4b',
  '#00660a',
  '#004229',
  '#00a4a6',
  '#49cecf',
  '#017a7c',
  '#003b4a',
  '#628bb3',
  '#566f88',
  '#2c93b5',
  '#00ade7',
  '#65caec',
  '#4183d7',
  '#3555db',
  '#1d438a',
  '#001eb5',
  '#000b60',
  '#1d438a',
  '#e081b6',
  '#d25299',
  '#da0a5b',
  '#973d99',
  '#a64e80',
  '#9370db',
  '#876288',
  '#5e50b5',
  '#4a33d4',
  '#150099',
  '#ff0b80',
  '#fe2dfd',
  '#b22efd',
  '#9a1db3',
  '#58117e',
  '#550855',
  '#ef7a86',
  '#e73c4e',
  '#cf000e',
  '#ef4836',
  '#ef9473',
  '#ff6932',
  '#ff4500',
  '#bd4e04',
  '#876820',
  '#b84922',
  '#aa2e00',
  '#991645',
  '#650f06',
  '#FF7A00',
];

export const getMappedColor = (s: string) =>
  colors[Math.abs(getHashCode(s)) % colors.length];
