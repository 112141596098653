import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { KeycloakService } from '../../services/KeycloakService';

export interface ProtectedRouteProps {
  roles?: string[];
  children?: React.ReactNode;
}

export const ProtectedRender: FunctionComponent<ProtectedRouteProps> = ({
  roles,
  children,
}) => (KeycloakService.isLoggedInWithRoles(roles) ? <Outlet /> : null);
