export const gqlTableShape = `
  name
  label
  listDisplay
  actions {
    label
    processName
  }
  itemActions {
    label
    processName
  }
`;

export const gqlTablesShape = `
  tables {
    ${gqlTableShape}
  }
`;

const gqlDateShape = `
  year
  month
  day
`;

const gqlValueFragment = `
  int
  long
  string
  boolean
  date {
    ${gqlDateShape}
  }
  dateTime {
    date {
      ${gqlDateShape}
    }
    hour
    minutes
    millis
    seconds
    tz
  }
  decimal {
    decimal
  }
  user {
    fullName
    username
  }
  group {
    name
  }
  device {
    serial
    vendor
    name
    address
  }
  list {
      int
      long
      string
      boolean
      date {
        ${gqlDateShape}
      }
      dateTime {
        date {
          ${gqlDateShape}
        }
        hour
        minutes
        millis
        seconds
        tz
      }
      decimal {
        decimal
      }
      user {
        fullName
        username
      }
      group {
        name
      }
      device {
        serial
        vendor
        name
        address
      }
      complex {
        nested {
          name
          value {
            int
            long
            string
            boolean
            date {
              ${gqlDateShape}
            }
            dateTime {
              date {
                ${gqlDateShape}
              }
              hour
              minutes
              millis
              seconds
              tz
            }
            decimal {
              decimal
            }
            user {
              fullName
              username
            }
            group {
              name
            }
            device {
              serial
              vendor
              name
              address
            }
          }
        }
      }
  }
  complex {
    nested {
      name
      value {
        int
        long
        string
        boolean
        date {
          ${gqlDateShape}
        }
        dateTime {
          date {
            ${gqlDateShape}
          }
          hour
          minutes
          millis
          seconds
          tz
        }
        decimal {
          decimal
        }
        user {
          fullName
          username
        }
        group {
          name
        }
        device {
          serial
          vendor
          name
          address
        }
        complex {
          nested {
            name
            value {
              int
              long
              string
              boolean
              date {
                ${gqlDateShape}
              }
              dateTime {
                date {
                  ${gqlDateShape}
                }
                hour
                minutes
                millis
                seconds
                tz
              }
              decimal {
                decimal
              }
              user {
                fullName
                username
              }
              group {
                name
              }
              device {
                serial
                vendor
                name
                address
              }
            }
          }
        }
      }
    }
  }
`;

const gqlNestedItemShape = `
  name
  value {
    ${gqlValueFragment}
  }
`;

export const gqlComplexFragment = `
    nested {
      ${gqlNestedItemShape}
    }
`;

export const gqlComplexShape = `
  ${gqlComplexFragment}
`;
