import { gql } from '@apollo/client/core';
import { gqlPagedFormDefinitionResolutionShape } from '../form/shapes';

export const EDIT_DOCUMENT_DETAILS = gql`
    mutation EditDocumentDetails(
        $documentId: ID!
        $appId: ID!
    ) {
        editDocumentDetails(
            documentId: $documentId
            appId: $appId
        ) {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
`;

export const EDIT_DOCUMENT_FOLDER_DETAILS = gql`
    mutation EditDocumentFolderDetails(
        $folderId: ID!
        $appId: ID!
    ) {
        editDocumentFolderDetails(
            folderId: $folderId
            appId: $appId
        ) {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
`;

export const DELETE_DOCUMENTS = gql`
  mutation DeleteDocuments($documentIds: [ID!]!, $appId: ID!) {
    deleteDocuments(documentIds: $documentIds, appId: $appId) {
      _
    }
  }
`;

export const DELETE_DOCUMENT_FOLDERS = gql`
  mutation DeleteDocumentFolders($folderIds: [ID!]!, $appId: ID!) {
    deleteDocumentFolders(folderIds: $folderIds, appId: $appId) {
      _
    }
  }
`;

export const PUBLISH_DOCUMENT = gql`
  mutation PublishDocument($documentId: ID!, $appId: ID!) {
    publishDocument(documentId: $documentId, appId: $appId) {
      _
    }
  }
`;

export const MOVE_DOCUMENT_FOLDERS = gql`
  mutation MoveDocumentFolders(
    $appId: ID!
    $folderIds: [ID!]!
    $targetFolderId: ID
  ) {
    moveDocumentFolders(
      appId: $appId
      folderIds: $folderIds
      targetFolderId: $targetFolderId
    ) {
      _
    }
  }
`;

export const MOVE_DOCUMENTS = gql`
  mutation MoveDocuments(
    $appId: ID!
    $documentIds: [ID!]!
    $targetFolderId: ID
  ) {
    moveDocuments(
      appId: $appId
      documentIds: $documentIds
      targetFolderId: $targetFolderId
    ) {
      _
    }
  }
`;
