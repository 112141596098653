import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormFieldDefinition, FormViewMode } from '@pec-manager/graphql';
import { FormFieldContainer } from './FormFieldContainer';
import { MultipleValuesInput } from '../../input/MultipleValuesInput';
import { extractDefaultValuesAsStrings } from '../../../utils/stringUtils';
import { MultipleSelect } from '../../common/MultipleSelect';
import { boundaryError } from './utils/errorMessage';

interface DynamicMultipleSelectTextFieldProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  svgRender?: (element: any) => JSX.Element;
  svgInput?: JSX.Element;
  arrowIcon?: boolean;
  onChangeValue?: () => void;
  viewMode?: FormViewMode;
  checkRequired?: boolean;
  columnForm?: number;
}

export const DynamicMultipleSelectTextField: FunctionComponent<
  DynamicMultipleSelectTextFieldProps
> = ({
  formField,
  fillField,
  errorMessage,
  svgRender,
  svgInput,
  arrowIcon,
  onChangeValue,
  viewMode,
  checkRequired,
  columnForm,
}) => {
  const [data, setData] = useState<string[]>([]);
  const [showDynamicSelectResult, setShowDynamicSelectResult] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    if (formField.defaultValues.length > 0) {
      setData(extractDefaultValuesAsStrings(formField.defaultValues));
    }
  }, [formField]);

  const setAllData = (newData: string[]) => {
    setData(newData);
    fillField(formField.id, newData, formField.isDynamic);
  };

  const displayValueMapping = useMemo(() => {
    const m: any = {};
    formField.kind?.genericSelect?.choices.forEach((c) => {
      m[c.id] = c.displayValue;
    });
    return m;
  }, [formField]);

  return (
    <FormFieldContainer
      viewMode={viewMode}
      formField={formField}
      errorMessage={
        firstOnClick && formField.isEditable
          ? boundaryError(
              data.length,
              formField.cardinalityBounds?.left,
              formField.cardinalityBounds?.right
            ) || errorMessage !== 'Cardinality error'
            ? errorMessage
            : undefined
          : undefined
      }
      columnForm={columnForm}
    >
      <MultipleValuesInput
        selectChoicesIsOpen={showDynamicSelectResult}
        onFocusInput={(val) => setShowDynamicSelectResult(val)}
        disablePressEnter={true}
        data={data}
        errorMessage={
          firstOnClick && formField.isEditable
            ? boundaryError(
                data.length,
                formField.cardinalityBounds?.left,
                formField.cardinalityBounds?.right
              ) || errorMessage !== 'Cardinality error'
              ? errorMessage
              : undefined
            : undefined
        }
        displayValueMap={displayValueMapping}
        svgInput={svgInput}
        setData={formField.isEditable ? setAllData : undefined}
        type="string"
        isEditable={formField.isEditable}
        boundaryRight={formField.cardinalityBounds?.right === 1}
        onInputChange={(val) => {
          setInputValue(val);
          setShowDynamicSelectResult(true);
          onChangeValue && onChangeValue();
        }}
        arrowIcon={arrowIcon}
        setFirstOnClick={setFirstOnClick}
      />
      <MultipleSelect
        choices={formField.kind?.genericSelect!.choices || []}
        filterTerm={inputValue.toLowerCase()}
        hasError={
          !!(firstOnClick && formField.isEditable
            ? boundaryError(
                data.length,
                formField.cardinalityBounds?.left,
                formField.cardinalityBounds?.right
              ) || errorMessage
            : undefined)
        }
        onSelectItemClick={(element) => {
          setFirstOnClick(true);
          setShowDynamicSelectResult(false);
          if (formField.cardinalityBounds?.right === 1) {
            setAllData([element.id]);
          } else {
            setAllData(Array.from(new Set(data.concat(element.id))));
          }
          onChangeValue && onChangeValue();
        }}
        svgRender={svgRender}
        showResults={showDynamicSelectResult}
      />
    </FormFieldContainer>
  );
};
