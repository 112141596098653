import { gqlUserResolutionShape } from '../user/shapes';
import { gqlIdentifiableValueShape } from '../common/shapes';

export const gqlBlobRefShape = `
    id
    name
    container
    size
    mimeType
    downloadUrl
    thumbnail
`;

export const gqlAuthorization = `
  actorType
  user {
    ${gqlUserResolutionShape}
  }
  group {
    id
    name
    members {
      ${gqlUserResolutionShape}
    }
  }

  canCreate
  canRead
  canUpdate
  canDelete
  canManageAcl
`;

export const gqlEntityMetadata = `
  category
  categoryDescription
  createdBy {
    ${gqlUserResolutionShape}
  }
  createdAt
  modifiedBy{
    ${gqlUserResolutionShape}
  }
  modifiedAt
`;

export const gqlBaseDocumentShape = `
  id
  name
  documentPath {
    ${gqlIdentifiableValueShape}
  }
  metadata {
    ${gqlEntityMetadata}
  }
  isPublished
  blobRef {
    ${gqlBlobRefShape}
  }
  versionNumber
`;

export const gqlBaseDocumentFolderShape = `
  id
  name
  folderPath {
    ${gqlIdentifiableValueShape}
  }
  metadata {
    ${gqlEntityMetadata}
  }
`;

export const gqlDocumentShape = `
  base {
    ${gqlBaseDocumentShape}
  }
  attachments {
    ${gqlBlobRefShape}
  }
  linkedDocuments {
    ${gqlBaseDocumentShape}
  }
  documentVersions {
    number
    
    documentId
    documentName
    documentBlobRef {
      ${gqlBlobRefShape}
    }
    documentModifiedBy {
      ${gqlUserResolutionShape}
    }
    documentModifiedAt
  } 
  outputSections {
    name
    fields {
      name
      values {
        value
        user {
          ${gqlUserResolutionShape}  
        }
      }
      type
    }
  }
  authorizations {
    ${gqlAuthorization}
  }
  isVersionable
  actions
`;
