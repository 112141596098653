import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationBanner } from '../../redux/notification/sidebar/reducers';
import {
  pushNotificationBannerAction,
  removeNotificationBannerAction,
} from '../../redux/notification/sidebar/actions';

export const useNotificationBanner: (
  gqlMutationResult: any,
  gqlMutationError: any,
  notificationBannerSuccess?: NotificationBanner,
  notificationBannerError?: NotificationBanner,
  closeModal?: () => void
) => {
  dispatchNotificationBanner: (notification: NotificationBanner) => void;
} = (
  gqlMutationResult,
  gqlMutationError,
  notificationBannerSuccess,
  notificationBannerError,
  closeModal
) => {
  const dispatch = useDispatch();

  const dispatchNotificationBanner = (notification: NotificationBanner) => {
    dispatch(pushNotificationBannerAction(notification));
    setTimeout(
      () => dispatch(removeNotificationBannerAction(notification)),
      3000
    );
  };

  useEffect(() => {
    if (gqlMutationResult && notificationBannerSuccess) {
      dispatchNotificationBanner(notificationBannerSuccess);
      closeModal && closeModal();
    }
    if (gqlMutationError && notificationBannerError) {
      dispatchNotificationBanner(notificationBannerError);
      closeModal && closeModal();
    }
    // eslint-disable-next-line
  }, [gqlMutationResult, gqlMutationError]);

  return {
    dispatchNotificationBanner,
  };
};
