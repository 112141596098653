import { gql } from '@apollo/client/core';
import { gqlBaseExecutionShape, gqlExecutionShape } from './shapes';

export const GET_EXECUTION_BY_ID = gql`
    query GetExecutionById($executionId: ID!) {
        getExecutionById(executionId: $executionId) {
            ${gqlExecutionShape}
        }
    }
`;

export const GET_EXECUTIONS = gql`
    query GetExecutions($appId: ID!, $statusFilters: [ExecutionStatus!]!, $sortFields: [SortField!]!, $pagination: Pagination, $fullSearchText: String, $fromToDateSearch: String) {
        getExecutions(appId: $appId, statusFilters: $statusFilters, sortFields: $sortFields, pagination: $pagination, fullSearchText: $fullSearchText, fromToDateSearch: $fromToDateSearch) {
            totalElements
            pageElements {
                ${gqlBaseExecutionShape}
            }
        }
    }
`;
