import { Period } from '@pec-manager/graphql';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { AnalyticsContainerHeader } from './AnalyticsContainerHeader';
import { AnalyticsContainerHeaderMobile } from './AnalyticsContainerHeaderMobile';

export enum TabAnalyticsEnum {
  PERFORMANCE = 'PERFORMANCE',
  WORKLOAD = 'WORKLOAD',
  // BUSINESS = 'BUSINESS',
}

export interface AnalyticsContainerProps {
  tabAnalytics: TabAnalyticsEnum;
  setTabAnalytics: (view: TabAnalyticsEnum) => void;
  periodSelected: Period;
  setPeriodSelected: (date: Period) => void;
  processNameSelected?: string;
  setProcessNameSelected: (processName: string) => void;
  notMobile?: boolean;
  children?: React.ReactNode;
}

export const AnalyticsContainer: FunctionComponent<AnalyticsContainerProps> = ({
  tabAnalytics,
  setTabAnalytics,
  setPeriodSelected,
  periodSelected,
  processNameSelected,
  setProcessNameSelected,
  notMobile,
  children,
}) => (
  <div style={{ position: 'relative', width: '100%' }}>
    <BackgroundBorderTop />
    <Container column>
      {notMobile ? (
        <AnalyticsContainerHeader
          setTabAnalytics={setTabAnalytics}
          tabAnalytics={tabAnalytics}
          periodSelected={periodSelected}
          setPeriodSelected={setPeriodSelected}
          processNameSelected={processNameSelected}
          setProcessNameSelected={setProcessNameSelected}
        />
      ) : (
        <AnalyticsContainerHeaderMobile
          setTabAnalytics={setTabAnalytics}
          tabAnalytics={tabAnalytics}
          periodSelected={periodSelected}
          setPeriodSelected={setPeriodSelected}
          processNameSelected={processNameSelected}
          setProcessNameSelected={setProcessNameSelected}
        />
      )}
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  </div>
);

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 6px 0 0 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @media (max-width: 990px) {
    border-radius: 0;
  }
`;

const ChildrenContainer = styled(FlexContainer)`
  height: calc(100vh - 156px);
  width: calc(100vw - 250px);

  @media (max-width: 1039px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 990px) {
    width: 100%;
    height: calc(100vh - 206px);
  }
`;

const BackgroundBorderTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.c212832};
`;
