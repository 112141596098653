import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { Color } from '../../theme';

export interface SvgIconProps {
  color?: Color | string;
  height?: string;
  width?: string;
  pointer?: boolean;
  svg: JSX.Element;
  rotateDeg?: number;
  style?: React.CSSProperties;
  center?: boolean;
}

export const SvgIcon: FunctionComponent<SvgIconProps> = ({ svg, ...props }) => (
  <SvgIconContainer {...props}>{svg}</SvgIconContainer>
);

type SvgIconContainerProps = Omit<SvgIconProps, 'svg'>;

const SvgIconContainer = styled.div<SvgIconContainerProps>`
  display: flex;
  height: ${({ height }) => height ?? '100%'};
  width: ${({ width }) => width ?? '100%'};
  transform: rotate(
    ${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : '0')}
  );

  ${({ center }) => center && 'align-items: center; justify-content: center;'}

  &:hover {
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'unset')};
  }

  svg {
    height: ${({ height }) => height ?? '100%'};
    width: ${({ width }) => width ?? '100%'};
    fill: ${({ color }) => color};

    path {
      fill: ${({ color }) => color};
    }
  }
`;
