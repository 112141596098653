import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { FormFieldDefinition } from '@pec-manager/graphql/lib/graphql.types';
import { throttle } from 'lodash';
import { FlexContainer } from '../layout/FlexContainer';
import { FormFieldContainer } from '../modals/FormModal/FormFieldContainer';
import { SvgIcon } from '../common/SvgIcon';
import { Eye } from '../../svg';
import { Theme } from '../../theme';
import { useTranslation } from 'react-i18next';

interface InputPasswordProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  checkRequired?: boolean;
  columnForm?: number;
  placeholder: string;
}

export const InputPassword: FunctionComponent<InputPasswordProps> = ({
  formField,
  fillField,
  errorMessage,
  checkRequired = false,
  columnForm,
  placeholder,
}) => {
  const { t } = useTranslation();
  const isWithConfirmation = formField.kind?.genericPassword?.withConfirmation;

  const [defaultPassword, defaultConfirmation] = formField.defaultValues;
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  const [password, setPassword] = useState(
    defaultPassword?.kind.string?.string || ''
  );
  const [confirmPassword, setConfirmPassword] = useState(
    defaultConfirmation?.kind.string?.string || ''
  );

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage, formField]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fillFieldDebounced = useCallback(
    throttle((passwords) => {
      fillField(formField.id, passwords, formField.isDynamic);
    }, 1000),
    []
  );

  useEffect(() => {
    setPassword(defaultPassword?.kind.string?.string || '');
    setConfirmPassword(defaultConfirmation?.kind.string?.string || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField]);

  useEffect(() => {
    fillFieldDebounced(
      isWithConfirmation ? [password, confirmPassword] : [password]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword]);

  const samePassword = useMemo(
    () => (password === confirmPassword ? undefined : t('passwordNotSame')),
    [password, confirmPassword]
  );

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={firstOnClick ? samePassword || errorMessageDate : undefined}
      columnForm={columnForm}
    >
      <Container justifyContent="space-between">
        <ContainerInput
          alignItems="center"
          justifyContent="space-between"
          error={firstOnClick && (!!errorMessageDate || !!samePassword)}
          isEditable={formField.isEditable}
        >
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            value={password}
            disabled={!formField.isEditable}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessageDate(undefined);
            }}
            onFocus={() => {
              setFirstOnClick(true);
            }}
            placeholder={placeholder}
          />
          <div onClick={() => setShowPassword((prevState) => !prevState)}>
            <SvgIcon
              svg={<Eye />}
              height="20px"
              width="30px"
              color={showPassword ? Theme.colors.c4C9AFF : undefined}
            />
          </div>
        </ContainerInput>
        {isWithConfirmation && (
          <ContainerInput
            alignItems="center"
            justifyContent="space-between"
            error={firstOnClick && (!!errorMessageDate || !!samePassword)}
            isEditable={formField.isEditable}
          >
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              autoComplete="new-password"
              value={confirmPassword}
              disabled={!formField.isEditable}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setErrorMessageDate(undefined);
              }}
              onFocus={() => {
                setFirstOnClick(true);
              }}
              placeholder={t('confirmPassword') as string}
            />
            <div
              onClick={() => setShowConfirmPassword((prevState) => !prevState)}
            >
              <SvgIcon
                svg={<Eye />}
                height="20px"
                width="30px"
                color={showConfirmPassword ? Theme.colors.c4C9AFF : undefined}
              />
            </div>
          </ContainerInput>
        )}
      </Container>
    </FormFieldContainer>
  );
};

const Container = styled(FlexContainer)`
  & > div {
    width: calc(50% - 10px);
    height: 51px;
  }
`;

const ContainerInput = styled(FlexContainer)<{
  error?: boolean;
  isEditable: boolean;
}>`
  width: 100%;
  border: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors.cFE4242 : theme.colors.cDFE1E6};
  border-radius: 6px;
  height: 40px;
  padding: 10px;

  input {
    outline: none;
    border: none;
    width: calc(100% - 40px);
  }

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }
`;
