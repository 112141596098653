import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { FlexContainer } from '../../../components/layout/FlexContainer';

interface FromSectionProps {
  from: string;
}

export const FromSection: FunctionComponent<FromSectionProps> = ({ from }) => (
  <Container alignItems="center">
    <Text text="from" fontSize="11px" lineHeight="13.2px" fontWeight="300" />
    <FromText text={from} fontSize="11px" lineHeight="13.2px" skipTranslation />
  </Container>
);

const Container = styled(FlexContainer)`
  margin-bottom: 2px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FromText = styled(Text)`
  margin-left: 5px;
`;
