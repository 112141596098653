import React, { FunctionComponent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BaseExecution } from '@pec-manager/graphql';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { TopSection } from './TopSection';
import { TitleSection } from './TitleSection';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { DueDateSectionExecution } from './DueDateSection';
import { BottomSection } from './BottomSection';
import { TaskSubExecutionSection } from './AttachmentsCommentsSection';

export const ExecutionItemCard: FunctionComponent<{
  execution: BaseExecution;
}> = ({ execution }) => {
  const [hideCorrelatedSection, setHideCorrelatedSection] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSelected = execution.id === searchParams.get('taskId');

  const numberTaskInSubExecution = useMemo(
    () =>
      execution.subExecutions.reduce(
        (accumulator, subExecution) => {
          return accumulator + (subExecution.task?.id ? 1 : 0);
        },
        execution.task?.id ? 1 : 0
      ),
    [execution.subExecutions, execution.task?.id]
  );

  return (
    <Motion
    /*      initial={{
        y: index <= MAILS_PAGE_SIZE ? -10 * (index + 1) : 0,
        opacity: 0,
      }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: index <= 7 ? 0.05 * (index + 1) : 0.1,
        type: 'tween',
      }}*/
    >
      <ContainerTask column selected={isSelected}>
        <PaddingContainer
          column
          onClick={() => {
            navigate(`?executionId=${execution.id}`);
          }}
        >
          <TopSection
            textCategory={execution.processName}
            colorBackground={execution.processName}
          />
          <TitleSection
            title={execution.name}
            receivedAt={execution.createdAt as any as number}
            setHideCorrelatedSection={setHideCorrelatedSection}
            hideCorrelatedSection={hideCorrelatedSection}
          />
          <DescriptionSection
            text={execution.description}
            skipTranslation
            lineHeight="18px"
            fontSize="12px"
            color={Theme.colors.c505050}
          />
          <TaskSubExecutionSection
            numberOfTask={numberTaskInSubExecution}
            numberOfSubExecution={execution.subExecutions.length}
          />
          <DueDateSectionExecution executionBase={execution} />
        </PaddingContainer>
        <BottomSection execution={execution} />
      </ContainerTask>
    </Motion>
  );
};

const ContainerTask = styled(FlexContainer)<{ selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFFFFFF};
  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  @media (max-width: 1040px) {
    max-width: 100%;
  }
`;

const PaddingContainer = styled(FlexContainer)`
  padding: 12px 16px 0 16px;
`;

const DescriptionSection = styled(Text)`
  max-width: calc(100% - 23px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

const Motion = styled(motion.div)`
  @media (min-width: 990px) {
    display: none;
  }
`;
