import { gql } from '@apollo/client/core';
import { gqlChatRoomShape } from './shapes';

export const GET_CHATS = gql`
    query GetChatRooms($pagination: Pagination) {
        getChatRooms(pagination: $pagination) {
            ${gqlChatRoomShape}
        }
    }
`;

export const GET_CHAT = gql`
  query GetChatRoomIdByResource($resource: ChatResourceInput!) {
    getChatRoomIdByResource(resource: $resource)
  }
`;
