import { gql } from '@apollo/client/core';
import { gqlExecutionShape } from './shapes';

export const EXECUTION_UPDATED = gql`
  subscription ExecutionUpdated($correlationId: String!, $executionId: ID!) {
      executionUpdated(correlationId: $correlationId, executionId: $executionId) {
        ${gqlExecutionShape}
    }
  }
`;
