import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BlobRefOrFolder, IdentifiableValue } from '@pec-manager/graphql';
import { Text } from '../../../common/Text';
import { FlexContainer } from '../../../layout/FlexContainer';
import { Theme } from '../../../../theme';
import { SvgIcon } from '../../../common/SvgIcon';
import { Disk } from '../../../../svg';
import { HorizontalLoader } from '../../../loader/HorizontalLoader';

interface FolderSectionProps {
  setCurrentFolder: (f: IdentifiableValue) => void;
  folderList: BlobRefOrFolder[];
  loading: boolean;
}

export const FolderSection: FunctionComponent<FolderSectionProps> = ({
  setCurrentFolder,
  folderList,
  loading,
}) => (
  <Container>
    <div style={{ height: '13px', display: 'flex' }}>
      <Text text="folder" fontSize="12px" lineHeight="18px" />
    </div>
    <ContainerFolder wrapContent>
      {loading && (
        <HorizontalLoader size="l" top="33px" color={Theme.colors.c4C9AFF} />
      )}
      {!loading && folderList.length > 0 ? (
        folderList.map(({ folder }: BlobRefOrFolder) => (
          <Folder key={folder!.id} onClick={() => setCurrentFolder(folder!)}>
            <ContainerSvg>
              <SvgIcon
                svg={<Disk />}
                height="16px"
                width="16px"
                color={Theme.colors.c505050}
              />
            </ContainerSvg>
            <Text
              text={folder!.value}
              fontSize="13px"
              lineHeight="16px"
              color={Theme.colors.c838383}
              ellipsis
            />
          </Folder>
        ))
      ) : (
        <EmptyFolders
          text="notFolders"
          fontSize="13px"
          lineHeight="13px"
          color={Theme.colors.c838383}
        />
      )}
    </ContainerFolder>
  </Container>
);

const Container = styled.div`
  padding-left: 16px;
  margin: 24px 0 12px 0;
`;

const ContainerFolder = styled(FlexContainer)`
  padding: 12px 0;
`;

const ContainerSvg = styled.div`
  height: 20px;
  margin-right: 6px;
`;

const Folder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 11px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  cursor: pointer;
`;

const EmptyFolders = styled(Text)`
  text-align: center;
`;
