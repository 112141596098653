import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, NotificationStatus } from '@pec-manager/graphql';
import { FlexContainer } from '../components/layout/FlexContainer';
import { Text } from '../components/common/Text';
import { Theme } from '../theme';
import { getDateAsString } from '../utils/dateUtils';
import { notificationStateSelector } from '../redux/notification/sidebar/selectors';
import {
  closeNotificationModalAction,
  toggleNotificationModalAction,
} from '../redux/notification/sidebar/actions';
import { useMutationHook } from '../components/hooks/useMutationHook';
import { READ_NOTIFICATIONS } from '../graphql/notification/mutations';
import { NotificationItem } from './NotificationItem';
import { useOutsideAlerter } from '../components/hooks/useOutsideAlerter';

function groupByDate(objects: Notification[]) {
  let prevDate = null;
  const groupings: any = {};
  for (const o of objects) {
    const lds = new Date(o.updatedAt as unknown as number).toLocaleDateString();
    if (prevDate !== lds) {
      groupings[lds] = [o];
      prevDate = lds;
    } else {
      groupings[lds].push(o);
    }
  }
  return groupings;
}

export const extractMultipleForNotificationTranslate = (
  activityUsers: boolean,
  activityCount: number
) => {
  if (activityCount === 1) {
    return '';
  }
  if (activityCount > 1) {
    return 's';
  }
  if (activityUsers) {
    return 's';
  }
  return '';
};

interface NotificationModalProps {
  setFormDefinitionId?: (id: string) => void;
  notMobile?: boolean;
}

export const NotificationModal: FunctionComponent<NotificationModalProps> = ({
  setFormDefinitionId,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  useOutsideAlerter(ref, () =>
    setTimeout(() => dispatch(closeNotificationModalAction()), 100)
  );

  const { notifications } = useSelector(notificationStateSelector);

  const groupedNotificationsByDate = groupByDate(notifications);

  const [childrenHaveContext, setChildrenHaveContext] = useState<any[]>([]);

  const [markNotificationAsRead] = useMutationHook({
    mutationGql: READ_NOTIFICATIONS,
    errorMessage: 'genericErrorMessage',
  });

  const notificationIds =
    notifications
      .filter((n) => n.notificationStatus === NotificationStatus.UNREAD)
      .map((n) => n.id) || [];

  const emptyNotification = useMemo(
    () =>
      Object.keys(groupedNotificationsByDate).some(
        (date) => groupedNotificationsByDate[date].length > 0
      ),
    [groupedNotificationsByDate]
  );

  return (
    <Container ref={ref}>
      <Arrow />
      <TopSection alignItems="center" justifyContent="space-between">
        <Text
          text="notifications"
          fontSize="14px"
          lineHeight="21px"
          color={Theme.colors.c9F9F9F}
          fontWeight="600"
        />
        {notificationIds.length > 0 && (
          <ClickableText
            onClick={() => {
              markNotificationAsRead({
                variables: {
                  notificationIds,
                },
              }).then(dispatch(toggleNotificationModalAction()));
            }}
          >
            <Text
              text="markAllAsRead"
              fontSize="12px"
              lineHeight="21px"
              color={Theme.colors.c2186C6}
              fontWeight="600"
            />
          </ClickableText>
        )}
      </TopSection>
      {emptyNotification ? (
        Object.keys(groupedNotificationsByDate).map((date) => (
          <NotificationSection key={date}>
            {childrenHaveContext.length > 1 &&
              childrenHaveContext[date as any] && (
                <DaySection>
                  <Text
                    text={date === getDateAsString(Date.now()) ? 'today' : date}
                    fontSize="11px"
                    lineHeight="11px"
                    fontWeight="600"
                    color={Theme.colors.c505050}
                  />
                </DaySection>
              )}
            <ItemSection>
              {(groupedNotificationsByDate[date] as Notification[]).map(
                (notification) => (
                  // eslint-disable-next-line react/jsx-key
                  <NotificationItem
                    haveContext={(id) => {
                      setChildrenHaveContext((prevState) => [
                        ...prevState,
                        {
                          [date]: id,
                        },
                      ]);
                    }}
                    key={notification.id}
                    notification={notification}
                    setFormDefinitionId={setFormDefinitionId}
                  />
                )
              )}
            </ItemSection>
          </NotificationSection>
        ))
      ) : (
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginTop: '200px' }}
        >
          <Text
            text="emptyNotifications"
            fontSize="15px"
            lineHeight="20px"
            color={Theme.colors.c505050}
          />
        </FlexContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 466px;
  border-radius: 12px;
  position: absolute;
  top: 176px;
  left: 3px;
  z-index: 20;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  box-shadow: 0 4px 20px 0 #00000026;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  height: 450px;
  overflow-y: auto;

  @media (max-width: 990px) {
    width: 100%;
    top: auto;
    bottom: 50px;
    left: 0;
  }
`;

const TopSection = styled(FlexContainer)`
  width: 466px;
  padding: 8px 16px;

  @media (max-width: 990px) {
    width: 100%;
  }
`;

const DaySection = styled.div`
  padding: 7px 16px;
  background-color: ${({ theme }) => theme.colors.cDCDCDC};
`;

const ItemSection = styled.div`
  max-height: 410px;
  overflow-y: auto;
  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  }
`;

const ClickableText = styled.div`
  cursor: pointer;
`;

const NotificationSection = styled.div`
  overflow: hidden;
  border-radius: 0 0 12px 12px;
`;

const Arrow = styled.div`
  position: absolute;
  top: -8px;
  left: 14px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${({ theme }) => theme.colors.cFFFFFF};
`;
