import {
  BaseDocument,
  EntityMetadata,
  BaseDocumentFolder,
  IdentifiableValue,
} from '@pec-manager/graphql';
import React, { FunctionComponent, useState } from 'react';
import { SvgIcon } from '../../components/common/SvgIcon';
import { FolderIcon, More } from '../../svg';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { UserWithDate } from './UserWithDate';
import { extractFullName } from '../../utils/stringUtils';
import { MenuChoices } from '../../components/PagedFormDefinition/MenuChoices';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ItemBig, ItemMenuChoices, ItemSmall } from './index';
import { downloadAndSave } from '../../utils/file';
import { AttachmentPreviewModal } from '../../components/modals/AttachmentPreviewModal';
import { useNavigate } from 'react-router-dom';
import { extractThumbnailFromName } from '../../components/common/AttachmentPreview/extractThumbnail';
import { handlePathForDocumentDetails } from '../../utils/funcUtils';
import { PathShow } from '../../components/common/PathShow';

interface DocumentItemProps {
  folder?: BaseDocumentFolder;
  document?: BaseDocument;
  setCurrentFolder: ({ id, value }: IdentifiableValue) => void;
  setPathFolder: (val: IdentifiableValue[]) => void;
  setOpenMenu: React.Dispatch<React.SetStateAction<string | undefined>>;
  openMenu?: string;
}

export const DocumentItem: FunctionComponent<DocumentItemProps> = ({
  document,
  folder,
  setCurrentFolder,
  setPathFolder,
  setOpenMenu,
  openMenu,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();
  const id = document?.id || folder!.id;
  const metadata: EntityMetadata = document?.metadata || folder!.metadata;

  const menuChoicesFolder = [
    {
      text: 'detail',
      onClick: () => {
        setCurrentFolder({
          id: folder?.id || document!.id,
          value: folder?.name || document!.name,
        });
        navigate(
          folder?.id ? `?folderId=${folder.id}` : `?documentId=${document!.id}`
        );
      },
    },
  ];
  const menuChoicesDocument = () => {
    const menu = [
      {
        text: 'detail',
        onClick: () => {
          setCurrentFolder({
            id: folder?.id || document!.id,
            value: folder?.name || document!.name,
          });
          navigate(
            folder?.id
              ? `?folderId=${folder.id}`
              : `?documentId=${document!.id}`
          );
        },
      },
      {
        text: 'download',
        onClick: async () => {
          await downloadAndSave(document?.blobRef?.downloadUrl || '');
        },
      },
    ];

    if (document?.blobRef?.mimeType === 'application/pdf') {
      menu.push({
        text: 'preview',
        onClick: () => setShowPreview(true),
      });
    }
    return menu;
  };

  return (
    <LineItem
      key={document?.id || folder?.id}
      alignItems="center"
      onClick={() => {
        if (folder?.id) {
          setPathFolder(handlePathForDocumentDetails(folder!.folderPath));
          setCurrentFolder({
            id: folder.id,
            value: folder!.name,
          });
        } else navigate(`?documentId=${document!.id}`);
      }}
    >
      <ItemBig>
        {folder && <SvgIcon svg={<FolderIcon />} height="30px" width="40px" />}
        {!folder && document?.blobRef && (
          <div style={{ position: 'relative' }}>
            <SvgIcon
              svg={
                extractThumbnailFromName(
                  document.blobRef.mimeType,
                  document.blobRef.name
                ).documents
              }
              height="30px"
              width="40px"
            />
            <TypeContainer
              numberLetters={
                extractThumbnailFromName(
                  document.blobRef.mimeType,
                  document.blobRef.name
                ).extension.length
              }
            >
              <Text
                text={
                  extractThumbnailFromName(
                    document.blobRef.mimeType,
                    document.blobRef.name
                  ).extension
                }
                fontSize="8px"
                lineHeight="30px"
                color={Theme.colors.cFFFFFF}
                fontWeight="500"
              />
            </TypeContainer>
          </div>
        )}
        <FlexContainer
          column
          style={{ marginLeft: '8px', paddingTop: '4px' }}
          justifyContent="center"
        >
          <Text
            text={
              (document?.name &&
                `${document?.name}${
                  document?.isPublished ? '' : ' - DRAFT'
                }`) ||
              folder?.name ||
              'withoutTitle'
            }
            fontSize="14px"
            lineHeight="20px"
            color={Theme.colors.c505050}
            fontWeight="500"
          />
          {(document?.documentPath || folder?.folderPath) && (
            <PathShow
              position={
                document?.documentPath.slice(0, -1) ||
                folder!.folderPath.slice(0, -1)
              }
            />
          )}
        </FlexContainer>
      </ItemBig>
      <ItemBig>
        <Text
          text={metadata.category}
          fontSize="14px"
          lineHeight="20px"
          ellipsis
        />
      </ItemBig>
      <ItemSmall listItem>
        {(document?.metadata || folder?.metadata) && (
          <UserWithDate
            name={extractFullName(metadata.createdBy)}
            date={metadata.createdAt}
          />
        )}
      </ItemSmall>
      <ItemSmall listItem>
        {(document?.metadata || folder?.metadata) && (
          <UserWithDate
            name={extractFullName(metadata.modifiedBy)}
            date={metadata.modifiedAt}
          />
        )}
      </ItemSmall>
      <ItemMenuChoices onClick={(e) => e.stopPropagation()}>
        <SvgClickable
          onClick={(e) => {
            setOpenMenu(id === openMenu ? undefined : id);
          }}
        >
          <SvgIcon
            svg={<More />}
            color={Theme.colors.c2186C6}
            height="20px"
            width="20px"
          />
          {openMenu === id && (
            <MenuChoices
              containerMenuProps={{ right: '20px', top: '0px' }}
              choices={folder?.id ? menuChoicesFolder : menuChoicesDocument()}
              onClickOutside={() => setOpenMenu(undefined)}
            />
          )}
        </SvgClickable>
      </ItemMenuChoices>
      {showPreview && document && document.blobRef && (
        <AttachmentPreviewModal
          closeOnClick={() => setShowPreview(false)}
          downloadUrl={document.blobRef.downloadUrl}
          nameFile={document.name}
        />
      )}
    </LineItem>
  );
};

const LineItem = styled(FlexContainer)`
  position: relative;
  width: 100%;
  padding: 10px 0 10px 20px;
  cursor: pointer;
  margin-top: 8px;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.1), 0 0 1px rgba(9, 30, 66, 0.31);
  background-color: ${({ theme }) => theme.colors.cFCFCFC}};

  &:hover {
    transform: translateZ(1.1);
  }

  & > div:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    padding-left: 16px;
  }
`;

const SvgClickable = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TypeContainer = styled.div<{ numberLetters: number }>`
  position: absolute;
  top: 0;
  left: ${({ numberLetters }) => {
    switch (numberLetters) {
      case 2:
        return '14px';
      case 4:
        return '10px';
      case 3:
      default:
        return '12px';
    }
  }};
`;
