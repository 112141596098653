import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { BaseTask } from '@pec-manager/graphql';
import { handleRoundedAvatar } from '../../utils/stringUtils';
import { DynamicGenericUserField } from '../../components/common/GenericUserSelect';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import { REASSIGN_TASK } from '../../graphql/task/mutations';

interface BottomSectionProps {
  taskBase: BaseTask;
  selected?: boolean;
  hideCorrelateSection?: boolean;
  // historyPushString?: string;
}

export const BottomSection: FunctionComponent<BottomSectionProps> = ({
  selected,
  taskBase,
  hideCorrelateSection,
}) => {
  const ref = useRef(null);
  const [reassignTask] = useMutationHook({
    mutationGql: REASSIGN_TASK,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [displayValueUsers, setDisplayValueUsers] = useState<
    {
      name: string;
      type: 'GROUP' | 'USER';
      id?: string;
    }[]
  >([]);

  const [users, setUsers] = useState<string[]>([]);

  useEffect(() => {
    setUsers(handleRoundedAvatar(taskBase).map((user) => user.id!));
    setDisplayValueUsers(handleRoundedAvatar(taskBase));

    // eslint-disable-next-line
  }, [taskBase]);

  return (
    <Container
      justifyContent="space-between"
      wrapContent
      alignItems="center"
      selected={selected}
      hideCorrelateSection={hideCorrelateSection}
      ref={ref}
    >
      <ItemContainer alignItems="center" marginRight="8px">
        <Text
          text={
            taskBase.inCharge?.id ? 'takeInChargeTo' : 'assignedToBottomList'
          }
          fontSize="12px"
          lineHeight="32px"
          color={Theme.colors.c505050}
          style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
        />
        <DynamicGenericUserField
          openUp={
            // @ts-ignore
            window.innerHeight - ref.current?.getBoundingClientRect().top < 214
          }
          width="calc(100% - 22px)"
          displayValue={displayValueUsers}
          users={users}
          disabled={!taskBase.isReassignable}
          setUsers={(el) => {
            const us = displayValueUsers
              .filter((user) => user.type !== 'GROUP')
              .map((user) => user.id!);

            const usersWithoutGroup = Array.from(new Set(us.concat(el.id!)));

            const groups = displayValueUsers.filter((u) => u.type === 'GROUP');

            reassignTask({
              variables: {
                taskId: taskBase.id,
                userIds: usersWithoutGroup,
                groupIds: groups.length ? groups.map((g) => g.id!) : [],
              },
            }).then(() => {
              if (groups.length > 0) {
                setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
              } else {
                setUsers(usersWithoutGroup);
              }
              setDisplayValueUsers((prevState) => {
                if (
                  prevState.find(
                    (element) => el.name === `${el.firstName} ${el.lastName}`
                  )
                ) {
                  return prevState;
                } else {
                  return [
                    ...prevState,
                    {
                      name: `${el.firstName} ${el.lastName}`,
                      type: 'USER',
                      id: el.id,
                    },
                  ];
                }
              });
            });
          }}
          onClickRemove={(id) => {
            const us = displayValueUsers
              .filter((user) => user.type !== 'GROUP')
              .map((user) => user.id!);

            const usersWithoutGroup = us.filter((user) => user !== id);

            const groups = displayValueUsers.filter((u) => u.type === 'GROUP');

            reassignTask({
              variables: {
                taskId: taskBase.id,
                userIds: usersWithoutGroup,
                groupIds: groups.length ? groups.map((g) => g.id!) : [],
              },
            }).then(() => {
              if (groups.length > 0) {
                setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
              } else {
                setUsers(usersWithoutGroup);
              }
              setDisplayValueUsers((prevState) =>
                prevState.filter((element) => element.id !== id)
              );
            });
          }}
        />
        {/*
        <>
          <Text
            text="notAssigned"
            fontSize="10px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.c838383}
          />
          <ContainerTakeInCharge
            onClick={() => {
              history.push(`/sintropi/takeincharge${historyPushString}`);
            }}
          >
            <Text
              text="takeCharge"
              fontSize="10px"
              lineHeight="14px"
              fontWeight="600"
              color={Theme.colors.c2186C6}
            />
          </ContainerTakeInCharge>
        </>{' '}
        */}
      </ItemContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)<{
  selected?: boolean;
  hideCorrelateSection?: boolean;
}>`
  width: 100%;
  height: 48px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cC7E7FF : theme.colors.cFCFCFC};
  padding: 0 16px;
  border-radius: 0 0 8px 8px;
  transition: 300ms filter ease-in-out;
  filter: drop-shadow(
    ${({ hideCorrelateSection }) =>
      !hideCorrelateSection ? '0px -1px 6px rgba(0, 0, 0, 0.08)' : 'unset'}
  );
`;

const ItemContainer = styled(FlexContainer)<{
  marginRight?: string;
  marginLeft?: string;
}>`
  min-width: 55%;
  & > span {
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  }
`;

/*
const ContainerTakeInCharge = styled.div`
  margin-left: 4px;
  cursor: pointer;
  display: flex;
`;
*/
