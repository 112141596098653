import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Theme } from '../../../theme';
import { Text } from '../../../components/common/Text';
import { ItemMenuChoices } from '../../document';
import { BaseExecution, IdentifiableValue } from '@pec-manager/graphql';
import styled from 'styled-components';
import { SubExecutionItem } from './SubExecutionItem';

interface ExecutionContainerProps {
  subExecution: BaseExecution[];
  onClickDetails: (val: IdentifiableValue) => void;
}

export const SubProcess: FunctionComponent<ExecutionContainerProps> = ({
  subExecution,
  onClickDetails,
}) => {
  return (
    <FlexContainer
      column
      style={{
        width: '100%',
      }}
    >
      <ListField>
        <ItemBig>
          <Text
            text="process"
            fontSize="14px"
            lineHeight="14px"
            color={Theme.colors.c505050}
            fontWeight="500"
            style={{ textTransform: 'uppercase' }}
          />
        </ItemBig>
        <ItemSmall>
          <Text
            text="date"
            fontSize="14px"
            lineHeight="14px"
            color={Theme.colors.c505050}
            fontWeight="500"
            style={{ textTransform: 'uppercase' }}
          />
        </ItemSmall>
        <ItemSmall>
          <Text
            text="state"
            fontSize="14px"
            lineHeight="14px"
            color={Theme.colors.c505050}
            fontWeight="500"
            style={{ textTransform: 'uppercase' }}
          />
        </ItemSmall>
        <ItemMenuChoices />
      </ListField>
      <ListContainer column>
        {subExecution && subExecution.length > 0 ? (
          subExecution.map((execution: BaseExecution) => (
            <SubExecutionItem
              key={execution.id}
              execution={execution}
              onClickDetails={onClickDetails}
            />
          ))
        ) : (
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '120px' }}
          >
            <Text text="emptyList" fontSize="12px" lineHeight="16px" />
          </FlexContainer>
        )}
      </ListContainer>
    </FlexContainer>
  );
};

const ListField = styled(FlexContainer)`
  height: 40px;
  width: calc(100% - 40px);
  padding-left: 20px;
  background-color: ${({ theme }) => theme.colors.cF0F0F0};

  & > div:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ItemBig = styled.div`
  height: 40px;
  width: 60%;
  display: flex;
  align-items: center;
  flex-basis: auto;
`;

const ItemSmall = styled.div`
  height: 40px;
  width: 20%;
  display: flex;
  align-items: center;
`;

const ListContainer = styled(FlexContainer)`
  height: 100%;
`;
