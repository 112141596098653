import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BaseTask, ResourcePriority, TaskStatus } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Choice, Select } from '../../components/select';
import { Theme } from '../../theme';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Calendar, Priority } from '../../svg';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import {
  CHANGE_TASK_DUE_DATE,
  CHANGE_TASK_PRIORITY,
} from '../../graphql/task/mutations';
import { Text } from '../../components/common/Text';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { KeycloakService } from '../../services/KeycloakService';
import DatePicker from 'react-datepicker';
import { ContainerClear } from '../TaskCalendar/EventItem';
import { useTranslation } from 'react-i18next';
import { HeaderDataPicker } from '../../components/common/HeaderDataPicker';

/* eslint-disable react/no-children-prop */

interface TitleSectionProps {
  taskBase: BaseTask;
}

const mapColorIcon = {
  [ResourcePriority.HIGH]: Theme.colors.cFE4242,
  [ResourcePriority.MEDIUM]: Theme.colors.cFFC53D,
  [ResourcePriority.LOW]: Theme.colors.c7EB902,
  [ResourcePriority.NONE]: Theme.colors.c505050,
};

const PRIORITIES = [
  ResourcePriority.HIGH,
  ResourcePriority.MEDIUM,
  ResourcePriority.LOW,
];

const PrioritySelect: Choice[] = PRIORITIES.map((priority) => ({
  svg: (
    <SvgIcon
      svg={<Priority />}
      color={mapColorIcon[priority as ResourcePriority]}
      width="13px"
    />
  ),
  displayValue: `${priority.toLowerCase()}Priority`,
  value: priority,
}));

export const PrioritySection: FunctionComponent<TitleSectionProps> = ({
  taskBase,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [changeTaskPriority] = useMutationHook({
    mutationGql: CHANGE_TASK_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeTaskDueDate] = useMutationHook({
    mutationGql: CHANGE_TASK_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  useEffect(() => {
    setDate(taskBase.expiresAt);
    // eslint-disable-next-line
  }, [taskBase]);

  return (
    <Container wrapContent alignItems="center" justifyContent="space-between">
      {!KeycloakService.removeFieldInTask().includes('dueDate') && (
        <FlexContainer alignItems="center">
          <Text
            text="dueDateReassign"
            fontSize="12px"
            lineHeight="36px"
            color={Theme.colors.c505050}
            style={{ marginRight: '8px' }}
          />
          <DatePickerStyled
            placeholderText={t('insertDate') as string}
            disabled={
              taskBase.status === TaskStatus.COMPLETED || !taskBase.isManageable
            }
            locale={
              mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
            }
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <HeaderDataPicker
                date={date}
                decreaseMonth={decreaseMonth}
                changeMonth={changeMonth}
                changeYear={changeYear}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            dateFormat={
              mappingLocaleForDatePicker(
                KeycloakService.getLocaleForDate()?.toLowerCase() ||
                  KeycloakService.getLocale()
              ).formatDate
            }
            onChange={(date) => {
              changeTaskDueDate({
                variables: {
                  taskId: taskBase.id,
                  newDueDate: new Date(date as any).getTime(),
                },
              }).then(() => {
                // @ts-ignore
                setDate(date);
              });
            }}
            children={
              <ContainerClear
                onClick={() => {
                  changeTaskDueDate({
                    variables: {
                      taskId: taskBase.id,
                      newDueDate: undefined,
                    },
                  }).then(() => {
                    setDate(undefined);
                  });
                }}
              >
                <Text
                  text={t('clearDueDate')}
                  fontSize="12px"
                  lineHeight="32px"
                  fontWeight="300"
                  color={Theme.colors.cD73E18}
                />
              </ContainerClear>
            }
            value={date as unknown as string}
            selected={date}
            showTimeSelect={false}
            timeFormat="HH:mm"
          />
          <SvgIcon
            svg={<Calendar />}
            color={Theme.colors.c505050}
            width="18px"
            style={{ marginLeft: '-26px', cursor: 'default' }}
          />
        </FlexContainer>
      )}
      {!KeycloakService.removeFieldInTask().includes('priority') && (
        <Select
          colorText={Theme.colors.c838383}
          fontSizeText="12px"
          lineHeightText="18px"
          choices={PrioritySelect.filter(
            (choice) => choice.value !== ResourcePriority.NONE
          )}
          width="150px"
          defaultChoice={
            PrioritySelect.filter((s) => s.value === taskBase.priority)[0]
          }
          disabled={!taskBase.isManageable}
          onChoiceSelected={(choice: Choice) => {
            changeTaskPriority({
              variables: {
                taskId: taskBase.id,
                newPriority: choice.value as ResourcePriority,
              },
            });
          }}
          height="32px"
          backgroundColor={Theme.colors.cFAFAFA}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  margin-bottom: 16px;

  @media (max-width: 990px) {
    flex-direction: column;
    align-items: flex-start;

    & > div:first-child {
      margin-bottom: 16px;
    }
    .react-datepicker-wrapper {
      width: 150px !important;
    }
  }
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 30px;
  overflow: auto;
  width: 100%;
  text-overflow: ellipsis;
  padding-right: 30px;
`;
