import React from 'react';
import { Select } from '../../../components/select';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { SortType } from '../Tasks';

interface TaskSelectViewProps {
  sort: SortType[];
  setSort: React.Dispatch<React.SetStateAction<SortType[]>>;
}
export const TaskSortView: React.FC<TaskSelectViewProps> = ({
  setSort,
  sort,
}) => (
  <SelectContainer marginBottom={sort[0].label === 'sortStartDate'}>
    <Text
      text="viewMode"
      fontSize="14px"
      lineHeight="16px"
      style={{ marginRight: '8px' }}
    />
    <Select
      width="250px"
      defaultChoice={sort[0]}
      choices={[
        {
          label: 'sortStartDate',
          value: [],
        },
        {
          label: 'groupByDueDate',
          value: [{ field: 'dueDate', order: 'ASC' }],
        },
      ]}
      onChoiceSelected={(choice) => {
        setSort([
          {
            label: choice.label!,
            value: choice.value,
          },
        ]);
      }}
      zIndex={2}
      lineHeightText="16px"
      fontSizeText="14px"
    />
  </SelectContainer>
);

const SelectContainer = styled.div<{ marginBottom?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '16px' : '0px')};
  width: 100%;
  padding-left: 24px;
  padding-top: 8px;

  @media (max-width: 990px) {
    display: none;
  }
`;
