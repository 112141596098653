import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { DocumentNode } from 'graphql';
import {
  pushNotificationBannerAction,
  removeNotificationBannerAction,
} from '../../redux/notification/sidebar/actions';
import { NotificationBanner } from '../../redux/notification/sidebar/reducers';

export const useLazyQueryHook = ({
  errorMessage,
  successMessage,
  queryGql,
}: {
  queryGql: DocumentNode;
  successMessage?: string;
  errorMessage?: string;
}): any => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lazyFunction, { loading, error, data }] = useLazyQuery(queryGql, {
    fetchPolicy: 'network-only',
  });

  const dispatchNotificationBanner = (notification: NotificationBanner) => {
    dispatch(pushNotificationBannerAction(notification));
    setTimeout(
      () => dispatch(removeNotificationBannerAction(notification)),
      3000
    );
  };

  useEffect(() => {
    if (errorMessage && error) {
      dispatchNotificationBanner({
        id: uuidv4(),
        title: t(errorMessage),
        type: 'failed',
      });
    }
    if (successMessage && data) {
      dispatchNotificationBanner({
        id: uuidv4(),
        title: t(successMessage),
        type: 'success',
      });
    }
    // eslint-disable-next-line
  }, [error, data]);

  return [lazyFunction, { data, loading, error }];
};
