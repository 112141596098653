import React, { FC } from 'react';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import styled from 'styled-components';

interface WidgetContainerProps {
  widthPercent: number;
  vertical?: boolean;
  textLabel?: string;
  children: React.ReactNode;
}

export const WidgetContainer: FC<WidgetContainerProps> = ({
  vertical,
  textLabel,
  widthPercent,
  children,
}) => (
  <Container widthPercent={widthPercent} column>
    {textLabel && (
      <Text
        text={textLabel}
        fontSize="12px"
        lineHeight="16px"
        color={Theme.colors.c505050}
        style={{ marginBottom: '16px' }}
        fontWeight="bold"
      />
    )}
    <WidgetContainerStyled
      alignItems="center"
      justifyContent="space-around"
      column={vertical}
    >
      {children}
    </WidgetContainerStyled>
  </Container>
);

const Container = styled(FlexContainer)<{ widthPercent: number }>`
  width: ${({ widthPercent }) => `calc(${widthPercent || 100}% - 8px)`};
  margin-right: 8px;
`;

const WidgetContainerStyled = styled(FlexContainer)`
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;
