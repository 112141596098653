import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Color, Theme } from '../../../theme';
import { getMappedColor } from '../../../utils/colorUtils';
import { AttachmentPreviewModal } from '../../../components/modals/AttachmentPreviewModal';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { BlobRef, User } from '@pec-manager/graphql';
import { downloadAndSave } from '../../../utils/file';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Arrow, Attachment, Comment, Download, Reload } from '../../../svg';
import { RoundedTextAvatar } from '../../../components/avatar/RoundedTextAvatar';
import { extractFullName } from '../../../utils/stringUtils';

interface AttachmentItemFormFieldProps {
  file: BlobRef;
  title: string;
  label?: string;
  numberOfAttachments?: number;
  numbersOfVersions?: number;
  numberOfComments?: number;
  user?: User;
  navigateTo?: () => void;
}

export const AttachmentItemMobile: FunctionComponent<
  AttachmentItemFormFieldProps
> = ({
  numberOfAttachments,
  numbersOfVersions,
  numberOfComments,
  file,
  label,
  title,
  user,
  navigateTo,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <Container wrapContent justifyContent="center">
      <FlexContainer>
        <TitleText
          text={title}
          fontSize="12px"
          lineHeight="18px"
          skipTranslation
        />
        {label && (
          <ExtensionContainer background={getMappedColor(label)}>
            <ExtensionText
              text={label}
              fontSize="10px"
              lineHeight="15px"
              skipTranslation
              color={Theme.colors.cFFFFFF}
            />
          </ExtensionContainer>
        )}
      </FlexContainer>
      <FlexContainer>
        <PreviewContainer>
          {numbersOfVersions && (
            <TipsContainer alignItems="center">
              <SvgIcon
                svg={<Reload />}
                height="16px"
                width="16px"
                color={Theme.colors.c505050}
              />
              <Text
                text={`${numbersOfVersions}`}
                fontSize="14px"
                lineHeight="16px"
                color={Theme.colors.c838383}
              />
            </TipsContainer>
          )}
          {numberOfAttachments && (
            <TipsContainer alignItems="center">
              <SvgIcon
                svg={<Attachment />}
                height="16px"
                width="16px"
                rotateDeg={20}
                color={Theme.colors.c505050}
              />
              <Text
                text={`${numberOfAttachments}`}
                fontSize="14px"
                lineHeight="16px"
                color={Theme.colors.c838383}
              />
            </TipsContainer>
          )}
          {numberOfComments && (
            <TipsContainer alignItems="center">
              <SvgIcon
                svg={<Comment />}
                height="16px"
                width="16px"
                color={Theme.colors.cFF8686}
              />
              <Text
                text={`${numberOfComments}`}
                fontSize="14px"
                lineHeight="16px"
                color={Theme.colors.c838383}
              />
            </TipsContainer>
          )}
        </PreviewContainer>
        <UserContainer alignItems="center">
          <RoundedTextAvatar
            text={extractFullName(user)}
            size={22}
            fontSize={12}
            visibleTooltip
          />
        </UserContainer>
        <ContainerRight alignItems="center">
          {file.id && (
            <div
              onClick={async () => {
                await downloadAndSave(file.downloadUrl);
              }}
            >
              <SvgIcon
                svg={<Download />}
                height="15px"
                width="25px"
                color={Theme.colors.c0E53B7}
              />
            </div>
          )}
          {navigateTo && (
            <div onClick={navigateTo}>
              <SvgIcon
                svg={<Arrow />}
                height="15px"
                width="25px"
                color={Theme.colors.c0E53B7}
                rotateDeg={270}
              />
            </div>
          )}
        </ContainerRight>
      </FlexContainer>

      {file && showPreview && (
        <AttachmentPreviewModal
          closeOnClick={() => setShowPreview(false)}
          downloadUrl={file.downloadUrl}
          nameFile={file.name}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  min-height: 40px;
  width: 100%;
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.colors.cDFE1E6};
  padding: 0 16px;

  @media (min-width: 990px) {
    display: none;
  }
`;

const TitleText = styled(Text)`
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExtensionText = styled(Text)`
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExtensionContainer = styled.div<{ background: Color | string }>`
  background-color: ${({ background }) => background};
  height: 17px;
  width: fit-content;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-left: 13px;
`;

const PreviewContainer = styled(FlexContainer)`
  height: 24px;
  margin-left: auto;
  padding: 0 16px;
`;

const UserContainer = styled(FlexContainer)`
  height: 24px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ContainerRight = styled(FlexContainer)`
  height: 24px;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};

  & > div:not(:last-child) {
    margin-right: 10px;
  }

  & > div,
  a {
    padding: 5px 5px;
    border-radius: 100%;
    transition: 150ms background ease-in-out;
    &:hover {
      background: ${({ theme }) => theme.colors.transparentGrey};
      cursor: pointer;
    }
  }
`;

const TipsContainer = styled(FlexContainer)`
  & > span {
    margin-left: 5px;
  }
`;
