import { useDispatch, useSelector } from 'react-redux';
import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  BaseExecution,
  ExecutionStatus,
  SortField,
} from '@pec-manager/graphql';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { gqlName } from '../../graphql/utils';
import { EXECUTIONS_PAGE_SIZE } from '../../constant';
import {
  appendExecutions,
  clearExecutionList,
} from '../../redux/Sintropi/execution/actions';
import { GET_EXECUTIONS } from '../../graphql/execution/queries';
import { executionsStateSelector } from '../../redux/Sintropi/execution/selectors';

export const useExecutionsQuery: (
  statusFilter: ExecutionStatus,
  fullSearchText: string,
  sortFields: SortField[],
  fromToDateSearch?: string
) => {
  fetchMore: () => void;
  executions: BaseExecution[];
  executionsCount: number;
  error?: ApolloError;
  loading: boolean;
} = (
  statusFilter: ExecutionStatus,
  fullSearchText = '',
  sortFields,
  fromToDateSearch
) => {
  const { appId } = useParams();

  const { executions, executionsCount } = useSelector(executionsStateSelector);

  const dispatch = useDispatch();
  const location = useLocation();
  const [refresh, setRefresh] = React.useState(false);

  useEffect(() => {
    setRefresh(true);
  }, [dispatch, statusFilter, location, fullSearchText, fromToDateSearch]);

  const [loadQuery, { loading, error, data }] = useLazyQuery(GET_EXECUTIONS, {
    fetchPolicy: 'network-only',
    variables: {
      appId,
      statusFilters: [statusFilter],
      fullSearchText,
      sortFields,
      fromToDateSearch,
      pagination: {
        offset: 0,
        limit: EXECUTIONS_PAGE_SIZE,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const { totalElements, pageElements } = data[gqlName(GET_EXECUTIONS!)];
      if (refresh) {
        setRefresh(false);
        dispatch(clearExecutionList());
      }
      dispatch(
        appendExecutions({
          executionsCount: totalElements,
          executions: pageElements,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function loadExecutions() {
    loadQuery({
      variables: {
        appId,
        statusFilters: [statusFilter],
        sortFields,
        fullSearchText,
        fromToDateSearch,
        pagination: {
          offset: 0,
          limit: EXECUTIONS_PAGE_SIZE,
        },
      },
    });
  }

  useEffect(() => {
    loadExecutions();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!executions) {
      loadExecutions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executions]);

  useEffect(() => {
    if (statusFilter) {
      loadExecutions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  useEffect(() => {
    loadExecutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullSearchText]);

  useEffect(() => {
    loadExecutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromToDateSearch]);

  useEffect(() => {
    loadExecutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFields]);

  return {
    fetchMore: () => {
      loadQuery({
        variables: {
          appId,
          statusFilters: [statusFilter],
          sortFields,
          fullSearchText,
          fromToDateSearch,
          pagination: {
            offset: executions?.length || 0,
            limit: EXECUTIONS_PAGE_SIZE,
          },
        },
      });
    },
    executions: executions || [],
    executionsCount,
    error,
    loading: loading,
  };
};
