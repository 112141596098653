import React, { FunctionComponent, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { ProcessPerformanceKpiResponse } from '@pec-manager/graphql';

interface CandlestickChartProps {
  processPerformance: ProcessPerformanceKpiResponse;
  width: number;
  labelText: string;
  notMobile?: boolean;
}

export const LineChartPerformance: FunctionComponent<CandlestickChartProps> = ({
  processPerformance,
  width,
  labelText,
  notMobile,
}) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    xaxis: {
      categories: processPerformance?.processSlaComplianceKpi.samples?.map(
        (sample) => sample.label
      ),
      offsetX: 1,
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: (value) => (value > 0 ? `${value}%` : ''),
      },
    },
  };
  const series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined =
    useMemo(() => {
      if (!processPerformance.processSlaComplianceKpi.samples) {
        return undefined;
      }
      return [
        {
          name: labelText,
          data: processPerformance?.processSlaComplianceKpi.samples?.map(
            (sample) => (sample.value.count > 0 ? sample.value.percent : null)
          ),
        },
      ];
    }, [labelText, processPerformance.processSlaComplianceKpi.samples]);

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width={notMobile ? width : '100%'}
      height={400}
    />
  );
};
