import React, { FC } from 'react';
import { KpiRow as KpiRowTypes, WidgetType } from '@pec-manager/graphql';
import { BoxAnalytics } from './BoxAnalytics';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { CounterWidget } from './CounterWidget';
import { DonutChartWidget } from './DonutChartWidget';
import { PieChartWidget } from './PieChartWidget';
import { ColumnLineWidget } from './ColumnLineChartWidget';
import { BarChartWidget } from './BarChartWidget';
import { WidgetContainer } from './WidgetContainer';
import styled from 'styled-components';
import { LineChartWidget } from './LineChartWidget';

interface KpiRowProps {
  kpiRow: KpiRowTypes;
}

export const KpiRow: FC<KpiRowProps> = ({ kpiRow }) => (
  <BoxAnalytics title={kpiRow.label} collapsed={kpiRow.collapsed} firstElement>
    <Text
      text={kpiRow.description}
      fontSize="12px"
      lineHeight="16px"
      color={Theme.colors.c505050}
      style={{ marginBottom: '16px' }}
    />
    <WidgetContainerStyled style={{ width: '100%' }} alignItems="center">
      {kpiRow.widgets?.map((widget) => {
        switch (widget.type) {
          case WidgetType.COUNTER:
            return (
              <WidgetContainer
                widthPercent={widget.percent}
                vertical={widget.vertical}
                textLabel={widget.label}
                key={widget.widgetId + widget.label}
              >
                {widget.data?.map((value, index) => (
                  <CounterWidget
                    key={widget.widgetId + index}
                    label={value.counter?.label || ''}
                    value={value.counter?.value || ''}
                    icon={value.counter?.icon}
                  />
                ))}
              </WidgetContainer>
            );
          case WidgetType.DONUT_CHART:
            return (
              <WidgetContainer
                widthPercent={widget.percent}
                vertical={widget.vertical}
                textLabel={widget.label}
                key={widget.widgetId + widget.label}
              >
                {widget.data?.map((value, index) => (
                  <DonutChartWidget
                    key={widget.widgetId + index}
                    label={value.donutChart?.label || ''}
                    value={value.donutChart?.value || ''}
                    labels={value.donutChart?.labels}
                    legend={value.donutChart?.legend || false}
                    colors={value.donutChart?.colors}
                    series={value.donutChart?.series}
                    column={widget.vertical || false}
                  />
                ))}
              </WidgetContainer>
            );
          case WidgetType.PIE_CHART:
            return (
              <WidgetContainer
                widthPercent={widget.percent}
                vertical={widget.vertical}
                textLabel={widget.label}
                key={widget.widgetId + widget.label}
              >
                {widget.data?.map((value, index) => {
                  return (
                    <PieChartWidget
                      key={widget.widgetId + widget.label + index}
                      labels={value.pieChart?.labels}
                      legend={value.pieChart?.legend || false}
                      colors={value.pieChart?.colors}
                      series={value.pieChart?.series}
                      column={widget.vertical || false}
                    />
                  );
                })}
              </WidgetContainer>
            );
          case WidgetType.COLUMN_LINE_CHART:
            return (
              <WidgetContainer
                widthPercent={widget.percent}
                vertical={widget.vertical}
                textLabel={widget.label}
                key={widget.widgetId + widget.label}
              >
                {widget.data?.map((value, index) => (
                  <ColumnLineWidget
                    key={widget.widgetId + index}
                    columnStacked={
                      value.columnLineChart?.columnStacked || false
                    }
                    lineColor={value.columnLineChart?.lineColor || ''}
                    yAxisColumnLabel={
                      value.columnLineChart?.yAxisColumnLabel || ''
                    }
                    yAxisLineLabel={value.columnLineChart?.yAxisLineLabel || ''}
                    columnValues={value.columnLineChart?.columnValues}
                    lineValues={value.columnLineChart?.lineValues}
                    xAxisCategories={value.columnLineChart?.xAxisCategories}
                  />
                ))}
              </WidgetContainer>
            );

          case WidgetType.BAR_CHART:
            return (
              <WidgetContainer
                widthPercent={widget.percent}
                vertical={widget.vertical}
                textLabel={widget.label}
                key={widget.widgetId + widget.label}
              >
                {widget.data?.map((value, index) => (
                  <BarChartWidget
                    key={widget.widgetId + index}
                    colors={value.barChart?.colors}
                    series={value.barChart?.series}
                    labels={value.barChart?.labels}
                  />
                ))}
              </WidgetContainer>
            );
          case WidgetType.LINE_CHART:
            return (
              <WidgetContainer
                widthPercent={widget.percent}
                vertical={widget.vertical}
                textLabel={widget.label}
                key={widget.widgetId + widget.label}
              >
                {widget.data?.map((value, index) => (
                  <LineChartWidget
                    key={widget.widgetId + index}
                    color={value.lineChart?.color || ''}
                    series={value.lineChart?.series}
                    labels={value.lineChart?.labels}
                  />
                ))}
              </WidgetContainer>
            );
          default:
            return null;
        }
      })}
    </WidgetContainerStyled>
  </BoxAnalytics>
);

const WidgetContainerStyled = styled(FlexContainer)<{ forceColumn?: boolean }>`
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;
