import {
  FormFieldDefaultValue,
  FormFieldDefinitionDefaultValueType,
} from '@pec-manager/graphql';
import { KeycloakService } from '../services/KeycloakService';

export const getTimeAsString = (epochMillis: number) => {
  const date = new Date(epochMillis);
  const minutes = date.getMinutes();

  return `${date.getHours()}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const locale = () => {
  const localeForDate = KeycloakService.getLocaleForDate()?.toLowerCase();
  switch (localeForDate) {
    case 'dd/mm/yyyy':
      return 'it';
    case 'mm/dd/yyyy':
      return 'en';
    default:
      return KeycloakService.getLocale();
  }
};

export const getDateAsString = (epochMillis: number) => {
  return new Date(epochMillis).toLocaleDateString(locale(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

function convertDayMonthYearStringToDate(dayMonthYearString: string): Date {
  const [day, month, year] = dayMonthYearString.split('/');
  return new Date(Number(year), Number(month) - 1, Number(day));
}

export const extractDefaultDates = (
  defaultValues: FormFieldDefaultValue[]
): (Date | null)[] => {
  const defaultDates: Date[] = [];
  for (const dv of defaultValues) {
    switch (dv.type) {
      case FormFieldDefinitionDefaultValueType.NUMBER: {
        defaultDates.push(new Date(dv.kind.number!.number));
        break;
      }
      case FormFieldDefinitionDefaultValueType.DATE: {
        // @ts-ignore
        defaultDates.push(new Date(dv.kind.date!.date * 1));
        break;
      }
      case FormFieldDefinitionDefaultValueType.STRING: {
        const defaultString = dv.kind.string?.string.trim();

        if (defaultString) {
          const parsedDate = Date.parse(defaultString);

          if (isNaN(parsedDate)) {
            defaultDates.push(convertDayMonthYearStringToDate(defaultString));
          } else {
            defaultDates.push(new Date(parsedDate));
          }
        }
      }
    }
  }
  return defaultDates;
};

export const calcFirstAndLastDayOfMonthInMilliseconds: any = (date: Date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  let sixDaysBeforeFirstDay = new Date(firstDay);
  sixDaysBeforeFirstDay.setDate(firstDay.getDate() - 6);

  let sixDaysAfterLastDay = new Date(firstDay);
  sixDaysAfterLastDay.setDate(lastDay.getDate() + 6);

  return [sixDaysBeforeFirstDay.getTime(), sixDaysAfterLastDay.getTime()];
};

export const transformNumberInMonth = (month: string) => {
  if (month === '0-1970') return 'noDueDate';
  switch (month.split('-')[0]) {
    case '0':
      return 'januaryYear';
    case '1':
      return 'februaryYear';
    case '2':
      return 'marchYear';
    case '3':
      return 'aprilYear';
    case '4':
      return 'mayYear';
    case '5':
      return 'juneYear';
    case '6':
      return 'julyYear';
    case '7':
      return 'augustYear';
    case '8':
      return 'septemberYear';
    case '9':
      return 'octoberYear';
    case '10':
      return 'novemberYear';
    case '11':
      return 'decemberYear';
    default:
      return 'noDueDate';
  }
};

export const extractDateFromSeconds = (seconds: number) => {
  const day = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds / 3600) % 24);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = Math.floor(seconds % 60);
  return { day, hours, minutes, secondsLeft };
};

export const transformSecondInDayHoursMinutes = (seconds: number) => {
  const { day, hours, minutes, secondsLeft } = extractDateFromSeconds(seconds);
  return `${day > 0 ? `${day}d` : ''} ${hours > 0 ? `${hours}h` : ''} ${
    minutes > 0 ? `${minutes}m` : ''
  } ${secondsLeft > 0 ? `${secondsLeft}s` : '0s'}`;
};

const convertDataInEpochMillis = (dateString: string) => {
  const date = new Date(dateString);
  return date.getTime();
};

export const convertDateToEpochMillis = (
  day: number,
  months: number,
  year: number
) => {
  const handleDate = `${months < 10 ? `0${months}` : months}/${
    day < 10 ? `0${day}` : day
  }/${year}`;

  return convertDataInEpochMillis(handleDate);
};

export const convertCompleteDateToEpochMillis = (
  day: number,
  months: number,
  year: number,
  hour?: number,
  millis?: number,
  minutes?: number,
  seconds?: number
) => {
  const handleDate = new Date(
    year,
    months,
    day,
    hour,
    minutes,
    seconds,
    millis
  );

  return handleDate.getTime();
};
