import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResourceType, User, Comment } from '@pec-manager/graphql';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useQuery } from '@apollo/client';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { Button } from '../../../components/common/Button';
import { CommentSection } from './CommentSection';
import { RoundedTextAvatar } from '../../../components/avatar/RoundedTextAvatar';
import { KeycloakService } from '../../../services/KeycloakService';
import defaultStyle from './defaultStyle';
import { FIND_USERS } from '../../../graphql/user/queries';
import { ADD_COMMENT } from '../../../graphql/comment/mutations';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import { GET_COMMENTS_BY_RESOURCE } from '../../../graphql/comment/queries';
import { nothing } from '../../../utils/funcUtils';
import { COMMENTS_PAGE_SIZE } from '../../../constant';

interface ActivitySectionProps {
  mailId: string;
  mailSubject: string;
}

export const ActivitySection: FunctionComponent<ActivitySectionProps> = ({
  mailId,
  mailSubject,
}) => {
  const { data: comments, refetch: refetchComments } = useQuery(
    GET_COMMENTS_BY_RESOURCE,
    {
      variables: {
        resource: {
          id: mailId,
          type: ResourceType.MAIL,
        },
        pagination: {
          offset: 0,
          limit: COMMENTS_PAGE_SIZE,
        },
      },
    }
  );

  /*  const { data: activityLog, refetch: refetchHistory } = useQuery(
    GET_HISTORY_LOG_BY_RESOURCE,
    {
      variables: {
        resource: {
          id: mailId,
          type: ResourceType.MAIL,
        },
        pagination: {
          offset: 0,
          limit: ACTIVITIES_PAGE_SIZE,
        },
      },
    }
  );*/

  const { t } = useTranslation();
  const [selected, setSelected] = useState<'comments' | 'chronology'>(
    'comments'
  );

  const [addComment] = useMutationHook({
    mutationGql: ADD_COMMENT,
    errorMessage: 'genericErrorMessage',
  });

  const [inputValue, setInputValue] = useState('');

  const [mentions, setMentions] = useState<string[]>([]);

  const { data: searchUser } = useQuery(FIND_USERS);

  const [users, setUsers] = useState<{ id: string; display: string }[]>([]);

  useEffect(() => {
    if (searchUser) {
      setUsers(
        searchUser.findUsers.map((u: User) => ({
          id: u.id,
          display: `${u.firstName} ${u.lastName}`,
        }))
      );
    }
  }, [searchUser]);

  /*  useEffect(() => {
    if (selected === 'chronology') {
      refetchHistory().then(nothing);
    }
    // eslint-disable-next-line
  }, [selected]);*/

  return (
    <Container column>
      <StyledTitle text="activity" lineHeight="21px" fontSize="14px" />
      <ContainerSwitch alignItems="center">
        <Text
          text="show"
          lineHeight="21px"
          fontSize="14px"
          fontWeight="300"
          color={Theme.colors.c505050}
        />
        <Button
          backgroundColor={
            selected === 'comments'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          height="26px"
          border={`1px solid ${Theme.colors.cDCDCDC}`}
          borderRadius="6px"
          padding="6px 8px"
          hoverBackgroundColor={
            selected === 'comments'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          onClick={() => setSelected('comments')}
        >
          <Text
            text="comments"
            fontSize="10px"
            lineHeight="12px"
            color={
              selected !== 'comments'
                ? Theme.colors.c6A6A6A
                : Theme.colors.cFFFFFF
            }
          />
        </Button>
        {/*  <Button
          backgroundColor={
            selected === 'chronology'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          height="26px"
          border={`1px solid ${Theme.colors.cDCDCDC}`}
          borderRadius="6px"
          padding="6px 8px"
          hoverBackgroundColor={
            selected === 'chronology'
              ? Theme.colors.c505050
              : Theme.colors.cFFFFFF
          }
          onClick={() => setSelected('chronology')}
        >
          <Text
            text="chronology"
            fontSize="10px"
            lineHeight="12px"
            color={
              selected !== 'chronology'
                ? Theme.colors.c6A6A6A
                : Theme.colors.cFFFFFF
            }
          />
        </Button>*/}
      </ContainerSwitch>
      <ContainerBottom column>
        {/* {selected === 'chronology' &&
          activityLog?.getActivityLogByResource.pageElements.map(
            (entry: ActivityLogEntry) => (
              <ChronologySection key={entry.id} entry={entry} />
            )
          )}*/}
        {selected === 'comments' && (
          <FlexContainer column>
            <FlexContainer
              column
              style={{ maxHeight: '600px', overflow: 'auto' }}
            >
              {comments?.getCommentsByResource.pageElements.map(
                (comment: Comment) => (
                  <CommentSection
                    refetchComments={refetchComments}
                    users={users}
                    key={comment.id}
                    resourceId={mailId}
                    comment={comment}
                  />
                )
              )}
            </FlexContainer>
            <>
              <InputContainer>
                <RoundedTextAvatar
                  size={24}
                  text={KeycloakService.getUsername()}
                  fontSize={10}
                />
                <MentionsInput
                  value={inputValue}
                  onChange={(event, newValue, newSet, mentionsItem) => {
                    setMentions(mentionsItem.map((m) => m.id));
                    setInputValue(newValue);
                  }}
                  onKeyUp={(event) => {
                    if (
                      event.key === 'Enter' &&
                      event.altKey &&
                      inputValue.length > 0
                    )
                      addComment({
                        variables: {
                          resource: {
                            id: mailId,
                            type: ResourceType.MAIL,
                          },
                          resourceName: mailSubject,
                          body: inputValue,
                          mentionIds: Array.from(new Set(mentions)),
                        },
                      }).then(() => {
                        setInputValue('');
                        refetchComments().then(nothing);
                      });
                  }}
                  style={defaultStyle}
                  placeholder={t('addComment') as string}
                >
                  <Mention
                    trigger="@"
                    data={users}
                    displayTransform={(id, display) => display}
                    style={{
                      backgroundColor: '#5B84EF2B',
                      borderRadius: '4px',
                      width: '110%',
                    }}
                  />
                </MentionsInput>
              </InputContainer>
              <SuggestionText
                text="suggestSendComment"
                fontSize="12px"
                lineHeight="18px"
                color={Theme.colors.c505050}
              />
            </>
          </FlexContainer>
        )}
      </ContainerBottom>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  height: 100%;
  overflow: auto;
  width: 100%;
  padding-top: 24px;

  @media (max-width: 1040px) {
    overflow: unset;
  }
`;

const StyledTitle = styled(Text)`
  margin-bottom: 6px;
`;

const ContainerSwitch = styled(FlexContainer)`
  & > span:first-child {
    margin-right: 16px;
  }

  button {
    margin-right: 8px;
  }
`;

const ContainerBottom = styled(FlexContainer)`
  margin-top: 34px;
  height: 100%;
`;

const InputContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const SuggestionText = styled(Text)`
  margin-top: 5px;
  margin-left: 40px;
`;
