import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { KeycloakService } from '../../services/KeycloakService';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { ProgressFlows } from '../../inbox/mail/ProgressFlows';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';
import { SearchBar } from '../../components/common/Search';
interface ExecutionContainerHeaderProps {
  setFullSearchText: (value: string) => void;
}

export const ExecutionContainerHeader: FunctionComponent<
  ExecutionContainerHeaderProps
> = ({ setFullSearchText }) => {
  const [searchValue, setSearchValue] = React.useState('');

  return (
    <Container column>
      <ContainerTop alignItems="center" justifyContent="space-between">
        <TitleWithChooseApp />
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </ContainerTop>
      <ContainerBottom alignItems="center" justifyContent="space-between">
        <LeftContainer alignItems="center">
          {searchValue.length > 0 ? (
            <Text
              text="searchWithWords"
              translationArgs={{ wordsToSearch: searchValue }}
              fontSize="18px"
              lineHeight="27px"
              fontWeight="500"
            />
          ) : (
            <Text
              text="processesProgress"
              fontSize="18px"
              lineHeight="27px"
              fontWeight="500"
            />
          )}
        </LeftContainer>
        <RightContainer justifyContent="flex-end">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            actionSearchBar={setFullSearchText}
            typeEntityAutocomplete={[]}
          />
          <ProgressFlows />
        </RightContainer>
      </ContainerBottom>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 106px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ContainerBottom = styled(FlexContainer)`
  width: 100%;
  height: 64px;
  padding: 0 16px;
`;

const ContainerTop = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

const LeftContainer = styled(FlexContainer)`
  max-width: 465px;
  width: 100%;
  height: 56px;
`;

const RightContainer = styled(FlexContainer)`
  width: 100%;
  padding-right: 20px;
  margin-right: -6px;
`;
