import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExecutionContext } from '@pec-manager/graphql/lib/graphql.types';
import { Route, Navigate, Routes } from 'react-router-dom';
import { NotificationBanners } from './components/common/NotificationBanners';
import { FlexContainer } from './components/layout/FlexContainer';
import Inbox from './inbox';
import { useNotificationQuery } from './components/hooks/useNotificationQuery';
import { NotificationModal } from './notification';
import { notificationStateSelector } from './redux/notification/sidebar/selectors';
import { Sidebar } from './root/sidebar';
import { toggleNotificationModalAction } from './redux/notification/sidebar/actions';
import Chat from './chat';
import { ChatInModal } from './chat/ChatInModal/ChatInModal';
import { Dashboard } from './dashboard';
import { Sintropi } from './sintropi';
import { ProtectedRender } from './components/keycloak/ProtectedRender';
import { useJoiningTaskReceived } from './components/hooks/useJoiningTaskReceived';
import { executionsStateSelector } from './redux/Sintropi/execution/selectors';
import { ErrorPage } from './sintropi/ErrorPage';
import eventBus from './graphql/EventBus';
import { ErrorBoundary } from '@sentry/react';
import { SidebarMobile } from './root/sidebarMobile';
import styled from 'styled-components';
import { DynamicForm } from './components/DynamicForm';

export const App = () => {
  useNotificationQuery();
  useJoiningTaskReceived();

  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  useEffect(() => {
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vhuse = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vhuse}px`);
    });
    return window.removeEventListener('resize', () => {
      // We execute the same script as before
      let vhuse = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vhuse}px`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  const { notificationBanners } = useSelector(notificationStateSelector);
  const [formDefinitionId, setFormDefinitionId] = useState('');

  const [notMobile, setNotMobile] = useState(false);

  useEffect(() => {
    const calc = () => {
      const width = window.innerWidth;
      setNotMobile(width > 990);
    };
    calc();
    window.addEventListener('resize', calc);
    return () => window.removeEventListener('resize', calc);
  }, []);

  const dispatch = useDispatch();
  const { showModal: showModalNotification } = useSelector(
    notificationStateSelector
  );
  const { chatInModal: chatModalIdIsOpen } = useSelector(
    executionsStateSelector
  );

  useEffect(() => {
    eventBus.on('websocket-connected', () => {
      console.log('websocket-connected');
    });
  }, []);

  const closeModalNotification = () => {
    if (showModalNotification) {
      dispatch(toggleNotificationModalAction());
    }
  };

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Container column onClick={closeModalNotification}>
        <FlexContainer
          height={notMobile ? '100%' : 'calc(100% - 50px)'}
          onClick={closeModalNotification}
        >
          {notMobile && <Sidebar />}
          <Routes>
            <Route path="/" element={<ProtectedRender />}>
              <Route
                path="/"
                element={
                  <Navigate
                    to={{
                      pathname: '/dashboard',
                    }}
                  />
                }
              />
              <Route
                path="/:appId/sintropi/*"
                element={<Sintropi notMobile={notMobile} />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/:appId/inbox/*" element={<Inbox />} />
              <Route path="/chat/*" element={<Chat />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </FlexContainer>
        {!notMobile && <SidebarMobile />}
      </Container>
      {showModalNotification && (
        <NotificationModal
          setFormDefinitionId={(id) => setFormDefinitionId(id)}
        />
      )}
      {notificationBanners.length > 0 && (
        <NotificationBanners notifications={notificationBanners} />
      )}
      {chatModalIdIsOpen?.id && (
        <ChatInModal
          chatId={chatModalIdIsOpen.id}
          resourceId={chatModalIdIsOpen.resourceId}
        />
      )}
      {formDefinitionId && (
        <DynamicForm
          executionContext={ExecutionContext.TASK}
          columnsForm={1}
          processId={formDefinitionId}
          onCancel={() => {
            setFormDefinitionId('');
          }}
          onSuccess={() => {
            setFormDefinitionId('');
          }}
        />
      )}
    </ErrorBoundary>
  );
};

const Container = styled(FlexContainer)`
  height: 100vh;
  @media (max-width: 990px) {
    overflow: hidden;
    height: calc(var(--vh, 1vh) * 100);
  }
`;
