export const gqlResourceContextShape = `
  appId
  resourceId
  resourceName
  type
  inbox {
    contextSection
    assignmentType
    mailState
    archiveId
  }
  sintropi {
    contextSection
    assignmentType
    taskState
  }
`;
