import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { FormFieldDefinition } from '@pec-manager/graphql/lib/graphql.types';
import { throttle } from 'lodash';
import { FormFieldContainer } from './FormFieldContainer';
import {
  boundaryError,
  generatePredicatesFromDefinitions,
  validationError,
} from './utils/errorMessage';
import { componentDecorator } from '../../common/ComponentDecorator';
import Linkify from 'react-linkify';
import { useTranslation } from 'react-i18next';
import { FormViewMode } from '@pec-manager/graphql';

interface TextAreaFieldForm {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  viewMode?: FormViewMode;
  checkRequired?: boolean;
  columnForm?: number;
}

function extractDefaults(
  formField: FormFieldDefinition
): string | (() => string) {
  return formField?.defaultValues?.length > 0
    ? `${
        (formField.defaultValues[0].kind as any)?.string?.string ||
        (formField.defaultValues[0].kind as any)?.number?.number ||
        ''
      }`
    : '';
}

export const TextAreaField: FunctionComponent<TextAreaFieldForm> = ({
  formField,
  fillField,
  errorMessage,
  viewMode,
  checkRequired = false,
  columnForm,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState('');
  const [fieldError, setFieldError] = useState<string | undefined>(undefined);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    setData(extractDefaults(formField));
  }, [formField]);

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage, formField]);

  const calcError = useMemo(
    () =>
      firstOnClick && formField.isEditable
        ? fieldError ||
          boundaryError(data.length, formField.cardinalityBounds?.left) ||
          errorMessageDate
        : undefined,
    [formField, data, fieldError, errorMessageDate, firstOnClick]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fillFieldDebounced = useCallback(
    throttle((newData) => {
      fillField(formField.id, newData, formField.isDynamic);
    }, 100),
    []
  );

  const setAllData = (newData: string) => {
    setData(newData);
    fillFieldDebounced([newData]);
  };

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={calcError}
      viewMode={viewMode}
      columnForm={columnForm}
    >
      {formField.isEditable ? (
        <StyledTextArea
          placeholder={t('insertText') as string}
          value={data}
          onFocus={() => {
            setFirstOnClick(true);
          }}
          onChange={(e) => {
            if (e.target.value.trim() === '') {
              setAllData('');
              setFieldError(
                validationError(
                  '',
                  generatePredicatesFromDefinitions(
                    formField.validationPredicates
                  )
                )
              );
            } else {
              setAllData(e.target.value);
              setErrorMessageDate(undefined);

              setFieldError(
                validationError(
                  e.target.value,
                  generatePredicatesFromDefinitions(
                    formField.validationPredicates
                  )
                )
              );
            }
          }}
          error={
            firstOnClick &&
            formField.isEditable &&
            !!(
              fieldError ||
              boundaryError(data.length, formField.cardinalityBounds?.left) ||
              errorMessageDate
            )
          }
        />
      ) : (
        <ContainerOutput>
          <Linkify componentDecorator={componentDecorator}>{data}</Linkify>
        </ContainerOutput>
      )}
    </FormFieldContainer>
  );
};

const StyledTextArea = styled.textarea<{ error?: boolean }>`
  padding: 10px;
  border: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors.cFE4242 : theme.colors.cDFE1E6};
  min-height: 7em;
  width: 100%;
  border-radius: 6px;
  resize: none;
  font-size: 14px;
  line-height: 21px;
  overflow-wrap: break-word;

  &:focus {
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.cFE4242 : theme.colors.cDFE1E6};
  }
  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }
`;

const ContainerOutput = styled.div`
  padding: 10px;
  border: 2px solid #dfe1e6;
  min-height: 7em;
  width: 100%;
  border-radius: 6px;
  resize: none;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 21px;
  background-color: rgba(239, 239, 239, 0.3);
  color: rgb(84, 84, 84);
`;
