import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { ApplicationOverview } from '@pec-manager/graphql';
import { AppDetails } from './AppDetails';
import { DashboardContainer } from './DashboardContainer';
import { FlexContainer } from '../components/layout/FlexContainer';
import { GET_APPLICATION_OVERVIEWS } from '../graphql/application/queries';
import { AroundLoader } from '../components/loader/AroundLoader';

export const Dashboard = () => {
  const { data, loading } = useQuery(GET_APPLICATION_OVERVIEWS);

  return (
    <DashboardContainer>
      <ContainerLeft wrapContent>
        {!loading &&
          data?.getApplicationOverviews.map((app: ApplicationOverview) => (
            <AppDetails key={app?.application?.id} app={app} />
          ))}
        {loading && (
          <LoaderContainer justifyContent="center" alignItems="center">
            <AroundLoader />
          </LoaderContainer>
        )}
      </ContainerLeft>
      {/*<ContainerRight>
        <ActivitiesSection />
      </ContainerRight>*/}
    </DashboardContainer>
  );
};

const ContainerLeft = styled(FlexContainer)`
  // min-width: calc(100% - 544px);
  min-width: 100%;
  overflow-y: scroll;
  padding-bottom: 16px;

  @media (max-width: 1039px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
/*
const ContainerRight = styled(FlexContainer)`
  min-width: calc(480px);
  padding: 21px 0;
  @media (max-width: 1039px) {
    width: 100%;
    max-height: 50%;
  }
`;
*/
const LoaderContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  padding-right: 14px;
`;
