import { createReducer } from '@reduxjs/toolkit';
import { setOpenMenuMobile } from './actions';

export interface TaskSidebarState {
  menuMobileIsOpen: boolean;
}

export const taskSidebarReducer = createReducer<TaskSidebarState>(
  {
    menuMobileIsOpen: false,
  },
  {
    [setOpenMenuMobile.type]: (
      state,
      action: { payload: boolean }
    ): TaskSidebarState => ({ menuMobileIsOpen: action.payload }),
  }
);
