import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  ExecutionContext,
  FilledForm,
  PagedFormDefinition,
} from '@pec-manager/graphql/lib/graphql.types';
import styled from 'styled-components';
import { ContainerModal } from '../ContainerModal';
import { FormDefinition } from './FormDefinition';
import { Text } from '../../common/Text';
import { FlexContainer } from '../../layout/FlexContainer';
import { Bubble } from '../../common/Bubble';
import { isFilledFormEqual } from '../../../utils/funcUtils';
import { ActionsButtons } from './ActionsButtons';
import { usePagedFormDefinition } from '../../hooks/usePagedFormDefinition';
import { extractAllFieldId } from './utils/extractAllFieldId';
import { ExtensionPoint, IdentifiableValue } from '@pec-manager/graphql';
import { PdfViewerWithToolbar } from '../../common/PdfViewerWithToolbar';
import { Theme } from '../../../theme';

interface FormModalProps {
  id?: string;
  processName?: string;
  executionContext: ExecutionContext;
  initialPagedFormDefinition?: PagedFormDefinition;
  closeOnClick: () => void;
  closeAllModals?: () => void;
  callbackFilledForm?: (filledForm?: FilledForm) => void;
  isAdvancedSearchModal?: boolean;
  extensionPoint?: ExtensionPoint;
  childrenActionButtons?: any;
  fileViewer?: string;
  title?: string;
  initialContext?: any;
}

export const FormDefinitionModalView: FunctionComponent<FormModalProps> = ({
  title,
  id,
  processName,
  executionContext = ExecutionContext.TASK,
  initialPagedFormDefinition,
  closeOnClick,
  callbackFilledForm,
  isAdvancedSearchModal,
  closeAllModals,
  extensionPoint,
  childrenActionButtons,
  fileViewer,
  initialContext,
}) => {
  console.log(
    'initialContextinitialContextinitialContextinitialContextinitialContext',
    initialContext
  );
  const [fileLocaleView, setFileLocaleView] = useState<string | undefined>(
    undefined
  );

  const [halfScreen, setHalfScreen] = useState<boolean>(false);

  useEffect(() => {
    setFileLocaleView(fileViewer);
  }, [fileViewer]);

  const {
    filledForm,
    currentPagedFormDefinition,
    formDefinitionDiffsOnDynamicFieldChange,
    navigationButtons,
    setCurrentPagedFormDefinition,
    setFilledFormDelegate,
  } = usePagedFormDefinition(
    executionContext,
    id,
    initialPagedFormDefinition,
    processName,
    initialContext
  );

  const [fieldCoordinatesWithError, setFieldCoordinatesWithError] = useState<
    string[]
  >([]);

  const [checkRequired, setCheckRequired] = useState<boolean>(false);

  const [onFilledFormInitialization, setOnFilledFormInitialization] =
    useState(false);

  useEffect(() => {
    if (currentPagedFormDefinition) {
      setFieldCoordinatesWithError((prevState) =>
        prevState.filter((field) =>
          extractAllFieldId(
            currentPagedFormDefinition?.formDefinition
          ).includes(field)
        )
      );
    }
  }, [currentPagedFormDefinition]);

  const [childrenPagedFormDefinition, setChildrenPagedFormDefinition] =
    useState<PagedFormDefinition | null>(null);

  const [childrenActionButtonsOther, setChildrenActionButtonsOther] = useState<
    IdentifiableValue | undefined
  >(childrenActionButtons);

  const [filledFormWithDefaultValues, setFilledFormWithDefaultValues] =
    useState<FilledForm | undefined>(undefined);

  useEffect(() => {
    if (onFilledFormInitialization) {
      if (!filledFormWithDefaultValues) {
        setFilledFormWithDefaultValues(filledForm);
      }
      if (filledForm && filledFormWithDefaultValues && callbackFilledForm) {
        callbackFilledForm(
          !isFilledFormEqual(filledForm, filledFormWithDefaultValues)
            ? filledForm
            : undefined
        );
      }
    }
    // eslint-disable-next-line
  }, [filledForm]);

  const isEditableForm = () => {
    const fieldsIsEditable =
      currentPagedFormDefinition?.formDefinition.fieldGroups.map((g) =>
        g.fields.map((f) => f.isEditable)
      );
    const form = fieldsIsEditable?.map((f) => f.some((c) => c));

    return form?.some((f) => f);
  };

  const formDefinitionHasErrors = useMemo(() => {
    if (!isEditableForm()) {
      return false;
    }
    if (currentPagedFormDefinition && onFilledFormInitialization) {
      return (
        fieldCoordinatesWithError.length > 0 ||
        currentPagedFormDefinition.formDefinition.errors.length > 0 ||
        currentPagedFormDefinition.formDefinition.fieldGroups.some(
          (g) => g.errors.length > 0
        )
      );
    }
    return true;
    // eslint-disable-next-line
  }, [
    fieldCoordinatesWithError,
    currentPagedFormDefinition,
    onFilledFormInitialization,
  ]);

  return (
    <ContainerModal position="fixed">
      {fileLocaleView && (
        <PdfViewerWithToolbar
          file={fileLocaleView}
          inModal
          halfScreen={halfScreen && !fileViewer}
        />
      )}
      <Container
        fileLocale={!!fileViewer}
        alignItems="center"
        justifyContent="center"
        column
        halfScreen={halfScreen}
        onClick={(e) => e.stopPropagation()}
      >
        {currentPagedFormDefinition && (
          <>
            <ContainerHeader alignItems="center">
              <Text
                text={title || currentPagedFormDefinition.formDefinition.name}
                fontSize="18px"
                lineHeight="18px"
                fontWeight="600"
              />
            </ContainerHeader>
            {currentPagedFormDefinition.pageRefs.length > 1 && (
              <ContainerTop>
                <Step>
                  {currentPagedFormDefinition.pageRefs.map((pageRef, index) => (
                    <Bubble
                      key={pageRef.pageId}
                      name={pageRef.pageName}
                      index={index}
                      isSelected={
                        pageRef.pageId === currentPagedFormDefinition.pageId
                      }
                    />
                  ))}
                </Step>
              </ContainerTop>
            )}
            <FormDefinition
              executionContext={executionContext || ExecutionContext.TASK}
              formDefinition={currentPagedFormDefinition.formDefinition}
              formDefinitionDiffsOnDynamicFieldChange={
                formDefinitionDiffsOnDynamicFieldChange
              }
              columnsForm={1}
              fillFormDelegateCallback={setFilledFormDelegate}
              isModalView
              onFilledFormInitialization={() =>
                setOnFilledFormInitialization(true)
              }
              notifyFieldCoordinateForError={(fieldCoordinate, hasError) => {
                setTimeout(() => {
                  if (hasError) {
                    if (
                      !fieldCoordinatesWithError.some(
                        (f) => f === fieldCoordinate
                      )
                    ) {
                      setFieldCoordinatesWithError((prevState) => [
                        ...prevState,
                        fieldCoordinate,
                      ]);
                    }
                  } else {
                    setFieldCoordinatesWithError((prevState) =>
                      prevState.filter((f) => f !== fieldCoordinate)
                    );
                  }
                }, 0);
              }}
              maxHeight100={!!fileLocaleView}
              setFileLocaleView={(fileLocaleView1) => {
                setHalfScreen(!!fileLocaleView1);
                setFileLocaleView(fileLocaleView1 || fileViewer);
              }}
              checkRequired={checkRequired}
            />
            <ContainerButtons alignItems="center" justifyContent="flex-end">
              {checkRequired && formDefinitionHasErrors && (
                <Text
                  text="genericErrorForm"
                  fontSize="14px"
                  lineHeight="16px"
                  color={Theme.colors.cFE4242}
                />
              )}
              {!isAdvancedSearchModal &&
                navigationButtons(formDefinitionHasErrors)}
              {currentPagedFormDefinition.formDefinition.actions.map(
                /* eslint-disable react/no-array-index-key */ (
                  action,
                  index
                ) => (
                  <ActionsButtons
                    key={index}
                    currentPagedFormDefinition={currentPagedFormDefinition}
                    setCurrentPagedFormDefinition={
                      setCurrentPagedFormDefinition
                    }
                    setChildrenActionButtons={setChildrenActionButtonsOther}
                    childrenActionButtons={childrenActionButtonsOther}
                    formDefinitionHasErrors={formDefinitionHasErrors}
                    setChildrenPagedFormDefinition={
                      setChildrenPagedFormDefinition
                    }
                    closeAllModals={closeAllModals}
                    callbackFilledForm={callbackFilledForm}
                    closeOnClick={closeOnClick}
                    formAction={action}
                    filledForm={filledForm}
                    executionContext={executionContext}
                    extensionPoint={extensionPoint}
                    id={id}
                    showRequiredFields={() => setCheckRequired(true)}
                    initialContext={initialContext}
                  />
                )
              )}
            </ContainerButtons>
          </>
        )}
      </Container>
      {childrenPagedFormDefinition && (
        <FormDefinitionModalView
          executionContext={executionContext}
          closeAllModals={closeAllModals}
          closeOnClick={() => setChildrenPagedFormDefinition(null)}
          initialPagedFormDefinition={childrenPagedFormDefinition}
          childrenActionButtons={childrenActionButtonsOther}
        />
      )}
    </ContainerModal>
  );
};

const Container = styled(FlexContainer)<{
  fileLocale?: boolean;
  halfScreen?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: ${({ fileLocale, halfScreen }) => {
    if (fileLocale || halfScreen) return '0';
    return fileLocale ? '0 12px 12px 0' : '12px';
  }};
  width: ${({ fileLocale, halfScreen }) => {
    if (fileLocale) return '30%';
    if (halfScreen) return '50%';
    return '940px';
  }};
  ${({ fileLocale, halfScreen }) =>
    (fileLocale || halfScreen) && 'height: 100%'};
  min-height: 300px;
  max-height: 100vh;
  @media (max-width: 990px) {
    width: 100%;
    max-height: calc((var(--vh, 1vh) * 100));
    border-radius: 0;
  }
`;

const ContainerHeader = styled(FlexContainer)`
  height: 70px;
  border-radius: 12px 12px 0 0;
  opacity: 0.98;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 16px 24px;
  width: 100%;
`;

const ContainerButtons = styled(FlexContainer)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 19px 24px 20px 0;
  & > button:not(:first-child) {
    margin-left: 16px;
  }
`;

const ContainerTop = styled(FlexContainer)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const Step = styled(FlexContainer)`
  height: 40px;
  margin-left: auto;

  & > div > div {
    padding-top: 2px;
  }
`;

styled(FlexContainer)`
  margin-right: 24px;

  & > div:first-child {
    margin-right: 8px;
  }
`;
