import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { BlobRef } from '@pec-manager/graphql/lib/graphql.types';
import { Text } from '../../components/common/Text';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Select } from '../../components/select';
import { Theme } from '../../theme';
import { AttachmentPreview } from '../../components/common/AttachmentPreview';

interface AttachmentSectionProps {
  attachments: BlobRef[];
  lastSection?: boolean;
}

const SELECT_ALL_CHOICE = 'selectAll';

export const AttachmentsSection: FunctionComponent<AttachmentSectionProps> = ({
  attachments,
  lastSection,
}) => {
  const extensions = Array.from(
    new Set(attachments.map((a) => a.mimeType))
  ).concat([SELECT_ALL_CHOICE]);

  const [selectedExtension, setSelectedExtension] = useState(
    SELECT_ALL_CHOICE.toLowerCase()
  );

  return (
    <Container lastSection={lastSection}>
      <SelectContainer alignItems="center">
        <Text text="attachments" fontSize="14px" lineHeight="17px" />
        {extensions.length > 2 && (
          <Select
            borderBottomChoice
            colorText={Theme.colors.c5B84EF}
            fontSizeText="12px"
            lineHeightText="12px"
            width="120px"
            height="30px"
            choices={extensions.map((extension) => ({
              displayValue: extension,
              value: extension,
            }))}
            placeholder={SELECT_ALL_CHOICE}
            onChoiceSelected={(choice) =>
              setSelectedExtension(choice.value.toLowerCase())
            }
          />
        )}
      </SelectContainer>
      <AttachmentsContainer wrapContent>
        {attachments
          .filter(
            (blobRef) =>
              selectedExtension === SELECT_ALL_CHOICE.toLowerCase() ||
              blobRef.mimeType.toLowerCase() === selectedExtension
          )
          .map((attachment) => {
            if (attachment)
              return (
                <AttachmentPreview
                  key={attachment.id}
                  shrinkYPx={0}
                  attachment={attachment}
                />
              );
            else return null;
          })}
      </AttachmentsContainer>
    </Container>
  );
};

const Container = styled.div<{ lastSection?: boolean }>`
  margin-top: 24px;
  width: 100%;
  padding-bottom: 24px;
  ${({ theme, lastSection }) =>
    !lastSection && `border-bottom: 1px solid ${theme.colors.cDCDCDC}`};
  padding-left: 2px;
`;

const SelectContainer = styled(FlexContainer)`
  margin-bottom: 16px;
`;

const AttachmentsContainer = styled(FlexContainer)`
  margin-left: -10px;
  & > div {
    margin: 10px;
  }
`;
