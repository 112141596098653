import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../SvgIcon';
import { Search } from '../../../svg';
import { Theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { FlexContainer } from '../../layout/FlexContainer';
import { HorizontalLoader } from '../../loader/HorizontalLoader';
import { useLazyQueryHook } from '../../hooks/useLazyQuerHook';
import { AUTOCOMPLETE } from '../../../graphql/search/queries';
import { AutocompleteEntry, SintropiEntityType } from '@pec-manager/graphql';
import { throttle } from 'lodash';
import { useParams } from 'react-router-dom';
import { useKeyPress } from '../../input/useKeyPress';
import { SuggestionItem } from './SuggestionItem';
import { Text } from '../Text';
import { removeTagsFromHtml } from './utils';

interface SearchBarProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  actionSearchBar?: (val: string) => void;
  placeholder?: string;
  typeEntityAutocomplete: SintropiEntityType[];
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({
  setSearchValue,
  searchValue,
  actionSearchBar,
  placeholder = 'search',
  typeEntityAutocomplete,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { appId } = useParams();
  const [onChangeText, setOnChangeText] = useState('');
  const [focusInput, setFocusInput] = useState(false);
  const [autocompleteResult, setAutocompleteResult] = useState<
    AutocompleteEntry[] | undefined
  >(undefined);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState(-1);
  const [hovered, setHovered] = useState<string | undefined>(undefined);

  useEffect(() => {
    setOnChangeText(searchValue);
  }, [searchValue]);

  const [
    autocompleteQuery,
    { data: autocompleteData, loading: autocompleteLoading },
  ] = useLazyQueryHook({
    queryGql: AUTOCOMPLETE,
  });

  useEffect(() => {
    if (
      typeEntityAutocomplete.length > 0 &&
      autocompleteData &&
      autocompleteData.autocomplete.length &&
      downPress
    ) {
      setCursor((prevState) =>
        prevState < autocompleteData.autocomplete.length - 1
          ? prevState + 1
          : prevState
      );
      setOnChangeText(
        removeTagsFromHtml(
          autocompleteData.autocomplete[
            cursor < autocompleteData.autocomplete.length - 1
              ? cursor + 1
              : cursor
          ].queryPlusText
        )
      );
    }
    // eslint-disable-next-line
  }, [autocompleteData, downPress]);

  useEffect(() => {
    if (
      typeEntityAutocomplete.length > 0 &&
      autocompleteData &&
      autocompleteData.autocomplete.length &&
      enterPress &&
      cursor > -1
    ) {
      setSearchValue(
        removeTagsFromHtml(autocompleteData.autocomplete[cursor].queryPlusText)
      );
      actionSearchBar &&
        actionSearchBar(
          removeTagsFromHtml(
            autocompleteData.autocomplete[cursor].queryPlusText
          )
        );
      setOnChangeText('');
      setAutocompleteResult(undefined);
      setCursor(-1);
    }
    // eslint-disable-next-line
  }, [cursor, enterPress]);

  useEffect(() => {
    if (autocompleteData && autocompleteData.autocomplete.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
      setOnChangeText(
        removeTagsFromHtml(
          autocompleteData.autocomplete[cursor > 0 ? cursor - 1 : cursor]
            .queryPlusText
        )
      );
    }
    // eslint-disable-next-line
  }, [autocompleteData, upPress]);

  useEffect(() => {
    if (autocompleteData) {
      setAutocompleteResult(autocompleteData.autocomplete);
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (
      typeEntityAutocomplete.length > 0 &&
      autocompleteResult &&
      autocompleteResult.length > 0 &&
      hovered
    ) {
      setCursor(
        autocompleteResult
          .map((a: AutocompleteEntry) => a.queryPlusText)
          .indexOf(hovered)
      );
    }
    // eslint-disable-next-line
  }, [autocompleteData, hovered]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fillFieldDebounced = useCallback(
    throttle((value) => {
      autocompleteQuery({
        variables: {
          input: {
            appId,
            searchString: value,
            types: typeEntityAutocomplete,
            maxResults: 10,
          },
        },
      });
    }, 100),
    []
  );

  const debounceAutocomplete = (value: string) => {
    if (value.length > 0) {
      fillFieldDebounced(value);
    } else {
      setAutocompleteResult([]);
    }
  };

  return (
    <Container>
      <InputContainer>
        <IconContainer
          focused={focusInput}
          onClick={() => {
            setSearchValue(onChangeText);
            actionSearchBar && actionSearchBar(onChangeText);
            setAutocompleteResult(undefined);
          }}
        >
          <SvgIcon
            svg={<Search />}
            center
            height="16px"
            color={Theme.colors.c505050}
          />
        </IconContainer>
        <InputStyled
          ref={ref}
          type="text"
          placeholder={t(placeholder) as string}
          value={onChangeText}
          onFocus={() => setFocusInput(true)}
          onBlur={() => setFocusInput(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearchValue((e.target as any).value);
              actionSearchBar && actionSearchBar((e.target as any).value || '');
              setAutocompleteResult(undefined);
            }
          }}
          onChange={(e) => {
            setOnChangeText((e.target as any).value);
            setSearchValue((e.target as any).value);
            typeEntityAutocomplete.length > 0 &&
              debounceAutocomplete((e.target as any).value || '');
          }}
        />
      </InputContainer>
      {typeEntityAutocomplete.length > 0 &&
        onChangeText !== '' &&
        autocompleteResult && (
          <AutocompleteContainer>
            {autocompleteLoading && (
              <LoaderContainer justifyContent="center">
                <HorizontalLoader
                  size="l"
                  top="-16px"
                  color={Theme.colors.c4C9AFF}
                />
              </LoaderContainer>
            )}
            {!autocompleteLoading &&
              (autocompleteResult.length > 0 ? (
                autocompleteResult.map((item: AutocompleteEntry, index) => (
                  <SuggestionItem
                    text={item.queryPlusText}
                    key={item.text}
                    index={index}
                    hovered={hovered}
                    setHovered={setHovered}
                    onMouseDown={(e, text) => {
                      e.stopPropagation();
                      setSearchValue(text);
                      actionSearchBar && actionSearchBar(text);
                      setOnChangeText(text);
                      setAutocompleteResult(undefined);
                    }}
                    cursor={cursor}
                  />
                ))
              ) : (
                <EmptySuggestionContainer>
                  <Text
                    text="noSuggest"
                    fontSize="14px"
                    lineHeight="14px"
                    color={Theme.colors.c838383}
                  />
                </EmptySuggestionContainer>
              ))}
          </AutocompleteContainer>
        )}
    </Container>
  );
};

const Container = styled.div`
  height: 30px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 990px) {
    height: 50px;
    width: 100%;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

const InputStyled = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.cDFE1E6};
  border-left: none;
  padding: 6px 5px 5px 5px;
  height: 100%;
  border-radius: 0 5px 5px 0;
  outline: none;
  font-size: 13px;

  &:focus {
    color: ${({ theme }) => theme.colors.c000000};
    border: 1px solid ${({ theme }) => theme.colors.c4C9AFF};
    border-left: none;
  }
`;

const IconContainer = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  padding-left: 5px;
  border: 1px solid
    ${({ theme, focused }) =>
      focused ? theme.colors.c4C9AFF : theme.colors.cDFE1E6};
  border-right: none;
  text-align: center;
  border-radius: 5px 0 0 5px;
  font-size: 20px;
  outline: none;
`;

const AutocompleteContainer = styled.div`
  min-height: 30px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.cDFE1E6};
  z-index: 2;
  background: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 5px;
  overflow: auto;
  position: absolute;
  top: 30px;

  @media (max-width: 990px) {
    min-width: 100%;
    top: 50px;
  }
`;

const LoaderContainer = styled(FlexContainer)`
  height: 15px;
  width: 100%;
  padding-right: 14px;
`;

const EmptySuggestionContainer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 8px;
`;
