import { ExecutionContext } from '@pec-manager/graphql/lib/graphql.types';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PagedFormDefinitionComponent } from '../../components/PagedFormDefinition';
import { ContainerModal } from '../../components/modals/ContainerModal';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { useMail } from '../../components/hooks/useMail';

interface TakeInChargeProps {
  viewInModal: boolean;
  context: 'inbox' | 'sintropi';
}

export const TakeInCharge: FunctionComponent<TakeInChargeProps> = ({
  viewInModal,
  context,
}) => {
  const { data: mail } = useMail();
  const navigate = useNavigate();
  const { appId } = useParams();

  const clickOnClose = () => {
    navigate(`/${appId}/${context}`);
  };

  if (mail) {
    return !viewInModal ? (
      <PagedFormDefinitionComponent
        id={mail.base.id}
        title={mail.base.subject}
        executionContext={ExecutionContext.TASK}
        resourceId={mail.base.id}
        closeOnClick={clickOnClose}
        closeAllModals={clickOnClose}
        viewInModal={viewInModal}
      />
    ) : (
      <ContainerModal position="fixed">
        <Container onClick={(e) => e.stopPropagation()}>
          <PagedFormDefinitionComponent
            id={mail.base.id}
            title={mail.base.subject}
            executionContext={ExecutionContext.TASK}
            resourceId={mail.base.id}
            closeOnClick={clickOnClose}
            closeAllModals={clickOnClose}
            viewInModal={viewInModal}
          />
        </Container>
      </ContainerModal>
    );
  }
  // TODO Loader
  return <></>;
};

const Container = styled(FlexContainer)`
  width: 100%;
  padding: 16px 8px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: 1040px) {
    padding: 0;
    margin: 20px;
    max-height: calc(100vh - 100px);
  }
`;
