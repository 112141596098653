import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { KpiContainer } from './KpiContainer';
import { KpiRow } from '@pec-manager/graphql';
import { GenericTab } from './GenericTab';

interface BusinessKpiProps {
  notMobile?: boolean;
}

export const BusinessKpi: FunctionComponent<BusinessKpiProps> = ({
  notMobile,
}) => {
  const [kpiRow, setKpiRow] = useState<KpiRow[] | undefined>(undefined);

  return (
    <KpiContainer notMobile={notMobile} setKpiRow={setKpiRow}>
      <Container>
        {!kpiRow && <div>Loading...</div>}
        {kpiRow && kpiRow.length === 0 && <div>Empty</div>}

        {kpiRow && kpiRow.length > 0 && (
          <GenericTab kpiRow={kpiRow} notMobile={notMobile} />
        )}
      </Container>
    </KpiContainer>
  );
};

const Container = styled.div`
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: auto;
  padding-bottom: 20px;
`;
