import { gql } from '@apollo/client/core';
import {
  gqlFullTaskResolutionShape,
  gqlTaskSummaryPagingWrapperShape,
} from './shapes';

export const GET_TASK_BY_ID = gql`
    query GetTaskById($taskId: ID!) {
        getTaskById(taskId: $taskId) {
            ${gqlFullTaskResolutionShape}
        }
    }
`;

export const GET_TASKS_IN_INBOX = gql`
    query GetTasksInInbox($appId: ID!, $pagination: Pagination, $sorts: [Sort], $fullSearchText: String) {
        getTasksInInbox(appId: $appId, pagination: $pagination, sorts: $sorts, fullSearchText: $fullSearchText) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_IN_INBOX_WITH_DUE_DATE_IN_RANGE = gql`
    query GetTasksInInboxWithDueDateInRange($appId: ID!, $from: Date!, $to: Date!, ) {
        getTasksInInboxWithDueDateInRange(appId: $appId, from: $from, to: $to) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_IN_INBOX_WITHOUT_DUE_DATE = gql`
    query GetTasksInInboxWithoutDueDate($appId: ID!, $pagination: Pagination, $sorts: [Sort], $fullSearchText: String) {
        getTasksInInboxWithoutDueDate(appId: $appId, pagination: $pagination, sorts: $sorts, fullSearchText: $fullSearchText) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_ASSIGNMENT_TYPE = gql`
    query GetTasksByAssignmentType($appId: ID!, $assignmentType: ResourceAssignmentType!, $pagination: Pagination, $sorts: [Sort], $fullSearchText: String) {
        getTasksByAssignmentType(appId: $appId, assignmentType: $assignmentType, pagination: $pagination, sorts: $sorts, fullSearchText: $fullSearchText) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_STATE = gql`
    query GetTasksByState($appId: ID!, $taskState: ResourceState!, $pagination: Pagination, $sorts: [Sort], $fullSearchText: String) {
        getTasksByState(appId: $appId, taskState: $taskState, pagination: $pagination, sorts: $sorts, fullSearchText: $fullSearchText) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_SAVED_SEARCH_ID = gql`
    query GetTasksBySavedSearchId($appId: ID!, $savedSearchId: ID!, $pagination: Pagination) {
        getTasksBySavedSearchId(appId: $appId, savedSearchId: $savedSearchId, pagination: $pagination) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_SIMPLE_SEARCH = gql`
    query GetTasksBySimpleSearch($appId: ID!, $searchFilter: String!, $pagination: Pagination) {
        getTasksBySimpleSearch (appId: $appId, searchFilter: $searchFilter, pagination: $pagination) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_FILLED_ADVANCED_SEARCH_FORM = gql`
    query GetTasksByFilledAdvancedSearchForm($appId: ID!, $filledForm: FilledForm!, $pagination: Pagination) {
        getTasksByFilledAdvancedSearchForm (appId: $appId, filledForm: $filledForm, pagination: $pagination) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_ASSIGNMENT_TYPE_WITH_DUE_DATE_IN_RANGE = gql`
    query GetTasksByAssignmentTypeWithDueDateInRange($appId: ID!, $assignmentType: ResourceAssignmentType!, $from: Date!, $to: Date!) {
        getTasksByAssignmentTypeWithDueDateInRange(appId: $appId, assignmentType: $assignmentType, from: $from, to: $to) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_STATE_WITH_DUE_DATE_IN_RANGE = gql`
    query GetTasksByStateWithDueDateInRange($appId: ID!, $taskState: ResourceState!, $from: Date!, $to: Date!) {
        getTasksByStateWithDueDateInRange(appId: $appId, taskState: $taskState, from: $from, to: $to) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_ASSIGNMENT_TYPE_WITHOUT_DUE_DATE = gql`
    query GetTasksByAssignmentTypeWithoutDueDate($appId: ID!, $assignmentType: ResourceAssignmentType!, $pagination: Pagination, $sorts: [Sort], $fullSearchText: String){
        getTasksByAssignmentTypeWithoutDueDate(appId: $appId, assignmentType: $assignmentType, pagination: $pagination, sorts: $sorts, fullSearchText: $fullSearchText) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;

export const GET_TASKS_BY_STATE_WITHOUT_DUE_DATE = gql`
    query GetTasksByStateWithoutDueDate($appId: ID!, $taskState: ResourceState!, $pagination: Pagination, $sorts: [Sort], $fullSearchText: String){
        getTasksByStateWithoutDueDate(appId: $appId, taskState: $taskState, pagination: $pagination, sorts: $sorts, fullSearchText: $fullSearchText) {
            ${gqlTaskSummaryPagingWrapperShape}
        }
    }
`;
