import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ReassignModal } from '../../components/modals/ReassignModal';
import { ContainerModal } from '../../components/modals/ContainerModal';
import { useTask } from '../../components/hooks/useTask';
import { TaskDetails } from './TaskDetails';
import { useNotificationBanner } from '../../components/hooks/useNotificationBanner';
import { v4 as uuidv4 } from 'uuid';

export enum ModalTypeInbox {
  SPAM,
  REASSIGN,
}

export const TaskDetailsHandler: FunctionComponent = () => {
  const navigate = useNavigate();
  const { data: task, error } = useTask();
  const [modalType, setModalType] = useState<ModalTypeInbox | undefined>();

  useNotificationBanner(undefined, error, undefined, {
    id: uuidv4(),
    type: 'failed',
    title: 'Woops!',
    content: 'somethingWentWrongs',
  });

  const clickOnClose = () => {
    navigate(-1);
  };

  const modal = () => {
    switch (modalType) {
      case ModalTypeInbox.REASSIGN:
        return (
          <ReassignModal
            id={task.base.id}
            closeOnClick={() => {
              clickOnClose();
              setModalType(undefined);
            }}
            type="TASK"
          />
        );
      default:
        return null;
    }
  };

  return (
    <ContainerModal position="fixed">
      <Container onClick={(e) => e.stopPropagation()}>
        <TaskDetails
          task={task}
          setModalType={setModalType}
          clickOnClose={clickOnClose}
        />
        {task && modal()}
      </Container>
    </ContainerModal>
  );
};

const Container = styled(FlexContainer)<{ chatIsOpen?: boolean }>`
  width: 100%;
  max-height: calc(100vh - 100px);
  max-width: 1440px;
  margin: 20px;
  overflow-y: auto;
  ${({ chatIsOpen }) => chatIsOpen && 'padding-bottom: 80px;'}

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: 1460px) {
    width: calc(100% - 40px);
  }

  @media (max-width: 990px) {
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
