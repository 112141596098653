import { gql } from '@apollo/client/core';

export const ADD_COMMENT = gql`
  mutation AddComment(
    $resource: ResourceInput!
    $resourceName: String!
    $body: String!
    $mentionIds: [ID!]!
  ) {
    addComment(
      resource: $resource
      resourceName: $resourceName
      body: $body
      mentionIds: $mentionIds
    ) {
      _
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation RemoveComment($resourceId: ID!, $commentId: ID!) {
    removeComment(resourceId: $resourceId, commentId: $commentId) {
      _
    }
  }
`;

export const EDIT_COMMENT = gql`
  mutation EditComment(
    $resourceId: ID!
    $commentId: ID!
    $body: String!
    $mentionIds: [ID!]!
  ) {
    editComment(
      resourceId: $resourceId
      commentId: $commentId
      body: $body
      mentionIds: $mentionIds
    ) {
      _
    }
  }
`;

export const REPLY_TO_COMMENT = gql`
  mutation ReplyToComment(
    $resourceId: ID!
    $commentId: ID!
    $body: String!
    $mentionIds: [ID!]!
  ) {
    replyToComment(
      resourceId: $resourceId
      commentId: $commentId
      body: $body
      mentionIds: $mentionIds
    ) {
      _
    }
  }
`;
