import { useLazyQuery, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { KeycloakService } from '../../services/KeycloakService';

import { RESOURCE_RECEIVED } from '../../graphql/resource/subscriptions';
import { useLocation, useParams } from 'react-router-dom';
import { getTypeAppInPath } from '../../utils/stringUtils';
import { deleteTask, updateTask } from '../../redux/Sintropi/task/actions';
import {
  Execution,
  Mail,
  MailReceived,
  ResourceContextInbox,
  ResourceContextSection,
  ResourceContextSintropi,
  Task,
  TaskReceived,
} from '@pec-manager/graphql';
import { deleteMail, updateMail } from '../../redux/Inbox/mail/actions';
import {
  deleteExecution,
  updateExecutions,
} from '../../redux/Sintropi/execution/actions';
import { GET_EXECUTION_BY_ID } from '../../graphql/execution/queries';
import { GET_TASK_BY_ID } from '../../graphql/task/queries';
import { GET_MAIL_BY_ID } from '../../graphql/mail/queries';

export const useResourceUpdateReceived = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { appId } = useParams();
  const typeApp = useMemo(() => getTypeAppInPath(location), [location]);
  const sectionElementId = searchParams.get('elementId');
  const sectionElementDropDownElementId = searchParams.get('dropDownId');

  const { data } = useSubscription(RESOURCE_RECEIVED, {
    variables: {
      correlationId: KeycloakService.getToken(),
      appId: `${appId}`,
    },
  });

  const [getExecutionById] = useLazyQuery(GET_EXECUTION_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [getTaskById] = useLazyQuery(GET_TASK_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const [getMailById] = useLazyQuery(GET_MAIL_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      data?.resourceReceived.executionReceived?.execution &&
      typeApp === 'sintropi'
    ) {
      getExecutionById({
        variables: {
          executionId:
            data.resourceReceived.executionReceived.execution.rootExecutionId ||
            data.resourceReceived.executionReceived.execution.id,
        },
      }).then((result: any) => {
        const execution = result.data.getExecutionById as Execution;
        return dispatch(updateExecutions(execution));
      });
    }
    if (data?.resourceReceived.taskReceived?.task && typeApp === 'sintropi') {
      const { contexts, task }: TaskReceived =
        data.resourceReceived.taskReceived;

      if (
        sectionElementId &&
        task.base.status ===
          (sectionElementId === 'WORKED' ? 'COMPLETED' : sectionElementId)
      ) {
        getTaskById({
          variables: {
            taskId: task.base.id,
          },
        }).then((result: any) => {
          const taskResult = result.data.getTaskById as Task;
          return dispatch(
            updateTask({
              base: taskResult.base,
              attachmentCount: task.attachmentCount,
              commentCount: task.commentCount,
            })
          );
        });
      } else {
        if (
          sectionElementDropDownElementId &&
          contexts.some(
            (a: ResourceContextSintropi) =>
              a.assignmentType === sectionElementDropDownElementId
          )
        ) {
          getTaskById({
            variables: {
              taskId: task.base.id,
            },
          }).then((result: any) => {
            const taskResult = result.data.getTaskById as Task;
            return dispatch(
              updateTask({
                base: taskResult.base,
                attachmentCount: task.attachmentCount,
                commentCount: task.commentCount,
              })
            );
          });
        } else {
          dispatch(deleteTask(task.base.id));
        }
        if (
          sectionElementId &&
          sectionElementId ===
            (contexts.some(
              (a: ResourceContextSintropi) =>
                a.contextSection === ResourceContextSection.DEFAULT
            )
              ? 'SINTROPI'
              : '') &&
          !sectionElementDropDownElementId
        ) {
          getTaskById({
            variables: {
              taskId: task.base.id,
            },
          }).then((result: any) => {
            const taskResult = result.data.getTaskById as Task;
            return dispatch(
              updateTask({
                base: taskResult.base,
                attachmentCount: task.attachmentCount,
                commentCount: task.commentCount,
              })
            );
          });
        }
      }
    }
    if (data?.resourceReceived.mailReceived && typeApp === 'inbox') {
      const { contexts, mail }: MailReceived =
        data.resourceReceived.mailReceived;

      if (
        sectionElementId &&
        contexts.some(
          (a: ResourceContextInbox) => a.mailState === sectionElementId
        )
      ) {
        getMailById({
          variables: {
            mailId: mail.base.id,
          },
        }).then((result: any) => {
          const mailResult = result.data.mailById as Mail;
          return dispatch(
            updateMail({
              base: mailResult.base,
              attachmentCount: mail.attachmentCount,
              commentCount: mail.commentCount,
              correlatedMails: mail.correlatedMails,
            })
          );
        });
      } else {
        if (
          sectionElementDropDownElementId &&
          contexts.some(
            (a: ResourceContextInbox) =>
              a.assignmentType === sectionElementDropDownElementId
          )
        ) {
          getMailById({
            variables: {
              mailId: mail.base.id,
            },
          }).then((result: any) => {
            const mailResult = result.data.mailById as Mail;
            return dispatch(
              updateMail({
                base: mailResult.base,
                attachmentCount: mail.attachmentCount,
                commentCount: mail.commentCount,
                correlatedMails: mail.correlatedMails,
              })
            );
          });
        }
        if (
          sectionElementId ===
            (contexts.some(
              (a: ResourceContextInbox) =>
                a.contextSection === ResourceContextSection.DEFAULT
            )
              ? 'INBOX'
              : '') &&
          !sectionElementDropDownElementId
        ) {
          getMailById({
            variables: {
              mailId: mail.base.id,
            },
          }).then((result: any) => {
            const mailResult = result.data.mailById as Mail;
            return dispatch(
              updateMail({
                base: mailResult.base,
                attachmentCount: mail.attachmentCount,
                commentCount: mail.commentCount,
                correlatedMails: mail.correlatedMails,
              })
            );
          });
        }
      }
    }
    if (data?.resourceReceived.taskDeleted?.taskId) {
      dispatch(deleteTask(data.resourceReceived.taskDeleted.taskId));
    }
    if (data?.resourceReceived.executionDeleted?.executionId) {
      dispatch(
        deleteExecution(data.resourceReceived.executionDeleted.executionId)
      );
    }
    if (data?.resourceReceived.mailDeleted?.mailId) {
      dispatch(deleteMail(data.resourceReceived.mailDeleted.mailId));
    }
    // eslint-disable-next-line
  }, [data, dispatch]);
};
