import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { BaseExecution, ExecutionStatus } from '@pec-manager/graphql';

interface BottomSectionProps {
  execution: BaseExecution;
}

export const BottomSection: FunctionComponent<BottomSectionProps> = ({
  execution,
}) => {
  return (
    <Container justifyContent="space-between" wrapContent alignItems="center">
      <ItemContainer alignItems="center" marginRight="8px">
        <Item alignItems="center" justifyContent="center">
          <Text
            text={
              execution.status === ExecutionStatus.CANCELED &&
              execution.cancelledBy
                ? 'executionCancelledBy'
                : 'executionStartedBy'
            }
            fontSize="12px"
            translationArgs={{
              name: execution.cancelledBy
                ? `${execution.cancelledBy.firstName} ${execution.cancelledBy.lastName}`
                : `${execution.startedBy?.firstName || ''} ${
                    execution.startedBy?.lastName || ''
                  }`,
            }}
            lineHeight="20px"
            color={Theme.colors.c505050}
          />
        </Item>
        {/*
        <>
          <Text
            text="notAssigned"
            fontSize="10px"
            lineHeight="14px"
            fontWeight="300"
            color={Theme.colors.c838383}
          />
          <ContainerTakeInCharge
            onClick={() => {
              history.push(`/sintropi/takeincharge${historyPushString}`);
            }}
          >
            <Text
              text="takeCharge"
              fontSize="10px"
              lineHeight="14px"
              fontWeight="600"
              color={Theme.colors.c2186C6}
            />
          </ContainerTakeInCharge>
        </>{' '}
        */}
      </ItemContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)<{
  selected?: boolean;
  hideCorrelateSection?: boolean;
}>`
  width: 100%;
  height: 48px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cC7E7FF : theme.colors.cFCFCFC};
  padding: 0 16px;
  border-radius: 0 0 8px 8px;
`;

const ItemContainer = styled(FlexContainer)<{
  marginRight?: string;
  marginLeft?: string;
}>`
  min-width: 100%;
  & > span {
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  }
`;

const Item = styled(FlexContainer)`
  width: 100%;
`;
