import { gql } from '@apollo/client/core';
import { gqlTaskProgressEventShape } from '../task/shapes';

export const TASK_PROGRESS_EVENT_RECEIVED = gql`
  subscription TaskProgressEventReceived($correlationId: String!) {
      taskProgressEventReceived(correlationId: $correlationId) {
        ${gqlTaskProgressEventShape}
    }
  }
`;
