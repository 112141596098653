import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.cDFE1E6};
  border-radius: 6px;
  height: 40px;
  outline: none;
  padding: 10px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.c4C9AFF};
  }
`;
