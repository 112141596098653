import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { BlobRef } from '@pec-manager/graphql';
import { Text } from '../../common/Text';
import { Color, Theme } from '../../../theme';
import { getMappedColor } from '../../../utils/colorUtils';
import { FlexContainer } from '../../layout/FlexContainer';
import { SvgIcon } from '../../common/SvgIcon';
import { Download, Eye, Trash } from '../../../svg';
import { AttachmentPreviewModal } from '../AttachmentPreviewModal';
import { HorizontalLoader } from '../../loader/HorizontalLoader';
import { downloadAndSave, downloadFile } from '../../../utils/file';

interface DefaultValueAttachmentFormFieldProps {
  documentObject: BlobRef;
  isLocalContext?: boolean;
  removeAttachments?: (id: string, isLocalContext?: boolean) => void;
  setFileLocaleView?: (fileLocaleView?: string) => void;
  setFileIdViewActive: React.Dispatch<React.SetStateAction<string | undefined>>;
  fileIdViewActive?: string;
  isEditable?: boolean;
}

export const AttachmentItemFormField: FunctionComponent<
  DefaultValueAttachmentFormFieldProps
> = ({
  documentObject,
  isLocalContext,
  removeAttachments,
  setFileLocaleView,
  setFileIdViewActive,
  fileIdViewActive,
  isEditable,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <Container alignItems="center" wrapContent>
      <ContainerMainField>
        <TitleText
          text={documentObject.name}
          fontSize="12px"
          lineHeight="18px"
          skipTranslation
        />
        {documentObject.mimeType && (
          <ExtensionContainer
            background={getMappedColor(documentObject.mimeType)}
          >
            <ExtensionText
              text={documentObject.mimeType.toUpperCase()}
              fontSize="10px"
              lineHeight="15px"
              skipTranslation
              color={Theme.colors.cFFFFFF}
            />
          </ExtensionContainer>
        )}
      </ContainerMainField>

      {isLocalContext ? (
        <ContainerLoader alignItems="center">
          <HorizontalLoader size="l" top="33px" color={Theme.colors.c4C9AFF} />
        </ContainerLoader>
      ) : (
        <ContainerRight alignItems="center">
          {documentObject.mimeType === 'application/pdf' && (
            <>
              <PreviewContainer
                onClick={() => {
                  if (setFileLocaleView) {
                    if (fileIdViewActive === documentObject.id) {
                      setFileIdViewActive(undefined);
                      setFileLocaleView(undefined);
                    } else {
                      setFileIdViewActive(documentObject.id);
                      return (async () => {
                        const fileLocale = await downloadFile(
                          documentObject.downloadUrl
                        );

                        const createObjectURL = URL.createObjectURL(
                          fileLocale.file
                        );
                        setFileLocaleView(createObjectURL);
                      })();
                    }
                  } else {
                    setShowPreview(true);
                  }
                }}
              >
                <SvgIcon
                  svg={<Eye />}
                  width="25px"
                  height="20px"
                  color={
                    fileIdViewActive === documentObject.id
                      ? Theme.colors.c52C41A
                      : Theme.colors.c0E53B7
                  }
                />
              </PreviewContainer>
              <PreviewContainerMobile
                onClick={() => {
                  setShowPreview(true);
                }}
              >
                <SvgIcon
                  svg={<Eye />}
                  width="25px"
                  height="20px"
                  color={Theme.colors.c0E53B7}
                />
              </PreviewContainerMobile>
            </>
          )}
          <div
            onClick={async () => {
              await downloadAndSave(documentObject.downloadUrl);
            }}
          >
            <SvgIcon
              svg={<Download />}
              height="15px"
              width="25px"
              color={Theme.colors.c0E53B7}
            />
          </div>
          {removeAttachments && isEditable ? (
            <div
              onClick={() => {
                removeAttachments(documentObject.id, isLocalContext);
              }}
            >
              <SvgIcon svg={<Trash />} height="14px" width="25px" />
            </div>
          ) : null}
        </ContainerRight>
      )}
      {showPreview && (
        <AttachmentPreviewModal
          closeOnClick={() => setShowPreview(false)}
          downloadUrl={documentObject.downloadUrl}
          nameFile={documentObject.name}
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  min-height: 40px;
  width: calc(50% - 40px);
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.colors.cDFE1E6};
  padding: 0 16px;

  @media (max-width: 1440px) {
    width: 100%;
  }

  @media (max-width: 990px) {
    width: 100%;
    flex-direction: column;
    padding: 8px 16px;
  }
`;

const ContainerMainField = styled.div`
  display: flex;
  align-items: center;

  width: calc(100% - 145px);

  @media (max-width: 990px) {
    width: 100%;
  }
`;

const TitleText = styled(Text)`
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 990px) {
    max-width: calc(100% - 50px);
  }
`;

const ExtensionText = styled(Text)`
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExtensionContainer = styled.div<{ background: Color | string }>`
  background-color: ${({ background }) => background};
  height: 17px;
  width: fit-content;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-left: 13px;
`;

const PreviewContainer = styled.div`
  & > div {
    display: flex;
    padding: 1px 0;
  }

  @media (max-width: 990px) {
    display: none;
  }
`;

const PreviewContainerMobile = styled.div`
  & > div {
    display: flex;
    padding: 1px 0;
  }

  @media (min-width: 990px) {
    display: none;
  }
`;

const ContainerRight = styled(FlexContainer)`
  height: 24px;
  margin-left: auto;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};

  & > div:not(:last-child) {
    margin-right: 10px;
  }

  & > div,
  a {
    padding: 5px 5px;
    border-radius: 100%;
    transition: 150ms background ease-in-out;
    &:hover {
      background: ${({ theme }) => theme.colors.transparentGrey};
      cursor: pointer;
    }
  }

  @media (max-width: 990px) {
    border: none;
    margin: 0;

    & > div:not(:first-child) {
      margin-right: 15px;
    }
    & > div,
    a {
      padding: 5px 10px;
    }
  }
`;

const ContainerLoader = styled(FlexContainer)`
  height: 24px;
  margin-left: auto;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;
