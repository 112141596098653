import { createReducer, current } from '@reduxjs/toolkit';
import {
  Notification,
  NotificationStatus,
  TaskProgressFlow,
} from '@pec-manager/graphql';
import {
  addInitialTasksProgressFlowAction,
  addTaskProgressFlowAction,
  closeNotificationModalAction,
  deleteNotificationAction,
  initNotificationsAction,
  pushNotificationAction,
  pushNotificationBannerAction,
  readNotificationAction,
  removeNotificationBannerAction,
  removeTaskProgressFlowAction,
  toggleNotificationModalAction,
  updateNotificationAction,
} from './actions';

export interface NotificationBanner {
  type: 'success' | 'failed';
  title: string;
  content?: string;
  id: string;
  variables?: any;
}

export interface NotificationState {
  showModal: boolean;
  notifications: Notification[];
  notificationBanners: NotificationBanner[];
  joiningTask: TaskProgressFlow[];
}

const defaultState: NotificationState = {
  showModal: false,
  notifications: [],
  notificationBanners: [],
  joiningTask: [],
};

export const notificationReducer = createReducer<NotificationState>(
  defaultState,
  {
    [toggleNotificationModalAction.type]: (state): NotificationState => ({
      ...state,
      showModal: !state.showModal,
    }),
    [closeNotificationModalAction.type]: (state): NotificationState => ({
      ...state,
      showModal: false,
    }),
    [initNotificationsAction.type]: (state, action): NotificationState => ({
      ...state,
      notifications: action.payload,
    }),
    [pushNotificationAction.type]: (state, action): NotificationState => ({
      ...state,
      notifications: [
        action.payload,
        ...state.notifications.filter((n) => n.id !== action.payload.id),
      ],
    }),
    [updateNotificationAction.type]: (state, action): NotificationState => ({
      ...state,
      notifications: [
        {
          ...state.notifications.find(
            (n) => n.id === action.payload.notificationId
          ),
          ...action.payload,
          notificationStatus: NotificationStatus.UNREAD,
        },
        ...state.notifications.filter(
          (n) => n.id !== action.payload.notificationId
        ),
      ],
    }),
    [readNotificationAction.type]: (state, action): NotificationState => ({
      ...state,
      notifications: state.notifications.map((n) =>
        n.id === action.payload
          ? { ...n, notificationStatus: NotificationStatus.READ }
          : n
      ),
    }),
    [deleteNotificationAction.type]: (state, action): NotificationState => ({
      ...state,
      notifications: state.notifications.filter((n) => n.id !== action.payload),
    }),
    [pushNotificationBannerAction.type]: (
      state,
      action
    ): NotificationState => ({
      ...state,
      notificationBanners: [...state.notificationBanners, action.payload],
    }),
    [removeNotificationBannerAction.type]: (
      state,
      action
    ): NotificationState => ({
      ...state,
      notificationBanners: state.notificationBanners.filter(
        (n) => n.id !== action.payload.id
      ),
    }),
    [addInitialTasksProgressFlowAction.type]: (
      state,
      action
    ): NotificationState => ({
      ...state,
      joiningTask: action.payload,
    }),
    [addTaskProgressFlowAction.type]: (state, action): NotificationState => {
      const task = current(state).joiningTask.find(
        (n) => n.taskId === action.payload.taskId
      );

      return {
        ...state,
        joiningTask: [
          ...state.joiningTask.filter(
            (n) => n.taskId !== action.payload.taskId
          ),
          task
            ? {
                ...task,
                progressSteps: [...action.payload.progressSteps],
              }
            : action.payload,
        ],
      };
    },
    [removeTaskProgressFlowAction.type]: (
      state,
      action
    ): NotificationState => ({
      ...state,
      joiningTask:
        state.joiningTask.filter((n) => n.taskId !== action.payload) || [],
    }),
  }
);
