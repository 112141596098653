import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../layout/FlexContainer';
import { getMappedColor } from '../../utils/colorUtils';
import { upperFirstTwo } from '../../utils/stringUtils';
import { Color } from '../../theme';
import { SvgIcon } from '../common/SvgIcon';

interface RoundedTextAvatarProps {
  text: string;
  size?: number;
  fontSize?: number;
  backgroundColor?: Color | string;
  visibleTooltip?: boolean;
  icon?: any;
  hidden?: boolean;
}

export const RoundedTextAvatar: FunctionComponent<RoundedTextAvatarProps> = ({
  text,
  size,
  fontSize,
  backgroundColor,
  visibleTooltip,
  icon,
  hidden,
}) => (
  <RoundedCircle
    backgroundColor={backgroundColor || getMappedColor(text)}
    alignItems="center"
    justifyContent="center"
    size={size}
    fontSize={fontSize}
    visibleTooltip={visibleTooltip}
    hidden={hidden}
  >
    {icon ? (
      <SvgIcon
        svg={icon}
        height="14px"
        color={backgroundColor || getMappedColor(text)}
      />
    ) : (
      upperFirstTwo(text)
    )}
    {visibleTooltip && (
      <Tooltip style={{ visibility: 'visible' }}>{text}</Tooltip>
    )}
  </RoundedCircle>
);

interface ContainerProps {
  backgroundColor: string;
  size?: number;
  fontSize?: number;
  visibleTooltip?: boolean;
  hidden?: boolean;
}

export const RoundedCircle = styled(FlexContainer)<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ size }) => size ?? 40}px;
  min-width: ${({ size }) => size ?? 40}px;
  max-height: ${({ size }) => size ?? 40}px;
  max-width: ${({ size }) => size ?? 40}px;
  border-radius: ${({ size }) => (size ?? 40) / 2}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  font-size: ${({ fontSize }) => fontSize ?? 16}px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.cFFFFFF};
  ${({ hidden }) => hidden && 'display: none;'}
  line-height: 2px;
  ${({ visibleTooltip }) =>
    visibleTooltip &&
    '&:hover > span { opacity: 1; height: auto;transition: opacity 0.25s ease-in; }'}
`;

export const Tooltip = styled.span`
  position: absolute;
  bottom: -22px;
  opacity: 0;
  height: 0;
  overflow: hidden;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.c6A6A6A};
  min-width: max-content;
  left: 0;
  border-radius: 4px;
  padding: 8px 4px;
`;
