import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IdentifiableValue } from '@pec-manager/graphql';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { SintropiSidebarSectionItemDropDownItem } from './SintropiSidebarSectionItemDropDownItem';
import { useDispatch, useSelector } from 'react-redux';
import { taskSidebarStateSelector } from '../../../redux/Sintropi/sidebar/selectors';
import { setOpenMenuMobile } from '../../../redux/Sintropi/sidebar/actions';

interface SidebarSectionItemDropDownProps {
  dropDownItems?: IdentifiableValue[];
  sectionId: string;
  sectionElementId: string;
  isParentSelected: boolean;
}

export const SintropiSidebarSectionItemDropDown: FunctionComponent<
  SidebarSectionItemDropDownProps
> = ({ dropDownItems = [], sectionId, sectionElementId, isParentSelected }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appId } = useParams();

  const dispatch = useDispatch();

  const { menuMobileIsOpen } = useSelector(taskSidebarStateSelector);

  const sectionElementDropDownElementId = new URLSearchParams(
    location.search
  ).get('dropDownId');

  const onClickHandler = (dropDownElementId: string) => {
    if (menuMobileIsOpen) {
      dispatch(setOpenMenuMobile(false));
    }
    navigate(
      `/${appId}/sintropi?sectionId=${sectionId}&elementId=${sectionElementId}&dropDownId=${dropDownElementId}`
    );
  };

  return (
    <motion.section
      initial={{ height: 0, opacity: 0 }}
      animate={{
        height:
          16 * dropDownItems.length + 12 * (dropDownItems.length - 1) + 24,
        opacity: 1,
      }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ type: 'just' }}
    >
      <DropDownContainer column>
        {dropDownItems?.map(({ id, value }) => (
          <SintropiSidebarSectionItemDropDownItem
            key={id}
            sectionElementDropDownElementId={sectionElementDropDownElementId}
            name={value}
            id={id}
            isParentSelected={isParentSelected}
            onClickHandler={onClickHandler}
          />
        ))}
      </DropDownContainer>
    </motion.section>
  );
};

const DropDownContainer = styled(FlexContainer)`
  padding-left: 36px;
  & > section:first-child {
    margin-top: 24px;
  }

  & > section:not(:first-child) {
    margin-top: 12px;
  }
`;
