import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ProgressBarDetail } from './ProgressBarDetail';

interface ExecutionsDetailProps {
  total: number;
  partialCompleted: number;
  partialInProgress: number;
}

export const ExecutionsDetail: FunctionComponent<ExecutionsDetailProps> = ({
  partialCompleted,
  partialInProgress,
  total,
}) => (
  <Container column>
    <Text
      text="executions"
      fontSize="14px"
      lineHeight="21px"
      fontWeight="600"
      color={Theme.colors.c505050}
    />
    <DetailsContainer>
      <TotalDetails column alignItems="center" justifyContent="center">
        <Text
          text={`${total}`}
          fontSize="48px"
          lineHeight="58px"
          color={Theme.colors.c5B84EF}
        />
        <Text text="total" fontSize="12px" lineHeight="18px" />
      </TotalDetails>
      <FieldsContainer column justifyContent="space-between">
        <ProgressBarDetail
          title="completed"
          total={total}
          partial={partialCompleted}
          fillerColor={Theme.colors.c00CCB3}
        />
        <ProgressBarDetail
          title="inProgress"
          total={total}
          partial={partialInProgress}
          fillerColor={Theme.colors.cFFC53D}
        />
      </FieldsContainer>
    </DetailsContainer>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  height: 160px;
  padding: 16px 16px 0 16px;
`;

const DetailsContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  margin-top: 16px;
  padding-bottom: 16px;
`;

const TotalDetails = styled(FlexContainer)`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-right: 32px;
  padding-left: 16px;
`;

const FieldsContainer = styled(FlexContainer)`
  width: 100%;
`;
