import { gql } from '@apollo/client/core';
import { gqlNotificationResolutionShape } from './shapes';

export const GET_NOTIFICATIONS = gql`
    query GetNotifications($pagination: Pagination) {
        getNotifications(pagination: $pagination) {
            ${gqlNotificationResolutionShape}
        }
    }
`;

export const IS_SUBSCRIBED = gql`
  query IsSubscribed($entityId: ID!, $entityType: EntityType!) {
    isSubscribed(entityId: $entityId, entityType: $entityType)
  }
`;
