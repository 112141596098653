import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ResourcePriority } from '@pec-manager/graphql';
import { SvgIcon } from './SvgIcon';
import { FlexContainer } from '../layout/FlexContainer';
import { Theme } from '../../theme';
import { Text } from './Text';
import { Priority } from '../../svg';

interface TipsProps {
  value: ResourcePriority;
  size: string;
  lineHeight: string;
}
const mapColorIcon = {
  [ResourcePriority.HIGH]: Theme.colors.cFE4242,
  [ResourcePriority.MEDIUM]: Theme.colors.cFFC53D,
  [ResourcePriority.LOW]: Theme.colors.c7EB902,
  [ResourcePriority.NONE]: Theme.colors.c505050,
};

export const Tips: FunctionComponent<TipsProps> = ({
  value,
  size,
  lineHeight,
}) => (
  <TipsContainer alignItems="center">
    <SvgIcon svg={<Priority />} color={mapColorIcon[value]} width={size} />
    <StyledText
      text={`${value.toLowerCase()}Priority`}
      fontSize={size}
      lineHeight={lineHeight}
      color={Theme.colors.c838383}
    />
  </TipsContainer>
);

const TipsContainer = styled(FlexContainer)`
  width: fit-content;
`;

const StyledText = styled(Text)`
  margin-left: 5px;
`;
