import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  Container,
  FormFieldDefinition,
  BlobRef,
  DocumentObjectKind,
} from '@pec-manager/graphql';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { FormFieldContainer } from './FormFieldContainer';
import { SvgIcon } from '../../common/SvgIcon';
import { Arrow, Attachment } from '../../../svg';
import { Theme } from '../../../theme';
import { Text } from '../../common/Text';
import { FlexContainer } from '../../layout/FlexContainer';
import { Button } from '../../common/Button';
import { AttachmentItemFormField } from './AttachmentItemFormField';
import { boundaryError } from './utils/errorMessage';
import { env } from '../../../index';
import { UploadFileModal } from '../UploadFileModal';
import { useTranslation } from 'react-i18next';
import { DisplayInfo } from '../../DispayInfo';
import { KeycloakService } from '../../../services/KeycloakService';

interface AttachmentFormFieldProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  setFileLocaleView?: (fileLocaleView?: string) => void;
  setFileIdViewActive: React.Dispatch<React.SetStateAction<string | undefined>>;
  fileIdViewActive?: string;
  checkRequired?: boolean;
  columnForm?: number;
}

export const AttachmentFormField: FunctionComponent<
  AttachmentFormFieldProps
> = ({
  formField,
  fillField,
  errorMessage,
  setFileLocaleView,
  setFileIdViewActive,
  fileIdViewActive,
  checkRequired = false,
  columnForm,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [remoteAttachments, setRemoteAttachments] = useState<
    DocumentObjectKind[]
  >([]);

  const { t } = useTranslation();

  const [localAttachments, setLocalAttachments] = useState<BlobRef[]>([]);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);

  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  useEffect(() => {
    setErrorMessageDate(errorMessage);
  }, [errorMessage]);

  const removeAttachments = (id: string, isLocalContext?: boolean) => {
    if (isLocalContext) {
      setLocalAttachments((prevState) => [
        ...prevState.filter((a) => a.id !== id),
      ]);
    } else {
      setRemoteAttachments((prevState) => [
        ...prevState.filter((a) => a.blobRef.id !== id),
      ]);
    }
  };

  useEffect(() => {
    setRemoteAttachments(
      formField.defaultValues.map((v) => v.kind.documentObject!)
    );
    // eslint-disable-next-line
  }, []);

  // @ts-ignore
  // eslint-disable-next-line consistent-return
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors) {
      setDropErrorMessage(rejectedFiles[0].errors[0].message);
      return false;
    } else {
      setDropErrorMessage('');
    }
    if (acceptedFiles) {
      setIsOpen(true);
      const correlatedFiles: any[] = [];

      if (
        formField.cardinalityBounds?.right &&
        acceptedFiles.length + remoteAttachments.length >
          formField.cardinalityBounds.right
      ) {
        // eslint-disable-next-line no-alert
        setDropErrorMessage(t('fileTooLarge') as string); // TODO i18n
        return false;
      }

      for (const file of acceptedFiles) {
        const correlationId = uuidv4();
        correlatedFiles.push({
          name: file.name,
          extension: file.type,
          mimeType: file.type,
          id: correlationId,
        });

        const formData = new FormData();
        formData.append('file', file);
        formData.append('containerId', Container.INBOX); // TODO Which container should we use?
        axios
          .post(
            `${
              env('REACT_APP_REST_HTTP_URL') || 'http://localhost:8080'
            }/blob/upload`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${KeycloakService.getToken()}`,
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .then((r: any) => {
            const uploadedBlob = r.data;
            setRemoteAttachments((prevState) => [
              ...prevState,
              {
                blobRef: {
                  id: uploadedBlob.id,
                  name: uploadedBlob.name,
                  size: uploadedBlob.size,
                  mimeType: uploadedBlob.mimeType,
                  container: uploadedBlob.container,
                  downloadUrl: uploadedBlob.downloadUrl,
                  thumbnail: uploadedBlob.thumbnail,
                },
              },
            ]);

            setLocalAttachments((prevState) =>
              prevState.filter((f) => f.id !== correlationId)
            );
          })
          .catch((e) => {
            // if is a Network Error
            if (e.message) {
              setDropErrorMessage(e.message);
            } else {
              setDropErrorMessage('error uploading file'); // TODO i18n
            }
            // on error remove the file from local list
            setLocalAttachments((prevState) =>
              prevState.filter((f) => f.id !== correlationId)
            );
          });
      }
      setLocalAttachments((prevState) => [...prevState, ...correlatedFiles]);
    }
  };

  useEffect(() => {
    if (remoteAttachments.length > 0) {
      fillField(
        formField.id,
        remoteAttachments.map(
          (f) => `${f.blobRef.container}://${f.blobRef.id}`
        ),
        formField.isDynamic
      );
    }
    // eslint-disable-next-line
  }, [remoteAttachments]);

  const [dropErrorMessage, setDropErrorMessage] = useState<string>('');

  const calcError = useMemo(
    () =>
      firstOnClick && formField.isEditable
        ? dropErrorMessage ||
          boundaryError(
            localAttachments.length + remoteAttachments.length,
            formField.cardinalityBounds?.left,
            formField.cardinalityBounds?.right
          ) ||
          errorMessageDate
        : undefined,
    [
      firstOnClick,
      formField.isEditable,
      formField.cardinalityBounds?.left,
      formField.cardinalityBounds?.right,
      dropErrorMessage,
      localAttachments.length,
      remoteAttachments.length,
      errorMessageDate,
    ]
  );

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={calcError}
      columnForm={columnForm}
    >
      <AttachmentsContainer
        className="AttachmentsContainer"
        error={!!calcError}
        isEditable={formField.isEditable}
      >
        <>
          <Text
            text={`${formField.name}${
              formField?.cardinalityBounds?.left &&
              formField.cardinalityBounds.left > 0
                ? ' *'
                : ''
            }`}
            fontSize="14px"
            lineHeight="18px"
            skipTranslation
          />
          {formField.placeholder && (
            <DisplayInfo
              style={{
                marginTop: '2px',
                marginLeft: '8px',
                marginRight: '8px',
              }}
              styleDescription={{
                top: '-50px',
              }}
              description={formField.placeholder}
            />
          )}
          <TipsContainer alignItems="center">
            <SvgIcon
              svg={<Attachment />}
              height="10px"
              width="10px"
              rotateDeg={20}
              color={Theme.colors.c505050}
            />
            <Text
              text={'numberOfAttachments'}
              translationArgs={{
                numberOfAttachments:
                  formField.cardinalityBounds?.right != null
                    ? `${remoteAttachments.length}/${formField.cardinalityBounds?.right}`
                    : `${remoteAttachments.length}`,
              }}
              fontSize="10px"
              lineHeight="15px"
              color={Theme.colors.c838383}
            />
          </TipsContainer>
        </>
        <ContainerRight alignItems="center">
          {formField.isEditable &&
            (formField.cardinalityBounds?.right == null ||
              remoteAttachments.length < formField.cardinalityBounds.right) && (
              <StyledButton
                border={`1px solid ${Theme.colors.c2186C6}`}
                padding="3px 15px"
                borderRadius="4px"
                onClick={() => {
                  setErrorMessageDate(undefined);
                  setOpenUploadModal(true);
                  setFirstOnClick(true);
                }}
              >
                <Text
                  text={'upload'}
                  fontSize="12px"
                  lineHeight="18px"
                  color={Theme.colors.c2186C6}
                />
              </StyledButton>
            )}
          <ClickableArrow
            onClick={() => {
              setFirstOnClick(true);
              remoteAttachments.length > 0 &&
                setIsOpen((prevState) => !prevState);
            }}
            cursorPointer={remoteAttachments.length > 0}
          >
            <ArrowIcon
              svg={<Arrow />}
              width="10.8px"
              color={
                remoteAttachments.length > 0
                  ? Theme.colors.c000000
                  : Theme.colors.c6A6A6A
              }
              isRotated={isOpen}
            />
          </ClickableArrow>
        </ContainerRight>
      </AttachmentsContainer>
      {(remoteAttachments.length > 0 || localAttachments.length > 0) && (
        <AttachmentsValueContainer
          isOpen={isOpen}
          justifyContent="space-between"
          wrapContent
        >
          {remoteAttachments.length > 0 &&
            remoteAttachments.map((value) => (
              <AttachmentItemFormField
                key={value.blobRef.id}
                documentObject={value.blobRef}
                removeAttachments={removeAttachments}
                setFileLocaleView={setFileLocaleView}
                setFileIdViewActive={setFileIdViewActive}
                fileIdViewActive={fileIdViewActive}
                isEditable={formField.isEditable}
              />
            ))}
          {localAttachments.length > 0 &&
            localAttachments.map((value) => (
              <AttachmentItemFormField
                key={value.id}
                documentObject={value}
                isLocalContext
                removeAttachments={removeAttachments}
                setFileLocaleView={setFileLocaleView}
                setFileIdViewActive={setFileIdViewActive}
                fileIdViewActive={fileIdViewActive}
                isEditable={formField.isEditable}
              />
            ))}
        </AttachmentsValueContainer>
      )}
      {openUploadModal && (
        <UploadFileModal
          allowedFiles={formField.kind?.documentObject?.allowedExtensions || []}
          closeOnClick={() => setOpenUploadModal(false)}
          onDropCallback={(acceptFiles, rejectedFiles) => {
            onDrop(acceptFiles, rejectedFiles);
            setIsOpen(true);
            setOpenUploadModal(false);
          }}
          insertBlobRefsFromSintropi={(blobRefs) => {
            setIsOpen(true);
            setErrorMessageDate(undefined);
            setRemoteAttachments((prevState) => [
              ...prevState,
              ...blobRefs
                .map((blobRef) => ({
                  blobRef,
                }))
                .filter(
                  (documentObj) =>
                    !prevState.some(
                      (d) => d.blobRef.id === documentObj.blobRef.id
                    )
                ),
            ]);
          }}
        />
      )}
    </FormFieldContainer>
  );
};

const AttachmentsContainer = styled.div<{
  error?: boolean;
  isEditable: boolean;
}>`
  padding-left: 13px;
  height: 51px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors.cFE4242 : theme.colors.transparentGrey};

  background-color: ${({ theme, isEditable }) =>
    isEditable ? theme.colors.primaryGentle : theme.colors.transparentGrey};

  z-index: 1;
`;

const TipsContainer = styled(FlexContainer)`
  & > span {
    margin-left: 5px;
  }

  &:nth-child(2) {
    margin-left: 16px;
  }
`;

const ContainerRight = styled(FlexContainer)`
  margin-left: auto;
  padding-right: 16px;
`;

const StyledButton = styled(Button)`
  margin-right: 16px;
`;

const ClickableArrow = styled.div<{ cursorPointer: boolean }>`
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 25px;
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled(SvgIcon)<{ isRotated: boolean }>`
  transition: 200ms transform ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`;

const AttachmentsValueContainer = styled(FlexContainer)<{ isOpen: boolean }>`
  margin-top: -2px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  padding: 16px;
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.colors.cDFE1E6};

  & > div:not(:nth-child(-n + 2)) {
    margin-top: 8px;
  }
`;
