import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { ChoicesType } from './index';

interface ChoicesProps {
  choiceSelected: ChoicesType;
  setChoiceSelected: (c: ChoicesType) => void;
}

export const Choices: FunctionComponent<ChoicesProps> = ({
  setChoiceSelected,
  choiceSelected,
}) => (
  <ContainerChoices>
    <Choice
      selected={choiceSelected === ChoicesType.uploadFile}
      onClick={() => {
        setChoiceSelected(ChoicesType.uploadFile);
      }}
    >
      <Text
        text="uploadFile"
        fontSize="12px"
        lineHeight="22px"
        color={
          choiceSelected === ChoicesType.uploadFile
            ? Theme.colors.c4259C1
            : undefined
        }
      />
    </Choice>
    <Choice
      selected={choiceSelected === ChoicesType.fromSintropiDrive}
      onClick={() => {
        setChoiceSelected(ChoicesType.fromSintropiDrive);
      }}
    >
      <Text
        text="fromSintropiDrive"
        fontSize="12px"
        lineHeight="22px"
        color={
          choiceSelected === ChoicesType.fromSintropiDrive
            ? Theme.colors.c4259C1
            : undefined
        }
      />
    </Choice>
    {/* <Choice
      selected={choiceSelected === ChoicesType.recent}
      onClick={() => {
        setChoiceSelected(ChoicesType.recent);
      }}
    >
      <Text
        text="recent"
        fontSize="12px"
        lineHeight="22px"
        color={
          choiceSelected === ChoicesType.recent
            ? Theme.colors.c4259C1
            : undefined
        }
      />
    </Choice> */}
  </ContainerChoices>
);

const Choice = styled.div<{ selected: boolean }>`
  padding: 0 2px 12px 2px;
  ${({ selected, theme }) =>
    selected &&
    // eslint-disable-next-line
    `border-bottom: 2px solid ${theme.colors.c4259C1};`};
  margin-right: 20px;
`;

const ContainerChoices = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
