import axios from 'axios';
import { saveAs } from 'file-saver';
import { KeycloakService } from '../services/KeycloakService';

export const downloadFile = (downloadUrl: string) =>
  axios
    .get(downloadUrl, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
      responseType: 'blob',
    })
    .then((r) => ({ file: r.data as File, fileName: r.headers['file-name'] }));

export const downloadAndSave = async (downloadUrl: string) => {
  const { file, fileName } = await downloadFile(downloadUrl);
  saveAs(file, fileName);
};
