import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IdentifiableValue } from '@pec-manager/graphql/lib/graphql.types';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Breadcrumb } from '../../components/common/breadcrumb';
import { Tag } from '../../components/tag';

interface TopSectionProps {
  textCategory?: string;
  archiveWalkPath?: IdentifiableValue[];
  colorBackground?: string;
}

export const TopSection: FunctionComponent<TopSectionProps> = ({
  textCategory,
  archiveWalkPath,
  colorBackground,
}) => (
  <Container justifyContent="space-between" wrapContent>
    <ContainerLeft alignItems="center">
      <Tag text={textCategory} colorBackground={colorBackground} />
      {archiveWalkPath && <Breadcrumb position={archiveWalkPath} />}
    </ContainerLeft>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 8px;
`;

const ContainerLeft = styled(FlexContainer)`
  & > div:first-child {
    margin-right: 10px;
  }
`;
