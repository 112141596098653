import React, { FunctionComponent, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Text } from './Text';
import { FlexContainer } from '../layout/FlexContainer';
import { Theme } from '../../theme';
import {
  extractDateFromSeconds,
  transformSecondInDayHoursMinutes,
} from '../../utils/dateUtils';
import { useGauge } from 'use-gauge';
import cc from 'classcat';
import { calcStringForGaugeValue } from '../../utils/stringUtils';

interface GaugeGenericProps {
  defaultValue: number;
  averageMedium: number;
  minValue: number;
  maxValue: number;
  scale: number;
  percentageWidth?: number;
  title?: string;
}

const START_ANGLE = 70;
const END_ANGLE = 290;

export const GaugeGeneric: FunctionComponent<GaugeGenericProps> = ({
  defaultValue,
  maxValue,
  scale,
  averageMedium,
  minValue,
  percentageWidth = 50,
  title,
}) => {
  const {
    day: scaleDay,
    minutes: scaleMinutes,
    hours: scaleHours,
    secondsLeft: scaleSecondsLeft,
  } = extractDateFromSeconds(scale);

  const {
    day: averageMediumDay,
    minutes: averageMediumMinutes,
    hours: averageMediumHours,
  } = extractDateFromSeconds(averageMedium);

  const maxDomain = useMemo(() => {
    if (averageMediumDay > 0) {
      return (
        scaleDay * 24 * 60 * 60 +
        scaleHours * 60 * 60 +
        scaleMinutes * 60 +
        scaleSecondsLeft
      );
    }
    if (averageMediumHours > 0) {
      if (averageMediumDay > 0) {
        return (
          scaleDay * 24 +
          scaleHours * 60 * 60 +
          scaleMinutes * 60 +
          scaleSecondsLeft
        );
      }
      return scaleHours * 60 * 60;
    }
    if (averageMediumMinutes > 0) {
      if (scaleDay > 0) {
        return (
          scaleDay * 24 * 60 +
          scaleHours * 60 +
          scaleMinutes * 60 +
          scaleSecondsLeft
        );
      }
      if (scaleHours > 0) {
        return scaleHours * 60 * 60 + scaleMinutes * 60 + scaleSecondsLeft;
      }
      return scaleMinutes * 60 + scaleSecondsLeft;
    }
    return scale;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    averageMediumDay,
    averageMediumHours,
    averageMediumMinutes,
    scale,
    scaleDay,
    scaleHours,
    scaleMinutes,
  ]);

  const gauge = useGauge({
    domain: [0, maxDomain],
    startAngle: START_ANGLE,
    endAngle: END_ANGLE,
    numTicks: 51,
    diameter: 250,
  });

  const needle = gauge.getNeedleProps({
    value: defaultValue,
    baseRadius: 12,
    tipRadius: 1,
  });

  const textGauge = useCallback(
    (asValue: number, index: number) => {
      if (averageMediumDay > 0) {
        return extractDateFromSeconds(asValue).day;
      } else if (averageMediumHours > 0) {
        if (extractDateFromSeconds(asValue).day > 0) {
          return (
            extractDateFromSeconds(asValue).day * 24 +
            extractDateFromSeconds(asValue).hours
          );
        }
        return extractDateFromSeconds(asValue).hours;
      } else if (averageMediumMinutes > 0) {
        if (extractDateFromSeconds(asValue).hours > 0) {
          return (
            extractDateFromSeconds(asValue).hours * 60 +
            extractDateFromSeconds(asValue).minutes
          );
        }
        return `${extractDateFromSeconds(asValue).minutes},${Math.floor(
          (extractDateFromSeconds(asValue).secondsLeft / 60) * 10
        )}`;
      }
      if (extractDateFromSeconds(asValue).minutes > 0) {
        return (
          extractDateFromSeconds(asValue).minutes * 60 +
          extractDateFromSeconds(asValue).secondsLeft
        );
      }
      return extractDateFromSeconds(asValue).secondsLeft;
    },
    [averageMediumDay, averageMediumHours, averageMediumMinutes]
  );

  return (
    <Container percentageWidth={percentageWidth}>
      <TopContainer column alignItems="center">
        {title && (
          <Text
            text={title}
            fontSize="20px"
            lineHeight="30px"
            color={Theme.colors.c505050}
            fontWeight="300"
            style={{ marginBottom: '30px' }}
          />
        )}
        <div className="p-4">
          <svg className="w-full overflow-visible p-2" {...gauge.getSVGProps()}>
            <g id="arcs">
              <path
                {...gauge.getArcProps({
                  offset: -32,
                  startAngle: START_ANGLE,
                  endAngle: END_ANGLE,
                })}
                fill="none"
                className="stroke-gray-200"
                strokeWidth={36}
              />
              <path
                {...gauge.getArcProps({
                  offset: -32,
                  startAngle: START_ANGLE,
                  endAngle: gauge.valueToAngle(defaultValue),
                })}
                fill="none"
                className={cc([
                  {
                    'stroke-green-300':
                      gauge.valueToAngle(defaultValue) - START_ANGLE <=
                      (220 * 70) / 100,
                    'stroke-orange-300':
                      gauge.valueToAngle(defaultValue) - START_ANGLE >
                        (220 * 70) / 100 &&
                      gauge.valueToAngle(defaultValue) - START_ANGLE <
                        (220 * 80) / 100,
                    'stroke-red-400':
                      gauge.valueToAngle(defaultValue) - START_ANGLE >=
                      (220 * 80) / 100,
                  },
                ])}
                strokeWidth={36}
              />
            </g>
            <g id="ticks">
              {gauge.ticks.map((angle, index) => {
                const asValue = gauge.angleToValue(angle);

                const showText = index % 10 === 0;

                //const lengthTick = showText || index === gauge.ticks.length - 1 ? 14 : 10;

                return (
                  <React.Fragment key={`tick-group-${angle}`}>
                    <line
                      className={cc([
                        {
                          'stroke-green-300': asValue <= (maxDomain * 70) / 100,
                          'stroke-orange-300':
                            asValue > (maxDomain * 70) / 100 &&
                            asValue < (maxDomain * 80) / 100,
                          'stroke-red-400': asValue >= (maxDomain * 80) / 100,
                        },
                      ])}
                      strokeWidth={2}
                      {...gauge.getTickProps({
                        angle,
                        length: showText ? 12 : 6,
                      })}
                    />
                    {(showText || index === gauge.ticks.length - 1) && (
                      <text
                        className="text-sm fill-gray-400 font-medium"
                        {...gauge.getLabelProps({ angle, offset: -32 })}
                      >
                        {textGauge(asValue, index)}
                      </text>
                    )}
                  </React.Fragment>
                );
              })}
            </g>
            <g id="needle">
              <circle className="fill-white" {...needle.base} r={20} />

              <line
                className="stroke-gray-600"
                strokeLinecap="round"
                strokeWidth={4}
                x1={needle.base.cx}
                x2={needle.tip.cx}
                y1={needle.base.cy}
                y2={needle.tip.cy}
              />
            </g>
          </svg>
        </div>
        <Text
          text={calcStringForGaugeValue(
            averageMediumDay,
            averageMediumHours,
            averageMediumMinutes
          )}
          fontSize="14px"
          lineHeight="20px"
          fontWeight="300"
          color={Theme.colors.c838383}
          style={{ marginTop: '-40px', marginBottom: '30px' }}
        />
        <Text
          text={transformSecondInDayHoursMinutes(defaultValue)}
          fontSize="28px"
          lineHeight="34px"
          fontWeight="600"
        />
      </TopContainer>
      <BottomContainer alignItems="center" justifyContent="space-around">
        <LeftContainer alignItems="center" column>
          <Text
            text="Min"
            fontSize="16px"
            lineHeight="24px"
            color={Theme.colors.c838383}
          />
          <Text
            text={transformSecondInDayHoursMinutes(minValue)}
            fontSize="24px"
            lineHeight="34px"
            fontWeight="600"
          />
        </LeftContainer>
        <RightContainer alignItems="center" column>
          <Text
            text="Max"
            fontSize="16px"
            lineHeight="24px"
            color={Theme.colors.c838383}
          />
          <Text
            text={transformSecondInDayHoursMinutes(maxValue)}
            fontSize="24px"
            lineHeight="34px"
            fontWeight="600"
          />
        </RightContainer>
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div<{ percentageWidth: number }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  width: ${({ percentageWidth }) => percentageWidth}%;

  @media (max-width: 990px) {
    width: calc(100% - 32px);
  }
`;

const TopContainer = styled(FlexContainer)`
  margin-bottom: 24px;
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
`;

const LeftContainer = styled(FlexContainer)``;

const RightContainer = styled(FlexContainer)``;
