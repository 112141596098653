import { ApolloProvider } from '@apollo/client';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { apolloClient } from './graphql';
import './i18n';
import './index.scss';
import { store } from './redux';
import { KeycloakService } from './services/KeycloakService';
import * as serviceWorker from './serviceWorker';
import { Theme } from './theme';

// Import Sintropi Dynamic Form definitions
import '@sintropi/dynamic-form/main.js';
import '@sintropi/dynamic-form/main.js.map';

// @ts-ignore
export const env = (name: string) => window.ENV && window.ENV[name];

if (env('REACT_APP_SENTRY_DSN')) {
  Sentry.init({
    dsn: env('REACT_APP_SENTRY_DSN'),
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

KeycloakService.initKeycloak(() => {
  i18n.changeLanguage(KeycloakService.getLocale()).then(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    root.render(
      <ApolloProvider client={apolloClient()}>
        <ThemeProvider theme={Theme}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </ApolloProvider>
    );
  });
  serviceWorker.unregister();
});
