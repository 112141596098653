import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { KeycloakService } from '../../services/KeycloakService';
import { RoundedTextAvatar } from '../../components/avatar/RoundedTextAvatar';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ProgressFlows } from '../../inbox/mail/ProgressFlows';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { TitleWithChooseApp } from '../../components/common/TitleWithChooseApp';
import { useBusinessKpi } from '../../components/hooks/useBusinessKpi';
import { useParams } from 'react-router-dom';
import { Select } from '../../components/select';
import { KpiContainerProps } from './KpiContainer';
import { useTranslation } from 'react-i18next';
import { KpiSelectFilterOption } from '@pec-manager/graphql';
import { InputDateRange } from './InputDateRange';

export const KpiContainerHeader: FunctionComponent<KpiContainerProps> = ({
  setKpiRow,
}) => {
  const { appId } = useParams();

  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState('');

  const [filterValue, setFilterValue] = useState<
    { name: string; value: string }[]
  >([]);

  const { kpiTabs, kpiFilters, kpiRow } = useBusinessKpi(
    appId || '',
    tabSelected,
    filterValue
  );

  useEffect(() => {
    if (kpiTabs && kpiTabs.length > 0 && !tabSelected) {
      setTabSelected(kpiTabs[0].tabId);
    }
    // eslint-disable-next-line
  }, [appId, kpiTabs]);

  useEffect(() => {
    setKpiRow(kpiRow);
  }, [kpiRow, setKpiRow]);

  useEffect(() => {
    if (kpiFilters && kpiFilters.length > 0) {
      setFilterValue(
        kpiFilters.map(({ required, options, selectId }) => ({
          name: selectId,
          value:
            required && options && options.length > 0 ? options[0].value : '',
        }))
      );
    }
    // eslint-disable-next-line
  }, [kpiFilters]);

  const choices = useMemo(
    () => (option: KpiSelectFilterOption[], required: boolean) => {
      if (required) {
        return option.map(({ label, value }) => ({
          label,
          value,
        }));
      }
      return option
        .map(({ label, value }) => ({
          label,
          value,
        }))
        .concat({
          label: '',
          value: t('remove'),
        });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Container column>
      <TopContainer alignItems="center" justifyContent="space-between">
        <TitleWithChooseApp />
        <div
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            process.env.REACT_APP_LINK_PROFILE
              ? window.open(process.env.REACT_APP_LINK_PROFILE)
              : {}
          }
          style={{
            cursor: process.env.REACT_APP_LINK_PROFILE ? 'pointer' : 'default',
          }}
        >
          <RoundedTextAvatar
            text={KeycloakService.getFullName()}
            fontSize={11}
            size={24}
          />
        </div>
      </TopContainer>
      <BottomContainer
        alignItems="center"
        justifyContent="space-between"
        column
      >
        <TitleContainer justifyContent="space-between">
          <LeftContainer alignItems="center">
            <Text
              text="businessKpi"
              fontSize="20px"
              lineHeight="27px"
              fontWeight="500"
              style={{ margin: '0 16px' }}
            />
            {kpiFilters &&
              kpiFilters.map(({ label, options, required, selectId }) => {
                if (selectId !== 'date_range') {
                  return (
                    <FilterContainer alignItems="center" key={label}>
                      <TextSelect
                        text={label}
                        fontSize="12px"
                        lineHeight="16px"
                      />
                      {options && options.length > 0 && (
                        <Select
                          width="150px"
                          borderContainer={`2px solid ${Theme.colors.cDFE1E6}`}
                          borderRadius="6px"
                          defaultChoice={filterValue.find(
                            (filter) => filter.name === selectId
                          )}
                          choices={choices(options, required)}
                          onChoiceSelected={(choice) => {
                            setFilterValue((prevState) => {
                              if (choice.value === t('remove')) {
                                return prevState.filter(
                                  (filter) => filter.name !== selectId
                                );
                              }
                              const choiceSelected = {
                                value: choice.value,
                                name: selectId,
                              };
                              return prevState
                                .filter((filter) => filter.name !== selectId)
                                .concat(choiceSelected);
                            });
                          }}
                          zIndex={2}
                          lineHeightText="16px"
                          fontSizeText="14px"
                        />
                      )}
                    </FilterContainer>
                  );
                } else {
                  return (
                    <FilterContainer alignItems="center" key={label}>
                      <TextSelect
                        text={label}
                        fontSize="12px"
                        lineHeight="16px"
                      />
                      <InputDateRange
                        onChangeValue={(value) => {
                          setFilterValue((prevState) => {
                            const choiceSelected = {
                              value,
                              name: selectId,
                            };

                            return prevState
                              .filter((filter) => filter.name !== selectId)
                              .concat(choiceSelected);
                          });
                        }}
                        deleteDateRange={() =>
                          setFilterValue((prevState) =>
                            prevState.filter(
                              (filter) => filter.name !== selectId
                            )
                          )
                        }
                      />
                    </FilterContainer>
                  );
                }
              })}
          </LeftContainer>
          <RightContainer justifyContent="flex-end">
            <ProgressFlows />
          </RightContainer>
        </TitleContainer>
        <TabContainer>
          <LeftTabContainer>
            {kpiTabs &&
              kpiTabs.map(({ tabId, label }) => (
                <TabItem
                  selected={tabId === tabSelected}
                  onClick={() => setTabSelected(tabId)}
                  key={tabId}
                >
                  <Text
                    text={label}
                    fontSize="12px"
                    lineHeight="22px"
                    color={
                      tabSelected === tabId ? Theme.colors.c5B84EF : undefined
                    }
                  />
                </TabItem>
              ))}
          </LeftTabContainer>
        </TabContainer>
      </BottomContainer>
    </Container>
  );
};

const TabItem = styled(FlexContainer)<{ selected: boolean }>`
  cursor: pointer;
  padding: 0 16px;
  height: 56px;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  ${({ selected }) =>
    selected &&
    `
    border-bottom: 4px solid ${Theme.colors.c5B84EF};
    color: ${Theme.colors.c5B84EF} !important;
  `}
`;

const Container = styled(FlexContainer)`
  width: 100%;
  height: 156px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  padding: 0 16px;
`;

const LeftContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 56px;
`;

const RightContainer = styled(FlexContainer)`
  width: fit-content;
  padding-right: 20px;
  margin-right: -6px;
`;

const TopContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  height: 42px;
`;

const TitleContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const TabContainer = styled(FlexContainer)`
  width: 100%;
  height: 56px;
`;

const LeftTabContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 56px;
`;

const FilterContainer = styled(FlexContainer)`
  padding: 0 16px;

  @media (max-width: 1440px) {
    flex-direction: column;
    padding: 0;
  }
`;

const TextSelect = styled(Text)`
  margin-right: 16px;
  @media (max-width: 1440px) {
    padding: 8px 0;
  }
`;

/* const SvgClickable = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  margin-right: 4px;
  cursor: pointer;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`; */
