import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  OutputSection,
  OutputSectionFieldType,
  OutputSectionFieldValue,
} from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { getDateAsString } from '../../utils/dateUtils';
import { extractFullName } from '../../utils/stringUtils';

interface CustomSectionProps {
  output: OutputSection;
}
// It will go into a hook
const mapFields = (
  type: OutputSectionFieldType,
  values: OutputSectionFieldValue[]
) => {
  switch (type) {
    case OutputSectionFieldType.DATE: {
      const epochMillis = parseInt(values[0]?.value || '', 10);

      if (isNaN(epochMillis)) {
        return {
          values: '',
        };
      }

      return {
        values: 'onlyDate',
        translationArgs: {
          date: getDateAsString(epochMillis),
        },
      };
    }
    case OutputSectionFieldType.USER:
      return {
        values: values.map(({ user }) => extractFullName(user)).join(', '),
      };
    case OutputSectionFieldType.MAIL:
    case OutputSectionFieldType.DEFAULT:
    default:
      return {
        values: values.map(({ value }) => value).join(', '),
        translationArgs: {},
      };
  }
};

export const CustomSection: FunctionComponent<CustomSectionProps> = ({
  output,
}) => (
  <Container column>
    <SectionTitle
      text={output.name}
      fontSize="14px"
      lineHeight="17px"
      fontWeight="500"
    />
    {output.fields.map(({ type, values, name }) => {
      const { values: mappedValues, translationArgs } = mapFields(type, values);

      return (
        <Item justifyContent="space-between" key={name}>
          <ItemTitle
            text={name}
            fontSize="12px"
            lineHeight="12px"
            fontWeight="600"
            skipTranslation
          />
          <Text
            text={mappedValues}
            fontSize="14px"
            translationArgs={translationArgs}
            fontWeight="300"
            lineHeight="28px"
            possibleLink
            skipTranslation={
              type !== OutputSectionFieldType.DATE &&
              type !== OutputSectionFieldType.USER
            }
            style={{ paddingLeft: '16px', textAlign: 'right' }}
          />
        </Item>
      );
    })}
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
`;

const SectionTitle = styled(Text)`
  width: 100%;
  padding-bottom: 16px;
`;

const Item = styled(FlexContainer)`
  width: 100%;
  padding: 8px 0;
`;

const ItemTitle = styled(Text)`
  margin: 8px;
`;
