import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  ExecutionContext,
  FilledForm,
  IdentifiableValue,
  PagedFormDefinition,
} from '@pec-manager/graphql';
import { FlexContainer } from '../layout/FlexContainer';
import { Header } from './Header';
import { FormDefinition } from '../modals/FormModal/FormDefinition';
import { ActionsButtons } from '../modals/FormModal/ActionsButtons';
import { FormDefinitionModalView } from '../modals/FormModal';
import { usePagedFormDefinition } from '../hooks/usePagedFormDefinition';
import { extractAllFieldId } from '../modals/FormModal/utils/extractAllFieldId';
import { Text } from '../common/Text';
import { Theme } from '../../theme';

interface PagedFormDefinitionComponentProps {
  id: string;
  executionContext: ExecutionContext;
  title?: string;
  resourceId?: string;
  closeOnClick?: () => void;
  closeAllModals?: () => void;
  viewInModal: boolean;
  appIdOutContext?: string;
}

export const PagedFormDefinitionComponent: React.FunctionComponent<
  PagedFormDefinitionComponentProps
> = ({
  id,
  executionContext,
  title,
  resourceId,
  closeOnClick,
  closeAllModals,
  viewInModal,
}) => {
  const {
    navigationButtons,
    setFilledFormDelegate,
    setCurrentPagedFormDefinition,
    filledForm,
    currentPagedFormDefinition,
    formDefinitionDiffsOnDynamicFieldChange,
  } = usePagedFormDefinition(executionContext, id, undefined);

  const [fieldCoordinatesWithError, setFieldCoordinatesWithError] = useState<
    string[]
  >([]);

  const [onFilledFormInitialization, setOnFilledFormInitialization] =
    useState(false);

  useEffect(() => {
    if (currentPagedFormDefinition) {
      setFieldCoordinatesWithError((prevState) =>
        prevState.filter((field) =>
          extractAllFieldId(
            currentPagedFormDefinition?.formDefinition
          ).includes(field)
        )
      );
    }
  }, [currentPagedFormDefinition]);

  const [childrenPagedFormDefinition, setChildrenPagedFormDefinition] =
    useState<PagedFormDefinition | null>(null);

  const [checkRequired, setCheckRequired] = useState<boolean>(false);

  const [childrenActionButtons, setChildrenActionButtons] = useState<
    IdentifiableValue | undefined
  >();

  const [filledFormWithDefaultValues, setFilledFormWithDefaultValues] =
    useState<FilledForm | undefined>(undefined);

  const isEditableForm = () => {
    const fieldsIsEditable =
      currentPagedFormDefinition?.formDefinition.fieldGroups.map((g) =>
        g.fields.map((f) => f.isEditable)
      );
    const form = fieldsIsEditable?.map((f) => f.some((c) => c));

    return form?.some((f) => f);
  };

  const formDefinitionHasErrors = useMemo(() => {
    if (!isEditableForm()) {
      return false;
    }
    if (currentPagedFormDefinition && onFilledFormInitialization) {
      return (
        fieldCoordinatesWithError.length > 0 ||
        currentPagedFormDefinition.formDefinition.errors.length > 0 ||
        currentPagedFormDefinition.formDefinition.fieldGroups.some(
          (g) => g.errors.length > 0
        )
      );
    }
    return true;
    // eslint-disable-next-line
  }, [fieldCoordinatesWithError, currentPagedFormDefinition]);

  useEffect(() => {
    if (onFilledFormInitialization) {
      if (!filledFormWithDefaultValues) {
        setFilledFormWithDefaultValues(filledForm);
      }
    }
    // eslint-disable-next-line
  }, [filledForm]);

  return id && currentPagedFormDefinition ? (
    <Container column>
      <Header
        title={title || currentPagedFormDefinition.formDefinition.name}
        pageRefs={currentPagedFormDefinition.pageRefs}
        currentPageId={currentPagedFormDefinition.pageId}
        resourceId={resourceId}
        closeOnClick={closeOnClick}
      />
      <div
        style={{
          height: `calc(100% - ${closeOnClick ? '138' : '180'}px)`,
          overflow: 'auto',
        }}
      >
        <FormDefinition
          isModalView={viewInModal}
          columnsForm={1}
          executionContext={executionContext}
          formDefinition={currentPagedFormDefinition.formDefinition}
          fillFormDelegateCallback={(f) => setFilledFormDelegate(f)}
          formDefinitionDiffsOnDynamicFieldChange={
            formDefinitionDiffsOnDynamicFieldChange
          }
          onFilledFormInitialization={() => setOnFilledFormInitialization(true)}
          notifyFieldCoordinateForError={(fieldCoordinate, hasError) => {
            setTimeout(() => {
              if (hasError) {
                if (
                  !fieldCoordinatesWithError.some((f) => f === fieldCoordinate)
                ) {
                  setFieldCoordinatesWithError((prevState) => [
                    ...prevState,
                    fieldCoordinate,
                  ]);
                }
              } else {
                setFieldCoordinatesWithError((prevState) =>
                  prevState.filter((f) => f !== fieldCoordinate)
                );
              }
            }, 0);
          }}
        />
      </div>
      <ButtonRow alignItems="center" justifyContent="flex-end" wrapContent>
        {checkRequired && formDefinitionHasErrors && (
          <Text
            text="genericErrorForm"
            fontSize="14px"
            lineHeight="16px"
            color={Theme.colors.cFE4242}
          />
        )}
        {navigationButtons && navigationButtons(formDefinitionHasErrors)}
        {currentPagedFormDefinition.formDefinition.actions.map(
          /* eslint-disable react/no-array-index-key */ (action, index) => (
            <ActionsButtons
              key={index}
              currentPagedFormDefinition={currentPagedFormDefinition}
              setCurrentPagedFormDefinition={setCurrentPagedFormDefinition}
              formDefinitionHasErrors={formDefinitionHasErrors}
              setChildrenPagedFormDefinition={setChildrenPagedFormDefinition}
              closeAllModals={closeAllModals}
              setChildrenActionButtons={setChildrenActionButtons}
              childrenActionButtons={childrenActionButtons}
              closeOnClick={closeOnClick}
              formAction={action}
              filledForm={filledForm}
              executionContext={executionContext}
              showRequiredFields={() => setCheckRequired(true)}
            />
          )
        )}
      </ButtonRow>
      {childrenPagedFormDefinition && (
        <FormDefinitionModalView
          childrenActionButtons={childrenActionButtons}
          closeAllModals={closeAllModals}
          closeOnClick={() => setChildrenPagedFormDefinition(null)}
          initialPagedFormDefinition={childrenPagedFormDefinition}
          executionContext={executionContext}
        />
      )}
    </Container>
  ) : (
    <></> // TODO Loader
  );
};

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  margin-left: -6px;
  border-radius: 6px 0 0 0;
  overflow: hidden;
  width: 100%;
  max-height: 100vh;

  @media (max-width: 1040px) {
    border-radius: 12px;
    max-height: 100%;
    margin-left: 0;
  }

  @media (max-width: 990px) {
    max-height: calc((var(--vh, 1vh) * 100 - 150px));
  }
`;

const ButtonRow = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  border-top: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  overflow: auto;

  & > button {
    margin-right: 10px;
  }

  & > button:last-child {
    margin-right: 32px;
  }
`;
