import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Theme } from '../../../theme';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { Tag } from '../../../components/tag';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Task } from '../../../svg';

interface CategoryProps {
  title: string;
  subtitle?: string;
  processName: string;
}

export const SubTaskTitle: FunctionComponent<CategoryProps> = ({
  subtitle,
  title,
  processName,
}) => (
  <Container column justifyContent="space-between">
    <FlexContainer>
      <SvgIcon
        svg={<Task />}
        height="16px"
        width="16px"
        color={Theme.colors.c52C41A}
      />
      <Text
        text={title}
        fontSize="14px"
        lineHeight="16px"
        ellipsis
        style={{
          margin: '0 10px 8px 8px',
        }}
      />
    </FlexContainer>
    {subtitle && (
      <Text
        text={subtitle}
        fontSize="12px"
        lineHeight="18px"
        color={Theme.colors.c505050}
        ellipsis
        style={{
          marginBottom: '8px',
        }}
      />
    )}
    <Tag text={processName} colorBackground={processName} />
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  padding-left: 20px;
  display: flex;
`;
