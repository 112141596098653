import React, { FunctionComponent, useState } from 'react';
import { TaskList } from './Tasks';
import { TaskDetailsHandler } from '../TaskDetails';
import { TaskContainer } from './TaskContainer';
import { useLocation } from 'react-router-dom';

interface TasksProps {
  notMobile: boolean;
}

export const Tasks: FunctionComponent<TasksProps> = ({ notMobile }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [fullSearchText, setFullSearchText] = useState<string | undefined>(
    undefined
  );

  return (
    <TaskContainer
      fullSearchText={fullSearchText}
      setFullSearchText={setFullSearchText}
    >
      <TaskList notMobile={notMobile} fullSearchText={fullSearchText} />
      {searchParams.get('taskId') && <TaskDetailsHandler />}
    </TaskContainer>
  );
};
