import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Theme } from '../../theme';
import { Tag } from '../../components/tag';
import { Text } from '../../components/common/Text';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Close, Task } from '../../svg';
import { isToday } from '../../utils/stringUtils';

interface TopSectionProps {
  colorBackground?: string;
  textTag?: string;
  receivedAt: number;
  clickOnClose?: () => void;
  title: string;
}

export const TopSection: FunctionComponent<TopSectionProps> = ({
  colorBackground,
  textTag,
  receivedAt: arrivedAt,
  clickOnClose,
  title,
}) => (
  <Container justifyContent="space-between" wrapContent>
    <FlexContainer column style={{ width: 'calc(100% - 40px)' }}>
      <Tag text={textTag} colorBackground={colorBackground} />
      <FlexContainer alignItems="center" style={{ marginTop: '8px' }}>
        <SvgIcon
          svg={<Task />}
          height="24px"
          width="24px"
          color={Theme.colors.c52C41A}
        />
        <TextTitle
          text={title}
          fontSize="24px"
          lineHeight="29px"
          fontWeight="500"
          skipTranslation
          style={{
            maxWidth: 'calc(100% - 165px)',
            marginLeft: '10px',
          }}
        />
      </FlexContainer>
    </FlexContainer>
    {!clickOnClose ? (
      <Text
        text={isToday(arrivedAt) ? 'todayAt' : 'completeDate'}
        translationArgs={{
          date: getDateAsString(arrivedAt),
          time: getTimeAsString(arrivedAt),
        }}
        fontSize="12px"
        lineHeight="18px"
        fontWeight="300"
        color={Theme.colors.c505050}
      />
    ) : (
      <ClickableIcon onClick={clickOnClose}>
        <SvgIcon
          svg={<Close />}
          width="10px"
          height="10px"
          color={Theme.colors.cFFFFFF}
        />
      </ClickableIcon>
    )}
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 16px;
`;

const ClickableIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.c838383};
`;

const TextTitle = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 990px) {
    overflow: auto;
    white-space: normal;
    text-overflow: unset;
    word-break: break-word;
    max-width: 100% !important;
  }
`;
