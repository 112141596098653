import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  OutputSection,
  OutputSectionFieldType,
  OutputSectionFieldValue,
} from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { getDateAsString } from '../../utils/dateUtils';
import { extractFullName } from '../../utils/stringUtils';
import { Theme } from '../../theme';

interface CustomSectionProps {
  output: OutputSection;
}
// It will go into a hook
const mapFields = (
  type: OutputSectionFieldType,
  values: OutputSectionFieldValue[]
) => {
  switch (type) {
    case OutputSectionFieldType.DATE: {
      const epochMillis = parseInt(values[0]?.value || '', 10);

      if (isNaN(epochMillis)) {
        return {
          values: '',
        };
      }

      return {
        values: 'onlyDate',
        translationArgs: {
          date: getDateAsString(epochMillis),
        },
      };
    }
    case OutputSectionFieldType.USER:
      return {
        values: values.map(({ user }) => extractFullName(user)).join(', '),
      };
    case OutputSectionFieldType.MAIL:
    case OutputSectionFieldType.DEFAULT:
    default:
      return {
        values: values.map(({ value }) => value).join(', '),
        translationArgs: {},
      };
  }
};

export const CustomSectionDocument: FunctionComponent<CustomSectionProps> = ({
  output,
}) => (
  <Container column>
    <SectionTitle
      text={output.name}
      fontSize="14px"
      lineHeight="17px"
      fontWeight="600"
    />
    <FlexContainer wrapContent style={{ height: 'fit-content' }}>
      {output.fields.map(({ type, values, name }) => {
        const { values: mappedValues, translationArgs } = mapFields(
          type,
          values
        );

        return (
          <Item key={name} column>
            <ItemTitle
              text={name}
              fontSize="12px"
              fontWeight="300"
              lineHeight="12px"
              skipTranslation
              color={Theme.colors.c505050}
            />
            <Text
              text={mappedValues}
              fontSize="12px"
              translationArgs={translationArgs}
              color={Theme.colors.c000000}
              lineHeight="12px"
              possibleLink
              skipTranslation={
                type !== OutputSectionFieldType.DATE &&
                type !== OutputSectionFieldType.USER
              }
              breakWord
              style={{ paddingLeft: '8px' }}
            />
          </Item>
        );
      })}
    </FlexContainer>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px 8px;
  overflow: auto;
  margin: 16px 0;
`;

const SectionTitle = styled(Text)`
  padding-bottom: 16px;
`;

const Item = styled(FlexContainer)`
  width: 300px;
  padding-bottom: 24px;
  margin-right: 24px;
`;

const ItemTitle = styled(Text)`
  margin: 8px;
`;
