// noinspection JSIgnoredPromiseFromCall

import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ChatRoomEvent, ChatRoomEventType } from '@pec-manager/graphql/';
import { useMutation } from '@apollo/client';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { chatEventMapper } from './chatEventMapper';
import { showAvatar, updateMessageStatuses } from '../utilis';
import { READ_MESSAGE_CHAT } from '../../../graphql/chat/mutations';

export interface MessageStatuses {
  [key: string]: { isRead: boolean; isReceived: boolean };
}

interface MessageContentProps {
  initialEvents: ChatRoomEvent[];
  chatRoomId: string;
  newChatEvent?: ChatRoomEvent;
}

export const ChatContent: FunctionComponent<MessageContentProps> = ({
  initialEvents,
  chatRoomId,
  newChatEvent,
}) => {
  const [chatEvents, setChatEvents] = useState<ChatRoomEvent[]>(
    initialEvents.slice().reverse()
  );

  const [messageStatuses, setMessageStatuses] = useState<MessageStatuses>(
    updateMessageStatuses(chatEvents)
  );

  const [readMessageById] = useMutation(READ_MESSAGE_CHAT);

  useEffect(() => {
    if (newChatEvent?.eventType === ChatRoomEventType.MESSAGE_POSTED) {
      readMessageById({
        variables: {
          chatRoomId,
          messageOffset: newChatEvent?.messagePostedEvent?.messageNumber,
        },
      });
    }
  }, [chatRoomId, newChatEvent, readMessageById]);

  useEffect(() => {
    if (
      newChatEvent?.eventType === ChatRoomEventType.PARTICIPANT_MESSAGE_READ
    ) {
      setMessageStatuses((prevState) => ({
        ...prevState,
        [newChatEvent.participantMessageReadEvent!.messageNumber!]: {
          isRead: true,
          isReceived: true,
        },
      }));
    } else if (
      newChatEvent?.eventType === ChatRoomEventType.PARTICIPANT_MESSAGE_RECEIVED
    ) {
      setMessageStatuses((prevState) => {
        if (
          prevState[
            newChatEvent.participantMessageReceivedEvent!.messageNumber!
          ]?.isRead
        ) {
          return prevState;
        }
        return {
          ...prevState,
          [newChatEvent.participantMessageReceivedEvent!.messageNumber!]: {
            isRead: false,
            isReceived: true,
          },
        };
      });
    }
  }, [newChatEvent]);

  useEffect(() => {
    if (newChatEvent) {
      if (!chatEvents.some((c) => c.id === newChatEvent.id)) {
        setChatEvents((prevState) => [newChatEvent, ...prevState]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChatEvent]);

  return (
    <ChatContainer column>
      {chatEvents
        .filter(
          (c) =>
            !(
              c.eventType === ChatRoomEventType.PARTICIPANT_MESSAGE_RECEIVED ||
              c.eventType === ChatRoomEventType.PARTICIPANT_MESSAGE_READ ||
              c.eventType === ChatRoomEventType.RESOURCE_LINKED
            )
        )
        .map((e, index) =>
          chatEventMapper(
            e,
            messageStatuses,
            e.eventType === ChatRoomEventType.MESSAGE_POSTED
              ? showAvatar(
                  chatEvents.filter(
                    (c) =>
                      !(
                        c.eventType ===
                          ChatRoomEventType.PARTICIPANT_MESSAGE_RECEIVED ||
                        c.eventType ===
                          ChatRoomEventType.PARTICIPANT_MESSAGE_READ ||
                        c.eventType === ChatRoomEventType.RESOURCE_LINKED
                      )
                  ),
                  index,
                  e.actor
                )
              : undefined
          )
        )}
    </ChatContainer>
  );
};

const ChatContainer = styled(FlexContainer)<{ takeInCharge?: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.cFAFAFA};
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  flex-direction: column-reverse;
  flex: 1;

  @media (max-width: 1040px) {
    overflow: auto;
  }
`;
