import { gql } from '@apollo/client/core';
import {
  gqlFullMailResolutionShape,
  mailSummaryPagingWrapperShape,
} from './shapes';
import { gqlIdentifiableValueShape } from '../common/shapes';

export const GET_MAIL_BY_ID = gql`
    query GetMailById($mailId: ID!) {
        getMailById(mailId: $mailId) {
            ${gqlFullMailResolutionShape}
            correlatedMails {
                ${gqlFullMailResolutionShape}
            }
        }
    }
`;

export const GET_MAILS_IN_INBOX = gql`
    query GetMailsInInbox($appId: ID!, $pagination: Pagination) {
        getMailsInInbox(appId: $appId, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_MAILS_BY_ASSIGNMENT_TYPE = gql`
    query GetMailsByAssignmentType($appId: ID!, $assignmentType: ResourceAssignmentType!, $pagination: Pagination) {
        getMailsByAssignmentType(appId: $appId, assignmentType: $assignmentType, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_MAILS_BY_STATE = gql`
    query GetMailsByState($appId: ID!, $mailState: ResourceState!, $pagination: Pagination) {
        getMailsByState(appId: $appId, mailState: $mailState, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_MAILS_IN_ALL_ARCHIVES = gql`
    query GetMailsInAllArchives($appId: ID!, $pagination: Pagination) {
        getMailsInAllArchives(appId: $appId, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_MAILS_IN_ARCHIVE = gql`
    query GetMailsInArchive($appId: ID!, $archiveId: ID!, $pagination: Pagination) {
        getMailsInArchive(appId: $appId, archiveId: $archiveId, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_MAILS_BY_SAVED_SEARCH_ID = gql`
    query GetMailsBySavedSearchId($appId: ID!, $savedSearchId: ID!, $pagination: Pagination) {
        getMailsBySavedSearchId(appId: $appId, savedSearchId: $savedSearchId, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_MAILS_BY_SIMPLE_SEARCH = gql`
    query GetMailsBySimpleSearch($appId: ID!, $searchFilter: String!, $pagination: Pagination) {
        getMailsBySimpleSearch (appId: $appId, searchFilter: $searchFilter, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_MAILS_BY_FILLED_ADVANCED_SEARCH_FORM = gql`
    query GetMailsByFilledAdvancedSearchForm($appId: ID!, $filledForm: FilledForm!, $pagination: Pagination) {
        getMailsByFilledAdvancedSearchForm (appId: $appId, filledForm: $filledForm, pagination: $pagination) {
            ${mailSummaryPagingWrapperShape}
        }
    }
`;

export const GET_ARCHIVES = gql`
    query GetArchives($appId: ID!) {
        getArchives(appId: $appId) {
            ${gqlIdentifiableValueShape}
        }
    }
`;
