import { createAction } from '@reduxjs/toolkit';
import {
  FilledForm,
  MailSummary,
  ResourcePriority,
  SavedSearch,
} from '@pec-manager/graphql';

enum Actions {
  SET_SELECTED_MAIL_ID = 'SET_SELECTED_MAIL_ID',
  CLEAR_MAIL_LIST = 'CLEAR_MAIL_LIST',
  APPEND_MAILS = 'APPEND_MAILS',
  UPDATE_MAIL = 'UPDATE_MAIL',
  SET_SIMPLE_SEARCH_FILTER = 'SET_SIMPLE_SEARCH_FILTER',
  SET_ADVANCED_SEARCH_FILLED_FORM = 'SET_ADVANCED_SEARCH_FILLED_FORM',
  SET_SAVED_SEARCH_LIST = 'SET_SAVED_SEARCH_LIST',
  NEW_PRIORITY = 'NEW_PRIORITY',
  DELETE_MAIL = 'DELETE_MAIL',
}

interface AppendMailsActionType {
  mailCount: number;
  mails: MailSummary[];
}

export const setSelectedMailId = createAction<string>(
  Actions.SET_SELECTED_MAIL_ID
);

export const clearMailList = createAction(Actions.CLEAR_MAIL_LIST);

export const appendMails = createAction<AppendMailsActionType>(
  Actions.APPEND_MAILS
);

export const deleteMail = createAction<string>(Actions.DELETE_MAIL);

export const updateMail = createAction<MailSummary>(Actions.UPDATE_MAIL);

export const setSimpleSearchFilter = createAction<string>(
  Actions.SET_SIMPLE_SEARCH_FILTER
);

export const setAdvancedSearchFilledForm = createAction<{
  pageId: string;
  filledForm: FilledForm;
}>(Actions.SET_ADVANCED_SEARCH_FILLED_FORM);

export const setSavedSearchList = createAction<SavedSearch[]>(
  Actions.SET_SAVED_SEARCH_LIST
);

export const setNewPriorityInMail = createAction<{
  id: string;
  priority: ResourcePriority;
}>(Actions.NEW_PRIORITY);
