import React, { FunctionComponent, useState } from 'react';
import { IdentifiableValue } from '@pec-manager/graphql';
import { ContainerModal } from '../ContainerModal';
import { Text } from '../../common/Text';
import { SvgIcon } from '../../common/SvgIcon';
import { KO } from '../../../svg';
import { Theme } from '../../../theme';
import styled from 'styled-components';
import { MoveFromSintropiDrive } from './MoveFromSintropiDrive';
import { ButtonSection } from './MoveFromSintropiDrive/ButtonSection';

interface UploadFileModalProps {
  closeOnClick: () => void;
  submitOnClick: (id: string, path: IdentifiableValue[]) => void;
  folderId?: string;
}

export const MoveDocumentModal: FunctionComponent<UploadFileModalProps> = ({
  closeOnClick,
  submitOnClick,
  folderId,
}) => {
  const [currentFolderSelected, setCurrentFolderSelected] =
    useState<IdentifiableValue>({ id: '', value: 'Drive' });

  const [pathFolder, setPathFolder] = useState<IdentifiableValue[]>([]);

  return (
    <ContainerModal position="fixed">
      <Container onClick={(e) => e.stopPropagation()}>
        <ContainerHeader>
          <Text
            text="move"
            fontSize="18px"
            lineHeight="27px"
            fontWeight="500"
          />
          <ClickableIcon onClick={closeOnClick}>
            <SvgIcon svg={<KO />} width="18px" color={Theme.colors.c7A869A} />
          </ClickableIcon>
        </ContainerHeader>
        <div
          style={{
            minHeight: '461px',
          }}
        >
          <MoveFromSintropiDrive
            setCurrentFolderSelected={setCurrentFolderSelected}
            currentFolderSelected={currentFolderSelected}
            pathFolder={pathFolder}
            setPathFolder={setPathFolder}
            folderId={folderId}
          />
        </div>
        <ButtonSection
          closeOnClick={closeOnClick}
          submitOnCLick={() =>
            submitOnClick(
              currentFolderSelected.id,
              pathFolder.concat(currentFolderSelected)
            )
          }
        />
      </Container>
    </ContainerModal>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 12px;
  overflow: hidden;
  width: 940px;
  min-height: 520px;
`;

const ContainerHeader = styled.div`
  height: 70px;
  border-radius: 12px 12px 0 0;
  opacity: 0.98;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const ClickableIcon = styled.div`
  margin-right: 12px;
  height: 18px;
  cursor: pointer;
`;
