import {
  ComplexItemOutput,
  ExecutionVariableType,
  ExecutionVariableValue,
} from '@pec-manager/graphql';
import { extractFullName } from '../../../utils/stringUtils';
import { getDateAsString } from '../../../utils/dateUtils';

export const complexValueHandler = (value: string) => {
  const match = value?.match(/d\/\/(\d{13})/);
  if (match && value) {
    return getDateAsString(parseInt(match[1]));
  }
  return value;
};

export const mapFieldsMainVariable = (
  type: ExecutionVariableType,
  values: ExecutionVariableValue[],
  complexItems?: ComplexItemOutput[]
) => {
  switch (type) {
    case ExecutionVariableType.DATE: {
      const epochMillis = parseInt(values[0].raw!, 10);

      if (isNaN(epochMillis)) {
        return {
          values: '',
        };
      }

      return {
        values: 'onlyDate',
        translationArgs: {
          date: getDateAsString(epochMillis),
        },
      };
    }
    case ExecutionVariableType.USER:
      return {
        values: values.map(({ user }) => extractFullName(user)).join(', '),
      };
    case ExecutionVariableType.COMPLEX:
      return {
        values: complexItems
          ? complexItems
              .map(({ value }) => value?.map(({ value }) => value).join(', '))
              .join(', ')
          : '',
      };
    case ExecutionVariableType.DEFAULT:
    default:
      return {
        values: values.map(({ raw }) => raw).join(', '),
        translationArgs: {},
      };
  }
};
