import { gqlUserResolutionShape } from '../user/shapes';
import {
  gqlTaskBaseResolutionShape,
  gqlTaskSummaryResolutionShape,
} from '../task/shapes';

export const gqlBaseExecutionShape = `
  id
  rootExecutionId
  name
  processName
  description
  createdAt
  startedAt
  cancelledBy {
    ${gqlUserResolutionShape}
  }
  cancelledAt
  completedAt
  status
  startedBy {
    ${gqlUserResolutionShape}
  }
  extPoints {
    key
    value
  }
  isManageable
  dueDate
  priority 
  task {
      ${gqlTaskBaseResolutionShape}
  }
  subExecutions {
    id
    name
    processName
    description
    createdAt
    startedAt
    completedAt
    cancelledBy {
      ${gqlUserResolutionShape}
    }
    cancelledAt
    status
    dueDate
    priority
    startedBy {
      ${gqlUserResolutionShape}
    }
    task {
      ${gqlTaskBaseResolutionShape}
    }
  }
`;

export const gqlExecutionProgressStepShape = `
  name
  completedAt
  status
  error {
    code
    message
  }
`;

export const gqlExecutionShape = `
  base {
      ${gqlBaseExecutionShape}
  }
  activities {
      ${gqlTaskSummaryResolutionShape}
  }
  mainVariables {
    name
    values {
      raw
      user {
        ${gqlUserResolutionShape}  
      } 
    }
    complex {
      name
      value {
        value
      } 
    }
    type
  }
  progress {
    steps {
        ${gqlExecutionProgressStepShape}
    }
  }
`;
