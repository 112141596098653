import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { PieChart } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toPng } from 'html-to-image';
import { SvgIcon } from '../../components/common/SvgIcon';
import { HamburgerMenu } from '../../svg';
import { Theme } from '../../theme';

interface PieChartWidgetProps extends PieChart {
  column?: boolean;
}

export const PieChartWidget: FunctionComponent<PieChartWidgetProps> = ({
  labels,
  legend,
  series,
  colors,
  column,
}) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((ref.current as any)?.offsetWidth);

    const getWidth = () => {
      setWidth((ref.current as any)?.offsetWidth);
    };
    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, [ref]);

  const options: ApexCharts.ApexOptions = {
    labels,
    dataLabels: {
      // @ts-ignore
      formatter(val, opts) {
        //const name = opts.w.globals.labels[opts.seriesIndex].split(':')[1];
        return [
          // @ts-ignore
          `${val.toFixed(1)}% ; ${Intl.NumberFormat().format(
            opts.w.globals.series[opts.seriesIndex]
          )}`,
        ];
      },
      dropShadow: {
        opacity: 0,
      },
      style: {
        fontSize: '20px',
        colors: ['#000'],
      },
      distributed: true,
      enabled: true,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          minAngleToShowLabel: 20,
        },
      },
    },
    tooltip: {
      y: {
        formatter(val: number) {
          return Intl.NumberFormat().format(val);
        },
      },
    },
    legend: {
      position: 'bottom',
      show: legend,
      horizontalAlign: 'left',
      fontSize: '16px',
    },
    responsive: [
      {
        breakpoint: 1700,
        options: {
          chart: {
            height: column ? 300 : 500,
          },
        },
      },
      {
        breakpoint: 1450,
        options: {
          chart: {
            height: column ? 300 : 400,
          },
        },
      },
      {
        breakpoint: 1350,
        options: {
          chart: {
            width: 500,
            height: 500,
          },
        },
      },
    ],
    colors,
  };

  const htmlToImageConvert = useCallback(() => {
    if (ref) {
      toPng((ref as any).current, {
        cacheBust: false,
        filter: (node) => {
          const exclusionClasses = ['apexcharts-legend-series', 'remove'];
          return !exclusionClasses.some((classname) =>
            node.classList?.contains(classname)
          );
        },
      })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'pie-chart.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
  }, [ref]);

  return (
    <Container
      column
      alignItems="center"
      style={{ marginBottom: '24px' }}
      ref={ref}
    >
      <SvgContainer className="remove" onClick={htmlToImageConvert}>
        <SvgIcon svg={<HamburgerMenu />} color="#6e8192" />
      </SvgContainer>
      <Chart
        options={options}
        series={series}
        type="pie"
        width={width}
        height={column ? 600 : undefined}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  background: ${Theme.colors.cFFFFFF};
  .apexcharts-legend {
    max-height: 150px;
    overflow: auto;
    .apexcharts-legend-series {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const SvgContainer = styled(FlexContainer)`
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
