import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { FlexContainer } from '../layout/FlexContainer';
import { SvgIcon } from './SvgIcon';
import { Text } from './Text';
import { Color, Theme } from '../../theme';

interface ButtonWithIconProps {
  text: string;
  subTitleText?: string;
  svg?: JSX.Element;
  onClickHandler: () => void;
  backgroundColor?: Color | string;
  color?: Color | string;
}

export const ButtonWithIcon: FunctionComponent<ButtonWithIconProps> = ({
  text,
  subTitleText,
  svg,
  onClickHandler,
  backgroundColor,
  color,
}) => (
  <Container
    backgroundColor={backgroundColor}
    alignItems="center"
    onClick={onClickHandler}
    subtitle={!!subTitleText}
  >
    {svg && (
      <StyledSvgIcon
        svg={svg}
        color={color ?? Theme.colors.c6A6A6A}
        width="13px"
        subtitle={!!subTitleText}
      />
    )}
    <FlexContainer column={!!subTitleText}>
      <Text
        text={text}
        fontSize="13px"
        lineHeight="16px"
        color={color ?? Theme.colors.c505050}
      />
      {subTitleText && (
        <SubtitleText
          text={subTitleText}
          fontSize="10px"
          lineHeight="12px"
          color={color ?? Theme.colors.c6A6A6A}
        />
      )}
    </FlexContainer>
  </Container>
);

const Container = styled(FlexContainer)<{
  subtitle?: boolean;
  backgroundColor?: Color | string;
}>`
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.colors.cFFFFFF};
  border-radius: 8px;
  padding: ${({ subtitle }) => (subtitle ? '8px' : '14px')} 15px;
  cursor: pointer;
  height: 46px;

  &:hover {
    opacity: 0.85;
  }
`;

const StyledSvgIcon = styled(SvgIcon)<{ subtitle?: boolean }>`
  margin-right: ${({ subtitle }) => (subtitle ? '16px' : '10px')};
`;

const SubtitleText = styled(Text)`
  margin-top: 4px;
`;
