import React, { useState } from 'react';
import styled from 'styled-components';
import {
  FormDefinitionInvocationArgs,
  TaskProgressStepType,
} from '@pec-manager/graphql';
import { AroundLoader } from '../../components/loader/AroundLoader';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { Tag } from '../../components/tag';
import { removeTaskProgressFlowAction } from '../../redux/notification/sidebar/actions';
import { useDispatch, useSelector } from 'react-redux';
import { joiningTaskSelector } from '../../redux/notification/sidebar/selectors';
import { SvgIcon } from '../../components/common/SvgIcon';
import { InProgress, Success } from '../../svg';
import { DynamicForm } from '../../components/DynamicForm';

export const ProgressFlows = () => {
  const dispatch = useDispatch();
  const ongoingProgressFLows = useSelector(joiningTaskSelector);
  const [openProgressList, setOpenProgressList] = useState(false);
  const [showFormDefinition, setsShowFormDefinition] = useState<
    undefined | FormDefinitionInvocationArgs
  >(undefined);

  const [taskIdSelected, setTaskIdSelected] = useState<undefined | string>(
    undefined
  );

  return ongoingProgressFLows.length > 0 ? (
    <>
      {showFormDefinition && (
        <DynamicForm
          executionContext={showFormDefinition.context as any}
          columnsForm={1}
          taskId={showFormDefinition.id}
          onCancel={() => {
            setsShowFormDefinition(undefined);
          }}
          onSuccess={() => {
            setsShowFormDefinition(undefined);
            taskIdSelected &&
              dispatch(removeTaskProgressFlowAction(taskIdSelected));
            setTaskIdSelected(undefined);
            setOpenProgressList(false);
          }}
        />
      )}
      <SpinnerContainer
        onClick={() => setOpenProgressList((prevState) => !prevState)}
      >
        <SvgIcon
          svg={<InProgress />}
          height="34px"
          color={Theme.colors.c838383}
          style={{
            minWidth: '34px',
            marginLeft: '8px',
          }}
        />
        {ongoingProgressFLows.filter((f) =>
          f.progressSteps.some((s) => s.type === TaskProgressStepType.COMPLETED)
        ).length > 0 && (
          <FlowsCount justifyContent="center" alignItems="center">
            <Text
              text={`${
                ongoingProgressFLows.filter((f) =>
                  f.progressSteps.some(
                    (s) => s.type === TaskProgressStepType.COMPLETED
                  )
                ).length
              }`}
              fontSize="10px"
              lineHeight="12px"
              color={Theme.colors.cFFFFFF}
            />
          </FlowsCount>
        )}
        {openProgressList && (
          <ContainerProgressFlows onClick={(event) => event.stopPropagation()}>
            <Arrow />
            <TopSection alignItems="center" justifyContent="space-between">
              <Text
                text="inProgress"
                fontSize="14px"
                lineHeight="21px"
                color={Theme.colors.c9F9F9F}
                style={{ textTransform: 'uppercase' }}
              />
            </TopSection>
            <ContainerItem>
              {ongoingProgressFLows.map((f) => {
                const isCompleted = f.progressSteps.filter(
                  (step) => step.type === TaskProgressStepType.COMPLETED
                );

                if (isCompleted.length > 0) {
                  const dateNow =
                    getDateAsString(Date.now()) ===
                    getDateAsString(isCompleted[0].occurredAt as any);

                  return (
                    <Item
                      key={f.taskId}
                      alignItems="center"
                      pointer
                      onClick={() => {
                        setOpenProgressList(false);
                        setsShowFormDefinition(
                          f.progressSteps[0].formDefinitionInvocationArgs
                        );
                        setTaskIdSelected(f.taskId);
                      }}
                    >
                      <SvgIcon
                        svg={<Success />}
                        style={{ marginRight: '16px' }}
                        width="30px"
                        color={Theme.colors.c52C41A}
                      />
                      <FlexContainer column style={{ width: '100%' }}>
                        <FlexContainer
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginBottom: '5px' }}
                        >
                          <Tag text={f.taskCategory || undefined} />
                          <Text
                            text={dateNow ? 'todayAt' : 'completeDate'}
                            translationArgs={{
                              date: getDateAsString(
                                isCompleted[0].occurredAt as any
                              ),
                              time: getTimeAsString(
                                isCompleted[0].occurredAt as any
                              ),
                              fresh: true,
                            }}
                            fontSize="12px"
                            lineHeight="18px"
                            fontWeight="300"
                            color={Theme.colors.c505050}
                          />
                        </FlexContainer>
                        <Text
                          text={f.taskDisplayName}
                          fontSize="12px"
                          lineHeight="18px"
                          fontWeight="600"
                          ellipsis
                          color={Theme.colors.c505050}
                        />
                        <Text
                          text="requestCompleted"
                          fontSize="12px"
                          lineHeight="18px"
                          color={Theme.colors.c505050}
                        />
                        <Text
                          text="clickToContinues"
                          fontSize="12px"
                          lineHeight="18px"
                          color={Theme.colors.c505050}
                        />
                      </FlexContainer>
                    </Item>
                  );
                } else {
                  const dateNow =
                    getDateAsString(Date.now()) ===
                    getDateAsString(f.progressSteps[0].occurredAt as any);

                  return (
                    <Item key={f.taskId} alignItems="center">
                      <AroundLoader style={{ marginRight: '16px' }} />
                      <FlexContainer column style={{ width: '100%' }}>
                        <FlexContainer
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginBottom: '5px' }}
                        >
                          <Tag text={f.taskCategory || undefined} />
                          <Text
                            text={dateNow ? 'todayAt' : 'completeDate'}
                            translationArgs={{
                              date: getDateAsString(
                                f.progressSteps[0].occurredAt as any
                              ),
                              time: getTimeAsString(
                                f.progressSteps[0].occurredAt as any
                              ),
                              fresh: true,
                            }}
                            fontSize="12px"
                            lineHeight="18px"
                            fontWeight="300"
                            color={Theme.colors.c505050}
                          />
                        </FlexContainer>
                        <Text
                          text={f.taskDisplayName}
                          fontSize="12px"
                          lineHeight="18px"
                          fontWeight="600"
                          ellipsis
                          color={Theme.colors.c505050}
                        />
                        <Text
                          text="requestForwarded"
                          fontSize="12px"
                          lineHeight="18px"
                          color={Theme.colors.c505050}
                        />
                      </FlexContainer>
                    </Item>
                  );
                }
              })}
            </ContainerItem>
          </ContainerProgressFlows>
        )}
      </SpinnerContainer>
    </>
  ) : null;
};

const SpinnerContainer = styled.div`
  margin-right: 32px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FlowsCount = styled(FlexContainer)`
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: ${({ theme }) => theme.colors.c52C41A};
  border-radius: 50%;
  top: -2px;
  right: -4px;
  border: 1px solid ${({ theme }) => theme.colors.cFFFFFF};
  box-sizing: unset;
`;

const ContainerProgressFlows = styled.div`
  width: 466px;
  border-radius: 12px;
  position: absolute;
  top: 50px;
  right: -20px;
  z-index: 9;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  box-shadow: 0 4px 20px 0 #00000026;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  cursor: default;
  overflow: hidden;
`;

const Arrow = styled.div`
  position: absolute;
  top: -8px;
  right: 28px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${({ theme }) => theme.colors.cFFFFFF};
`;

const TopSection = styled(FlexContainer)`
  width: 466px;
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ContainerItem = styled.div`
  max-height: 329px;
  overflow-y: scroll;
`;

const Item = styled(FlexContainer)<{ pointer?: boolean }>`
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  ${({ pointer, theme }) =>
    pointer &&
    `cursor: pointer; &:hover { background-color: ${theme.colors.transparentBlue};}`}

  &:last-child {
    border-bottom: 0;
  }
`;
