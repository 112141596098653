import { BarChart } from '@pec-manager/graphql';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { KeycloakService } from '../../services/KeycloakService';

export const BarChartWidget: FunctionComponent<BarChart> = ({
  labels,
  colors,
  series,
}) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((ref.current as any)?.offsetWidth);

    const getWidth = () => {
      setWidth((ref.current as any)?.offsetWidth);
    };
    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, [ref]);

  const options: ApexCharts.ApexOptions = {
    series: [
      {
        data: series || [0, 0],
      },
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        distributed: true,
      },
    },
    dataLabels: {
      dropShadow: {
        opacity: 1,
        blur: 1,
      },
      offsetX: 0,
      textAnchor: 'start',
      background: {
        opacity: 1,
      },
      style: {
        colors: ['#000'],
      },
      formatter(val: number, opts?: any): string {
        return Intl.NumberFormat(KeycloakService.getLocale()).format(val);
      },
    },
    colors,
    xaxis: {
      categories: labels,
      sorted: true,
      tickAmount: 3,
      labels: {
        // @ts-ignore
        formatter(val: number, opts?: any): string {
          return Intl.NumberFormat(KeycloakService.getLocale()).format(val);
        },
      },
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return '';
          },
        },
        formatter(val: number, opts?: any): string {
          return Intl.NumberFormat(KeycloakService.getLocale()).format(val);
        },
      },
    },
    responsive: [
      {
        breakpoint: 1350,
        options: {
          chart: {
            width: 700,
          },
        },
      },
      {
        breakpoint: 1150,
        options: {
          chart: {
            width: 700,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };

  return (
    <Container
      column
      alignItems="center"
      style={{ marginBottom: '24px' }}
      ref={ref}
    >
      <Chart
        options={options}
        series={[{ data: series || [0, 0] }]}
        type="bar"
        width={width}
        height={series!.length > 10 ? undefined : (series!.length + 1) * 50}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  .apexcharts-legend {
    max-height: 150px;
    overflow: auto;
    .apexcharts-legend-series {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
