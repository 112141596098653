import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import { Task } from '@pec-manager/graphql';
import { TopSection } from './TopSection';
import { PrioritySection } from './PrioritySection';
import { Theme } from '../../theme';
import { AttachmentsSection } from './AttachmentsSection';
import { CustomSection } from './CustomSection';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { ActionSection } from './ActionSection';
import { ModalTypeInbox } from './index';
import { ActivitySection } from '../document/Document/ActivitySection';
import { AssigneesSection } from './AssigneesSection';
import { AroundLoader } from '../../components/loader/AroundLoader';

interface MailDetailsProps {
  task: Task;
  setModalType: Dispatch<SetStateAction<ModalTypeInbox | undefined>>;
  clickOnClose?: () => void;
}

export const TaskDetails: FunctionComponent<MailDetailsProps> = ({
  task,
  setModalType,
  clickOnClose,
}) => (
  <TaskContainer column>
    {task ? (
      <>
        <TopSection
          colorBackground={task.base.rootExecutionId}
          textTag={task.base.category}
          receivedAt={task.base.createdAt as any as number}
          clickOnClose={clickOnClose}
          title={task.base.name}
        />
        <ContainerDetails>
          {task.outputSections.length > 0 && (
            <CustomSectionContainer column>
              {task.outputSections.map((output) => (
                <CustomSection key={output.name} output={output} />
              ))}
            </CustomSectionContainer>
          )}
          <FlexContainer column style={{ width: '100%' }}>
            <PrioritySection taskBase={task.base} />
            {task.base.assignees.length > 0 && (
              <AssigneesSection taskBase={task.base} />
            )}
            <DescriptionSection
              text={task.base.description}
              skipTranslation
              lineHeight="24px"
              fontSize="16px"
              color={Theme.colors.c505050}
            />
            {task.attachments.length > 0 && (
              <AttachmentsSection
                attachments={task.attachments}
                lastSection={task.outputSections.length === 0}
              />
            )}
            <>
              <ActivitySection id={task.base.id} taskSubject={task.base.name} />
            </>
          </FlexContainer>
        </ContainerDetails>
        <ActionSection
          actions={task.actions}
          setModalType={setModalType}
          rootExecutionId={task.base.rootExecutionId}
        />
      </>
    ) : (
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%', marginTop: '200px' }}
      >
        <AroundLoader />
      </FlexContainer>
    )}
  </TaskContainer>
);

const TaskContainer = styled(FlexContainer)`
  width: 100%;
  border-radius: 12px;
  box-shadow: 3px 1px 20px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 16px 24px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  height: calc(100vh - 100px);
  overflow: hidden;
  @media (max-width: 990px) {
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const CustomSectionContainer = styled(FlexContainer)`
  padding: 0 24px 24px 24px;
  margin-right: 24px;
  width: 50%;
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  @media (max-width: 990px) {
    width: 100%;
    margin-right: 0;
    border: none;
  }
`;

const DescriptionSection = styled(Text)`
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
`;

const ContainerDetails = styled(FlexContainer)`
  overflow: auto;
  height: 100%;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;
