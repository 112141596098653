import React, { FunctionComponent } from 'react';
import { ChatRoomMessagePostedEvent, User } from '@pec-manager/graphql';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { RoundedTextAvatar } from '../../../components/avatar/RoundedTextAvatar';
import { KeycloakService } from '../../../services/KeycloakService';
import { Text } from '../../../components/common/Text';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { ClockAttendMessage, MessageCheckmark } from '../../../svg';
import { getTimeAsString } from '../../../utils/dateUtils';
import { Theme } from '../../../theme';
import { decomposeRawWithMentions } from '../../../utils/mention';
import { extractFullName } from '../../../utils/stringUtils';

interface MessagePostedProps {
  messagePostedEvent: ChatRoomMessagePostedEvent;
  isRead?: boolean;
  isReceived?: boolean;
  showAvatar?: boolean;
  actor: User;
  timestamp: number;
}

export const MessagePosted: FunctionComponent<MessagePostedProps> = ({
  messagePostedEvent,
  isRead = false,
  isReceived = false,
  showAvatar = false,
  actor,
  timestamp,
}) => {
  const mine = actor.username === KeycloakService.getUsername();

  return (
    <Container mine={mine} showAvatar={showAvatar}>
      <ContainerAvatar>
        {showAvatar && <RoundedTextAvatar text={extractFullName(actor)} />}
      </ContainerAvatar>
      <MessageContainer mine={mine} showAvatar={showAvatar}>
        <BodyContainer>
          {decomposeRawWithMentions(messagePostedEvent.message).map(
            (m, index) => {
              /* eslint-disable react/no-array-index-key */
              if (m) {
                return (
                  <Text
                    key={index}
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                    text={
                      (m as any).id
                        ? (m as any).display
                        : (m as any).textElement
                    }
                    color={(m as any).id ? Theme.colors.c5B84EF : undefined}
                    skipTranslation
                    fontSize="14px"
                    lineHeight="21px"
                  />
                );
              }
              return null;
            }
          )}
        </BodyContainer>
        <ContainerSvg mine={mine}>
          <>
            <Text
              text={getTimeAsString(timestamp)}
              fontSize="8px"
              lineHeight="12px"
            />
            {mine && !isReceived && !isRead && (
              <SvgIcon
                svg={<ClockAttendMessage />}
                height="12px"
                color={Theme.colors.c000000}
              />
            )}
            {mine && isReceived && (
              <SvgIcon
                svg={<MessageCheckmark />}
                height="12px"
                color={isReceived ? Theme.colors.c0E53B7 : Theme.colors.c000000}
              />
            )}
            {mine && isRead && (
              <SvgIcon
                svg={<MessageCheckmark />}
                height="12px"
                width="12px"
                color={Theme.colors.c0E53B7}
              />
            )}
          </>
        </ContainerSvg>
      </MessageContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)<{ mine: boolean; showAvatar: boolean }>`
  margin-top: ${({ showAvatar }) => (showAvatar ? '0px' : '-20px')};
  margin-bottom: 24px;
  ${({ mine }) => mine && `flex-direction: row-reverse;`}
`;

const ContainerAvatar = styled.div`
  width: 40px;
`;

const BodyContainer = styled.div`
  display: inline;
`;

const MessageContainer = styled.div<{ mine: boolean; showAvatar: boolean }>`
  word-break: break-word;
  position: relative;
  background-color: ${({ mine, theme }) =>
    mine ? theme.colors.cE2F3FF : theme.colors.cFFFFFF};
  padding: 12px;
  max-width: 600px;
  border-radius: ${({ mine, showAvatar }) => {
    if (!showAvatar) {
      return '20px';
    }
    return mine ? '20px 0 20px 20px' : '0 20px 20px 20px';
  }};
  margin-left: 12px;
  ${({ mine }) => mine && `margin-right: 12px;`}
`;

const ContainerSvg = styled.div<{ mine: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  align-items: center;
  ${({ mine }) => mine && `width: fit-content;`}

  & > span:first-child {
    margin-right: 4px;
    ${({ mine }) => mine && `width: 38px;`}
  }

  & > div:nth-child(3) {
    margin-left: -16px;
  }
`;
