import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IdentifiableValue } from '@pec-manager/graphql';
import { Text } from '../../../common/Text';
import { SvgIcon } from '../../../common/SvgIcon';
import { Arrow } from '../../../../svg';
import { FlexContainer } from '../../../layout/FlexContainer';
import { Breadcrumb } from '../../../common/breadcrumb';
import { useLocation } from 'react-router-dom';

interface TitleProps {
  folder: IdentifiableValue;
  backAction: () => void;
  folderPath?: IdentifiableValue[];
  border?: boolean;
  translationArgs?: any;
}

export const Title: FunctionComponent<TitleProps> = ({
  folder,
  backAction,
  folderPath = [],
  border,
  translationArgs,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const documentId = searchParams.get('documentId');

  return (
    <ContainerTitle onClick={backAction} border={border}>
      {folder?.id && (
        <ContainerSvg>
          <BackButton
            onClick={(e) => {
              backAction();
            }}
          >
            <SvgIcon
              svg={<Arrow />}
              width="46.42px"
              rotateDeg={90}
              height="10px"
            />
          </BackButton>
        </ContainerSvg>
      )}
      <ContentContainer
        column
        justifyContent="space-between"
        activeBreadcrumb={!!documentId}
      >
        {folderPath.length > 0 && (
          <Breadcrumb
            position={folderPath.map((f) => ({
              id: f?.id || '',
              value: f?.value || 'Drive',
            }))}
          />
        )}
        <TitleText
          text={folder?.value || 'Drive'}
          fontSize="18px"
          lineHeight="27px"
          fontWeight="500"
          translationArgs={translationArgs}
          ellipsis
        />
      </ContentContainer>
    </ContainerTitle>
  );
};

const ContainerTitle = styled.div<{ border?: boolean }>`
  display: flex;
  ${({ border, theme }) =>
    border &&
    `border-top: 1px solid ${theme.colors.cDCDCDC}; border-bottom: 1px solid ${theme.colors.cDCDCDC}`};
  padding: 18px 0;
`;

const ContentContainer = styled(FlexContainer)<{ activeBreadcrumb?: boolean }>`
  margin: 0 16px;

  @media (max-width: 990px) {
    & > div {
      display: ${({ activeBreadcrumb }) =>
        activeBreadcrumb ? 'flex' : 'none'};
    }
    max-width: 65%;
  }
`;

const ContainerSvg = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 990px) {
    padding: 0 8px;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 24px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
`;

const TitleText = styled(Text)``;
