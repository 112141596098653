import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { DetailsSectionExecution } from './DetailsSectionExecution';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Close, ProcessIcon } from '../../../svg';
import { useNavigate } from 'react-router-dom';
import { StepProgressBar } from '../../../components/stepProgressBar';
import { SubProcess } from './SubProcess';
import { DetailsSubProcess } from './DetailsSubProcess';
import { IdentifiableValue } from '@pec-manager/graphql';
import { ActivityProcess } from './ActivityProcess';
import { Tag } from '../../../components/tag';
import { useDispatch, useSelector } from 'react-redux';
import { executionsStateSelector } from '../../../redux/Sintropi/execution/selectors';
import { AroundLoader } from '../../../components/loader/AroundLoader';
import { clearExecutionDetails } from '../../../redux/Sintropi/execution/actions';

interface ExecutionDetailsProps {
  id: string;
}

export const Details: FunctionComponent<ExecutionDetailsProps> = ({ id }) => {
  const dispatch = useDispatch();
  const [detailSubProcess, setDetailsSubProcess] = useState<
    IdentifiableValue | undefined
  >();

  const { executionDetails } = useSelector(executionsStateSelector);

  useEffect(() => {
    return () => {
      dispatch(clearExecutionDetails());
    };
  }, [dispatch]);

  const navigate = useNavigate();

  return (
    <Container column>
      {executionDetails ? (
        <FlexContainer
          style={{ height: '100%', padding: '0px 20px 20px 20px' }}
          column
        >
          <IconContainer onClick={() => navigate(-1)}>
            <SvgIcon
              svg={<Close />}
              width="10px"
              height="10px"
              color={Theme.colors.cFFFFFF}
            />
          </IconContainer>
          <Tag text={executionDetails.base.processName} />
          <FlexContainer alignItems="center" style={{ marginTop: '16px' }}>
            <SvgIcon
              svg={<ProcessIcon />}
              width="24px"
              height="24px"
              color={Theme.colors.c2F80ED}
            />
            <Text
              text={executionDetails.base.name}
              fontSize="20px"
              lineHeight="20px"
              fontWeight="600"
              style={{ marginLeft: '8px' }}
            />
          </FlexContainer>
          <ContainerGeneral style={{ height: '100%' }}>
            <ContainerLeft
              column
              fullwidth={executionDetails?.progress?.steps.length <= 0}
            >
              <TopContainer column>
                <Text
                  text={executionDetails.base.description}
                  fontSize="14px"
                  lineHeight="20px"
                  color={Theme.colors.c505050}
                  style={{ marginTop: '16px' }}
                />
              </TopContainer>
              <BottomContainer column>
                <DetailsSectionExecution
                  startedBy={executionDetails.base.startedBy!}
                  startDate={executionDetails.base.startedAt! as any as number}
                  endDate={
                    executionDetails.base.completedAt as any as
                      | number
                      | undefined
                  }
                  state={executionDetails.base.status}
                  mainVariables={executionDetails.mainVariables || []}
                />
                <FlexContainer column style={{ overflow: 'auto' }}>
                  <>
                    <Text
                      text="tasks"
                      fontSize="16px"
                      lineHeight="12px"
                      style={{ marginTop: '30px', marginBottom: '15px' }}
                    />
                    <ActivityProcess
                      activities={executionDetails.activities || []}
                    />
                  </>
                  {executionDetails.base.subExecutions.length > 0 && (
                    <>
                      <Text
                        text="subprocessAdvancement"
                        fontSize="16px"
                        lineHeight="12px"
                        style={{ marginTop: '16px', marginBottom: '15px' }}
                      />
                      {!detailSubProcess ? (
                        <SubProcess
                          subExecution={
                            executionDetails.base.subExecutions || []
                          }
                          onClickDetails={setDetailsSubProcess}
                        />
                      ) : (
                        <DetailsSubProcess
                          onClickBack={() => setDetailsSubProcess(undefined)}
                          subProcess={detailSubProcess}
                        />
                      )}
                    </>
                  )}
                </FlexContainer>
              </BottomContainer>
            </ContainerLeft>
            {executionDetails.progress.steps.length > 0 && (
              <ContainerRight column>
                <StepProgressBar optionList={executionDetails.progress.steps} />
              </ContainerRight>
            )}
          </ContainerGeneral>
        </FlexContainer>
      ) : (
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginTop: '200px' }}
        >
          <AroundLoader />
        </FlexContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  border-radius: 12px;
  box-shadow: 3px 1px 20px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 16px 24px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  min-height: 600px;
  overflow: hidden;

  @media (max-width: 1040px) {
    overflow: auto;
  }
  @media (max-width: 990px) {
    padding: 8px;
  }
`;

const ContainerGeneral = styled(FlexContainer)`
  height: 100%;
  padding: 0 20px 20px 20px;
  @media (max-width: 990px) {
    flex-direction: column;
    padding: 0;
  }
`;

const ContainerLeft = styled(FlexContainer)<{ fullwidth?: boolean }>`
  width: ${({ fullwidth }) => (fullwidth ? '100%' : '70%')};

  border-right: ${({ fullwidth, theme }) =>
    fullwidth ? 'none' : `1px solid ${theme.colors.cDCDCDC};`};
  padding-bottom: 16px;
  height: 100%;

  @media (max-width: 990px) {
    width: 100%;
    border-right: none;
  }
`;

const ContainerRight = styled(FlexContainer)`
  width: 30%;
  height: 100%;
  padding: 24px;
  border-left: 1px solid #dcdcdc;
  margin-left: -1px;
  overflow: auto;
  & > div:not(:last-child, :first-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
  .vertical-timeline {
    padding: 0 !important;
  }

  @media (max-width: 990px) {
    display: none;
  }
`;

const TopContainer = styled(FlexContainer)`
  width: 100%;
  height: fit-content;
`;

const IconContainer = styled(FlexContainer)`
  min-height: 20px;
  min-width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.c838383};
  margin: 0px -22px 0px auto;
`;
const BottomContainer = styled(FlexContainer)`
  overflow: auto;
  height: 100%;
`;
