import { gql } from '@apollo/client/core';
import { gqlChatRoomEventShape } from './shapes';

export const CHAT_ROOM_EVENT_RECEIVED = gql`
  subscription ChatRoomEventReceived($correlationId: String!) {
      chatRoomEventReceived(correlationId: $correlationId) {
        ${gqlChatRoomEventShape}
    }
  }
`;

export const CHAT_ROOM_LIST_EVENT_RECEIVED = gql`
  subscription ChatRoomListEventReceived($correlationId: String!) {
      chatRoomListEventReceived(correlationId: $correlationId) {
        ${gqlChatRoomEventShape}
    }
  }
`;
