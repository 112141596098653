import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { NotificationResourceType } from '@pec-manager/graphql/lib/graphql.types';
import { ChatModalCompressed } from './ChatModalCompressed';
import { closeChatModalId } from '../../redux/Sintropi/execution/actions';
import { ChatModalExtended } from './ChatModalExtended';
import { CHAT_ROOM_EVENT_RECEIVED } from '../../graphql/chat/subscriptions';
import { useChat } from '../hooks/useChat';
import { GET_RESOURCE_CONTEXT } from '../../graphql/resource/queries';
import { extractTitleChat } from '../ChatDetails/utilis';

interface ChatInModalProps {
  chatId: string;
  resourceId?: string;
}

export const ChatInModal: FunctionComponent<ChatInModalProps> = ({
  chatId,
  resourceId,
}) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState<string>('Loading...');

  const [isCompressed, setIsCompressed] = useState(false);

  const { data: chat, openChat } = useChat(chatId, resourceId);

  const { data: newChatEvent } = useSubscription(CHAT_ROOM_EVENT_RECEIVED, {
    variables: { correlationId: chatId },
  });

  const [getResourceContext, { data: context }] =
    useLazyQuery(GET_RESOURCE_CONTEXT);

  useEffect(() => {
    if (context?.getResourceContext?.context?.resourceName) {
      setTitle(context.getResourceContext.context.resourceName);
    } else if (chat?.chatRoom.participants) {
      setTitle(extractTitleChat(chat.chatRoom.participants, true));
    }
  }, [chat, context]);

  useEffect(() => {
    if (resourceId && chat?.chatRoom?.resourceId) {
      getResourceContext({
        variables: {
          resourceId: chat.chatRoom.resourceId,
          resourceType: NotificationResourceType.EXECUTION,
        },
      });
    }
    // eslint-disable-next-line
  }, [chat, location]);

  useEffect(() => {
    if (openChat) {
      openChat();
    }
    // eslint-disable-next-line
  }, [isCompressed]);

  if (!chat) {
    // loading or empty
    return <></>;
  }

  return isCompressed ? (
    <ChatModalCompressed
      closeCompressed={() => setIsCompressed((prevState) => !prevState)}
      closeChat={() => dispatch(closeChatModalId())}
      title={title}
    />
  ) : (
    <ChatModalExtended
      context={context?.getResourceContext?.context}
      title={title}
      closeCompressed={() => setIsCompressed((prevState) => !prevState)}
      closeChat={() => dispatch(closeChatModalId())}
      chat={chat}
      newChatEvent={newChatEvent?.chatRoomEventReceived}
    />
  );
};
