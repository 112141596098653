import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Mention, MentionsInput } from 'react-mentions';
import { useQuery, useSubscription } from '@apollo/client';
import { User } from '@pec-manager/graphql/lib/graphql.types';
import { useTranslation } from 'react-i18next';
import { FlexContainer } from '../layout/FlexContainer';
import { ChatContent } from '../../chat/ChatDetails/ChatContent';
import defaultStyle from './defaultStyle';
import { CHAT_ROOM_EVENT_RECEIVED } from '../../graphql/chat/subscriptions';
import { FIND_USERS } from '../../graphql/user/queries';
import { useMutationHook } from '../hooks/useMutationHook';
import { POST_MESSAGE_CHAT } from '../../graphql/chat/mutations';
import { useChat } from '../../chat/hooks/useChat';

interface ChatRightProps {
  chatRoomId: string;
  resourceId?: string;
}

export const ChatRight: FunctionComponent<ChatRightProps> = ({
  chatRoomId,
  resourceId,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [mentions, setMentions] = useState<string[]>([]);

  const [postMessage] = useMutationHook({
    mutationGql: POST_MESSAGE_CHAT,
    errorMessage: 'genericErrorMessage',
  });

  const [users, setUsers] = useState<{ id: string; display: string }[]>([]);

  const { data: searchUser } = useQuery(FIND_USERS);

  const { data: chat } = useChat(chatRoomId, resourceId);

  useEffect(() => {
    if (searchUser) {
      setUsers(
        searchUser.findUsers.map((u: User) => ({
          id: u.id,
          display: `${u.firstName} ${u.lastName}`,
        }))
      );
    }
  }, [searchUser]);

  const { data: newChatEvent } = useSubscription(CHAT_ROOM_EVENT_RECEIVED, {
    variables: { correlationId: chatRoomId },
  });

  if (!chat) {
    // loading or empty
    return <></>;
  }

  return (
    <Container column>
      <ChatContent
        initialEvents={chat.events}
        chatRoomId={chat.chatRoom.id}
        newChatEvent={newChatEvent?.chatRoomEventReceived}
      />
      <SendMessageContainer>
        <MentionsInput
          value={inputValue}
          onChange={(event, newValue, newSet, mentionsItem) => {
            setMentions(mentionsItem.map((m) => m.id));
            setInputValue(newValue);
          }}
          style={defaultStyle}
          placeholder={t('enterTextToSend') as string}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputValue) {
              postMessage({
                variables: {
                  chatRoomId: chat.chatRoom.id,
                  message: {
                    message: inputValue,
                    mentions: Array.from(new Set(mentions)),
                  },
                },
              }).then(() => {
                setInputValue('');
              });
            }
          }}
        >
          <Mention
            trigger="@"
            data={users}
            displayTransform={(id, display) => display}
            style={{
              backgroundColor: 'rgba(205, 205, 205, 0.5)',
              borderRadius: '4px',
            }}
          />
        </MentionsInput>
      </SendMessageContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;

  height: calc(100% - 66px);
`;

const SendMessageContainer = styled.div`
  display: flex;
  min-height: 80px;
  padding: 20px 0;
  -webkit-box-shadow: 0px -2px 16px -2px #50505012;
  box-shadow: 0px -2px 16px 4px #50505012;
  z-index: 1;
`;
