import { gql } from '@apollo/client/core';
import { gqlPagedFormDefinitionResolutionShape } from './shapes';

export const FORM_DEFINITION_BY_ID = gql`
    mutation FormDefinitionById(
        $context: ExecutionContext!
        $id: ID!
        $name: String
        $initialContext: InitialContext
    ) {
        formDefinitionById(
            context: $context
            id: $id
            name: $name
            initialContext: $initialContext
        ) {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
`;

export const FORM_DEFINITION_DIFFS_ON_DYNAMIC_FIELD_CHANGE = gql`
    mutation FormDefinitionDiffsOnDynamicFieldChange(
        $context: ExecutionContext!
        $currentPageId: ID!
        $filledForm: FilledForm!
    ) {
        formDefinitionDiffsOnDynamicFieldChange(
            context: $context
            currentPageId: $currentPageId
            filledForm: $filledForm
        ) {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
`;

export const ADVANCE_FORM_DEFINITION_PAGE_BY_CURSOR_DIRECTION = gql`
    mutation AdvanceFormDefinitionPageByCursorDirection(
        $context: ExecutionContext!
        $currentPageId: ID!
        $filledForm: FilledForm!
        $direction: PagedFormDefinitionCursorDirection!
    ) {
        advanceFormDefinitionPageByCursorDirection(
            context: $context
            currentPageId: $currentPageId
            filledForm: $filledForm
            direction: $direction
        ) {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
`;

export const SUBMIT_FORM = gql`
  mutation SubmitForm(
    $context: ExecutionContext!
    $currentPageId: ID!
    $filledForm: FilledForm!
    $dynamicAction: IdentifiableValueInput
    $initialContext: InitialContext
  ) {
      submitForm(
      context: $context
      currentPageId: $currentPageId
      filledForm: $filledForm
      dynamicAction: $dynamicAction
      initialContext: $initialContext
    ) {
        pagedFormDefinition {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
  }
`;
export const DRAFT_FORM = gql`
  mutation DraftForm(
    $context: ExecutionContext!
    $currentPageId: ID!
    $filledForm: FilledForm!
    $dynamicAction: IdentifiableValueInput
  ) {
      draftForm(
      context: $context
      currentPageId: $currentPageId
      filledForm: $filledForm
      dynamicAction: $dynamicAction
    ) {
        pagedFormDefinition {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
  }
`;

export const SUBMIT_EXTENSION_FORM = gql`
  mutation SubmitExtensionForm(
    $execId: ID!
    $extPoint: ExtensionPointInput!
    $filledForm: FilledForm!
  ) {
      submitExtensionForm(
      execId: $execId
      extPoint: $extPoint
      filledForm: $filledForm
    ) {
        pagedFormDefinition {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
  }
`;

export const FORM_DEFINITION_BY_ACTION = gql`
  mutation FormDefinitionByAction(
    $context: ExecutionContext!
    $filledForm: FilledForm!
    $dynamicAction: IdentifiableValueInput!
  ) {
      formDefinitionByAction(
      context: $context
      filledForm: $filledForm
      dynamicAction: $dynamicAction
    ) {
        ${gqlPagedFormDefinitionResolutionShape}
    }
  }
`;

export const FORM_DEFINITION_BY_EXT_POINT = gql`
  mutation FormDefinitionByExtPoint(
    $execId: ID!
    $extPoint: ExtensionPointInput!
  ) {
      formDefinitionByExtPoint(
      execId: $execId
      extPoint: $extPoint
    ) {
        ${gqlPagedFormDefinitionResolutionShape}
    }
  }
`;
