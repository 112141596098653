import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_RECORD_DETAIL } from '../../../graphql/record/queries';
import { FieldSets, Operator, QueryFilter } from '@pec-manager/graphql';
import { CustomSectionRecord } from './CustomSectionRecord';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { CircleLoader } from '../../../components/common/CircleLoader';
import { calcInitialContextForRecord } from '../ItemRecordList/utils';

interface RecordDetailsProps {
  appCode?: string;
  tableName?: string;
  setInitialContextDetail?: React.Dispatch<React.SetStateAction<any>>;
  forceRefetch?: boolean;
  setForceRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RecordDetails: FC<RecordDetailsProps> = ({
  appCode,
  tableName,
  setInitialContextDetail,
  forceRefetch,
  setForceRefetch,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const recordId = useMemo(
    () => searchParams.get('recordId')?.toString(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams.get('recordId')]
  );

  const [getRecordDetail, { data, loading, refetch }] =
    useLazyQuery(GET_RECORD_DETAIL);

  useEffect(() => {
    if (forceRefetch) {
      refetch();
      setForceRefetch && setForceRefetch(false);
    }
  }, [forceRefetch, refetch, setForceRefetch]);

  useEffect(() => {
    if (recordId && tableName) {
      const variables: QueryFilter = {
        filter: {
          operator: {
            key: 'pk',
            value: { string: recordId },
            op: Operator.exact,
          },
        },
      };

      getRecordDetail({
        variables: {
          appName: appCode,
          table: tableName,
          queryFilter: variables,
        },
      });
    }
  }, [appCode, getRecordDetail, recordId, tableName]);

  useEffect(() => {
    if (setInitialContextDetail && data) {
      const calcInitialContext = data.getRecordDetail.fieldSets.map(
        (f: FieldSets) =>
          f.columns?.nested?.map((c) => ({
            key: c.name,
            values:
              c.value?.list && c.value.list.length > 0
                ? [...calcInitialContextForRecord(c.value)]
                : [calcInitialContextForRecord(c.value)],
          }))
      );

      setInitialContextDetail(calcInitialContext || undefined);
    }
  }, [data, setInitialContextDetail]);

  return (
    <Container>
      {loading ? (
        <FlexContainer
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%', height: '80px' }}
        >
          <CircleLoader thicknessInPx="4px" sizeInPx="36px" />
        </FlexContainer>
      ) : (
        data && <CustomSectionRecord record={data.getRecordDetail} />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: auto;
  background: ${({ theme }) => theme.colors.cFAFAFA};
`;
