import { Value } from '@pec-manager/graphql';
import {
  convertCompleteDateToEpochMillis,
  convertDateToEpochMillis,
  getDateAsString,
} from '../../../utils/dateUtils';

export const calcValueRecord = (value?: Value): string => {
  if (!value) {
    return '';
  }
  if (value.boolean) {
    return value.boolean.toString();
  }
  if (value.long) {
    return value.long.toString();
  }
  if (value.string) {
    return value.string;
  }
  if (value.date) {
    return getDateAsString(
      convertDateToEpochMillis(
        value.date.day,
        value.date.month,
        value.date.year
      )
    );
  }
  if (value.dateTime) {
    return getDateAsString(
      convertCompleteDateToEpochMillis(
        value.dateTime.date.day,
        value.dateTime.date.month,
        value.dateTime.date.year,
        value.dateTime.hour,
        value.dateTime.minutes,
        value.dateTime.seconds,
        value.dateTime.millis
      )
    );
  }
  if (value.decimal) {
    return value.decimal.decimal;
  }
  if (value.user) {
    return value.user.fullName || '';
  }
  if (value.group) {
    return value.group.name;
  }
  if (value.device) {
    return `(${value.device.serial}) ${value.device.name || ''}`;
  }
  if (value.int) {
    return value.int.toString();
  }
  return '';
};

export const calcInitialContextForRecord = (
  value?: Value
): string | string[] | any => {
  if (!value) {
    return '';
  }
  if (value.boolean) {
    return value.boolean.toString();
  }
  if (value.long) {
    return value.long.toString();
  }
  if (value.string) {
    return value.string;
  }
  if (value.int) {
    return value.int.toString();
  }
  if (value.date) {
    return convertDateToEpochMillis(
      value.date.day,
      value.date.month,
      value.date.year
    ).toString();
  }
  if (value.dateTime) {
    return convertCompleteDateToEpochMillis(
      value.dateTime.date.day,
      value.dateTime.date.month,
      value.dateTime.date.year,
      value.dateTime.hour,
      value.dateTime.millis,
      value.dateTime.minutes,
      value.dateTime.seconds
    ).toString();
  }
  if (value.decimal) {
    return value.decimal.decimal;
  }
  if (value.user) {
    return value.user.fullName || '';
  }
  if (value.group) {
    return value.group.name;
  }
  if (value.device) {
    return `(${value.device.serial}) ${value.device.name || ''}`;
  }
  if (value.complex) {
    const dataComplex = value.complex.nested?.map((v) => ({
      [v.name]: calcInitialContextForRecord(v.value),
    }));

    let combinedObject: any = {};

    // eslint-disable-next-line array-callback-return
    dataComplex?.map((obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      combinedObject[key] = [value];
    });

    return combinedObject;
  }
  if (value.list) {
    const returnList: any[] = [];
    // eslint-disable-next-line array-callback-return
    value.list.map((v) => {
      returnList.push(calcInitialContextForRecord(v));
    });

    return returnList.length > 0 ? returnList : '';
  }
  if (value.int) {
    return value.int.toString();
  }
  return '';
};
