import React from 'react';
import styled from 'styled-components';
import {
  FirstItem,
  ItemBig,
  ItemSmall,
  LastItem,
  StartInfo,
  UsersItem,
} from './index';
import { ItemMenuChoices } from '../document';
import { Text } from '../../components/common/Text';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { KeycloakService } from '../../services/KeycloakService';

export const TopDetailItemList = () => (
  <Container alignItems="center">
    <FirstItem>
      <Text
        text="process"
        fontSize="14px"
        lineHeight="40px"
        style={{ textTransform: 'uppercase' }}
      />
    </FirstItem>
    <ItemBig>
      <Text
        text="description"
        fontSize="14px"
        lineHeight="40px"
        style={{ textTransform: 'uppercase' }}
      />
    </ItemBig>
    <StartInfo>
      <Text
        text="startInfo"
        fontSize="14px"
        lineHeight="40px"
        style={{ textTransform: 'uppercase' }}
      />
    </StartInfo>
    <UsersItem>
      <Text
        text="users"
        fontSize="14px"
        lineHeight="40px"
        style={{ textTransform: 'uppercase' }}
      />
    </UsersItem>
    {!KeycloakService.removeFieldInTask().includes('dueDate') && (
      <ItemSmall>
        <Text
          text="dueDate"
          fontSize="14px"
          lineHeight="40px"
          style={{ textTransform: 'uppercase' }}
        />
      </ItemSmall>
    )}
    {!KeycloakService.removeFieldInTask().includes('priority') && (
      <LastItem>
        <Text
          text="priority"
          fontSize="14px"
          lineHeight="40px"
          style={{ textTransform: 'uppercase' }}
        />
      </LastItem>
    )}
    <ItemMenuChoices />
  </Container>
);

const Container = styled(FlexContainer)`
  width: calc(100% - 20px);
  height: 40px;
  background-color: ${({ theme }) => theme.colors.cF0F0F0};

  & > div:first-child {
    padding-left: 20px;
    margin-right: 6px;
  }

  & > div:not(:first-child) {
    padding-left: 16px;
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    height: 100%;
    align-items: center;
  }
`;
