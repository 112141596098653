import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Text } from '../common/Text';
import { Theme } from '../../theme';

interface DisplayInfoProps {
  description: string;
  style?: React.CSSProperties;
  styleDescription?: React.CSSProperties;
  refContainer?: React.RefObject<HTMLDivElement>;
}

export const DisplayInfo: FC<DisplayInfoProps> = ({
  description,
  style,
  styleDescription,
  refContainer,
}) => {
  const [bounds, setBounds] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    if (refContainer?.current) {
      setBounds((refContainer as any).current?.getBoundingClientRect().x);
    }
  }, [refContainer]);

  return (
    <Container style={style}>
      <Text
        text="i"
        skipTranslation
        fontSize="10px"
        lineHeight="8px"
        color={Theme.colors.c7A869A}
      />
      <ContainerDescription
        style={styleDescription}
        rightBounds={(bounds || 0) > 750}
      >
        <Text
          text={description}
          fontSize="10px"
          lineHeight="12px"
          color={Theme.colors.c505050}
        />
      </ContainerDescription>
    </Container>
  );
};

const Container = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.c7A869A};
  border-radius: 50%;
  position: relative;

  &:hover {
    div {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
      z-index: 1;
    }
  }
`;

const ContainerDescription = styled.div<{ rightBounds?: boolean }>`
  position: absolute;
  top: 0;
  ${({ rightBounds }) => (rightBounds ? 'right: 15px;' : 'left: 15px;')}
  padding: 8px;
  background: ${({ theme }) => theme.colors.cFCFCFC};
  border: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  border-radius: 4px;
  min-width: 200px;
  min-height: 80px;
  z-index: -1;
  opacity: 0;

  &:hover {
    opacity: 0 !important;
    z-index: -1 !important;
  }
`;
