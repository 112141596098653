import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { DonutChart } from '@pec-manager/graphql';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toPng } from 'html-to-image';
import { SvgIcon } from '../../components/common/SvgIcon';
import { HamburgerMenu } from '../../svg';

interface DonutChartWidgetProps extends DonutChart {
  column: boolean;
}

export const DonutChartWidget: FunctionComponent<DonutChartWidgetProps> = ({
  value,
  labels,
  legend,
  series,
  colors,
  label,
  column,
}) => {
  const ref = useRef(null);

  const htmlToImageConvert = useCallback(() => {
    if (ref) {
      toPng((ref as any).current, {
        cacheBust: false,
        filter: (node) => {
          const exclusionClasses = ['apexcharts-legend-series', 'remove'];
          return !exclusionClasses.some((classname) =>
            node.classList?.contains(classname)
          );
        },
      })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'donut-chart.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ref]);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((ref.current as any)?.offsetWidth);

    const getWidth = () => {
      setWidth((ref.current as any)?.offsetWidth);
    };
    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, [ref]);

  const options: ApexCharts.ApexOptions = {
    labels,
    dataLabels: {
      // @ts-ignore
      formatter(val, opts) {
        //const name = opts.w.globals.labels[opts.seriesIndex].split(':')[1];
        return [
          // @ts-ignore
          `${val.toFixed(1)}%`,
          Intl.NumberFormat().format(opts.w.globals.series[opts.seriesIndex]),
        ];
      },
      dropShadow: {
        opacity: 0,
      },
      style: {
        fontSize: '20px',
        colors: ['#000'],
      },
    },
    tooltip: {
      y: {
        formatter(val: number) {
          return Intl.NumberFormat().format(val);
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
        },
      },
    },
    legend: {
      position: 'bottom',
      show: legend,
      horizontalAlign: 'center',
      fontSize: '16px',
      formatter: function (val, opts) {
        return `${val} - ${Intl.NumberFormat().format(
          opts.w.globals.series[opts.seriesIndex]
        )}`;
      },
    },
    responsive: [
      {
        breakpoint: 1700,
        options: {
          chart: {
            width: 500,
            height: column ? 300 : 500,
          },
        },
      },
      {
        breakpoint: 1350,
        options: {
          chart: {
            width: 500,
            height: 500,
          },
        },
      },
    ],
    colors,
  };

  return (
    <Container
      column
      alignItems="center"
      style={{ marginBottom: '24px' }}
      ref={ref}
    >
      <SvgContainer className="remove" onClick={htmlToImageConvert}>
        <SvgIcon svg={<HamburgerMenu />} color="#6e8192" />
      </SvgContainer>
      <TextContainer column alignItems="center">
        {value && (
          <Text
            text={value}
            fontSize="16px"
            lineHeight="30px"
            color={Theme.colors.c505050}
            fontWeight="bold"
          />
        )}
        {label && (
          <Text
            text={label}
            fontSize="15px"
            lineHeight="20px"
            color={Theme.colors.c505050}
            fontWeight="300"
          />
        )}
      </TextContainer>
      <Chart
        options={options}
        series={series}
        type="donut"
        width={column ? 400 : width - 350 < 500 ? 600 : width - 350}
        height={column ? 400 : width - 350 < 500 ? 600 : width - 350}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  background: ${Theme.colors.cFFFFFF};
  .apexcharts-legend {
    max-height: 150px;
    overflow: auto;
    .apexcharts-legend-series {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const SvgContainer = styled(FlexContainer)`
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const TextContainer = styled(FlexContainer)`
  position: absolute;
  top: 35%;
  max-width: 150px;
  text-align: center;
`;
