import {
  BaseExecution,
  ExecutionContext,
  ExecutionStatus,
  ExtensionPoint,
  ResourcePriority,
} from '@pec-manager/graphql';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Arrow, Calendar, More, ProcessIcon } from '../../svg';
import { Theme } from '../../theme';
import { StateExecution } from './StateExecution';
import { MenuChoices } from '../../components/PagedFormDefinition/MenuChoices';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { SubExecutionItem } from './SubExecutionItem';
import { useNavigate } from 'react-router-dom';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import { Tag } from '../../components/tag';
import { mappingLocaleForDatePicker } from '../../utils/locale';
import { KeycloakService } from '../../services/KeycloakService';
import { ContainerClear, PrioritySelect } from '../TaskCalendar/EventItem';
import DatePicker from 'react-datepicker';
import {
  CHANGE_EXECUTION_DUE_DATE,
  CHANGE_EXECUTION_PRIORITY,
} from '../../graphql/execution/mutations';
import { useTranslation } from 'react-i18next';
import { Choice, Select } from '../../components/select';
import { SubTaskItem } from './SubTaskItem';
import { HeaderDataPicker } from '../../components/common/HeaderDataPicker';
import { ExecutionItemCard } from './ExecutionItemCard';
import { DynamicForm } from '../../components/DynamicForm';

/* eslint-disable react/no-children-prop */

interface ExecutionItemProps {
  execution: BaseExecution;
  openMenu: string | undefined;
  setOpenMenu: (id: string | undefined) => void;
  openExecution: string | undefined;
  setOpenExecution: (id: string | undefined) => void;
}

export const ExecutionItem: FunctionComponent<ExecutionItemProps> = ({
  execution,
  setOpenMenu,
  openMenu,
  setOpenExecution,
  openExecution,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showForm, setShowForm] = useState<ExtensionPoint | undefined>(
    undefined
  );

  const choicesMenu = useMemo(() => {
    let choices = [
      {
        text: 'detail',
        onClick: () => {
          navigate(`?executionId=${execution.id}`);
        },
      },
    ];

    if (execution.extPoints?.length > 0) {
      // eslint-disable-next-line array-callback-return
      execution.extPoints.map((extPoint) => {
        choices.push({
          text: extPoint.value,
          onClick: () => {
            setShowForm({ value: extPoint.value, key: extPoint.key });
          },
        });
      });
    }

    return choices;
  }, [execution.extPoints, execution.id, navigate]);

  const [changeExecutionDueDate] = useMutationHook({
    mutationGql: CHANGE_EXECUTION_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeExecutionPriority] = useMutationHook({
    mutationGql: CHANGE_EXECUTION_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [date, setDate] = useState(execution.dueDate);
  useEffect(() => {
    if (execution && execution.dueDate && execution.dueDate !== date) {
      setDate(execution.dueDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execution]);

  const dateNow =
    getDateAsString(Date.now()) ===
    getDateAsString(
      execution.status === ExecutionStatus.CANCELED
        ? execution.cancelledAt
        : (execution.createdAt as any)
    );

  const hasChildren = useMemo(
    () => !!(execution.subExecutions.some((s) => s.task) || execution.task),
    [execution]
  );

  return (
    <ItemContainer column>
      <ContainerItemList alignItems="center">
        <FirstItem
          onClick={(e) => {
            navigate(`?executionId=${execution.id}`);
          }}
          style={{ cursor: 'pointer' }}
        >
          {hasChildren && (
            <IconContainer
              rotateFactor={openExecution === execution.id}
              onClick={(e) => {
                e.stopPropagation();
                setOpenExecution(
                  openExecution === execution.id ? undefined : execution.id
                );
              }}
            >
              {hasChildren && (
                <SvgIcon
                  svg={<Arrow />}
                  height="15px"
                  color={Theme.colors.c505050}
                />
              )}
            </IconContainer>
          )}
          {!hasChildren && <NoneContainer />}
          <TitleContainer column justifyContent="space-between">
            <FlexContainer>
              <SvgIcon
                svg={<ProcessIcon />}
                height="20px"
                width="20px"
                color={Theme.colors.c2F80ED}
              />
              <Text
                text={execution.name}
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                ellipsis={true}
                style={{ marginBottom: '8px', marginLeft: '10px' }}
              />
            </FlexContainer>
            <Tag
              text={execution.processName}
              colorBackground={execution.processName}
            />
          </TitleContainer>
        </FirstItem>
        <ItemMedium justifyContent="space-around" column>
          <Text
            text={dateNow ? 'todayAt' : 'completeDate'}
            translationArgs={{
              date: getDateAsString(
                execution.status === ExecutionStatus.CANCELED
                  ? execution.cancelledAt
                  : (execution.createdAt as any)
              ),
              time: getTimeAsString(
                execution.status === ExecutionStatus.CANCELED
                  ? execution.cancelledAt
                  : (execution.createdAt as any)
              ),
              fresh: true,
            }}
            fontSize="12px"
            lineHeight="18px"
            fontWeight="300"
            color={Theme.colors.c505050}
          />
          <Text
            text={
              execution.status === ExecutionStatus.CANCELED &&
              execution.cancelledBy
                ? 'executionCancelledBy'
                : `by ${execution.startedBy!.firstName} ${
                    execution.startedBy!.lastName
                  }`
            }
            fontSize="12px"
            translationArgs={{
              name: `${execution.cancelledBy?.firstName} ${execution.cancelledBy?.lastName}`,
            }}
            lineHeight="20px"
            color={Theme.colors.c505050}
            style={{ marginRight: '8px' }}
          />
        </ItemMedium>
        {!KeycloakService.removeFieldInExecution().includes('dueDate') && (
          <ItemSmall>
            <DatePickerStyled
              placeholderText={t('insertDate') as string}
              disabled={
                execution.status !== ExecutionStatus.RUNNING ||
                !execution.isManageable
              }
              locale={
                mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
              }
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <HeaderDataPicker
                  date={date}
                  decreaseMonth={decreaseMonth}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
              dateFormat={
                mappingLocaleForDatePicker(
                  KeycloakService.getLocaleForDate()?.toLowerCase() ||
                    KeycloakService.getLocale()
                ).formatDate
              }
              onChange={(date) => {
                changeExecutionDueDate({
                  variables: {
                    executionId: execution.id,
                    newDueDate: new Date(date as any).getTime(),
                  },
                }).then(() => {
                  setDate(date as any);
                });
              }}
              children={
                <ContainerClear
                  onClick={() => {
                    changeExecutionDueDate({
                      variables: {
                        executionId: execution.id,
                        newDueDate: undefined,
                      },
                    }).then(() => {
                      setDate(undefined);
                    });
                  }}
                >
                  <Text
                    text={t('clearDueDate')}
                    fontSize="12px"
                    lineHeight="32px"
                    fontWeight="300"
                    color={Theme.colors.cD73E18}
                  />
                </ContainerClear>
              }
              value={date as string | undefined}
              selected={date}
              showTimeSelect={false}
              timeFormat="HH:mm"
            />
            <SvgIcon
              svg={<Calendar />}
              color={Theme.colors.c505050}
              width="18px"
              style={{ marginLeft: '-26px', cursor: 'default' }}
            />
          </ItemSmall>
        )}
        {!KeycloakService.removeFieldInExecution().includes('priority') && (
          <SelectItem>
            <Select
              colorText={Theme.colors.c838383}
              fontSizeText="12px"
              lineHeightText="18px"
              choices={PrioritySelect.filter(
                (choice) => choice.value !== ResourcePriority.NONE
              )}
              defaultChoice={
                PrioritySelect.filter((s) => s.value === execution.priority)[0]
              }
              disabled={
                execution.status !== ExecutionStatus.RUNNING ||
                !execution.isManageable
              }
              onChoiceSelected={(choice: Choice) => {
                changeExecutionPriority({
                  variables: {
                    executionId: execution.id,
                    newPriority: choice.value as ResourcePriority,
                  },
                });
              }}
              height="32px"
              backgroundColor={Theme.colors.cFAFAFA}
              noDisplayValue
            />
          </SelectItem>
        )}
        <ItemState justifyContent="space-around" column>
          <StateExecution
            state={execution.status}
            colorText={Theme.colors.c505050}
            fontSize={14}
            sizeCircle="18px"
          />
        </ItemState>
        <ItemMenuChoices onClick={(e) => e.stopPropagation()}>
          <SvgClickable
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenu(execution.id === openMenu ? undefined : execution.id);
            }}
          >
            <SvgIcon
              svg={<More />}
              color={Theme.colors.c2186C6}
              height="20px"
              width="20px"
            />
            {openMenu === execution.id && (
              <MenuChoices
                containerMenuProps={{ right: '-10px', top: '36px' }}
                choices={choicesMenu}
                onClickOutside={() => setOpenMenu(undefined)}
              />
            )}
          </SvgClickable>
        </ItemMenuChoices>
      </ContainerItemList>
      <ExecutionItemCard execution={execution} />
      {openExecution === execution.id && execution.task && (
        <FlexContainer alignItems="center">
          <SubTaskItem task={execution.task} key={execution.task.id} />
        </FlexContainer>
      )}
      {openExecution === execution.id &&
        execution.subExecutions.map((sub) => {
          if (sub.task) {
            return (
              <FlexContainer alignItems="center">
                <SubTaskItem task={sub.task} key={sub.task.id} />
              </FlexContainer>
            );
          }
          return null;
        })}
      {openExecution === execution.id &&
        execution.subExecutions.map((sub) => {
          if (sub.status !== ExecutionStatus.RUNNING) {
            return (
              <FlexContainer alignItems="center">
                <SubExecutionItem subExecution={sub} key={sub.id} />
              </FlexContainer>
            );
          }
          return null;
        })}
      {showForm && (
        <DynamicForm
          executionContext={ExecutionContext.START}
          columnsForm={1}
          externalPoint={JSON.stringify(showForm)}
          processId={execution.id}
          onCancel={() => {
            setShowForm(undefined);
          }}
          onSuccess={() => {
            setShowForm(undefined);
          }}
        />
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled(FlexContainer)<{
  selected?: boolean;
}>`
  width: calc(100% - 48px);
  padding: 10px 0 5px 20px;
  margin: 8px 0 0 20px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFFFFFF};
  box-shadow: 0 3px 5px rgb(9 30 66 / 10%), 0 0 1px rgb(9 30 66 / 31%);

  @media (max-width: 990px) {
    padding: 0;
    border-radius: 8px;
  }
`;

const FirstItem = styled.div<{ hasChildren?: boolean }>`
  flex: 3 1 0%;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const ItemMedium = styled(FlexContainer)`
  height: 100%;
  min-height: 38px;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 19%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ItemState = styled(FlexContainer)`
  height: 100%;
  min-height: 38px;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 7%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ContainerItemList = styled(FlexContainer)`
  @media (max-width: 990px) {
    display: none;
  }
`;

const ItemSmall = styled(FlexContainer)`
  height: 100%;
  min-height: 38px;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  @media (max-width: 1300px) {
    width: 16%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const SelectItem = styled(FlexContainer)`
  display: flex;
  align-items: center;
  min-height: 38px;
  height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 16%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const IconContainer = styled(FlexContainer)<{ rotateFactor: boolean }>`
  cursor: pointer;
  width: 15px;
  min-width: 15px;
  transition: 250ms transform ease-in-out;
  transform: rotate(
    ${({ rotateFactor }) => (rotateFactor ? `360deg` : `270deg`)}
  );
  margin-right: 5px;
`;

const NoneContainer = styled(FlexContainer)`
  width: 15px;
  margin-right: 7px;
`;

const TitleContainer = styled(FlexContainer)`
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding-right: 16px;
  flex-direction: column;
`;

const SvgClickable = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  & > div {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ItemMenuChoices = styled.div`
  height: 40px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding: 0 !important;
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 100%;
  overflow: auto;
  width: calc(100% - 4px);
  text-overflow: ellipsis;
  padding-right: 30px;
`;
