import { ResourceState } from '@pec-manager/graphql/lib/graphql.types';

export const ACTIVITIES_PAGE_SIZE = 100;
export const COMMENTS_PAGE_SIZE = 100;
export const EXECUTIONS_PAGE_SIZE = 25;
export const REPLIES_PAGE_SIZE = 1;
export const MAILS_PAGE_SIZE = 100;
export const TASK_PAGE_SIZE = 25;
export const mailStateKeys = Object.keys(ResourceState);
export const taskStateKeys = Object.keys(ResourceState);
