import React, { SetStateAction, Dispatch } from 'react';
import { ResourceActionType } from '@pec-manager/graphql';
import { ModalTypeInbox } from './index';
import { Chat, Spam, Reassign } from '../../svg';

interface ReturnType {
  text: string;
  icon?: JSX.Element;
  onClick: () => void;
}

// eslint-disable-next-line consistent-return
export const ActionMapping = (
  type: ResourceActionType,
  setModalType: Dispatch<SetStateAction<ModalTypeInbox | undefined>>,
  takeInChargeCallback: () => void,
  openChatCallback: () => void
): ReturnType | null => {
  switch (type) {
    case ResourceActionType.MARK_AS_SPAM:
      return {
        text: 'reportAsSpam',
        icon: <Spam />,
        onClick: () => setModalType(ModalTypeInbox.SPAM),
      };
    case ResourceActionType.TAKE_IN_CHARGE:
      return {
        text: 'takeCharge',
        onClick: () => {
          takeInChargeCallback();
        },
      };
    case ResourceActionType.REASSIGN:
      return {
        text: 'reassign',
        icon: <Reassign />,
        onClick: () => setModalType(ModalTypeInbox.REASSIGN),
      };
    case ResourceActionType.START_CHAT:
      return {
        text: 'startChat',
        icon: <Chat />,
        onClick: () => openChatCallback(),
      };
  }
  return null;
};
