import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { KpiContainerHeader } from './KpiContainerHeader';
import { KpiContainerHeaderMobile } from './KpiContainerHeaderMobile';
import { KpiRow } from '@pec-manager/graphql';

export interface KpiContainerProps {
  setKpiRow: (kpiRow?: KpiRow[]) => void;
  notMobile?: boolean;
  setHeaderHeight?: (height: number) => void;
  children?: React.ReactNode;
}

export const KpiContainer: FunctionComponent<KpiContainerProps> = ({
  setKpiRow,
  notMobile,
  children,
}) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <BackgroundBorderTop />
      <Container column>
        {notMobile ? (
          <KpiContainerHeader setKpiRow={setKpiRow} />
        ) : (
          <KpiContainerHeaderMobile
            setKpiRow={setKpiRow}
            setHeaderHeight={setHeaderHeight}
          />
        )}
        <ChildrenContainer paddingBottom={headerHeight}>
          {children}
        </ChildrenContainer>
      </Container>
    </div>
  );
};

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 6px 0 0 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @media (max-width: 990px) {
    border-radius: 0;
  }
`;

const ChildrenContainer = styled(FlexContainer)<{ paddingBottom?: number }>`
  height: calc(100vh - 156px);
  width: calc(100vw - 250px);

  @media (max-width: 1039px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 990px) {
    height: calc(100vh);
    width: 100%;
    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${paddingBottom + 40}px;`}
  }
`;

const BackgroundBorderTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.c212832};
`;
