import React, { FunctionComponent, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { CountKpi } from '@pec-manager/graphql';
import { FlexContainer } from '../layout/FlexContainer';
import { Text } from './Text';
import { Theme } from '../../theme';
import styled from 'styled-components';

interface PieChartProps {
  samples: CountKpi[];
  width: number | string;
  title?: string;
  count: number;
  dailyRate: number;
}

export const PieChart: FunctionComponent<PieChartProps> = ({
  samples,
  width,
  title,
  count,
  dailyRate,
}) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: samples.map((sample) => `${sample.name}: ${sample.value}`),
    dataLabels: {
      // @ts-ignore
      formatter(val, opts) {
        //const name = opts.w.globals.labels[opts.seriesIndex].split(':')[1];
        // @ts-ignore
        return [val.toFixed(1) + '%'];
      },
    },
    legend: {
      position: 'bottom',
      show: true,
      horizontalAlign: 'left',
      height: 60,
    },
  };
  const series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined =
    useMemo(() => {
      return samples.map((sample) => sample.value);
    }, [samples]);
  return (
    <Container column alignItems="center" style={{ marginBottom: '24px' }}>
      {title && (
        <Text
          text={title}
          fontSize="20px"
          lineHeight="30px"
          color={Theme.colors.c505050}
          fontWeight="300"
          style={{ marginBottom: '30px' }}
        />
      )}
      <Chart
        options={options}
        series={series}
        type="donut"
        width={width}
        height={400}
      />
      {count && (
        <Text
          text={`Total completed: ${count}`}
          fontSize="28px"
          lineHeight="34px"
          fontWeight="600"
          style={{ marginTop: '20px' }}
        />
      )}
      <Text
        text={`Daily frequency: ${dailyRate}`}
        fontSize="18px"
        lineHeight="30px"
        color={Theme.colors.c505050}
        fontWeight="300"
        style={{ marginTop: '10px' }}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  .apexcharts-legend {
    max-height: 150px;
    overflow: auto;
    .apexcharts-legend-series {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
