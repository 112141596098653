import React from 'react';
import { ResourceAssignmentType, ResourceState } from '@pec-manager/graphql';
import {
  Success,
  KO,
  Disk,
  Analytics,
  Task,
  ProcessIcon,
  Table,
} from '../../svg';
import { SintropiSidebarSectionElement } from './components/SintropiSidebarSection';

export enum SintropiSidebarMainSectionElementId {
  SINTROPI = 'SINTROPI',
  DOCUMENT = 'DOCUMENT',
}

export function getSintropiSidebarMainSectionElements(
  analytics: boolean,
  hasBusinessKpi?: boolean,
  hasRecords?: boolean
): SintropiSidebarSectionElement[] {
  const elements: SintropiSidebarSectionElement[] = [
    {
      svgIcon: { icon: <ProcessIcon /> },
      id: ResourceState.IN_PROGRESS,
      name: 'processes',
    },
    {
      dropDownItems: [
        {
          id: ResourceAssignmentType.DEFAULT,
          value: 'assignedTasks',
        },
        {
          id: ResourceAssignmentType.GROUP,
          value: 'groupTasks',
        },
        {
          id: ResourceAssignmentType.TO_OTHERS,
          value: 'taskAssignedToOthers',
        },
      ],
      svgIcon: { icon: <Task /> },
      id: SintropiSidebarMainSectionElementId.SINTROPI,
      name: 'tasks',
    },
    {
      svgIcon: { icon: <KO /> },
      id: ResourceState.EXPIRED,
      name: 'expired',
    },
    {
      svgIcon: { icon: <Success /> },
      id: ResourceState.WORKED,
      name: 'worked',
    },
    {
      name: 'businessKpi',
      id: 'BUSINESS_KPI',
      svgIcon: { icon: <Analytics /> },
    },
    {
      name: 'analytics',
      id: 'ANALYTICS',
      svgIcon: { icon: <Analytics /> },
    },
    {
      name: 'record',
      id: 'RECORD',
      svgIcon: { icon: <Table /> },
    },
    {
      svgIcon: { icon: <Disk /> },
      id: SintropiSidebarMainSectionElementId.DOCUMENT,
      name: 'archive',
    },
  ];

  if (!analytics) {
    const removeIndex = elements.findIndex(
      (element) => element.id === 'ANALYTICS'
    );
    elements.splice(removeIndex, 1);
  }

  if (!hasBusinessKpi) {
    const removeIndex = elements.findIndex(
      (element) => element.id === 'BUSINESS_KPI'
    );
    elements.splice(removeIndex, 1);
  }
  if (!hasRecords) {
    const removeIndex = elements.findIndex(
      (element) => element.id === 'RECORD'
    );
    elements.splice(removeIndex, 1);
  }

  return elements;
}
