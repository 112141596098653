import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormFieldDefinition } from '@pec-manager/graphql';
import styled from 'styled-components';
import { FormFieldContainer } from './FormFieldContainer';
import { Text } from '../../common/Text';
import { Theme } from '../../../theme';
import { extractDefaultValuesAsStrings } from '../../../utils/stringUtils';
import { boundaryError } from './utils/errorMessage';

interface RadioButtonSelectProps {
  formField: FormFieldDefinition;
  fillField: (
    fieldId: string,
    data: string[],
    refetchDefinition?: boolean
  ) => void;
  errorMessage?: string;
  checkbox?: boolean;
  onChangeValue?: () => void;
  checkRequired?: boolean;
  columnForm?: number;
}

export const RadioButtonSelect: FunctionComponent<RadioButtonSelectProps> = ({
  formField,
  fillField,
  errorMessage,
  checkbox,
  onChangeValue,
  checkRequired,
  columnForm,
}) => {
  const [choiceIds, setChoiceIds] = useState<string[]>([]);
  const [firstOnClick, setFirstOnClick] = useState(checkRequired);
  const [errorMessageDate, setErrorMessageDate] = useState<string | undefined>(
    errorMessage
  );

  useEffect(() => {
    if (formField.defaultValues.length > 0) {
      setChoiceIds(extractDefaultValuesAsStrings(formField.defaultValues));
    }
  }, [formField]);

  useEffect(() => {
    setFirstOnClick(checkRequired);
  }, [checkRequired]);

  const setAllData = (choiceId: string) => {
    let newState = [];

    if (checkbox) {
      const filteredIds = choiceIds.filter((c) => c !== choiceId);
      newState =
        filteredIds.length === choiceIds.length
          ? [...choiceIds, choiceId]
          : filteredIds;
    } else {
      newState = [choiceId];
    }

    setChoiceIds(newState);
    fillField(formField.id, newState, formField.isDynamic);
  };

  return (
    <FormFieldContainer
      formField={formField}
      errorMessage={
        firstOnClick && formField.isEditable
          ? boundaryError(
              choiceIds.length,
              formField.cardinalityBounds?.left,
              formField.cardinalityBounds?.right
            ) || errorMessageDate
          : undefined
      }
      columnForm={columnForm}
    >
      <ContainerRadioButton>
        {formField.kind?.genericSelect?.choices.map(({ id, displayValue }) => (
          <ContainerInput key={formField.id + '_' + id}>
            <input
              disabled={!formField.isEditable}
              onClick={() => {
                setAllData(id);
                setFirstOnClick(true);
                setErrorMessageDate(undefined);
                onChangeValue && onChangeValue();
              }}
              type={checkbox ? 'checkbox' : 'radio'}
              onChange={() => {}}
              checked={choiceIds.some((v) => v === id)}
              value={id}
              name={formField.id + '_' + id}
            />
            <Text
              text={displayValue}
              fontSize="14px"
              lineHeight="14px"
              fontWeight="300"
              color={Theme.colors.c505050}
              skipTranslation
            />
          </ContainerInput>
        ))}
      </ContainerRadioButton>
    </FormFieldContainer>
  );
};

const ContainerRadioButton = styled.div`
  height: 51px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;

const ContainerInput = styled.div`
  width: fit-content;

  & > input {
    margin-right: 5px;
  }
`;
