import { useDispatch, useSelector } from 'react-redux';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { Sort, TaskSummary } from '@pec-manager/graphql';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  GET_TASKS_BY_ASSIGNMENT_TYPE,
  GET_TASKS_BY_SAVED_SEARCH_ID,
  GET_TASKS_BY_STATE,
  GET_TASKS_IN_INBOX,
} from '../../graphql/task/queries';
import { gqlName } from '../../graphql/utils';
import { TASK_PAGE_SIZE, taskStateKeys } from '../../constant';
import { SintropiSidebarDefaultSectionId } from '../../sintropi/sidebar/sintropiSidebarDefaultSectionId';
import { SintropiSidebarMainSectionElementId } from '../../sintropi/sidebar/sintropiSidebarDefaultSectionElements';
import { clearTaskList, appendTasks } from '../../redux/Sintropi/task/actions';
import { taskStateSelector } from '../../redux/Sintropi/task/selectors';
import { nothing } from '../../utils/funcUtils';

function computeGqlVariablesWithOffset(
  gqlVariables: any,
  offset: number,
  sorts?: Sort[],
  fullSearchText?: string
) {
  return {
    ...gqlVariables,
    pagination: {
      offset,
      limit: TASK_PAGE_SIZE,
    },
    sorts,
    fullSearchText: fullSearchText,
  };
}
export const useTasksQuery: (
  sorts?: Sort[],
  fullSearchText?: string
) => {
  fetchMore: () => void;
  tasks: TaskSummary[];
  taskCount: number;
  error?: ApolloError;
  loading: boolean;
} = (sorts, fullSearchText) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const { appId } = useParams();
  const sectionId = searchParams.get('sectionId');
  const sectionElementId = searchParams.get('elementId');
  const sectionElementDropDownElementId = searchParams.get('dropDownId');

  const { tasks, taskCount, simpleSearchFilter } =
    useSelector(taskStateSelector);

  const dispatch = useDispatch();
  const [refresh, setRefresh] = React.useState(false);

  useEffect(() => {
    setRefresh(true);
  }, [
    sectionId,
    sectionElementId,
    sectionElementDropDownElementId,
    dispatch,
    simpleSearchFilter,
    sorts,
    fullSearchText,
  ]);

  const { gqlQuery, gqlQueryVariables } = useMemo(() => {
    let query: any | null = null;
    let queryVariables: any = {};

    switch (sectionId) {
      case SintropiSidebarDefaultSectionId.MAIN:
        switch (true) {
          case taskStateKeys.some((s) => s === sectionElementId):
            query = GET_TASKS_BY_STATE;
            queryVariables = {
              ...queryVariables,
              appId,
              taskState: sectionElementId,
              sorts,
              fullSearchText: fullSearchText,
            };
            break;
          case sectionElementId ===
            SintropiSidebarMainSectionElementId.SINTROPI:
            if (!!sectionElementDropDownElementId) {
              query = GET_TASKS_BY_ASSIGNMENT_TYPE;
              queryVariables = {
                ...queryVariables,
                appId,
                assignmentType: sectionElementDropDownElementId,
                sorts,
                fullSearchText: fullSearchText,
              };
            } else {
              query = GET_TASKS_IN_INBOX;
              queryVariables = {
                ...queryVariables,
                appId,
                sorts,
                fullSearchText: fullSearchText,
              };
            }
            break;
          default:
            break;
        }
        break;
      case SintropiSidebarDefaultSectionId.SAVED_SEARCHES:
        query = GET_TASKS_BY_SAVED_SEARCH_ID;
        queryVariables = {
          ...queryVariables,
          appId,
          savedSearchId: sectionElementId,
        };
        break;
    }
    return { gqlQuery: query, gqlQueryVariables: queryVariables };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectionId,
    sectionElementId,
    sectionElementDropDownElementId,
    simpleSearchFilter,
    sorts,
    fullSearchText,
  ]);
  useEffect(() => {
    if (!(gqlQuery && gqlQueryVariables)) {
      navigate(`/${appId}/sintropi?sectionId=MAIN&elementId=SINTROPI`);
    }
  }, [appId, gqlQuery, gqlQueryVariables, navigate]);

  const [loadQuery, { loading, error, data }] = useLazyQuery(
    gqlQuery || GET_TASKS_IN_INBOX,
    {
      fetchPolicy: 'network-only',
      variables: computeGqlVariablesWithOffset(
        gqlQueryVariables,
        0,
        sorts,
        fullSearchText
      ),
    }
  );

  useEffect(() => {
    if (data) {
      const { totalElements, pageElements } = data[gqlName(gqlQuery!)];
      dispatch(
        appendTasks({
          taskCount: totalElements,
          tasks: pageElements,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (refresh) {
      dispatch(clearTaskList());
      setRefresh(false);
    }
  }, [dispatch, refresh]);

  function loadTaskQuery() {
    loadQuery({
      variables: computeGqlVariablesWithOffset(
        gqlQueryVariables,
        0,
        sorts,
        fullSearchText
      ),
    }).then(nothing);
  }

  useEffect(() => {
    loadTaskQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!tasks) {
      loadTaskQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, sectionId, sectionElementId, sectionElementDropDownElementId]);

  useEffect(() => {
    if (sorts) {
      loadTaskQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorts]);

  return {
    fetchMore: () => {
      loadQuery({
        variables: computeGqlVariablesWithOffset(
          gqlQueryVariables,
          tasks?.length || 0,
          sorts,
          fullSearchText
        ),
      }).then(nothing);
    },
    tasks: tasks || [],
    taskCount,
    error,
    loading: loading || gqlQuery == null,
  };
};
