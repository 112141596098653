import { gql } from '@apollo/client/core';
import { gqlPagedFormDefinitionResolutionShape } from '../form/shapes';

export const SAVE_SEARCH = gql`
  mutation SaveSearch(
    $appId: ID!
    $filledForm: FilledForm!
    $searchName: String!
  ) {
    saveSearch(
      appId: $appId
      filledForm: $filledForm
      searchName: $searchName
    ) {
      id
    }
  }
`;

export const EDIT_SAVED_SEARCH = gql`
  mutation EditSavedSearch(
    $appId: ID!
    $searchId: ID!
    $filledForm: FilledForm!
  ) {
    editSavedSearch(
      appId: $appId
      searchId: $searchId
      filledForm: $filledForm
    ) {
      _
    }
  }
`;

export const RENAME_SAVED_SEARCH = gql`
  mutation RenameSavedSearch($appId: ID!, $searchId: ID!, $newName: String!) {
    renameSavedSearch(appId: $appId, searchId: $searchId, newName: $newName) {
      _
    }
  }
`;

export const DUPLICATE_SAVED_SEARCH = gql`
  mutation DuplicateSavedSearch(
    $appId: ID!
    $searchId: ID!
    $newName: String!
  ) {
    duplicateSavedSearch(
      appId: $appId
      searchId: $searchId
      newName: $newName
    ) {
      id
    }
  }
`;

export const DELETE_SAVED_SEARCH = gql`
  mutation DeleteSavedSearch($appId: ID!, $searchId: ID!) {
    deleteSavedSearch(appId: $appId, searchId: $searchId) {
      _
    }
  }
`;

export const ADVANCED_SEARCH_FORM_DEFINITION = gql`
    mutation AdvancedSearchFormDefinition($appId: ID!) {
        advancedSearchFormDefinition(appId: $appId) {
            ${gqlPagedFormDefinitionResolutionShape}
        }
    }
`;

export const ADVANCED_SEARCH_FORM_DEFINITION_DIFFS_ON_DYNAMIC_FIELD_CHANGE = gql`
  mutation AdvancedSearchFormDefinitionDiffsOnDynamicFieldChange($appId: ID!, $filledForm: FilledForm!) {
    advancedSearchFormDefinitionDiffsOnDynamicFieldChange(appId: $appId, filledForm: $filledForm) {
      ${gqlPagedFormDefinitionResolutionShape}
    }
  }
`;
