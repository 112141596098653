import React, { FC } from 'react';
import { TaskItemList } from '../../TaskItemList';
import { TopDetailItemList } from '../../TaskItemList/TopDetailItemList';
import { TaskSummary } from '@pec-manager/graphql';
import { Text } from '../../../components/common/Text';
import { grouptasksByExpiresAtByMonth } from '../utils';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { transformNumberInMonth } from '../../../utils/dateUtils';
import { SortType } from '../Tasks';
import { ViewItemEnum } from '../TaskContainer';
import { TaskItemCard } from '../../TaskItemCard';

interface TaskListProps {
  tasks: TaskSummary[];
  sort: SortType[];
  setSort: React.Dispatch<React.SetStateAction<SortType[]>>;
  view: ViewItemEnum;
}
function sortTaskSummaryByDueDate(a: TaskSummary, b: TaskSummary) {
  return (
    new Date(a.base.expiresAt as any).getTime() -
    new Date(b.base.expiresAt as any).getTime()
  );
}

export const TaskListView: FC<TaskListProps> = ({ tasks, sort, view }) => (
  <>
    {sort[0].label === 'sortStartDate' && (
      <>
        {view === ViewItemEnum.LIST && <TopDetailItemList />}
        {tasks.map((task, i) =>
          view === ViewItemEnum.LIST ? (
            <TaskItemList task={task} key={task.base.id} index={i} />
          ) : (
            <TaskItemCard task={task} key={task.base.id} index={i} />
          )
        )}
      </>
    )}
    {sort[0].label === 'groupByDueDate' &&
      grouptasksByExpiresAtByMonth(tasks).map(({ month, tasks }) => (
        <FlexContainer
          column={view === ViewItemEnum.LIST}
          style={{ width: '100%' }}
          key={month}
          wrapContent={view === ViewItemEnum.CARD}
        >
          <Text
            text={transformNumberInMonth(month)}
            fontSize="16px"
            lineHeight="21px"
            translationArgs={{
              year: month.split('-')[1],
            }}
            fontWeight="600"
            style={{ margin: '16px 8px', width: '100%' }}
          />
          {view === ViewItemEnum.LIST && <TopDetailItemList />}
          {tasks
            .sort(sortTaskSummaryByDueDate)
            .map((task, i) =>
              view === ViewItemEnum.LIST ? (
                <TaskItemList task={task} key={task.base.id} index={i} />
              ) : (
                <TaskItemCard task={task} key={task.base.id} index={i} />
              )
            )}
        </FlexContainer>
      ))}
  </>
);
