import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Application, ApplicationOverview } from '@pec-manager/graphql';
import { Text } from './Text';
import { MenuChoices } from '../PagedFormDefinition/MenuChoices';
import { SvgIcon } from './SvgIcon';
import { Arrow } from '../../svg';
import { FlexContainer } from '../layout/FlexContainer';
import { GET_APPLICATION_OVERVIEWS } from '../../graphql/application/queries';
import { HorizontalLoader } from '../loader/HorizontalLoader';
import { Theme } from '../../theme';

interface TitleWithChooseAppProps {
  exportApplication?: (application: Application) => void;
}

export const TitleWithChooseApp: FunctionComponent<TitleWithChooseAppProps> = ({
  exportApplication,
}) => {
  const navigate = useNavigate();
  const [openChangeAppMenu, setOpenChangeAppMenu] = useState(false);
  const { data, loading } = useQuery(GET_APPLICATION_OVERVIEWS);
  const { appId } = useParams();

  useEffect(() => {
    if (data && exportApplication) {
      exportApplication(
        data.getApplicationOverviews.find(
          (app: ApplicationOverview) => app?.application?.id === appId
        )?.application
      );
    }
  }, [appId, data, exportApplication]);

  const choicesMenu = useMemo(() => {
    if (data) {
      const getProcess = data.getApplicationOverviews
        .filter((a: ApplicationOverview) => a?.application?.id !== appId)
        .map((app: ApplicationOverview) => ({
          text: 'passToWithName',
          translationArgs: {
            name: app?.application?.name,
          },
          onClick: () => {
            navigate(
              `/${app?.application?.id.toLowerCase()}/${app?.application?.context.toLowerCase()}`
            );
            setOpenChangeAppMenu(false);
          },
        }));
      if (appId) {
        return [
          ...getProcess,
          {
            text: 'passToWithName',
            onClick: () => navigate('/'),
            translationArgs: {
              name: 'Dashboard',
            },
          },
        ];
      }
      return getProcess;
    }
    return [];
    // eslint-disable-next-line
  }, [data, appId]);

  return (
    <>
      <Container alignItems="center">
        {loading && !(appId && data) ? (
          <HorizontalLoader color={Theme.colors.c5B84EF} size="s" top="12px" />
        ) : (
          <Text
            text={
              data?.getApplicationOverviews?.filter(
                (app: ApplicationOverview) => app?.application?.id === appId
              )[0]?.application.name || 'Dashboard'
            }
            skipTranslation
            fontSize="14px"
            lineHeight="21px"
          />
        )}
        {openChangeAppMenu && data && (
          <MenuChoices
            containerMenuProps={{
              left: '50px',
              top: '40px',
              maxWidth: 'fit-content',
            }}
            choices={choicesMenu}
            onClickOutside={() => setOpenChangeAppMenu(false)}
          />
        )}
        {!loading && (
          <ArrowClickable
            rotateFactor={openChangeAppMenu}
            onClick={() => setOpenChangeAppMenu((prevState) => !prevState)}
          >
            <SvgIcon svg={<Arrow />} height="10px" />
          </ArrowClickable>
        )}
      </Container>
      <div />
    </>
  );
};

const ArrowClickable = styled(FlexContainer)<{ rotateFactor: boolean }>`
  margin-left: 8px;
  cursor: pointer;

  transition: 250ms transform ease-in-out;
  transform: rotate(${({ rotateFactor }) => (rotateFactor ? `180deg` : `0`)});
  @media (max-width: 990px) {
    display: none;
  }
`;

const Container = styled(FlexContainer)`
  margin-right: 16px;
`;
