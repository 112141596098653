import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';

interface MailItemLoaderProps {
  extended?: boolean;
}

const MailItemLoader: FunctionComponent<MailItemLoaderProps> = ({
  extended,
}) => {
  const calcPosition = (extendedValue: string, notExtendedValue: string) =>
    extended ? extendedValue : notExtendedValue;

  return (
    <ContainerMail>
      <ContentLoader
        speed={2}
        width="100%"
        height={184}
        backgroundColor="#dedede"
        foregroundColor="#ebebf9"
      >
        <rect x="15" y="15" rx="3" ry="3" width="55" height="17" />
        <rect x="15" y="157" rx="3" ry="3" width="74" height="10" />
        <rect x="15" y="101" rx="3" ry="3" width="85%" height="10" />
        <rect x="15" y="121" rx="3" ry="3" width="43" height="5" />
        <circle cx="110" cy="162" r="12" />
        <rect
          x={calcPosition('88%', '365')}
          y="15"
          rx="3"
          ry="3"
          width="68"
          height="16"
        />
        <rect x="62" y="121" rx="3" ry="3" width="43" height="5" />
        <rect x="15" y="65" rx="3" ry="3" width="208" height="12" />
        <rect
          x={calcPosition('88.5%', '366')}
          y="65"
          rx="3"
          ry="3"
          width="65"
          height="12"
        />
        <rect x="15" y="40" rx="3" ry="3" width="124" height="14" />
        <rect
          x={calcPosition('85%', '343')}
          y="154"
          rx="3"
          ry="3"
          width="90"
          height="16"
        />
      </ContentLoader>
    </ContainerMail>
  );
};

export default MailItemLoader;

const ContainerMail = styled(FlexContainer)<{ selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.cE2F3FF : theme.colors.cFFFFFF};
  max-width: 455px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 8px;
  @media (max-width: 1040px) {
    max-width: 100%;
  }
`;
