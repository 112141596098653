import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../common/SvgIcon';
import { Color, Theme } from '../../theme';
import { Text } from '../common/Text';
import { FlexContainer } from '../layout/FlexContainer';
import { Arrow } from '../../svg';

export interface Choice {
  svg?: JSX.Element;
  label?: string;
  value: any;
}

interface SelectProps {
  width?: string;
  height?: string;
  choices: Choice[];
  placeholder?: string;
  defaultChoice?: Choice;
  onChoiceSelected: (choice: Choice) => void;
  borderBottomChoice?: boolean;
  backgroundColor?: Color | string;
  lineHeightText: string;
  fontSizeText: string;
  colorText?: Color | string;
  borderContainer?: string;
  borderSelected?: string;
  borderRadius?: string;
  zIndex?: number;
  noDisplayValue?: boolean;
  disabled?: boolean;
  notSelected?: boolean;
  topValue?: number;
}

export const Select: FunctionComponent<SelectProps> = ({
  width,
  height = '37px',
  choices,
  placeholder,
  defaultChoice,
  onChoiceSelected,
  borderBottomChoice,
  backgroundColor = Theme.colors.cFFFFFF,
  lineHeightText,
  fontSizeText,
  colorText,
  borderContainer,
  borderSelected,
  borderRadius,
  zIndex,
  noDisplayValue,
  disabled,
  notSelected,
  topValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState<Choice | undefined>(
    undefined
  );

  useEffect(() => {
    setSelectedChoice(defaultChoice);
    // eslint-disable-next-line
  }, [defaultChoice]);

  const selectedChoiceValue = useMemo(() => {
    if ((selectedChoice?.label || selectedChoice?.value) && choices) {
      const choice = choices.find(
        (choice) => choice.value === selectedChoice?.value
      );

      return choice?.label || choice?.value || defaultChoice?.label || '';
    }
    if (choices.length > 0) {
      return 'Seleziona';
    }
    return 'loading';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices, selectedChoice?.label, selectedChoice?.value]);

  return (
    <Container
      width={width}
      borderContainer={
        isOpen ? borderSelected || borderContainer : borderContainer
      }
      borderRadius={borderRadius}
      zIndex={zIndex}
      noCursor={disabled}
    >
      <ContainerValue
        backgroundColor={backgroundColor}
        height={height}
        onClick={() => !disabled && setIsOpen((prevState) => !prevState)}
      >
        <FlexContainer
          alignItems="center"
          style={{ overflow: 'hidden', paddingRight: '16px' }}
        >
          {selectedChoice?.svg && (
            <ContainerIcon>{selectedChoice.svg}</ContainerIcon>
          )}
          {!noDisplayValue && (
            <ContainerEllipsis height={height}>
              <Text
                text={selectedChoiceValue}
                color={colorText}
                lineHeight={lineHeightText}
                fontSize={fontSizeText}
                tooltipText={selectedChoiceValue}
                ellipsis
              />
            </ContainerEllipsis>
          )}
        </FlexContainer>
        {!disabled && (
          <ArrowIcon
            svg={<Arrow />}
            width="10.8px"
            color={Theme.colors.c000000}
            isRotated={isOpen}
          />
        )}
      </ContainerValue>
      <>
        {isOpen && (
          <OptionsContainer
            className="topValue"
            topValue={topValue}
            height={height}
            zIndex={zIndex}
          >
            {choices.map((choice) => (
              <SingleOption
                borderBottomChoice={borderBottomChoice}
                height={height}
                key={choice.value}
                onClick={() => {
                  setSelectedChoice(choice);
                  onChoiceSelected(choice);
                  setIsOpen(false);
                }}
                isSelected={selectedChoice?.value === choice.value}
              >
                {choice?.svg && <ContainerIcon>{choice.svg}</ContainerIcon>}
                {!noDisplayValue && (
                  <ContainerEllipsis height={height}>
                    <Text
                      color={colorText}
                      text={choice.label || choice.value}
                      fontSize={fontSizeText}
                      lineHeight={lineHeightText}
                      tooltipText={choice.label || choice.value}
                    />
                  </ContainerEllipsis>
                )}
              </SingleOption>
            ))}
          </OptionsContainer>
        )}
      </>
    </Container>
  );
};

const Container = styled.div<{
  width?: string;
  borderContainer?: string;
  borderRadius?: string;
  zIndex?: number;
  noCursor?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ width }) => width && `width: ${width};`}
  ${({ noCursor }) => !noCursor && `cursor: pointer;`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ borderContainer }) => borderContainer && `border: ${borderContainer};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
`;

const ContainerIcon = styled.div`
  margin-right: 5px;
`;

const ContainerValue = styled.div<{
  height: string;
  backgroundColor: Color | string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => height};
  width: 100%;
  padding-left: 13px;
  border-radius: 8px;
`;

const OptionsContainer = styled.div<{
  height: string;
  zIndex?: number;
  topValue?: number;
}>`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ height, topValue }) =>
    topValue
      ? `${topValue}px`
      : `${parseInt(height.replace('px', ''), 10) + 4}px`};
  left: 0;
  width: 100%;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.1), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  overflow: hidden;
  ${({ zIndex }) => !zIndex && `z-index: 1;`}
`;

interface SingleOptionProps {
  height: string;
  isSelected: boolean;
  borderBottomChoice?: boolean;
}

const SingleOption = styled.div<SingleOptionProps>`
  padding: 0 13px;
  display: flex;
  width: 100%;
  align-items: center;
  height: ${({ height }) => height};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.cF0F0F0 : theme.colors.cFFFFFF};
  ${({ borderBottomChoice, theme }) =>
    borderBottomChoice &&
    `border-bottom: 1px solid ${theme.colors.cDCDCDC};
      &:last-child { 
        border-bottom: 0; 
      }`}
`;

const ArrowIcon = styled(SvgIcon)<{ isRotated: boolean }>`
  margin-right: 9px;
  transition: 200ms transform ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`;

const ContainerEllipsis = styled.div<{ height: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${({ height }) => height};
`;
