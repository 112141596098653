import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IdentifiableValue } from '@pec-manager/graphql/lib/graphql.types';
import { Tag } from '../../../components/tag';
import { Text } from '../../../components/common/Text';
import { getDateAsString, getTimeAsString } from '../../../utils/dateUtils';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Breadcrumb } from '../../../components/common/breadcrumb';
import { isToday } from '../../../utils/stringUtils';

interface TopSectionProps {
  textCategory?: string;
  receivedAt: number;
  archiveWalkPath?: IdentifiableValue[];
}

export const TopSection: FunctionComponent<TopSectionProps> = ({
  textCategory,
  receivedAt: arrivedAt,
  archiveWalkPath,
}) => (
  <Container justifyContent="space-between" wrapContent>
    <ContainerLeft alignItems="center">
      <Tag text={textCategory} />
      {archiveWalkPath && <Breadcrumb position={archiveWalkPath} />}
    </ContainerLeft>
    <Text
      text={isToday(arrivedAt as any) ? 'todayAt' : 'completeDate'}
      translationArgs={{
        date: getDateAsString(arrivedAt),
        time: getTimeAsString(arrivedAt),
        fresh: true,
      }}
      fontSize="12px"
      lineHeight="18px"
      fontWeight="300"
      color={Theme.colors.c505050}
    />
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 8px;
`;

const ContainerLeft = styled(FlexContainer)`
  & > div:first-child {
    margin-right: 10px;
  }
`;
