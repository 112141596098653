export const gqlApplicationShape = `
  application {
    id
    name
    context
    code
  }

  executionsCompleted
  executionsInProgress

  activitiesCompleted
  activitiesExpired
  activitiesInDraft
`;
