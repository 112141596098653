import {
  FormFieldDefaultValue,
  FormFieldDefinitionDefaultValueType,
  User,
  BaseTask,
} from '@pec-manager/graphql';
import { getDateAsString } from './dateUtils';

export const upperFirstTwo = (s: string) => {
  const tr = s.trim();
  if (!tr) {
    return tr;
  }
  const [hd, ...tl] = tr.split(' ').filter((str) => !!str);
  if (tl.length === 0) {
    return hd.slice(0, 2).toUpperCase();
  }
  return hd.charAt(0).toUpperCase() + tl[0].charAt(0).toUpperCase();
};

export const splitBySeparator = (s: string, sep: string): string[] =>
  s
    .split(sep)
    .map((k) => k.trim())
    .filter((k) => !!k);

export const extractFullName = (user?: User) =>
  user ? `${user.firstName} ${user.lastName}` : '--';

function jsonDisplayValue(s: string): any {
  try {
    return JSON.parse(s).displayValue;
  } catch (e) {
    return s;
  }
}

export const extractDefaultValuesAsStrings = (
  defaultValues: FormFieldDefaultValue[]
) =>
  defaultValues
    .map((d) => {
      switch (d.type) {
        case FormFieldDefinitionDefaultValueType.STRING:
          return d.kind.string!.string;
        case FormFieldDefinitionDefaultValueType.NUMBER:
          return `${d.kind.number!.number}`;
        case FormFieldDefinitionDefaultValueType.DATE:
          return `${d.kind.date!.date}`;
        case FormFieldDefinitionDefaultValueType.USER:
          return d.kind.user!.user.id;
        case FormFieldDefinitionDefaultValueType.CURRENCY:
          return `${d.kind.currency!.values}`;
        case FormFieldDefinitionDefaultValueType.DOCUMENT_OBJECT: {
          const { id, container } = d.kind.documentObject!.blobRef;
          return `${container}://${id}`;
        }
        default:
          return '';
      }
    })
    .filter((d) => d.trim() !== '');

export const extractDisplayNamesFromDefaultValues = (
  defaultValues: FormFieldDefaultValue[]
) => {
  const mappings: any = {};

  defaultValues.forEach((v) => {
    switch (v.type) {
      case FormFieldDefinitionDefaultValueType.STRING:
        mappings[v.kind.string?.string || ''] = jsonDisplayValue(
          v.kind.string!.string
        );

        break;
      case FormFieldDefinitionDefaultValueType.NUMBER:
        mappings[`${v.kind.number?.number}`] = `${v.kind.number?.number}`;
        break;
      case FormFieldDefinitionDefaultValueType.DATE:
        mappings[`${v.kind.date?.date}`] = `${v.kind.date?.date}`;
        break;
      case FormFieldDefinitionDefaultValueType.USER:
        mappings[v.kind.user?.user?.id || ''] = extractFullName(
          v.kind.user?.user
        );
        break;
    }
  });
  return mappings;
};

export const transformEnumValueToCamelCase = (v: string) =>
  v
    .split('_')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join('');

export const getTypeAppInPath = (location: any) => {
  const path = location.pathname.split('/');
  return path[2];
};

export const handleRoundedAvatar: (taskBase: BaseTask) => {
  name: string;
  type: 'GROUP' | 'USER';
  id?: string;
}[] = (taskBase) => {
  const inCharge =
    taskBase.inCharge &&
    `${taskBase.inCharge.firstName} ${taskBase.inCharge.lastName}`;

  if (inCharge) {
    return [{ name: inCharge, type: 'USER', id: taskBase.inCharge!.id }];
  }

  const groups: {
    name: string;
    type: 'GROUP' | 'USER';
    id: string;
  }[] = taskBase.assignees
    .filter((a) => a.group)
    .map(({ group }) => ({
      name: group!.name,
      type: 'GROUP',
      id: group!.id,
    }));
  const uniqueGroups = groups.filter(
    (g, i) => groups.findIndex((g2) => g2.id === g.id) === i
  );

  const users: {
    name: string;
    type: 'GROUP' | 'USER';
    id: string;
  }[] = taskBase.assignees
    .filter((a) => a.user)
    .map(({ user }) => ({
      name: `${user!.firstName} ${user!.lastName}`,
      type: 'USER',
      id: user!.id,
    }));
  const uniqueUsers = users.filter(
    (u, i) => users.findIndex((u2) => u2.id === u.id) === i
  );
  return [...uniqueGroups, ...uniqueUsers];
};

export const calculateDifferenceBetweenDatesSplitDaysMinSec = (
  epocMillisStartDate: number,
  EndDate: number
) => {
  const startDate = new Date(epocMillisStartDate);
  const endDate = new Date(EndDate);
  const diff = endDate.getTime() - startDate.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  return { days, hours, minutes };
};

export const isToday = (epocMillis: number) => {
  return getDateAsString(Date.now()) === getDateAsString(epocMillis);
};

export const calcStringForGaugeValue = (
  day: number,
  hours: number,
  minutes: number
) => {
  if (day > 0) {
    return 'days';
  }
  if (hours > 0) {
    return 'hours';
  }
  if (minutes > 0) {
    return 'minutes';
  }
  return 'seconds';
};

export const trasformStringInUppercaseForRecord = (text: string) => {
  let parole = text.split('_');
  parole[0] =
    parole[0].charAt(0).toUpperCase() + parole[0].slice(1).toLowerCase();
  return parole.join(' ');
};

export const orderArrayByName = (a: any, b: any) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
