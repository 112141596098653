import React, { useCallback, useEffect, useState } from 'react';
import { ExecutionContainer } from './ExecutionContainer';
import {
  BaseExecution,
  ExecutionStatus,
  SortField,
} from '@pec-manager/graphql';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { ExecutionItem } from './ExecutionItem';
import { ItemMenuChoices } from '../document';
import { ExecutionDetails } from './ExecutionDetails';
import { AroundLoader } from '../../components/loader/AroundLoader';
import { useExecutionsQuery } from '../../components/hooks/useExecutionsQuery';
import { Select } from '../../components/select';
import { TaskDetailsHandler } from '../TaskDetails';
import InfiniteScroll from 'react-infinite-scroll-component';
import { clearExecutionList } from '../../redux/Sintropi/execution/actions';
import { useDispatch } from 'react-redux';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Arrow } from '../../svg';
import { InputDateRange } from '../Kpi/InputDateRange';
import { KeycloakService } from '../../services/KeycloakService';

export const Execution = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const executionId = searchParams.get('executionId');
  const [openMenu, setOpenMenu] = React.useState<string | undefined>(undefined);
  const [openSubExecution, setOpenSubExecution] = React.useState<
    string | undefined
  >(undefined);
  const [statusFilter, setStatusFilter] = React.useState<{
    displayValue: string;
    value: ExecutionStatus;
  }>({
    displayValue: 'executionStatusRunning',
    value: ExecutionStatus.RUNNING,
  });

  const [fullSearchText, setFullSearchText] = useState<string>('');

  const [sortField, setSortField] = useState<SortField[]>([]);

  const [filterDueDate, setFilterDueDate] = useState<string>('');

  const { executions, loading, executionsCount, fetchMore } =
    useExecutionsQuery(
      statusFilter.value,
      fullSearchText,
      sortField,
      filterDueDate
    );

  useEffect(() => {
    dispatch(clearExecutionList());
  }, [dispatch]);

  const onClickSort = useCallback(
    (sortableField: string) => {
      const field = sortField.find((f) => f.field === sortableField);
      if (!!field) {
        if (field.asc) {
          return setSortField([
            ...sortField.filter((f) => f.field !== sortableField),
            {
              field: sortableField,
              asc: false,
            },
          ]);
        } else {
          return setSortField(
            sortField.filter((f) => f.field !== sortableField)
          );
        }
      } else {
        setSortField([
          ...sortField,
          {
            field: sortableField,
            asc: true,
          },
        ]);
      }
    },
    [sortField]
  );

  const calcSortIcon = useCallback(
    (sortableField: string) => {
      const field = sortField.find((f) => f.field === sortableField);
      if (!!field) {
        if (field.asc) {
          return (
            <SvgIcon
              svg={<Arrow />}
              width="16px"
              rotateDeg={180}
              color={Theme.colors.c2F80ED}
              style={{ marginLeft: '8px' }}
            />
          );
        } else {
          return (
            <SvgIcon
              svg={<Arrow />}
              width="16px"
              color={Theme.colors.c2F80ED}
              style={{ marginLeft: '8px' }}
            />
          );
        }
      }
      return null;
    },
    [sortField]
  );

  return (
    <ExecutionContainer setFullSearchText={setFullSearchText}>
      <Container loadingCursor={loading} id="containerScroll">
        <CustomInfiniteScroll
          dataLength={executions.length}
          next={fetchMore}
          hasMore={executions.length !== executionsCount}
          loader={
            <p className="text-center" style={{ width: '100%' }}>
              <Text
                text="loading"
                className="font-bold"
                fontSize="12px"
                lineHeight="14px"
                color={Theme.colors.c505050}
              />
            </p>
          }
          scrollableTarget="containerScroll"
          endMessage={
            !loading &&
            executions.length !== 0 && (
              <p className="text-center" style={{ width: '100%' }}>
                <Text
                  text="endResults"
                  className="font-bold"
                  fontSize="12px"
                  lineHeight="14px"
                  color={Theme.colors.cDCDCDC}
                />
              </p>
            )
          }
        >
          <FlexContainer
            justifyContent="space-between"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <SelectContainer>
              <Text
                text="statusFilter"
                fontSize="14px"
                lineHeight="16px"
                style={{ marginRight: '8px' }}
              />
              <Select
                width="250px"
                height="41px"
                defaultChoice={statusFilter}
                choices={[
                  {
                    label: 'executionStatusRunning',
                    value: ExecutionStatus.RUNNING,
                  },
                  {
                    label: 'executionStatusCompleted',
                    value: ExecutionStatus.COMPLETED,
                  },
                  {
                    label: 'executionStatusCancelled',
                    value: ExecutionStatus.CANCELED,
                  },
                  {
                    label: 'executionStatusFailed',
                    value: ExecutionStatus.FAILED,
                  },
                ]}
                onChoiceSelected={(choice) =>
                  setStatusFilter(
                    choice as { displayValue: string; value: ExecutionStatus }
                  )
                }
                zIndex={2}
                lineHeightText="16px"
                fontSizeText="14px"
              />
            </SelectContainer>
            {!KeycloakService.removeFieldInTask().includes('dueDate') && (
              <FilterContainer alignItems="center">
                <TextSelect
                  text="expirationDate"
                  fontSize="12px"
                  lineHeight="16px"
                />
                <InputDateRange
                  removeBorder
                  onChangeValue={(value) => {
                    setFilterDueDate(value);
                  }}
                  deleteDateRange={() => setFilterDueDate('')}
                />
              </FilterContainer>
            )}
          </FlexContainer>
          <ListField>
            <ItemBig
              style={{ cursor: 'pointer' }}
              onClick={() => onClickSort('process')}
            >
              <Text
                text="process"
                fontSize="14px"
                lineHeight="14px"
                style={{ textTransform: 'uppercase' }}
              />
              {calcSortIcon('process')}
            </ItemBig>
            <ItemMedium>
              <Text
                text="users"
                fontSize="14px"
                lineHeight="14px"
                style={{ textTransform: 'uppercase' }}
              />
            </ItemMedium>
            {!KeycloakService.removeFieldInExecution().includes('dueDate') && (
              <ItemSmall
                style={{ cursor: 'pointer' }}
                onClick={() => onClickSort('due_date')}
              >
                <Text
                  text="dueDate"
                  fontSize="14px"
                  lineHeight="14px"
                  style={{ textTransform: 'uppercase' }}
                />
                {calcSortIcon('due_date')}
              </ItemSmall>
            )}
            {!KeycloakService.removeFieldInExecution().includes('priority') && (
              <ItemSmall
                style={{ cursor: 'pointer' }}
                onClick={() => onClickSort('priority')}
              >
                <Text
                  text="priority"
                  fontSize="14px"
                  lineHeight="14px"
                  style={{ textTransform: 'uppercase' }}
                />
                {calcSortIcon('priority')}
              </ItemSmall>
            )}
            <ItemState>
              <Text
                text="state"
                fontSize="14px"
                lineHeight="14px"
                style={{ textTransform: 'uppercase' }}
              />
            </ItemState>
            <ItemMenuChoices />
          </ListField>
          <ListContainer column>
            {executions &&
              executions
                // to exclude executions that are updated via events and thar change theirs states
                .filter((execution) => execution.status === statusFilter.value)
                .map((execution: BaseExecution) => (
                  <ExecutionItem
                    key={execution.id + execution.status + execution.createdAt}
                    execution={execution}
                    setOpenMenu={setOpenMenu}
                    openMenu={openMenu}
                    openExecution={openSubExecution}
                    setOpenExecution={setOpenSubExecution}
                  />
                ))}
            {loading && (
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                style={{ width: '100%', marginTop: '20px' }}
              >
                <AroundLoader />
              </FlexContainer>
            )}
            {!loading && executions.length === 0 && (
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                style={{ width: '100%', marginTop: '200px' }}
              >
                <Text text="emptyList" fontSize="25px" lineHeight="80px" />
              </FlexContainer>
            )}
          </ListContainer>
        </CustomInfiniteScroll>
      </Container>
      {executionId && <ExecutionDetails id={executionId} />}
      {searchParams.get('taskId') && <TaskDetailsHandler />}
    </ExecutionContainer>
  );
};

const ListField = styled(FlexContainer)`
  height: 40px;
  width: calc(100% - 48px);
  padding-left: 20px;
  margin: 16px 0 0 20px;
  background-color: ${({ theme }) => theme.colors.cF0F0F0};

  & > div:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 990px) {
    display: none;
  }
`;

const Container = styled.div<{
  loadingCursor?: boolean;
  overflowAuto?: boolean;
}>`
  height: 100%;
  width: 100%;
  cursor: ${({ loadingCursor }) => (loadingCursor ? 'wait' : 'default')};
  overflow: auto;
  background-color: rgb(245, 245, 245);
`;

const ItemBig = styled.div`
  height: 40px;
  flex: 3 1 0%;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const ItemMedium = styled.div`
  height: 40px;
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    width: 19%;
  }
`;

const ItemState = styled.div`
  height: 40px;
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    width: 7%;
  }
`;

const ItemSmall = styled.div`
  height: 40px;
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    width: 16%;
  }
`;

const ListContainer = styled(FlexContainer)`
  height: 100%;
`;

// @ts-ignore
const CustomInfiniteScroll = styled(InfiniteScroll)`
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

const SelectContainer = styled.div<{ marginBottom?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 5px;
`;
const FilterContainer = styled(FlexContainer)`
  padding: 0 16px;

  @media (max-width: 1440px) {
    flex-direction: column;
    padding: 0;
  }
`;

const TextSelect = styled(Text)`
  margin-right: 16px;
  @media (max-width: 1440px) {
    padding: 8px 0;
  }
`;
