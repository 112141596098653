import React, { FC, useMemo } from 'react';
import { Record } from '@pec-manager/graphql';
import styled from 'styled-components';
import { calcValueRecord } from './utils';
import { Close, More, Success } from '../../../svg';
import { Theme } from '../../../theme';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Text } from '../../../components/common/Text';
import { MenuChoices } from '../../../components/PagedFormDefinition/MenuChoices';
import { useNavigate } from 'react-router-dom';
import { Circle } from '../RecordDetails/CustomSectionRecord';
interface ItemRecordListProps {
  record: Record;
  listLabel: string[];
  openMenu?: string;
  setOpenMenu: React.Dispatch<React.SetStateAction<string | undefined>>;
  choicesItem: (record: Record) => void;
  resetForm: () => void;
}
export const ItemRecordList: FC<ItemRecordListProps> = ({
  record,
  listLabel,
  openMenu,
  setOpenMenu,
  choicesItem,
  resetForm,
}) => {
  const navigate = useNavigate();
  const menuChoices = useMemo(
    () => [
      {
        text: 'detail',
        onClick: () => {
          setOpenMenu(undefined);
          resetForm();
          navigate(`?recordId=${record.id}`);
        },
      },
      ...((choicesItem(record) as any) || []),
    ],
    // eslint-disable-next-line
    [choicesItem, navigate, record.id, resetForm]
  );

  return (
    <Container>
      {listLabel.length > 0 &&
        listLabel.map((label, index) => {
          const value = calcValueRecord(
            record.columns?.nested?.find((column) => column.name === label)
              ?.value
          );
          const iconStatusTrue = value.toLowerCase() === 'true';
          const iconStatusFalse = value.toLowerCase() === 'false';
          const notIcon = !(iconStatusTrue || iconStatusFalse);

          return (
            <Label key={index} elementWidth={listLabel.length}>
              {iconStatusTrue && (
                <Circle color={Theme.colors.cFFFFFF} sizeCircle="20px">
                  <SvgIcon
                    svg={<Success />}
                    width="22px"
                    color={Theme.colors.c52C41A}
                  />
                </Circle>
              )}
              {iconStatusFalse && (
                <Circle color={Theme.colors.cEB5757} sizeCircle="20px">
                  <SvgIcon
                    svg={<Close />}
                    width="12px"
                    color={Theme.colors.cFFFFFF}
                  />
                </Circle>
              )}
              {notIcon && (
                <Text
                  text={value}
                  fontSize="14px"
                  lineHeight="18px"
                  ellipsis
                  tooltipText={calcValueRecord(
                    record.columns?.nested?.find(
                      (column) => column.name === label
                    )?.value
                  )}
                  color={Theme.colors.c505050}
                />
              )}
            </Label>
          );
        })}
      <MenuIcon onClick={() => setOpenMenu(record.id)}>
        <SvgIcon
          svg={<More />}
          color={Theme.colors.c2186C6}
          height="20px"
          width="20px"
        />
        {openMenu && record.id === openMenu && (
          <MenuChoices
            containerMenuProps={{ right: '20px', top: '0px' }}
            choices={menuChoices}
            onClickOutside={() => {
              setOpenMenu(undefined);
            }}
          />
        )}
      </MenuIcon>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  background: ${({ theme }) => theme.colors.cFFFFFF};
  align-items: center;
  & > div:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
    height: 50px;
  }
  & > div {
    padding-left: 16px;
  }
`;

const Label = styled.div<{ elementWidth: number }>`
  width: ${({ elementWidth }) => 100 / elementWidth}%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
`;

const MenuIcon = styled.div`
  display: flex;
  width: 56px;
  align-items: center;
  cursor: pointer;
  position: relative;
`;
