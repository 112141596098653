import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/common/Text';
import { FlexContainer } from '../../../components/layout/FlexContainer';

interface BoxAnalyticsProps {
  title: string;
  collapsed?: boolean;
  firstElement?: boolean;
  children: React.ReactNode;
}

export const BoxAnalytics: FunctionComponent<BoxAnalyticsProps> = ({
  title,
  collapsed = false,
  firstElement = false,
  children,
}) => {
  const [showChildren, setShowChildren] = useState(!collapsed);
  return (
    <Container firstElement={firstElement}>
      <TopContainer>
        <Text text={title} fontSize="16px" lineHeight="24px" />
        <Resizer onClick={() => setShowChildren((prevState) => !prevState)}>
          {showChildren ? '-' : '+'}
        </Resizer>
      </TopContainer>
      <ChildrenContainer showChildren={showChildren}>
        {children}
      </ChildrenContainer>
    </Container>
  );
};

export const Container = styled.div<{ firstElement: boolean }>`
  display: flex;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  flex-direction: column;
  margin-top: 10px;
  padding: ${({ firstElement }) => (firstElement ? '12px 16px' : '4px 16px')};

  @media (max-width: 990px) {
    width: 100%;
  }
`;

export const TopContainer = styled(FlexContainer)`
  display: flex;
  height: 54px;
  justify-content: space-between;
  align-items: center;
`;

export const ChildrenContainer = styled(FlexContainer)<{
  showChildren: boolean;
}>`
  max-height: ${({ showChildren }) => (showChildren ? 'auto' : '0px')};
  overflow: ${({ showChildren }) => (showChildren ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
`;

export const Resizer = styled.div`
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.colors.c9F9F9F};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.cFFFFFF};

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;
