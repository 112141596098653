import { gql } from '@apollo/client/core';
import { gqlTaskSummaryResolutionShape } from '../task/shapes';
import { gqlMailSummaryResolutionShape } from '../mail/shapes';
import { gqlBaseExecutionShape } from '../execution/shapes';

export const RESOURCE_RECEIVED = gql`
  subscription ResourceReceived($correlationId: String!, $appId: String!) {
      resourceReceived(correlationId: $correlationId, appId: $appId) {
          taskReceived {
              task {
                ${gqlTaskSummaryResolutionShape}
              }
              contexts {
                contextSection
                assignmentType
                taskState
            }
          }
          mailReceived {
              mail {
                 ${gqlMailSummaryResolutionShape}
              }
              contexts {
                contextSection
                assignmentType
                mailState
                archiveId
              }       
          }
          executionReceived {
              execution {
                  ${gqlBaseExecutionShape}
              }
          }
          taskDeleted {
              taskId
          }
          mailDeleted {
              mailId     
          }
          executionDeleted {
              executionId
          }
      }
    }
`;
