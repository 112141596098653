import { gql } from '@apollo/client/core';
import { gqlChatRoomEventsResponseShape } from './shapes';

export const CREATE_CHAT = gql`
  mutation CreateChatRoom($invitedUsers: [ID!], $resource: ChatResourceInput) {
    createChatRoom(invitedUsers: $invitedUsers, resource: $resource)
  }
`;

export const LOAD_CHAT = gql`
    mutation LoadChatRoom($chatRoomId: ID!) {
        loadChatRoom(chatRoomId: $chatRoomId) {
            ${gqlChatRoomEventsResponseShape}
        }
    }
`;

export const OPEN_CHAT = gql`
    mutation OpenChatRoom($chatRoomId: ID!, $resource: ChatResourceInput!) {
        openChatRoom(chatRoomId: $chatRoomId, resource: $resource) {
            ${gqlChatRoomEventsResponseShape}
        }
    }
`;

export const POST_MESSAGE_CHAT = gql`
  mutation PostMessage($chatRoomId: ID!, $message: MessageInput!) {
    postMessage(chatRoomId: $chatRoomId, message: $message)
  }
`;

export const RECEIVE_MESSAGE_CHAT = gql`
  mutation ReceiveMessage($chatRoomId: ID!, $messageOffset: Int!) {
    receiveMessage(chatRoomId: $chatRoomId, messageOffset: $messageOffset)
  }
`;

export const READ_MESSAGE_CHAT = gql`
  mutation ReadMessage($chatRoomId: ID!, $messageOffset: Int!) {
    readMessage(chatRoomId: $chatRoomId, messageOffset: $messageOffset)
  }
`;

export const LEAVE_CHAT = gql`
  mutation LeaveChatRoom($chatRoomId: ID!) {
    leaveChatRoom(chatRoomId: $chatRoomId)
  }
`;
