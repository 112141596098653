import {
  FormDefinition,
  FormFieldDefinitionDefaultValueType,
  FormFieldType,
} from '@pec-manager/graphql';
import { FormFieldDefinition } from '@pec-manager/graphql/lib/graphql.types';

export function extractAllFieldId(formDefinition: FormDefinition): string[] {
  const formDefinitionPredicatesMap: any = {};

  const isCalled: any = {};

  for (const fieldGroup of formDefinition.fieldGroups) {
    for (const field of fieldGroup.fields) {
      formDefinitionPredicatesMap[`${fieldGroup.id}__${field.id}`] = () => {
        if (field.type === FormFieldType.HIDDEN || fieldGroup.isHidden) {
          return false;
        }

        if (!isCalled[`${fieldGroup.id}__${field.id}`]) {
          isCalled[`${fieldGroup.id}__${field.id}`] = true;
        }
      };
    }
  }
  return Object.keys(formDefinitionPredicatesMap);
}

export const extractFormFieldDefinitionDefaultValueType = (
  defaultValue: FormFieldType
): FormFieldDefinitionDefaultValueType => {
  switch (defaultValue) {
    case FormFieldType.CURRENCY:
      return FormFieldDefinitionDefaultValueType.CURRENCY;
    case FormFieldType.DOCUMENT_OBJECT:
      return FormFieldDefinitionDefaultValueType.DOCUMENT_OBJECT;
    case FormFieldType.AUTH_IDENTITY:
      return FormFieldDefinitionDefaultValueType.USER;
    case FormFieldType.DATE_RANGE:
    case FormFieldType.GENERIC_DATE:
    case FormFieldType.GENERIC_DATETIME:
      return FormFieldDefinitionDefaultValueType.DATE;
    case FormFieldType.TELEPHONE_NUMBER:
    case FormFieldType.GENERIC_NUMBER:
      return FormFieldDefinitionDefaultValueType.NUMBER;
    case FormFieldType.FORMULA:
    case FormFieldType.DMS_NODE:
    case FormFieldType.EMAIL_ADDRESS:
    case FormFieldType.GENERIC_LONG_TEXT:
    case FormFieldType.GENERIC_TEXT:
    case FormFieldType.ADVANCED_SELECT:
    case FormFieldType.GENERIC_SELECT:
    case FormFieldType.HIDDEN:
    case FormFieldType.GENERIC_PASSWORD:
      return FormFieldDefinitionDefaultValueType.STRING;
    case FormFieldType.COMPOSITE:
      return FormFieldDefinitionDefaultValueType.COMPOSITE;
    case FormFieldType.APPROVAL:
      return FormFieldDefinitionDefaultValueType.APPROVAL;
    default:
      return FormFieldDefinitionDefaultValueType.STRING;
  }
};
export const insertValueInFilledForm = (
  type: FormFieldDefinitionDefaultValueType,
  value: any,
  filledForm: FormFieldDefinition
) => {
  switch (type) {
    case FormFieldDefinitionDefaultValueType.STRING:
      if (
        filledForm.defaultValues.length > 0 &&
        filledForm.defaultValues[0].kind.string?.string
      )
        return { string: filledForm.defaultValues[0].kind.string.string };
      return { string: value };
    case FormFieldDefinitionDefaultValueType.NUMBER:
      if (
        filledForm.defaultValues.length > 0 &&
        filledForm.defaultValues[0].kind.number?.number
      )
        return { number: filledForm.defaultValues[0].kind.number.number };
      return { number: value };
    case FormFieldDefinitionDefaultValueType.DATE:
      if (value * 1 > 0) return { date: value.toString() };
      else return null;
    case FormFieldDefinitionDefaultValueType.CURRENCY:
      if (
        filledForm.defaultValues.length > 0 &&
        filledForm.defaultValues[0].kind.currency?.values
      )
        return filledForm.defaultValues[0].kind.currency;
      return {
        currency: filledForm.defaultValues[0].kind.currency?.currency,
        values: [value],
      };
    case FormFieldDefinitionDefaultValueType.DOCUMENT_OBJECT:
      if (
        filledForm.defaultValues.length > 0 &&
        filledForm.defaultValues[0].kind.documentObject?.blobRef
      )
        return filledForm.defaultValues[0].kind.documentObject;
      return {
        blobRef: value,
      };
    case FormFieldDefinitionDefaultValueType.USER:
    case FormFieldDefinitionDefaultValueType.APPROVAL:
    case FormFieldDefinitionDefaultValueType.COMPOSITE:
    default:
      return '';
  }
};

export const extractDefaultValueKind = (
  defaultValue: FormFieldDefinitionDefaultValueType
) => {
  switch (defaultValue) {
    case FormFieldDefinitionDefaultValueType.STRING:
      return 'string';
    case FormFieldDefinitionDefaultValueType.NUMBER:
      return 'number';
    case FormFieldDefinitionDefaultValueType.COMPOSITE:
      return 'composite';
    case FormFieldDefinitionDefaultValueType.APPROVAL:
      return 'approval';
    case FormFieldDefinitionDefaultValueType.DATE:
      return 'date';
    case FormFieldDefinitionDefaultValueType.CURRENCY:
      return 'currency';
    case FormFieldDefinitionDefaultValueType.DOCUMENT_OBJECT:
      return 'documentObject';
    case FormFieldDefinitionDefaultValueType.USER:
      return 'user';
    default:
      return '';
  }
};
