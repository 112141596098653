import { gql } from '@apollo/client/core';
import { gqlNotificationEventResolutionShape } from './shapes';

export const NOTIFICATION_RECEIVED = gql`
  subscription NotificationReceived($correlationId: String!) {
      notificationReceived(correlationId: $correlationId) {
        ${gqlNotificationEventResolutionShape}
    }
  }
`;
