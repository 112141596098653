import React, { FunctionComponent, useEffect, useState } from 'react';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { Theme } from '../../theme';
import styled from 'styled-components';
import { BaseTask } from '@pec-manager/graphql';
import { Text } from '../../components/common/Text';
import { DynamicGenericUserField } from '../../components/common/GenericUserSelect';
import { useMutationHook } from '../../components/hooks/useMutationHook';
import { REASSIGN_TASK } from '../../graphql/task/mutations';
import { handleRoundedAvatar } from '../../utils/stringUtils';

interface AssigneesSectionProps {
  taskBase: BaseTask;
}

export const AssigneesSection: FunctionComponent<AssigneesSectionProps> = ({
  taskBase,
}) => {
  const [reassignTask] = useMutationHook({
    mutationGql: REASSIGN_TASK,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [displayValueUsers, setDisplayValueUsers] = useState<
    {
      name: string;
      type: 'GROUP' | 'USER';
      id?: string;
    }[]
  >([]);

  const [users, setUsers] = useState<string[]>([]);

  useEffect(() => {
    setUsers(handleRoundedAvatar(taskBase).map((user) => user.id!));
    setDisplayValueUsers(handleRoundedAvatar(taskBase));

    // eslint-disable-next-line
  }, [taskBase]);

  return (
    <Container justifyContent="space-between" alignItems="center">
      <ItemContainer alignItems="center" marginRight="8px">
        <Text
          text={
            taskBase.inCharge?.id ? 'takeInChargeTo' : 'assignedToBottomList'
          }
          fontSize="12px"
          lineHeight="32px"
          color={Theme.colors.c505050}
          style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
        />
        <DynamicGenericUserField
          width="calc(100% - 22px)"
          left={true}
          displayValue={displayValueUsers}
          users={users}
          disabled={!taskBase.isReassignable}
          setUsers={(el) => {
            const us = displayValueUsers
              .filter((user) => user.type !== 'GROUP')
              .map((user) => user.id!);

            const usersWithoutGroup = Array.from(new Set(us.concat(el.id!)));

            const groups = displayValueUsers.filter((u) => u.type === 'GROUP');

            reassignTask({
              variables: {
                taskId: taskBase.id,
                userIds: usersWithoutGroup,
                groupIds: groups.length ? groups.map((g) => g.id!) : [],
              },
            }).then(() => {
              if (groups.length > 0) {
                setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
              } else {
                setUsers(usersWithoutGroup);
              }
              setDisplayValueUsers((prevState) => {
                if (
                  prevState.find(
                    (element) => el.name === `${el.firstName} ${el.lastName}`
                  )
                ) {
                  return prevState;
                } else {
                  return [
                    ...prevState,
                    {
                      name: `${el.firstName} ${el.lastName}`,
                      type: 'USER',
                      id: el.id,
                    },
                  ];
                }
              });
            });
          }}
          onClickRemove={(id) => {
            const us = displayValueUsers
              .filter((user) => user.type !== 'GROUP')
              .map((user) => user.id!);

            const usersWithoutGroup = us.filter((user) => user !== id);

            const groups = displayValueUsers.filter((u) => u.type === 'GROUP');

            reassignTask({
              variables: {
                taskId: taskBase.id,
                userIds: usersWithoutGroup,
                groupIds: groups.length ? groups.map((g) => g.id!) : [],
              },
            }).then(() => {
              if (groups.length > 0) {
                setUsers(usersWithoutGroup.concat(groups.map((g) => g.id!)));
              } else {
                setUsers(usersWithoutGroup);
              }
              setDisplayValueUsers((prevState) =>
                prevState.filter((element) => element.id !== id)
              );
            });
          }}
        />
        {/* <>
            <Text
              text="notAssigned"
              fontSize="10px"
              lineHeight="14px"
              fontWeight="300"
              color={Theme.colors.c838383}
            />
            <ContainerTakeInCharge
              onClick={() => {
                history(`/inbox/takeincharge${historyPushString}`);
              }}
            >
              <Text
                text="takeCharge"
                fontSize="10px"
                lineHeight="14px"
                fontWeight="600"
                color={Theme.colors.c2186C6}
              />
            </ContainerTakeInCharge>
          </> */}
      </ItemContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 12px;
`;

const ItemContainer = styled(FlexContainer)<{
  marginRight?: string;
  marginLeft?: string;
}>`
  & > span {
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  }
`;
