import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ChatRoom,
  ChatRoomEventType,
} from '@pec-manager/graphql/lib/graphql.types';
import { useQuery, useSubscription } from '@apollo/client';
import * as _ from 'lodash';
import { ChatItem } from './ChatItem';
import { FlexContainer } from '../components/layout/FlexContainer';
import { Text } from '../components/common/Text';
import { SvgIcon } from '../components/common/SvgIcon';
import { Add } from '../svg';
import { Theme } from '../theme';
import { NewChatModal } from '../components/modals/NewChat';
import { CHAT_ROOM_LIST_EVENT_RECEIVED } from '../graphql/chat/subscriptions';
import { KeycloakService } from '../services/KeycloakService';
import { GET_CHATS } from '../graphql/chat/queries';
import { useLocation } from 'react-router-dom';

export const ChatList: FunctionComponent = () => {
  const location = useLocation();
  const [openNewMessageModal, setOpenNewMessageModal] = useState(false);
  const { data: chats, refetch } = useQuery(GET_CHATS);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [location]);

  const [chatRooms, setChatRooms] = useState<ChatRoom[]>([]);

  useEffect(() => {
    if (chats) {
      const chatRoomsResponse = chats.getChatRooms;
      setChatRooms([
        ..._.sortBy(
          chatRoomsResponse.filter(
            (c: ChatRoom) => c.lastMessagePreview != null
          ),
          (c) => c.lastMessagePreview?.receivedAt
        ).reverse(),
        ...chatRoomsResponse.filter(
          (c: ChatRoom) => c.lastMessagePreview == null
        ),
      ]);
    }
  }, [setChatRooms, chats]);

  const { data: chatRoomListEvent } = useSubscription(
    CHAT_ROOM_LIST_EVENT_RECEIVED,
    {
      variables: { correlationId: KeycloakService.getToken() },
    }
  );

  useEffect(() => {
    if (chatRoomListEvent) {
      if (
        chatRoomListEvent.chatRoomListEventReceived.eventType ===
          ChatRoomEventType.CREATED ||
        chatRoomListEvent.chatRoomListEventReceived.eventType ===
          ChatRoomEventType.CLOSED ||
        chatRoomListEvent.chatRoomListEventReceived.eventType ===
          ChatRoomEventType.REOPENED ||
        chatRoomListEvent.chatRoomListEventReceived.eventType ===
          ChatRoomEventType.PARTICIPANT_INVITED ||
        chatRoomListEvent.chatRoomListEventReceived.eventType ===
          ChatRoomEventType.PARTICIPANT_LEFT
      ) {
        refetch().then();
      } else if (
        chatRoomListEvent.chatRoomListEventReceived.eventType ===
        ChatRoomEventType.MESSAGE_POSTED
      ) {
        setChatRooms((prevState) => [
          prevState.find(
            (c) =>
              c.id ===
              chatRoomListEvent!.chatRoomListEventReceived.messagePostedEvent
                .chatRoomId
          )!,
          ...prevState.filter(
            (c) =>
              c.id !==
              chatRoomListEvent!.chatRoomListEventReceived.messagePostedEvent
                .chatRoomId
          ),
        ]);
      }
    }
    // eslint-disable-next-line
  }, [setChatRooms, chatRoomListEvent]);

  return (
    <ListContainer>
      <NewChatContainer alignItems="center" justifyContent="space-between">
        <Text text="Chat" fontSize="18px" lineHeight="18px" />
        <SvgClickable onClick={() => setOpenNewMessageModal(true)}>
          <SvgIcon svg={<Add />} color={Theme.colors.c2186C6} height="20px" />
        </SvgClickable>
      </NewChatContainer>
      <ChatsContainer>
        {chatRooms.map((chatRoom) => (
          <ChatItem
            chatRoom={chatRoom}
            key={chatRoom.id}
            chatEvent={chatRoomListEvent}
          />
        ))}
      </ChatsContainer>
      {openNewMessageModal && (
        <NewChatModal onClickClose={() => setOpenNewMessageModal(false)} />
      )}
    </ListContainer>
  );
};

const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.cF5F5F5};
  max-width: 465px;
  width: 100%;
  height: calc(100vh - 42px);

  @media (max-width: 1040px) {
    width: calc(100vw - 49px);
    max-width: 100%;
  }

  @media (max-width: 990px) {
    width: 100%;
    height: calc((var(--vh, 1vh) * 100) - 42px);
  }
`;

const NewChatContainer = styled(FlexContainer)`
  height: 80px;
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  padding: 16px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};

  @media (max-width: 1040px) {
    max-width: 100%;
  }
`;

const SvgClickable = styled.div`
  margin-right: 4px;
  cursor: pointer;
`;

const ChatsContainer = styled.div`
  height: calc(100% - 80px);
  width: 100%;
  overflow-y: auto;
`;
