import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { Theme } from '../../theme';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { ProgressBarDetail } from './ProgressBarDetail';

interface ActivitiesDetailProps {
  total: number;
  partialCompleted: number;
  partialInDraft: number;
  partialInExpired: number;
}

export const ActivitiesDetail: FunctionComponent<ActivitiesDetailProps> = ({
  partialCompleted,
  partialInDraft,
  partialInExpired,
  total,
}) => (
  <Container column>
    <Text
      text="tasks"
      fontSize="14px"
      lineHeight="21px"
      fontWeight="600"
      color={Theme.colors.c505050}
    />
    <DetailsContainer>
      <TotalDetails column alignItems="center" justifyContent="center">
        <Text
          text={`${total}`}
          fontSize="48px"
          lineHeight="58px"
          color={Theme.colors.c5B84EF}
        />
        <Text text="total" fontSize="12px" lineHeight="18px" />
      </TotalDetails>
      <FieldsContainer column justifyContent="space-between">
        <ProgressBarDetail
          title="assigned"
          total={total}
          partial={partialCompleted}
          fillerColor={Theme.colors.c00CCB3}
        />
        <ProgressBarDetail
          title="inDraft"
          total={total}
          partial={partialInDraft}
          fillerColor={Theme.colors.cFFC53D}
        />
        <ProgressBarDetail
          title="expired"
          total={total}
          partial={partialInExpired}
          fillerColor={Theme.colors.cFE4242}
        />
      </FieldsContainer>
    </DetailsContainer>
  </Container>
);

const Container = styled(FlexContainer)`
  width: 100%;
  height: 214px;
  padding: 16px 16px 0 16px;
`;

const DetailsContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  margin-top: 16px;
  padding-bottom: 16px;
`;

const TotalDetails = styled(FlexContainer)`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-right: 32px;
  padding-left: 16px;
`;

const FieldsContainer = styled(FlexContainer)`
  width: 100%;
`;
