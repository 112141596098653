import {
  ResourcePriority,
  TaskStatus,
  TaskSummary,
} from '@pec-manager/graphql';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { handleRoundedAvatar } from '../../../utils/stringUtils';
import { Tag } from '../../../components/tag';
import { mappingLocaleForDatePicker } from '../../../utils/locale';
import { KeycloakService } from '../../../services/KeycloakService';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Task } from '../../../svg';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import {
  CHANGE_TASK_DUE_DATE,
  CHANGE_TASK_PRIORITY,
  REASSIGN_TASK,
} from '../../../graphql/task/mutations';
import { DynamicGenericUserField } from '../../../components/common/GenericUserSelect';
import { Choice, Select } from '../../../components/select';
import { PrioritySelect } from '../../TaskCalendar/EventItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderDataPicker } from '../../../components/common/HeaderDataPicker';

// eslint-disable-next-line no-lone-blocks
{
  /* eslint-disable react/no-children-prop */
}

interface ActivityProcessItemProps {
  activity: TaskSummary;
}

export const ActivityProcessItem: FunctionComponent<
  ActivityProcessItemProps
> = ({ activity }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [date, setDate] = useState<Date | undefined>(undefined);

  const [changeTaskDueDate] = useMutationHook({
    mutationGql: CHANGE_TASK_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [reassignTask] = useMutationHook({
    mutationGql: REASSIGN_TASK,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [displayValueUsers, setDisplayValueUsers] = useState<
    {
      name: string;
      type: 'GROUP' | 'USER';
      id?: string;
    }[]
  >([]);

  const [users, setUsers] = useState<string[]>([]);

  const [changeTaskPriority] = useMutationHook({
    mutationGql: CHANGE_TASK_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  useEffect(() => {
    setUsers(handleRoundedAvatar(activity.base).map((user) => user.id!));
    setDisplayValueUsers(handleRoundedAvatar(activity.base));
    // eslint-disable-next-line
  }, [activity.base.assignees]);

  useEffect(() => {
    setDate(activity.base.expiresAt);
    // eslint-disable-next-line
  }, [activity.base.expiresAt]);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const executionId = searchParams.get('executionId');

  const taskLink = `?executionId=${executionId}&taskId=${activity.base.id}`;

  return (
    <>
      <ItemContainer column ref={ref}>
        <FlexContainer
          style={{
            paddingBottom: '8px',
            height: '100%',
          }}
        >
          <FirstItem>
            <TitleContainer
              column
              onClick={(event) => {
                event.stopPropagation();
                navigate(taskLink);
              }}
            >
              <FlexContainer alignItems="center">
                <SvgIcon
                  svg={<Task />}
                  width="14px"
                  height="14px"
                  color={Theme.colors.c52C41A}
                />
                <Text
                  text={`${
                    activity.base.executionId === activity.base.rootExecutionId
                      ? ''
                      : '+'
                  } ${activity.base.name}`}
                  skipTranslation={true}
                  fontSize="14px"
                  lineHeight="20px"
                  color={Theme.colors.c505050}
                  style={{ marginLeft: '8px' }}
                  ellipsis
                />
              </FlexContainer>
              <Tag text={activity.base.category} />
            </TitleContainer>
          </FirstItem>
          <ItemSmall style={{ display: 'inline-block' }}>
            <Text
              text={
                activity.base.inCharge?.id
                  ? 'takeInChargeTo'
                  : 'assignedToBottomList'
              }
              fontSize="12px"
              lineHeight="32px"
              color={Theme.colors.c505050}
              style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
            />
            <DynamicGenericUserField
              openUp={
                // @ts-ignore
                window.innerHeight - ref.current?.getBoundingClientRect().top <
                214
              }
              width="calc(100% - 22px)"
              displayValue={displayValueUsers}
              users={users}
              disabled={!activity.base.isReassignable}
              setUsers={(el) => {
                const us = displayValueUsers
                  .filter((user) => user.type !== 'GROUP')
                  .map((user) => user.id!);

                const usersWithoutGroup = Array.from(
                  new Set(us.concat(el.id!))
                );

                const groups = displayValueUsers.filter(
                  (u) => u.type === 'GROUP'
                );

                reassignTask({
                  variables: {
                    taskId: activity.base.id,
                    userIds: usersWithoutGroup,
                    groupIds: groups.length ? groups.map((g) => g.id!) : [],
                  },
                }).then(() => {
                  if (groups.length > 0) {
                    setUsers(
                      usersWithoutGroup.concat(groups.map((g) => g.id!))
                    );
                  } else {
                    setUsers(usersWithoutGroup);
                  }
                  setDisplayValueUsers((prevState) => {
                    if (
                      prevState.find(
                        (element) =>
                          element.name === `${el.firstName} ${el.lastName}`
                      )
                    ) {
                      return prevState;
                    } else {
                      return [
                        ...prevState,
                        {
                          name: `${el.firstName} ${el.lastName}`,
                          type: 'USER',
                          id: el.id,
                        },
                      ];
                    }
                  });
                });
              }}
              onClickRemove={(id) => {
                const us = displayValueUsers
                  .filter((user) => user.type !== 'GROUP')
                  .map((user) => user.id!);

                const usersWithoutGroup = us.filter((user) => user !== id);

                const groups = displayValueUsers.filter(
                  (u) => u.type === 'GROUP'
                );

                reassignTask({
                  variables: {
                    taskId: activity.base.id,
                    userIds: usersWithoutGroup,
                    groupIds: groups.length ? groups.map((g) => g.id!) : [],
                  },
                }).then(() => {
                  if (groups.length > 0) {
                    setUsers(
                      usersWithoutGroup.concat(groups.map((g) => g.id!))
                    );
                  } else {
                    setUsers(usersWithoutGroup);
                  }
                  setDisplayValueUsers((prevState) =>
                    prevState.filter((element) => element.id !== id)
                  );
                });
              }}
            />
          </ItemSmall>
          {!KeycloakService.removeFieldInExecution().includes('dueDate') && (
            <ItemSmall>
              <DatePickerStyled
                placeholderText={t('insertDate') as string}
                disabled={
                  activity.base.status === TaskStatus.COMPLETED ||
                  !activity.base.isManageable
                }
                locale={
                  mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
                }
                dateFormat={
                  mappingLocaleForDatePicker(
                    KeycloakService.getLocaleForDate()?.toLowerCase() ||
                      KeycloakService.getLocale()
                  ).formatDate
                }
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <HeaderDataPicker
                    date={date}
                    decreaseMonth={decreaseMonth}
                    changeMonth={changeMonth}
                    changeYear={changeYear}
                    increaseMonth={increaseMonth}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                  />
                )}
                onChange={(date) => {
                  changeTaskDueDate({
                    variables: {
                      taskId: activity.base.id,
                      newDueDate: new Date(date as any).getTime(),
                    },
                  }).then(() => {
                    // @ts-ignore
                    setDate(date);
                  });
                }}
                children={
                  <ContainerClear
                    onClick={() => {
                      changeTaskDueDate({
                        variables: {
                          taskId: activity.base.id,
                          newDueDate: undefined,
                        },
                      }).then(() => {
                        setDate(undefined);
                      });
                    }}
                  >
                    <Text
                      text={t('clearDueDate')}
                      fontSize="12px"
                      lineHeight="32px"
                      fontWeight="300"
                      color={Theme.colors.cD73E18}
                    />
                  </ContainerClear>
                }
                value={date as unknown as string}
                selected={date}
                showTimeSelect={false}
                timeFormat="HH:mm"
              />
            </ItemSmall>
          )}
          {!KeycloakService.removeFieldInExecution().includes('priority') && (
            <LastItem>
              <Select
                colorText={Theme.colors.c838383}
                fontSizeText="12px"
                lineHeightText="18px"
                choices={PrioritySelect.filter(
                  (choice) => choice.value !== ResourcePriority.NONE
                )}
                defaultChoice={
                  PrioritySelect.filter(
                    (s) => s.value === activity.base.priority
                  )[0]
                }
                disabled={!activity.base.isManageable}
                onChoiceSelected={(choice: Choice) => {
                  changeTaskPriority({
                    variables: {
                      taskId: activity.base.id,
                      newPriority: choice.value as ResourcePriority,
                    },
                  });
                }}
                height="32px"
                backgroundColor={Theme.colors.cFAFAFA}
                noDisplayValue
              />
            </LastItem>
          )}
        </FlexContainer>
      </ItemContainer>
      <CardActivity column onClick={() => navigate(taskLink)}>
        <FlexContainer alignItems="center" style={{ marginBottom: '10px' }}>
          <SvgIcon
            svg={<Task />}
            color={Theme.colors.c52C41A}
            height="16px"
            width="14px"
          />
          <Text
            text={`${
              activity.base.executionId === activity.base.rootExecutionId
                ? ''
                : '+'
            } ${activity.base.name}`}
            skipTranslation={true}
            fontSize="14px"
            lineHeight="20px"
            color={Theme.colors.c505050}
            style={{ marginLeft: '10px' }}
            ellipsis
          />
        </FlexContainer>
        <Tag text={activity.base.category} />
      </CardActivity>
    </>
  );
};

const ItemContainer = styled(FlexContainer)<{
  selected?: boolean;
}>`
  width: calc(100% - 40px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-top: 10px;

  @media (max-width: 990px) {
    display: none;
  }
`;

const FirstItem = styled.div`
  flex: 2;
  display: flex;
  overflow: hidden;
  align-items: center;
  min-height: 100%;
  padding-left: 20px;
`;

const ItemSmall = styled.div`
  min-height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
`;

const TitleContainer = styled(FlexContainer)`
  overflow: auto;
  cursor: pointer;
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 100%;
  overflow: auto;
  width: calc(100% - 4px);
  text-overflow: ellipsis;
`;

const ContainerClear = styled.div`
  border-radius: 4px;
  text-align: right;
  padding: 2px 8px;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.cDFE1E6};
`;

const CardActivity = styled(FlexContainer)`
  overflow: hidden;
  width: 100%;
  height: 80px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.1), 0 0 1px rgba(9, 30, 66, 0.31);

  @media (min-width: 990px) {
    display: none;
  }
`;

export const LastItem = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};

  @media (max-width: 1300px) {
    width: 12%;
  }
  @media (max-width: 1100px) {
    width: 14%;
  }
`;
