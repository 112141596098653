import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ExecutionVariable, ExecutionVariableType } from '@pec-manager/graphql';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { mapFieldsMainVariable } from './utils';

interface CustomSectionSubProcessProps {
  mainVariables: ExecutionVariable[];
}

export const CustomSectionSubProcess: FunctionComponent<
  CustomSectionSubProcessProps
> = ({ mainVariables }) => (
  <Container wrapContent justifyContent="space-between">
    {mainVariables.map(({ name, values, type }) => {
      const { values: mappedValues, translationArgs } = mapFieldsMainVariable(
        type,
        values
      );

      return (
        <Item column justifyContent="space-around" key={name}>
          <ItemTitle
            text={name}
            fontSize="12px"
            lineHeight="12px"
            color={Theme.colors.c7A869A}
            skipTranslation
          />
          <Text
            text={mappedValues}
            fontSize="14px"
            translationArgs={translationArgs}
            lineHeight="21px"
            possibleLink
            skipTranslation={
              type !== ExecutionVariableType.DATE &&
              type !== ExecutionVariableType.USER
            }
          />
        </Item>
      );
    })}
  </Container>
);

const Container = styled(FlexContainer)`
  padding-top: 16px;
  padding-right: 24px;
  width: 100%;
`;

const Item = styled(FlexContainer)`
  width: calc((100% / 2) - 8px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.cF0F0F0};
  padding: 8px;
`;

const ItemTitle = styled(Text)`
  margin: 8px 0;
`;
