import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../common/Text';
import { Theme } from '../../theme';
import { getMappedColor } from '../../utils/colorUtils';

interface TagProps {
  text?: string;
  colorBackground?: string;
}

export const Tag: FunctionComponent<TagProps> = ({
  text = 'withoutCategory',
  colorBackground,
}) => {
  const isWithoutCategory = text === 'withoutCategory';

  return (
    <TagContainer backgroundColor={getMappedColor(colorBackground || text)}>
      <Text
        text={text}
        fontSize="10px"
        lineHeight="15px"
        color={isWithoutCategory ? Theme.colors.c9F9F9F : Theme.colors.cFFFFFF}
        ellipsis
      />
      {/* {isWithoutCategory && (
        <StyledText
          lineHeight="15px"
          fontSize="10px"
          text="assign"
          color={Theme.colors.c86B7FF}
        />
      )} */}
    </TagContainer>
  );
};

const TagContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 1px 7px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 2px;
  max-width: 100%;

  &:hover {
    cursor: default;
  }
`;

/* const StyledText = styled(Text)`
  padding-left: 5px;
`; */
