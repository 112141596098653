import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTasksQuery } from '../../components/hooks/useTasksQuery';
import { useNotificationBanner } from '../../components/hooks/useNotificationBanner';
import { v4 as uuidv4 } from 'uuid';
import { Text } from '../../components/common/Text';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { TaskCalendar } from '../TaskCalendar';
import { ViewItemEnum } from './TaskContainer';
import { TaskListView } from './TaskListView';
import { Sort } from '@pec-manager/graphql';
import { Theme } from '../../theme';
import { TaskSortView } from './TaskSortView';
import { SvgIcon } from '../../components/common/SvgIcon';
import { Calendar, CardList, List } from '../../svg';
import { KeycloakService } from '../../services/KeycloakService';
import { AroundLoader } from '../../components/loader/AroundLoader';

export interface SortType {
  label: string;
  value: Sort[];
}

interface TaskListProps {
  notMobile: boolean;
  fullSearchText?: string;
}

export const TaskList: FunctionComponent<TaskListProps> = ({
  notMobile,
  fullSearchText,
}) => {
  const [sort, setSort] = React.useState<SortType[]>([
    {
      label: 'sortStartDate',
      value: [],
    },
  ]);

  const [viewItem, setViewItem] = useState<ViewItemEnum>(
    notMobile ? ViewItemEnum.LIST : ViewItemEnum.CARD
  );

  const { fetchMore, tasks, taskCount, error, loading } = useTasksQuery(
    viewItem === ViewItemEnum.LIST ? sort[0].value : undefined,
    fullSearchText
  );

  useNotificationBanner(undefined, error, undefined, {
    id: uuidv4(),
    type: 'failed',
    title: 'Woops!',
    content: 'somethingWentWrongs',
  });

  const viewCard = viewItem === ViewItemEnum.CARD;
  const viewList = viewItem === ViewItemEnum.LIST;
  const viewCalendar = viewItem === ViewItemEnum.CALENDAR;

  return (
    <ListContainer id="containerScroll">
      <ContainerTop viewCalendar={viewCalendar}>
        {viewItem !== ViewItemEnum.CALENDAR ? (
          <TaskSortView sort={sort} setSort={setSort} />
        ) : (
          <div />
        )}
        <ContainerSetView>
          <IconViewContainer
            selected={viewList}
            onClick={() => !viewList && setViewItem(ViewItemEnum.LIST)}
          >
            <SvgIcon
              svg={<List />}
              color={viewList ? Theme.colors.c0E53B7 : Theme.colors.c838383}
              width="18px"
            />
          </IconViewContainer>
          <IconViewContainer
            selected={viewCard}
            onClick={() => !viewCard && setViewItem(ViewItemEnum.CARD)}
          >
            <SvgIcon
              svg={<CardList />}
              color={viewCard ? Theme.colors.c0E53B7 : Theme.colors.c838383}
              width="18px"
            />
          </IconViewContainer>
          {!KeycloakService.removeFieldInTask().includes('dueDate') && (
            <IconViewContainer
              selected={viewCalendar}
              onClick={() =>
                !viewCalendar && setViewItem(ViewItemEnum.CALENDAR)
              }
            >
              <SvgIcon
                svg={<Calendar />}
                color={
                  viewCalendar ? Theme.colors.c0E53B7 : Theme.colors.c838383
                }
                width="18px"
              />
            </IconViewContainer>
          )}
        </ContainerSetView>
      </ContainerTop>
      {viewItem !== ViewItemEnum.CALENDAR && (
        <CustomInfiniteScroll
          dataLength={tasks.length}
          next={fetchMore}
          hasMore={tasks.length !== taskCount}
          loader={
            <p className="text-center" style={{ width: '100%' }}>
              <Text
                text="loading"
                className="font-bold"
                fontSize="12px"
                lineHeight="14px"
                color={Theme.colors.c505050}
              />
            </p>
          }
          scrollableTarget="containerScroll"
          endMessage={
            !loading &&
            taskCount !== 0 && (
              <p className="text-center" style={{ width: '100%' }}>
                <Text
                  text="endResults"
                  className="font-bold"
                  fontSize="12px"
                  lineHeight="14px"
                  color={Theme.colors.cDCDCDC}
                />
              </p>
            )
          }
        >
          <TaskListView
            tasks={tasks}
            setSort={setSort}
            sort={sort}
            view={viewItem}
          />
          {loading && (
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%', marginTop: '20px' }}
            >
              <AroundLoader />
            </FlexContainer>
          )}
          {!loading && tasks.length === 0 && (
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%', marginTop: '200px' }}
            >
              <Text text="emptyList" fontSize="25px" lineHeight="200px" />
            </FlexContainer>
          )}
        </CustomInfiniteScroll>
      )}
      {viewCalendar &&
        !KeycloakService.removeFieldInTask().includes('dueDate') && (
          <TaskCalendar />
        )}
    </ListContainer>
  );
};

// @ts-ignore
const CustomInfiniteScroll = styled(InfiniteScroll)`
  padding: 16px 16px 20px 16px;
  display: flex;
  flex-flow: wrap;

  @media (max-width: 990px) {
    padding: 0;
  }
`;

const ContainerTop = styled.div<{ viewCalendar: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ viewCalendar }) => viewCalendar && 'padding: 22px 0px 20px;'}
`;

const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.cF5F5F5};
  width: 100%;
  height: 100%;
  overflow: auto;

  @media (max-width: 1040px) {
    max-width: 100%;
    height: calc((var(--vh, 1vh) * 100) - 160px) !important;
  }
`;

const IconViewContainer = styled.div<{ selected: boolean }>`
  ${({ selected }) => !selected && 'cursor: pointer;'}
`;
const ContainerSetView = styled(FlexContainer)`
  margin-right: 24px;

  & > div:not(:last-child) {
    margin-right: 24px;
  }
  @media (max-width: 990px) {
    display: none;
  }
`;
