import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Text } from '../../components/common/Text';
import { SvgIcon } from '../../components/common/SvgIcon';
import { CircleArrow, Task } from '../../svg';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { isToday } from '../../utils/stringUtils';
import { getDateAsString, getTimeAsString } from '../../utils/dateUtils';
import { Theme } from '../../theme';

interface TitleSectionProps {
  title: string;
  receivedAt: number;
  setHideCorrelatedSection: Dispatch<SetStateAction<boolean>>;
  hideCorrelatedSection: boolean;
  showSvgArrow?: boolean;
}

export const TitleSection: FunctionComponent<TitleSectionProps> = ({
  title,
  receivedAt: arrivedAt,
  setHideCorrelatedSection,
  hideCorrelatedSection,
  showSvgArrow,
}) => (
  <Container wrapContent alignItems="center" justifyContent="space-between">
    <FlexContainer alignItems="center">
      <SvgIcon
        svg={<Task />}
        height="14px"
        width="14px"
        color={Theme.colors.c52C41A}
      />
      <TextTitle
        text={title}
        fontSize="14px"
        lineHeight="21px"
        fontWeight="500"
        skipTranslation
        style={{ marginLeft: '8px' }}
      />
    </FlexContainer>
    <RightContainer alignItems="center">
      <Text
        text={isToday(arrivedAt) ? 'todayAt' : 'completeDate'}
        translationArgs={{
          date: getDateAsString(arrivedAt),
          time: getTimeAsString(arrivedAt),
          fresh: true,
        }}
        fontSize="12px"
        lineHeight="18px"
        fontWeight="300"
        color={Theme.colors.c505050}
      />
      {showSvgArrow && (
        <motion.div whileHover={{ scale: 1.2 }} transition={{ duration: 0.2 }}>
          <ClickableSvg
            onClick={(e) => {
              e.stopPropagation();
              setHideCorrelatedSection((prevState) => !prevState);
            }}
            rotateFactor={!hideCorrelatedSection}
          >
            <SvgIcon svg={<CircleArrow />} width="18px" height="18px" />
          </ClickableSvg>
        </motion.div>
      )}
    </RightContainer>
  </Container>
);

const RightContainer = styled(FlexContainer)`
  margin-left: auto;
`;

const Container = styled(FlexContainer)`
  margin-bottom: 8px;
`;

const TextTitle = styled(Text)`
  margin-right: 16px;
  max-width: 285px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClickableSvg = styled.div<{ rotateFactor: boolean }>`
  height: 18px;
  transition: 250ms transform ease-in-out;
  transform: rotate(${({ rotateFactor }) => (rotateFactor ? `180deg` : `0`)});
`;
