import { enGB, it } from 'date-fns/esm/locale';

export const mappingLocaleForDatePicker = (locale: any, withTime?: boolean) => {
  switch (locale) {
    case 'dd/mm/yyyy':
    case 'it':
    case 'it-IT':
      if (withTime) return { locale: it, formatDate: 'dd/MM/yyyy HH:mm' };
      return { locale: it, formatDate: 'dd/MM/yyyy' };
    case 'en':
    case 'en-EN':
    default:
      if (withTime) return { locale: enGB, formatDate: 'mm-dd-yyyy HH:mm a' };
      return { locale: enGB, formatDate: 'mm-dd-yyyy' };
  }
};
