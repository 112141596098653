import styled from 'styled-components';

interface ButtonProps {
  width?: string;
  height?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  isPrimary?: boolean;
  disabled?: boolean;
}

export const FormButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: ${({ width }) => width ?? 'fit-content'};
  height: ${({ height }) => height ?? 'fit-content'};
  padding: ${({ padding }) => padding ?? '0'};
  border: ${({ border }) => border ?? '0 none transparent'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '0'};
  box-shadow: ${({ boxShadow }) => boxShadow ?? 'none'};
  background-color: ${({ isPrimary, theme, disabled }) => {
    if (disabled) {
      return theme.colors.cDCDCDC;
    }
    return isPrimary ? theme.colors.c5B84EF : theme.colors.cFFFFFF;
  }};
  transition: 200ms all ease-in-out;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background-color: ${({ isPrimary, theme, disabled }) => {
      if (disabled) {
        return theme.colors.cDCDCDC;
      }
      return isPrimary ? theme.colors.c5176d6 : theme.colors.cFFFFFF;
    }};
  }
`;
