import { gql } from '@apollo/client/core';
import {
  gqlAuthorization,
  gqlBaseDocumentFolderShape,
  gqlBaseDocumentShape,
  gqlBlobRefShape,
  gqlDocumentShape,
} from './shapes';
import { gqlIdentifiableValueShape } from '../common/shapes';
import { gqlUserResolutionShape } from '../user/shapes';

export const GET_CHILDREN_DMS_FOLDERS_BY_PARENT_ID = gql`
    query GetChildrenDMSFoldersByParentId($appId: ID!, $parentId: ID) {
        getChildrenDMSFoldersByParentId(appId: $appId,parentId: $parentId) {
            ${gqlIdentifiableValueShape}
        }
    }
`;

export const LIST_DRIVE_FOLDER = gql`
  query ListDriveFolder($appId: ID!, $folderId: ID, $pagination: Pagination) {
      listDriveFolder(appId: $appId, folderId: $folderId, pagination: $pagination) {
      totalElements
      pageElements {
          blobRef {
              ${gqlBlobRefShape}
          }
          folder {
              ${gqlIdentifiableValueShape}
          }
      }
    }
  }
`;

export const LIST_DOCUMENT_FOLDER = gql`
  query ListDocumentFolder($appId: ID!, $folderId: ID, $pagination: Pagination) {
      listDocumentFolder(appId: $appId, folderId: $folderId, pagination: $pagination) {
      totalElements
      pageElements {
          document {
              ${gqlBaseDocumentShape}
          }
          folder {
              ${gqlBaseDocumentFolderShape}
          }
      }
      queriedFolderPath {
          ${gqlIdentifiableValueShape}
      }
    }
  }
`;

export const GET_RECENT_BLOB_REFS = gql`
  query GetRecentBlobRefs($appId: ID!) {
    getRecentBlobRefs(appId: $appId) {
        totalElements
        pageElements {
            ${gqlBlobRefShape}
        }
    }
  }
`;

export const GET_DOCUMENT_BY_ID = gql`
  query GetDocumentById($id: ID!) {
      getDocumentById(id: $id) {
        ${gqlDocumentShape}
    }
  }
`;

export const GET_DOCUMENT_FOLDER_BY_ID = gql`
  query GetDocumentFolderById($id: ID!) {
      getDocumentFolderById(id: $id) {
          base {
              ${gqlBaseDocumentFolderShape}
          }
          actions
          authorizations {
              ${gqlAuthorization}
          }
          outputSections {
              name
              fields {
                  name
                  values {
                      value
                      user {
                          ${gqlUserResolutionShape}
                      }
                  }
                  type
              }
          }
        }
    }
`;
