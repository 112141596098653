import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components/layout/FlexContainer';
import { RecordContainerHeader } from './RecordContainerHeader';
import { Table } from '@pec-manager/graphql';

export interface RecordContainerProps {
  setAppCode: (name: string) => void;
  setTable: (table: Table) => void;
  table?: Table;
  setSearchRecords?: (search: string) => void;
  detailsView?: boolean;
  initialContextDetail?: any;
  setInitialContextDetail?: React.Dispatch<React.SetStateAction<any>>;
  setForceRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

export const RecordContainer: FunctionComponent<RecordContainerProps> = ({
  setAppCode,
  setTable,
  table,
  detailsView,
  initialContextDetail,
  setInitialContextDetail,
  setSearchRecords,
  setForceRefetch,
  children,
}) => (
  <div style={{ position: 'relative', width: '100%' }}>
    <BackgroundBorderTop />
    <Container column>
      <RecordContainerHeader
        setAppCode={setAppCode}
        setTable={setTable}
        table={table}
        detailsView={detailsView}
        setInitialContextDetail={setInitialContextDetail}
        setSearchRecords={setSearchRecords}
        setForceRefetch={setForceRefetch}
        initialContextDetail={initialContextDetail}
      />
      <ChildrenContainer paddingBottom={0}>{children}</ChildrenContainer>
    </Container>
  </div>
);

const Container = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.cFFFFFF};
  border-radius: 6px 0 0 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @media (max-width: 990px) {
    border-radius: 0;
  }
`;

const ChildrenContainer = styled(FlexContainer)<{ paddingBottom?: number }>`
  height: calc(100vh - 100px);
  width: calc(100vw - 250px);

  @media (max-width: 1039px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 990px) {
    height: calc(100vh);
    width: 100%;
    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${paddingBottom + 40}px;`}
  }
`;

const BackgroundBorderTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.c212832};
`;
