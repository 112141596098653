import { gql } from '@apollo/client/core';
import { gqlApplicationShape } from './shapes';

export const GET_APPLICATION_OVERVIEWS = gql`
    query GetApplicationOverviews {
        getApplicationOverviews {
            ${gqlApplicationShape}
        }
    }
`;
