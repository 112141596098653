import React, { FC } from 'react';
import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import { useTranslation } from 'react-i18next';

interface HeaderDataPickerProps {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const years = range(getYear(new Date()) - 20, getYear(new Date()) + 20, 1);
const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const HeaderDataPicker: FC<HeaderDataPickerProps> = ({
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  changeYear,
  date,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        style={{
          border: '1px solid #ccc',
          width: '30px',
        }}
      >
        {'<'}
      </button>
      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(parseInt(value))}
        style={{
          border: '1px solid #ccc',
        }}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        style={{
          border: '1px solid #ccc',
        }}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {t(option)}
          </option>
        ))}
      </select>

      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        style={{
          border: '1px solid #ccc',
          width: '30px',
        }}
      >
        {'>'}
      </button>
    </div>
  );
};
