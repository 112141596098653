import React, { FunctionComponent, useEffect, useState } from 'react';
import { Text } from '../../../components/common/Text';
import { Theme } from '../../../theme';
import { StateExecution } from '../StateExecution';
import styled from 'styled-components';
import {
  BaseExecution,
  ExecutionStatus,
  ResourcePriority,
} from '@pec-manager/graphql';
import { motion } from 'framer-motion';
import { calculateDifferenceBetweenDatesSplitDaysMinSec } from '../../../utils/stringUtils';
import { FlexContainer } from '../../../components/layout/FlexContainer';
import { ItemMenuChoices } from '../../document';
import { mappingLocaleForDatePicker } from '../../../utils/locale';
import { KeycloakService } from '../../../services/KeycloakService';
import { ContainerClear, PrioritySelect } from '../../TaskCalendar/EventItem';
import { SvgIcon } from '../../../components/common/SvgIcon';
import { Calendar, ProcessIcon } from '../../../svg';
import { Choice, Select } from '../../../components/select';
import { useMutationHook } from '../../../components/hooks/useMutationHook';
import {
  CHANGE_EXECUTION_DUE_DATE,
  CHANGE_EXECUTION_PRIORITY,
} from '../../../graphql/execution/mutations';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { getDateAsString, getTimeAsString } from '../../../utils/dateUtils';
import { HeaderDataPicker } from '../../../components/common/HeaderDataPicker';
import { Tag } from '../../../components/tag';

/* eslint-disable react/no-children-prop */

interface SubExecutionContainerProps {
  subExecution: BaseExecution;
}

export const SubExecutionItem: FunctionComponent<
  SubExecutionContainerProps
> = ({ subExecution }) => {
  const { t } = useTranslation();
  const [changeExecutionDueDate] = useMutationHook({
    mutationGql: CHANGE_EXECUTION_DUE_DATE,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [changeExecutionPriority] = useMutationHook({
    mutationGql: CHANGE_EXECUTION_PRIORITY,
    errorMessage: 'genericErrorMessage',
    successMessage: 'genericSuccessMessage',
  });

  const [date, setDate] = useState(subExecution.dueDate);
  useEffect(() => {
    if (subExecution && subExecution.dueDate && subExecution.dueDate !== date) {
      setDate(subExecution.dueDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subExecution]);

  const dateNow =
    getDateAsString(Date.now()) ===
    getDateAsString((subExecution.cancelledAt as any) || 0);

  return (
    <SubExecutionContainer
      key={subExecution.id}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: '100%' }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ type: 'just' }}
    >
      <SubExecutionFirstItem>
        <NoneContainer />
        <TitleContainer>
          <FlexContainer>
            <SvgIcon
              svg={<ProcessIcon />}
              height="20px"
              width="20px"
              color={Theme.colors.c2F80ED}
            />
            <Text
              text={subExecution.name}
              fontSize="14px"
              lineHeight="18px"
              fontWeight="500"
              ellipsis={true}
              style={{ marginBottom: '8px', marginLeft: '10px' }}
            />
          </FlexContainer>
          <Tag
            text={subExecution.processName}
            colorBackground={subExecution.processName}
          />
        </TitleContainer>
      </SubExecutionFirstItem>
      <ItemMedium justifyContent="space-around" column>
        {subExecution.startedAt &&
          subExecution.status !== ExecutionStatus.CANCELED && (
            <Text
              text="ddhhmm"
              translationArgs={{
                days: calculateDifferenceBetweenDatesSplitDaysMinSec(
                  subExecution.startedAt as any,
                  Date.now()
                ).days,
                hours: calculateDifferenceBetweenDatesSplitDaysMinSec(
                  subExecution.startedAt as any,
                  Date.now()
                ).hours,
                minutes: calculateDifferenceBetweenDatesSplitDaysMinSec(
                  subExecution.startedAt as any,
                  Date.now()
                ).minutes,
              }}
              fontSize="12px"
              lineHeight="14px"
              ellipsis
              color={Theme.colors.c505050}
            />
          )}
        {subExecution.status === ExecutionStatus.CANCELED && (
          <>
            <Text
              text={dateNow ? 'todayAt' : 'completeDate'}
              translationArgs={{
                date: getDateAsString(subExecution.cancelledAt as any),
                time: getTimeAsString(subExecution.cancelledAt as any),
                fresh: true,
              }}
              fontSize="12px"
              lineHeight="18px"
              fontWeight="300"
              color={Theme.colors.c505050}
            />
            <Text
              text="executionCancelledBy"
              fontSize="12px"
              translationArgs={{
                name: `${subExecution.cancelledBy?.firstName} ${subExecution.cancelledBy?.lastName}`,
              }}
              lineHeight="20px"
              color={Theme.colors.c505050}
              style={{ marginRight: '8px' }}
            />
          </>
        )}
      </ItemMedium>
      {!KeycloakService.removeFieldInExecution().includes('dueDate') && (
        <ItemSmall>
          <DatePickerStyled
            placeholderText={t('insertDate') as string}
            disabled={
              subExecution.status !== ExecutionStatus.RUNNING ||
              !subExecution.isManageable
            }
            locale={
              mappingLocaleForDatePicker(KeycloakService.getLocale()).locale
            }
            dateFormat={
              mappingLocaleForDatePicker(
                KeycloakService.getLocaleForDate()?.toLowerCase() ||
                  KeycloakService.getLocale()
              ).formatDate
            }
            onChange={(date) => {
              changeExecutionDueDate({
                variables: {
                  executionId: subExecution.id,
                  newDueDate: new Date(date as any).getTime(),
                },
              }).then(() => {
                setDate(date as any);
              });
            }}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <HeaderDataPicker
                date={date}
                decreaseMonth={decreaseMonth}
                changeMonth={changeMonth}
                changeYear={changeYear}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            children={
              <ContainerClear
                onClick={() => {
                  changeExecutionDueDate({
                    variables: {
                      executionId: subExecution.id,
                      newDueDate: undefined,
                    },
                  }).then(() => {
                    setDate(undefined);
                  });
                }}
              >
                <Text
                  text={t('clearDueDate')}
                  fontSize="12px"
                  lineHeight="32px"
                  fontWeight="300"
                  color={Theme.colors.cD73E18}
                />
              </ContainerClear>
            }
            value={date as string | undefined}
            selected={date}
            showTimeSelect={false}
            timeFormat="HH:mm"
          />
          <SvgIcon
            svg={<Calendar />}
            color={Theme.colors.c505050}
            width="18px"
            style={{ marginLeft: '-26px', cursor: 'default' }}
          />
        </ItemSmall>
      )}
      {!KeycloakService.removeFieldInExecution().includes('priority') && (
        <SelectItem>
          <Select
            colorText={Theme.colors.c838383}
            fontSizeText="12px"
            lineHeightText="18px"
            choices={PrioritySelect.filter(
              (choice) => choice.value !== ResourcePriority.NONE
            )}
            defaultChoice={
              PrioritySelect.filter((s) => s.value === subExecution.priority)[0]
            }
            disabled={
              subExecution.status !== ExecutionStatus.RUNNING ||
              !subExecution.isManageable
            }
            onChoiceSelected={(choice: Choice) => {
              changeExecutionPriority({
                variables: {
                  executionId: subExecution.id,
                  newPriority: choice.value as ResourcePriority,
                },
              });
            }}
            height="32px"
            backgroundColor={Theme.colors.cFAFAFA}
            noDisplayValue
          />
        </SelectItem>
      )}
      <ItemState alignItems="center">
        <StateExecution
          state={subExecution.status}
          colorText={Theme.colors.c505050}
          fontSize={14}
          sizeCircle="18px"
        />
      </ItemState>
      <ItemMenuChoices />
    </SubExecutionContainer>
  );
};

const SubExecutionContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  margin-top: 8px;
  min-height: 100%;
`;

const SubExecutionFirstItem = styled.div`
  flex: 3 1 0%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ItemSmall = styled(FlexContainer)`
  min-height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  @media (max-width: 1300px) {
    width: 16%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ItemMedium = styled(FlexContainer)`
  min-height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 19%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ItemState = styled(FlexContainer)`
  min-height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 7%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const SelectItem = styled(FlexContainer)`
  display: flex;
  align-items: center;
  min-height: 100%;
  flex: 1;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.colors.cDCDCDC};
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1300px) {
    width: 16%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  background: ${({ theme }) => theme.colors.cFAFAFA};
  padding: 2px 6px;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.c505050};
  height: 100%;
  overflow: auto;
  width: calc(100% - 4px);
  text-overflow: ellipsis;
  padding-right: 30px;
`;

const NoneContainer = styled(FlexContainer)`
  width: 15px;
  margin-right: 7px;
`;

const TitleContainer = styled(FlexContainer)`
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding-right: 16px;
  flex-direction: column;
`;
